export const PERFORMANCE = 'PERFORMANCE';
export const SALES_PERFORMANCE = 'SALES_PERFORMANCE';
export const COLLECTION_PERFORMANCE = 'COLLECTION_PERFORMANCE';
export const SET_SALES_PERFORMANCE = 'SET_SALES_PERFORMANCE';
export const SET_COLLECTION_PERFORMANCE = 'SET_COLLECTION_PERFORMANCE';

export const INITIAL_STATE = {
  ftd: {
    amount: '',
    amount_localized: '',
    increment: true,
    percentage: '',
  },
  mtd: {
    amount: '',
    amount_localized: '',
    increment: true,
    percentage: '',
  },
  ytd: {
    amount: '',
    amount_localized: '',
    increment: true,
    percentage: '',
  },
  description: '',
};
