import { find, map } from 'lodash';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux';
import * as constants from '@reducers/commonReducerConstants';
import { resetReducerState } from '@reducers/commonReducerHelpers';
import { RESET_REDUCERS_STATE } from '@constants/auth';
import GAReducer from '@reducers/GAReducer';
import AccountEntries from '@reducers/account_entries';
import AccountingAmounts from '@reducers/accounting/amounts';
import AccountingSubAccounts from '@reducers/accounting/sub_accounts';
import Accounts from '@reducers/accounts';
import AuditHistory from '@reducers/audit_history';
import Auth from '@reducers/auth';
import AuthorisationConditions from '@reducers/authorisation_conditions';
import BalanceSheet from '@reducers/balance_sheet';
import BankAccounts from '@reducers/bank_accounts';
import Banks from '@reducers/banks';
import BeatRouteAssignments from '@reducers/beat_route_assignments';
import BeatRoutes from '@reducers/beat_routes';
import Branches from '@reducers/branches';
import ChartOfAccounts from '@reducers/accounting/accounts';
import ChartsReducer from '@component/home/charts_reducer';
import CollectionPerformance from '@reducers/collection_performance';
import Comments from '@reducers/comments';
import Brands from '@reducers/brands';
import Contents from '@reducers/contents';
import CreditLeeways from '@reducers/credit_leeways';
import CreditNoteInvoices from '@reducers/credit_note_invoices';
import CreditNotes from '@reducers/credit_notes';
import CreditReasons from '@reducers/credit_reasons';
import CustomerAddresses from '@reducers/customer_addresses';
import CustomerCategories from '@reducers/customer_categories';
import CustomerContacts from '@reducers/customer_contacts';
import CustomerRatings from '@reducers/customer_ratings';
import Customers from '@reducers/customers';
import CustomFields from '@reducers/custom_fields';
import Dashboard from '@reducers/dashboard';
import DebitNotes from '@reducers/debit_notes';
import DebitReasons from '@reducers/debit_reasons';
import DefaultDacRoles from '@reducers/default_dac_roles';
import DocumentCategories from '@reducers/document_categories';
import Documents from '@reducers/documents';
import Downloads from '@reducers/downloads';
import DueInvoiceChart from '@reducers/due_invoice_chart';
import EInvoiceCredentials from '@reducers/credentials/e_invoice';
import EWayBillCredentials from '@reducers/credentials/e_way_bill';
import EligibleOffers from '@reducers/eligible_offers';
import Errors from '@reducers/errors';
import EWayBill from '@reducers/e_way_bill';
import Feeds from '@reducers/FeedsReducer';
import IncomeStatement from '@reducers/income_statement';
import Integrations from '@reducers/integrations';
import inventoryBatches from '@reducers/inventory_batches';
import InventoryIdentifications from '@reducers/inventory_identifications';
import inventoryPieces from '@reducers/inventory_pieces';
import InvoicePayments from '@reducers/invoice_payments';
import Invoices from '@reducers/invoices';
import InvoiceTerms from '@reducers/invoice_terms';
import OrderTerms from '@reducers/order_terms';
import JournalEntries from '@reducers/accounting/entries';
import Notices from '@reducers/notices';
import Offers from '@reducers/offers';
import Orders from '@reducers/orders';
import Organizations from '@reducers/organizations';
import PaymentChart from '@reducers/payment_chart';
import PaymentOuts from '@reducers/payment_outs';
import Payments from '@reducers/payments';
import PeriodLocks from '@reducers/period_locks';
import ProductCategories from '@reducers/product_categories';
import Products from '@reducers/products';
import PrintFormats from '@reducers/print_formats';
import PurchaseInvoices from '@reducers/purchase_invoices';
import PurchaseOrders from '@reducers/PurchaseOrders';
import PurchaseOrderTerms from '@reducers/purchase_order_terms';
import PurchaseReturns from '@reducers/purchase_returns';
import QuickAddModule from '@reducers/quick_add_module';
import QuickListingModule from '@reducers/quick_listing_module';
import RecentDebitNotes from '@reducers/recent_debit_notes';
import RecentCreditNotes from '@reducers/recent_credit_notes';
import RecentInvoices from '@reducers/recent_invoices';
import RecentOrders from '@reducers/recent_orders';
import RecentPaymentOuts from '@reducers/recent_payment_outs';
import RecentPayments from '@reducers/recent_payments';
import RecentPurchaseInvoices from '@reducers/recent_purchase_invoices';
import RecentPurchaseOrders from '@reducers/recent_purchase_orders';
import RecentPurchaseReturns from '@reducers/recent_purchase_returns';
import RecentReturns from '@reducers/recent_returns';
import Reconciliations from '@reducers/accounting/reconciliations';
import ReconEntries from '@reducers/accounting/recon_entries';
import Reports from '@reducers/reports';
import Returns from '@reducers/returns';
import RootReducer from '@reducers/root';
import Statistics from '@reducers/statistics';
import SMSStatistics from '@reducers/sms_statistics';
import SalesChart from '@reducers/sales_chart';
import SalesPerformance from '@reducers/sales_performance';
import Settings from '@reducers/settings';
import StockEntries from '@reducers/stock_entries';
import Stocks from '@reducers/stocks';
import StockTransfers from '@reducers/stock_transfers';
import Suppliers from '@reducers/suppliers';
import SupplierAddresses from '@reducers/supplier_addresses';
import SupplierContacts from '@reducers/supplier_contacts';
import Subscriptions from '@component/subscription/reducer';
import TaxCategories from '@reducers/tax_categories';
import Taxes from '@reducers/taxes';
import TaxRates from '@reducers/tax_rates';
import UserRoles from '@reducers/user_roles';
import Users from '@reducers/users';
import UsersGroups from '@reducers/users_groups';
import Utilities from '@reducers/utilities';
import VoucherBooksReducer from '@reducers/voucher_books';
import VisitsReducer from '@reducers/visits';
import Warehouses from '@reducers/warehouses';
import Zones from '@reducers/zones';
import SettingsReducer from '@containers/settings/SettingsReducer';
import ReportReducer from '@component/reports/ReportsReducer';

const IncOrDecCommentCount = (state, action, isIncrement) => {
  const { commentableId } = action;
  const imState = fromJS(state).toJS();
  for (let i = 0; i < imState.items.length; i += 1) {
    if (imState.items[i].id === commentableId) {
      if (isIncrement) {
        imState.items[i].number_of_comments += 1;
      } else {
        imState.items[i].number_of_comments -= 1;
      }
      break;
    }
  }
  return imState;
};

const higherOrderReducer = (moduleName, reducer) => (state, action) => {
  switch (action.type) {
    case RESET_REDUCERS_STATE: {
      return resetReducerState({ moduleName, reducerState: state });
    }
    case `FETCHING_${moduleName}_REQUEST`: {
      if (action?.subModuleName) {
        return {
          ...state,
          [action.subModuleName]: {
            ...state[action.subModuleName],
            isFetching: true,
            isError: false,
            errors: {},
            hasWarnings: false,
            warnings: {},
          },
          ...action.otherParams,
        };
      }
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: {},
        hasWarnings: false,
        warnings: {},
        ...action.otherParams,
      };
    }
    case `${moduleName}_ERROR_RESPONSE`:
      if (action?.subModuleName) {
        return {
          ...state,
          [action.subModuleName]: {
            ...state[action.subModuleName], isFetching: false, isError: true, errors: action.errors,
          },
        };
      }
      return {
        ...state, isFetching: false, isError: true, errors: action.errors,
      };
    case `${moduleName}_WARNING_RESPONSE`:
      if (action?.subModuleName) {
        return {
          ...state,
          [action.subModuleName]: {
            ...state[action.subModuleName],
            isFetching: false,
            hasWarnings: true,
            warnings: action.warnings,
          },
        };
      }
      return {
        ...state, isFetching: false, hasWarnings: true, warnings: action.warnings,
      };

    // SECTION comment related stuff
    case `${moduleName}_DECREMENT_COMMENT_COUNT`:
      return IncOrDecCommentCount(state, action, false);
    case `${moduleName}_INCREMENT_COMMENT_COUNT`:
      return IncOrDecCommentCount(state, action, true);

    case `RESET_${moduleName}_ERRORS`:
      if (action?.subModuleName) {
        return {
          ...state,
          [action.subModuleName]: {
            ...state[action.subModuleName], isFetching: false, isError: false, errors: {},
          },
        };
      }
      return {
        ...state, isFetching: false, isError: false, errors: {},
      };
    case `BULK_UPDATE_${moduleName}_DATA`: {
      const { data } = action?.payload || {};
      const updatedItems = map(state?.items, (item) => {
        const responseItemData = find(data, { id: item?.id });
        if (responseItemData?.id) {
          return responseItemData;
        }
        return item;
      });
      return {
        ...state, items: updatedItems, isFetching: false, isError: false, errors: {},
      };
    }
    default:
      return reducer(state, action);
  }
};

export default function createReducer(reducers) {
  return combineReducers({
    root_reducer: higherOrderReducer(constants.ROOT, RootReducer),
    errors: higherOrderReducer(constants.ERRORS, Errors),
    GAReducer: higherOrderReducer(constants.GOOGLE_ANALYTICS, GAReducer),
    account_entries: higherOrderReducer(constants.ACCOUNT_ENTRY, AccountEntries),
    accounting_amounts: higherOrderReducer(constants.ACCOUNTING_AMOUNT, AccountingAmounts),
    accounting_sub_accounts: higherOrderReducer(
      constants.ACCOUNTING_SUB_ACCOUNT, AccountingSubAccounts,
    ),
    accounts: higherOrderReducer(constants.ACCOUNT, Accounts),
    audit_history: higherOrderReducer(constants.AUDIT_HISTORY, AuditHistory),
    auth: higherOrderReducer(constants.CURRENT_USER, Auth),
    authorisation_conditions: higherOrderReducer(
      constants.AUTHORISATION_CONDITION, AuthorisationConditions,
    ),
    balance_sheet: higherOrderReducer(constants.BALANCE_SHEET, BalanceSheet),
    bank_accounts: higherOrderReducer(constants.BANK_ACCOUNT, BankAccounts),
    banks: higherOrderReducer(constants.BANK, Banks),
    beat_route_assignments: higherOrderReducer(
      constants.BEAT_ROUTE_ASSIGNMENT, BeatRouteAssignments,
    ),
    beat_routes: higherOrderReducer(constants.BEAT_ROUTE, BeatRoutes),
    branches: higherOrderReducer(constants.BRANCH, Branches),
    chart_of_accounts: higherOrderReducer(constants.CHART_OF_ACCOUNT, ChartOfAccounts),
    charts_reducer: higherOrderReducer(constants.CHART_REDUCER, ChartsReducer),
    collection_performance: higherOrderReducer(
      constants.COLLECTION_PERFORMANCE, CollectionPerformance,
    ),
    comments: higherOrderReducer(constants.COMMENT, Comments),
    brands: higherOrderReducer(constants.BRAND, Brands),
    contents: higherOrderReducer(constants.CONTENT, Contents),
    credit_leeways: higherOrderReducer(constants.CREDIT_LEEWAY, CreditLeeways),
    credit_note_invoices: higherOrderReducer(constants.CREDIT_NOTE_INVOICE, CreditNoteInvoices),
    credit_notes: higherOrderReducer(constants.CREDIT_NOTE, CreditNotes),
    credit_reasons: higherOrderReducer(constants.CREDIT_REASON, CreditReasons),
    custom_fields: higherOrderReducer(constants.CUSTOM_FIELDS, CustomFields),
    customer_addresses: higherOrderReducer(constants.CUSTOMER_ADDRESS, CustomerAddresses),
    customer_categories: higherOrderReducer(constants.CUSTOMER_CATEGORY, CustomerCategories),
    customer_contacts: higherOrderReducer(constants.CUSTOMER_CONTACT, CustomerContacts),
    customer_ratings: higherOrderReducer(constants.CUSTOMER_RATING, CustomerRatings),
    customers: higherOrderReducer(constants.CUSTOMER, Customers),
    dashboard: higherOrderReducer(constants.DASHBOARD, Dashboard),
    debit_notes: higherOrderReducer(constants.DEBIT_NOTE, DebitNotes),
    debit_reasons: higherOrderReducer(constants.DEBIT_REASON, DebitReasons),
    default_dac_roles: higherOrderReducer(constants.DEFAULT_DAC_ROLE, DefaultDacRoles),
    document_categories: higherOrderReducer(constants.DOCUMENT_CATEGORY, DocumentCategories),
    documents: higherOrderReducer(constants.DOCUMENT, Documents),
    downloads: higherOrderReducer(constants.DOWNLOAD, Downloads),
    due_invoice_chart: higherOrderReducer(constants.DUE_INVOICE_CHART, DueInvoiceChart),
    e_invoice_credential: higherOrderReducer(constants.E_INVOICE_CREDENTIAL, EInvoiceCredentials),
    e_way_bill_credential: higherOrderReducer(constants.E_WAY_BILL_CREDENTIAL, EWayBillCredentials),
    eligible_offers: higherOrderReducer(constants.ELIGIBLE_OFFER, EligibleOffers),
    e_way_bills: higherOrderReducer(constants.E_WAY_BILL, EWayBill),
    feeds: higherOrderReducer(constants.FEED, Feeds),
    income_statement: higherOrderReducer(constants.INCOME_STATEMENT, IncomeStatement),
    inventory_batches: higherOrderReducer(constants.INVENTORY_BATCH, inventoryBatches),
    integrations: higherOrderReducer(constants.INTEGRATION, Integrations),
    inventory_identifications: higherOrderReducer(
      constants.INVENTORY_IDENTIFICATION, InventoryIdentifications,
    ),
    inventory_pieces: higherOrderReducer(constants.INVENTORY_PIECE, inventoryPieces),
    invoice_payments: higherOrderReducer(constants.INVOICE_PAYMENT, InvoicePayments),
    invoice_terms: higherOrderReducer(constants.INVOICE_TERM, InvoiceTerms),
    order_terms: higherOrderReducer(constants.ORDER_TERM, OrderTerms),
    invoices: higherOrderReducer(constants.INVOICE, Invoices),
    journal_entries: higherOrderReducer(constants.JOURNAL_ENTRY, JournalEntries),
    notices: higherOrderReducer(constants.NOTICE, Notices),
    offers: higherOrderReducer(constants.OFFER, Offers),
    orders: higherOrderReducer(constants.ORDER, Orders),
    organizations: higherOrderReducer(constants.ORGANIZATION, Organizations),
    payment_chart: higherOrderReducer(constants.PAYMENT_CHART, PaymentChart),
    payment_outs: higherOrderReducer(constants.PAYMENT_OUT, PaymentOuts),
    payments: higherOrderReducer(constants.PAYMENT, Payments),
    period_locks: higherOrderReducer(constants.PERIOD_LOCK, PeriodLocks),
    product_categories: higherOrderReducer(constants.PRODUCT_CATEGORY, ProductCategories),
    products: higherOrderReducer(constants.PRODUCT, Products),
    print_formats: higherOrderReducer(constants.PRINT_FORMAT, PrintFormats),
    purchase_invoices: higherOrderReducer(constants.PURCHASE_INVOICE, PurchaseInvoices),
    purchase_orders: higherOrderReducer(constants.PURCHASE_ORDER, PurchaseOrders),
    purchase_order_terms: higherOrderReducer(constants.PURCHASE_ORDER_TERM, PurchaseOrderTerms),
    purchase_returns: higherOrderReducer(constants.PURCHASE_RETURN, PurchaseReturns),
    quick_add_module: higherOrderReducer(constants.QUICK_ADD_MODULE, QuickAddModule),
    quick_listing_module: higherOrderReducer(constants.QUICK_LISTING_MODULE, QuickListingModule),
    recent_debit_notes: higherOrderReducer(constants.RECENT_DEBIT_NOTE, RecentDebitNotes),
    recent_credit_notes: higherOrderReducer(constants.RECENT_CREDIT_NOTE, RecentCreditNotes),
    recent_invoices: higherOrderReducer(constants.RECENT_INVOICE, RecentInvoices),
    recent_orders: higherOrderReducer(constants.RECENT_ORDER, RecentOrders),
    recent_payment_outs: higherOrderReducer(constants.RECENT_PAYMENT_OUT, RecentPaymentOuts),
    recent_payments: higherOrderReducer(constants.RECENT_PAYMENT, RecentPayments),
    recent_purchase_invoices: higherOrderReducer(
      constants.RECENT_PURCHASE_INVOICE, RecentPurchaseInvoices,
    ),
    recent_purchase_orders: higherOrderReducer(
      constants.RECENT_PURCHASE_ORDER, RecentPurchaseOrders,
    ),
    recent_purchase_returns: higherOrderReducer(
      constants.RECENT_PURCHASE_RETURN, RecentPurchaseReturns,
    ),
    recent_returns: higherOrderReducer(constants.RECENT_RETURN, RecentReturns),
    recon_entries: higherOrderReducer(constants.RECON_ENTRY, ReconEntries),
    reconciliations: higherOrderReducer(constants.RECONCILIATION, Reconciliations),
    ReportReducer: higherOrderReducer(constants.REPORT, ReportReducer),
    reports: higherOrderReducer(constants.REPORTS, Reports),
    returns: higherOrderReducer(constants.RETURN, Returns),
    statistics: higherOrderReducer(constants.STATISTIC, Statistics),
    sms_statistics: higherOrderReducer(constants.SMS_STATISTIC, SMSStatistics),
    sales_chart: higherOrderReducer(constants.SALES_CHART, SalesChart),
    sales_performance: higherOrderReducer(constants.SALES_PERFORMANCE, SalesPerformance),
    settings_reducer: higherOrderReducer(constants.SETTINGS, SettingsReducer),
    settings: higherOrderReducer(constants.SETTING, Settings),
    stock_entries: higherOrderReducer(constants.STOCK_ENTRY, StockEntries),
    stock_transfers: higherOrderReducer(constants.STOCK_TRANSFER, StockTransfers),
    stocks: higherOrderReducer(constants.STOCK, Stocks),
    suppliers: higherOrderReducer(constants.SUPPLIER, Suppliers),
    supplier_addresses: higherOrderReducer(constants.SUPPLIER_ADDRESS, SupplierAddresses),
    supplier_contacts: higherOrderReducer(constants.SUPPLIER_CONTACT, SupplierContacts),
    subscriptions: higherOrderReducer(constants.SUBSCRIPTION, Subscriptions),
    tax_categories: higherOrderReducer(constants.TAX_CATEGORY, TaxCategories),
    tax_rates: higherOrderReducer(constants.TAX_RATE, TaxRates),
    taxes: higherOrderReducer(constants.TAX, Taxes),
    user_roles: higherOrderReducer(constants.USER_ROLE, UserRoles),
    users_groups: higherOrderReducer(constants.USERS_GROUP, UsersGroups),
    users: higherOrderReducer(constants.USER, Users),
    utilities: higherOrderReducer(constants.UTILITIES, Utilities),
    voucher_books: higherOrderReducer(constants.VOUCHER_BOOK, VoucherBooksReducer),
    visits: higherOrderReducer(constants.VISIT, VisitsReducer),
    warehouses: higherOrderReducer(constants.WAREHOUSE, Warehouses),
    zones: higherOrderReducer(constants.ZONE, Zones),
    ...reducers,
  });
}
