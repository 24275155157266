import React from 'react';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { moduleMappingWithClass } from '@component/subscription/enforcement/helper';
import Content from './content';

interface Props {
  activeModule?: string;
  subscriptions: {
    features?: {
      isFetching: boolean;
      items: any[];
    },
  },
}

const defaultProps = {
  activeModule: '',
};

const Enforcement = (props: Props) => {
  const { activeModule, subscriptions } = props;
  const { features } = subscriptions;

  const backendModelClassName = moduleMappingWithClass?.[activeModule];
  let feature: any = {};
  if (features?.items?.length > 0) {
    feature = find(features?.items, { name: backendModelClassName });
  }

  return (
    <Content meta={feature?.meta || {}} />
  );
};

Enforcement.defaultProps = defaultProps;

function mapStateToProps(state) {
  const { subscriptions, root_reducer: rootReducer } = state;
  return { subscriptions, activeModule: rootReducer?.activeModule };
}

export default connect(mapStateToProps, undefined)(Enforcement);
