/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import {
  func, array, object, string, bool,
} from 'prop-types';
import { compact, map } from 'lodash';
import { NavDropdown, Breadcrumb } from 'react-bootstrap';
import { getBreadcrumbUrl } from '@helpers/common_helper';
import { doesNotHaveIndiaAsBaseCountry } from '@helpers/International_customer_helpers';
import { getIndexVariants } from '@component/common/headers/helpers/common_header_helper';
import { logEvent, CategoryEnum, ActionEnum } from '@utils/GAUtils';
import { moduleName as customersModuleName } from '@constants/customers';
import history from '../../history';

const ignoreListViewsToShowingInternationalCustomers = ['customers_w_gstin', 'customers_wo_gstin'];
const moduleNames = [customersModuleName];

export const renderBreadcrumbs = (breadcrumbListing) => (
  <div>
    {(breadcrumbListing?.length > 0)
      ? (
        <span className="breadcrumb">
          {
            map(breadcrumbListing, (item) => {
              if (item.active) {
                return (
                  <Breadcrumb.Item active key={item.title}>{item.title}</Breadcrumb.Item>
                );
              }
              const hrefUrl = getBreadcrumbUrl({ item });
              return (
                <Breadcrumb.Item
                  href={hrefUrl}
                  key={item.title}
                  onClick={() => logEvent(CategoryEnum.WINDOW, ActionEnum.BREADCRUMB, item.title)}
                >
                  &nbsp;
                  {item.title}
                </Breadcrumb.Item>
              );
            })
          }
        </span>
      ) : null}
  </div>
);

class IndexHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
  }

  handleToggle = () => {
    const { isMenuOpened } = this.state;
    this.setState({ isMenuOpened: !isMenuOpened });
  }

  renderVariantSelection = () => {
    const {
      handleVariantChange, selectedVariant, moduleName, clientSettings,
    } = this.props;
    const { isMenuOpened } = this.state;

    let indexPageVariants = getIndexVariants(moduleName);

    if (doesNotHaveIndiaAsBaseCountry(clientSettings) && moduleNames.includes(moduleName)) {
      const listViews = map(indexPageVariants?.LIST_VIEWS, (item) => {
        if (ignoreListViewsToShowingInternationalCustomers.includes(item.key)) {
          return '';
        }
        return item;
      });
      indexPageVariants = { ...indexPageVariants, LIST_VIEWS: compact(listViews) };
    }

    const hasMultipleOptions = (indexPageVariants?.LIST_VIEWS?.length > 1);

    return hasMultipleOptions ? (
      <NavDropdown
        title={(
          <div id="header-title">
            {selectedVariant.label}
            &nbsp;
            <i className="fa fa-chevron-down" />
          </div>
        )}
        noCaret
        id="report-variant-dropdown"
        open={isMenuOpened}
        onToggle={this.handleToggle}
        onClick={() => { this.setState({ isMenuOpened: true }); }}
      >
        {
          map(indexPageVariants, (entryValue, entryKey) => (
            <div className="index-dropdown-section" key={`index_header_selection_key_${entryKey}`}>
              <span>
                {entryKey?.replace('_', ' ')}
              </span>
              {
                map(entryValue, (option) => (
                  <a
                    key={`index_header_selection_option_${option.key}`}
                    onClick={() => {
                      // Hide nav dropdown
                      this.setState({ isMenuOpened: false });
                      if (option.callback) {
                        option.callback();
                      }
                      if (option.redirectUrl) {
                        history.push(option.redirectUrl);
                      }
                      if (option.applyFilters) {
                        handleVariantChange({
                          shouldFilter: true,
                          filterSelection: option.filterSelection,
                          selectedVariant: option,
                        });
                      }
                      if (option.clearFilters) {
                        handleVariantChange({ shouldFilter: false, selectedVariant: option });
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                    role="presentation"
                  >

                    {option.label}

                  </a>
                ))
              }
            </div>
          ))
        }
      </NavDropdown>
    )
      : (
        <div id="header-title">
          {selectedVariant.label}
        </div>
      );
  };

  render() {
    const {
      breadcrumbListing, showbreadcrumb, moduleName,
    } = this.props;
    const indexPageVariants = getIndexVariants(moduleName);
    return (
      <div className="report-page-info" style={{ width: 'fit-content' }}>
        {showbreadcrumb ? renderBreadcrumbs(breadcrumbListing) : null}
        {indexPageVariants
          ? this.renderVariantSelection()
          : (
            <h6 id="header-title">&nbsp;</h6>
          )}
      </div>
    );
  }
}

IndexHeader.propTypes = {
  breadcrumbListing: array,
  handleVariantChange: func,
  selectedVariant: object,
  moduleName: string.isRequired,
  showbreadcrumb: bool,
  clientSettings: object,
};

IndexHeader.defaultProps = {
  breadcrumbListing: [],
  handleVariantChange: () => ({}),
  selectedVariant: {},
  showbreadcrumb: true,
  clientSettings: {},
};

export default IndexHeader;
