import { HOME_BREADCRUMB_LISTING } from './common';

// This constants used in invoice action and reducer.
export const INVOICE = 'INVOICE';
export const SET_INVOICES = 'SET_INVOICES';
export const ADD_INVOICE = 'ADD_INVOICE';
export const INVOICE_FETCHED = 'INVOICE_FETCHED';
export const INVOICE_UPDATED = 'INVOICE_UPDATED';
export const INVOICE_DELETED = 'INVOICE_DELETED';
export const INVOICE_EDIT = 'INVOICE_EDIT';
export const RESET_INVOICE_ERRORS = 'RESET_INVOICE_ERRORS';
export const INVOICE_CLEAR_ERROR = 'INVOICE_CLEAR_ERROR';
export const AUTOCOMPLETE_INVOICES = 'AUTOCOMPLETE_INVOICES';
export const SET_ORDER_INVOICE = 'SET_ORDER_INVOICE';

export const FETCH_INVOICES = 'FETCH_INVOICES';

export const UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DELIVERED = 'UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DELIVERED';
export const UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DISPATCHED = 'UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DISPATCHED';
export const FORM_INFORMATION_TITLE = 'INVOICE INFORMATION';

// This constants used in invoice components.
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GENERATE_BULK_PDF = 'GENERATE_BULK_PDF';
export const BULK_DELIVERED = 'BULK_DELIVERED';
export const BULK_DISPATCHED = 'BULK_DISPATCHED';
export const REFETCH_AND_SAVE = 'REFETCH_AND_SAVE';
export const CANCELLED_REMAINING_QTY_AND_SAVE = 'CANCELLED_REMAINING_QTY_AND_SAVE';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';
export const OPEN_INVOICE_PAYMENT_FORM_MODAL = 'OPEN_INVOICE_PAYMENT_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';
export const UPDATE_STATE_CONFIRMATION = 'UPDATE_STATE_CONFIRMATION';

export const PERCENTAGE = 'percentage';
export const FLAT = 'flat';
export const DISCOUNT_TYPES = [
  { label: '%', value: PERCENTAGE },
  { label: 'Flat', value: FLAT },
];

export const title = 'Invoices';
export const singularTitle = 'Invoice';
export const moduleName = 'invoices';
export const baseUrl = '/invoices';
export const redirectUrl = '/invoices';
export const listingDataKey = 'invoices';
export const itemDataKey = 'invoice';

export const invoiceConstants = {
  item: INVOICE,
  setItems: SET_INVOICES,
  addItem: ADD_INVOICE,
  setItem: INVOICE_FETCHED,
  updateItem: INVOICE_UPDATED,
  deleteItem: INVOICE_DELETED,
  resetErrors: RESET_INVOICE_ERRORS,
  autocompleteItems: AUTOCOMPLETE_INVOICES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  customer_po_number_eq: '',
  variants_id_in: [],
  billing_address_line_1_cont: '',
  state_in: [],
  user_id_in: [],
  customer_id_in: [],
  variants_brand_id_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  variants_product_category_id_in: [],
  billing_address_state_in: [],
  orders_user_id_in: [],
  unpaid_amount_gt: '',
  customer_beat_routes_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_customer_name_or_customer_gstin_or_customer_beat_routes_name_cont: '',
  orders_id_eq: '', // This search params use in order show page while showing invoices listing on order show page.
  ...advanceSearchParams,
};

// This two constance usefull for advance search in invoices listing.
export const requiredPrefetchingModuleNames = ['brands', 'users', 'product_categories', 'branches', 'warehouses', 'customers', 'products', 'state_codes', 'beat_routes', 'voucher_books'];

export const ready = 'ready';
export const dispatched = 'dispatched';
export const delivered = 'delivered';
export const closed = 'closed';
export const cancel = 'cancel';
export const cancelled = 'cancelled';

export const INVOICE_STATES = [
  { label: 'Ready', value: ready, stateStyle: 'info' },
  { label: 'Dispatched', value: dispatched, stateStyle: 'warning' },
  { label: 'Delivered', value: delivered, stateStyle: 'danger' },
  { label: 'Closed', value: closed, stateStyle: 'success' },
  { label: 'Cancelled', value: cancelled, stateStyle: 'default' },
];

export const INVOICE_STATES_FOR_REPORTS = [
  { name: 'Ready', id: ready },
  { name: 'Dispatched', id: dispatched },
  { name: 'Delivered', id: delivered },
  { name: 'Closed', id: closed },
  { name: 'Cancelled', id: cancelled },
];

export const SOURCING_STATUS = [
  { name: 'Sourced', id: 'Sourced' },
  { name: 'Unsourced', id: 'Unsourced' },
];

export const Title = 'Invoices';
export const TargetBaseUrl = 'invoices';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'customer', 'customer_id', 'shipping_address_id', 'billing_address_id', 'branch', 'branch_id', 'warehouse', 'warehouse_id', 'due_date', 'invoice_term', 'invoice_term_id', 'sequence', 'voucher_discount_type', 'voucher_discount_value'],
  invoice_items: ['order_item_id', 'variant', 'variant_id', 'description', 'quantity', 'input_quantity', 'input_quantity_unit', 'input_price', 'input_price_per_unit',
    'cancellation_reason', 'gross_amount', 'gross_amount', 'price', 'line_discount_amount', 'net_price', 'invoice_item_custom_discount', 'invoice_item_custom_discount.value', 'invoice_item_custom_discount.discount_type'],
};

export const TAX_AMOUNT_BREAKDOWN_FOR_TAXES = 'Tax Amounts Breakdown';
export const TAX_AMOUNT_BREAKDOWN_FOR_TCS = 'Tax Collection at Source';
