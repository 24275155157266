import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_CUSTOMER_CATEGORIES, ADD_CUSTOMER_CATEGORY, CUSTOMER_CATEGORY_FETCHED,
  CUSTOMER_CATEGORY_UPDATED, CUSTOMER_CATEGORY_DELETED, AUTOCOMPLETE_CUSTOMER_CATEGORIES,
  defaultSearchParams, moduleName,
} from '../constants/customer_categories';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function customerCategories(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_CATEGORIES: return setItems(state, action);
    case ADD_CUSTOMER_CATEGORY: return addItem(state, action);
    case CUSTOMER_CATEGORY_FETCHED: return fetchItem(state, action);
    case CUSTOMER_CATEGORY_UPDATED: return updateItem(state, action);
    case CUSTOMER_CATEGORY_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_CUSTOMER_CATEGORIES: return autocompleteUnions(state, action);
    default:
      return state;
  }
}
