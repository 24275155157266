import * as constants from '@reducers/commonReducerConstants';
import { defaultGATimingState as gaInitialState } from '@reducers/GAReducer';
import { initialState as accountEntriesInitialState } from '@reducers/account_entries';
import { initialState as accountingAmountsInitialState } from '@reducers/accounting/amounts';
import { initialState as accountingSubAccountsInitialState } from '@reducers/accounting/sub_accounts';
import { initialState as accountsInitialState } from '@reducers/accounts';
import { auditHistoryInitialState } from '@reducers/audit_history';
import { initialState as authInitialState } from '@reducers/auth';
import { initialState as authorisationConditionsInitialState } from '@reducers/authorisation_conditions';
import { initialState as balanceSheetInitialState } from '@reducers/balance_sheet';
import { initialState as bankAccountsInitialState } from '@reducers/bank_accounts';
import { initialState as banksInitialState } from '@reducers/banks';
import { initialState as beatRouteAssignmentsInitialState } from '@reducers/beat_route_assignments';
import { initialState as beatRoutesInitialState } from '@reducers/beat_routes';
import { initialState as branchesInitialState } from '@reducers/branches';
import { initialState as chartOfAccountsInitialState } from '@reducers/accounting/accounts';
import { initialState as collectionPerformanceInitialState } from '@reducers/collection_performance';
import { initialState as commentsInitialState } from '@reducers/comments';
import { initialState as brandsInitialState } from '@src/reducers/brands';
import { initialState as creditLeewaysInitialState } from '@reducers/credit_leeways';
import { initialState as creditNoteInvoicesInitialState } from '@reducers/credit_note_invoices';
import { initialState as creditNotesInitialState } from '@reducers/credit_notes';
import { initialState as creditReasonsInitialState } from '@reducers/credit_reasons';
import { initialState as customerAddressesInitialState } from '@reducers/customer_addresses';
import { initialState as customerCategoriesInitialState } from '@reducers/customer_categories';
import { initialState as customerContactsInitialState } from '@reducers/customer_contacts';
import { initialState as customerRatingsInitialState } from '@reducers/customer_ratings';
import { initialState as customersInitialState } from '@reducers/customers';
import { customFieldsInitialState } from '@reducers/custom_fields';
import { initialState as dashboardInitialState } from '@reducers/dashboard';
import { initialState as debitNotesInitialState } from '@reducers/debit_notes';
import { initialState as debitReasonsInitialState } from '@reducers/debit_reasons';
import { initialState as defaultDacRolesInitialState } from '@reducers/default_dac_roles';
import { initialState as documentCategoriesInitialState } from '@reducers/document_categories';
import { initialState as documentsInitialState } from '@reducers/documents';
import { initialState as downloadsInitialState } from '@reducers/downloads';
import { initialState as dueInvoiceChartInitialState } from '@reducers/due_invoice_chart';
import { initialState as eligibleOffersInitialState } from '@reducers/eligible_offers';
import { initialState as errorsInitialState } from '@reducers/errors';
import { initialState as feedsInitialState } from '@reducers/FeedsReducer';
import { initialState as incomeStatementInitialState } from '@reducers/income_statement';
import { initialState as inventoryIdentificationsInitialState } from '@reducers/inventory_identifications';
import { initialState as invoicePaymentsInitialState } from '@reducers/invoice_payments';
import { initialState as invoicesInitialState } from '@reducers/invoices';
import { initialState as invoiceTermsInitialState } from '@reducers/invoice_terms';
import { initialState as orderTermsInitialState } from '@reducers/order_terms';
import { initialState as journalEntriesInitialState } from '@reducers/accounting/entries';
import { noticesInitialState } from '@reducers/notices';
import { initialState as offersInitialState } from '@reducers/offers';
import { initialState as ordersInitialState } from '@reducers/orders';
import { initialState as organizationsInitialState } from '@reducers/organizations';
import { initialState as paymentChartInitialState } from '@reducers/payment_chart';
import { initialState as paymentOutsInitialState } from '@reducers/payment_outs';
import { initialState as paymentsInitialState } from '@reducers/payments';
import { initialState as periodLocksInitialState } from '@reducers/period_locks';
import { initialState as productCategoriesInitialState } from '@reducers/product_categories';
import { initialState as productsInitialState } from '@reducers/products';
import { initialState as printFormatsInitialState } from '@reducers/print_formats';
import { initialState as purchaseInvoicesInitialState } from '@reducers/purchase_invoices';
import { initialState as purchaseOrdersInitialState } from '@reducers/PurchaseOrders';
import { initialState as purchaseReturnsInitialState } from '@reducers/purchase_returns';
import { initialState as quickAddModuleInitialState } from '@reducers/quick_add_module';
import { initialState as quickListingModuleInitialState } from '@reducers/quick_listing_module';
import { initialState as recentDebitNotesInitialState } from '@reducers/recent_debit_notes';
import { initialState as recentCreditNotesInitialState } from '@reducers/recent_credit_notes';
import { initialState as recentInvoicesInitialState } from '@reducers/recent_invoices';
import { initialState as recentOrdersInitialState } from '@reducers/recent_orders';
import { initialState as recentPaymentOutsInitialState } from '@reducers/recent_payment_outs';
import { initialState as recentPaymentsInitialState } from '@reducers/recent_payments';
import { initialState as recentPurchaseInvoicesInitialState } from '@reducers/recent_purchase_invoices';
import { initialState as recentPurchaseOrdersInitialState } from '@reducers/recent_purchase_orders';
import { initialState as recentPurchaseReturnsInitialState } from '@reducers/recent_purchase_returns';
import { initialState as recentReturnsInitialState } from '@reducers/recent_returns';
import { initialState as reconciliationsInitialState } from '@reducers/accounting/reconciliations';
import { reconEntriesInitialState } from '@reducers/accounting/recon_entries';
import { initialState as reportsInitialState } from '@reducers/reports';
import { initialState as returnsInitialState } from '@reducers/returns';
import { initialState as rootReducerInitialState } from '@reducers/root';
import { initialState as statisticsInitialState } from '@reducers/statistics';
import { initialState as salesChartInitialState } from '@reducers/sales_chart';
import { initialState as salesPerformanceInitialState } from '@reducers/sales_performance';
import { initialState as settingsInitialState } from '@reducers/settings';
import { initialState as stockEntriesInitialState } from '@reducers/stock_entries';
import { initialState as stocksInitialState } from '@reducers/stocks';
import { initialState as stockTransfersInitialState } from '@reducers/stock_transfers';
import { initialState as suppliersInitialState } from '@reducers/suppliers';
import { initialState as taxCategoriesInitialState } from '@reducers/tax_categories';
import { initialState as taxesInitialState } from '@reducers/taxes';
import { initialState as taxRatesInitialState } from '@reducers/tax_rates';
import { initialState as userRolesInitialState } from '@reducers/user_roles';
import { initialState as usersInitialState } from '@reducers/users';
import { initialState as usersGroupsInitialState } from '@reducers/users_groups';
import { initialState as voucherBooksReducerInitialState } from '@reducers/voucher_books';
import { initialState as warehousesInitialState } from '@reducers/warehouses';
import { initialState as zonesInitialState } from '@reducers/zones';
import { initialState as settingsReducerInitialState } from '@containers/settings/SettingsReducer';
import { initialState as reportReducerInitialState } from '@component/reports/ReportsReducer';
import { defaultChartsState as chartsReducerInitialState } from '@component/home/charts_reducer';

export const resetReducerState = ({ moduleName, reducerState }) => {
  switch (moduleName) {
    case constants.ROOT:
      return rootReducerInitialState;
    case constants.ERRORS:
      return errorsInitialState;
    case constants.GOOGLE_ANALYTICS:
      return gaInitialState;
    case constants.ACCOUNT_ENTRY:
      return accountEntriesInitialState;
    case constants.ACCOUNTING_AMOUNT:
      return accountingAmountsInitialState;
    case constants.ACCOUNTING_SUB_ACCOUNT:
      return accountingSubAccountsInitialState;
    case constants.ACCOUNT:
      return accountsInitialState;
    case constants.AUDIT_HISTORY:
      return auditHistoryInitialState;
    case constants.CURRENT_USER:
      return authInitialState;
    case constants.AUTHORISATION_CONDITION:
      return authorisationConditionsInitialState;
    case constants.BALANCE_SHEET:
      return balanceSheetInitialState;
    case constants.BANK_ACCOUNT:
      return bankAccountsInitialState;
    case constants.BANK:
      return banksInitialState;
    case constants.BEAT_ROUTE_ASSIGNMENT:
      return beatRouteAssignmentsInitialState;
    case constants.BEAT_ROUTE:
      return beatRoutesInitialState;
    case constants.BRANCH:
      return branchesInitialState;
    case constants.CHART_OF_ACCOUNT:
      return chartOfAccountsInitialState;
    case constants.CHART_REDUCER:
      return chartsReducerInitialState;
    case constants.COLLECTION_PERFORMANCE:
      return collectionPerformanceInitialState;
    case constants.COMMENT:
      return commentsInitialState;
    case constants.BRAND:
      return brandsInitialState;
    case constants.CREDIT_LEEWAY:
      return creditLeewaysInitialState;
    case constants.CREDIT_NOTE_INVOICE:
      return creditNoteInvoicesInitialState;
    case constants.CREDIT_NOTE:
      return creditNotesInitialState;
    case constants.CREDIT_REASON:
      return creditReasonsInitialState;
    case constants.CUSTOM_FIELDS:
      return customFieldsInitialState;
    case constants.CUSTOMER_ADDRESS:
      return customerAddressesInitialState;
    case constants.CUSTOMER_CATEGORY:
      return customerCategoriesInitialState;
    case constants.CUSTOMER_CONTACT:
      return customerContactsInitialState;
    case constants.CUSTOMER_RATING:
      return customerRatingsInitialState;
    case constants.CUSTOMER:
      return customersInitialState;
    case constants.DASHBOARD:
      return dashboardInitialState;
    case constants.DEBIT_NOTE:
      return debitNotesInitialState;
    case constants.DEBIT_REASON:
      return debitReasonsInitialState;
    case constants.DEFAULT_DAC_ROLE:
      return defaultDacRolesInitialState;
    case constants.DOCUMENT_CATEGORY:
      return documentCategoriesInitialState;
    case constants.DOCUMENT:
      return documentsInitialState;
    case constants.DOWNLOAD:
      return downloadsInitialState;
    case constants.DUE_INVOICE_CHART:
      return dueInvoiceChartInitialState;
    case constants.ELIGIBLE_OFFER:
      return eligibleOffersInitialState;
    case constants.FEED:
      return feedsInitialState;
    case constants.INCOME_STATEMENT:
      return incomeStatementInitialState;
    case constants.INVENTORY_TRACKING:
      return inventoryIdentificationsInitialState;
    case constants.INVOICE_PAYMENT:
      return invoicePaymentsInitialState;
    case constants.INVOICE_TERM:
      return invoiceTermsInitialState;
    case constants.ORDER_TERM:
      return orderTermsInitialState;
    case constants.INVOICE:
      return invoicesInitialState;
    case constants.JOURNAL_ENTRY:
      return journalEntriesInitialState;
    case constants.NOTICE:
      return noticesInitialState;
    case constants.OFFER:
      return offersInitialState;
    case constants.ORDER:
      return ordersInitialState;
    case constants.ORGANIZATION:
      return organizationsInitialState;
    case constants.PAYMENT_CHART:
      return paymentChartInitialState;
    case constants.PAYMENT_OUT:
      return paymentOutsInitialState;
    case constants.PAYMENT:
      return paymentsInitialState;
    case constants.PERIOD_LOCK:
      return periodLocksInitialState;
    case constants.PRODUCT_CATEGORY:
      return productCategoriesInitialState;
    case constants.PRODUCT:
      return productsInitialState;
    case constants.PRINT_FORMAT:
      return printFormatsInitialState;
    case constants.PURCHASE_INVOICE:
      return purchaseInvoicesInitialState;
    case constants.PURCHASE_ORDER:
      return purchaseOrdersInitialState;
    case constants.PURCHASE_RETURN:
      return purchaseReturnsInitialState;
    case constants.QUICK_ADD_MODULE:
      return quickAddModuleInitialState;
    case constants.QUICK_LISTING_MODULE:
      return quickListingModuleInitialState;
    case constants.RECENT_DEBIT_NOTE:
      return recentDebitNotesInitialState;
    case constants.RECENT_CREDIT_NOTE:
      return recentCreditNotesInitialState;
    case constants.RECENT_INVOICE:
      return recentInvoicesInitialState;
    case constants.RECENT_ORDER:
      return recentOrdersInitialState;
    case constants.RECENT_PAYMENT_OUT:
      return recentPaymentOutsInitialState;
    case constants.RECENT_PAYMENT:
      return recentPaymentsInitialState;
    case constants.RECENT_PURCHASE_INVOICE:
      return recentPurchaseInvoicesInitialState;
    case constants.RECENT_PURCHASE_ORDER:
      return recentPurchaseOrdersInitialState;
    case constants.RECENT_PURCHASE_RETURN:
      return recentPurchaseReturnsInitialState;
    case constants.RECENT_RETURN:
      return recentReturnsInitialState;
    case constants.RECON_ENTRY:
      return reconEntriesInitialState;
    case constants.RECONCILIATION:
      return reconciliationsInitialState;
    case constants.REPORT:
      return reportReducerInitialState;
    case constants.REPORTS:
      return reportsInitialState;
    case constants.RETURN:
      return returnsInitialState;
    case constants.STATISTIC:
      return statisticsInitialState;
    case constants.SALES_CHART:
      return salesChartInitialState;
    case constants.SALES_PERFORMANCE:
      return salesPerformanceInitialState;
    case constants.SETTINGS:
      return settingsReducerInitialState;
    case constants.SETTING:
      return settingsInitialState;
    case constants.STOCK_ENTRY:
      return stockEntriesInitialState;
    case constants.STOCK_TRANSFER:
      return stockTransfersInitialState;
    case constants.STOCK:
      return stocksInitialState;
    case constants.SUPPLIER:
      return suppliersInitialState;
    case constants.TAX_CATEGORY:
      return taxCategoriesInitialState;
    case constants.TAX_RATE:
      return taxRatesInitialState;
    case constants.TAX:
      return taxesInitialState;
    case constants.USER_ROLE:
      return userRolesInitialState;
    case constants.USERS_GROUP:
      return usersGroupsInitialState;
    case constants.USER:
      return usersInitialState;
    case constants.VOUCHER_BOOK:
      return voucherBooksReducerInitialState;
    case constants.WAREHOUSE:
      return warehousesInitialState;
    case constants.ZONE:
      return zonesInitialState;
    default:
      return reducerState;
  }
};
