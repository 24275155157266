import { fromJS } from 'immutable';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_RECONCILIATIONS, ADD_RECONCILIATION, RECONCILIATION_FETCHED, RECONCILIATION_UPDATED,
  RECONCILIATION_DELETED, RESET_RECONCILIATIONS, defaultSearchParams, moduleName,
} from '@constants/accounting/reconciliations';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'position',
  sort_order: 'asc',
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function reconciliations(state = initialState, action) {
  switch (action.type) {
    case SET_RECONCILIATIONS: return setItems(state, action);
    case ADD_RECONCILIATION: return addItem(state, action);
    case RECONCILIATION_FETCHED: return fetchItem(state, action);
    case RECONCILIATION_UPDATED: return updateItem(state, action);
    case RECONCILIATION_DELETED: return deleteItem(state, action);

    // WHY  this to ensure deep level copying is done properly, because if we directly use the
    //      initialState obj, redux shallowly copies the initialState into the currentState, so
    //      filters (search_params) applied in the previous pages won't be cleared when user reaches
    //      the recons index page
    case RESET_RECONCILIATIONS: { return fromJS(initialState).toJS(); }
    default:
      return state;
  }
}
