import { HOME_BREADCRUMB_LISTING } from './common';

export const BANK = 'BANK';
export const SET_BANKS = 'SET_BANKS';
export const ADD_BANK = 'ADD_BANK';
export const BANK_FETCHED = 'BANK_FETCHED';
export const BANK_UPDATED = 'BANK_UPDATED';
export const BANK_DELETED = 'BANK_DELETED';
export const AUTOCOMPLETE_BANKS = 'AUTOCOMPLETE_BANKS';

export const FORM_INFORMATION_TITLE = 'BANK INFORMATION';
export const RESET_BANK_ERRORS = 'RESET_BANK_ERRORS';
export const ADD_NEW_BANK = 'Add New Bank';

export const title = 'Banks';
export const singularTitle = 'Bank';
export const moduleName = 'banks';
export const baseUrl = '/banks';
export const redirectUrl = '/banks';
export const listingDataKey = 'banks';
export const itemDataKey = 'bank';

export const bankConstants = {
  item: BANK,
  setItems: SET_BANKS,
  addItem: ADD_BANK,
  setItem: BANK_FETCHED,
  updateItem: BANK_UPDATED,
  deleteItem: BANK_DELETED,
  autocompleteItems: AUTOCOMPLETE_BANKS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

export const Title = 'Banks';
export const TargetBaseUrl = 'banks';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
