import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { SET_AUDIT_HISTORY, LOAD_MORE_AUDIT_HISTORY } from '../constants/audit_history';
import { setItems, updateObject } from './common';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

export const auditHistoryInitialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 10,
  search_params: {},
  audit_history_entries_left: 0,
};

export function addMoreAuditHistory(state, action) {
  let updated_action_state = { ...action };
  updated_action_state = omit(updated_action_state, 'type');
  if (action.search_params) {
    updated_action_state.search_params = { ...state.search_params, ...action.search_params };
  }
  if (!isEmpty(action.items)) {
    const { total_count } = action;
    const audit_history_entries = [...state.items, ...action.items];
    const audit_history_entries_left = total_count - audit_history_entries.length;
    updated_action_state.items = audit_history_entries;
    updated_action_state.audit_history_entries_left = audit_history_entries_left;
  }

  return updateObject(state, updated_action_state);
}

export default function auditHistory(state = auditHistoryInitialState, action) {
  switch (action.type) {
    case SET_AUDIT_HISTORY: return setItems(state, action);
    case LOAD_MORE_AUDIT_HISTORY: return addMoreAuditHistory(state, action);
    default:
      return state;
  }
}
