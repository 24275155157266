import { UNAUTHENTICATED } from '@src/constants/auth';
import {
  addErrors, addWarnings, dispatchCurrentUser, resetReducerAllState,
} from '@utils/handle_errors_helpers';
import { UNPROCESSABLE_ENTITY_ERROR_NOTIFICATION_MESSAGE } from '@constants/common';
import history from '@component/history';
import {
  clearAllLocalStorage, setCurrentUrlInSessionStorage,
  getCurrentUrl, getCurrentAnchor, clearAuthDetailsFromBrowserStorage, getSubDomain, getDomain, getMainAccountTargetUrl,
} from '@helpers/common_helper';
import { deleteAllCookies, deleteCookie } from '@helpers/browserStorageManager';
import { RECENT_USED_TENANT } from './common_constants';

// This function automatically call before they are handled by then or catch.
// export const globalResponseInterceptor = axios.interceptors.response.use((response) => {
// After successfull api response, we call fetchingRequest(false);
// this function take single argument ie. false.
// fetchingRequest(false);

// return response;
// }, error =>
// Do something with response error
// Promise.reject(error));

// We can handle all possible api errors and exception in below function.
// subModuleName args is usefull in offers module. ex. offers: { invoices: {}, payments: { } }
export function handleErrors(error, redirectPath, moduleName, shouldRedirect = true, subModuleName = '') {
  const { response } = error;
  const statusText = (response?.statusText) || '';
  if (response) {
    switch (response.status) {
      // Unauthenticated
      case 401: {
        const { errors } = response?.data || {};
        const meta = response?.data?.meta?.alert || '';
        const data = (error?.response?.data) || {};
        const notificationMessage = (errors?.status) || meta || statusText || UNAUTHENTICATED;
        // Below condition are usefull for to avoid Authentication error notification
        // also we stop clearing localstorage data , cookies data for '/sign_up', '/account/password', '/account/confirmation' urls.
        if (!(window.location.hash.includes('#/sign_up') || window.location.hash.includes('#/account/password') || window.location.hash.includes('#/account/confirmation'))) {
          addErrors(data, notificationMessage, moduleName, subModuleName);
          // clearAuthDetailsFromBrowserStorage();
          resetReducerAllState();
          clearAllLocalStorage();
          // const tenantName = getCookie(RECENT_USED_TENANT);
          deleteAllCookies();
          // const domain = getDomain();
          // setCookie(RECENT_USED_TENANT, tenantName, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
        }

        if (redirectPath !== '/login') {
          dispatchCurrentUser(false, {}, true, errors);
        }
        break;
      }
      // Unauthorized
      case 403: {
        const { data } = response;
        const notificationMessage = data.errors;
        addErrors(data, notificationMessage, moduleName, subModuleName);
        const hasSubDomain = !!getSubDomain();

        if (shouldRedirect) {
          if (hasSubDomain && (data?.errors === 'Access Denied')) {
            deleteCookie(RECENT_USED_TENANT);
            const targetUrl = getMainAccountTargetUrl();
            window.location.href = targetUrl;
            window.location.href = targetUrl;
          } else {
            history.push('/');
          }
        }
        break;
      }
      // Not Found
      case 404: {
        const currentUrl = getCurrentUrl();
        const currentURLAnchor = getCurrentAnchor();
        if (currentURLAnchor !== '#/404') {
          setCurrentUrlInSessionStorage(currentUrl);
        }
        const data = response?.data || {};
        const notificationMessage = ((data?.errors) || statusText || '');
        addErrors(data, notificationMessage, moduleName, subModuleName);

        const hasSubDomain = !!getSubDomain();

        if (hasSubDomain && (response?.headers?.['error-message'] === 'Tenant Not Found')) {
          deleteCookie(RECENT_USED_TENANT);
          const targetUrl = getMainAccountTargetUrl();
          window.location.href = targetUrl;
        } else {
          history.replace('/404');
        }
        break;
      }
      // Unprocessable Entity
      case 400:
      case 422: {
        if (redirectPath === '/login') {
          const data = response?.data || {};
          const meta = data.meta || {};
          const alert = meta?.alert || '';
          addErrors({ message: alert }, alert, moduleName, subModuleName);
        } else {
          const { data } = response;
          let notificationMessage = UNPROCESSABLE_ENTITY_ERROR_NOTIFICATION_MESSAGE;

          if (data?.errors?.alert) {
            notificationMessage = data.errors.alert;
          }

          if (data?.errors?.base) {
            notificationMessage = data.errors.base;
          }

          if (data?.errors && (typeof data.errors === 'string')) {
            notificationMessage = data.errors;
          }

          if (data?.error) {
            notificationMessage = data.error;
          }

          let errors = data?.errors || {};
          if (!data?.errors) {
            errors = data;
          }

          addErrors(errors, notificationMessage, moduleName, subModuleName);
          addWarnings(data.warnings || {}, moduleName, subModuleName);
        }
        break;
      }
      case 500: {
        const { data } = response;
        const notificationMessage = (data?.errors || statusText);
        addErrors(data, notificationMessage, moduleName, subModuleName);
        break;
      }
      default: {
        const notificationMessage = (error?.message || statusText);
        addErrors(error.message, notificationMessage, moduleName, subModuleName);
        break;
      }
    }
  } else {
    const notificationMessage = (error?.message || statusText || '');
    addErrors(error.message, notificationMessage, moduleName, subModuleName);
  }
}
