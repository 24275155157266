import axios from '@utils/axios';
import { getActionTypes } from '@actions/action_helpers';
import { moduleName } from '@constants/credit_notes';
import { serializeSearchParams } from '@utils/SerializeParams';
import { handleErrors } from '@utils/handleErrors';

// autocompleteCreditNotes
export function autocompleteCreditNotes(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params } = args;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteCreditableReturnItems(args: any) {
  const {
    search_params, party_id, warehouse_id, product_id, credit_note_item_id,
  } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/creditable_return_items?${q}`,
    method: 'get',
    params: {
      party_id, warehouse_id, product_id, credit_note_item_id,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}
