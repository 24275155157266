import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_E_WAY_BILL, FETCH_E_WAY_BILL_MASTER_DATA, CANCEL_E_WAY_BILL,
  AUTOCOMPLETE_E_WAY_BILL_CANCELLATION_REASONS,
} from '@component/common/e_way_bill/constants';
import {
  autocompleteEWayBillCancellationReasons, createEWayBillAction,
  fetchEWayBillMastersDataAction, generateEWAYBillAction, cancelEWayBill, cancelEWayBillAction,
} from '@component/common/e_way_bill/actions';
import { getActionTypes } from '@actions/action_helpers';
import {
  FetchEWayBillMastersDataSagaFuncTypes, CreateEWayBillSagaFuncTypes, autocompleteEInvoiceCancellationReasonsType, CancelEInvoiceType,
} from '@component/common/e_way_bill/types';

function* fetchEWayBillMastersDataFunc(action: FetchEWayBillMastersDataSagaFuncTypes) {
  const { moduleName, redirectUrl } = action;

  try {
    const response = yield call(fetchEWayBillMastersDataAction({ moduleName, redirectUrl }));
    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* createEWayBillFunc(action: CreateEWayBillSagaFuncTypes) {
  const {
    moduleName, voucherModuleName, redirectUrl, e_way_bill: eWayBill,
  } = action;

  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;

  try {
    const response = yield call(createEWayBillAction({
      moduleName, redirectUrl, e_way_bill: eWayBill,
    }));

    yield put(generateEWAYBillAction({ id: eWayBill?.voucher_id, item, response }));
    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* cancelEWayBillFunc(action: CancelEInvoiceType) {
  const {
    moduleName, voucherId, id, cancellation_reason, cancellation_remarks,
  } = action;

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;

  try {
    const response = yield call(cancelEWayBill({
      moduleName, id, cancellation_reason, cancellation_remarks,
    }));

    yield put(cancelEWayBillAction({ id: voucherId, item, response }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompleteEWayBillCancellationReasonsFunc(
  action: autocompleteEInvoiceCancellationReasonsType,
) {
  const { moduleName } = action;

  try {
    const response = yield call(autocompleteEWayBillCancellationReasons({ moduleName }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* eWayBillWatcher() {
  yield takeLatest(FETCH_E_WAY_BILL_MASTER_DATA, fetchEWayBillMastersDataFunc);
  yield takeLatest(CREATE_E_WAY_BILL, createEWayBillFunc);
  yield takeLatest(CANCEL_E_WAY_BILL, cancelEWayBillFunc);
  yield takeLatest(AUTOCOMPLETE_E_WAY_BILL_CANCELLATION_REASONS, autocompleteEWayBillCancellationReasonsFunc);
}
