import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_E_INVOICE_CREDENTIAL, UPDATE_E_INVOICE_CREDENTIAL } from '@constants/credentials/e_invoice';
import { FETCH_E_WAY_BILL_CREDENTIAL, UPDATE_E_WAY_BILL_CREDENTIAL } from '@constants/credentials/e_way_bill';
import {
  fetchEInvoiceCredential as fetchEInvoiceCredentialApi,
  updateEInvoiceCredential as updateEInvoiceCredentialApi,
  fetchEWayBillCredential as fetchEWayBillCredentialApi,
  updateEWayBillCredential as updateEWayBillCredentialApi,
} from '@api/credentials';
import { setEInvoiceCredentialAction, setEWayBillCredentialAction } from '@actions/credentials';

function* fetchEInvoiceCredential(action: any) {
  try {
    const response = yield call(fetchEInvoiceCredentialApi());
    const { in_gsp_credential_e_invoice } = response?.data || {};
    yield put(setEInvoiceCredentialAction({ in_gsp_credential_e_invoice }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* updateEInvoiceCredential(action: any) {
  const { in_gsp_credential_e_invoice: inGspCredentialEInvoice } = action;
  try {
    const response = yield call(updateEInvoiceCredentialApi({
      in_gsp_credential_e_invoice: inGspCredentialEInvoice,
    }));
    const { in_gsp_credential_e_invoice } = response?.data || {};
    yield put(setEInvoiceCredentialAction({ in_gsp_credential_e_invoice }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchEWayBillCredential(action: any) {
  try {
    const response = yield call(fetchEWayBillCredentialApi());
    const { in_gsp_credential_e_way_bill } = response?.data || {};
    yield put(setEWayBillCredentialAction({
      in_gsp_credential_e_way_bill,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* updateEWayBillCredential(action: any) {
  const { in_gsp_credential_e_way_bill: inGspCredentialEWayBill } = action;
  try {
    const response = yield call(updateEWayBillCredentialApi({
      in_gsp_credential_e_way_bill: inGspCredentialEWayBill,
    }));
    const { in_gsp_credential_e_way_bill } = response?.data || {};
    yield put(setEWayBillCredentialAction({ in_gsp_credential_e_way_bill }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* credentialsWatcher() {
  yield takeLatest(FETCH_E_INVOICE_CREDENTIAL, fetchEInvoiceCredential);
  yield takeLatest(UPDATE_E_INVOICE_CREDENTIAL, updateEInvoiceCredential);
  yield takeLatest(FETCH_E_WAY_BILL_CREDENTIAL, fetchEWayBillCredential);
  yield takeLatest(UPDATE_E_WAY_BILL_CREDENTIAL, updateEWayBillCredential);
}
