import axios, { fetchingRequest } from '@utils/axios';
import { GET } from '@utils/axios_methods';
import * as types from '@constants/accounting/accounts';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { handleErrors } from '@utils/handleErrors';

export function autocompleteAccountingAccountName(args: any) {
  const { search_params, id, apiEndPointUrl } = args;
  const q = serializeSearchParams(search_params);

  let redirectTo = `${types.baseUrl}/new`;
  if (args.id) {
    redirectTo = `${types.baseUrl}/${id}`;
  }
  let url = `${types.baseUrl}/autocomplete_ledgers?${q}`;
  if (apiEndPointUrl) {
    url = `${apiEndPointUrl}?${q}`;
  }
  return () => GET({
    url,
    error: { redirect_to: redirectTo, module_name: types.CHART_OF_ACCOUNT },
  });
}

// This function are use in container ComponentDidMount() callback function.
export function fetchAccountingAccountBasicInfo(args: any) {
  const { search_params, page, per_page } = args;
  const pageParams = serializePageParams(page, per_page);
  const q = serializeSearchParams(search_params);
  fetchingRequest(types.CHART_OF_ACCOUNT);
  return () => axios({
    url: `${types.baseUrl}/autocomplete?${pageParams}&${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, types.redirectUrl, types.CHART_OF_ACCOUNT);
      throw error.response;
    });
}
