import { SET_DUE_INVOICE_CHART, INITIAL_STATE, getxAxisDataKey } from '../constants/charts';

export const initialState = {
  ...INITIAL_STATE,
  total_amount: '',
  brand_id: '',
};

export default function dueInvoiceChart(state = initialState, action) {
  switch (action.type) {
    case SET_DUE_INVOICE_CHART: {
      return {
        period: action.period,
        xAxisDataKey: getxAxisDataKey(action.period),
        total_amount: action.total_amount,
        data: action.data,
        start_date: action.start_date,
        end_date: action.end_date,
        description: action.description,
        brand_id: action.brand_id,
      };
    }
    default:
      return state;
  }
}
