import GeneralRoutes from '@routes/GeneralRoutes';
import OrderRoutes from '@routes/OrderRoutes';
import CatalogueRoutes from '@routes/CatalogueRoutes';
import InventoryRoutes from '@routes/InventoryRoutes';
import MasterRoutes from '@routes/MasterRoutes';
import FinanceRoutes from '@routes/FinanceRoutes';
import OffersRoutes from '@routes/OffersRoutes';
import BeatRouteRoutes from '@routes/BeatRouteRoutes';
import ReportRoutes from '@routes/ReportRoutes';
import UtilsRoutes from '@routes/UtilsRoutes';
import AccessManagement from '@routes/AccessManagement';
import Accounting from '@routes/Accounting';
import FeedRoutes from '@routes/FeedRoutes';
import ConfigRoutes from '@routes/ConfigRoutes';

const AppRoutes = [
  ...GeneralRoutes,
  ...OrderRoutes,
  ...CatalogueRoutes,
  ...InventoryRoutes,
  ...MasterRoutes,
  ...FinanceRoutes,
  ...OffersRoutes,
  ...BeatRouteRoutes,
  ...ReportRoutes,
  ...UtilsRoutes,
  ...AccessManagement,
  ...Accounting,
  ...FeedRoutes,
  ...ConfigRoutes,
];

export default AppRoutes;
