import { POST, GET, PUT } from '@utils/axios_methods';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

interface GenerateEInvoiceDataInterface {
  moduleName: string;
  voucher_id: number | string;
  voucher_type: string;
}

interface CancelEInvoiceInterface {
  moduleName: string;
  id: number | string;
  cancellation_reason: string;
  cancellation_remarks: string;
}

interface EInvoiceCancellationReasonsInterface {
  moduleName: string;
}

// Generate e-invoice.
export function generateEInvoice(data: GenerateEInvoiceDataInterface) {
  const { moduleName, voucher_id, voucher_type } = data;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  const url = '/in/e_invoices';
  return () => POST({
    url,
    data: { in_e_invoice: { voucher_id, voucher_type } },
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  })
    .then((response) => response);
}

// Cancel e-invoice.
export function cancelEInvoice(data: CancelEInvoiceInterface) {
  const {
    moduleName, id, cancellation_reason, cancellation_remarks,
  } = data;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  const url = `/in/e_invoices/${id}/cancel`;
  return () => PUT({
    url,
    data: {
      in_e_invoice: {
        id, cancellation_reason, cancellation_remarks,
      },
    },
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  })
    .then((response) => response);
}

// Autocomplete E-Invoice Cancellation Reasons
export function autocompleteEInvoiceCancellationReasons(
  data: EInvoiceCancellationReasonsInterface,
) {
  const { moduleName } = data;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const url = '/in/e_invoices/cancellation_reasons';
  return () => GET({
    url,
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  })
    .then((response) => response);
}
