export const getNestedFormName = ({ moduleName }) => {
  switch (moduleName) {
    case 'orders':
      return ['order_items'];
    case 'invoices':
      return ['invoice_items'];
    case 'payments':
      return ['invoice_payments', 'debit_note_settlements'];
    case 'returns':
      return ['return_items'];
    case 'credit_notes':
      return ['credit_note_items', 'credit_note_invoices', 'debit_note_settlements'];
    case 'debit_notes':
      return ['debit_note_items', 'debit_note_purchase_invoices'];
    case 'customers':
      return ['default_address', 'primary_contact', 'customer_specific_taxes'];
    case 'purchase_orders':
      return ['purchase_order_items', 'order_item_purchase_order_items'];
    case 'purchase_invoices':
      return ['purchase_invoice_items', 'purchase_invoice_taxes'];
    case 'payment_outs':
      return ['purchase_invoice_payments'];
    case 'purchase_returns':
      return ['purchase_return_items'];
    case 'suppliers':
      return ['default_address', 'primary_contact', 'supplier_specific_taxes'];
    case 'branches':
      return ['address', 'branch_zone_taxes', 'branch_warehouses'];
    case 'journal_entries':
      return ['debit_amounts', 'credit_amounts'];
    case 'stocks':
      return ['opening_stock_entry'];
    case 'stock_transfers':
      return ['stock_transfer_items'];
    case 'products':
      return ['current_prices', 'product_packages'];
    case 'warehouses':
      return ['address'];
    case 'customer_ratings':
      return ['credit_control_factors'];
    case 'tax_categories':
      return ['tax_category_tax_rates'];
    case 'taxes':
      return ['tax_rates'];
    case 'voucher_books':
      return ['number_format'];
    case 'beat_routes':
      return ['beat_route_assignments'];
    case 'offers':
      return ['offer_benefits'];
    case 'inventory_batches':
      return ['inventory_manufacturer_batch'];
    case 'inventory_pieces':
      return ['inventory_manufacturer_piece'];
    default:
      return [];
  }
};
