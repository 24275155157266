export const CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS';
export const SET_CUSTOMER_ADDRESSES = 'SET_CUSTOMER_ADDRESSES';
export const ADD_CUSTOMER_ADDRESS = 'ADD_CUSTOMER_ADDRESS';
export const CUSTOMER_ADDRESS_FETCHED = 'CUSTOMER_ADDRESS_FETCHED';
export const CUSTOMER_ADDRESS_UPDATED = 'CUSTOMER_ADDRESS_UPDATED';
export const CUSTOMER_ADDRESS_DELETED = 'CUSTOMER_ADDRESS_DELETED';
export const CUSTOMER_ADDRESS_EDIT = 'CUSTOMER_ADDRESS_EDIT';
export const AUTOCOMPLETE_CUSTOMER_ADDRESSES = 'AUTOCOMPLETE_CUSTOMER_ADDRESSES';

export const OPEN_CUSTOMER_ADDRESS_FORM_MODAL = 'OPEN_CUSTOMER_ADDRESS_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Customer Addresses';
export const singularTitle = 'Customer address';
export const moduleName = 'customer_addresses';
export const baseUrl = '/customer_addresses';
export const redirectUrl = '/customer_addresses';
export const listingDataKey = 'customer_addresses';
export const itemDataKey = 'customer_address';
export const permissionKey = 'customer/addresses';

export const customerAddressConstants = {
  item: CUSTOMER_ADDRESS,
  setItems: SET_CUSTOMER_ADDRESSES,
  addItem: ADD_CUSTOMER_ADDRESS,
  setItem: CUSTOMER_ADDRESS_FETCHED,
  updateItem: CUSTOMER_ADDRESS_UPDATED,
  deleteItem: CUSTOMER_ADDRESS_DELETED,
  autocompleteItems: AUTOCOMPLETE_CUSTOMER_ADDRESSES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
