import React from 'react';
import { filter, find } from 'lodash';
import { moduleMappingWithClass } from '@component/subscription/enforcement/helper';
import { planCodeMappingWithName } from '@src/components/subscription/constants';
import { Link } from 'react-router-dom';
import store from '../store/store';

interface Props {
  moduleName: string,
  value: any,
  isLoading: boolean,
  labelKey: string,
  items: any,
  showCustomNoResultsText: boolean,
  noResultsText: boolean,
  handleNoResultsText(): any,
}

export const getNoResultText = ({
  moduleName, value, isLoading, labelKey, items, showCustomNoResultsText, noResultsText,
  handleNoResultsText,
}: Props) => {
  // WHY  converting i[labelKey] to string as number doesnot have includes function
  const new_items = filter(items, (i) => (i && i[labelKey] && (`${i[labelKey]}`).includes(value)));
  if (isLoading) {
    return 'Fetching...';
  }

  if (value.length < 2) {
    return 'Start typing...';
  }

  if (showCustomNoResultsText && value && (new_items?.length <= 0)) {
    const beClassName = moduleMappingWithClass?.[moduleName];

    const storeData = store?.getState();
    const subscriptions: any = storeData?.subscriptions || {};
    const features = subscriptions?.features || {};
    const feature = find(features?.items, { name: beClassName });
    const upgradePlan = feature?.meta?.upgrade_plan || '';

    return (
      <>
        No result found
        <br />
        <a
          onClick={upgradePlan ? () => ({}) : handleNoResultsText}
          data-cy={`${moduleName}-no-result-text-link`}
          style={upgradePlan ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
          role="presentation"
          className={upgradePlan ? 'dms-select-no-result-text' : ''}
        >
          {noResultsText}
        </a>
        {upgradePlan && (
          <div className="dms-select-upgrade-to-new-plan-text">
            Please upgrade to
            {' '}
            <Link to="/billing/pricing">{planCodeMappingWithName?.[upgradePlan]}</Link>
            {' '}
            Plan.
          </div>
        )}
      </>
    );
  }

  if (value && (new_items.length <= 0)) {
    return 'No result found';
  }

  return '';
};

getNoResultText.defaultProps = {
  value: '',
  isLoading: false,
  labelKey: undefined,
  items: [],
  showCustomNoResultsText: false,
  noResultsText: 'No result found',
  handleNoResultsText: () => ({}),
};
