import { SET_BALANCE_SHEET } from '../constants/reports';

export const initialState = {
  data: {},
  to_date: '',
  isFetching: false,
  isError: false,
  errors: {},
};

export default function balanceSheet(state = initialState, action) {
  switch (action.type) {
    case SET_BALANCE_SHEET: {
      return {
        ...state,
        data: action.data,
        to_date: action.to_date || '',
        isFetching: false,
        isError: false,
        errors: {},
      };
    }
    default:
      return state;
  }
}
