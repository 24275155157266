import axios from '@utils/axios';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { handleErrors } from '@utils/handleErrors';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

// fetch addresses
export function fetchAddresses(args) {
  const { moduleName, url, redirectUrl } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${url}?${pageParams}&${q}`,
    method: 'get',
    params: { sort_by, order: sort_order },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// // save Address.
export function saveAddress(args) {
  const {
    url, redirectUrl, moduleName, address,
  } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({ url, method: 'post', data: { address } })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// // Get Address.
export function fetchAddress(args) {
  const { url, redirectUrl, moduleName } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({ url, method: 'get' })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// // Update address.
export function updateAddress(args) {
  const {
    url, redirectUrl, moduleName, address,
  } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({ url, method: 'put', data: { address } })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// // Delete Address.
export function deleteAddress(args) {
  const { url, redirectUrl, moduleName } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({ url, method: 'delete' })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}
