import {
  SET_OFFERS, ADD_OFFER, OFFER_FETCHED, OFFER_UPDATED, OFFER_DELETED,
  defaultSearchParams, UPDATE_ELIGIBLE_CUSTOMERS_COUNT, UPDATE_APPLICABLE_ON_PRODUCTS_COUNT,
} from '@constants/offers';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

// eslint-disable-next-line import/no-mutable-exports
export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  timePeriod: 'Custom',
  sort_by: '',
  sort_order: '',
  search_params: { ...defaultSearchParams },
};

export default function offers(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_OFFERS: return setItems(state, action);
    case ADD_OFFER: return addItem(state, action);
    case OFFER_FETCHED: return fetchItem(state, action);
    case OFFER_UPDATED: return updateItem(state, action);
    case OFFER_DELETED: return deleteItem(state, action);
    case UPDATE_ELIGIBLE_CUSTOMERS_COUNT: {
      const moduleItems = (state?.items) || [];
      const updatedState = {
        items: moduleItems.map((item) => {
          if (item.id !== action.id) return item;
          return { ...item, eligible_customers_count: action.eligible_customers_count };
        }),
      };
      return { ...state, ...updatedState };
    }
    case UPDATE_APPLICABLE_ON_PRODUCTS_COUNT: {
      const moduleItems = (state?.items) || [];
      const updatedState = {
        items: moduleItems.map((item) => {
          if (item.id !== action.id) return item;
          return { ...item, ...action?.products_count_params };
        }),
      };
      return { ...state, ...updatedState };
    }
    default:
      return state;
  }
}
