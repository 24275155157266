import { map } from 'lodash';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_PRODUCTS, ADD_PRODUCT, PRODUCT_FETCHED, PRODUCT_UPDATED, PRODUCT_DELETED,
  SET_PRODUCTS_RETURNABLE_BY_CUSTOMER, SET_ORDERABLE_PRODUCTS, SET_SELLABLE, ADD_VARIANT,
  defaultSearchParams, FEED_FILE_UPLOADED, AUTOCOMPLETE_PRODUCTS, moduleName,
} from '../constants/products';
import {
  setItems, addItem, fetchItem, deleteItem, autocompleteUnions,
} from './common';
import { setAndGetIndexRelatedParams } from '../components/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

// Note: We can't use common updateItem function because PRODUCT_UPDATED different from other.
export default function products(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTS: return setItems(state, action);
    case ADD_PRODUCT: return addItem(state, action);
    case PRODUCT_FETCHED: return fetchItem(state, action);
    case PRODUCT_DELETED: return deleteItem(state, action);
    case FEED_FILE_UPLOADED: return { ...state, isFetching: false };
    case SET_ORDERABLE_PRODUCTS: return autocompleteUnions(state, action);
    case SET_PRODUCTS_RETURNABLE_BY_CUSTOMER: return autocompleteUnions(state, action);
    case SET_SELLABLE: return autocompleteUnions(state, action);
    case AUTOCOMPLETE_PRODUCTS: return autocompleteUnions(state, action);
    case ADD_VARIANT: {
      return {
        ...state,
        items: map(state.items, (item) => {
          if (item.id === action.parent_id) {
            let parentProduct = item;
            const parentProductVariants = item?.variants || [];
            parentProductVariants?.push(action.item);
            parentProduct = { ...parentProduct, variants: parentProductVariants };
            return parentProduct;
          }
          return item;
        }),
        isFetching: false,
        isError: false,
        errors: {},
      };
    }
    case PRODUCT_UPDATED: {
      return {
        ...state,
        items: map(state.items, (item) => {
          if (item.id === action.item.id) {
            return action.item;
          } if (action.item.parent_id && (item.id === action.item.parent_id)) {
            const updated_variants = map(item.variants, (variant) => {
              if (variant.id === action.item.id) return action.item;
              return variant;
            });
            return { ...item, variants: updated_variants };
          }
          return item;
        }),
        isFetching: false,
        isError: false,
        errors: {},
      };
    }
    default:
      return state;
  }
}
