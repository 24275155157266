import { HOME_BREADCRUMB_LISTING } from './common';

export const PURCHASE_RETURN = 'PURCHASE_RETURN';
export const SET_PURCHASE_RETURNS = 'SET_PURCHASE_RETURNS';
export const ADD_PURCHASE_RETURN = 'ADD_PURCHASE_RETURN';
export const PURCHASE_RETURN_FETCHED = 'PURCHASE_RETURN_FETCHED';
export const PURCHASE_RETURN_UPDATED = 'PURCHASE_RETURN_UPDATED';
export const PURCHASE_RETURN_DELETED = 'PURCHASE_RETURN_DELETED';
export const PURCHASE_RETURN_EDIT = 'PURCHASE_RETURN_EDIT';
export const AUTOCOMPLETE_PURCHASE_RETURNS = 'AUTOCOMPLETE_PURCHASE_RETURNS';

export const FETCH_PURCHASE_RETURNS = 'FETCH_PURCHASE_RETURNS';

export const title = 'Purchase Returns';
export const singularTitle = 'Purchase Return';
export const moduleName = 'purchase_returns';
export const baseUrl = '/purchase_returns';
export const redirectUrl = '/purchase_returns';
export const listingDataKey = 'purchase_returns';
export const itemDataKey = 'purchase_return';

// Views for helper text
export const PURCHASE_RETURN_FORM_VIEW = 'purchase_return_form';

export const purchaseReturnConstants = {
  item: PURCHASE_RETURN,
  setItems: SET_PURCHASE_RETURNS,
  addItem: ADD_PURCHASE_RETURN,
  setItem: PURCHASE_RETURN_FETCHED,
  updateItem: PURCHASE_RETURN_UPDATED,
  deleteItem: PURCHASE_RETURN_DELETED,
  autocompleteItems: AUTOCOMPLETE_PURCHASE_RETURNS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  state_in: [],
  supplier_id_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  user_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_supplier_name_or_supplier_gstin_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['suppliers', 'branches', 'warehouses', 'users', 'voucher_books'];

// Purchase invoice states
export const draft = 'draft';
export const approved = 'approved';

export const PURCHASE_RETURN_STATES = [{ value: draft, label: 'Draft' }, { value: approved, label: 'Approved' }];

// This two accounted and unaccounted debit note status.
// We use this two status in purchase return listing page to show is purchase return has debit note or not.
// If purchase return has debit note we use accounted, otherwise we use unaccounted.
export const accounted = 'accounted';
export const unaccounted = 'unaccounted';
export const DEBIT_NOTE_STATUS = [{ label: 'Accounted', value: accounted }, { label: 'Unaccounted', value: unaccounted }];

// Breadcrumb
export const Title = 'Purchase Returns';
export const TargetBaseUrl = 'purchase_returns';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'supplier', 'supplier_id', 'branch', 'branch_id', 'warehouse', 'warehouse_id', 'notes'],
  purchase_return_items: ['variant', 'variant_id', 'quantity', 'input_quantity_unit', 'price', 'input_price_per_unit'],
};
