import {
  SET_ALL_ROLES, SET_PAYMENT_MODES, SET_ALL_STATES, SET_CLIENT_SETTINGS, SET_GLOBAL_SETTINGS,
  SET_CLIENT_UI_PERMISSIONS, SET_LETTER_OF_CREDIT_TYPES, SET_COUNTRY_CODES, SET_TAX_ID_TYPES,
  SET_COUNTRY_DIAL_CODES,
} from '@constants/settings';

export const initialState = {
  payment_modes: [],
  state_codes: [],
  country_codes: [],
  uniq_country_dial_codes: [],
  roles: [],
  tax_id_types: [],
  client_settings: {},
  global_settings: {},
  client_ui_permissions: { isFetching: false },
  isFetching: false,
  isError: false,
  errors: {},
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_ROLES: {
      return {
        ...state,
        roles: action.roles,
        isFetching: false,
        errors: {},
      };
    }
    case SET_TAX_ID_TYPES: {
      return {
        ...state,
        tax_id_types: action.taxTypes,
        isFetching: false,
      };
    }
    case SET_PAYMENT_MODES: {
      return {
        ...state,
        payment_modes: action.payment_modes,
        isFetching: false,
      };
    }
    case SET_LETTER_OF_CREDIT_TYPES: {
      return {
        ...state,
        letter_of_credit_types: action.letter_of_credit_types,
        isFetching: false,
      };
    }
    case SET_ALL_STATES: {
      let stateCodes = action.state_codes;
      stateCodes = stateCodes.map((element) => ({
        ...element, id: element.value, name: element.label,
      }));
      return {
        ...state,
        state_codes: stateCodes,
        isFetching: false,
      };
    }
    case SET_COUNTRY_CODES: {
      let countryCodes = action.country_codes;
      countryCodes = countryCodes.map((element) => ({
        ...element, id: element.value, name: element.label,
      }));
      return {
        ...state,
        country_codes: countryCodes,
        isFetching: false,
      };
    }
    case SET_COUNTRY_DIAL_CODES: {
      return {
        ...state,
        uniqCountryDialCodes: action?.uniqCountryDialCodes,
        isFetching: false,
      };
    }
    case SET_CLIENT_SETTINGS: {
      return {
        ...state,
        client_settings: action.client_settings,
        isFetching: false,
        errors: {},
      };
    }
    case SET_GLOBAL_SETTINGS: {
      return {
        ...state,
        global_settings: action.global_settings,
        isFetching: false,
        errors: {},
      };
    }
    case SET_CLIENT_UI_PERMISSIONS: {
      return {
        ...state,
        client_ui_permissions: action.client_ui_permissions,
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
