import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sagaPreWatcherAction } from '@actions/common';
import { RESET_MODULE_ERRORS } from '@constants/common_constants';
import { resetQuickAddModule } from '@component/common/quick_add_module/helper';
import { closeQuickListingModal } from '@component/common/quick_listing/helper';
import RollerLoader from '../components/common/RollerLoader';

// Types
type HOCProps = any;
type HOCStateProps = { isLoading: boolean; }
export type PropsFromLoadingHOC = {
  isLoading: boolean;
  handleHocState(newState: HOCStateProps): void;
}

const withHandleIsLoadingState = (
  WrappedComponent: any, moduleNames?: string[], isImmutable = false, showLoader = true,
) => {
  class HOC extends React.Component<HOCProps, HOCStateProps> {
    canSetState: boolean | undefined;

    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
      };
    }

    UNSAFE_componentWillMount() {
      this.canSetState = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      let isLoading;
      const { isLoading: currentStateIsLoading } = this.state;
      if (isImmutable) {
        isLoading = moduleNames?.find((name) => (nextProps[name]
          && nextProps[name].get('isFetching')) === true) !== undefined;
      } else {
        isLoading = moduleNames?.find((name) => (nextProps[name]
          && nextProps[name].isFetching) === true) !== undefined;
      }

      if (isLoading !== currentStateIsLoading) {
        this.setState({ isLoading });
      }
    }

    componentWillUnmount() {
      // RESET MODULE ERRORS
      const { sagaPreWatcherAction, resetModuleErrorsKey, resetSubModuleErrorsKey } = this.props;
      if (resetModuleErrorsKey) {
        sagaPreWatcherAction({
          type: RESET_MODULE_ERRORS,
          payload: {
            resetModuleErrorsKey, resetSubModuleErrorsKey: (resetSubModuleErrorsKey || ''),
          },
        });
      }
      // RESET MODULE ERRORS

      this.canSetState = false;

      // RESET/EMPTY QUICK MODULE PARAMS
      resetQuickAddModule();

      // CLOSE QUICK LISTING MODAL
      closeQuickListingModal();
    }

    handleHocState = (newState: HOCStateProps) => {
      if ((this.canSetState) || (this.canSetState === undefined)) {
        this.setState(newState);
      }
    }

    render() {
      const { isLoading } = this.state;
      return (
        <>
          {
            (showLoader && isLoading)
              ? <RollerLoader />
              : null
          }
          <WrappedComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            isLoading={isLoading}
            handleHocState={(args) => this.handleHocState(args)}
          />
        </>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sagaPreWatcherAction }, dispatch);
  }

  return connect(null, mapDispatchToProps)(HOC);
};

export default withHandleIsLoadingState;
