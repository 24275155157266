import { ADD_BANK } from '@constants/banks';
import { updateAndCloseQuickAddModule } from '@src/components/common/quick_add_module/helper';
import axios from '../utils/axios';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { handleErrors } from '../utils/handleErrors';
import {
  setItems, addItem, updateItem, setItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { getActionTypes, startFetchingRequest } from '../actions/action_helpers';

const moduleName = 'bank_accounts';

// Get BankAccounts List (dispatch, getState)
// args ex. { search_params, per_page, page, sort_by, sort_order }
export function fetchBankAccounts(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { bank_accounts, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: bank_accounts,
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save Bank Account.
export function saveBankAccount(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      bank_account: data,
    },
  })
    .then((response) => {
      const { bank_account } = response.data;
      dispatch(addItem({ moduleName, item: { ...bank_account, bank_ledger_name: `${bank_account.bank_name} ${bank_account.number}` } }));
      const params = { bank_account_id: bank_account.id, bank_account_name: bank_account.bank_name };
      updateAndCloseQuickAddModule({ identifier: ADD_BANK, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Update Bank Account.
export function updateBankAccount(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      bank_account: data,
    },
  })
    .then((response) => {
      const { bank_account } = response.data;
      dispatch(updateItem({ moduleName, item: bank_account }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get bank_accounts.
export function fetchBankAccount(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'moduleName', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { bank_account } = response.data;
      dispatch(setItem({ moduleName, item: bank_account }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Delete Bank Account.
export function deleteBankAccount(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'moduleName', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// This function are use in container ComponentDidMount() callback function.
export function fetchBankAccountsBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'moduleName', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { bank_accounts } = response.data;
      dispatch(autocompleteItems({ moduleName, items: bank_accounts }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompleteBankAccounts(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'moduleName', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}
