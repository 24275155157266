import { HOME_BREADCRUMB_LISTING } from './common';

export const ACCOUNT = 'ACCOUNT';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ACCOUNT_FETCHED = 'ACCOUNT_FETCHED';
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

export const title = 'Accounts';
export const singularTitle = 'Account';
export const moduleName = 'accounts';
export const baseUrl = '/accounts';
export const redirectUrl = '/accounts';
export const listingDataKey = 'accounts';
export const itemDataKey = 'account';

export const accountConstants = {
  item: ACCOUNT,
  setItems: SET_ACCOUNTS,
  addItem: ADD_ACCOUNT,
  setItem: ACCOUNT_FETCHED,
  updateItem: ACCOUNT_UPDATED,
  deleteItem: ACCOUNT_DELETED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const STATEMENT_TIME_PERIOD_KEYS = ['entry_date_gteq', 'entry_date_lteq'];

export const accountable_types = [
  { label: 'Invoice', value: 'Invoice' }, { label: 'Payment', value: 'Payment' },
  { label: 'CreditNote', value: 'CreditNote' },
];

export const defaultSearchParams = {
  customer_name_cont: '',
};

export const Title = 'Accounts';
export const TargetBaseUrl = 'accounting/accounts';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: false }],
);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: true }],
);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
