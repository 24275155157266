// Constants
export const ACCOUNT_ENTRY = 'ACCOUNT_ENTRY';
export const ACCOUNTING_AMOUNT = 'ACCOUNTING_AMOUNT';
export const ACCOUNTING_SUB_ACCOUNT = 'ACCOUNTING_SUB_ACCOUNT';
export const ACCOUNT = 'ACCOUNT';
export const AUDIT_HISTORY = 'AUDIT_HISTORY';
export const CURRENT_USER = 'CURRENT_USER';
export const AUTHORISATION_CONDITION = 'AUTHORISATION_CONDITION';
export const BALANCE_SHEET = 'BALANCE_SHEET';
export const BANK_ACCOUNT = 'BANK_ACCOUNT';
export const BANK = 'BANK';
export const BEAT_ROUTE_ASSIGNMENT = 'BEAT_ROUTE_ASSIGNMENT';
export const BEAT_ROUTE = 'BEAT_ROUTE';
export const BRANCH = 'BRANCH';
export const CHART_OF_ACCOUNT = 'CHART_OF_ACCOUNT';
export const CHART_REDUCER = 'CHART_REDUCER';
export const COLLECTION_PERFORMANCE = 'COLLECTION_PERFORMANCE';
export const COMMENT = 'COMMENT';
export const BRAND = 'BRAND';
export const CONTENT = 'CONTENT';
export const CREDIT_LEEWAY = 'CREDIT_LEEWAY';
export const CREDIT_NOTE_INVOICE = 'CREDIT_NOTE_INVOICE';
export const CREDIT_NOTE = 'CREDIT_NOTE';
export const CREDIT_REASON = 'CREDIT_REASON';
export const CUSTOM_FIELDS = 'CUSTOM_FIELDS';
export const CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS';
export const CUSTOMER_CATEGORY = 'CUSTOMER_CATEGORY';
export const CUSTOMER_CONTACT = 'CUSTOMER_CONTACT';
export const CUSTOMER_RATING = 'CUSTOMER_RATING';
export const CUSTOMER = 'CUSTOMER';
export const DASHBOARD = 'DASHBOARD';
export const DEBIT_NOTE = 'DEBIT_NOTE';
export const DEBIT_REASON = 'DEBIT_REASON';
export const DEFAULT_DAC_ROLE = 'DEFAULT_DAC_ROLE';
export const DOCUMENT_CATEGORY = 'DOCUMENT_CATEGORY';
export const DOCUMENT = 'DOCUMENT';
export const DOWNLOAD = 'DOWNLOAD';
export const DUE_INVOICE_CHART = 'DUE_INVOICE_CHART';
export const E_INVOICE_CREDENTIAL = 'E_INVOICE_CREDENTIAL';
export const E_WAY_BILL_CREDENTIAL = 'E_WAY_BILL_CREDENTIAL';
export const ELIGIBLE_OFFER = 'ELIGIBLE_OFFER';
export const E_WAY_BILL = 'E_WAY_BILL';
export const ERRORS = 'ERRORS';
export const FEED = 'FEED';
export const GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS';
export const INCOME_STATEMENT = 'INCOME_STATEMENT';
export const INVENTORY_TRACKING = 'INVENTORY_TRACKING';
export const INVENTORY_BATCH = 'INVENTORY_BATCH';
export const INTEGRATION = 'INTEGRATION';
export const INVENTORY_IDENTIFICATION = 'INVENTORY_IDENTIFICATION';
export const INVENTORY_PIECE = 'INVENTORY_PIECE';
export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';
export const INVOICE_TERM = 'INVOICE_TERM';
export const ORDER_TERM = 'ORDER_TERM';
export const INVOICE = 'INVOICE';
export const JOURNAL_ENTRY = 'JOURNAL_ENTRY';
export const NOTICE = 'NOTICE';
export const OFFER = 'OFFER';
export const ORDER = 'ORDER';
export const ORGANIZATION = 'ORGANIZATION';
export const PAYMENT_CHART = 'PAYMENT_CHART';
export const PAYMENT_OUT = 'PAYMENT_OUT';
export const PAYMENT = 'PAYMENT';
export const PERIOD_LOCK = 'PERIOD_LOCK';
export const PRODUCT_CATEGORY = 'PRODUCT_CATEGORY';
export const PRODUCT = 'PRODUCT';
export const PRINT_FORMAT = 'PRINT_FORMAT';
export const PURCHASE_INVOICE = 'PURCHASE_INVOICE';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const PURCHASE_ORDER_TERM = 'PURCHASE_ORDER_TERM';
export const QUICK_ADD_MODULE = 'QUICK_ADD_MODULE';
export const QUICK_LISTING_MODULE = 'QUICK_LISTING_MODULE';
export const PURCHASE_RETURN = 'PURCHASE_RETURN';
export const RECENT_DEBIT_NOTE = 'RECENT_DEBIT_NOTE';
export const RECENT_CREDIT_NOTE = 'RECENT_CREDIT_NOTE';
export const RECENT_INVOICE = 'RECENT_INVOICE';
export const RECENT_ORDER = 'RECENT_ORDER';
export const RECENT_PAYMENT_OUT = 'RECENT_PAYMENT_OUT';
export const RECENT_PAYMENT = 'RECENT_PAYMENT';
export const RECENT_PURCHASE_INVOICE = 'RECENT_PURCHASE_INVOICE';
export const RECENT_PURCHASE_ORDER = 'RECENT_PURCHASE_ORDER';
export const RECENT_PURCHASE_RETURN = 'RECENT_PURCHASE_RETURN';
export const RECENT_RETURN = 'RECENT_RETURN';
export const RECON_ENTRY = 'RECON_ENTRY';
export const RECONCILIATION = 'RECONCILIATION';
export const REPORT = 'REPORT';
export const REPORTS = 'REPORTS';
export const RETURN = 'RETURN';
export const ROOT = 'ROOT';
export const STATISTIC = 'STATISTIC';
export const SMS_STATISTIC = 'SMS_STATISTIC';
export const SALES_CHART = 'SALES_CHART';
export const SALES_PERFORMANCE = 'SALES_PERFORMANCE';
export const SETTINGS = 'SETTINGS';
export const SETTING = 'SETTING';
export const STOCK_ENTRY = 'STOCK_ENTRY';
export const STOCK_TRANSFER = 'STOCK_TRANSFER';
export const STOCK = 'STOCK';
export const SUPPLIER = 'SUPPLIER';
export const SUPPLIER_ADDRESS = 'SUPPLIER_ADDRESS';
export const SUPPLIER_CONTACT = 'SUPPLIER_CONTACT';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const TAX_CATEGORY = 'TAX_CATEGORY';
export const TAX_RATE = 'TAX_RATE';
export const TAX = 'TAX';
export const USER_ROLE = 'USER_ROLE';
export const USERS_GROUP = 'USERS_GROUP';
export const USER = 'USER';
export const UTILITIES = 'UTILITIES';
export const VOUCHER_BOOK = 'VOUCHER_BOOK';
export const VISIT = 'VISIT';
export const WAREHOUSE = 'WAREHOUSE';
export const ZONE = 'ZONE';
