import { getActionTypes } from '@actions/action_helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  AUTOCOMPLETE_E_INVOICE_CANCELLATION_REASONS, CANCEL_E_INVOICE,
  GENERATE_E_INVOICE,
} from '@constants/e_invoice';
import { generateEInvoice, cancelEInvoice, autocompleteEInvoiceCancellationReasons } from '@api/e_invoice';
import { cancelEInvoiceAction, generateEInvoiceAction } from '@actions/e_invoice';

function* generateEInvoiceFunc(action: any) {
  const {
    moduleName, voucher_id, voucher_type,
  } = action;

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;

  try {
    const response = yield call(generateEInvoice({
      moduleName, voucher_id, voucher_type,
    }));

    yield put(generateEInvoiceAction({
      id: voucher_id, item, response,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* cancelEInvoiceFunc(action: any) {
  const {
    moduleName, voucherId, id, cancellation_reason, cancellation_remarks,
  } = action;

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;

  try {
    const response = yield call(cancelEInvoice({
      moduleName, id, cancellation_reason, cancellation_remarks,
    }));

    yield put(cancelEInvoiceAction({ id: voucherId, item, response }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompleteEInvoiceCancellationReasonsFunc(action: any) {
  const { moduleName } = action;

  try {
    const response = yield call(autocompleteEInvoiceCancellationReasons({ moduleName }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* eInvoiceWatcher() {
  yield takeLatest(GENERATE_E_INVOICE, generateEInvoiceFunc);
  yield takeLatest(CANCEL_E_INVOICE, cancelEInvoiceFunc);
  yield takeLatest(AUTOCOMPLETE_E_INVOICE_CANCELLATION_REASONS, autocompleteEInvoiceCancellationReasonsFunc);
}
