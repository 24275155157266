import { getActionTypes } from '@actions/action_helpers';
import axios from '@utils/axios';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { getBulkCreateVoucherItemInventoryBatchesApiKey } from '@helpers/inventories_helpers';

export function autocompleteInventoryPieces(args) {
  const { voucherId, url, voucherModuleName } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  return () => axios({
    url: `${url}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, item);
      throw error.response;
    });
}

export function bulkCreateVoucherItemInventoryPieces(args) {
  const {
    url, voucherModuleName, voucherId, data,
  } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl } = moduleTypes;

  const apiDataKey = getBulkCreateVoucherItemInventoryBatchesApiKey(voucherModuleName);

  return () => axios({
    url,
    method: 'post',
    data: { [apiDataKey]: { ...data } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, '');
      throw error.response;
    });
}

export function fetchInventoryPieceAvailableQuantity(args) {
  const {
    url, voucherModuleName, voucherId, productId, warehouseId,
  } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;

  return () => axios({
    url,
    method: 'get',
    params: { product_id: productId, warehouse_id: warehouseId },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, item);
      throw error.response;
    });
}
