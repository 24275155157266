import axios from '../utils/axios';
import { moduleName } from '../constants/orders';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import {
  setItems, addItem, updateItem, deleteItem, setItem,
} from './common';
import { startFetchingRequest, getActionTypes } from './action_helpers';

import * as types from '../constants/orders';

export function fetchBulkOrdersInvoice(orders) {
  return {
    type: types.BULK_ORDERS_INVOICE,
    items: orders,
  };
}

export function updateOrderParams(orderId, params) {
  return {
    type: types.UPDATE_PARAMS,
    itemId: orderId,
    params,
  };
}

export function updateOrdersStatusToProcessing(orderIds) {
  return {
    type: types.UPDATE_ORDERS_STATUS_TO_PROCESSING,
    selected_items_ids: orderIds,
  };
}

// Save Order.
export function saveOrder(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      order: data,
    },
  })
    .then((response) => {
      const { order } = response.data;
      dispatch(addItem({ moduleName, item: order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get Order.
export function fetchOrder(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { order } = response.data;
      dispatch(setItem({ moduleName, item: order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update Order.
export function updateOrder(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      order: data,
    },
  })
    .then((response) => {
      const { order } = response.data;
      dispatch(updateItem({ moduleName, item: order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete Order.
export function deleteOrder(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Not used
export function bulkCancelRemainingQuantity(orderIds) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  let ids = orderIds;
  if (typeof orderIds === 'string') {
    ids = JSON.parse(orderIds);
  }

  return () => axios({
    url: `${baseUrl}/bulk_cancel_remaining_quantity`,
    method: 'put',
    data: {
      order: { order_ids: ids },
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => {
    dispatch(updateOrderParams(id, { popup_info: {} }));
    return axios({
      url: `${baseUrl}/${id}/update_state`,
      method: 'put',
      data: {
        state,
      },
    })
      .then((response) => {
        const { order } = response.data;
        dispatch(updateItem({ moduleName, item: order }));
        return response;
      })
      .catch((error) => {
        const { data } = error.response;
        const { popup_info } = data;
        dispatch(updateOrderParams(id, { popup_info: (popup_info || {}) }));
        handleErrors(error, redirectUrl, item);
        throw error.response;
      });
  };
}

export function fetchOrdersForReport(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  // TODO change url.
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => {
      const { orders, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: orders, current_page: page, total_count, sort_by, sort_order,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompleteOrders(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function duplicateOrder(orderId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    // baseURL: `${BASE_URL}`,
    params: {
      order_id: orderId,
    },
  })
    .then((response) => {
      const { order } = response?.data || {};
      dispatch(setItem({ moduleName, item: order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
