import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  ADD_CUSTOMER_ADDRESS, CUSTOMER_ADDRESS_UPDATED, CUSTOMER_ADDRESS_DELETED, SET_CUSTOMER_ADDRESSES,
  CUSTOMER_ADDRESS_FETCHED,
} from '../constants/customer_addresses';
import {
  setItems, deleteItem, fetchItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 8,
  sort_by: '',
  sort_order: '',
  search_params: {
    line_1_cont: '',
  },
};

export default function customerAddresses(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_ADDRESSES: return setItems(state, action);
    case CUSTOMER_ADDRESS_FETCHED: return fetchItem(state, action);
    case ADD_CUSTOMER_ADDRESS: {
      const isDefaultAddress = action?.item?.default || false;
      const items = state?.items?.map((address) => ({
        ...address, default: isDefaultAddress ? false : address.default,
      }));
      return { ...state, items: [...items, action?.item] };
    }
    case CUSTOMER_ADDRESS_UPDATED: {
      const items = state?.items?.map((address) => {
        if (address?.id !== action?.item?.id) return { ...address, default: false };
        return { ...address, ...action?.item };
      });
      return { ...state, items };
    }
    case CUSTOMER_ADDRESS_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
