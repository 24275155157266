import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { dispatchCurrentUser } from '@utils/handle_errors_helpers';
import axios from './utils/axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select/dist/react-select.css';
import { BASE_URL } from './constants/common';

import store from './store/store';
import Routes from './routes/Routes';
import { handleErrors } from './utils/handleErrors';
import '@assets/css/main.scss';
import '@assets/css/pe-icon-7-stroke.css';
import '@assets/css/custom.scss';
import '@assets/css/show_page.scss';
import '@assets/css/flexbox_changes.scss';
import history from './components/history';
import { handleTenantAccess } from './helpers/tenant_access';

const DistributoWebApp = () => {
  // Check if user is authenticated on mount
  const fetchIsAuthenticatedApi = () => {
    axios({
      url: '/account/is_authenticated',
      method: 'get',
      baseURL: `${BASE_URL}`,
    })
      .then((response) => {
        const { account } = response?.data || {};
        dispatchCurrentUser(true, account, false, {});
        handleTenantAccess({ account });
      })
      .catch((error) => {
        if ((error?.response?.status === 401) && ((window?.location?.href?.includes('organisations')) && !(window?.location?.href?.includes('#/login')))) {
          history.push('/login');
        }
        handleErrors(error, '/login');
      });
  };

  useEffect(() => {
    fetchIsAuthenticatedApi();
  }, []);

  return (
    <Provider store={store}>
      {Routes}
    </Provider>
  );
};

ReactDOM.render(
  <DistributoWebApp />,
  document.getElementById('main-wrapper'),
);
