// import { saveAs } from 'file-saver';
import { call, takeLatest } from 'redux-saga/effects';
import { autocompleteCreditableReturnItems, autocompleteCreditNotes } from '@api/credit_notes';
import axios from '@utils/axios';
import { AUTOCOMPLETE_CREDIT_NOTES, AUTOCOMPLETE_RETURN_ITEM_CREDIT_NOTES, moduleName } from '@constants/credit_notes';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

// Get CreditNotes List (dispatch, getState)
export function fetchCreditNotes(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'GET',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { credit_notes, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData:
        {
          items: credit_notes,
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save CreditNote.
export function saveCreditNote(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'POST',
    data: {
      credit_note: data,
    },
  })
    .then((response) => {
      const { credit_note } = response.data;
      dispatch(addItem({ moduleName, item: credit_note }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get CreditNote.
export function fetchCreditNote(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'GET',
  })
    .then((response) => {
      const { credit_note } = response.data;
      dispatch(setItem({ moduleName, item: credit_note }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update CreditNote.
export function updateCreditNote(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'PUT',
    data: {
      credit_note: data,
    },
  })
    .then((response) => {
      const { credit_note } = response.data;
      dispatch(updateItem({ moduleName, item: credit_note }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Allocate Credit Notes.
export function allocateCreditNotes(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}/allocate`,
    method: 'put',
    data: {
      credit_note: data,
    },
  })
    .then((response) => {
      const { credit_note } = response.data;
      dispatch(updateItem({ moduleName, item: credit_note }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete CreditNote.
export function deleteCreditNote(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'DELETE',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: { state },
  })
    .then((response) => {
      const { credit_note } = response.data;
      dispatch(updateItem({ moduleName, item: credit_note }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function bulkCreateCreditNotes(returnIds) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  let ids = returnIds;
  if (typeof returnIds === 'string') {
    ids = JSON.parse(returnIds);
  }

  return () => axios({
    url: `${baseUrl}/bulk_create`,
    method: 'post',
    data: {
      return_ids: ids,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

function* autocompleteCreditNotesFunc(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(autocompleteCreditNotes({ search_params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompleteCreditableReturnItemsGeneratorFunc(action: any) {
  const {
    search_params, party_id, warehouse_id, product_id, credit_note_item_id,
  } = action;
  try {
    const response = yield call(autocompleteCreditableReturnItems({
      search_params, party_id, warehouse_id, product_id, credit_note_item_id,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* creditNotesWatcher() {
  yield takeLatest(AUTOCOMPLETE_CREDIT_NOTES, autocompleteCreditNotesFunc);
  yield takeLatest(AUTOCOMPLETE_RETURN_ITEM_CREDIT_NOTES, autocompleteCreditableReturnItemsGeneratorFunc);
}
