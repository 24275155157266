export const COMMON = 'common';
export const TO_DATE_KEY = 'to_date';
export const FROM_DATE_KEY = 'from_date';
export const LOCALIZED_SUFFIX_KEY = '_localized';
export const HUMANIZED_SUFFIX_KEY = '_humanized';

// Filter keys
// # Dashboard filters
export const SALESMAN_ID_IN = 'salesman_id_in';
export const STATE_IN = 'address_state_in';
export const BRANCH_ID_IN = 'branch_id_in';
export const BRAND_ID_IN = 'brand_id_in';
export const PRODUCT_CATEGORY_ID_IN = 'product_category_id_in';

// Strings
export const brandFilterNotApplyNotice = 'Brand filters do not apply';
export const Prices = 'Prices';
export const creditControl = 'Credit Control';
export const genericErrorMsg = 'Something went wrong!';
export const supportNumber1 = '+91-8277-20-5444';
export const errorBoundaryTitle = 'Sorry! Something went wrong while trying to open the page.';
export const crashTitle = 'Sorry! Something went wrong on the page.';
export const crashDesc1 = 'We have been notified of this issue, and we are working on it.';
export const crashDesc2 = 'If the problem isn\'t resolved soon, please reach out to our support at ';
export const offlineTitle = 'Something seems wrong! To resolve, Please try the following!';
export const offlineDesc = 'Slow or no internet connection.';
export const offlineDesc2 = 'Check your connection and try again.';
export const autoGenerateCode = 'Auto Generate';
export const openingBalanceTypeCredit = 'Credit';
export const getStatement = 'Get Statement';
export const fromDate = 'From Date';
export const toDate = 'To Date';

export const RESET_MODULE_ERRORS = 'RESET_MODULE_ERRORS';
export const UNSPECIFIED = 'Unspecified';

export const INVALID_INPUT_ERROR_HEADER_TEXT = 'Invalid Input:';
export const INVALID_ACTION_ERROR_HEADER_TEXT = 'Invalid Action:';

// Status codes
export const errStatusCodeToBeLogged = [
  // client errors to be logged
  400, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 421, 423, 424,
  426, 428, 429, 431, 451,

  // Server errors to be logged
  500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511,
];
