import { HOME_BREADCRUMB_LISTING } from './common';

export const TAX = 'TAX';
export const SET_TAXES = 'SET_TAXES';
export const ADD_TAX = 'ADD_TAX';
export const TAX_FETCHED = 'TAX_FETCHED';
export const TAX_UPDATED = 'TAX_UPDATED';
export const TAX_DELETED = 'TAX_DELETED';
export const AUTOCOMPLETE_TAXES = 'AUTOCOMPLETE_TAXES';
export const RESET_TAX_ERRORS = 'RESET_TAX_ERRORS';

export const FORM_INFORMATION_TITLE = 'TAX INFORMATION';
export const ADD_NEW_TAX = 'Add New Tax';

export const title = 'Taxes';
export const singularTitle = 'Tax';
export const moduleName = 'taxes';
export const baseUrl = '/taxes';
export const redirectUrl = '/taxes';
export const listingDataKey = 'taxes';
export const itemDataKey = 'tax';

export const taxConstants = {
  item: TAX,
  setItems: SET_TAXES,
  addItem: ADD_TAX,
  setItem: TAX_FETCHED,
  updateItem: TAX_UPDATED,
  deleteItem: TAX_DELETED,
  autocompleteItems: AUTOCOMPLETE_TAXES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

export const CGST = 'CGST';
export const IGST = 'IGST';
export const SGST = 'SGST';
export const CESS = 'Cess';
export const KeralaFloodCess = 'Kerala Flood Cess';
export const KrishiKalyanCess = 'Krishi Kalyan Cess';
export const SwacchBharatCess = 'Swacch Bharat Cess';
export const TCS = 'Tax Collection at Source';

export const LIST_OF_TAXES = [
  { label: 'CGST', value: 'CGST' },
  { label: 'IGST', value: 'IGST' },
  { label: 'SGST', value: 'SGST' },
  { label: 'Cess', value: 'Cess' },
  { label: 'Kerala Flood Cess', value: 'Kerala Flood Cess' },
  { label: 'Krishi Kalyan Cess', value: 'Krishi Kalyan Cess' },
  { label: 'Swacch Bharat Cess', value: 'Swacch Bharat Cess' },
  { label: 'Tax Collection at Source', value: 'Tax Collection at Source' },
];

export const TAX_RATE_BASED_ON_LIST = [
  { label: 'Value', value: 'value' },
  { label: 'Quantity', value: 'quantity' },
  { label: 'Value and Quantity', value: 'value_and_quantity' },
];

export const APPLICABLE_TO = [
  { label: 'Pan', value: 'PAN' },
  { label: 'Non Pan', value: 'Non-PAN' },
];

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Taxes';
export const TargetBaseUrl = 'taxes';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'applicable_from', 'applicable_tax_ids'],
  tax_rates: ['based_on', 'percentage', 'flat', 'applicable_to'],
};
