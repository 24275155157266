import { filter, findIndex, map } from 'lodash';
import {
  SET_CUSTOMERS, ADD_CUSTOMER, CUSTOMER_FETCHED, CUSTOMER_UPDATED, CUSTOMER_DELETED,
  CUSTOMER_EDIT, DELETE_CUSTOMER_BY_ID, SET_WARNING_BEFORE_MERGEING_CUSTOMER,
  AUTOCOMPLETE_CUSTOMER_WITH_UNION_BY_ID, defaultSearchParams, moduleName,
  UPDATE_CUSTOMER_ADDRESS, CREATE_CUSTOMER_ADDRESS,
} from '@constants/customers';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMERS: return setItems(state, action);
    case ADD_CUSTOMER: return addItem(state, action);
    case CUSTOMER_FETCHED: return fetchItem(state, action);
    case CUSTOMER_EDIT: return fetchItem(state, action);
    case CUSTOMER_UPDATED: return updateItem(state, action);
    case CUSTOMER_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_CUSTOMER_WITH_UNION_BY_ID: return autocompleteUnions(state, action);
    case DELETE_CUSTOMER_BY_ID: {
      return {
        ...state,
        items: filter(state.items, (obj) => action.customer_id !== parseInt(obj.id, 10)),
        isFetching: false,
        isError: false,
        errors: {},
      };
    }
    case SET_WARNING_BEFORE_MERGEING_CUSTOMER: {
      const index = findIndex(state.items, (item) => item.id === action.id);
      let updated_action_state = {};
      if (index > -1) {
        updated_action_state = {
          items: map(state.items, (current_item) => {
            if (current_item.id === action.id) return { ...current_item, warning: action.warning };
            return current_item;
          }),
          isFetching: false,
          isError: false,
          errors: {},
        };
      }
      return { ...state, ...updated_action_state };
    }
    case UPDATE_CUSTOMER_ADDRESS: {
      const isDefaultAddress = action?.payload?.address?.default || false;
      const items = state?.items?.map((customer) => {
        if (customer?.id !== action?.payload?.customer_id) return customer;

        const addresses = customer?.addresses?.map((address) => {
          if (address?.id !== action?.payload?.address?.id) {
            return { ...address, default: isDefaultAddress ? false : address?.default };
          }
          return action?.payload?.address;
        });

        return {
          ...customer,
          addresses,
          default_address: isDefaultAddress ? action?.payload?.address : (customer?.default_address || {}),
        };
      });
      return {
        ...state,
        items,
        isError: false,
        errors: {},
      };
    }
    case CREATE_CUSTOMER_ADDRESS: {
      const isDefaultAddress = action?.payload?.address?.default || false;

      const items = state?.items?.map((customer) => {
        if (customer?.id !== action?.payload?.customer_id) return customer;

        let updateAddresses = customer?.addresses;
        if (isDefaultAddress) {
          updateAddresses = customer?.addresses?.map((address) => ({ ...address, default: false }));
        }

        return {
          ...customer,
          addresses: [...updateAddresses, { ...action?.payload?.address }],
          default_address: isDefaultAddress ? action?.payload?.address : (customer?.default_address || {}),
        };
      });
      return {
        ...state,
        items,
        isError: false,
        errors: {},
      };
    }
    default:
      return state;
  }
}
