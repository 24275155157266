// import axios from '../utils/axios';
import { SET_NOTICES, NOTICE_DELETED, ADD_NOTICE } from '../constants/notices';

export const setNotices = (noticesState: any) => ({
  type: SET_NOTICES,
  ...noticesState,
});

// const fetchNotices = () => (dispatch) => axios({ url: '/notices', method: 'GET' })
//   .then(({ data }) => {
//     const { notices } = data;
//     dispatch(setNotices({ items: notices }));
//   });

export const deleteNotice = (id) => ({ type: NOTICE_DELETED, id });
export const addNotice = (item: any) => ({ type: ADD_NOTICE, item });
