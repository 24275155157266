import { SET_CONTENTS } from '@component/contents/constants';
import { PER_PAGE } from '@constants/common';
import { filter } from 'lodash';

export const initialState = {
  explore: {
    items: [],
    current_page: 1,
    per_page: PER_PAGE,
    total_count: 0,
  },
  onboard: {
    items: [],
    current_page: 1,
    per_page: PER_PAGE,
    total_count: 0,
  },
  help: {
    items: [],
    current_page: 1,
    per_page: PER_PAGE,
    total_count: 0,
  },
  faq: {
    items: [],
    current_page: 1,
    per_page: PER_PAGE,
    total_count: 0,
  },
  isError: false,
  hasWarnings: false,
  errors: {},
  warnings: {},
  isFetching: false,
};

const Contents = (state = initialState, action) => {
  switch (action?.type) {
    case SET_CONTENTS: {
      const {
        type_in: typeIn, items, current_page: currentPage, per_page: perPage, total_count
      } = action?.payload || {};

      let updatedState = { ...state };

      typeIn?.forEach((type) => {
        const filteredItems = filter(items, { type });
        updatedState = {
          ...updatedState,
          [type]: {
            items: filteredItems, current_page: currentPage, per_page: perPage, total_count: (filteredItems?.length || ''),
          },
        };
      });
      return { ...updatedState };
    }
    default:
      return state;
  }
};

export default Contents;
