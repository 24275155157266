import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '@utils/axios';
import { FETCH_STATISTICS, SET_STATISTICS, FETCHING_STATISTICS } from '@constants/statistics';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@src/utils/SerializeParams';

export function fetchStatistics(searchParams) {
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `/utilities/statistics/vouchers_masters_statistics_count?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/statistics', {});
      // throw error.response;
    });
}

function* fetchStatisticsSagaSideEffect(action: any) {
  const { search_params: searchParams } = action;

  yield put({ type: FETCHING_STATISTICS });

  try {
    const response = yield call(fetchStatistics(searchParams));

    const statistics = response?.data || {};
    yield put({ type: SET_STATISTICS, data: statistics });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* statisticsWatcher() {
  yield takeLatest(FETCH_STATISTICS, fetchStatisticsSagaSideEffect);
}
