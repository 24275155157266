import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BRANDS, ADD_BRAND, BRAND_FETCHED, BRAND_UPDATED, BRAND_DELETED,
  AUTOCOMPLETE_BRANDS, defaultSearchParams, moduleName,
} from '../constants/brands';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'position',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function brands(state = initialState, action) {
  switch (action.type) {
    case SET_BRANDS: return setItems(state, action);
    case ADD_BRAND: return addItem(state, action);
    case BRAND_FETCHED: return fetchItem(state, action);
    case BRAND_UPDATED: return updateItem(state, action);
    case BRAND_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_BRANDS: return autocomplete(state, action);
    default:
      return state;
  }
}
