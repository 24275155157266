import { HOME_BREADCRUMB_LISTING } from './common';

export const RETURN_FORM_VIEW = 'return_form';

export const RETURN = 'RETURN';
export const NEW_RETURN = 'NEW_RETURN';
export const SET_RETURNS = 'SET_RETURNS';
export const ADD_RETURN = 'ADD_RETURN';
export const RETURN_FETCHED = 'RETURN_FETCHED';
export const RETURN_UPDATED = 'RETURN_UPDATED';
export const RETURN_DELETED = 'RETURN_DELETED';
export const RETURN_EDIT = 'RETURN_EDIT';
export const AUTOCOMPLETE_RETURNS = 'AUTOCOMPLETE_RETURNS';

export const FETCH_RETURNS = 'FETCH_RETURNS';

export const UPDATE_SELECTED_BULK_RETURNS_STATUS_TO_APPROVED = 'UPDATE_SELECTED_BULK_RETURNS_STATUS_TO_APPROVED';
export const RETURN_BULK_STATE_APPROVED = 'RETURN_BULK_STATE_APPROVED';
export const CREATE_BULK_CREDIT_NOTES = 'CREATE_BULK_CREDIT_NOTES';

export const title = 'Returns';
export const singularTitle = 'Return';
export const moduleName = 'returns';
export const baseUrl = '/returns';
export const redirectUrl = '/returns';
export const listingDataKey = 'returns';
export const itemDataKey = 'return';

export const returnConstants = {
  item: RETURN,
  setNewItem: NEW_RETURN,
  setItems: SET_RETURNS,
  addItem: ADD_RETURN,
  setItem: RETURN_FETCHED,
  updateItem: RETURN_UPDATED,
  editItem: RETURN_EDIT,
  deleteItem: RETURN_DELETED,
  autocompleteItems: AUTOCOMPLETE_RETURNS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  credit_note_status: '',
  state_in: [],
  variants_product_category_id_in: [],
  variants_id_in: [],
  variants_brand_id_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  user_id_in: [],
  customer_id_in: [],
};

export const defaultSearchParams = {
  number_or_customer_name_or_customer_gstin_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['users', 'brands', 'branches', 'warehouses', 'product_categories', 'customers', 'products', 'voucher_books'];

export const draft = 'draft';
export const approved = 'approved';

export const RETURN_STATES = [{ label: 'Draft', value: draft }, { label: 'Approved', value: approved }];

export const RETURN_STATES_FOR_REPORTS = [
  { name: 'Draft', id: draft },
  { name: 'Approved', id: approved },
];

// This two accounted and unaccounted credit note status.
// We use this two status in return listing page to show is return has credit note or not.
// If return has credit note we use accounted, otherwise we use unaccounted.
export const accounted = 'accounted';
export const unaccounted = 'unaccounted';
export const CREDIT_NOTE_STATUS = [{ label: 'Accounted', value: accounted }, { label: 'Unaccounted', value: unaccounted }];
// Breadcrumb
export const Title = 'Returns';
export const TargetBaseUrl = 'returns';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'customer', 'customer_id', 'branch', 'branch_id', 'warehouse', 'warehouse_id', 'notes'],
  return_items: ['variant', 'variant_id', 'quantity', 'price', 'gross_price'],
};
