import { productFilter, productCategoryFilter, brandFilter } from '../../../components/common/advance_search/common';
import { marginOptions } from './price_utils';

export const MRP_KEY = 'mrp';
export const PRODUCT_INFO_KEY = 'product_info';
export const MARGIN_KEY = 'margins';
export const GROSS_PRICE_KEY = 'price';
export const GROSS_PRICE_INC_TAX_KEY = 'price_inc_tax';
export const MIN_GROSS_PRICE_KEY = 'min_price';
export const MIN_GROSS_PRICE_INC_TAX_KEY = 'min_price_inc_tax';
export const INCLUSIVE_TAX_KEY = 'inclusive_tax';
export const CUSTOMER_CATEGORY_KEY = 'customer_category_id';
export const COST_LOCAL_KEY = 'purchase_price_localized';
export const COST_INC_TAX_LOCAL_KEY = 'purchase_price_inc_tax_localized';
export const AVG_COST_LOCAL_KEY = 'avg_purchase_price_localized';
export const AVG_COST_INC_TAX_LOCAL_KEY = 'avg_purchase_price_inc_tax_localized';
export const AVG_PRICE_OPTIONS_KEY = 'avgPriceOptions';
export const MARGIN_OPTIONS_KEY = 'marginOptions';
export const COST_OPTIONS_KEY = 'costOptions';
export const OFFERS_KEY = 'offers';
export const PROFIT_MARGIN_KEY = 'profit_margin';
export const MRP_MARGIN_KEY = 'mrp_margin';
export const RETAILER_MARGIN_KEY = 'retailer_margin';
export const PROFIT_MARGIN_ERROR_KEY = 'profit_margin_error';
export const MRP_MARGIN_ERROR_KEY = 'mrp_margin_error';
export const RETAILER_MARGIN_ERROR_KEY = 'retailer_margin_error';

const categoryColWidth = 200;
const validFromColWidth = 120;
const grossPriceColWidth = 100;
const grossPriceIncTaxColWidth = 160;
const minGrossPriceColWidth = 130;
const inclusiveTaxColWidth = 150;
const sellingPriceColWidth = (categoryColWidth
  + validFromColWidth + inclusiveTaxColWidth + grossPriceColWidth + grossPriceIncTaxColWidth);

export const productInfoColumn = {
  key: PRODUCT_INFO_KEY,
  label: 'Product',
  callFunc: true,
  inputType: undefined,
  renderForParentRowOnly: true,
  thClassName: 'word-break',
  attributes: {
    rowspan: 2, colspan: 1, column_width: 200,
  },
};
export const categoryColumn = {
  key: CUSTOMER_CATEGORY_KEY,
  label: 'CUSTOMER CATEGORY',
  callFunc: true,
  queryKey: CUSTOMER_CATEGORY_KEY,
  thClassName: 'word-break',
  attributes: {
    rowspan: 1, colspan: 1, column_width: categoryColWidth,
  },
};
export const purchasePriceColumn = {
  key: COST_LOCAL_KEY,
  label: 'COST - ',
  inputType: 'dropdown',
  renderForParentRowOnly: true,
  optionsKey: COST_OPTIONS_KEY,
  callFunc: true,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 2, colspan: 1, column_width: 200,
  },
};
export const sellingPriceColumn = {
  key: 'selling_price',
  label: 'SELLING PRICE',
  inputType: undefined,
  callFunc: true,
  thClassName: 'word-break text-center',
  attributes: {
    rowspan: 1, colspan: 5, column_width: sellingPriceColWidth,
  },
};
export const marginTypeColumn = {
  key: MARGIN_KEY,
  label: 'MARGINS',
  inputType: 'dropdown',
  callFunc: true,
  optionsKey: MARGIN_OPTIONS_KEY,
  thClassName: 'word-break text-center',
  attributes: {
    rowspan: 2, colspan: 1, column_width: 160,
  },
};
export const offerColumn = {
  key: OFFERS_KEY,
  label: 'ACTIVE OFFERS',
  inputType: undefined,
  callFunc: true,
  thClassName: 'word-break',
  attributes: {
    rowspan: 2, colspan: 1, column_width: 300,
  },
};
export const validFromColumn = {
  key: 'valid_from',
  label: 'VALID FROM',
  inputType: 'date',
  callFunc: true,
  thClassName: 'word-break text-center',
  attributes: {
    rowspan: 2, colspan: 1, column_width: validFromColWidth,
  },
};
export const inclusiveTaxColumn = {
  key: INCLUSIVE_TAX_KEY,
  label: 'INCLUSIVE TAX',
  inputType: 'checkbox',
  callFunc: true,
  thClassName: 'word-break text-center',
  attributes: {
    rowspan: 1, colspan: 1, column_width: inclusiveTaxColWidth,
  },
};
export const grossPriceColumn = {
  key: GROSS_PRICE_KEY,
  label: 'GROSS PRICE',
  inputType: 'numberDecimal',
  callFunc: true,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 1, colspan: 1, column_width: grossPriceColWidth,
  },
};
export const minGrossPriceColumn = {
  key: MIN_GROSS_PRICE_KEY,
  label: 'MIN GROSS PRICE',
  inputType: 'numberDecimal',
  callFunc: true,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 1, colspan: 1, column_width: minGrossPriceColWidth,
  },
};
export const marginCategoryColumn = {
  key: 'margin_category',
  label: 'PERCENTAGE',
  inputType: undefined,
  callFunc: true,
  thClassName: 'word-break text-center z-inx',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 160,
  },
};

// These are fixed headers, which will show fixed column on left side.
export const fixedColumnHeaders = [
  [
    productInfoColumn,
  ],
  [
    { key: 'invisible_column', attributes: { rowspan: 1, colspan: 1 } },
  ],
];

// These are the headers, all of them with row and col spans
export const headers = [
  [
    productInfoColumn, purchasePriceColumn,
    sellingPriceColumn, marginTypeColumn,
    offerColumn,
  ],
  [
    categoryColumn, validFromColumn, inclusiveTaxColumn, minGrossPriceColumn,
    grossPriceColumn,
  ],
];

// These are the leaf columns required to show the data
export const dataColumn = [
  productInfoColumn,
  { ...purchasePriceColumn, inputType: undefined },
  { ...categoryColumn, inputType: 'dropdown' }, validFromColumn,
  inclusiveTaxColumn,
  minGrossPriceColumn, grossPriceColumn,
  { ...marginCategoryColumn, key: MARGIN_KEY },
  offerColumn,
];

// These are the possible options a user can change in price table
export const configOptions = {
  marginOptions,
};

export const priceObject = {
  price: '',
  inclusive_tax: false,
  min_price: '',
  margins: {},
  valid_from: '',
  errors: {},
  synced: true,
};

export const getPriceObject = ({
  productId,
}) => (
  {
    product_id: productId,
    customer_category_id: '',
    ...priceObject,
  }
);

export const calculatePriceIncTax = (args: { price: number; product: any }) => {
  const { price, product } = args;
  let totalTax = 0;
  product.taxes.forEach((tax) => {
    totalTax += tax.percentage;
  });
  const priceIncTax = price * (1 + (totalTax / 100));
  return Number(priceIncTax.toFixed(2));
};

export const calculatePrice = (args: { priceIncTax: number; product: any }) => {
  const { priceIncTax, product } = args;
  let totalTax = 0;
  product.taxes.forEach((tax) => {
    totalTax += tax.percentage;
  });
  const price = priceIncTax * (100 / (totalTax + 100));
  return Number(price.toFixed(2));
};

export const changePriceFiltersSchema = [
  { ...productFilter, query_key: 'id_in' },
  { ...productCategoryFilter, query_key: 'product_category_id_in' },
  { ...brandFilter, query_key: 'brand_id_in' },
];
