import { SET_RECENT_PAYMENT_OUTS, PAYMENT_OUTS, UPDATE_SELECTED_BULK_RECENT_PAYMENT_OUTS_STATUS_TO_ACCOUNTED } from '../constants/recents';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { setRecents, updateStatus } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  search_params: {
    module_name: PAYMENT_OUTS,
  },
};

export default function recentPaymentOutsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECENT_PAYMENT_OUTS: return setRecents(state, action);
    case UPDATE_SELECTED_BULK_RECENT_PAYMENT_OUTS_STATUS_TO_ACCOUNTED:
      return updateStatus(state, action, action.status);
    default:
      return state;
  }
}
