import { moduleName, PURCHASE_ORDER, redirectUrl } from '@constants/purchase_orders';
import axios from '@utils/axios';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

// Get List of items(dispatch, getState)
export function fetchPurchaseOrders(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateOrderItemPurchaseOrderItems(args) {
  const url = `/purchase_orders/${args.purchaseOrderID}/purchase_order_items/${args.purchaseOrderItemID}/allocate`;
  startFetchingRequest(moduleName);
  return () => axios({
    url,
    method: 'put',
    data: args.data,
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, PURCHASE_ORDER);
      throw error.response;
    });
}
