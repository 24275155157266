import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_DOCUMENT_CATEGORIES, ADD_DOCUMENT_CATEGORY, DOCUMENT_CATEGORY_FETCHED,
  DOCUMENT_CATEGORY_UPDATED, DOCUMENT_CATEGORY_DELETED, SET_DOCUMENT_CATEGORY_TEMPLATE,
  defaultSearchParams, moduleName,
} from '../constants/document_categories';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'resource_type',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  available_resource_types: [],
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function documentCategories(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT_CATEGORIES: return setItems(state, action);
    case ADD_DOCUMENT_CATEGORY: return addItem(state, action);
    case DOCUMENT_CATEGORY_FETCHED: return fetchItem(state, action);
    case DOCUMENT_CATEGORY_UPDATED: return updateItem(state, action);
    case DOCUMENT_CATEGORY_DELETED: return deleteItem(state, action);
    case SET_DOCUMENT_CATEGORY_TEMPLATE: {
      return { ...state, available_resource_types: action.available_resource_types };
    }
    default:
      return state;
  }
}
