import Loadable from 'react-loadable';
import Loading from '@component/common/page_loading';

// We use loadable for user index page.
const Users = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersIndex" */ '@containers/users/Index'),
  loading: Loading,
});

// We use loadable for user new and edit page.
const UserShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersShow" */ '@containers/users/show'),
  loading: Loading,
});

// We use loadable for user new and edit page.
const UserCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersCredate" */ '@containers/users/Credate'),
  loading: Loading,
});

// We use loadable for user index page.
const Branches = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBranchesIndex" */ '@containers/branches/Index'),
  loading: Loading,
});

// We use loadable for user new and edit page.
const BranchCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBranchesCredate" */ '@containers/branches/Credate'),
  loading: Loading,
});

// We use loadable for branch show page.
const BranchShow = Loadable({
  loader: () => import('@containers/branches/Show'),
  loading: Loading,
});

// We use loadable for user index page.
const Warehouses = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersWarehousesIndex" */ '@containers/warehouses/Index'),
  loading: Loading,
});

// We use loadable for user new and edit page.
const WarehouseCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersWarehousesCredate" */ '@containers/warehouses/Credate'),
  loading: Loading,
});

// WarehouseShow
// We use loadable for warehouse show page.
const WarehouseShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersWarehousesShow" */ '@containers/warehouses/Show'),
  loading: Loading,
});

// We use loadable for customer index page.
const Customers = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomersIndex" */ '@containers/customers/Index'),
  loading: Loading,
});

// We use loadable for customer new and edit page.
const CustomerCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomersCredate" */ '@containers/customers/Credate'),
  loading: Loading,
});

// We use loadable for customer show page.
const CustomerShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomersShow" */ '@containers/customers/Show'),
  loading: Loading,
});

// We use loadable for customer_category index page.
const CustomerCategories = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomerCategoriesIndex" */ '@containers/customer_categories/Index'),
  loading: Loading,
});

// We use loadable for customer_category new and edit page.
const CustomerCategoryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomerCategoriesCredate" */ '@containers/customer_categories/Credate'),
  loading: Loading,
});

// We use loadable for customer_ratings index page.
const CustomerRatings = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomerRatingsIndex" */ '@containers/customer_ratings/Index'),
  loading: Loading,
});

// We use loadable for customer_ratings new and edit page.
const CustomerRatingCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCustomerRatingsCredate" */ '@containers/customer_ratings/Credate'),
  loading: Loading,
});

const CommonActionsBaseScreen = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsShow" */ '@containers/common_actions/common_actions_base_screen'),
  loading: Loading,
});

// We use loadable for suppliers index page.
const Suppliers = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSuppliersIndex" */ '@containers/suppliers/Index'),
  loading: Loading,
});

// We use loadable for suppliers new and edit page.
const SupplierCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSuppliersCredate" */ '@containers/suppliers/Credate'),
  loading: Loading,
});

// We use loadable for suppliers show page.
const SupplierShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSuppliersShow" */ '@containers/suppliers/show'),
  loading: Loading,
});

// We use loadable for zones index page.
const Zones = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersZonesIndex" */ '@containers/zones/Index'),
  loading: Loading,
});

// We use loadable for zones new and edit page.
const ZoneCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersZonesCredate" */ '@containers/zones/Credate'),
  loading: Loading,
});

// We use loadable for taxes index page.
const Taxes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersTaxesIndex" */ '@containers/taxes/Index'),
  loading: Loading,
});

// We use loadable for taxes new and edit page.
const TaxCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersTaxesCredate" */ '@containers/taxes/Credate'),
  loading: Loading,
});

// We use loadable for tax_categories index page.
const TaxCategories = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersTaxCategoriesIndex" */ '@containers/tax_categories/Index'),
  loading: Loading,
});

// We use loadable for tax_categories new and edit page.
const TaxCategoryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersTaxCategoriesCredate" */ '@containers/tax_categories/Credate'),
  loading: Loading,
});

// We use loadable for bank_accounts index page.
const BankAccounts = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBankAccountsIndex" */ '@containers/bank_accounts/Index'),
  loading: Loading,
});

// We use loadable for bank_accounts new and edit page.
const BankAccountCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBankAccountsCredate" */ '@containers/bank_accounts/Credate'),
  loading: Loading,
});

// We use loadable for bank account show page.
const BankAccountShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBankAccountsShow" */ '@containers/bank_accounts/Show'),
  loading: Loading,
});

// We use loadable for credit reasons index page.
const CreditReasons = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCreditReasonsIndex" */ '@containers/credit_reasons/Index'),
  loading: Loading,
});

// We use loadable for credit reason new and edit page.
const CreditReasonCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCreditReasonsCredate" */ '@containers/credit_reasons/Credate'),
  loading: Loading,
});

// We use loadable for debit reasons index page.
const DebitReasons = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDebitReasonsIndex" */ '@containers/debit_reasons/index'),
  loading: Loading,
});

// We use loadable for debit reason new and edit page.
const DebitReasonCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDebitReasonsCredate" */ '@containers/debit_reasons/credate'),
  loading: Loading,
});

const VoucherBookIndex = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerVoucherBookIndex" */ '@containers/voucher_books/index'),
  loading: Loading,
});

const VoucherBookCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerVoucherBookCredate" */ '@containers/voucher_books/credate'),
  loading: Loading,
});

const VoucherBookShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerVoucherBookShow" */ '@containers/voucher_books/show'),
  loading: Loading,
});

// SECTION document categories loadable components
// We use loadable for document category index page.
const DocumentCategoriesRoutes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDocumentCategoriesIndex" */ '@containers/document_categories/Index'),
  loading: Loading,
});

// We use loadable for document category new and edit page.
const DocumentCategoryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDocumentCategoryCredate" */ '@containers/document_categories/Credate'),
  loading: Loading,
});
// !SECTION document categories loadable components

const PeriodLockIndex = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerPeriodLockIndex" */ '@containers/period_locks/index'),
  loading: Loading,
});

const PeriodLockCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerPeriodLockCredate" */ '@containers/period_locks/credate'),
  loading: Loading,
});

const PeriodLockShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainerPeriodLockShow" */ '@containers/period_locks/show'),
  loading: Loading,
});

const MasterRoutes = [
  {
    path: '/users/new', name: 'users/new', component: UserCredate,
  },
  {
    path: '/users/:id', name: 'users/show', component: UserShow,
  },
  {
    path: '/users/:id/edit', name: 'users/edit', component: UserCredate,
  },
  {
    path: '/users', name: 'Users', component: Users,
  },

  {
    path: '/branches/new', name: 'branches/new', component: BranchCredate,
  },
  {
    path: '/branches/:id/edit', name: 'branches/edit', component: BranchCredate,
  },
  {
    path: '/branches/:id', name: 'Branches', component: BranchShow,
  },
  {
    path: '/branches', name: 'Branches', component: Branches,
  },
  {
    path: '/warehouses/new', name: 'warehouses/new', component: WarehouseCredate,
  },
  {
    path: '/warehouses/:id/edit', name: 'warehouses/edit', component: WarehouseCredate,
  },
  {
    path: '/warehouses/:id', name: 'Warehouses', component: WarehouseShow,
  },
  {
    path: '/warehouses', name: 'Warehouses', component: Warehouses,
  },

  {
    path: '/customers/new', name: 'customers/new', component: CustomerCredate,
  },
  {
    path: '/customers/:id/edit', name: 'customers/edit', component: CustomerCredate,
  },
  {
    path: '/customers/:id', name: 'Customers', component: CustomerShow,
  },
  {
    path: '/customers', name: 'Customers', component: Customers,
  },

  {
    path: '/customer_categories/new', name: 'customer_categories/new', component: CustomerCategoryCredate,
  },
  {
    path: '/customer_categories/:id/edit', name: 'customer_categories/edit', component: CustomerCategoryCredate,
  },
  {
    path: '/customer_categories', name: 'customer_category', component: CustomerCategories,
  },

  {
    path: '/customer_ratings/new', name: 'customer_ratings/new', component: CustomerRatingCredate,
  },
  {
    path: '/customer_ratings/:id/edit', name: 'customer_ratings/edit', component: CustomerRatingCredate,
  },
  {
    path: '/customer_ratings', name: 'Customer Ratings', component: CustomerRatings,
  },

  {
    path: '/suppliers/new', name: 'suppliers/new', component: SupplierCredate,
  },
  {
    path: '/suppliers/:id', name: 'suppliers/show', component: SupplierShow,
  },
  {
    path: '/suppliers/:id/edit', name: 'suppliers/edit', component: SupplierCredate,
  },
  {
    path: '/suppliers', name: 'Suppliers', component: Suppliers,
  },

  // {
  //   path: '/zones/new', name: 'zones/new', component: ZoneCredate,
  // },
  // {
  //   path: '/zones/:id/edit', name: 'zones/edit', component: ZoneCredate,
  // },
  {
    path: '/zones', name: 'Zones', component: Zones,
  },

  {
    path: '/taxes/new', name: 'taxes/new', component: TaxCredate,
  },
  {
    path: '/taxes/:id/edit', name: 'taxes/edit', component: TaxCredate,
  },
  {
    path: '/taxes', name: 'Taxes', component: Taxes,
  },

  {
    path: '/tax_categories/new', name: 'tax_categories/new', component: TaxCategoryCredate,
  },
  {
    path: '/tax_categories/:id/edit', name: 'tax_categories/edit', component: TaxCategoryCredate,
  },
  {
    path: '/tax_categories', name: 'Tax Categories', component: TaxCategories,
  },
  {
    path: '/bank_accounts/new', name: 'bank_accounts/new', component: BankAccountCredate,
  },
  {
    path: '/bank_accounts/:id/edit', name: 'bank_accounts/edit', component: BankAccountCredate,
  },
  {
    path: '/bank_accounts', name: 'Bank Accounts', component: BankAccounts,
  },
  {
    path: '/bank_accounts/:id', name: 'Bank Account', component: BankAccountShow,
  },

  {
    path: '/credit_reasons/new', name: 'credit_reasons/new', component: CreditReasonCredate,
  },
  {
    path: '/credit_reasons/:id/edit', name: 'credit_reasons/edit', component: CreditReasonCredate,
  },
  {
    path: '/credit_reasons', name: 'Credit Reasons', component: CreditReasons,
  },

  {
    path: '/debit_reasons/new', name: 'New Debit Reason', component: DebitReasonCredate,
  },
  {
    path: '/debit_reasons/:id/edit', name: 'Edit Debit Reason', component: DebitReasonCredate,
  },
  {
    path: '/debit_reasons', name: 'Debit Reasons', component: DebitReasons,
  },

  // SECTION voucher books links
  {
    path: '/voucher_books', name: 'Voucher Books', component: VoucherBookIndex,
  },
  {
    path: '/voucher_books/new', name: 'New Voucher Book', component: VoucherBookCredate,
  },
  {
    path: '/voucher_books/:id/edit', name: 'Edit Voucher book', component: VoucherBookCredate,
  },
  {
    path: '/voucher_books/:id', name: 'Voucher Book', component: VoucherBookShow,
  },
  // !SECTION voucher books links

  // SECTION document categories links
  {
    path: '/document_categories', name: 'document_categories', component: DocumentCategoriesRoutes,
  },
  {
    path: '/document_categories/new', name: 'document_categories/new', component: DocumentCategoryCredate,
  },
  {
    path: '/document_categories/:id/edit', name: 'document_categories/edit', component: DocumentCategoryCredate,
  },

  {
    path: '/credit_control', name: 'Credit Control', component: CommonActionsBaseScreen,
  },
  // !SECTION document categories links

  // SECTION period locks links
  {
    path: '/period_locks', name: 'Period Locks', component: PeriodLockIndex,
  },
  {
    path: '/period_locks/new', name: 'New Period Lock', component: PeriodLockCredate,
  },
  {
    path: '/period_locks/:id/edit', name: 'Edit Period Lock', component: PeriodLockCredate,
  },
  {
    path: '/period_locks/:id', name: 'Show Period Lock', component: PeriodLockShow,
  },
  // !SECTION period locks links
];

export default MasterRoutes;
