import React, { useEffect, useState } from 'react';
import { MenuItem } from 'react-bootstrap';
import { downloadFileHelper } from '@component/common/download_files/helper';
import { AxiosResponse } from 'axios';

interface DownloadFileActionProps {
  url: string;
  params: Record<string, unknown>;
  redirectTo: string;
  identifier: string;
  shouldOpen: boolean;
  shouldDownloadForGet: boolean;
}

interface Props {
  idx: string;
  printFormat: any;
  url: string;
  params: Record<string, any>;
  redirectUrl: string;
  identifier: string;
  downloadFile({
    url, params, redirectTo, identifier, shouldOpen, shouldDownloadForGet,
  }: DownloadFileActionProps): any;
}

const PrintFormatDropdownItem = (props: Props) => {
  // Hooks
  const [isLoading, handleChange] = useState(false);

  const downloadPrintFormatPdf = () => {
    const { printFormat } = props;
    const { id } = printFormat;

    // Call useState hook
    handleChange(true);

    // Props
    const {
      url, params, redirectUrl, identifier, downloadFile,
    } = props;

    let updatedParams = { ...params };
    if (id) {
      updatedParams = { ...updatedParams, configuration_id: id };
    }

    downloadFile({
      url,
      params: updatedParams,
      redirectTo: redirectUrl,
      identifier,
      shouldOpen: false,
      shouldDownloadForGet: true,
    })
      .then((response: AxiosResponse) => {
        // This function take pdf response and download pdf.
        downloadFileHelper({ response });
        // Call useState hook
        handleChange(false);
      })
      .catch(() => {
        // Call useState hook
        handleChange(false);
      });
  };

  useEffect(() => {
    if (isLoading) {
      downloadPrintFormatPdf();
    }
  }, [isLoading]);

  const { printFormat, idx } = props;
  const { id, name, default: defaultProps } = printFormat;

  return (
    <MenuItem key={id} eventKey={idx + 1} className="listing-action-menu-item">
      <div
        role="presentation"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          handleChange(true);
        }}
        className="text-primary"
      >
        {
          (isLoading)
            ? <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            : null
        }
        <span>
          {name || ''}
          {
            defaultProps
              ? <small className="text-muted">&nbsp;(default)</small>
              : null
          }
        </span>
      </div>
    </MenuItem>
  );
};

export default PrintFormatDropdownItem;
