import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const FETCH_SMS_STATISTICS = 'FETCH_SMS_STATISTICS';
export const SET_SMS_STATISTICS = 'SET_SMS_STATISTICS';
export const FETCHING_SMS_STATISTICS = 'FETCHING_SMS_STATISTICS';

export const title = 'SMS Statistics';
export const singularTitle = 'SMS Statistic';
export const moduleName = 'sms_statistics';
export const baseUrl = '/sms_statistics';
export const redirectUrl = '/sms_statistics';
export const listingDataKey = 'sms_statistics';
export const itemDataKey = 'sms_statistic';

export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title, target: baseUrl, active: true }],
);
