import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  ADD_INVOICE_PAYMENT, INVOICE_PAYMENT_UPDATED, defaultSearchParams,
} from '../constants/invoice_payments';
import { addItem, updateItem } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
};

const invoicePayments = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVOICE_PAYMENT: return addItem(state, action);
    case INVOICE_PAYMENT_UPDATED: return updateItem(state, action);
    default:
      return state;
  }
};

export default invoicePayments;
