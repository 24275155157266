import { HOME_BREADCRUMB_LISTING } from './common';

export const CUSTOMER_RATING = 'CUSTOMER_RATING';
export const SET_CUSTOMER_RATINGS = 'SET_CUSTOMER_RATINGS';
export const ADD_CUSTOMER_RATING = 'ADD_CUSTOMER_RATING';
export const CUSTOMER_RATING_FETCHED = 'CUSTOMER_RATING_FETCHED';
export const CUSTOMER_RATING_UPDATED = 'CUSTOMER_RATING_UPDATED';
export const CUSTOMER_RATING_DELETED = 'CUSTOMER_RATING_DELETED';
export const AUTOCOMPLETE_CUSTOMER_RATINGS = 'AUTOCOMPLETE_CUSTOMER_RATINGS';

export const RESET_CUSTOMER_RATING_ERRORS = 'RESET_CUSTOMER_RATING_ERRORS';
export const FORM_INFORMATION_TITLE = 'CUSTOMER RATING INFORMATION';
export const ADD_NEW_CUSTOMER_RATING = 'Add New Customer Rating';

export const defaultSearchParams = {
  name_cont: '',
};

export const title = 'Customer Ratings';
export const singularTitle = 'Customer Rating';
export const moduleName = 'customer_ratings';
export const baseUrl = '/customer_ratings';
export const redirectUrl = '/customer_ratings';
export const listingDataKey = 'customer_ratings';
export const itemDataKey = 'customer_rating';

export const customerRatingConstants = {
  item: CUSTOMER_RATING,
  setItems: SET_CUSTOMER_RATINGS,
  addItem: ADD_CUSTOMER_RATING,
  setItem: CUSTOMER_RATING_FETCHED,
  updateItem: CUSTOMER_RATING_UPDATED,
  deleteItem: CUSTOMER_RATING_DELETED,
  autocompleteItems: AUTOCOMPLETE_CUSTOMER_RATINGS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

// Models, This constants require in customer ratings.
export const Invoice = 'Invoice';
export const Payment = 'Payment';
export const Return = 'Return';
export const Order = 'Order';
export const PurchaseInvoice = 'PurchaseInvoice';
export const PaymentOut = 'PaymentOut';
export const LIST_OF_MODELS = [
  { label: Order, value: Order },
  { label: Payment, value: Payment },
  { label: Return, value: Return },
  { label: 'Purchase Invoice', value: PurchaseInvoice },
  { label: 'Payment Out', value: PaymentOut },
];

export const Title = 'Customer Ratings';
export const TargetBaseUrl = 'customer_ratings';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'credit_limit', 'outstanding_invoice_limit', 'outstanding_days_limit'],
  credit_control_factors: ['model', 'mode_id', 'state', 'consideration_percentage'],
};
