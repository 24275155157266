import ReactGA from 'react-ga';

export function removeIDAndQueriesFromUrl(url) {
  const idLessUrl = url.replace(/\/\d+/g, '/id');
  if (idLessUrl.indexOf('?') !== -1) {
    return `${idLessUrl.substring(0, idLessUrl.indexOf('?'))}/queries`;
  }
  return idLessUrl;
}

export function logException(message, isFatal) {
  ReactGA.exception({
    description: message,
    fatal: isFatal,
  });
}

export function logTiming(category, variable, value) {
  ReactGA.timing({
    category,
    variable,
    value,
  });
}

export function logEvent(category, action, label) {
  ReactGA.event({
    category,
    action,
    label: label || action,
  });
}

export function getCurrentTimestamp() {
  if (!Date.now) {
    return new Date().getTime();
  }
  return Date.now();
}

export const CategoryEnum = {
  ACCOUNTING_REPORT_DETAIL: 'ACCOUNTING_REPORT_DETAIL',
  ACCOUNTS: 'ACCOUNTS',
  ACCOUNTS_RECONCILIATION_HISTORY: 'Accounts_Reconciliation_History',
  ACCOUNT_AMOUNTS: 'ACCOUNT_AMOUNTS',
  ACCOUNT_AMOUNTS_DETAIL: 'ACCOUNT_AMOUNTS_DETAIL',
  ACCOUNT_CREATE: 'ACCOUNT_CREATE',
  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
  ACCOUNT_FORM: 'ACCOUNT_FORM',
  ACCOUNT_RECONCILIATION_HISTORY_DETAIL: 'Account_Reconciliation_History_Detail',
  ACCOUNT_SUB_ACCOUNT: 'ACCOUNT_SUB_ACCOUNT',
  ACCOUNT_SUB_ACCOUNTS: 'ACCOUNT_SUB_ACCOUNTS',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  API: 'API',
  AUTHORISATION_CONDITIONS: 'AUTHORISATION_CONDITIONS',
  AUTHORISATION_CONDITION_CREATE: 'AUTHORISATION_CONDITION_CREATE',
  AUTHORISATION_CONDITION_DETAIL: 'AUTHORISATION_CONDITION_DETAIL',
  AUTHORISATION_CONDITION_FORM: 'AUTHORISATION_CONDITION_FORM',
  AUTHORISATION_CONDITION_UPDATE: 'AUTHORISATION_CONDITION_UPDATE',
  BANKS: 'BANKS',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  BANK_ACCOUNT_CREATE: 'BANK_ACCOUNT_CREATE',
  BANK_ACCOUNT_DETAIL: 'BANK_ACCOUNT_DETAIL',
  BANK_ACCOUNT_FORM: 'BANK_ACCOUNT_FORM',
  BANK_ACCOUNT_UPDATE: 'BANK_ACCOUNT_UPDATE',
  BANK_CREATE: 'BANK_CREATE',
  BANK_DETAIL: 'BANK_DETAIL',
  BANK_FORM: 'BANK_FORM',
  BANK_UPDATE: 'BANK_UPDATE',
  BEAT_ROUTES: 'BEAT_ROUTES',
  BEAT_ROUTES_DETAIL: 'BEAT_ROUTES_DETAIL',
  BEAT_ROUTES_SHOW: 'BEAT_ROUTES_SHOW',
  BEAT_ROUTE_ASSIGNMENT: 'BEAT_ROUTE_ASSIGNMENT',
  BEAT_ROUTE_ASSIGNMENT_CREATE: 'BEAT_ROUTE_ASSIGNMENT_CREATE',
  BEAT_ROUTE_ASSIGNMENT_UPDATE: 'BEAT_ROUTE_ASSIGNMENT_UPDATE',
  BEAT_ROUTE_CREATE: 'BEAT_ROUTE_CREATE',
  BEAT_ROUTE_FORM: 'BEAT_ROUTE_FORM',
  BEAT_ROUTE_UPDATE: 'BEAT_ROUTE_UPDATE',
  BRANCHES: 'BRANCHES',
  BRANCH_CREATE: 'BRANCH_CREATE',
  BRANCH_DETAIL: 'BRANCH_DETAIL',
  BRANCH_FORM: 'BRANCH_FORM',
  BRANCH_UPDATE: 'BRANCH_UPDATE',
  CHANGE_ORGANIZATION_INFO: 'CHANGE_ORGANIZATION_INFO',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHART_OF_ACCOUNTS: 'CHART_OF_ACCOUNTS',
  CHART_OF_ACCOUNT_CREATE: 'CHART_OF_ACCOUNT_CREATE',
  CHART_OF_ACCOUNT_DETAIL: 'CHART_OF_ACCOUNT_DETAIL',
  CHART_OF_ACCOUNT_FORM: 'CHART_OF_ACCOUNT_FORM',
  CHART_OF_ACCOUNT_UPDATE: 'CHART_OF_ACCOUNT_UPDATE',
  BRANDS: 'BRANDS',
  BRAND_CREATE: 'BRAND_CREATE',
  BRAND_DETAIL: 'BRAND_DETAIL',
  BRAND_FORM: 'BRAND_FORM',
  BRAND_UPDATE: 'BRAND_UPDATE',
  CREDIT_NOTE: 'CREDIT_NOTE',
  CREDIT_NOTES: 'CREDIT_NOTES',
  CREDIT_NOTE_CREATE: 'CREDIT_NOTE_CREATE',
  CREDIT_NOTE_DETAIL: 'CREDIT_NOTE_DETAIL',
  CREDIT_NOTE_FORM: 'CREDIT_NOTE_FORM',
  CREDIT_NOTE_UPDATE: 'CREDIT_NOTE_UPDATE',
  CREDIT_REASON: 'CREDIT_REASON',
  CREDIT_REASONS: 'CREDIT_REASONS',
  CREDIT_REASON_CREATE: 'Credit_Reason_Create',
  CREDIT_REASON_FORM: 'CREDIT_REASON_FORM',
  CREDIT_REASON_UPDATE: 'Credit_Reason_Update',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_CATEGORIES: 'CUSTOMER_CATEGORIES',
  CUSTOMER_CATEGORY_CREATE: 'CUSTOMER_CATEGORY_CREATE',
  CUSTOMER_CATEGORY_FORM: 'CUSTOMER_CATEGORY_FORM',
  CUSTOMER_CATEGORY_UPDATE: 'CUSTOMER_CATEGORY_UPDATE',
  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
  CUSTOMER_FORM: 'CUSTOMER_FORM',
  CUSTOMER_RATINGS: 'CUSTOMER_RATINGS',
  CUSTOMER_RATING_CREATE: 'CUSTOMER_RATING_CREATE',
  CUSTOMER_RATING_FORM: 'CUSTOMER_RATING_FORM',
  CUSTOMER_RATING_UPDATE: 'CUSTOMER_RATING_UPDATE',
  CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
  DEBIT_NOTE: 'DEBIT_NOTE',
  DEBIT_NOTES: 'DEBIT_NOTES',
  DEBIT_NOTE_CREATE: 'DEBIT_NOTE_CREATE',
  DEBIT_NOTE_DETAIL: 'DEBIT_NOTE_DETAIL',
  DEBIT_NOTE_FORM: 'DEBIT_NOTE_FORM',
  DEBIT_NOTE_UPDATE: 'DEBIT_NOTE_UPDATE',
  DEBIT_REASON: 'DEBIT_REASON',
  DEBIT_REASONS: 'DEBIT_REASONS',
  DEBIT_REASON_CREATE: 'DEBIT_REASON_CREATE',
  DEBIT_REASON_FORM: 'DEBIT_REASON_FORM',
  DEBIT_REASON_UPDATE: 'DEBIT_REASON_UPDATE',
  DEFAULT_DAC_ROLES: 'DEFAULT_DAC_ROLES',
  DEFAULT_DAC_ROLE_CREATE: 'DEFAULT_DAC_ROLE_CREATE',
  DEFAULT_DAC_ROLE_FORM: 'DEFAULT_DAC_ROLE_FORM',
  DEFAULT_DAC_ROLE_UPDATE: 'DEFAULT_DAC_ROLE_UPDATE',
  DELETE_CUSTOMER_ADDRESS: 'DELETE_CUSTOMER_ADDRESS',
  DELETE_CUSTOMER_CONTACT: 'DELETE_CUSTOMER_CONTACT',
  DELETE_SUPPLIER_CONTACT: 'DELETE_SUPPLIER_CONTACT',
  DOWNLOADS: 'DOWNLOADS',
  E_INVOICE_CREDENTIAL: 'E_INVOICE_CREDENTIAL',
  E_WAY_BILL_CREDENTIAL: 'E_WAY_BILL_CREDENTIAL',
  EDIT_CUSTOMER_ADDRESS: 'EDIT_CUSTOMER_ADDRESS',
  EDIT_CUSTOMER_CONTACT: 'EDIT_CUSTOMER_CONTACT',
  EDIT_SUPPLIER_CONTACT: 'EDIT_SUPPLIER_CONTACT',
  EMAIL: 'EMAIL',
  FEEDS: 'FEEDS',
  FEED_FILE_DOWNLOAD: 'FEED_FILE_DOWNLOAD',
  INVOICE: 'INVOICE',
  INVOICES: 'INVOICES',
  INVOICE_CREATE: 'INVOICE_CREATE',
  INVOICE_DELETE: 'INVOICE_DELETE',
  INVOICE_DETAIL: 'INVOICE_DETAIL',
  INVOICE_FORM: 'INVOICE_FORM',
  INVOICE_UPDATE: 'INVOICE_UPDATE',
  INVENTORY_TRACKING: 'INVENTORY_TRACKING',
  INVENTORY_TRACKINGS: 'INVENTORY_TRACKINGS',
  INVENTORY_TRACKING_CREATE: 'INVENTORY_TRACKING_CREATE',
  INVENTORY_TRACKING_DELETE: 'INVENTORY_TRACKING_DELETE',
  INVENTORY_TRACKING_DETAIL: 'INVENTORY_TRACKING_DETAIL',
  INVENTORY_TRACKING_FORM: 'INVENTORY_TRACKING_FORM',
  INVENTORY_TRACKING_UPDATE: 'INVENTORY_TRACKING_UPDATE',
  INVENTORY_BATCH: 'INVENTORY_BATCH',
  INVENTORY_BATCHES: 'INVENTORY_BATCHES',
  INVENTORY_BATCH_CREATE: 'INVENTORY_BATCH_CREATE',
  INVENTORY_BATCH_DELETE: 'INVENTORY_BATCH_DELETE',
  INVENTORY_BATCH_DETAIL: 'INVENTORY_BATCH_DETAIL',
  INVENTORY_BATCH_FORM: 'INVENTORY_BATCH_FORM',
  INVENTORY_BATCH_UPDATE: 'INVENTORY_BATCH_UPDATE',
  INVENTORY_IDENTIFICATION: 'INVENTORY_IDENTIFICATION',
  INVENTORY_IDENTIFICATIONS: 'INVENTORY_IDENTIFICATIONS',
  INVENTORY_IDENTIFICATION_CREATE: 'INVENTORY_IDENTIFICATION_CREATE',
  INVENTORY_IDENTIFICATION_DELETE: 'INVENTORY_IDENTIFICATION_DELETE',
  INVENTORY_IDENTIFICATION_DETAIL: 'INVENTORY_IDENTIFICATION_DETAIL',
  INVENTORY_IDENTIFICATION_FORM: 'INVENTORY_IDENTIFICATION_FORM',
  INVENTORY_IDENTIFICATION_UPDATE: 'INVENTORY_IDENTIFICATION_UPDATE',
  INVENTORY_PIECE: 'INVENTORY_PIECE',
  INVENTORY_PIECES: 'INVENTORY_PIECES',
  INVENTORY_PIECE_CREATE: 'INVENTORY_PIECE_CREATE',
  INVENTORY_PIECE_DELETE: 'INVENTORY_PIECE_DELETE',
  INVENTORY_PIECE_DETAIL: 'INVENTORY_PIECE_DETAIL',
  INVENTORY_PIECE_FORM: 'INVENTORY_PIECE_FORM',
  INVENTORY_PIECE_UPDATE: 'INVENTORY_PIECE_UPDATE',
  INVOICE_TERMS: 'INVOICE_TERMS',
  INVOICE_TERM_CREATE: 'INVOICE_TERM_CREATE',
  INVOICE_TERM_FORM: 'INVOICE_TERM_FORM',
  INVOICE_TERM_UPDATE: 'INVOICE_TERM_UPDATE',
  JOURNAL_ENTRIES: 'JOURNAL_ENTRIES',
  JOURNAL_ENTRY_CREATE: 'JOURNAL_ENTRY_CREATE',
  JOURNAL_ENTRY_DETAIL: 'JOURNAL_ENTRY_DETAIL',
  JOURNAL_ENTRY_FORM: 'JOURNAL_ENTRY_FORM',
  JOURNAL_ENTRY_UPDATE: 'JOURNAL_ENTRY_UPDATE',
  LEDGER_DETAIL: 'LEDGER_DETAIL',
  LOGIN: 'LOGIN',
  OFFERS: 'OFFERS',
  OFFER_CREATE: 'OFFER_CREATE',
  OFFER_DETAIL: 'OFFER_DETAIL',
  OFFER_FORM: 'OFFER_FORM',
  OFFER_UPDATE: 'OFFER_UPDATE',
  ORDER: 'ORDER',
  ORDERS: 'ORDERS',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDER_DETAIL: 'ORDER_DETAIL',
  ORDER_FORM: 'ORDER_FORM',
  ORDER_UPDATE: 'ORDER_UPDATE',
  ORDER_TERMS: 'ORDER_TERMS',
  ORDER_TERM_CREATE: 'ORDER_TERM_CREATE',
  ORDER_TERM_FORM: 'ORDER_TERM_FORM',
  ORDER_TERM_UPDATE: 'ORDER_TERM_UPDATE',
  PAGINATION: 'PAGINATION',
  PAYMENT: 'PAYMENT',
  PAYMENTS: 'PAYMENTS',
  PAYMENT_CREATE: 'PAYMENT_CREATE',
  PAYMENT_DELETE: 'PAYMENT_DELETE',
  PAYMENT_DETAIL: 'PAYMENT_DETAIL',
  PAYMENT_DOWNLOAD: 'PAYMENT_DOWNLOAD',
  PAYMENT_FORM: 'PAYMENT_FORM',
  PAYMENT_OUT: 'PAYMENT_OUT',
  PAYMENT_OUTS: 'PAYMENT_OUTS',
  PAYMENT_OUT_CREATE: 'PAYMENT_OUT_CREATE',
  PAYMENT_OUT_DELETE: 'PAYMENT_OUT_DELETE',
  PAYMENT_OUT_DETAIL: 'PAYMENT_OUT_DETAIL',
  PAYMENT_OUT_DOWNLOAD: 'PAYMENT_OUT_DOWNLOAD',
  PAYMENT_OUT_FORM: 'PAYMENT_OUT_FORM',
  PAYMENT_OUT_UPDATE: 'PAYMENT_OUT_UPDATE',
  PAYMENT_UPDATE: 'PAYMENT_UPDATE',
  PERIOD_LOCK: 'PERIOD_LOCK',
  PERIOD_LOCKS: 'PERIOD_LOCKS',
  PERIOD_LOCK_CREATE: 'PERIOD_LOCK_CREATE',
  PERIOD_LOCK_DELETE: 'PERIOD_LOCK_DELETE',
  PERIOD_LOCK_DETAIL: 'PERIOD_LOCK_DETAIL',
  PERIOD_LOCK_DOWNLOAD: 'PERIOD_LOCK_DOWNLOAD',
  PERIOD_LOCK_FORM: 'PERIOD_LOCK_FORM',
  PERIOD_LOCK_UPDATE: 'PERIOD_LOCK_UPDATE',
  PER_PAGE_COUNT: 'PER_PAGE_COUNT',
  PROCESSED_FEED_DOWNLOAD: 'PROCESSED_FEED_DOWNLOAD',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
  PRODUCT_CATEGORY_CREATE: 'PRODUCT_CATEGORY_CREATE',
  PRODUCT_CATEGORY_FORM: 'PRODUCT_CATEGORY_FORM',
  PRODUCT_CATEGORY_UPDATE: 'PRODUCT_CATEGORY_UPDATE',
  PRODUCT_CREATE: 'PRODUCT_CREATE',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  PRODUCT_FORM: 'PRODUCT_FORM',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRINT_FORMATS: 'PRINT_FORMATS',
  PURCHASE_INVOICE: 'PURCHASE_INVOICE',
  PURCHASE_INVOICES: 'PURCHASE_INVOICES',
  PURCHASE_INVOICE_CREATE: 'PURCHASE_INVOICE_CREATE',
  PURCHASE_INVOICE_DELETE: 'PURCHASE_INVOICE_DELETE',
  PURCHASE_INVOICE_DETAIL: 'PURCHASE_INVOICE_DETAIL',
  PURCHASE_INVOICE_FORM: 'PURCHASE_INVOICE_FORM',
  PURCHASE_INVOICE_UPDATE: 'PURCHASE_INVOICE_UPDATE',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  PURCHASE_ORDERS: 'PURCHASE_ORDERS',
  PURCHASE_ORDER_CREATE: 'PURCHASE_ORDER_CREATE',
  PURCHASE_ORDER_DELETE: 'PURCHASE_ORDER_DELETE',
  PURCHASE_ORDER_DETAIL: 'PURCHASE_ORDER_DETAIL',
  PURCHASE_ORDER_FORM: 'PURCHASE_ORDER_FORM',
  PURCHASE_ORDER_TERMS: 'PURCHASE_ORDER_TERMS',
  PURCHASE_ORDER_TERM_FORM: 'PURCHASE_ORDER_TERM_FORM',
  PURCHASE_ORDER_TERM_UPDATE: 'PURCHASE_ORDER_TERM_UPDATE',
  PURCHASE_ORDER_TERM_CREATE: 'PURCHASE_ORDER_TERM_CREATE',
  PURCHASE_ORDER_UPDATE: 'PURCHASE_ORDER_UPDATE',
  PURCHASE_RETURN: 'PURCHASE_RETURN',
  PURCHASE_RETURNS: 'PURCHASE_RETURNS',
  PURCHASE_RETURN_CREATE: 'PURCHASE_RETURN_CREATE',
  PURCHASE_RETURN_DELETE: 'PURCHASE_RETURN_DELETE',
  PURCHASE_RETURN_DETAIL: 'PURCHASE_RETURN_DETAIL',
  PURCHASE_RETURN_NEW: 'PURCHASE_RETURN_NEW',
  PURCHASE_RETURN_UPDATE: 'PURCHASE_RETURN_UPDATE',
  RECONCILIATIONS: 'RECONCILIATIONS',
  REPORT: 'REPORT',
  REPORTS: 'REPORTS',
  RETURN: 'RETURN',
  RETURNS: 'RETURNS',
  RETURN_CREATE: 'RETURN_CREATE',
  RETURN_DETAIL: 'RETURN_DETAIL',
  RETURN_FORM: 'RETURN_FORM',
  RETURN_UPDATE: 'RETURN_UPDATE',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  STOCKS: 'STOCKS',
  STOCK_CREATE: 'STOCK_CREATE',
  STOCK_DETAIL: 'STOCK_DETAIL',
  STOCK_ENTRY: 'STOCK_ENTRY',
  STOCK_ENTRY_CREATE: 'STOCK_ENTRY_CREATE',
  STOCK_ENTRY_DELETE: 'STOCK_ENTRY_DELETE',
  STOCK_ENTRY_UPDATE: 'STOCK_ENTRY_UPDATE',
  STOCK_FORM: 'STOCK_FORM',
  STOCK_TRANSFER: 'STOCK_TRANSFER',
  STOCK_TRANSFERS: 'STOCK_TRANSFERS',
  STOCK_TRANSFER_CREATE: 'STOCK_TRANSFER_CREATE',
  STOCK_TRANSFER_DELETE: 'STOCK_TRANSFER_DELETE',
  STOCK_TRANSFER_DETAIL: 'STOCK_TRANSFER_DETAIL',
  STOCK_TRANSFER_FORM: 'STOCK_TRANSFER_FORM',
  STOCK_TRANSFER_NEW: 'STOCK_TRANSFER_NEW',
  STOCK_TRANSFER_UPDATE: 'STOCK_TRANSFER_UPDATE',
  STOCK_UPDATE: 'STOCK_UPDATE',
  STATISTICS_DETAIL: 'STATISTICS_DETAIL',
  SUPPLIERS: 'SUPPLIERS',
  SUPPLIER_CREATE: 'SUPPLIER_CREATE',
  SUPPLIER_DETAIL: 'SUPPLIER_DETAIL',
  SUPPLIER_FORM: 'SUPPLIER_FORM',
  SUPPLIER_UPDATE: 'SUPPLIER_UPDATE',
  TALLY_INTEGRATION: 'TALLY_INTEGRATION',
  TAXES: 'TAXES',
  TAX_CATEGORIES: 'TAX_CATEGORIES',
  TAX_CATEGORY_CREATE: 'TAX_CATEGORY_CREATE',
  TAX_CATEGORY_FORM: 'TAX_CATEGORY_FORM',
  TAX_CATEGORY_UPDATE: 'TAX_CATEGORY_UPDATE',
  TAX_CREATE: 'TAX_CREATE',
  TAX_FORM: 'TAX_FORM',
  TAX_UPDATE: 'TAX_UPDATE',
  USER: 'USER',
  USERS: 'USERS',
  USERS_GROUPS: 'USERS_GROUPS',
  USERS_GROUPS_DETAIL: 'USERS_GROUPS_DETAIL',
  USERS_GROUP_CREATE: 'USERS_GROUP_CREATE',
  USERS_GROUP_FORM: 'USERS_GROUP_FORM',
  USERS_GROUP_UPDATE: 'USERS_GROUP_UPDATE',
  USER_CREATE: 'USER_CREATE',
  USER_DETAIL: 'USER_DETAIL',
  USER_FORM: 'USER_FORM',
  USER_UPDATE: 'USER_UPDATE',
  VOUCHER_BOOKS: 'VOUCHER_BOOKS',
  VOUCHER_BOOK_DETAIL: 'VOUCHER_BOOK_DETAIL',
  WAREHOUSES: 'WAREHOUSES',
  WAREHOUSE_CREATE: 'WAREHOUSE_CREATE',
  WAREHOUSE_FORM: 'WAREHOUSE_FORM',
  WAREHOUSE_UPDATE: 'WAREHOUSE_UPDATE',
  WAREHOUSE_DETAIL: 'WAREHOUSE_DETAIL',
  HELP_VIDEO: 'HELP_VIDEO',
  WINDOW: 'WINDOW',
  ZONES: 'ZONES',
  ZONE_CREATE: 'ZONE_CREATE',
  ZONE_FORM: 'ZONE_FORM',
  ZONE_UPDATE: 'ZONE_UPDATE',
};

export const ActionEnum = {
  ACCOUNTING_REPORT_TAB_CHANGE: 'Accounting_Report_Tab_Change',
  ACCOUNT_AMOUNTS_FILTER: 'Account_Amounts_Filter',
  ACCOUNT_ENTRY_FILTER: 'Account_Entry_Filter',
  ACCOUNT_ENTRY_SHOW: 'Account_Entry_Show',
  ACCOUNT_RECONCILIATION_HISTORY_FILTER: 'Account_Reconciliation_History_Filter',
  ACCOUNT_REPORT: 'Account_Report',
  ACCOUNT_SHOW: 'Account_Show',
  ACCOUNT_STATEMENT_ACTION: 'Account_Statement_Action',
  ACCOUNT_STATEMENT_APPLY_FILTERS: 'Account_Statement_Apply_Filters',
  ACCOUNT_STATEMENT_CLEAR_FILTERS: 'Account_Statement_Clear_Filters',
  ADD_AUTHORISE_USER: 'Add_Authorise_User',
  ADVANCED_SEARCH: 'Advanced_Search',
  AGING_REPORT: 'Aging_Report',
  AUTHORISATION_CONDITIONS_SHOW: 'Authorisation_Conditions_Show',
  AUTHORISATION_CONDITION_NEW: 'Authorisation_Condition_New',
  BANK_ACCOUNT_NEW: 'Bank_Account_New',
  BANK_NEW: 'Bank_New',
  BEAT_ROUTES_SHOW: 'Beat_Routes_Show',
  BEAT_ROUTE_ASSIGNMENT_NEW: 'Beat_Route_Assignment_New',
  BEAT_ROUTE_ITEM_ACTION: 'Beat_Route_Item_Action',
  BEAT_ROUTE_ITEM_ADD: 'Beat_Route_Item_Add',
  BEAT_ROUTE_ITEM_REMOVE: 'Beat_Route_Item_Remove',
  BEAT_ROUTE_NEW: 'Beat_Route_New',
  BRANCH_NEW: 'Branch_New',
  BRANCH_SHOW: 'Branch_Show',
  BRANCH_WAREHOUSE_ITEM_ACTION: 'Branch_Warehouse_Item_Action',
  BRANCH_WAREHOUSE_ITEM_ADD: 'Branch_Warehouse_Item_Add',
  BRANCH_WAREHOUSE_ITEM_REMOVE: 'Branch_Warehouse_Item_Remove',
  BRANCH_ZONE_ITEM_ACTION: 'Branch_Zone_Item_Action',
  BRANCH_ZONE_ITEM_ADD: 'Branch_Zone_Item_Add',
  BRANCH_ZONE_ITEM_REMOVE: 'Branch_Zone_Item_Remove',
  BREADCRUMB: 'Breadcrumb',
  CHANGE_PASSWORD: 'Change_Password',
  UPDATE_ACCOUNT_PROFILE: 'Update_Account_Profile',
  CHART_OF_ACCOUNT_NEW: 'Chart_Of_Account_New',
  CHART_OF_ACCOUNT_SHOW: 'Chart_Of_Account_Show',
  BRAND_NEW: 'Brand_New',
  BRAND_SHOW: 'Brand_Show',
  COPY: 'Copy',
  CREDIT_LEEWAY_REPORT: 'Credit_Leeway_Report',
  CREDIT_NOTE_BULK_DOWNLOAD: 'Credit_Note_Bulk_Download',
  CREDIT_NOTE_DOWNLOAD: 'Credit_Note_Download',
  CREDIT_NOTE_INVOICE_ACTION: 'User_Branch_Item_Action',
  CREDIT_NOTE_INVOICE_ADD: 'User_Branch_Item_Add',
  CREDIT_NOTE_INVOICE_NEW: 'Credit_Note_Invoice_New',
  CREDIT_NOTE_ITEM_ACTION: 'Credit_Note_Item_Action',
  CREDIT_NOTE_ITEM_ADD: 'Credit_Note_Item_Add',
  CREDIT_NOTE_ITEM_REMOVE: 'Credit_Note_Item_Remove',
  CREDIT_NOTE_NEW: 'Credit_Note_New',
  CREDIT_NOTE_REPORT: 'Credit_Note_Report',
  CREDIT_NOTE_SHOW: 'Credit_Note_Show',
  CREDIT_REASON_NEW: 'Credit_Reason_New',
  CUSTOMER_CATEGORY_NEW: 'Customer_Category_New',
  CUSTOMER_DOWNLOAD: 'Customer_Download',
  CUSTOMER_FILTER: 'Customer_Filter',
  CUSTOMER_NEW: 'Customer_New',
  CUSTOMER_RATING_CREDIT_CONTROL_ITEM_ACTION: 'Customer_Rating_Credit_Control_Item_Action',
  CUSTOMER_RATING_CREDIT_CONTROL_ITEM_ADD: 'Customer_Rating_Credit_Control_Item_Add',
  CUSTOMER_RATING_CREDIT_CONTROL_ITEM_REMOVE: 'Customer_Rating_Credit_Control_Item_Remove',
  CUSTOMER_RATING_NEW: 'Customer_Rating_New',
  CUSTOMER_REPORT: 'Customer_Report',
  CUSTOMER_SHOW: 'Customer_Show',
  CUSTOMER_SHOW_TAB_CHANGE: 'Customer_Show_Tab_Change',
  DAYBOOK: 'Daybook',
  DAYBOOK_TAB_CHANGE: 'Daybook_Tab_Change',
  DEBIT_NOTE_BULK_DOWNLOAD: 'Debit_Note_Bulk_Download',
  DEBIT_NOTE_BULK_OPTIONS: 'Debit_Note_Bulk_Options',
  DEBIT_NOTE_DOWNLOAD: 'Debit_Note_Download',
  DEBIT_NOTE_INVOICE_NEW: 'Debit_Note_Invoice_New',
  DEBIT_NOTE_ITEM_ACTION: 'Debit_Note_Item_Action',
  DEBIT_NOTE_ITEM_ADD: 'Debit_Note_Item_Add',
  DEBIT_NOTE_ITEM_REMOVE: 'Debit_Note_Item_Remove',
  DEBIT_NOTE_NEW: 'Debit_Note_New',
  DEBIT_NOTE_REPORT: 'Debit_Note_Report',
  DEBIT_NOTE_SHOW: 'Debit_Note_Show',
  DEBIT_REASON_NEW: 'Debit_Reason_New',
  DEFAULT_DAC_ROLE_NEW: 'Default_Dac_Role_New',
  DOWNLOAD: 'Download',
  DOWNLOAD_NEW: 'Download_New',
  EMAIL_ADD: 'Email_Add',
  EMAIL_ATTACHMENT_DOWNLOAD: 'Email_Attachment_Download',
  EXPORT_REPORT: 'Export_Report',
  GENERATE: 'Generate',
  GENERATE_DEBIT_NOTE: 'Generate_Debit_Note',
  GENERATE_INVOICE: 'Generate_Invoice',
  GST_REPORT: 'GST_Report',
  IMAGE_REMOVE: 'Image_Remove',
  IMAGE_UPLOAD: 'Image_Upload',
  INVENTORY_TRACKING_NEW: 'Inventory_Tracking_New',
  INVENTORY_TRACKING_SHOW: 'Inventory_Tracking_Show',
  INVENTORY_BATCH_NEW: 'Inventory_Batch_New',
  INVENTORY_BATCH_SHOW: 'Inventory_Batch_Show',
  INVENTORY_BATCH_ITEM_ACTION: 'Inventory_Batch_Item_Action',
  INVENTORY_BATCH_ITEM_ADD: 'Inventory_Batch_Item_Add',
  INVENTORY_BATCH_ITEM_REMOVE: 'Inventory_Batch_Item_Remove',
  INVENTORY_PIECE_NEW: 'Inventory_Piece_New',
  INVENTORY_PIECE_SHOW: 'Inventory_Piece_Show',
  INVENTORY_PIECE_ITEM_ACTION: 'Inventory_Piece_Item_Action',
  INVENTORY_PIECE_ITEM_ADD: 'Inventory_Piece_Item_Add',
  INVENTORY_PIECE_ITEM_REMOVE: 'Inventory_Piece_Item_Remove',
  INVENTORY_IDENTIFICATION_NEW: 'Inventory_Identification_New',
  INVENTORY_IDENTIFICATION_SHOW: 'Inventory_Identification_Show',
  INVOCE_PAYMENT_ACTION: 'Invoice_Payment_Action',
  INVOCE_PAYMENT_ADD: 'Invoice_Payment__Add',
  INVOICE_BULK_DOWNLOAD: 'Invoice_Bulk_Download',
  INVOICE_BULK_OPTIONS: 'Invoice_Bulk_Options',
  INVOICE_DOWNLOAD: 'Invoice_Download',
  INVOICE_ITEM_ACTION: 'Invoice_Item_Action',
  INVOICE_ITEM_REMOVE: 'Invoice_Item_Remove',
  INVOICE_NEW: 'Invoice_New',
  INVOICE_DISPATCHED: 'Invoice_Dispatched',
  INVOICE_RECALCULATE: 'Invoice_Recalculate',
  INVOICE_REPORT: 'Invoice_Report',
  INVOICE_REPORT_WITH_ITEMS: 'Invoice_Report_With_Items',
  INVOICE_SHOW: 'Invoice_Show',
  INVOICE_TERM_NEW: 'Invoice_Term_New',
  INVOICE_TO_DELIVERED: 'Invoice_To_Delivered',
  JOURNAL_ENTRY_NEW: 'Accounting_Entry_New',
  LEDGER_DOWNLOAD: 'Ledger_Download',
  MASTER_ALL_LEDGER: 'Master_All_Ledger',
  MASTER_INVENTORY: 'Master_Inventory',
  NORMAL_SEARCH: 'Normal_Search',
  OFFER_ITEM_ACTION: 'Offer_Item_Action',
  OFFER_ITEM_ADD: 'Offer_Item_Add',
  OFFER_ITEM_REMOVE: 'Offer_Item_Remove',
  OFFER_NEW: 'Offer_New',
  OFFER_SHOW: 'Offer_Show',
  ORDERS_BULK_OPTIONS: 'Order_Bulk_Options',
  ORDERS_INDIVIDUAL_OPTIONS: 'Order_Individual_Options',
  ORDERS_REPORT: 'Orders_Report',
  ORDER_BULK_CANCEL_REMAINING_QUANTITY: 'Orders_Bulk_Cancel_Remaining_Quantity',
  ORDER_BULK_CONFIRM: 'Orders_Bulk_Confirm',
  ORDER_BULK_CREATE_INVOICES: 'Orders_Bulk_Create_Invoices',
  ORDER_DOWNLOAD: 'Order_Download',
  ORDER_GENERATE_INVOICE: 'Order_Generate_Invoice',
  ORDER_ITEM_ACTION: 'Order_Item_Action',
  ORDER_ITEM_ADD: 'Order_Item_Add',
  ORDER_ITEM_REMOVE: 'Order_Item_Remove',
  ORDER_NEW: 'Order_New',
  ORDER_PACKAGING_LIST_CUSTOMER_WISE: 'Orders_Packaging_List_customer_wise',
  ORDER_PACKAGING_LIST_PRODUCT_WISE: 'Orders_Packaging_List_product_wise',
  ORDER_SHOW: 'Order_Show',
  ORDER_TERM_NEW: 'Order_Term_New',
  ORDER_TERM_FORM: 'Order_Term_form',
  OUTSTANDING_BALANCE_REPORT: 'Outstanding_Balance_Report',
  PAYMENT_AS_ACCOUNTED: 'Payment_As_Accounted',
  PAYMENT_BULK_OPTIONS: 'Payment_Bulk_Options',
  PAYMENT_DOWNLOAD: 'Payment_Download',
  PAYMENT_INVOICE_ITEM_ACTION: 'Payment_Invoice_Item_Action',
  PAYMENT_INVOICE_ITEM_ADD: 'Payment_Invoice_Item_Add',
  PAYMENT_INVOICE_ITEM_REMOVE: 'Payment_Invoice_Item_Remove',
  PAYMENT_NEW: 'Payment_New',
  PAYMENT_OUT_AS_ACCOUNTED: 'Payment_Out_As_Accounted',
  PAYMENT_OUT_BULK_OPTIONS: 'Payment_Out_Bulk_Options',
  PAYMENT_OUT_DOWNLOAD: 'Payment_Out_Download',
  PAYMENT_OUT_NEW: 'Payment_Out_New',
  PAYMENT_OUT_REPORT: 'Payment_Out_Report',
  PAYMENT_OUT_SHOW: 'Payment_Out_Show',
  PAYMENT_REPORT: 'Payment_Report',
  PAYMENT_SHOW: 'Payment_Show',
  PERIOD_LOCK_NEW: 'Period_Lock_New',
  PERIOD_LOCK_SHOW: 'Period_Lock_Show',
  PRODUCT_CATEGORY_NEW: 'Product_Category_New',
  PRODUCT_CATEGORY_SHOW: 'Product_Category_Show',
  PRODUCT_FORM_TAB_CHANGE: 'Product_Form_Tab_Change',
  PRODUCT_NEW: 'Product_New',
  PRODUCT_PACKAGE_ITEM_ACTION: 'Product_Package_Item_Action',
  PRODUCT_PACKAGE_ITEM_ADD: 'Product_Package_Item_Add',
  PRODUCT_PACKAGE_ITEM_REMOVE: 'Product_Package_Item_Remove',
  PRODUCT_PRICE_ITEM_ACTION: 'Product_Price_Item_Action',
  PRODUCT_PRICE_ITEM_ADD: 'Product_Price_Item_Add',
  PRODUCT_PRICE_ITEM_REMOVE: 'Product_Price_Item_Remove',
  PRODUCT_SHOW: 'Product_Show',
  PRINT_FORMATS_SAVE: 'Print_format_save',
  PRINT_FORMATS_UPDATE: 'Print_format_update',
  PURCHASE_INVOICE_ITEM_ACTION: 'Purchase_Invoice_Item_Action',
  PURCHASE_INVOICE_ITEM_ADD: 'Purchase_Invoice_Item_Add',
  PURCHASE_INVOICE_ITEM_REMOVE: 'Purchase_Invoice_Item_Remove',
  PURCHASE_INVOICE_TAX_ACTION: 'Purchase_Invoice_Tax_Action',
  PURCHASE_INVOICE_TAX_ADD: 'Purchase_Invoice_Tax_Add',
  PURCHASE_INVOICE_TAX_REMOVE: 'Purchase_Invoice_Tax_Remove',
  PURCHASE_INVOICE_NEW: 'Purchase_Invoice_New',
  PURCHASE_INVOICE_REPORT: 'Purchase_Invoice_Report',
  PURCHASE_INVOICE_SHOW: 'Purchase_Invoice_Show',
  PURCHASE_ORDER_DOWNLOAD: 'Purchase_Order_Download',
  PURCHASE_ORDER_GENERATE_PURCHASE_INVOICE: 'Purchase_Order_Generate_Purchase_Invoice',
  PURCHASE_ORDER_ITEM_ACTION: 'Purchase_Order_Item_Action',
  PURCHASE_ORDER_ITEM_ADD: 'Purchase_Order_Item_Add',
  PURCHASE_ORDER_ITEM_REMOVE: 'Purchase_Order_Item_Remove',
  PURCHASE_ORDER_NEW: 'Purchase_Order_New',
  PURCHASE_ORDER_TERM_NEW: 'Purchase_Order_Term_New',
  PURCHASE_ORDER_REPORT: 'Purchase_Order_Report',
  PURCHASE_ORDER_SHOW: 'Purchase_Order_Show',
  PURCHASE_RETURN_SHOW: 'Purchase_Return_Show',
  RECONCILIATION_NEW: 'RECONCILIATION_NEW',
  REPORT_DELETE: 'Report_Delete',
  REPORT_DOWNLOAD: 'Report_Download',
  REPORT_DOWNLOADS: 'Report_Downloads',
  REPORT_SHOW: 'Report_Show',
  RETURN_AS_APPROVED: 'Return_As_Approved',
  RETURN_BULK_OPTIONS: 'Return_Bulk_Options',
  RETURN_ITEM_ACTION: 'Return_Item_Action',
  RETURN_ITEM_ADD: 'Return_Item_Add',
  RETURN_ITEM_REMOVE: 'Return_Item_Remove',
  RETURN_NEW: 'Retrun_New',
  RETURN_REPORT: 'Return_Report',
  RETURN_SHOW: 'Return_Show',
  SALESMAN_BRAND_REPORT: 'Salesman_Brand_Report',
  SALESMAN_PERFORMANCE_REPORT: 'Salesman_Performance_Report',
  SEARCH: 'Search',
  SETTINGS: 'Settings',
  SETTINGS_SAVE: 'Settings_Save',
  SETTINGS_TAB_CHANGE: 'Settings_Tab_Change',
  SIDEBAR_TOGGLE: 'Sidebar_Toggle',
  SIDENAV_CLICK: 'Sidenav_Click',
  SIDENAV_TOGGLE: 'Sidenav_Toggle',
  SIGN_OUT: 'Sign_Out',
  SORTING: 'Sorting',
  SAVE_CUSTOMIZED_REPORT: 'SAVE_CUSTOMIZED_REPORT',
  STATEMENT_DOWNLOAD: 'Statement_Download',
  STATE_CHANGE: 'State_Change',
  STOCK_ENTRY_FILTER: 'Stock_Entry_Filter',
  STOCK_ENTRY_NEW: 'Stock_Entry_New',
  STOCK_NEW: 'Stock_New',
  STOCK_SHOW: 'Stock_Show',
  STOCK_TRANSFER_DOWNLOAD: 'Stock_Transfer_Download',
  SUPPLIER_NEW: 'Supplier_New',
  SUPPLIER_SHOW: 'Supplier_Show',
  TAX_CATEGORY_NEW: 'Tax_Category_New',
  TAX_CATEGORY_TAX_RATE_ITEM_ACTION: 'Tax_Category_Tax_Rate_Item_Action',
  TAX_CATEGORY_TAX_RATE_ITEM_ADD: 'Tax_Category_Tax_Rate_Item_Add',
  TAX_CATEGORY_TAX_RATE_ITEM_REMOVE: 'Tax_Category_Tax_Rate_Item_Remove',
  TAX_NEW: 'Tax_New',
  TRANSACTION_HISTORY_TAB_CHANGE: 'Transaction_History_Tab_Change',
  USERS_GROUPS_SHOW: 'Users_Groups_Show',
  USER_BRANCH_ITEM_ACTION: 'User_Branch_Item_Action',
  USER_BRANCH_ITEM_ADD: 'User_Branch_Item_Add',
  USER_BRANCH_ITEM_REMOVE: 'User_Branch_Item_Remove',
  USER_GROUP_NEW: 'User_Group_New',
  USER_NEW: 'User_New',
  USER_RECENT_CREDIT_LEEWAY_FILTER: 'User_Recent_Credit_Leeway_Filter',
  USER_SHOW: 'User_Show',
  USER_SHOW_BEAT_ROUTE_ASSIGNMENT_ADD: 'User_Show_Beat_Route_Assignment_Add',
  USER_SHOW_TAB_CHANGE: 'User_Show_Tab_Change',
  USER_SHOW_TAB_ITEM: 'User_Show_Tab_Item',
  VOUCHER_BOOK_NEW: 'Voucher_Book_New',
  VOUCHER_BOOK_SHOW: 'Voucher_Book_Show',
  WAREHOUSE_NEW: 'Warehouse_New',
  WAREHOUSE_SHOW: 'Warehouse_Show',
  WAREHOUSE_DOWNLOAD: 'WAREHOUSE_DOWNLOAD',
  ZONE_NEW: 'Zone_New',
};

// WHY  they are the actions which are used commonly, like adv. search, search, per page
//      structure: `${which page or section}_${module which is effected}_${action label}`
//      they have to be used in this manner => `${moduleItem}_${commonActionLabel}`
//      eg: `ORDER_Index_Per_Page`, `PAYMENT_Index_Comment_Add`
export const CommonActionLabelEnum = {
  INDEX_VARIANT_SELECTION: 'Index_Variant_Selection',
  VARIANT_SELECTION: 'Variant_Selection',
  CLEAR_FILTERS: 'Clear_Filters',
  APPLY_FILTERS: 'Apply_Filters',
  INDEX_REFRESH_ITEMS: 'Index_Refresh_Items',
  VIEW_ITEMS: 'View_Items',
  REFRESH_ITEMS: 'Refresh_Items',
  INDEX_SEARCH: 'Index_Search',
  FETCH_AUDIT_HISTORY: 'Fetch_Audit_History',
  FETCH_MORE_AUDIT_HISTORY: 'Fetch_More_Audit_History',
};
