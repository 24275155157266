import * as types from '@constants/auth';
import { handleErrors } from '@utils/handleErrors';
import { resetReducerState, setCurrentUser } from '@actions/auth';
// import { fetchSettings, fetchUiPermissions } from '@saga/root';
import {
  CSRF_TOKEN_FOR_BE, CSRF_TOKEN_FOR_FE, AUTH_TOKEN_HEADER_FOR_BE,
  AUTH_TOKEN_HEADER_FOR_FE, USER_MOBILE_HEADER_FOR_BE,
} from '@utils/common_constants';
import axios, { fetchingRequest } from '@utils/axios';
import { clearAllLocalStorage, getDomain } from '@helpers/common_helper';
import history from '@component/history';
import {
  DEFAULT_COOKIE_STORAGE_PERIOD, deleteAllCookies, setCookie,
} from '@helpers/browserStorageManager';
import { handleTenantAccess } from '@helpers/tenant_access';
// import store from '../store/store';

// /v1/user/sign_in
export function login(userCredential) {
  const userMobile = userCredential.mobile;

  fetchingRequest(types.CURRENT_USER);
  return (dispatch) => axios({
    url: '/account/sign_in',
    method: 'post',
    data: {
      account: userCredential,
    },
    headers: {
      [USER_MOBILE_HEADER_FOR_BE]: userMobile,
    },
  })
    .then((response) => {
      // Fetch settings and permissions after successful login.

      // const stringifiedRequestPayload = JSON.stringify(requestPayload);
      const { data, headers } = response || {};
      const account = data?.account || {};

      const authToken = headers?.[AUTH_TOKEN_HEADER_FOR_FE] || '';
      const csrfToken = headers?.[CSRF_TOKEN_FOR_FE] || '';

      localStorage.setItem(AUTH_TOKEN_HEADER_FOR_BE, authToken);
      localStorage.setItem(USER_MOBILE_HEADER_FOR_BE, userMobile);
      localStorage.setItem(CSRF_TOKEN_FOR_BE, csrfToken);
      const domain = getDomain();

      setCookie(AUTH_TOKEN_HEADER_FOR_BE, authToken, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
      setCookie(USER_MOBILE_HEADER_FOR_BE, userMobile, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
      setCookie(CSRF_TOKEN_FOR_BE, csrfToken, DEFAULT_COOKIE_STORAGE_PERIOD, domain);

      dispatch(setCurrentUser(true, account, false, {}));

      handleTenantAccess({ account });

      return response;
    })
    .catch((error) => {
      handleErrors(error, '/login', types.CURRENT_USER);
      throw error.response;
    });
}

// /v1/user/sign_out
export function logout() {
  fetchingRequest(types.CURRENT_USER);
  return (dispatch) => axios({
    url: '/account/sign_out',
    method: 'delete',
  })
    .then((response) => {
      // dispatch(setCurrentUser(false, {}, false, {}));
      // clearAuthDetailsFromBrowserStorage();

      dispatch(resetReducerState());
      clearAllLocalStorage();
      // const tenantName = getCookie(RECENT_USED_TENANT);
      deleteAllCookies();
      history.push('/');
      // const domain = getDomain();
      // setCookie(RECENT_USED_TENANT, tenantName, DEFAULT_COOKIE_STORAGE_PERIOD, domain);

      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.CURRENT_USER);
      // On error in logout, delete local storage & reducer data and redirect the user to login page
      dispatch(resetReducerState());
      clearAllLocalStorage();
      // const tenantName = getCookie(RECENT_USED_TENANT);
      deleteAllCookies();
      // const domain = getDomain();
      // setCookie(RECENT_USED_TENANT, tenantName, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
      history.push('/login');
      throw error.response;
    });
}

// export function changePassword(args) {
//   const { user } = args;
//   return () => axios({
//     url: '/user/edit',
//     method: 'put',
//     data: {
//       user,
//     },
//   })
//     .then((response) => response)
//     .catch((error) => {
//       handleErrors(error, '/account/password', types.CURRENT_USER);
//       throw error.response;
//     });
// }

export function fetchTenantDetails(baseUrl) {
  return () => axios({
    url: '/tenants/current',
    method: 'GET',
    baseURL: baseUrl,
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/login', types.CURRENT_USER, false);
      throw error;
    });
}
