import { call, put, takeLatest } from '@redux-saga/core/effects';
import { BE_CONTROLLER_VERSION } from '@utils/common_constants';
// eslint-disable-next-line import/no-cycle
import axios from '../utils/axios';
import store from '../store/store';
import { setLocalStorage, arrayBufferToString } from '../helpers/common_helper';
import { handleErrors } from '../utils/handleErrors';
import { ORDER } from '../constants/orders';
import { updateOrdersStatusToProcessing } from '../actions/orders';
import {
  setClientSettings, setIsAdsBlockDetected, setClientUiPermissions, setGlobalSettings,
} from '../actions/root';
import { setNotices } from '../actions/notices';
import { serializeParams } from '../utils/SerializeParams';

type DownloadPdfArgs = {
  url: string;
  params: any;
  redirectTo: string;
  identifier: string;

  // Optional
  key?: string;
  requestType?: 'get' | 'post';
  shouldOpen?: boolean;
  shouldDownloadForGet?: boolean;
}

const handleArrBufferErr = (error, redirectTo, identifier) => {
  // Convert array buffer data type to JSON object
  const { response } = error;
  const { data } = response;
  const dataString = arrayBufferToString(data);
  const modError = error;
  modError.response.data = JSON.parse(dataString);
  handleErrors(modError, redirectTo, identifier);
  throw error.response;
};

export const fetchSettings = () => axios({
  url: '/configuration/global',
  method: 'get',
})
  .then((response) => {
    const { configuration } = response.data;
    const { settings } = configuration;

    const simplefiedSettings = {};
    const globalSettings = {};

    settings.forEach((setting) => {
      simplefiedSettings[setting.var] = setting.value;
      globalSettings[setting.var] = setting;
    });

    setLocalStorage({ moduleName: '', key: 'settings', data: simplefiedSettings });
    setLocalStorage({ moduleName: '', key: 'global_settings', data: globalSettings });
    store.dispatch(setClientSettings(simplefiedSettings));
    store.dispatch(setGlobalSettings(globalSettings));
    return response;
  })
  .catch((error) => {
    // throw error.response;
  });

export const fetchI18nFields = () => axios({
  url: '/tenants/current/i18n_fields',
  method: 'get',
})
  .then((response) => {
    const i18nFields = response?.data?.data || {};
    setLocalStorage({ moduleName: '', key: 'i18nFields', data: i18nFields });
    return response;
  })
  .catch((error) => ({}));

export const fetchUiPermissions = () => {
  store.dispatch(setClientUiPermissions({ isFetching: true }));
  axios({
    url: '/granted_permissions',
    method: 'get',
  })
    .then((response) => {
      const { data } = response.data;

      setLocalStorage({ moduleName: '', key: 'ui_permissions', data });
      store.dispatch(setClientUiPermissions({ ...data, isFetching: false }));
      return response;
    })
    .catch((error) => {
      store.dispatch(setClientUiPermissions({ isFetching: false }));
    });
};

export const fetchNotices = () => {
  axios({ url: '/notices', method: 'GET' })
    .then(({ data }) => {
      const { notices } = data;
      store.dispatch(setNotices({ items: notices }));
    });
};

// NOTE   in the function parameters, for `identifier` should be `item` from constants
//        for eg: ORDER, INVOICE, CREDIT_NOTE ..etc.,
export function downloadPdf({
  url, requestType, key, params, redirectTo, identifier,
  shouldOpen = true, shouldDownloadForGet = false,
}: DownloadPdfArgs) {
  const adsBlockEle = document.getElementById('ads-block-detected');
  if (adsBlockEle && adsBlockEle.offsetHeight > 0) {
    const k = key || 'params';

    const resType = requestType || 'get';
    let processRequest = Promise.resolve({ data: '' });

    if (resType === 'get') {
      // WHAT  if `shouldOpen` is true, the browser will open link in a new tab
      if (shouldOpen) {
        const pdfUrl = `/${BE_CONTROLLER_VERSION}${url}?${serializeParams(params)}`;
        window.open(pdfUrl);
        return (dispatch) => {
          dispatch(setIsAdsBlockDetected(false));
          return processRequest;
        };
      }

      // WHAT   this condition is there to download the file when we need to show file name
      if (!shouldOpen && shouldDownloadForGet) {
        processRequest = axios.get(url, { responseType: 'arraybuffer', params });
        return (dispatch) => processRequest
          .then((response) => {
            dispatch(setIsAdsBlockDetected(false));
            return response;
          })
          .catch((error) => {
            dispatch(setIsAdsBlockDetected(false));
            handleArrBufferErr(error, redirectTo, identifier);
          });
      }
    } else if (resType === 'post') {
      const body = { [k]: params };
      processRequest = axios.post(url, body, { responseType: 'arraybuffer' });

      return (dispatch) => processRequest
        .then((response) => {
          // const { headers, data } = response;
          const { data } = response;
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // WHAT  if `shouldOpen` is true, the browser will open link in a new tab
          if (shouldOpen) {
            window.open(fileURL);
          }
          dispatch(setIsAdsBlockDetected(false));
          if ((identifier === ORDER) && (url.includes('generate_packing_list')) && params.order_ids) {
            dispatch(updateOrdersStatusToProcessing(params.order_ids));
          }
          // saveAs(file, headers['file-name']);
          return response;
        })
        .catch((error) => {
          dispatch(setIsAdsBlockDetected(false));
          handleArrBufferErr(error, redirectTo, identifier);
        });
    }
  }
  return (dispatch) => new Promise((resolve) => {
    dispatch(setIsAdsBlockDetected(true));
    resolve('');
  });
}
