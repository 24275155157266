import { HOME_BREADCRUMB_LISTING } from './common';

// We use this constants in reducer and actions.
export const PRODUCT = 'PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_VARIANT = 'ADD_VARIANT';
export const PRODUCT_FETCHED = 'PRODUCT_FETCHED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const PRODUCT_DELETED = 'PRODUCT_DELETED';
export const RESET_PRODUCT_ERRORS = 'RESET_PRODUCT_ERRORS';
export const AUTOCOMPLETE_PRODUCTS = 'AUTOCOMPLETE_PRODUCTS';

// Redux saga constants
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const AUTOCOMPLETE_ORDERABLE_PRODUCTS = 'AUTOCOMPLETE_ORDERABLE_PRODUCTS';
export const AUTOCOMPLETE_ALL_PRODUCTS = 'AUTOCOMPLETE_ALL_PRODUCTS';
export const AUTOCOMPLETE_RETURNABLE_BY_CUSTOMER_PRODUCTS = 'AUTOCOMPLETE_RETURNABLE_BY_CUSTOMER_PRODUCTS';
export const AUTOCOMPLETE_SELLABLE_PRODUCTS = 'AUTOCOMPLETE_SELLABLE_PRODUCTS';
export const AUTOCOMPLETE_BASIC_INFO_PRODUCTS = 'AUTOCOMPLETE_BASIC_INFO_PRODUCTS';
export const FETCH_SELLABLE_PRODUCTS = 'FETCH_SELLABLE_PRODUCTS';

export const SET_ORDERABLE_PRODUCTS = 'SET_ORDERABLE_PRODUCTS';
export const SET_PRODUCTS_RETURNABLE_BY_CUSTOMER = 'SET_PRODUCTS_RETURNABLE_BY_CUSTOMER';
export const SET_SELLABLE = 'SET_SELLABLE';
export const FEED_FILE_UPLOADED = 'FEED_FILE_UPLOADED';

export const FORM_INFORMATION_TITLE = 'PRODUCT INFORMATION';
export const ADD_NEW_PRODUCT = 'Add New Product';

export const title = 'Products';
export const singularTitle = 'Product';
export const moduleName = 'products';
export const baseUrl = '/products';
export const redirectUrl = '/products';
export const listingDataKey = 'products';
export const itemDataKey = 'product';

export const productConstants = {
  item: PRODUCT,
  setItems: SET_PRODUCTS,
  addItem: ADD_PRODUCT,
  setItem: PRODUCT_FETCHED,
  updateItem: PRODUCT_UPDATED,
  deleteItem: PRODUCT_DELETED,
  resetErrors: RESET_PRODUCT_ERRORS,
  autocompleteItems: AUTOCOMPLETE_PRODUCTS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// Product state ie. Active/Inactive
export const Active = 'Active';
export const Inactive = 'Inactive';
export const STATES = [{ label: Active, value: true }, { label: Inactive, value: false }];

export const productType = 'Product';
export const serviceType = 'Service';
export const types = [
  { value: productType, label: productType },
  { value: serviceType, label: serviceType },
];

export const PRODUCT_TYPES = [
  { label: 'All products', value: '' },
  { label: 'Simple and parent products', value: true },
];

// We use this constant for as a loader name in product advance search.
export const BRAND = 'brand';
export const PRODUCT_CATEGORY = 'product_category';
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES';
export const UPLOAD_PRODUCT_IMAGES_FORM = 'UPLOAD_PRODUCT_IMAGES_FORM';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const VARIANT_FORM = 'VARIANT_FORM';
export const PRODUCT_FORM = 'PRODUCT_FORM';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const ACCOUNT_INFO = 'ACCOUNT_INFO';

export const advanceSearchParams = {
  active_eq: '',
  brand_id_in: [],
  product_category_id_in: [],
  for_tax_category_ids: [],
  tax_category_id_null: '',
  hsn_code_blank: '',
  id_in: [],
  parent_id_blank: '',
};

export const defaultSearchParams = {
  sku_or_barcode_or_name_or_variants_name_cont: '',
  ...advanceSearchParams,
};

export const PRODUCT_WITHOUT_TAXES_OPTIONS = [{ label: 'Tax inclusive products', value: 'false' }, { label: 'Tax exclusive products', value: true }];
export const PRODUCT_WITHOUT_HSN_CODE_OPTIONS = [{ label: 'Products with HSN/SAC', value: 'false' }, { label: 'Products without HSN/SAC', value: true }];

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['brands', 'product_categories', 'tax_categories', 'products'];

// Breadcrumb
export const Title = 'Products';
export const TargetBaseUrl = 'products';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

const current_prices = ['valid_from', 'customer_category', 'customer_category_id', 'inclusive_tax', 'min_price', 'price'];
const product_packages = ['unit', 'primary_unit_multiplier'];

export const handledErrorKeys = {
  master: ['type', 'variantable', 'name', 'sku', 'description', 'unit', 'moq', 'mrp', 'barcode', 'tax_category', 'tax_category_id', 'hsn_code', 'brand', 'brand_id', 'product_category', 'product_category_id', 'inventory_tracking', 'inventory_identification_id', 'weight', 'width', 'length', 'height', 'purchase_account', 'purchase_account_id', 'sales_account', 'sales_account_id', 'purchase_price', 'purchase_inclusive_tax'],
  current_prices,
  product_packages,
};

export const variantHandledErrorKeys = {
  master: ['type', 'variantable', 'name', 'sku', 'description', 'unit', 'moq', 'mrp', 'barcode', 'tax_category', 'tax_category_id', 'hsn_code', 'brand', 'brand_id', 'product_category', 'product_category_id', 'inventory_tracking', 'inventory_identification_id', 'weight', 'width', 'length', 'height', 'purchase_account', 'purchase_account_id', 'sales_account', 'sales_account_id', 'purchase_price', 'purchase_inclusive_tax'],
  current_prices,
  product_packages,
};
