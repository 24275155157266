export const ADDRESSES = 'ADDRESSES';
export const ADDRESS = 'ADDRESS';

export const OPEN_ADDRESS_FORM_MODAL = 'OPEN_ADDRESS_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';

export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ADDRESS = 'FETCH_ADDRESS';
export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
