import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_SUPPLIERS, ADD_SUPPLIER, SUPPLIER_FETCHED, SUPPLIER_UPDATED, SUPPLIER_DELETED,
  AUTOCOMPLETE_SUPPLIERS, defaultSearchParams, moduleName, UPDATE_SUPPLIER_ADDRESS, CREATE_SUPPLIER_ADDRESS,
} from '@constants/suppliers';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function suppliers(state = initialState, action) {
  switch (action.type) {
    case SET_SUPPLIERS: return setItems(state, action);
    case ADD_SUPPLIER: return addItem(state, action);
    case SUPPLIER_FETCHED: return fetchItem(state, action);
    case SUPPLIER_UPDATED: return updateItem(state, action);
    case SUPPLIER_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_SUPPLIERS: return autocompleteUnions(state, action);
    case UPDATE_SUPPLIER_ADDRESS: {
      const isDefaultAddress = action?.payload?.address?.default || false;

      const items = state?.items.map((item) => {
        if (item?.id !== action?.payload?.supplier_id) return item;

        const addresses = item?.addresses?.map((address) => {
          if (address?.id !== action?.payload?.address?.id) {
            return { ...address, default: isDefaultAddress ? false : address?.default };
          }
          return action?.payload?.address;
        });

        return {
          ...item,
          addresses,
          default_address: isDefaultAddress ? (action?.payload?.address || {}) : (item?.default_address || {}),
        };
      });
      return {
        ...state,
        items,
        isError: false,
        errors: {},
      };
    }
    case CREATE_SUPPLIER_ADDRESS: {
      const isDefaultAddress = action?.payload?.address?.default || false;

      const items = state?.items.map((item) => {
        if (item?.id !== action?.payload?.supplier_id) return item;

        let updateAddresses = item?.addresses;
        if (isDefaultAddress) {
          updateAddresses = item?.addresses?.map((address) => ({ ...address, default: false }));
        }

        return {
          ...item,
          addresses: [...updateAddresses, { ...action?.payload?.address }],
          default_address: isDefaultAddress ? (action?.payload?.address || {}) : (item?.default_address || {}),
        };
      });
      return {
        ...state,
        items,
        isError: false,
        errors: {},
      };
    }
    default:
      return state;
  }
}
