import { getDomain } from './common_helper';

export const DEFAULT_COOKIE_STORAGE_PERIOD_MULTIPLIER = 60 * 60 * 1000; // (60 * 60 * 1000)ms = 1hr
export const DEFAULT_COOKIE_STORAGE_PERIOD = 48; // 48hr

export const setCookie = (cookieName: string, cookieValue: string | number, hours: number, domain?: string) => {
  const d = new Date();
  d.setTime(d.getTime() + (hours * DEFAULT_COOKIE_STORAGE_PERIOD_MULTIPLIER));
  const expires = `expires=${d.toUTCString()}`;
  let cookie = `${cookieName}=${cookieValue ? cookieValue.toString() : ''};${expires};path=/`;
  if (domain) {
    cookie = `${cookieName}=${cookieValue ? cookieValue.toString() : ''};${expires};path=/;domain=${domain}`;
  }
  document.cookie = cookie;
};

export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  const ca = document?.cookie?.split(';'); // O(number_of_cookies)

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteAllCookies = () => {
  const domain = getDomain();
  const cookies = document.cookie?.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const name = cookies[i].split('=')[0];
    document.cookie = `${name}=;max-age=0;path=/;domain=${domain}`;
  }
};

export const deleteCookie = (name) => {
  const domain = getDomain();
  document.cookie = `${name}=;max-age=0;path=/;domain=${domain}`;
};
