import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { ORGANIZATION_FETCHED, ORGANIZATION_UPDATED, defaultSearchParams } from '../constants/organizations';
import { fetchItem, updateItem } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
};

export default function organizations(state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION_FETCHED: return fetchItem(state, action);
    case ORGANIZATION_UPDATED: return updateItem(state, action);
    default:
      return state;
  }
}
