import { SET_DEBIT_NOTE_E_INVOICE_DATA } from '@constants/e_invoice';
import unionBy from 'lodash/unionBy';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_DEBIT_NOTES, ADD_DEBIT_NOTE, DEBIT_NOTE_FETCHED, DEBIT_NOTE_UPDATED,
  DEBIT_NOTE_DELETED, defaultSearchParams, moduleName, GENERATE_DEBIT_NOTE_FROM_PURCHASE_RETURN,
} from '@constants/debit_notes';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function debitNotes(state = initialState, action) {
  switch (action.type) {
    case SET_DEBIT_NOTES: return setItems(state, action);
    case ADD_DEBIT_NOTE: return addItem(state, action);
    case DEBIT_NOTE_FETCHED: return fetchItem(state, action);
    case DEBIT_NOTE_UPDATED: return updateItem(state, action);
    case DEBIT_NOTE_DELETED: return deleteItem(state, action);
    case SET_DEBIT_NOTE_E_INVOICE_DATA: {
      const items = state?.items?.map((item) => {
        if (item?.id !== action?.payload?.id) return item;
        return { ...item, e_invoice: action?.payload?.e_invoice };
      });
      return {
        ...state, items, isFetching: false, isError: false, errors: {},
      };
    }
    case GENERATE_DEBIT_NOTE_FROM_PURCHASE_RETURN: {
      const item = action?.item || {};
      const oldDebitNotesState = state?.items || [];
      const unionDebitNotesState = unionBy([{ ...item }], oldDebitNotesState, 'id');
      return {
        ...state,
        items: unionDebitNotesState,
        isError: false,
        errors: {},
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
