import React from 'react';
import DatePicker from 'react-datepicker';
import isDate from 'lodash/isDate';
import dayjs from 'dayjs';
import { getYearFromNow, getDateTimeFormat } from '../../helpers/date_picker_helper';
import { fallBackDateFormat, fallBackTimeFormat, apiDefaultDateFormat } from '../../constants/common';

const localizedFormat = require('dayjs/plugin/localizedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const quarterOfYear = require('dayjs/plugin/quarterOfYear');

// WHY  inorder to parse `l`(local) based date formats
dayjs.extend(localizedFormat);

// WHY  inorder to parse `quarter`
dayjs.extend(quarterOfYear);

// WHY  inorder to parse date from string when format of the date is known
//      Eg: when date picker is just a time picker field, then value from component will be a string
//      which will look something like `8:00 pm`. `dayjs` can't efficiently parse this. So when the
//      date format which is supplied to the datepicker component is known we can pass format to
//      `dayjs` so that it will parse the date properly
dayjs.extend(customParseFormat);

// INTERFACES
interface DMSDatePickerPropTypes {
  id: string;
  name?: string;
  selected?: Date | string | null;
  placeholderText?: string;
  dateFormat?: string;
  overrideDateFormat?: boolean;
  peekNextMonth?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  minDate?: Date | null | dayjs.Dayjs | '';
  maxDate?: Date | null;
  dropdownMode?: 'scroll' | 'select';
  onSelect?(date: string, event: React.SyntheticEvent<any> | undefined): void;
  onChange(date: string, event: React.SyntheticEvent<any> | undefined): void;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  isClearable?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  popperPlacement?: string;
  timeCaption?: string;
  apiDatetimeFormat?: string;
  dataTestID?: string
}
// !INTERFACES

// WHAT convert date object or string to a normal date object
// WHY  as the new react-datepicker will only accept a valid date obj, and some of the
//      dateformats are not supported by js Date object, so if the selected object is a date object
//      just return a date value or parse it with `dayjs` and convert it into js Date object
const getDatePickerValue = (value: any, format: string): Date => {
  if (isDate(value)) {
    return value;
  }
  if (!isNaN(dayjs(value).toDate().getTime())) {
    return dayjs(value).toDate();
  }
  return dayjs(value, format).toDate();
};

const DMSDatePicker: React.SFC<DMSDatePickerPropTypes> = ({
  id, name, selected, placeholderText, peekNextMonth, showMonthDropdown, dateFormat,
  showYearDropdown, minDate, maxDate, dropdownMode, onSelect, onChange, className,
  disabled, tabIndex, autoComplete, autoFocus, isClearable, showTimeSelect, showTimeSelectOnly,
  timeFormat, timeIntervals, popperPlacement, timeCaption, overrideDateFormat,
  apiDatetimeFormat, dataTestID = '',
}: DMSDatePickerPropTypes) => {
  const [format1, format2] = getDateTimeFormat({
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    overrideDateFormat,
    apiDatetimeFormat: apiDatetimeFormat || apiDefaultDateFormat,
  });

  // WHY  question is why using a seperate format(format2) for formatting date
  //      to maintain consistency in datetime which is being sent to backend
  const handleSelect = (date: Date, event) => {
    if (onSelect) {
      onSelect(date ? dayjs(date).format(format2) : '', event);
    }
  };
  const handleChange = (date: Date, event) => {
    if (onChange) {
      onChange(date ? dayjs(date).format(format2) : '', event);
    }
  };

  return (
    <div data-cy={id} data-testid={dataTestID}>
      <DatePicker
        id={id}
        name={name}
        selected={selected ? getDatePickerValue(selected, format2) : null}
        placeholderText={placeholderText}
        dateFormat={format1}
        peekNextMonth={peekNextMonth}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        minDate={minDate}
        maxDate={maxDate}
        dropdownMode={dropdownMode}
        onSelect={handleSelect}
        onChange={handleChange}
        className={className}
        disabled={disabled}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        isClearable={isClearable}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeFormat={timeFormat || fallBackTimeFormat}
        timeIntervals={timeIntervals}
        popperPlacement={popperPlacement}
        timeCaption={timeCaption}
      />
    </div>
  );
};

DMSDatePicker.defaultProps = {
  minDate: new Date('2001 1, 1'),
  maxDate: getYearFromNow(10),
  dropdownMode: 'select',
  className: 'form-control',
  disabled: false,
  peekNextMonth: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dateFormat: fallBackDateFormat,
  timeCaption: 'Time',
  apiDatetimeFormat: apiDefaultDateFormat,
  overrideDateFormat: false,
  autoComplete: 'off',
  autoFocus: false,
};

export default DMSDatePicker;
