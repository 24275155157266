import React from 'react';
import isArray from 'lodash/isArray';
import { Breadcrumb } from 'react-bootstrap';
import { logEvent, CategoryEnum, ActionEnum } from '../../utils/GAUtils';
import { getBreadcrumbUrl } from '../../helpers/common_helper';

const BreadcrumbWithoutButtons = ({ title, breadcrumb_listing: breadcrumbListing }: Props) => (
  <>
    <h4 className="header-breadcrumb">
      {(isArray(breadcrumbListing) && breadcrumbListing?.length > 0)
        ? (
          <span className="breadcrumb">
            {
              breadcrumbListing?.map((item, idx) => {
                if (item?.active) {
                  return (
                    <Breadcrumb.Item active key={item?.title || `breadcrumb-item-${idx}`}>{item?.title || ''}</Breadcrumb.Item>
                  );
                }
                const hrefUrl = getBreadcrumbUrl({ item });
                return (
                  <Breadcrumb.Item
                    href={hrefUrl}
                    key={item?.title || `breadcrumb-item-${idx}`}
                    onClick={() => { logEvent(CategoryEnum.WINDOW, ActionEnum.BREADCRUMB, `${item?.title || ''}`); }}
                  >
                    &nbsp;
                    {item?.title}
                  </Breadcrumb.Item>
                );
              })
            }
          </span>
        ) : null}
      {
        title
          ? (
            <>
              <br />
              {title}
            </>
          )
          : null
      }
    </h4>
  </>
);

// PROPS
interface Props {
  title?: string;
  breadcrumb_listing?: { title: string | number; active: boolean }[];
}
// !PROPS

BreadcrumbWithoutButtons.defaultProps = {
  title: '',
  breadcrumb_listing: [],
};

export default BreadcrumbWithoutButtons;
