import React from 'react';
import {
  bool, string, oneOfType, arrayOf,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { LOADER_BTN_NAME } from '../../constants/common';

const FormSubmitLoader = ({
  loader, name, loader_btn_name, class_name, altered, afterWarningsBtnTitle, warnings,
  moduleName, isFormOpenInModal,
}) => {
  if (loader) {
    return (
      <button
        type="button"
        className={`btn submit-form-loader btn-primary ${class_name} disabled`}
        data-cy={isFormOpenInModal ? `quick-add-save-${moduleName}-button` : 'save-button'}
      >
        <i className="fa fa-spinner fa-spin" />
        {loader_btn_name}
      </button>
    );
  }

  return (
    <button
      className={`btn btn-primary ${class_name}`}
      type="submit"
      disabled={!altered}
      data-cy={isFormOpenInModal ? `quick-add-save-${moduleName}-button` : 'save-button'}
    >
      {isEmpty(warnings) ? name : afterWarningsBtnTitle}
    </button>
  );
};

FormSubmitLoader.propTypes = {
  loader: bool.isRequired,
  name: string,
  afterWarningsBtnTitle: string,
  loader_btn_name: string,
  class_name: string,
  altered: bool,
  warnings: oneOfType([string, arrayOf(string)]),
  isFormOpenInModal: bool,
  moduleName: string,
};

FormSubmitLoader.defaultProps = {
  name: 'SAVE',
  afterWarningsBtnTitle: 'RECHECK & SAVE',
  loader_btn_name: LOADER_BTN_NAME,
  class_name: '',
  altered: true,
  warnings: '',
  isFormOpenInModal: false,
  moduleName: '',
};
export default FormSubmitLoader;
