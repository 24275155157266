import { isArray, isEmpty, forEach } from 'lodash';

// serializeSearchParams(searchParams) function take one argument ie. searchParams
// searchParams take hash like { name_cont: 'lg', number_cont: '123' } and
// return "q[name_cont]=lg&q[number_cont]=123&" string.
export function serializeSearchParams(searchParams) {
  let q = '';
  if (!isEmpty(searchParams)) {
    forEach(searchParams, (value, key) => {
      if ((typeof value === 'object') && isArray(value)) {
        forEach(value, (v) => {
          const val = encodeURIComponent(v);
          q += `q[${key}][]=${val}&`;
        });
      } else {
        const val = encodeURIComponent(value);
        q += `q[${key}]=${val}&`;
      }
    });
  }
  return q;
}

export function serializePageParams(page, per_page) {
  return `page[number]=${page}&page[size]=${per_page}`;
}

export const serializeParams = (params = {}) => {
  const paramKeys = Object.keys(params);

  return paramKeys.map((key, i) => {
    if (isArray(params[paramKeys[i]])) {
      const paramArr = params[key];
      return paramArr.map((paramEl) => (`${key}[]=${paramEl}`)).join('&');
    }

    return `${key}=${params[key]}`;
  })
    .join('&');
};
