import { moduleName } from '../constants/products';
import axios from '../utils/axios';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import setIndexRequestParams from '../utils/HandleIndexActionParams';

// Get Products List (dispatch, getState)
export function fetchProducts(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${args.url}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function fetchOrderableProducts(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params, customer_id } = args;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/orderable_by_user?${q}`,
    method: 'get',
    params: {
      customer_id,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteAllProducts(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params } = args;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete_product?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchProductsBasicInfo(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params } = args;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}/autocomplete_product?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchProductsReturnableByCustomer(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params, customer_id } = args;
  const q = serializeSearchParams(search_params);
  let endPointUrl = `${baseUrl}/returnable_by_customer`;
  if (args?.url) {
    endPointUrl = args?.url;
  }

  return () => axios({
    url: `${endPointUrl}?${q}`,
    method: 'get',
    params: {
      customer_id,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchSellableProducts(args: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { search_params } = args;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/sellable?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function deleteProductVariant(variantId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl } = moduleTypes;
  return axios({
    url: `${baseUrl}/${variantId}`,
    method: 'delete',
  });
}
