import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_TAX_CATEGORIES, ADD_TAX_CATEGORY, TAX_CATEGORY_FETCHED, TAX_CATEGORY_UPDATED,
  TAX_CATEGORY_DELETED, AUTOCOMPLETE_TAX_CATEGORIES, defaultSearchParams, moduleName,
} from '@constants/tax_categories';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function taxCategories(state = initialState, action) {
  switch (action.type) {
    case SET_TAX_CATEGORIES: return setItems(state, action);
    case ADD_TAX_CATEGORY: return addItem(state, action);
    case TAX_CATEGORY_FETCHED: return fetchItem(state, action);
    case TAX_CATEGORY_UPDATED: return updateItem(state, action);
    case TAX_CATEGORY_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_TAX_CATEGORIES: return autocomplete(state, action);
    default:
      return state;
  }
}
