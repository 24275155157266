import {
  fallBackDateFormat, fallBackTimeFormat, apiDefaultDateFormat,
} from '@constants/common';
import { getClientSetting } from '@helpers/settings_helper';
// import { moduleName as orders } from '@constants/orders';
import { getTenantLocalDateTime } from '@helpers/common_helper';
import dayjs from 'dayjs';

export const dateFormat1 = 'dd/MM/yyyy';
export const dateFormat2 = 'dd/MM/yyyy h:mm aa';
export const dateFormat3 = 'MMMM d, yyyy';
export const dateFormat4 = 'MMMM d, yyyy h:mm aa';

// export const getYearFromNow = (years: number) => dayjs().add(years, 'year').toDate();
export function getYearFromNow(years: number) {
  const dateTime: any = getTenantLocalDateTime('', false);
  return dateTime?.add(years, 'year').toDate();
}

export const getApiDateTimeFormatForModule = (moduleName) => {
  switch (moduleName) {
    // case orders:
    //   return apiDefaultDateTimeFormat;
    default:
      return apiDefaultDateFormat;
  }
};

// WHAT   this func converts `m` to `M` format
//        Eg: dd/mm/yyyy => dd/MM/yyyy
export const formatDateTimeFormatForDatePicker = (format: string) => {
  let newDateTimeFormat = format;
  newDateTimeFormat = newDateTimeFormat.replace(/m/g, 'M');

  return newDateTimeFormat;
};

// WHAT   this function gets a time format for the datepicker and the backend
//        return as array [f1: `format for date picker`, f2: `format for backend`]
export const getDateTimeFormat = ({
  showTimeSelect = false, showTimeSelectOnly = false, dateFormat = fallBackDateFormat,
  overrideDateFormat = false, apiDatetimeFormat = apiDefaultDateFormat,
}) => {
  if (overrideDateFormat) {
    return [dateFormat, apiDatetimeFormat];
  }

  if (showTimeSelectOnly) {
    return [fallBackTimeFormat, fallBackTimeFormat];
  }
  const uiDateFormat = getClientSetting('date_format_ui');
  const f1: string = formatDateTimeFormatForDatePicker(uiDateFormat || fallBackDateFormat);
  const f2: string = apiDatetimeFormat;

  if (showTimeSelect && !showTimeSelectOnly) {
    return [`${f1}, ${fallBackTimeFormat}`, apiDatetimeFormat];
  }

  return [f1, f2];
};

export const setMinDate = (dateGteq) => {
  const formattedDate = dayjs(dateGteq);
  let minDate: any = '';
  if (dateGteq) {
    minDate = formattedDate.toDate();
  }
  return minDate;
};

export const validateAndReturnToDateValue = ({
  name, from_date, to_date, fromDateKey = 'date_gteq', toDateKey = 'date_lteq',
}) => {
  let args = {};
  if (name === fromDateKey) {
    const fromDate = new Date(from_date).getTime();
    const toDate = new Date(to_date || '').getTime();
    if (fromDate > toDate) {
      args = { [toDateKey]: '' };
    }
  }
  return args;
};
