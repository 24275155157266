import { call, put, takeLatest } from 'redux-saga/effects';
import { getActionTypes } from '@actions/action_helpers';
import { autocompleteInventoryPieces, bulkCreateVoucherItemInventoryPieces, fetchInventoryPieceAvailableQuantity } from './api';
import { AUTOCOMPLETE_INVENTORY_PIECES, BULK_CREATE_VOUCHER_ITEM_INVENTORY_PIECES, FETCH_INVENTORY_PIECE_AVAILABLE_QUANTITY } from './constants';
import { updateBulkCreatePieceVoucherItem } from './actions';

function* autocompleteInventoryPiecesFunc(action) {
  const {
    url, voucherModuleName, voucherId, search_params, per_page, page,
  } = action;
  try {
    const response = yield call(autocompleteInventoryPieces({
      url, voucherId, voucherModuleName, search_params, per_page, page,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* bulkCreateVoucherItemInventoryPiecesFunc(action) {
  const {
    url, voucherModuleName, voucherId, voucherItemId, data, voucherItemRetrieveApiDataKey,
  } = action || {};

  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['item'] });
  const { item: itemKey } = moduleTypes;

  try {
    const response = yield call(bulkCreateVoucherItemInventoryPieces({
      url, voucherModuleName, voucherId, data,
    }));

    const reponseItemData = response?.data?.[voucherItemRetrieveApiDataKey] || {};

    yield put(updateBulkCreatePieceVoucherItem({
      voucherId, voucherItemId, reponseItemData, itemKey,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchInventoryPieceAvailableQuantityFunc(action) {
  const {
    url, voucherModuleName, voucherId, voucherItemId, productId, warehouseId,
  } = action || {};

  try {
    const response = yield call(fetchInventoryPieceAvailableQuantity({
      url, voucherModuleName, voucherId, voucherItemId, productId, warehouseId,
    }));

    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* inventoryPieceTrackingWatcher() {
  yield takeLatest(AUTOCOMPLETE_INVENTORY_PIECES, autocompleteInventoryPiecesFunc);
  yield takeLatest(
    BULK_CREATE_VOUCHER_ITEM_INVENTORY_PIECES, bulkCreateVoucherItemInventoryPiecesFunc,
  );
  yield takeLatest(FETCH_INVENTORY_PIECE_AVAILABLE_QUANTITY, fetchInventoryPieceAvailableQuantityFunc);
}
