import omit from 'lodash/omit';
import { REDUCER_COMMON_INITIAL_STATES } from '../../constants/common';
import {
  SET_CHART_OF_ACCOUNTS, ADD_CHART_OF_ACCOUNT, CHART_OF_ACCOUNT_FETCHED,
  CHART_OF_ACCOUNT_UPDATED, CHART_OF_ACCOUNT_DELETED, AUTOCOMPLETE_CHART_OF_ACCOUNT,
  defaultSearchParams,
} from '../../constants/accounting/accounts';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateObject,
} from '../common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 50,
  sort_by: 'type',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
};

export function setInfItems(state, action) {
  const {
    total_count, per_page, items, page,
  } = action;
  const updated_action_state = { ...(omit(action, ['type', 'items'])) };

  if (action.items) {
    const isFirst = page === 1;
    const tableItems = isFirst ? [] : [...state.items];
    if (action.page === 1) {
      for (let i = 0; i < total_count; i += 1) {
        tableItems.push(undefined);
      }
    }
    tableItems.splice((page - 1) * per_page, items.length, ...items);
    updated_action_state.items = tableItems;
  }

  return updateObject(state, updated_action_state);
}

export default function chartOfAccounts(state = initialState, action) {
  switch (action.type) {
    case SET_CHART_OF_ACCOUNTS: return setItems(state, action);
    case ADD_CHART_OF_ACCOUNT: return addItem(state, action);
    case CHART_OF_ACCOUNT_FETCHED: return fetchItem(state, action);
    case CHART_OF_ACCOUNT_UPDATED: return updateItem(state, action);
    case CHART_OF_ACCOUNT_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_CHART_OF_ACCOUNT: return setInfItems(state, action);
    default:
      return state;
  }
}
