import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import * as types from '@constants/settings';

export function fetchUniqCountryDialCodes() {
  return () => axios({
    url: '/uniq_country_dial_codes',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}
