import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BANK_ACCOUNTS, ADD_BANK_ACCOUNT, BANK_ACCOUNT_FETCHED, BANK_ACCOUNT_UPDATED,
  BANK_ACCOUNT_DELETED, AUTOCOMPLETE_BANK_ACCOUNTS, defaultSearchParams, moduleName,
} from '../constants/bank_accounts';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'holder_name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function bankAccounts(state = initialState, action) {
  switch (action.type) {
    case SET_BANK_ACCOUNTS: return setItems(state, action);
    case ADD_BANK_ACCOUNT: return addItem(state, action);
    case BANK_ACCOUNT_FETCHED: return fetchItem(state, action);
    case BANK_ACCOUNT_UPDATED: return updateItem(state, action);
    case BANK_ACCOUNT_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_BANK_ACCOUNTS: return autocomplete(state, action);
    default:
      return state;
  }
}
