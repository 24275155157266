import axios from '@utils/axios';
import { ADD_ORDER_TERM, moduleName } from '@constants/order_terms';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { updateAndCloseQuickAddModule } from '@src/components/common/quick_add_module/helper';

export function fetchOrderTerms(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => {
      const { order_terms, meta } = response?.data || {};
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: order_terms || [],
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save order_term.
export function saveOrderTerm(order_term_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'POST',
    data: {
      order_term: order_term_params,
    },
  })
    .then((response) => {
      const { order_term } = response.data;
      dispatch(addItem({ moduleName, item: order_term }));
      const params = { order_term_id: order_term.id, order_term_name: order_term.name };
      updateAndCloseQuickAddModule({ identifier: ADD_ORDER_TERM, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get order_term
export function fetchOrderTerm(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { order_term } = response.data;
      dispatch(setItem({ moduleName, item: order_term }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update OrderTerm.
export function updateOrderTerm(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'PATCH',
    data: {
      order_term: data,
    },
  })
    .then((response) => {
      const { order_term } = response.data;
      dispatch(updateItem({ moduleName, item: order_term }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete OrderTerm.
export function deleteOrderTerm(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteOrderTermsName(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete_order_terms_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchOrderTermsBasicInfo(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_order_terms_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { order_terms } = response.data;
      dispatch(autocompleteItems({ moduleName, items: order_terms }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
