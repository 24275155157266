import { call, put, takeLatest } from 'redux-saga/effects';
import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { SET_USER_ACCOUNT_PROFILE } from '@constants/auth';
import { UPDATE_ACCOUNT_PROFILE } from './constants';

function updateAccountProfileAction(args) {
  const { account } = args;

  return () => axios({
    url: '/account',
    method: 'PUT',
    data: { account },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/update_profile', '');
      throw error.response;
    });
}

function* updateAccountProfileFunc(action) {
  const { account } = action || {};
  try {
    const response = yield call(updateAccountProfileAction({ account }));
    yield put({ type: SET_USER_ACCOUNT_PROFILE, account: response?.data?.account || {} });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* updateAccountProfile() {
  yield takeLatest(UPDATE_ACCOUNT_PROFILE, updateAccountProfileFunc);
}
