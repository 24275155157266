import { HOME_BREADCRUMB_LISTING } from './common';
import {
  moduleName as branches,
} from './branches';
import { moduleName as warehouses } from './warehouses';

import {
  moduleName as products,
} from './products';

export interface WarehouseDetailsIntf {
  source_warehouse_id: number;
  source_warehouse_name: string;
  meta: {
    permissions_hash: {
      can_show: boolean;
      can_create: boolean;
      can_destroy: boolean;
      can_update: boolean;
      can_index: boolean;
      can_download_pdf: boolean;
    };
  };
  stock_transfer_items: [];
}

export const STOCK_TRANSFER = 'STOCK_TRANSFER';
export const SET_STOCK_TRANSFERS = 'SET_STOCK_TRANSFERS';
export const ADD_STOCK_TRANSFER = 'ADD_STOCK_TRANSFER';
export const STOCK_TRANSFER_FETCHED = 'STOCK_TRANSFER_FETCHED';
export const STOCK_TRANSFER_UPDATED = 'STOCK_TRANSFER_UPDATED';
export const STOCK_TRANSFER_DELETED = 'STOCK_TRANSFER_DELETED';
export const AUTOCOMPLETE_STOCK_TRANSFERS = 'AUTOCOMPLETE_STOCK_TRANSFERS';
export const SET_WAREHOUSE_EMPTYING_DETAILS = '@actions/stock_transfers/SET_WAREHOUSE_EMPTYING_DETAILS';
export const RESET_STOCK_TRANSFER_ITEMS = '@actions/stock_transfers/RESET_STOCK_TRANSFER_ITEMS';

export const FORM_INFORMATION_TITLE = 'STOCK TRANSFER INFORMATION';

export const FETCH_STOCK_TRANSFERS = 'FETCH_STOCK_TRANSFERS';

export const title = 'Stock Transfers';
export const singularTitle = 'Stock Transfer';
export const moduleName = 'stock_transfers';
export const baseUrl = '/stock_transfers';
export const redirectUrl = '/stock_transfers';
export const listingDataKey = 'stock_transfers';
export const itemDataKey = 'stock_transfer';

export const sourceBranchEventIdentifier = 'source_branch_id';
export const destinationBranchEventIdentifier = 'destination_branch_id';
export const sourceWarehouseEventIdentifier = 'source_warehouse_id';
export const destinationWarehouseEventIdentifier = 'destination_warehouse_id';

export const stockTransferConstants = {
  item: STOCK_TRANSFER,
  setItems: SET_STOCK_TRANSFERS,
  addItem: ADD_STOCK_TRANSFER,
  setItem: STOCK_TRANSFER_FETCHED,
  updateItem: STOCK_TRANSFER_UPDATED,
  deleteItem: STOCK_TRANSFER_DELETED,
  autocompleteItems: AUTOCOMPLETE_STOCK_TRANSFERS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  number_cont: '',
  date_gteq: '',
  date_lteq: '',
  source_branch_id_in: [],
  destination_branch_id_in: [],
  source_warehouse_id_in: [],
  destination_warehouse_id_in: [],
  stock_transfer_items_variant_id_in: [],
  voucher_book_id_in: [],
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [branches, warehouses, products, 'voucher_books'];

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title, target: baseUrl, active: false }],
);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title, target: baseUrl, active: true }],
);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat(
  [{ title: 'New', target: '', active: true }],
);

export const handledErrorKeys = {
  master: ['date', 'source_branch', 'source_branch_id', 'source_warehouse', 'source_warehouse_id', 'destination_branch', 'destination_branch_id', 'destination_warehouse', 'destination_warehouse_id'],
  stock_transfer_items: ['variant', 'variant_id', 'quantity', 'price'],
};
