import {
  SET_ORDERS, ADD_ORDER, ORDER_FETCHED, ORDER_UPDATED, ORDER_DELETED,
  UPDATE_SELECTED_BULK_ORDERS_STATUS_TO_CONFIRM, UPDATE_ORDERS_STATUS_TO_PROCESSING,
  BULK_ORDERS_INVOICE, RESET_ORDER_ERRORS, processing, confirm, defaultSearchParams,
  moduleName, UPDATE_PARAMS,
} from '@constants/orders';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete, updateStatus,
} from '@reducers/common';

// SETUP-SECTION  localStorage index_params
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });
// !SETUP-SECTION  localStorage index_params

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,

  // SETUP-SECTION  localStorage index_params
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
  // !SETUP-SECTION  localStorage index_params
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case SET_ORDERS: return setItems(state, action);
    case ADD_ORDER: return addItem(state, action);
    case ORDER_FETCHED: return fetchItem(state, action);
    case ORDER_UPDATED: return updateItem(state, action);
    case ORDER_DELETED: return deleteItem(state, action);
    case UPDATE_SELECTED_BULK_ORDERS_STATUS_TO_CONFIRM: return updateStatus(state, action, confirm);
    case UPDATE_ORDERS_STATUS_TO_PROCESSING: return updateStatus(state, action, processing);
    case BULK_ORDERS_INVOICE: return autocomplete(state, action);
    case RESET_ORDER_ERRORS: {
      return {
        ...state, isError: false, errors: {}, isFetching: false,
      };
    }
    case UPDATE_PARAMS: {
      const items = state.items.map((item) => {
        if (item.id !== action.itemId) return item;
        return { ...item, params: { ...action.params } };
      });
      return { ...state, items };
    }
    default:
      return state;
  }
}
