import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET, POST, PUT, DELETE,
} from '@utils/axios_methods';
import { fetchingRequest } from '@utils/axios';
import * as types from '@constants/accounting/accounts';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { updateAndCloseQuickAddModule } from '@component/common/quick_add_module/helper';
import {
  setAccountingAccounts, addAccountingAccount, accountingAccountFetched, accountingAccountUpdated,
  accountingAccountDeleted,
  setAccountingAccountFromAutocomplete,
} from '@actions/accounting/accounts';
import { fetchAccountingAccountBasicInfo, autocompleteAccountingAccountName } from '@api/accounting/accounts';

// Get Accounting Accounts List (dispatch, getState)
// args ex. { search_params, per_page, page, sort_by, sort_order }
export function fetchAccountingAccounts(args) {
  const request_params = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = request_params;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const page_params = serializePageParams(page, per_page);
  fetchingRequest(types.CHART_OF_ACCOUNT);

  const accountsUrl = (search_params.name_cont) ? '/accounting/accounts/chart' : types.baseUrl;

  return (dispatch) => GET({
    url: `${accountsUrl}?${page_params}&${q}`,
    params: { sort_by, order: sort_order },
    error: { redirect_to: types.redirectUrl, module_name: types.CHART_OF_ACCOUNT },
  })
    .then((response) => {
      const { accounts, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setAccountingAccounts({
        items: accounts || [], current_page: page, total_count, sort_by, sort_order, search_params, per_page, selected_variant, dont_merge_search_params, timePeriod,
      }));
      return response;
    });
}

// Save Accounting Account.
export function saveAccountingAccount(account) {
  fetchingRequest(types.CHART_OF_ACCOUNT);
  return (dispatch) => POST({
    url: types.baseUrl,
    data: { account },
    error: { redirect_to: types.redirectUrl, module_name: types.CHART_OF_ACCOUNT },
  })
    .then((response) => {
      const { account } = response.data;
      const params = { account_id: account.id, account_name: account.name };
      updateAndCloseQuickAddModule({ identifier: '', params });
      dispatch(addAccountingAccount(account));
      return response;
    });
}

// Get Account.
export function fetchAccountingAccount(args) {
  fetchingRequest(types.CHART_OF_ACCOUNT);
  return (dispatch) => GET({
    url: `${types.baseUrl}/${args.id}`,
    error: { redirect_to: types.redirectUrl, module_name: types.CHART_OF_ACCOUNT },
  })
    .then((response) => {
      const { account } = response.data;
      dispatch(accountingAccountFetched(account));
      return response;
    });
}

// Update Accounting Account.
export function updateAccountingAccount(account) {
  fetchingRequest(types.CHART_OF_ACCOUNT);
  return (dispatch) => PUT({
    url: `${types.baseUrl}/${account.id}`,
    data: { account },
    error: { redirect_to: types.redirectUrl, module_name: types.CHART_OF_ACCOUNT },
  })
    .then((response) => {
      const { account } = response.data;
      dispatch(accountingAccountUpdated(account));
      return response;
    });
}

// Delete Accounting Account.
export function deleteAccountingAccount(id) {
  fetchingRequest(types.CHART_OF_ACCOUNT);
  return (dispatch) => DELETE({
    url: `${types.baseUrl}/${id}`,
    error: { redirect_to: `${types.baseUrl}/${id}`, module_name: types.CHART_OF_ACCOUNT },
  })
    .then((response) => {
      dispatch(accountingAccountDeleted(id));
      return response;
    });
}

function* allInfoChartOfAccountsSagaSideEffect(action: any) {
  const { search_params, id, apiEndPointUrl } = action;
  try {
    const response = yield call(autocompleteAccountingAccountName({
      search_params, id, apiEndPointUrl,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* basicInfoChartOfAccountsSagaSideEffect(action: any) {
  const { search_params, page = 1, per_page = 100 } = action;
  try {
    const response = yield call(fetchAccountingAccountBasicInfo({ search_params, page, per_page }));
    const { accounts, meta } = response.data;
    const {
      total_count, total_pages, next_page, current_page,
    } = meta.pagination;
    yield put(setAccountingAccountFromAutocomplete({
      items: accounts, total_count, page, per_page, total_pages, next_page, current_page,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* chartOfAccountsWatcher() {
  yield takeLatest(types.AUTOCOMPLETE_ALL_CHART_OF_ACCOUNTS, allInfoChartOfAccountsSagaSideEffect);
  yield takeLatest(types.AUTOCOMPLETE_BASIC_INFO_CHART_OF_ACCOUNTS, basicInfoChartOfAccountsSagaSideEffect);
}
