import isEmpty from 'lodash/isEmpty';

interface UnitsType {
  units: Record<string, any>;
}
// Response data =>
// data: { units: {
//     bag: {name: "Bag", symbol: "bag", in: {gst: {code: "BAG-BAGS", name: "BAGS", symbol: "BAG"}}}
//     box: {name: "Box", symbol: "box", in: {gst: {code: "BOX-BOX", name: "BOXES", symbol: "BOX"}}}
//   }
// }

// Formated data in units =>
// units = [
//   {name: "Bag", symbol: "bag", in: {gst: {code: "BAG-BAGS", name: "BAGS", symbol: "BAG"}}}
//   {name: "Box", symbol: "box", in: {gst: {code: "BOX-BOX", name: "BOXES", symbol: "BOX"}}
// ]

export const getUnits = (data: UnitsType) => {
  let units = [];
  const responseUnits = data?.units || {};
  if (!isEmpty(responseUnits)) {
    units = Object.values(responseUnits);
  }
  return units;
};
