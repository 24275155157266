import { ADD_CUSTOMER } from '@constants/customers';
import { ADD_BRAND } from '@constants/brands';
import { ADD_PRODUCT_CATEGORY } from '@constants/product_categories';
import { ADD_INVOICE_TERM } from '@constants/invoice_terms';
import { ADD_CHART_OF_ACCOUNT } from '@constants/accounting/accounts';
import { ADD_PRODUCT } from '@constants/products';
import { ADD_CUSTOMER_RATING } from '@constants/customer_ratings';
import { ADD_CUSTOMER_CATEGORY } from '@constants/customer_categories';
import { ADD_DOCUMENT_CATEGORY } from '@constants/document_categories';
import { ADD_JOURNAL_ENTRY } from '@constants/accounting/entries';
import { ADD_INVENTORY_BATCH } from '@constants/inventory_batches';
import { ADD_INVENTORY_PIECE } from '@constants/inventory_pieces';

// SETUP-SECTION quick add
import { ADD_PAYMENT } from '@constants/payments';
// !SETUP-SECTION quick add

import { ADD_CREDIT_NOTE } from '@constants/credit_notes';
import { ADD_INVOICE } from '@constants/invoices';
import { ADD_PURCHASE_INVOICE } from '@constants/purchase_invoices';
import { ADD_BRANCH } from '@constants/branches';
import { ADD_WAREHOUSE } from '@constants/warehouses';
import { ADD_BEAT_ROUTE } from '@constants/beat_routes';
import { ADD_CREDIT_REASON } from '@constants/credit_reasons';
import { ADD_SUPPLIER } from '@constants/suppliers';
import { ADD_ORDER_TERM } from '@constants/order_terms';
import { ADD_TAX } from '@constants/taxes';
import { ADD_BANK } from '@constants/banks';
import { ADD_INVENTORY_IDENTIFICATION } from '@constants/inventory_identifications';
import { ADD_TAX_CATEGORY } from '@constants/tax_categories';

// GLOBAL_CONSTANT QUICK_ADD_IS_USED_FOR
// WHAT:  this constant gives us an idea about where are we using quick add module. Especially
//        helpful when closing the quick add modal after successful creation of a record
export const QUICK_ADD_IS_USED_FOR = {
  ADD_CUSTOMER,
  ADD_BRAND,
  ADD_PRODUCT_CATEGORY,
  ADD_INVOICE_TERM,
  ADD_CHART_OF_ACCOUNT,
  ADD_PRODUCT,
  ADD_CUSTOMER_RATING,
  ADD_CUSTOMER_CATEGORY,
  ADD_DOCUMENT_CATEGORY,
  ADD_JOURNAL_ENTRY,

  // SETUP-SECTION quick add
  ADD_PAYMENT,
  // !SETUP-SECTION quick add

  ADD_CREDIT_NOTE,
  ADD_INVOICE,
  ADD_PURCHASE_INVOICE,
  ADD_BRANCH,
  ADD_WAREHOUSE,
  ADD_BEAT_ROUTE,
  ADD_CREDIT_REASON,
  ADD_SUPPLIER,
  ADD_ORDER_TERM,
  ADD_TAX,
  ADD_BANK,
  ADD_INVENTORY_IDENTIFICATION,
  ADD_TAX_CATEGORY,
  ADD_INVENTORY_PIECE,
  ADD_INVENTORY_BATCH,
};
