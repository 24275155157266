import {
  ADD_NOTIFICATION, CLEAR_NOTIFICATION, SIDENAV_MINIMIZE, IS_ADS_BLOCK_DETECTED,
  CRASH_DIALOG_VISIBLITY, SET_ACTIVE_MODULE,
} from '@constants/root';
import store from '@store/store';

export function setNotification({
  title, message, level, renderView,
}) {
  return {
    type: ADD_NOTIFICATION,
    title,
    message,
    level,
    renderView,
  };
}

export function resetNotification({ renderView }) {
  return {
    type: CLEAR_NOTIFICATION,
    renderView,
  };
}

export function successNotification(args) {
  const { title, message, renderView } = args;
  const updatedTitle = title || 'Success';
  store.dispatch(setNotification({
    title: updatedTitle, message, level: 'success', renderView,
  }));
}

export function errorNotification(args) {
  const { title, message, renderView } = args;
  const updatedTitle = title || 'Error';
  store.dispatch(setNotification({
    title: updatedTitle, message, level: 'error', renderView,
  }));
}

export function warningNotification(args) {
  const { title, message, renderView } = args;
  const updatedTitle = title || 'Warning';
  store.dispatch(setNotification({
    title: updatedTitle, message, level: 'warning', renderView,
  }));
}

export function infoNotification(args) {
  const { title, message, renderView } = args;
  const updatedTitle = title || 'Info';
  store.dispatch(setNotification({
    title: updatedTitle, message, level: 'info', renderView,
  }));
}

export function clearNotification(args) {
  const { renderView } = args;
  store.dispatch(resetNotification({ renderView }));
}

export function setSidebarMinimizeState(isSidenavMinimize) {
  return {
    type: SIDENAV_MINIMIZE,
    isSidenavMinimize,
  };
}

export function setSidebarMinimize(isSidenavMinimize) {
  return (dispatch) => dispatch(setSidebarMinimizeState(isSidenavMinimize));
}

export function setIsAdsBlockDetected(isAdsBlockDetected) {
  return {
    type: IS_ADS_BLOCK_DETECTED,
    isAdsBlockDetected,
  };
}

export function setCrashModalVisibility(crashDialogVisibility) {
  return {
    type: CRASH_DIALOG_VISIBLITY,
    crashDialogVisibility,
  };
}

export function adsBlockDetected(isAdsBlockDetected) {
  return (dispatch) => dispatch(setIsAdsBlockDetected(isAdsBlockDetected));
}

export function changeCrashDialogVisibility(crashDialogVisibility) {
  return (dispatch) => dispatch(setCrashModalVisibility(crashDialogVisibility));
}

export function setClientSettings(client_settings) {
  return {
    type: 'SET_CLIENT_SETTINGS',
    client_settings,
  };
}

export function setGlobalSettings(global_settings) {
  return {
    type: 'SET_GLOBAL_SETTINGS',
    global_settings,
  };
}

export function setClientUiPermissions(client_ui_permissions) {
  return {
    type: 'SET_CLIENT_UI_PERMISSIONS',
    client_ui_permissions,
  };
}

export function setActiveModuleName({ activeModule }) {
  return { type: SET_ACTIVE_MODULE, activeModule };
}

export function setActiveModule(args) {
  const { activeModule } = args;
  return (dispatch) => dispatch(setActiveModuleName({ activeModule }));
}
