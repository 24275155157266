import React, { CSSProperties, PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { Grid, Row, Col } from 'react-bootstrap';
import Loadable from 'react-loadable';
import { INVALID_INPUT_ERROR_HEADER_TEXT } from '@constants/common_constants';
import Loading from '../Loading';
import BasicHeader from '../headers/BasicHeader';
import { getFormUnhandledErrors } from '../DMSFlashMessage/helper';

const DMSFlashMessage = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonFlashMessage" */ '../DMSFlashMessage'),
  loading: Loading,
});

interface DMSBasicCardProps {
  title?: string;
  moduleName?: string,
  open_form_in_modal?: boolean;
  information_title?: string;
  breadcrumb_listing?: any[];
  children: any;
  gridStyle?: any;
  gridClassName?: string;
  headerRightComponents?: any;
  headerClassName?: string;
  processedBackendErrors?: any;
  handledErrorKeys?: any[];
  view?: string;
  updateFormComponent?: (selectedMenuItem: any) => void;
  showDropDownMenuItems?: boolean;
  headerTitleStyle?: Record<string, any>;
  headerRightComponentsStyle?: Record<string, any>;
  headerRightComponentClassName?: string;
  isModuleConfigVisible?: boolean;
  isReportLinkVisible?: boolean;
}

class DMSBasicCard extends PureComponent<DMSBasicCardProps, {}> {
  static defaultProps = {
    title: '',
    open_form_in_modal: false,
    information_title: '',
    breadcrumb_listing: [],
    children: '',
    gridStyle: {},
    gridClassName: '',
    headerRightComponents: '',
    headerClassName: '',
    processedBackendErrors: {},
    handledErrorKeys: [],
    view: '',
    updateFormComponent: () => ({}),
    showDropDownMenuItems: false,
    headerTitleStyle: {},
    headerRightComponentsStyle: {},
    headerRightComponentClassName: '',
    isModuleConfigVisible: true,
    isReportLinkVisible: true,
  }

  render() {
    const {
      title, open_form_in_modal, information_title, children, breadcrumb_listing,
      gridStyle, gridClassName, headerRightComponents, headerClassName,
      processedBackendErrors, handledErrorKeys, moduleName,
      updateFormComponent, showDropDownMenuItems, headerTitleStyle, headerRightComponentsStyle,
      headerRightComponentClassName, isModuleConfigVisible, isReportLinkVisible,
    } = this.props;

    const unhandledErrors = getFormUnhandledErrors({ processedBackendErrors, handledErrorKeys });

    const headerTitleDefaultStyle: CSSProperties = {
      width: '100%',
      display: 'inline-block',
      ...headerTitleStyle,
    };

    return (
      <>
        {
          !open_form_in_modal
            ? (
              <BasicHeader
                title={title}
                moduleName={moduleName}
                breadcrumb_listing={breadcrumb_listing}
                headerClassName={headerClassName}
                showDropDownMenuItems={showDropDownMenuItems}
                updateFormComponent={updateFormComponent}
                isModuleConfigVisible={isModuleConfigVisible}
                isReportLinkVisible={isReportLinkVisible}
              />
            )
            : null
        }
        <Grid fluid className={`form-container-fluid ${gridClassName}`} style={gridStyle}>
          <Row>
            <Col md={12}>
              <div className="card">
                {
                  (!isEmpty(unhandledErrors))
                    ? (
                      <DMSFlashMessage
                        unhandledErrors={unhandledErrors}
                        flashMessageTitle={INVALID_INPUT_ERROR_HEADER_TEXT}
                      />
                    )
                    : null
                }
                {
                  (!open_form_in_modal && information_title)
                    ? (
                      <div className="header row">
                        <div className="col-md-12 card-header">
                          <div className="card-header-title">
                            <h4 className="text-muted">
                              <div className="information-title">

                                <span
                                  role="presentation"
                                  style={headerTitleDefaultStyle}
                                >
                                  {information_title}
                                </span>
                                <div style={headerRightComponentsStyle} className={headerRightComponentClassName}>
                                  {headerRightComponents}
                                </div>
                              </div>
                            </h4>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                    : null
                }
                <div className="form-content row">
                  {children}
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
}

export default DMSBasicCard;
