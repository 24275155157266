import * as types from '../../constants/accounting/accounts';

// Create SET_CHART_OF_ACCOUNTS action.
export function setAccountingAccounts(accounts_states) {
  return {
    type: types.SET_CHART_OF_ACCOUNTS,
    ...accounts_states,
  };
}

// Create ADD_CHART_OF_ACCOUNT action.
export function addAccountingAccount(account) {
  return {
    type: types.ADD_CHART_OF_ACCOUNT,
    item: account,
  };
}

// Create CHART_OF_ACCOUNT_FETCHED action.
export function accountingAccountFetched(account) {
  return {
    type: types.CHART_OF_ACCOUNT_FETCHED,
    item: account,
  };
}

// Create CHART_OF_ACCOUNT_UPDATED action
export function accountingAccountUpdated(account) {
  return {
    type: types.CHART_OF_ACCOUNT_UPDATED,
    item: account,
  };
}

// Create CHART_OF_ACCOUNT_DELETED action
export function accountingAccountDeleted(id) {
  return {
    type: types.CHART_OF_ACCOUNT_DELETED,
    id,
  };
}

export function setAccountingAccountFromAutocomplete(accountsPayload) {
  return {
    type: types.AUTOCOMPLETE_CHART_OF_ACCOUNT,
    ...accountsPayload,
  };
}
