export const INTEGRATION = 'INTEGRATION';
export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const FETCH_LAST_SUCCESSFUL_SYNCS_INTEGRATION = 'FETCH_LAST_SUCCESSFUL_SYNCS_INTEGRATION';
export const FETCH_LAST_SYNCS_INTEGRATION = 'FETCH_LAST_SYNCS_INTEGRATION';
export const UPDATE_SYNCS_INTEGRATION = 'UPDATE_SYNCS_INTEGRATION';
export const FETCH_LAST_SYNCS_INTEGRATION_API = 'FETCH_LAST_SYNCS_INTEGRATION_API';
export const FETCH_ALL_SYNCS_INTEGRATIONS_API = 'FETCH_ALL_SYNCS_INTEGRATIONS_API';
export const FETCH_LAST_SUCCESS_SYNCS_INTEGRATION_API = 'FETCH_LAST_SUCCESS_SYNCS_INTEGRATION_API';
export const UPDATE_LAST_SYNCS_INTEGRATION_API = 'UPDATE_LAST_SYNCS_INTEGRATION_API';

export const PREPARING = 'preparing';
export const SYNCING = 'syncing';
export const SUCCESS = 'success';
export const FAILED = 'failed';
