import map from 'lodash/map';
import { HOME_BREADCRUMB_LISTING } from './common';

// Views for helper text
export const DEBIT_NOTE_FORM_VIEW = 'debit_note_form';

// NOTE: Don't change this constants.
export const DEBIT_NOTE = 'DEBIT_NOTE';
export const SET_DEBIT_NOTES = 'SET_DEBIT_NOTES';
export const ADD_DEBIT_NOTE = 'ADD_DEBIT_NOTE';
export const DEBIT_NOTE_FETCHED = 'DEBIT_NOTE_FETCHED';
export const DEBIT_NOTE_UPDATED = 'DEBIT_NOTE_UPDATED';
export const DEBIT_NOTE_DELETED = 'DEBIT_NOTE_DELETED';
export const DEBIT_NOTE_EDIT = 'DEBIT_NOTE_EDIT';
export const AUTOCOMPLETE_DEBIT_NOTES = 'AUTOCOMPLETE_DEBIT_NOTES';
export const OPEN_STATE_CHANGE_CONFIRMATION_MODAL = 'OPEN_STATE_CHANGE_CONFIRMATION_MODAL';
export const AUTOCOMPLETE_PURCHASE_RETURN_ITEM_DEBIT_NOTES = 'AUTOCOMPLETE_PURCHASE_RETURN_ITEM_DEBIT_NOTES';

export const GENERATE_DEBIT_NOTE_FROM_PURCHASE_RETURN = 'GENERATE_DEBIT_NOTE_FROM_PURCHASE_RETURN';

export const FORM_INFORMATION_TITLE = 'CREDIT NOTE INFORMATION';
export const RESET_DEBIT_NOTE_ERRORS = 'RESET_DEBIT_NOTE_ERRORS';
export const GENERATE_BULK_PDF = 'GENERATE_BULK_PDF';

export const FETCH_DEBIT_NOTES = 'FETCH_DEBIT_NOTES';

export const title = 'Debit Notes';
export const singularTitle = 'Debit Note';
export const moduleName = 'debit_notes';
export const baseUrl = '/debit_notes';
export const redirectUrl = '/debit_notes';
export const listingDataKey = 'debit_notes';
export const itemDataKey = 'debit_note';

export const debitNoteConstants = {
  item: DEBIT_NOTE,
  setItems: SET_DEBIT_NOTES,
  addItem: ADD_DEBIT_NOTE,
  setItem: DEBIT_NOTE_FETCHED,
  updateItem: DEBIT_NOTE_UPDATED,
  deleteItem: DEBIT_NOTE_DELETED,
  autocompleteItems: AUTOCOMPLETE_DEBIT_NOTES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  party_type_eq: '',
  e_invoice_id_null: '',
  ref_voucher_type_eq: '',
  party_gstin_cont: '',
  party_gstin_present: '',
  state_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  debit_note_items_debit_reason_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_party_name_cont: '',
  ...advanceSearchParams,
};

export const INVOICE = 'Invoice';
export const PURCHASE_RETURN_LABEL = 'Purchase Return';
export const PURCHASE_RETURN = 'PurchaseReturn';

export const ISSUED = 'issued';
export const CANCELLED = 'cancelled';
export const ISSUED_BTN_NAME = 'Issued';
export const CANCELLED_BTN_NAME = 'Cancelled';

export const DEBIT_NOTE_STATES = [
  { label: ISSUED_BTN_NAME, value: ISSUED }, { label: CANCELLED_BTN_NAME, value: CANCELLED },
];

export const DEBIT_NOTE_STATES_FOR_REPORTS = map(DEBIT_NOTE_STATES,
  (state) => ({ name: state.label, id: state.value }));

export const REF_VOUCHER_TYPES = [{ label: 'Invoice', value: 'Invoice' }];

export const requiredPrefetchingModuleNames = ['branches', 'debit_reasons', 'warehouses', 'suppliers', 'customers', 'voucher_books'];

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'branch', 'branch_id', 'party', 'party_type', 'party_id', 'ref_voucher_type', 'ref_voucher_id', 'notes'],
  debit_note_items: ['variant', 'variant_id', 'description', 'purchase_return_item_id', 'quantity', 'net_price', 'price', 'net_amount', 'total_amount', 'tax_category', 'tax_category_id', 'tax_amount', 'debit_reason', 'debit_reason_id', 'purchase_return_item_debit_note_items'],
  debit_note_purchase_invoices: ['purchase_invoice_id', 'purchase_invoice', 'amount'],
};
