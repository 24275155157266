import React, { PureComponent } from 'react';
import {
  func, shape, string, number, oneOfType, bool,
} from 'prop-types';
import { filter, find } from 'lodash';
import ShowMore from 'react-show-more';
import classnames from 'classnames';
import Loadable from 'react-loadable';
import Loading from '@component/common/Loading';
import { FormGroup, FormControl } from 'react-bootstrap';
import {
  CANCELLATION_REASONS, DISCOUNT_TYPES, FLAT, handledErrorKeys, moduleName, PERCENTAGE,
} from '@constants/orders';
import { ADD_PRODUCT } from '@constants/products';
import { DECIMAL_NUMBER_REGEX } from '@constants/common';
import {
  doesErrorExistOnField, getFieldErrors, priceInclusiveOrExclusiveTax, validateNumberValue,
  validateEditedNumberValue, getSelectedProductUnit, generateDynamicDecimalRegex,
} from '@helpers/common_helper';
import AddItemButton from '@component/common/AddItemButton';
import DMSSelect from '@component/common/DMSSelect';
// import AltUnitInfo from '@component/common/alt_unit_info';
import { getNestedFormIndexWiseUnhandledErrors, getNestedFormUnhandledBaseErrors } from '@component/common/DMSFlashMessage/helper';
import DisplayNestedFormErrors from '@component/common/DMSFlashMessage/display_nested_form_errors';
import FormSectionTitle from '@component/common/form_components/form_section_title';
import NestedFormLabel from '@component/common/form_components/NestedFormLabel';
import FormFieldLabel from '@component/common/FormFieldLabel';
import ItemsSortable from '@component/common/items_sortable';
import DragablePositionCell from '@component/common/items_sortable/dragable_position_cell';
import CustomUnitOption from '@component/orders/custom_unit_option';
import { getClientSetting } from '@helpers/settings_helper';

const HistoricalPricingLink = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonProductHistoricalPricing" */ '@component/common/product_historical_pricing'),
  loading: Loading,
});

// Constants
const ID = 'id';
const BRAND_CATEGORY_MRP_NAME = 'brand_category_mrp_name';
const ORDER_ITEMS_SORT_BY = 'order_items_sort_by';
const nestedFormName = 'order_items';

// const unitInfo = (order_item, indexProducts) => {
//   if (order_item?.input_booked_quantity && (order_item?.input_quantity_unit !== order_item?.primary_unit)) {
//     return (<span className="text-muted">{`${order_item?.input_booked_quantity} ${order_item?.input_quantity_unit}`}</span>);
//   }
//   if (order_item?.id) return (<AltUnitInfo variant={{ units: order_item?.units, primary_unit: order_item?.primary_unit }} products={indexProducts} variantId={order_item?.variant_id} quantity={order_item?.input_booked_quantity} />);
//   return <AltUnitInfo products={indexProducts} variantId={order_item?.variant_id} quantity={order_item?.input_booked_quantity} />;
// };

class OrderItemNestedForm extends PureComponent {
  components = {
    [ID]: ItemsSortable,
    [BRAND_CATEGORY_MRP_NAME]: 'div',
  };

  render() {
    const {
      isLoading, loadingIdentifier, order, products, handleNestedFormInputChange,
      addNestedFormItem, selectNestedFormChange, selectVariantNestedFormChange,
      getSimpleAndVariantProducts, removeNestedFormItem, errors, settings,
      processedBackendErrors, updateFormComponentState, nestedFormAutoFocusIndex,
      handleOrderItemCustomDiscountNestedFormInputChange,
    } = this.props;

    const {
      client_settings: clientSettings, client_ui_permissions: clientUiPermissions,
    } = settings;
    const { accounting_currency: accountingCurrency } = clientSettings;

    const canSetItemDiscount = clientUiPermissions?.order_items?.can_set_item_discount;

    const { order_items_attributes } = order;

    // SETUP-SECTION unhandled errors
    const unhandledErrors = getNestedFormUnhandledBaseErrors({
      processedBackendErrors, nestedFormName,
    });
    // !SETUP-SECTION unhandled errors

    // order_items_sort_by ['id', 'brand_category_mrp_name']
    // if order_items_sort_by 'id' then show drag and drop functionality.
    // if order_items_sort_by 'brand_category_mrp_name' then hide drag and drop functionality and show normal functionality.
    const orderItemsSortBy = getClientSetting(ORDER_ITEMS_SORT_BY);
    const orderItemsSortById = !!(orderItemsSortBy === ID);
    const CustomTag = this.components?.[orderItemsSortBy] || 'div';

    let count = 1;
    return (
      <>
        <div className="tasks-fieldset row" data-cy="order-items">

          <FormSectionTitle
            displayTitle="ORDER ITEMS"
            isNestedFormFlashMessage
            unhandledErrors={unhandledErrors}
            view="order_form"
          />

          <div className="task-form">
            <div className="col-md-12 flex-box">
              <div className="col-md-half text-center item-serial-number">
                <NestedFormLabel displayTitle={orderItemsSortById ? '#' : 'S. No'} sNoLabel />
              </div>
              <div className={canSetItemDiscount ? 'col-md-4' : 'col-md-6'}>
                <NestedFormLabel
                  dataCy="order-item-variant-required"
                  displayTitle="Product/Service"
                  view="order_form"
                  isRequired
                />
              </div>
              <div className="col-md-2-half">
                <NestedFormLabel
                  dataCy="order-item-input-booked-quantity-required"
                  displayTitle="Quantity"
                  view="order_form"
                  isRequired
                />
              </div>
              <div className="col-md-2-half nested-form-price-per-unit-labels">
                <NestedFormLabel
                  dataCy="order-item-input-price-required"
                  displayTitle="Price"
                  view="order_form"
                  isRequired
                  className="price-label"
                />
                <NestedFormLabel
                  dataCy="order-item-input-price-required"
                  displayTitle="Tax"
                  view="order_form"
                  className="tax-label"
                />
                <NestedFormLabel
                  dataCy="order-item-input-price-required"
                  displayTitle="Per Unit"
                  view="order_form"
                  isRequired
                  className="per-unit-label"
                />
              </div>
              {
                canSetItemDiscount && (
                  <div className="col-md-2">
                    <NestedFormLabel
                      dataCy="order-item-discount-required"
                      displayTitle="Discount"
                      view="order_form"
                      className="discount-label"
                    />
                  </div>
                )
              }
              <div className="col-md-half">
                <NestedFormLabel actionLabel />
              </div>
            </div>
          </div>
          {
            order_items_attributes && (filter(order_items_attributes, { _destroy: false }).length < 1)
              ? (
                <div className="task-form">
                  <div className="col-md-12">
                    <div className="col-md-12 no-record-found">
                      <hr />
                      No Order Items Found
                    </div>
                  </div>
                </div>
              )
              : (
                <CustomTag
                  nestedItemsAttributes={order_items_attributes}
                  nestedFormName="order_items_attributes"
                  updateFormComponentState={updateFormComponentState}
                >
                  {
                    order_items_attributes?.map((order_item) => {
                      const idx = order_item?.index;
                      if (order_item?._destroy === false) {
                        const priceInclusiveOrExclusiveTaxText = priceInclusiveOrExclusiveTax({
                          inclusive_tax: order_item?.inclusive_tax,
                        });
                        const indexProducts = (products?.[idx]) || [];

                        let quantity_units = (order_item?.quantity_units) || [];
                        let units = (order_item?.units) || [];

                        if ((order_item?.variant_id) && (indexProducts?.length > 0)) {
                          const product = find(indexProducts, { id: order_item?.variant_id });
                          units = (product?.units) || [];
                          quantity_units = (product?.quantity_units) || [];
                        }

                        // SETUP-SECTION unhandled errors
                        const unhandledNestedFormErrors = getNestedFormIndexWiseUnhandledErrors({
                          processedBackendErrors, idx, nestedFormName, handledErrorKeys,
                        });
                        // !SETUP-SECTION unhandled errors

                        const isNewOrderItem = ((order?.id) && (order_item?.id));
                        const selectedProductUnit = getSelectedProductUnit(quantity_units, order_item?.input_quantity_unit);
                        const orderItemDom = (
                          <div id={orderItemsSortById ? order_item?.position : `order_item_nested_items_${count}`} className={orderItemsSortById ? 'nested-draggable-item task-form form-group' : 'task-form form-group'} key={(order_item?.id || `order_item_${idx}`)} data-cy={`nested-form-${idx}-item`}>
                            <DisplayNestedFormErrors
                              unhandledErrors={unhandledNestedFormErrors || {}}
                            />
                            <div className="col-md-12">

                              <DragablePositionCell
                                idx={idx}
                                position={orderItemsSortById ? order_item?.position : count}
                                showCursorGrab={!!orderItemsSortById}
                              />

                              <div className={classnames(canSetItemDiscount ? 'col-md-4' : 'col-md-6', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].variant`, `order_items[${idx}].variant_id`] }) })}>
                                <DMSSelect
                                  id={`order-item-${idx}-variant`}
                                  placeholder="Search by name or SKU or barcode"
                                  autoComplete="off"
                                  name="variant_id"
                                  value={order_item?.variant_id || ''}
                                  onChange={!isNewOrderItem ? (selectedItem) => selectVariantNestedFormChange(selectedItem, 'variant_id', [`order_items[${idx}].variant`, `order_items[${idx}].variant_id`], idx) : undefined}
                                  onInputChange={!isNewOrderItem
                                    ? (input) => getSimpleAndVariantProducts(input, idx)
                                    : undefined}
                                  valueKey="id"
                                  labelKey="name"
                                  isLoading={(isLoading && (loadingIdentifier === idx))}
                                  filterOptions={false}
                                  options={isNewOrderItem ? [{ id: (order_item?.variant_id || ''), name: (order_item?.product_label || '') }] : indexProducts || []}
                                  disabled={!!isNewOrderItem}
                                  tabIndex={isNewOrderItem ? -1 : 0}
                                  showCustomNoResultsText
                                  identifier={ADD_PRODUCT}
                                  className="wrappable-select"
                                  autoFocus={!!(nestedFormAutoFocusIndex === idx)}
                                />
                                <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].variant`, `order_items[${idx}].variant_id`] })}</span>
                                {/* {unitInfo(order_item, indexProducts)} */}
                                {
                                  clientSettings?.order_item_show_description_field
                                    ? (
                                      <div className={classnames({ error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].description`] }) })}>
                                        <FormControl
                                          id="order-item-description"
                                          componentClass="textarea"
                                          bsClass="form-control"
                                          placeholder="Description"
                                          name="description"
                                          value={order_item?.description || ''}
                                          onChange={(event) => handleNestedFormInputChange(event, `order_items[${idx}].description`, idx)}
                                          style={{ height: 40 }}
                                        />
                                        <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].description`] })}</span>
                                      </div>
                                    )
                                    : null
                                }
                              </div>

                              <div className="col-md-2-half">
                                <div className={classnames('input-group flex', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].input_booked_quantity`, `order_items[${idx}].booked_quantity`, `order_items[${idx}].quantity`] }) })}>
                                  <input
                                    id={`order-item-${idx}-input-booked-quantity`}
                                    data-cy={`order-item-${idx}-input-booked-quantity`}
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Quantity"
                                    onChange={!(order_item?.disable_quantity_field) ? (event) => {
                                      const isValid = validateEditedNumberValue({
                                        event,
                                        item: order_item,
                                        units: quantity_units,
                                        inputKey: 'input_booked_quantity',
                                      });
                                      if (isValid) {
                                        handleNestedFormInputChange(event, [`order_items[${idx}].input_booked_quantity`, `order_items[${idx}].booked_quantity`, `order_items[${idx}].quantity`], idx);
                                      }
                                    } : undefined}
                                    value={order_item?.input_booked_quantity || ''}
                                    className="form-control z-index-zero"
                                    name="input_booked_quantity"
                                    disabled={order_item?.disable_quantity_field}
                                  // pattern="[0-9]-[0-9]"
                                  // pattern="[0-9]+([-\,][0-9])?"
                                  />
                                  <div className="order-item-input-quantity-unit">
                                    <DMSSelect
                                      id={`order-item-${idx}-input-quantity-unit`}
                                      placeholder="Unit"
                                      name="unit"
                                      value={order_item?.input_quantity_unit || ''}
                                      onChange={!(order_item?.disable_quantity_field)
                                        ? (selectedObject) => {
                                          selectNestedFormChange((selectedObject?.unit) || '', 'input_quantity_unit', [`order_items[${idx}].input_quantity_unit`], idx);
                                        } : undefined}
                                      options={quantity_units || []}
                                      optionComponent={CustomUnitOption}
                                      valueKey="unit"
                                      labelKey="label"
                                      clearable={false}
                                      disabled={order_item?.disable_quantity_field}
                                      tabIndex={(!!order.id && order_item?.disable_quantity_field) ? -1 : 0}
                                    />
                                  </div>
                                </div>
                                <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].input_booked_quantity`, `order_items[${idx}].booked_quantity`, `order_items[${idx}].quantity`] })}</span>
                                <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].input_quantity_unit`] })}</span>
                                {/* {unitInfo(order_item, indexProducts)} */}

                                {/* Cancelled quantity and reason */}
                                {!!order_item?.id && (
                                  <div className="input-group flex row no-gutter">
                                    <div className={classnames('col-md-4-half', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].input_cancelled_quantity`] }) })}>
                                      <FormGroup controlId="input_cancelled_quantity">
                                        <FormFieldLabel
                                          id="order-cancelled-quantity-label"
                                          displayName={(
                                            <ShowMore
                                              lines={1}
                                              more=""
                                              less=""
                                              anchorClass=""
                                            >
                                              Cancelled Qty.
                                            </ShowMore>
                                          )}
                                          view="order_form"
                                        />
                                        <input
                                          id={`order-item-${idx}-input-cancelled-quantity`}
                                          data-cy={`order-item-${idx}-input-cancelled-quantity`}
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Cancelled Quantity"
                                          onChange={(event) => {
                                            const isValid = validateNumberValue(event.target.value);
                                            if (isValid) {
                                              handleNestedFormInputChange(event, `order_items[${idx}].input_cancelled_quantity`, idx);
                                            }
                                          }}
                                          value={order_item?.input_cancelled_quantity || ''}
                                          className="form-control z-index-zero"
                                          name="input_cancelled_quantity"
                                          style={order_item?.cancellable_quantity ? { borderRight: 0 } : {}}
                                        />
                                        <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].input_cancelled_quantity`] })}</span>
                                        <small className="text-muted">{`Cancellable qty : ${order_item?.input_cancelled_quantity}`}</small>
                                      </FormGroup>
                                    </div>
                                    <div className={classnames('col-md-7-half', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].cancellation_reason`] }) })}>
                                      {!!(order_item?.cancellable_quantity) && (
                                        <FormGroup controlId="cancelled_quantity">
                                          <FormFieldLabel
                                            id="order-cancelled-reason-label"
                                            displayName="Reason"
                                            view="order_form"
                                          />
                                          <DMSSelect
                                            id={`order-item-${idx}-reason`}
                                            placeholder="Select reason"
                                            autoComplete="off"
                                            name="cancellation_reason"
                                            value={order_item?.cancellation_reason || ''}
                                            onChange={(selectedItem) => selectNestedFormChange((selectedItem?.value) || '', 'cancellation_reason', [`order_items[${idx}].cancellation_reason`], idx)}
                                            options={CANCELLATION_REASONS || []}
                                          />
                                        </FormGroup>
                                      )}
                                      <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].cancellation_reason`] })}</span>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="col-md-2-half">
                                <div className={classnames('input-group nested-form-price-per-unit-fields', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].input_price`] }) })}>
                                  <input
                                    id={`order-item-${idx}-input-price`}
                                    data-cy={`order-item-${idx}-input-price`}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={order_item?.input_price || 'Price'}
                                    onChange={!order_item?.disable_price_field ? (event) => {
                                      const isDecimalNumber = validateNumberValue(
                                        event.target.value, DECIMAL_NUMBER_REGEX,
                                      );
                                      if (isDecimalNumber) {
                                        handleNestedFormInputChange(event, `order_items[${idx}].input_price`, idx);
                                      }
                                    } : undefined}
                                    value={order_item?.input_price || ''}
                                    className="form-control z-index-zero price-field"
                                    name="input_price"
                                    disabled={order_item?.disable_price_field}
                                    pattern="[0-9].[0-9]"
                                  />
                                  <span className="price-per-input-price-per-unit flex items-center tax-field">
                                    {priceInclusiveOrExclusiveTaxText}
                                  </span>
                                  <span className="price-per-input-price-per-unit flex items-center">
                                    /
                                  </span>
                                  <div className="per-unit-field">
                                    <DMSSelect
                                      id={`order-item-${idx}-input-price-per-unit`}
                                      placeholder="Unit"
                                      optionComponent={CustomUnitOption}
                                      name="unit"
                                      value={order_item?.input_price_per_unit || ''}
                                      onChange={!(order_item?.disable_price_field) ? (selectedObject) => selectNestedFormChange((selectedObject?.unit) || '', 'input_price_per_unit', [`order_items[${idx}].input_price_per_unit`], idx) : undefined}
                                      options={units || []}
                                      valueKey="unit"
                                      labelKey="label"
                                      clearable={false}
                                      disabled={order_item?.disable_price_field}
                                      tabIndex={(!!order.id && order_item?.disable_price_field) ? -1 : 0}
                                    />
                                  </div>
                                </div>
                                <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].input_price`] })}</span>
                                <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].input_price_per_unit`] })}</span>
                                {
                                  (!order_item?.id && order_item?.min_price)
                                    ? (
                                      <span className="text-muted">
                                        Min price :
                                        {order_item?.min_price}
                                      </span>
                                    )
                                    : null
                                }
                                {
                                  (order_item?.variant_id && order?.customer_id)
                                    ? (
                                      <HistoricalPricingLink
                                        voucherModuleName={moduleName}
                                        partyId={order?.customer_id}
                                        partyName={order?.customer_name}
                                        variantData={order_item || {}}
                                        priceUnits={units}
                                        settingKey="order_item_show_matching_voucher_items"
                                      />
                                    )
                                    : null
                                }
                              </div>
                              {
                                canSetItemDiscount && (
                                  <div className="col-md-2">
                                    <div className={classnames('item-custom-discount-field input-group flex', { error: doesErrorExistOnField({ errors, error_keys: [`order_items[${idx}].order_item_custom_discount.discount_type`, `order_items[${idx}].order_item_custom_discount.value`] }) })}>
                                      <div className="discount-type">
                                        <DMSSelect
                                          id={`order-item-${idx}-order-item-custom-discount-discount-type`}
                                          placeholder="Type"
                                          name=""
                                          value={order_item?.order_item_custom_discount_attributes?.discount_type || ''}
                                          onChange={(selectedObject) => {
                                            handleOrderItemCustomDiscountNestedFormInputChange('discount_type', selectedObject?.value || '', [`order_items[${idx}].order_item_custom_discount.discount_type`], idx);
                                          }}
                                          options={DISCOUNT_TYPES.map((disType) => {
                                            if (disType?.value === FLAT) {
                                              return { label: accountingCurrency || 'Flat', value: FLAT };
                                            }
                                            return disType;
                                          }) || []}
                                          clearable
                                        />
                                      </div>
                                      <input
                                        id={`order-item-${idx}-order-item-custom-discount-value`}
                                        data-cy={`order-item-${idx}-order-item-custom-discount-value`}
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Discount"
                                        onChange={(event) => {
                                          const value = event?.target?.value || '';
                                          const decimalValidatorRegex = generateDynamicDecimalRegex(2);
                                          const isValid = validateNumberValue(
                                            value, decimalValidatorRegex,
                                          );
                                          const discountType = order_item?.order_item_custom_discount_attributes?.discount_type || '';

                                          if (isValid) {
                                            if (discountType === PERCENTAGE) {
                                              const parsedValue = value && parseFloat(value);
                                              if ((parsedValue >= 0) && (parsedValue <= 100)) {
                                                handleOrderItemCustomDiscountNestedFormInputChange('value', value, [`order_items[${idx}].order_item_custom_discount.value`], idx);
                                              }
                                            } else {
                                              handleOrderItemCustomDiscountNestedFormInputChange('value', value, [`order_items[${idx}].order_item_custom_discount.value`], idx);
                                            }
                                          }
                                        }}
                                        value={order_item?.order_item_custom_discount_attributes?.value || ''}
                                        className="form-control discount-value z-index-zero"
                                        name="order_item_custom_discount_value"
                                        disabled={!order_item?.order_item_custom_discount_attributes?.discount_type}
                                      />
                                    </div>
                                    <span className="errors-message">{getFieldErrors({ errors, error_keys: [`order_items[${idx}].order_item_custom_discount.discount_type`, `order_items[${idx}].order_item_custom_discount.value`] })}</span>
                                  </div>
                                )
                              }

                              <div className="col-md-half text-right order-items nested-form-item-destroy-btn">
                                <button
                                  id={`order-item-${idx}-delete`}
                                  type="button"
                                  className={`btn btn-danger-outline btn-block text-center ${!order_item?.can_be_destroyed ? 'disabled' : ''}`}
                                  tabIndex={!order_item?.can_be_destroyed ? '-1' : ''}
                                  data-cy={`delete-${idx}-nested-form`}
                                  onClick={order_item?.can_be_destroyed
                                    ? () => removeNestedFormItem(idx) : undefined}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </div>

                            </div>
                          </div>
                        );
                        count += 1;
                        return orderItemDom;
                      }
                      return null;
                    })
                  }
                </CustomTag>
              )
          }
        </div>
        <AddItemButton handleClick={(event) => {
          addNestedFormItem(event);
        }}
        />
      </>
    );
  }
}

OrderItemNestedForm.propTypes = {
  isLoading: bool,
  loadingIdentifier: oneOfType([number, string]),
  order: shape({}),
  products: shape({}),
  getSimpleAndVariantProducts: func.isRequired,
  addNestedFormItem: func.isRequired,
  selectVariantNestedFormChange: func.isRequired,
  handleNestedFormInputChange: func.isRequired,
  selectNestedFormChange: func.isRequired,
  removeNestedFormItem: func.isRequired,
  errors: shape({}),
  settings: shape({}).isRequired,
  processedBackendErrors: shape({}),
  updateFormComponentState: func.isRequired,
  nestedFormAutoFocusIndex: oneOfType([number, string]),
  handleOrderItemCustomDiscountNestedFormInputChange: func.isRequired,
};

OrderItemNestedForm.defaultProps = {
  isLoading: false,
  loadingIdentifier: '',
  order: {},
  products: {},
  errors: {},
  processedBackendErrors: {},
  nestedFormAutoFocusIndex: '',
};

export default OrderItemNestedForm;
