import { GET } from '@utils/axios_methods';
import { serializeSearchParams } from '../../utils/SerializeParams';

export const CHART_DEFAULT_PARAMS = '@actions/CHART_DEFAULT_PARAMS';
export const CHART_REQUEST = '@actions/CHART_REQUEST';
export const CHART_RESPONSE = '@actions/CHART_RESPONSE';

export function setChartParamsAction(
  searchParams: any, groupBy: [], activeReportPeriod: string,
) {
  return {
    type: CHART_DEFAULT_PARAMS,
    activeReportPeriod,
    searchParams,
    groupBy,
  };
}

export function chartRequestAction(chartKey: string) {
  return {
    type: CHART_REQUEST,
    chartKey,
  };
}

export function chartResponseAction(
  chartKey: string, response = { data: {}, meta: {}, totals: [] },
) {
  return {
    type: CHART_RESPONSE, chartKey, response,
  };
}

export const getChartsData = (
  args: {api: string; groupBy: []; searchParams: {}; chartKey: string; refresh: boolean; module_name: string},
) => {
  const {
    api, groupBy, searchParams, chartKey, refresh, module_name,
  } = args;
  const q = serializeSearchParams(searchParams);
  return (dispatch) => {
    // Fetching Request
    dispatch(chartRequestAction(chartKey));
    return GET({
      url: `${api}?${q}`,
      params: { group_by: groupBy, refresh },
      error: { redirect_to: '/', module_name },
    })
      .then((response) => {
        dispatch(chartResponseAction(chartKey, response?.data || {}));
      });
  };
};
