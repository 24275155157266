import {
  FETCH_INTEGRATIONS, SET_INTEGRATIONS, INTEGRATION, FETCH_LAST_SUCCESSFUL_SYNCS_INTEGRATION,
  FETCH_LAST_SYNCS_INTEGRATION, UPDATE_SYNCS_INTEGRATION,
} from '@constants/integrations';
import {
  fetchAllIntegrations, fetchLastSuccessfulSyncsIntegration, fetchLastSyncsIntegration,
  updateSyncsIntegration,
} from '@api/integrations';
import { call, put, takeLatest } from 'redux-saga/effects';
import { startFetchingRequest } from '@actions/action_helpers';

const setAllIntegrations = (payload: { integrations: any[] }) => ({
  type: SET_INTEGRATIONS,
  payload,
});

function* fetchAllIntegrationsFunc(action: any) {
  startFetchingRequest(INTEGRATION);
  const { url } = action;
  try {
    const response = yield call(fetchAllIntegrations({ url }));

    const integrations = response?.data?.integrations || {};
    yield put(setAllIntegrations({ integrations }));
    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* fetchLastSuccessfulSyncsIntegrationFunc(action: any) {
  const { integrationId } = action;
  try {
    const response = yield call(fetchLastSuccessfulSyncsIntegration({ integrationId }));
    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* fetchLastSyncsIntegrationFunc(action: any) {
  const { integrationId } = action;
  try {
    const response = yield call(fetchLastSyncsIntegration({ integrationId }));

    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* updateSyncsIntegrationFunc(action: any) {
  const { integrationId, integrationSyncId, state } = action;
  try {
    const response = yield call(updateSyncsIntegration({
      integrationId, integrationSyncId, state,
    }));

    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

export function* integrationsWatcher() {
  yield takeLatest(FETCH_INTEGRATIONS, fetchAllIntegrationsFunc);
  yield takeLatest(FETCH_LAST_SUCCESSFUL_SYNCS_INTEGRATION,
    fetchLastSuccessfulSyncsIntegrationFunc);
  yield takeLatest(FETCH_LAST_SYNCS_INTEGRATION, fetchLastSyncsIntegrationFunc);
  yield takeLatest(UPDATE_SYNCS_INTEGRATION, updateSyncsIntegrationFunc);
}
