import { call, put, takeLatest } from 'redux-saga/effects';
import { moduleName, FETCH_PURCHASE_RETURNS, AUTOCOMPLETE_PURCHASE_RETURNS } from '@constants/purchase_returns';
import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@utils/SerializeParams';
import { BASE_URL } from '@constants/common';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { autocompletePurchaseReturns, fetchPurchaseReturns } from '@api/purchase_returns';

// Save PurchaseReturn.
export function savePurchaseReturn(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      purchase_return: data,
    },
  })
    .then((response) => {
      const { purchase_return } = response.data;
      dispatch(addItem({ moduleName, item: purchase_return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get PurchaseReturn.
export function fetchPurchaseReturn(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { purchase_return } = response.data;
      dispatch(setItem({ moduleName, item: purchase_return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update PurchaseReturn.
export function updatePurchaseReturn(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      purchase_return: data,
    },
  })
    .then((response) => {
      const { purchase_return } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete PurchaseReturn.
export function deletePurchaseReturn(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: {
      state,
    },
  })
    .then((response) => {
      const { purchase_return } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompletePurchaseReturnNumber({ customer_id, search_params }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `/customers/${customer_id}/purchase_returns/autocomplete_purchase_returns_number?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { purchase_returns } = response.data;
      dispatch(autocompleteItems({ moduleName, items: purchase_returns }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function generatePurchaseReturnFromPurchaseInvoice(purchaseInvoiceId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    baseURL: BASE_URL,
    params: {
      purchase_invoice_id: purchaseInvoiceId,
    },
  })
    .then((response) => {
      const { purchase_return } = response.data;
      dispatch(setItem({ moduleName, item: purchase_return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchPurchaseReturnsSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchPurchaseReturns({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { purchase_returns, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: purchase_returns,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompletePurchaseReturnsFunc(action: any) {
  const { search_params } = action;

  try {
    const response = yield call(autocompletePurchaseReturns({ search_params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* purchaseReturnsWatcher() {
  yield takeLatest(FETCH_PURCHASE_RETURNS, fetchPurchaseReturnsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_PURCHASE_RETURNS, autocompletePurchaseReturnsFunc);
}
