// Constants
import { moduleName as orders, ORDER_STATES } from '@constants/orders';
import { moduleName as invoices, INVOICE_STATES } from '@constants/invoices';
import {
  moduleName as offers, PRODUCTS_FILTER, CONDITION_PRODUCTS_FILTER, DISABLED_OPTIONS,
  CONDITION_ON_OPTIONS, CONDITION_OPERATOR_OPTIONS, CUSTOMERS_FILTER, OFFER_TYPES, IMPACT_TYPE_OPTIONS_FOR_ITEM_OFFERS, IMPACT_ON_OPTIONS,
} from '@constants/offers';
import {
  moduleName as payments, PAYMENT_STATES, ACTIVE_STATUS, IS_UNALLOCATED_OPTIONS,
} from '@constants/payments';
import { moduleName as purchaseInvoices, PURCHASE_INVOICE_STATES } from '@constants/purchase_invoices';
import { moduleName as purchaseOrders, PURCHASE_ORDER_STATES } from '@constants/purchase_orders';
import { moduleName as creditNotes, CREDITABLE_TYPES, CREDIT_NOTE_STATES } from '@constants/credit_notes';
import {
  moduleName as debitNotes, DEBIT_NOTE_STATES, REF_VOUCHER_TYPES,
} from '@constants/debit_notes';
import {
  PARTY_TYPES, DATE, apiDefaultDateTimeFormat, fallBackDatetimeFormat, fallBackDateFormat,
  SUPPLIER, CUSTOMER,
} from '@constants/common';
import { moduleName as stocks, STOCK_LOGABLE_TYPE } from '@constants/stocks';
import { moduleName as accountingEntries, VOUCHER_TYPE, ACCOUNT_ENTRY_TYPE } from '@constants/accounting/entries';
import { moduleName as reconEntries } from '@constants/accounting/recon_entries';
import { CREDIT_NOTE_STATUS, moduleName as returns } from '@constants/returns';
import { moduleName as customers, CUSTOMER_GSTIN_FILTER_VALUES } from '@constants/customers';
import { moduleName as stockEntries } from '@constants/stock_entries';
import {
  moduleName as products, PRODUCT_WITHOUT_TAXES_OPTIONS, PRODUCT_WITHOUT_HSN_CODE_OPTIONS,
  PRODUCT_TYPES,
} from '@constants/products';
import { DEBIT_NOTE_STATUS, moduleName as purchaseReturns } from '@constants/purchase_returns';
import { moduleName as bankAccounts } from '@constants/bank_accounts';
import { moduleName as downloads } from '@constants/downloads';
import { moduleName as stockTransfers } from '@constants/stock_transfers';
import { moduleName as paymentOuts, PAYMENT_OUT_STATES } from '@constants/payment_outs';
import { moduleName as voucherBooks } from '@constants/voucher_books';
import { moduleName as inventoryBatches } from '@constants/inventory_batches';
import { moduleName as inventoryPieces } from '@constants/inventory_pieces';
import { moduleName as reconciliations, RECONCILIATION_STATES } from '@constants/accounting/reconciliations';
import { openingBalDateFilterOptions } from '@constants/accounting/amounts';

// SETUP-SECTION advanced search
// !SETUP-SECTION advanced search

// Helpers
import { getFinancialYears, getI18nFields } from '@helpers/common_helper';
import { getYearFromNow } from '@helpers/date_picker_helper';
import { E_INVOICE_STATUSES } from '@constants/e_invoice';
import { E_WAY_BILL_STATUSES } from '@component/common/e_way_bill/constants';
import { INVENTORY_TRACKING_LEVEL } from '@constants/inventories';

const customersI18nFields = getI18nFields('customers') || {};
const gstinDetails = customersI18nFields?.gstin || {};

export const fromDateFilter = {
  key: 'from_date',
  placeholder: 'From date',
  label: 'From date',
  query_key: 'date_gteq',
  input_type: 'date',
  min_date: new Date('2001-1-1'),
  max_date: getYearFromNow(10),
  type: DATE,
  show_time_select: false,
  className: 'col-md-4',
};

export const toDateFilter = {
  key: 'to_date',
  placeholder: 'To date',
  label: 'To date',
  query_key: 'date_lteq',
  input_type: 'date',
  min_date: new Date('2001-1-1'),
  max_date: getYearFromNow(10),
  type: DATE,
  show_time_select: false,
  className: 'col-md-4',
};

export const statusFilter = {
  key: 'status',
  placeholder: 'Select statuses',
  label: 'Status',
  query_key: 'state_in',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: true,
  should_prefetch: false,
  call_api: false,
  options: ORDER_STATES,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const eInvoiceStatusFilter = {
  key: 'status',
  placeholder: 'Select E invoice statuses',
  label: 'E invoice status',
  query_key: 'e_invoice_id_null',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: E_INVOICE_STATUSES,
  type: 'array',
  className: 'col-md-6',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const eWayBillStatusFilter = {
  key: 'status',
  placeholder: 'Select E Way Bill statuses',
  label: 'E Way Bill status',
  query_key: 'e_way_bill_id_null',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: E_WAY_BILL_STATUSES,
  type: 'array',
  className: 'col-md-6',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const openingBalFilter = {
  key: 'before_opening_balance',
  placeholder: 'Select Option',
  label: 'Based On Opening Balance Date',
  query_key: 'before_opening_balance',
  input_type: 'dropdown',
  should_prefetch: false,
  call_api: false,
  options: openingBalDateFilterOptions,
  type: 'array',
  className: 'col-md-12',
};

// export const partiallyInvoiceFilter = {
//   key: 'partially_invoiced',
//   labelName: 'Yes',
//   label: 'Partially Invoiced',
//   query_key: 'partially_invoiced',
//   input_type: 'checkbox',
//   showLabel: true,
//   disabled: false,
//   type: 'array',
//   className: 'col-md-6',
//   customStyle: { height: 42, lineHeight: '42px' },
// };

export const userFilter = {
  key: 'users',
  placeholder: 'Select users',
  label: 'Users',
  query_key: 'user_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const bankFilter = {
  key: 'banks',
  placeholder: 'Select banks',
  label: 'Banks',
  query_key: 'bank_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const brandFilter = {
  key: 'brands',
  placeholder: 'Select brands',
  label: 'Brands',
  query_key: 'brand_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const unitsFilter = {
  key: 'units',
  placeholder: 'Select net quantity unit',
  label: 'Net Quantity Unit',
  query_key: 'net_quantity_unit_eq',
  input_type: 'dropdown',
  valueKey: 'symbol',
  labelKey: 'name',
  multi_select: false,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-6',
  removeSelected: false,
  closeOnSelect: false,
  // renderOptionWithCheckbox: true,
};

export const customerFilter = {
  key: 'customers',
  placeholder: 'Search customers',
  label: 'Customers',
  query_key: 'customer_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: false,
  call_api: true,
  type: 'array',
  className: 'col-md-12',
};

export const productFilter = {
  key: 'products',
  placeholder: 'Search products',
  label: 'Products',
  query_key: 'product_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: false,
  call_api: true,
  type: 'array',
  className: 'col-md-12',
};

export const beatRouteFilter = {
  key: 'beat_routes',
  placeholder: 'Select beat routes',
  label: 'Beat routes',
  query_key: 'customer_beat_routes_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const branchFilter = {
  key: 'branches',
  placeholder: 'Select branches',
  label: 'Branches',
  query_key: 'branch_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const productCategoryFilter = {
  key: 'product_categories',
  placeholder: 'Select product categories',
  label: 'Product categories',
  query_key: 'product_category_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const warehouseFilter = {
  key: 'warehouses',
  placeholder: 'Select warehouses',
  label: 'Warehouses',
  query_key: 'warehouse_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const textFieldFilter = {
  key: '',
  placeholder: 'contains',
  label: '',
  query_key: '',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const billingAddressLine1Filter = {
  key: 'billing_address_line_1',
  placeholder: 'Line 1 contains',
  label: 'Address',
  query_key: 'billing_address_line_1_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const customerPONumberFilter = {
  key: 'customer_po_number',
  placeholder: 'PO Number',
  label: 'PO Number',
  query_key: 'customer_po_number_eq',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const amountgtFilter = {
  key: 'amount_gt',
  placeholder: 'Amount greater than',
  label: 'Amount greater than',
  query_key: 'amount_gt',
  input_type: 'number',
  type: 'string',
  className: 'col-md-6',
};

export const amounteqFilter = {
  key: 'amount_eq',
  placeholder: 'Amount equals',
  label: 'Amount equals',
  query_key: 'amount_eq',
  input_type: 'number',
  type: 'string',
  className: 'col-md-6',
};

export const amountltFilter = {
  key: 'amount_lt',
  placeholder: 'Amount less than',
  label: 'Amount less than',
  query_key: 'amount_lt',
  input_type: 'number',
  type: 'string',
  className: 'col-md-6',
};

export const instrumentNoFilter = {
  key: 'instrument_no_cont',
  placeholder: 'Instrument no. contains',
  label: 'Instrument no.',
  query_key: 'instrument_no_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

// export const allocationFilter = {
//   key: 'allocations',
//   placeholder: 'Select allocation',
//   label: 'Allocation',
//   query_key: 'allocation_eq',
//   input_type: 'dropdown',
//   valueKey: undefined,
//   labelKey: undefined,
//   multi_select: false,
//   should_prefetch: false,
//   call_api: false,
//   options: ALLOCATIONS,
//   type: 'array',
//   className: 'col-md-6',
// };

export const unallocatedFilter = {
  key: 'unallocated',
  placeholder: 'Select Allocation',
  label: 'Allocation',
  query_key: 'unallocated',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  should_prefetch: false,
  call_api: false,
  options: IS_UNALLOCATED_OPTIONS,
  type: 'array',
  className: 'col-md-6',
};

export const unallocatedamountgtFilter = {
  key: 'unallocated_amount_gt',
  placeholder: 'greater than',
  label: 'Unallocated amount greater than',
  query_key: 'unallocated_amount_gt',
  input_type: 'number',
  type: 'string',
  className: 'col-md-6',
};

export const paymentModeFilter = {
  key: 'payment_modes',
  placeholder: 'Select payment modes',
  label: 'Payment modes',
  query_key: 'payment_mode_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const creditNoteStatusFilter = {
  key: 'credit_notes',
  placeholder: 'Select CN status',
  label: 'CN status',
  query_key: 'credit_note_status',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: CREDIT_NOTE_STATUS,
  type: 'array',
  className: 'col-md-6',
};

export const creditableTypeFilter = {
  key: 'creditable_types',
  placeholder: 'Select type',
  label: 'Type',
  query_key: 'creditable_type_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: CREDITABLE_TYPES,
  type: 'array',
  className: 'col-md-6',
};

export const creditReasonFilter = {
  key: 'credit_reasons',
  placeholder: 'Select reason',
  label: 'Credit reasons',
  query_key: 'credit_reason_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const debitReasonFilter = {
  key: 'debit_reasons',
  placeholder: 'Select reason',
  label: 'Debit reasons',
  query_key: 'debit_note_items_debit_reason_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
  removeSelected: false,
  closeOnSelect: false,
  renderOptionWithCheckbox: true,
};

export const partyTypeFilter = {
  key: 'party_type',
  placeholder: 'Select party type',
  label: 'Party Type',
  query_key: 'party_type_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: PARTY_TYPES,
  type: 'array',
  className: 'col-md-6',
};

export const activeFilter = {
  key: 'active',
  placeholder: 'Active',
  label: 'Active',
  query_key: 'active_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: ACTIVE_STATUS,
  type: 'array',
  className: 'col-md-6',
};

export const productWithoutTaxes = {
  key: 'tax_category_id_null',
  placeholder: 'Filter by taxes',
  label: 'Tax status',
  query_key: 'tax_category_id_null',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: PRODUCT_WITHOUT_TAXES_OPTIONS,
  type: 'array',
  className: 'col-md-6',
};

export const inventoryTrackingLevel = {
  key: 'inventory_identification_level_eq',
  placeholder: 'Inventory tracking level',
  label: 'Inventory Tracking Level',
  query_key: 'inventory_identification_level_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: INVENTORY_TRACKING_LEVEL,
  type: 'array',
  className: 'col-md-6',
};

export const productWithoutHSNCode = {
  key: 'hsn_code_blank',
  placeholder: 'HSN/SAC code',
  label: 'HSN/SAC code',
  query_key: 'hsn_code_blank',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: PRODUCT_WITHOUT_HSN_CODE_OPTIONS,
  type: 'array',
  className: 'col-md-6',
};

export const productsTypes = {
  key: 'parent_id_blank',
  placeholder: 'Select product type',
  label: 'Product types',
  query_key: 'parent_id_blank',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: PRODUCT_TYPES,
  type: 'array',
  className: 'col-md-6',
};

export const gstinFilter = {
  key: 'gstin_cont',
  placeholder: `${gstinDetails?.label || 'GSTIN'} contains`,
  label: `${gstinDetails?.label || 'GSTIN'}`,
  query_key: 'gstin_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const addressCityFilter = {
  key: 'addresses_city_cont',
  placeholder: 'City contains',
  label: 'City',
  query_key: 'addresses_city_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const refNoContFilter = {
  key: 'ref_no_cont',
  placeholder: 'Reference no. contains',
  label: 'Reference no.',
  query_key: 'ref_no_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const stateCodesFilter = {
  key: 'state_codes',
  placeholder: 'Select states',
  label: 'States',
  query_key: 'addresses_state_in',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const customerCategoryFilter = {
  key: 'customer_categories',
  placeholder: 'Select customer categories',
  label: 'Customer categories',
  query_key: 'customer_category_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const taxCategoryFilter = {
  key: 'tax_categories',
  placeholder: 'Select tax categories',
  label: 'Tax categories',
  query_key: 'for_tax_category_ids',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const customerRatingFilter = {
  key: 'customer_ratings',
  placeholder: 'Select customer ratings',
  label: 'Customer ratings',
  query_key: 'customer_rating_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const supplierFilter = {
  key: 'suppliers',
  placeholder: 'Select suppliers',
  label: 'Suppliers',
  query_key: 'supplier_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-12',
};

export const stockLogableTypeFilter = {
  key: 'stock_logable_type',
  placeholder: 'Select type',
  label: 'Type',
  query_key: 'stock_logable_type_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: STOCK_LOGABLE_TYPE,
  type: 'array',
  className: 'col-md-6',
};

export const selectFinancialYearFilter = {
  key: 'ref_no_cont',
  placeholder: 'Select financial year',
  label: 'Financial year',
  query_key: 'ref_no_cont',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: getFinancialYears(),
  type: 'array',
  className: 'col-md-6',
};

export const accountingAccountFilter = {
  key: 'chart_of_accounts',
  placeholder: 'Search accounts',
  label: 'Accounts',
  query_key: 'credit_accounts_id_or_debit_accounts_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: false,
  call_api: true,
  type: 'array',
  className: 'col-md-12',
  hasInfLoading: true,
  moduleName: 'chart_of_accounts',
};

export const accountingEntryTypeFilter = {
  key: 'type_in',
  placeholder: 'Select entry type',
  label: 'Entry type',
  query_key: 'type_in',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: true,
  should_prefetch: false,
  call_api: false,
  options: ACCOUNT_ENTRY_TYPE,
  type: 'array',
  className: 'col-md-12',
};

export const voucherTypeFilter = {
  key: 'commercial_document_type_in',
  placeholder: 'Select voucher type',
  label: 'Voucher type',
  query_key: 'commercial_document_type_in',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: true,
  should_prefetch: false,
  call_api: false,
  options: VOUCHER_TYPE,
  type: 'array',
  className: 'col-md-12',
};

export const voucherBookFilter = {
  key: 'voucher_books',
  placeholder: 'Select voucher books',
  label: 'Voucher books',
  query_key: 'voucher_book_id_in',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: true,
  should_prefetch: true,
  call_api: false,
  type: 'array',
  className: 'col-md-6',
};

export const offerTypesFilter = {
  key: 'type_eq',
  placeholder: 'Select offer type',
  label: 'Offer type',
  query_key: 'type_eq',
  input_type: 'dropdown',
  valueKey: 'type',
  labelKey: 'labelForIndex',
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: OFFER_TYPES,
  type: 'array',
  className: 'col-md-4',
};

export const offerBenefitsImpactType = {
  key: 'offer_benefits_impact_type_eq',
  placeholder: 'Select impact type',
  label: 'Impact type',
  query_key: 'offer_benefits_impact_type_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: IMPACT_TYPE_OPTIONS_FOR_ITEM_OFFERS,
  type: 'array',
  className: 'col-md-4',
};

export const offerBenefitsImpactOn = {
  key: 'offer_benefits_impact_on_eq',
  placeholder: 'Select impact on',
  label: 'Impact on',
  query_key: 'offer_benefits_impact_on_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: IMPACT_ON_OPTIONS,
  type: 'array',
  className: 'col-md-4',
};

export const refVoucherTypeFilter = {
  key: 'ref_voucher_type_eq',
  placeholder: 'Select ref voucher type',
  label: 'Ref voucher type',
  query_key: 'ref_voucher_type_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: REF_VOUCHER_TYPES,
  type: 'array',
  className: 'col-md-6',
};

export const ifscCodeFilter = {
  key: 'ifsc_code_cont',
  placeholder: 'IFSC code contains',
  label: 'IFSC Code',
  query_key: 'ifsc_code_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const swiftCodeFilter = {
  key: 'swift_code_cont',
  placeholder: 'Swift code contains',
  label: 'Swift Code',
  query_key: 'swift_code_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const bankBranchNameFilter = {
  key: 'branch_cont',
  placeholder: 'Branch contains',
  label: 'Branch',
  query_key: 'branch_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const customerPhoneOrNameFilter = {
  key: 'phone_or_name_or_addresses_mobile_or_contacts_mobile_cont',
  placeholder: 'Contains',
  label: 'Name or Phone',
  query_key: 'phone_or_name_or_addresses_mobile_or_contacts_mobile_cont',
  input_type: 'text',
  type: 'string',
  className: 'col-md-6',
};

export const timePeriodFilter = {
  key: 'time_period',
  placeholder: 'Time period',
  label: 'Time period',
  input_type: 'period',
  from_date_key: 'date_gteq',
  to_date_key: 'date_lteq',
  className: 'col-md-4',
};

export const validFromFilter = {
  key: 'valid_from',
  placeholder: 'Valid from',
  label: 'Valid From',
  query_key: 'valid_from_gteq',
  input_type: 'date',
  min_date: new Date('2001-1-1'),
  max_date: getYearFromNow(10),
  type: DATE,
  show_time_select: false,
  className: 'col-md-4',
};

export const validTillFilter = {
  key: 'valid_till',
  placeholder: 'Valid till',
  label: 'Valid Till',
  query_key: 'valid_till_lteq',
  input_type: 'date',
  min_date: new Date('2001-1-1'),
  max_date: getYearFromNow(10),
  type: DATE,
  show_time_select: false,
  className: 'col-md-4',
};

export const disabledFilter = {
  key: 'disabled',
  placeholder: 'Disabled',
  label: 'Is Disabled?',
  query_key: 'disabled_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: DISABLED_OPTIONS,
  type: 'array',
  className: 'col-md-4',
};

export const gstinPresent = {
  key: 'gstin_present',
  placeholder: 'Customer GST status',
  label: 'GST Status',
  query_key: 'gstin_present',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: CUSTOMER_GSTIN_FILTER_VALUES,
  type: 'array',
  className: 'col-md-6',
};

export const conditionOnFilter = {
  key: 'condition_on',
  placeholder: 'Condition on',
  label: 'Condition On',
  query_key: 'condition_on_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: CONDITION_ON_OPTIONS,
  type: 'array',
  className: 'col-md-4',
};

export const conditionOperatorFilter = {
  key: 'condition_operator_eq',
  placeholder: 'Condition operator',
  label: 'Condition Operator',
  query_key: 'condition_operator_eq',
  input_type: 'dropdown',
  valueKey: undefined,
  labelKey: undefined,
  multi_select: false,
  should_prefetch: false,
  call_api: false,
  options: CONDITION_OPERATOR_OPTIONS,
  type: 'array',
  className: 'col-md-4',
};

export const accountNameContFilter = {
  key: 'chart_of_accounts',
  placeholder: 'Account Name',
  label: 'Account Name',
  query_key: 'account_id_eq',
  input_type: 'dropdown',
  valueKey: 'id',
  labelKey: 'name',
  multi_select: false,
  type: 'array',
  should_prefetch: false,
  call_api: true,
  className: 'col-md-12',
};

export const unpaidAmountGTFilter = {
  key: 'unpaid_amount_gt',
  placeholder: 'greater than',
  label: 'Unpaid amount greater than',
  query_key: 'unpaid_amount_gt',
  input_type: 'number',
  type: 'string',
  className: 'col-md-6',
};

export const ordersAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  // [{ ...timePeriodFilter, className: 'col-md-4' }, {
  //   ...fromDateFilter, query_key: 'date_gteq', format: fallBackDateFormat, overrideDateFormat: true, className: 'col-md-4', apiDatetimeFormat: apiDefaultDateTimeFormat,
  // }, {
  //   ...toDateFilter, query_key: 'date_lteq', format: fallBackDateFormat, overrideDateFormat: true, className: 'col-md-4', apiDatetimeFormat: apiDefaultDateTimeFormat,
  // }],
  [billingAddressLine1Filter, customerPONumberFilter],
  // [billingAddressLine1Filter, partiallyInvoiceFilter],
  [{ ...statusFilter, className: 'col-md-6' }, { ...userFilter, className: 'col-md-6' }],
  [{ ...customerFilter, className: 'col-md-6' }, { ...beatRouteFilter, className: 'col-md-6' }],
  [{ ...productFilter, query_key: 'variants_id_in', className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'variants_product_category_id_in', className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...brandFilter, query_key: 'variants_brand_id_in', className: 'col-md-6' }],
  [voucherBookFilter],
];

export const invoicesAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...billingAddressLine1Filter, query_key: 'billing_address_line_1_cont', key: 'billing_address_line_1_cont' }, unpaidAmountGTFilter],
  [{ ...customerPONumberFilter, className: 'col-md-6' }, { ...statusFilter, options: INVOICE_STATES, className: 'col-md-6' }],
  [{ ...gstinFilter, key: 'customer_gstin_cont', query_key: 'customer_gstin_cont' }, { ...gstinPresent, key: 'customer_gstin_present', query_key: 'customer_gstin_present' }],
  [eInvoiceStatusFilter, eWayBillStatusFilter],
  [{ ...customerFilter, className: 'col-md-6' }, { ...stateCodesFilter, query_key: 'billing_address_state_in', className: 'col-md-6' }],
  [{ ...beatRouteFilter, className: 'col-md-6' }, {
    ...userFilter, query_key: 'orders_user_id_in', placeholder: 'Select salesmen', label: 'Salesmen', className: 'col-md-6',
  }],
  [{ ...productFilter, query_key: 'variants_id_in', className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'variants_product_category_id_in', className: 'col-md-6' }],
  [{ ...brandFilter, query_key: 'variants_brand_id_in', className: 'col-md-6' }, { ...userFilter, className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
  [voucherBookFilter],
];

export const paymentsAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...statusFilter, options: PAYMENT_STATES, className: 'col-md-4' }, { ...unallocatedFilter, className: 'col-md-4' }],
  [{ ...amountgtFilter, className: 'col-md-4' }, { ...amounteqFilter, className: 'col-md-4' }, { ...amountltFilter, className: 'col-md-4' }],
  [unallocatedamountgtFilter],
  [{ ...paymentModeFilter, className: 'col-md-6' }, { ...instrumentNoFilter, className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, {
    ...userFilter, placeholder: 'Select salesmen', label: 'Salesmen', className: 'col-md-6',
  }],
  [
    { ...partyTypeFilter, query_key: 'customer_type_eq', clearDependantFieldsValue: { customer_id_in: [] } },
    {
      ...customerFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'customer_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-6',
      partyTypeKey: 'customer_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    }],
  [{
    ...billingAddressLine1Filter, query_key: 'customer_default_address_line_1_cont', key: 'customer_default_address_line_1_cont', className: 'col-md-6',
  }, { ...brandFilter, query_key: 'invoice_payments_invoice_variants_brand_id_in', className: 'col-md-6' },
  ],
  [{ ...beatRouteFilter, className: 'col-md-6' }, voucherBookFilter],
];

export const returnsAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [creditNoteStatusFilter, { ...statusFilter, options: PURCHASE_INVOICE_STATES, className: 'col-md-6' }],
  [customerFilter],
  [{ ...productFilter, query_key: 'variants_id_in', className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'variants_product_category_id_in', className: 'col-md-6' }],
  [{ ...brandFilter, query_key: 'variants_brand_id_in', className: 'col-md-6' }, { ...userFilter, className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
  [voucherBookFilter],
];

export const creditNotesAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [amountgtFilter, amountltFilter],
  [{ ...gstinFilter, key: 'party_gstin_cont', query_key: 'party_gstin_cont' }, { ...gstinPresent, key: 'party_gstin_present', query_key: 'party_gstin_present' }],
  [eInvoiceStatusFilter, { ...userFilter, className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
  [{ ...statusFilter, options: CREDIT_NOTE_STATES, className: 'col-md-6' }, { ...partyTypeFilter, query_key: 'party_type_eq', clearDependantFieldsValue: { party_id_in: [] } }],
  [{
    ...customerFilter,
    key: 'parties',
    placeholder: 'Select Parties',
    label: 'Parties',
    query_key: 'party_id_in',
    //   should_prefetch: true,
    //   call_api: false,
    className: 'col-md-12',
    partyTypeKey: 'party_type_eq',
    fieldDependantConditions: {
      default: { key: 'parties', should_prefetch: false, call_api: false },
      [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
      [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
    },
  }],
  [voucherBookFilter],
];

export const customersAdvanceFilterSchema = [
  [customerPhoneOrNameFilter, activeFilter],
  [gstinFilter, gstinPresent],
  [{
    ...billingAddressLine1Filter, query_key: 'addresses_line_1_or_addresses_line_2_cont', placeholder: 'Line 1 or line 2 contains', key: 'addresses_line_1_or_addresses_line_2_cont',
  }, addressCityFilter],
  [stateCodesFilter],
  [{ ...branchFilter, query_key: 'branches_id_in' }],
  [{ ...customerCategoryFilter, query_key: 'for_customer_category_ids' }],
  [{ ...customerRatingFilter, query_key: 'for_customer_rating_ids' }],
  [{ ...beatRouteFilter, query_key: 'beat_routes_id_in' }],
];

export const offerCustomersAdvanceFilterSchema = [
  [{ ...customerFilter, query_key: 'id_in', className: 'col-md-8' }, { ...activeFilter, className: 'col-md-4' }],
  [gstinFilter, gstinPresent],
  [{
    ...billingAddressLine1Filter, query_key: 'addresses_line_1_or_addresses_line_2_cont', placeholder: 'Line 1 or line 2 contains', key: 'addresses_line_1_or_addresses_line_2_cont',
  }, addressCityFilter],
  [stateCodesFilter],
  [{ ...branchFilter, query_key: 'branches_id_in' }],
  [customerCategoryFilter],
  [customerRatingFilter],
];

export const stocksAdvanceFilterSchema = [
  [{ ...productFilter, className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'product_product_category_id_in', className: 'col-md-6' }],
  [{ ...brandFilter, query_key: 'product_brand_id_in', className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
];

export const stockEntriesAdvanceFilterSchema = [
  [stockLogableTypeFilter, selectFinancialYearFilter],
  [{ ...productFilter, query_key: 'stock_product_id_in', className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'stock_product_product_category_id_in', className: 'col-md-6' }],
  [{ ...brandFilter, query_key: 'stock_product_brand_id_in', className: 'col-md-6' }, { ...warehouseFilter, query_key: 'stock_warehouse_id_in', className: 'col-md-6' }],
];

export const productsAdvanceFilterSchema = [
  [activeFilter, productWithoutTaxes],
  [productWithoutHSNCode, productsTypes],
  [inventoryTrackingLevel, { ...brandFilter, query_key: 'for_brand_ids', className: 'col-md-6' }],
  [
    { ...productCategoryFilter, query_key: 'for_product_category_ids', className: 'col-md-6' },
    { ...taxCategoryFilter, className: 'col-md-6' },
  ],
];

export const offerProductsAdvanceFilterSchema = [
  [activeFilter, productWithoutTaxes],
  [productWithoutHSNCode, unitsFilter],
  [{ ...productFilter, query_key: 'id_in' }],
  [brandFilter],
  [productCategoryFilter],
  [taxCategoryFilter],
];

export const purchaseInvoicesAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [refNoContFilter, { ...statusFilter, options: PURCHASE_INVOICE_STATES, className: 'col-md-6' }],
  [
    { ...partyTypeFilter, query_key: 'supplier_type_eq', clearDependantFieldsValue: { supplier_id_in: [] } },
    {
      ...supplierFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'supplier_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-6',
      partyTypeKey: 'supplier_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    }],
  [{ ...productFilter, query_key: 'variants_id_in', className: 'col-md-6' }, { ...productCategoryFilter, query_key: 'variants_product_category_id_in', className: 'col-md-6' }],
  [{ ...brandFilter, query_key: 'variants_brand_id_in', className: 'col-md-6' }, { ...userFilter, className: 'col-md-6' }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
  [voucherBookFilter],
];

export const purchaseOrdersAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...statusFilter, options: PURCHASE_ORDER_STATES, className: 'col-md-12' }],
  [
    { ...partyTypeFilter, query_key: 'supplier_type_eq', clearDependantFieldsValue: { supplier_id_in: [] } },
    {
      ...supplierFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'supplier_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-6',
      partyTypeKey: 'supplier_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    }],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
  [voucherBookFilter],
];

export const purchaseReturnsAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [supplierFilter],
  [{ ...statusFilter, options: PURCHASE_INVOICE_STATES, className: 'col-md-6' }, {
    ...statusFilter,
    options: DEBIT_NOTE_STATUS,
    label: 'DN Status',
    query_key: 'debit_note_status',
    multi_select: false,
    placeholder: 'Select debit note status',
    className: 'col-md-6',
  }],
  [{ ...userFilter, className: 'col-md-6' }, voucherBookFilter],
  [{ ...branchFilter, className: 'col-md-6' }, { ...warehouseFilter, className: 'col-md-6' }],
];

export const accountingEntriesAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...voucherTypeFilter, className: 'col-md-6' }, { ...accountingEntryTypeFilter, className: 'col-md-6' }],
  [accountingAccountFilter],
];

export const bankAccountsAdvanceFilterSchema = [
  [ifscCodeFilter, swiftCodeFilter],
  [bankBranchNameFilter, bankFilter],
];

export const offersAdvanceFilterSchema = [
  [timePeriodFilter, validFromFilter, validTillFilter],
  [disabledFilter, conditionOnFilter, conditionOperatorFilter],
  [offerTypesFilter, offerBenefitsImpactType, offerBenefitsImpactOn],
  [{ ...productFilter, query_key: 'applicable_on_product_ids' }],
  [{ ...customerFilter, query_key: 'eligible_for_customer_ids' }],
];

export const downloadAdvanceFilterSchema = [
  [{ ...timePeriodFilter, className: 'col-md-6' }],
  [{ ...fromDateFilter, query_key: 'created_at_gteq', className: 'col-md-6' }, { ...toDateFilter, query_key: 'created_at_lteq', className: 'col-md-6' }],
  // [{ ...timePeriodFilter, className: 'col-md-6' }],
  // [{
  //   ...fromDateFilter, query_key: 'created_at_gteq', show_time_select: true, format: fallBackDatetimeFormat, overrideDateFormat: true, className: 'col-md-6', apiDatetimeFormat: apiDefaultDateTimeFormat,
  // }, {
  //   ...toDateFilter, query_key: 'created_at_lteq', show_time_select: true, format: fallBackDatetimeFormat, overrideDateFormat: true, className: 'col-md-6', apiDatetimeFormat: apiDefaultDateTimeFormat,
  // }],
];

export const stockTransfersAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [
    {
      ...branchFilter, label: 'Source Branches', placeholder: 'Select source branches', query_key: 'source_branch_id_in', className: 'col-md-6',
    },
    {
      ...branchFilter, label: 'Dest. Branches', placeholder: 'Select dest. branches', query_key: 'destination_branch_id_in', className: 'col-md-6',
    },
  ],
  [
    {
      ...warehouseFilter, label: 'Source Warehouses', placeholder: 'Select source warehouses', query_key: 'source_warehouse_id_in', className: 'col-md-6',
    },
    {
      ...warehouseFilter, label: 'Dest. Warehouses', placeholder: 'Select dest. warehouses', query_key: 'destination_warehouse_id_in', className: 'col-md-6',
    },
  ],
  [{ ...productFilter, query_key: 'stock_transfer_items_variant_id_in' }],
  [voucherBookFilter],
];

export const reconEntriesAdvanceFilterSchema = [
  [timePeriodFilter, { ...fromDateFilter, query_key: 'entry_date_gteq' }, { ...toDateFilter, query_key: 'entry_date_lteq' }],
  [{ ...amountgtFilter, query_key: 'amount_lteq' }, { ...amountltFilter, query_key: 'amount_gteq' }],
  [{ ...voucherTypeFilter, query_key: 'entry_commercial_document_type_in' }],
  [{ ...customerFilter, query_key: 'counterparty_id_in' }],
];

// SETUP-SECTION advanced search
export const reconciliationsAdvanceFilterSchema = [
  [
    timePeriodFilter,
    {
      ...fromDateFilter,
      query_key: 'start_date_gteq',
      label: 'Recon Date From',
      placeholder: 'Recon Date From',
    },
    {
      ...toDateFilter,
      query_key: 'end_date_lteq',
      label: 'Recon Date To',
      placeholder: 'Recon Date To',
    },
  ],
  // [accountNameContFilter],
  [{ ...statusFilter, options: RECONCILIATION_STATES, className: 'col-md-12' }],
];
// !SETUP-SECTION advanced search

const debitNotesAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...statusFilter, options: DEBIT_NOTE_STATES, className: 'col-md-6' }, { ...branchFilter, className: 'col-md-6' }],
  [{ ...warehouseFilter, className: 'col-md-6' }, { ...debitReasonFilter, className: 'col-md-6' }],
  [{ ...gstinFilter, key: 'party_gstin_cont', query_key: 'party_gstin_cont' }, { ...gstinPresent, key: 'party_gstin_present', query_key: 'party_gstin_present' }],
  [eInvoiceStatusFilter, refVoucherTypeFilter],
  [{
    ...partyTypeFilter, query_key: 'party_type_eq', className: 'col-md-4', clearDependantFieldsValue: { party_id_in: [] },
  }],
  [{
    ...customerFilter,
    key: 'parties',
    placeholder: 'Select Parties',
    label: 'Parties',
    query_key: 'party_id_in',
    //   should_prefetch: true,
    //   call_api: false,
    className: 'col-md-8',
    partyTypeKey: 'party_type_eq',
    fieldDependantConditions: {
      default: { key: 'parties', should_prefetch: false, call_api: false },
      [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
      [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
    },
  }],
  [voucherBookFilter],
];

export const paymentOutsAdvanceFilterSchema = [
  [timePeriodFilter, fromDateFilter, toDateFilter],
  [{ ...amountgtFilter, className: 'col-md-4' }, { ...amounteqFilter, className: 'col-md-4' }, { ...amountltFilter, className: 'col-md-4' }],
  [unallocatedamountgtFilter, { ...statusFilter, options: PAYMENT_OUT_STATES, className: 'col-md-6' }],
  [
    { ...partyTypeFilter, query_key: 'supplier_type_eq', clearDependantFieldsValue: { supplier_id_in: [] } },
    {
      ...supplierFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'supplier_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-6',
      partyTypeKey: 'supplier_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    },
  ],
  [{
    ...billingAddressLine1Filter, query_key: 'supplier_address_line_1_cont', key: 'supplier_address_line_1_cont', className: 'col-md-6',
  }, instrumentNoFilter],
  [{ ...paymentModeFilter, className: 'col-md-6' }, { ...branchFilter, className: 'col-md-6' }],
  [{ ...userFilter, className: 'col-md-6' }, { ...brandFilter, query_key: 'purchase_invoice_payments_purchase_invoice_variants_brand_id_in', className: 'col-md-6' }],
  [voucherBookFilter],
];

export const voucherBooksAdvanceFilterSchema = [
  [timePeriodFilter, validFromFilter, { ...validTillFilter, query_key: 'valid_from_lteq' }],
  [branchFilter],
  // [{ ...brandFilter, query_key: 'brand_ids_in' }],
];

export const inventoryBatchesAdvanceFilterSchema = [
  [
    {
      ...fromDateFilter,
      query_key: 'inventory_manufacturer_batch_mfd_date_gteq',
      label: 'Mfd From Date',
      placeholder: 'Inventory Manufacturer Batch Mfd From Date',
      className: 'col-md-6',
    },
    {
      ...toDateFilter,
      query_key: 'inventory_manufacturer_batch_mfd_date_lteq',
      label: 'Mfd Till Date',
      placeholder: 'Inventory Manufacturer Batch Mfd To Date',
      className: 'col-md-6',
    },
  ],
  [
    {
      ...fromDateFilter,
      query_key: 'inventory_manufacturer_batch_expiry_date_gteq',
      label: 'Expiry From Date',
      placeholder: 'Inventory Manufacturer Batch Expiry From Date',
      className: 'col-md-6',
    },
    {
      ...toDateFilter,
      query_key: 'inventory_manufacturer_batch_expiry_date_lteq',
      label: 'Expiry Till Date',
      placeholder: 'Inventory Manufacturer Batch Expiry To Date',
      className: 'col-md-6',
    },
  ],
];

export const inventoryPiecesAdvanceFilterSchema = [
  [
    {
      ...fromDateFilter,
      query_key: 'inventory_manufacturer_piece_mfd_date_gteq',
      label: 'Mfd From Date',
      placeholder: 'Inventory Manufacturer Piece Mfd From Date',
      className: 'col-md-6',
    },
    {
      ...toDateFilter,
      query_key: 'inventory_manufacturer_piece_mfd_date_lteq',
      label: 'Mfd Till Date',
      placeholder: 'Inventory Manufacturer Piece Mfd To Date',
      className: 'col-md-6',
    },
  ],
  [
    {
      ...fromDateFilter,
      query_key: 'inventory_manufacturer_piece_expiry_date_gteq',
      label: 'Expiry From Date',
      placeholder: 'Inventory Manufacturer Piece Expiry From Date',
      className: 'col-md-6',
    },
    {
      ...toDateFilter,
      query_key: 'inventory_manufacturer_piece_expiry_date_lteq',
      label: 'Expiry Till Date',
      placeholder: 'Inventory Manufacturer Piece Expiry To Date',
      className: 'col-md-6',
    },
  ],
];

export const getAdvanceSearchFiltersSchema = (moduleName) => {
  switch (moduleName) {
    case orders:
      return ordersAdvanceFilterSchema;
    case invoices:
      return invoicesAdvanceFilterSchema;
    case payments:
      return paymentsAdvanceFilterSchema;
    case returns:
      return returnsAdvanceFilterSchema;
    case creditNotes:
      return creditNotesAdvanceFilterSchema;
    case debitNotes:
      return debitNotesAdvanceFilterSchema;
    case customers:
      return customersAdvanceFilterSchema;
    case CUSTOMERS_FILTER:
      return offerCustomersAdvanceFilterSchema;
    case stocks:
      return stocksAdvanceFilterSchema;
    case stockEntries:
      return stockEntriesAdvanceFilterSchema;
    case products:
      return productsAdvanceFilterSchema;
    case purchaseInvoices:
      return purchaseInvoicesAdvanceFilterSchema;
    case purchaseOrders:
      return purchaseOrdersAdvanceFilterSchema;
    case purchaseReturns:
      return purchaseReturnsAdvanceFilterSchema;
    case accountingEntries:
      return accountingEntriesAdvanceFilterSchema;
    case reconEntries:
      return reconEntriesAdvanceFilterSchema;
    case bankAccounts:
      return bankAccountsAdvanceFilterSchema;
    case offers:
      return offersAdvanceFilterSchema;
    case PRODUCTS_FILTER:
    case CONDITION_PRODUCTS_FILTER:
      return offerProductsAdvanceFilterSchema;
    // SETUP-SECTION advanced search
    case reconciliations:
      return reconciliationsAdvanceFilterSchema;
    // !SETUP-SECTION advanced search
    case downloads:
      return downloadAdvanceFilterSchema;
    case stockTransfers:
      return stockTransfersAdvanceFilterSchema;
    case paymentOuts:
      return paymentOutsAdvanceFilterSchema;
    case voucherBooks:
      return voucherBooksAdvanceFilterSchema;
    case inventoryBatches:
      return inventoryBatchesAdvanceFilterSchema;
    case inventoryPieces:
      return inventoryPiecesAdvanceFilterSchema;
    default:
      return [];
  }
};
