// This constants used in invoice action and reducer.
export const CREDIT_NOTE_INVOICE = 'CREDIT_NOTE_INVOICE';
export const SET_CREDIT_NOTE_INVOICES = 'SET_CREDIT_NOTE_INVOICES';
export const ADD_CREDIT_NOTE_INVOICE = 'ADD_CREDIT_NOTE_INVOICE';
export const CREDIT_NOTE_INVOICE_FETCHED = 'CREDIT_NOTE_INVOICE_FETCHED';
export const CREDIT_NOTE_INVOICE_UPDATED = 'CREDIT_NOTE_INVOICE_UPDATED';
export const CREDIT_NOTE_INVOICE_DELETED = 'CREDIT_NOTE_INVOICE_DELETED';
export const CREDIT_NOTE_INVOICE_EDIT = 'CREDIT_NOTE_INVOICE_EDIT';
export const AUTOCOMPLETE_CREDIT_NOTE_INVOICE = 'AUTOCOMPLETE_CREDIT_NOTE_INVOICE';

export const title = 'Credit Note Invoices';
export const singularTitle = 'Credit Note Invoice';
export const moduleName = 'credit_note_invoices';
export const baseUrl = '/credit_note_invoices';
export const redirectUrl = '/credit_note_invoices';
export const listingDataKey = 'credit_note_invoices';
export const itemDataKey = 'credit_note_invoice';

export const creditNoteInvoiceConstants = {
  item: CREDIT_NOTE_INVOICE,
  setItems: SET_CREDIT_NOTE_INVOICES,
  addItem: ADD_CREDIT_NOTE_INVOICE,
  setItem: CREDIT_NOTE_INVOICE_FETCHED,
  updateItem: CREDIT_NOTE_INVOICE_UPDATED,
  deleteItem: CREDIT_NOTE_INVOICE_DELETED,
  autocompleteItems: AUTOCOMPLETE_CREDIT_NOTE_INVOICE,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  credit_note_id_in: [],
};
