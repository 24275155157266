import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_SETTABLE_CATEGORY, FETCH_PRINT_FORMAT_LIST, FETCH_PRINT_FORMAT, FETCH_NEW_PRINT_FORMAT,
  CREATE_PRINT_FORMAT, UPDATE_PRINT_FORMAT, DELETE_PRINT_FORMAT, FETCH_DUPLICATE_PRINT_FORMAT,
  FETCH_PRINT_FORMAT_PREVIEW,
} from '../constants/print_formats';
import {
  setSettableCategory, setPrintFormats, printFormatFetched, addPrintFormat, printFormatUpdated,
  printFormatDeleted, printFormatCreated, duplicatePrintFormat, printFormatPreview,
  resetPrintFormats,
} from '../actions/print_formats';
import {
  fetchPrintFormatApi, createPrintFormatApi, updatePrintFormatApi, deletePrintFormatApi,
  previewPrintFormat, fetchPrintFormatPreviewApi,
} from '../api/print_formats';
import { BASE_URL } from '../constants/common';

function* fetchSettableCategorySagaSideEffect(action: any) {
  const { url, settableCategoryName } = action;

  try {
    const response = yield call(fetchPrintFormatApi({ url }));
    const { settable_category } = response.data;
    yield put(setSettableCategory({ settableCategoryName, payload: settable_category }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchPrintFormatsListSagaSideEffect(action: any) {
  const { url, settableCategoryName } = action;

  try {
    yield put(resetPrintFormats({ settableCategoryName }));
    const response = yield call(fetchPrintFormatApi({ url }));
    const { configurations } = response.data;
    yield put(setPrintFormats({ settableCategoryName, payload: configurations }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchPrintFormatSagaSideEffect(action: any) {
  const { url, settableCategoryName } = action;

  try {
    const response = yield call(fetchPrintFormatApi({ url }));
    const { configuration } = response.data;
    yield put(printFormatFetched({ settableCategoryName, payload: configuration }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchNewPrintFormatSagaSideEffect(action: any) {
  const { url, settableCategoryName } = action;

  try {
    const response = yield call(fetchPrintFormatApi({ url }));
    const { configuration } = response.data;
    yield put(addPrintFormat({ settableCategoryName, payload: configuration }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchDuplicatePrintFormatSagaSideEffect(action: any) {
  const { url, settableCategoryName } = action;

  try {
    const response = yield call(fetchPrintFormatApi({ url }));
    const { configuration } = response.data;
    yield put(duplicatePrintFormat({ settableCategoryName, payload: configuration }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* createPrintFormatSagaSideEffect(action: any) {
  const {
    url, settableCategoryName, data, idx,
  } = action;
  try {
    const response = yield call(createPrintFormatApi({ url, data }));
    const { configuration } = response.data;
    yield put(printFormatCreated({ settableCategoryName, payload: configuration, idx }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* updatePrintFormatSagaSideEffect(action: any) {
  const { url, settableCategoryName, data } = action;
  try {
    const response = yield call(updatePrintFormatApi({ url, data }));
    const { configuration } = response.data;
    yield put(printFormatUpdated({ settableCategoryName, payload: configuration }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* deletePrintFormatSagaSideEffect(action: any) {
  const { url, printFormat, settableCategoryName } = action;
  try {
    let response = printFormat;
    if (printFormat && printFormat.id) {
      response = yield call(deletePrintFormatApi({ url }));
    }
    yield put(printFormatDeleted({ settableCategoryName, idx: printFormat.idx }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* previewPrintFormatSagaSideEffect(action: any) {
  const { url, id, settableCategoryName } = action;
  try {
    const response = yield call(fetchPrintFormatPreviewApi({ url }));
    if (response) {
      const resFileData = yield call(previewPrintFormat({ response }));
      yield put(printFormatPreview({ settableCategoryName, id, payload: { uri: resFileData, headers: response.headers, url: `${BASE_URL}/${url}` } }));
    }
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* printFormatsWatcher() {
  yield takeLatest(FETCH_SETTABLE_CATEGORY, fetchSettableCategorySagaSideEffect);
  yield takeLatest(FETCH_PRINT_FORMAT_LIST, fetchPrintFormatsListSagaSideEffect);
  yield takeLatest(FETCH_PRINT_FORMAT, fetchPrintFormatSagaSideEffect);
  yield takeLatest(FETCH_NEW_PRINT_FORMAT, fetchNewPrintFormatSagaSideEffect);
  yield takeLatest(FETCH_DUPLICATE_PRINT_FORMAT, fetchDuplicatePrintFormatSagaSideEffect);
  yield takeLatest(CREATE_PRINT_FORMAT, createPrintFormatSagaSideEffect);
  yield takeLatest(UPDATE_PRINT_FORMAT, updatePrintFormatSagaSideEffect);
  yield takeLatest(DELETE_PRINT_FORMAT, deletePrintFormatSagaSideEffect);
  yield takeLatest(FETCH_PRINT_FORMAT_PREVIEW, previewPrintFormatSagaSideEffect);
}
