import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_JOURNAL_ENTRIES, ADD_JOURNAL_ENTRY, JOURNAL_ENTRY_FETCHED, JOURNAL_ENTRY_UPDATED,
  JOURNAL_ENTRY_DELETED, otherAdvSearchConfig, defaultSearchParams, moduleName,
  SET_CREDIT_ACCOUNTS_ID_OR_DEBIT_ACCOUNTS_ID_IN_SELECTED_OPTIONS,
} from '@constants/accounting/entries';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';

const {
  curSelectedVariant, curSearchParams, curOtherAdvSearchConfig = {},
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: { ...curSearchParams },
  otherAdvSearchConfig: { ...otherAdvSearchConfig, ...curOtherAdvSearchConfig },
  selected_variant: curSelectedVariant,
};

const setAccountsOptionsInSearchParams = (state, action) => {
  const { options, searchParamKey } = action;
  const updatedState = {
    ...state,
    otherAdvSearchConfig: {
      ...state.otherAdvSearchConfig,
      [`${searchParamKey}_selected_options`]: options,
    },
  };
  return updatedState;
};

export default function journalEntries(state = initialState, action) {
  switch (action.type) {
    case SET_JOURNAL_ENTRIES: return setItems(state, action);
    case ADD_JOURNAL_ENTRY: return addItem(state, action);
    case JOURNAL_ENTRY_FETCHED: return fetchItem(state, action);
    case JOURNAL_ENTRY_UPDATED: return updateItem(state, action);
    case JOURNAL_ENTRY_DELETED: return deleteItem(state, action);
    case SET_CREDIT_ACCOUNTS_ID_OR_DEBIT_ACCOUNTS_ID_IN_SELECTED_OPTIONS:
      return setAccountsOptionsInSearchParams(state, action);
    default:
      return state;
  }
}
