import map from 'lodash/map';
import { HOME_BREADCRUMB_LISTING } from './common';

export const PAYMENT = 'PAYMENT';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const PAYMENT_FETCHED = 'PAYMENT_FETCHED';
export const PAYMENT_UPDATED = 'PAYMENT_UPDATED';
export const PAYMENT_DELETED = 'PAYMENT_DELETED';
export const PAYMENT_EDIT = 'PAYMENT_EDIT';
export const UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED = 'UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED';
export const BULK_PAYMENT_STATE_TO_ACCOUNTED = 'BULK_PAYMENT_STATE_TO_ACCOUNTED';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';

export const title = 'Payments';
export const singularTitle = 'Payment';
export const moduleName = 'payments';
export const baseUrl = '/payments';
export const redirectUrl = '/payments';
export const listingDataKey = 'payments';
export const itemDataKey = 'payment';

export const paymentConstants = {
  item: PAYMENT,
  setItems: SET_PAYMENTS,
  addItem: ADD_PAYMENT,
  setItem: PAYMENT_FETCHED,
  updateItem: PAYMENT_UPDATED,
  deleteItem: PAYMENT_DELETED,
  changeItemStatus: UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// SETUP-SECTION quick add
export const FORM_INFORMATION_TITLE = 'PAYMENT INFORMATION';
export const RESET_PAYMENT_ERRORS = 'RESET_PAYMENT_ERRORS';
// !SETUP-SECTION quick add

export const SHOW_PAYMENT = 'SHOW_PAYMENT';

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  amount_gt: '',
  amount_eq: '',
  amount_lt: '',
  customer_default_address_line_1_cont: '',
  instrument_no_cont: '',
  allocation_eq: '',
  customer_type_eq: '',
  state_in: [],
  payment_mode_id_in: [],
  branch_id_in: [],
  customer_id_in: [],
  user_id_in: [],
  invoice_payments_invoice_variants_brand_id_in: [],
  unallocated_amount_gt: '',
  unallocated: '',
  customer_beat_routes_id_in: [],
};

export const defaultSearchParams = {
  number_or_instrument_no_or_customer_name_or_customer_gstin_or_customer_beat_routes_name_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['users', 'brands', 'branches', 'customers', 'payment_modes', 'beat_routes', 'suppliers', 'voucher_books'];

// Payment States
export const open = 'open';
export const received = 'received';
export const deposited = 'deposited';
export const accounted = 'accounted';
export const failed = 'failed';

export const PAYMENT_STATES = [
  { label: 'Open', value: open },
  { label: 'Received', value: received },
  { label: 'Deposited', value: deposited },
  { label: 'Accounted', value: accounted },
  { label: 'Failed', value: failed },
];

export const PAYMENT_STATES_FOR_REPORTS = map(PAYMENT_STATES, (state) => ({
  name: state.label, id: state.value,
}));

// Payment Modes
export const cash = 'Cash';
export const cheque = 'Cheque';
export const dd = 'DD';
export const neft = 'NEFT/Online Transfer';
export const rtgs = 'RTGS';
export const paytm = 'Paytm';
export const letterOfCredit = 'Letter of Credit';
export const upi = 'UPI';
export const other = 'Other';

export const PAYMENT_MODES = [
  { label: cash, value: cash }, { label: cheque, value: cheque }, { label: dd, value: dd },
  { label: neft, value: neft }, { label: paytm, value: paytm },
  { label: letterOfCredit, value: letterOfCredit }, { label: other, value: other },
];

export const ledger = 'ledger';
export const auto = 'auto';
export const manual = 'manual';

export const ALLOCATIONS = [
  { label: 'Ledger', value: ledger }, { label: 'Auto', value: auto }, { label: 'Manual', value: manual }];

export const IS_UNALLOCATED_OPTIONS = [
  { label: 'Unallocated', value: 'true' },
  { label: 'Allocated', value: 'false' },
  { label: 'All', value: '' },
];

export const ACTIVE_STATUS = [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }];

// Breadcrumb
export const Title = 'Payments';
export const TargetBaseUrl = 'payments';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{
  title: 'New', target: '', active: true,
}]);

export const handledErrorKeys = {
  master: ['date', 'customer', 'customer_id', 'branch', 'branch_id', 'amount', 'payment_mode', 'payment_mode_id', 'instrument_no', 'discount', 'instrument_date', 'realisation_date', 'instrument_bank', 'bank_account_id', 'notes'],
  invoice_payments: ['invoice', 'invoice_id', 'amount', 'discount'],
  debit_note_settlements: ['debit_note', 'debit_note_id', 'amount', 'discount'],
};
