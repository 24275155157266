import { HOME_BREADCRUMB_LISTING } from './common';

export const INVOICE_TERM = 'INVOICE_TERM';
export const SET_INVOICE_TERMS = 'SET_INVOICE_TERMS';
export const ADD_INVOICE_TERM = 'ADD_INVOICE_TERM';
export const INVOICE_TERM_FETCHED = 'INVOICE_TERM_FETCHED';
export const INVOICE_TERM_UPDATED = 'INVOICE_TERM_UPDATED';
export const INVOICE_TERM_DELETED = 'INVOICE_TERM_DELETED';
export const RESET_INVOICE_TERM_ERRORS = 'RESET_INVOICE_TERM_ERRORS';
export const AUTOCOMPLETE_INVOICE_TERMS = 'AUTOCOMPLETE_INVOICE_TERMS';

export const FORM_INFORMATION_TITLE = 'INVOICE TERM INFORMATION';
export const ADD_NEW_INVOICE_TERM = 'Add New Invoice Term';

export const title = 'Invoice Terms';
export const singularTitle = 'Invoice Term';
export const moduleName = 'invoice_terms';
export const baseUrl = '/invoice_terms';
export const redirectUrl = '/invoice_terms';
export const listingDataKey = 'invoice_terms';
export const itemDataKey = 'invoice_term';

export const invoiceTermConstants = {
  item: INVOICE_TERM,
  setItems: SET_INVOICE_TERMS,
  addItem: ADD_INVOICE_TERM,
  setItem: INVOICE_TERM_FETCHED,
  updateItem: INVOICE_TERM_UPDATED,
  deleteItem: INVOICE_TERM_DELETED,
  resetErrors: RESET_INVOICE_TERM_ERRORS,
  autocompleteItems: AUTOCOMPLETE_INVOICE_TERMS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Invoice Terms';
export const TargetBaseUrl = 'invoice_terms';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'credit_days', 'interest_rate', 'grace_days', 'terms'],
};
