import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

const ProductFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsProductFeed" */ '@containers/feeds/ProductFeed'),
  loading: Loading,
});

const ProductCategoryFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsProductCategoryFeed" */ '@containers/feeds/product_categories_feed'),
  loading: Loading,
});

const CustomerFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsCustomerFeed" */ '@containers/feeds/CustomerFeed'),
  loading: Loading,
});

const SupplierFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsSupplierFeed" */ '@containers/feeds/supplier_feed'),
  loading: Loading,
});

const PriceListFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsPriceListFeed" */ '@containers/feeds/price_list_feed'),
  loading: Loading,
});

const OrderFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsOrderFeed" */ '@containers/feeds/OrderFeed'),
  loading: Loading,
});

const InvoiceFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsInvoiceFeed" */ '@containers/feeds/InvoiceFeed'),
  loading: Loading,
});

const PaymentFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsPaymentFeed" */ '@containers/feeds/PaymentFeed'),
  loading: Loading,
});

const PaymentOutFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsPaymentOutFeed" */ '@containers/feeds/PaymentOutFeed'),
  loading: Loading,
});

const PurchaseInvoiceFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsPaymentFeed" */ '@containers/feeds/purchase_invoice_feed'),
  loading: Loading,
});

const CreditNoteFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsCreditNoteFeed" */ '@containers/feeds/credit_note_feed'),
  loading: Loading,
});

const AccountEntryFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsAccountEntryFeed" */ '@containers/feeds/account_entry_feed'),
  loading: Loading,
});

const StockFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsStockFeed" */ '@containers/feeds/StockFeed'),
  loading: Loading,
});

const InventoryBatchFeed = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsInventoryBatchFeed" */ '@containers/feeds/inventory_batch_tracking_feed'),
  loading: Loading,
});

const FeedRoutes = [
  {
    path: '/products_feed/new', name: 'products_feed/new', component: ProductFeed,
  },
  {
    path: '/product_categories_feed', name: 'Product Categories Feed', component: ProductCategoryFeed,
  },
  {
    path: '/inventory_batch_feed', name: 'Inventory Batch Feed', component: InventoryBatchFeed,
  },
  {
    path: '/customers_feed/new', name: 'customers_feed/new', component: CustomerFeed,
  },
  {
    path: '/suppliers_feed/new', name: 'suppliers_feed/new', component: SupplierFeed,
  },
  {
    path: '/orders_feed/new', name: 'orders_feed/new', component: OrderFeed,
  },
  {
    path: '/invoices_feed/new', name: 'invoices_feed/new', component: InvoiceFeed,
  },
  {
    path: '/price_list_feed/new', name: 'New Price List Feed', component: PriceListFeed,
  },
  {
    path: '/payments_feed/new', name: 'payments_feed/new', component: PaymentFeed,
  },
  {
    path: '/payment_outs_feed/new', name: 'payment_outs_feed/new', component: PaymentOutFeed,
  },
  {
    path: '/purchase_invoice_feed/new', name: 'purchase_invoice_feed/new', component: PurchaseInvoiceFeed,
  },
  {
    path: '/credit_notes_feed/new', name: 'credit_notes_feed/new', component: CreditNoteFeed,
  },
  {
    path: '/account_entries_feed/new', name: 'account_entries_feed/new', component: AccountEntryFeed,
  },
  {
    path: '/stocks_feed/new', name: 'stocks_feed/new', component: StockFeed,
  },
];

export default FeedRoutes;
