import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for home page.
const Home = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersHomeIndex" */ '../containers/home/Index'),
  loading: Loading,
});

// We use loadable for about page.
// const About = Loadable({
//   loader: () => import(/* webpackChunkName : "ContainersStaticAbout" */ '../components/static/About'),
//   loading: Loading,
// });

const CommonActionsBaseScreen = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsShow" */ '../containers/common_actions/common_actions_base_screen'),
  loading: Loading,
});

const GeneralRoutes = [
  {
    path: '/', name: 'Home', component: Home,
  },

  // Common actions screen related routes
  // Payment actions
  {
    path: '/payment_bulk_options', name: 'payment_bulk_options', component: CommonActionsBaseScreen,
  },
  // Location tracking
  {
    path: '/location_logs', name: 'location_tracking', component: CommonActionsBaseScreen,
  },
];

export default GeneralRoutes;
