import range from 'lodash/range';
import { BATCH, PIECE } from './units';

export const moduleName = 'inventories';
export const nestedFormName = 'voucher_item_inventory_pieces';
export const batchesNestedFormName = 'voucher_item_inventory_batches';
export const piecesNestedFormName = 'voucher_item_inventory_pieces';

export const FETCH_INVENTORIES = 'FETCH_INVENTORIES';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';
export const AUTO_COMPLETE_INVENTORY = 'AUTO_COMPLETE_INVENTORY';

export const AUTOCOMPLETE_INVENTORY_BATCHES = 'AUTOCOMPLETE_INVENTORY_BATCHES';
export const BULK_CREATE_VOUCHER_ITEM_INVENTORY_BATCHES = 'BULK_CREATE_VOUCHER_ITEM_INVENTORY_BATCHES';

export const OPEN_BATCH_INVENTORY_EDIT_MODAL = 'open_batch_inventory_edit_modal';
export const OPEN_BATCH_INVENTORY_SHOW_MODAL = 'open_batch_inventory_show_modal';

export const FETCH_INVENTORY_BATCH_AVAILABLE_QUANTITY = 'FETCH_INVENTORY_BATCH_AVAILABLE_QUANTITY';

export const INVENTORY_BATCH = 'INVENTORY_BATCH';
export const BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_INVOICE_ITEMS = 'BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_INVOICE_ITEMS';
export const BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_RETURN_ITEMS = 'BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_RETURN_ITEMS';
export const BULK_CREATE_INVENTORY_BATCHES_FOR_RETURN_ITEMS = 'BULK_CREATE_INVENTORY_BATCHES_FOR_RETURN_ITEMS';
export const BULK_CREATE_INVENTORY_BATCHES_FOR_INVOICE_ITEMS = 'BULK_CREATE_INVENTORY_BATCHES_FOR_INVOICE_ITEMS';
export const BULK_CREATE_INVENTORY_BATCHES_FOR_STOCK_TRANSFER_ITEMS = 'BULK_CREATE_INVENTORY_BATCHES_FOR_STOCK_TRANSFER_ITEMS';

export const INVENTORY_TRACKING_LEVEL = [
  { label: PIECE, value: PIECE }, { label: BATCH, value: BATCH },
];

export const handledErrorKeys = {
  master: [],
  voucher_item_inventory_identities: [],
  voucher_item_inventory_batches: ['inventory_batch_id', 'inventory_batch', 'input_quantity', 'quantity', 'input_quantity_unit'],
};

export const DAYS = [
  { value: 1, label: '01' }, { value: 2, label: '02' }, { value: 3, label: '03' },
  { value: 4, label: '04' }, { value: 5, label: '05' }, { value: 6, label: '06' },
  { value: 7, label: '07' }, { value: 8, label: '08' }, { value: 9, label: '09' },
  { value: 10, label: 10 }, { value: 11, label: 11 }, { value: 12, label: 12 },
  { value: 13, label: 13 }, { value: 14, label: 14 }, { value: 15, label: 15 },
  { value: 16, label: 16 }, { value: 17, label: 17 }, { value: 18, label: 18 },
  { value: 19, label: 19 }, { value: 20, label: 20 }, { value: 21, label: 21 },
  { value: 22, label: 22 }, { value: 23, label: 23 }, { value: 24, label: 24 },
  { value: 25, label: 25 }, { value: 26, label: 26 }, { value: 27, label: 27 },
  { value: 28, label: 28 }, { value: 29, label: 29 }, { value: 30, label: 30 },
  { value: 31, label: 31 },
];

export const MONTHS = [
  { value: 1, label: '01 - January' }, { value: 2, label: '02 - February' }, { value: 3, label: '03- March' },
  { value: 4, label: '04 - April' }, { value: 5, label: '05 - May' }, { value: 6, label: '06 - June' },
  { value: 7, label: '07 - July' }, { value: 8, label: '08 - August' }, { value: 9, label: '09 - September' },
  { value: 10, label: '10 - October' }, { value: 11, label: '11 - November' }, { value: 12, label: '12 - December' },
];
const date = new Date();
const currentYear = date.getFullYear(); // ie. 2022
const endYear = currentYear + 10;

export const YEARS = range(2000, endYear).map((year) => ({ value: year, label: year }));

export const FETCHING_PRODUCT_DATA_FOR_NESTED_FORM = 'FETCHING_PRODUCT_DATA_FOR_NESTED_FORM';
export const FETCHING_PRODUCT_DATA = 'FETCHING_PRODUCT_DATA';
