import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_USER_ROLES, ADD_USER_ROLE, USER_ROLE_FETCHED, USER_ROLE_UPDATED,
  USER_ROLE_DELETED,
} from '../constants/user_roles';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: {},
};

export default function userRoles(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ROLES: return setItems(state, action);
    case ADD_USER_ROLE: return addItem(state, action);
    case USER_ROLE_FETCHED: return fetchItem(state, action);
    case USER_ROLE_UPDATED: return updateItem(state, action);
    case USER_ROLE_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
