import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BRANCHES, ADD_BRANCH, BRANCH_FETCHED, BRANCH_UPDATED, BRANCH_DELETED, AUTOCOMPLETE_BRANCHES,
  defaultSearchParams, moduleName,
} from '../constants/branches';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function branches(state = initialState, action) {
  switch (action.type) {
    case SET_BRANCHES: return setItems(state, action);
    case ADD_BRANCH: return addItem(state, action);
    case BRANCH_FETCHED: return fetchItem(state, action);
    case BRANCH_UPDATED: return updateItem(state, action);
    case BRANCH_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_BRANCHES: return autocomplete(state, action);
    default:
      return state;
  }
}
