import { SET_DASHBOARD_RESPONSE } from '../constants/dashboard';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_RESPONSE: return {
      ...state,
      items: action.permissions,
      isFetching: false,
      isError: false,
      errors: {},
    };
    default:
      return state;
  }
}
