import { HOME_BREADCRUMB_LISTING } from './common';

// We use this constants in reducer and actions.
export const VOUCHER_BOOK = 'VOUCHER_BOOK';
export const SET_VOUCHER_BOOKS = 'SET_VOUCHER_BOOKS';
export const ADD_VOUCHER_BOOK = 'ADD_VOUCHER_BOOK';
export const VOUCHER_BOOK_FETCHED = 'VOUCHER_BOOK_FETCHED';
export const VOUCHER_BOOK_UPDATED = 'VOUCHER_BOOK_UPDATED';
export const VOUCHER_BOOK_DELETED = 'VOUCHER_BOOK_DELETED';
export const RESET_VOUCHER_BOOK_ERRORS = 'RESET_VOUCHER_BOOK_ERRORS';
export const AUTOCOMPLETE_VOUCHER_BOOKS = 'AUTOCOMPLETE_VOUCHER_BOOKS';

// Redux saga constants
export const FETCH_VOUCHER_BOOKS = 'FETCH_VOUCHER_BOOKS';
export const FETCH_FORMAT_PREVIEW = 'FETCH_FORMAT_PREVIEW';

export const FORM_INFORMATION_TITLE = 'VOUCHER BOOK INFORMATION';
export const ADD_NEW_VOUCHER_BOOK = 'Add New Voucher Book';

export const title = 'Voucher Books';
export const singularTitle = 'Voucher Book';
export const moduleName = 'voucher_books';
export const baseUrl = '/voucher_books';
export const redirectUrl = '/voucher_books';
export const listingDataKey = 'voucher_books';
export const itemDataKey = 'voucher_book';

export const voucherBookConstants = {
  item: VOUCHER_BOOK,
  setItems: SET_VOUCHER_BOOKS,
  addItem: ADD_VOUCHER_BOOK,
  setItem: VOUCHER_BOOK_FETCHED,
  updateItem: VOUCHER_BOOK_UPDATED,
  deleteItem: VOUCHER_BOOK_DELETED,
  resetErrors: RESET_VOUCHER_BOOK_ERRORS,
  autocompleteItems: AUTOCOMPLETE_VOUCHER_BOOKS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  valid_from_gteq: '',
  valid_from_lteq: '',
  name_or_resource_type_cont: '',
  branch_id_in: [],
  // brand_ids_in: [],
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['brands', 'branches'];

// Breadcrumb
export const Title = title;
export const TargetBaseUrl = moduleName;
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'active', 'resource_type', 'valid_from'],
  number_format: [],
};
