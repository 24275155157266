import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { serializePageParams, serializeSearchParams } from '@utils/SerializeParams';
import { CONTENT } from './constants';

export function fetchContents({ params, search_params }: any) {
  const { redirectUrl = '/', page, per_page } = params;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  return () => axios({
    url: `/contents?${pageParams}&${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, CONTENT);
    });
}

export function fetchContent({ params }: any) {
  const { redirectUrl = '/', id } = params;
  return () => axios({
    url: `/contents/${id}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, CONTENT);
    });
}
