import { HOME_BREADCRUMB_LISTING } from './common';

export const DEFAULT_DAC_ROLE = 'DEFAULT_DAC_ROLE';
export const SET_DEFAULT_DAC_ROLES = 'SET_DEFAULT_DAC_ROLES';
export const ADD_DEFAULT_DAC_ROLE = 'ADD_DEFAULT_DAC_ROLE';
export const DEFAULT_DAC_ROLE_FETCHED = 'DEFAULT_DAC_ROLE_FETCHED';
export const DEFAULT_DAC_ROLE_UPDATED = 'DEFAULT_DAC_ROLE_UPDATED';
export const DEFAULT_DAC_ROLE_DELETED = 'DEFAULT_DAC_ROLE_DELETED';
export const AUTOCOMPLETE_DEFAULT_DAC_ROLES = 'AUTOCOMPLETE_DEFAULT_DAC_ROLES';
export const SET_DEFAULT_DAC_ROLE_TEMPLATE = 'SET_DEFAULT_DAC_ROLE_TEMPLATE';

export const title = 'Default Dac Roles';
export const singularTitle = 'Default Dac Role';
export const moduleName = 'default_dac_roles';
export const baseUrl = '/default_dac_roles';
export const redirectUrl = '/default_dac_roles';
export const listingDataKey = 'default_dac_roles';
export const itemDataKey = 'default_dac_role';
export const permissionKey = 'authorisation/default_dac_roles';

export const defaultDacRoleConstants = {
  item: DEFAULT_DAC_ROLE,
  setItems: SET_DEFAULT_DAC_ROLES,
  addItem: ADD_DEFAULT_DAC_ROLE,
  setItem: DEFAULT_DAC_ROLE_FETCHED,
  updateItem: DEFAULT_DAC_ROLE_UPDATED,
  deleteItem: DEFAULT_DAC_ROLE_DELETED,
  autocompleteItems: AUTOCOMPLETE_DEFAULT_DAC_ROLES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};

export const defaultSearchParams = {
  resource_type_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Default Dac Roles';
export const TargetBaseUrl = 'default_dac_roles';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['resource_type', 'resource_user_reference', 'role', 'role_id'],
};
