import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_SMS_STATISTICS, SET_SMS_STATISTICS, FETCHING_SMS_STATISTICS,
} from '@constants/sms_statistics';
import { fetchSmsStatistics } from '@api/sms_statistics';

function* fetchSmsStatisticsSagaFunc(action: any) {
  const {
    current_page: currentPage, per_page, search_params: searchParams,
  } = action;

  yield put({ type: FETCHING_SMS_STATISTICS });

  try {
    const response = yield call(fetchSmsStatistics({
      search_params: searchParams, per_page, page: currentPage,
    }));

    const responseData = response?.data || {};
    const { sms, meta } = responseData;
    const { total_count: totalCount } = meta?.pagination || {};

    const data = {
      items: sms || [],
      current_page: currentPage,
      per_page,
      total_count: totalCount || 10,
      isFetching: false,
      search_params: searchParams,
    };

    yield put({ type: SET_SMS_STATISTICS, data });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* smsStatisticsWatcher() {
  yield takeLatest(FETCH_SMS_STATISTICS, fetchSmsStatisticsSagaFunc);
}
