import { call, put, takeLatest } from 'redux-saga/effects';
import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { CREATE_ORGANISATION, SET_NEW_ORGANISATION } from './constants';

function createOrganisation(args) {
  const { organisation } = args || {};
  return () => axios({
    url: '/organisations',
    method: 'POST',
    data: { organisation },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

function* createOrganisationFunc(action) {
  const { organisation } = action || {};
  try {
    const response = yield call(createOrganisation({ organisation }));
    yield put({ type: SET_NEW_ORGANISATION, organisation: response.data.organisation || {} });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* newOrganisation() {
  yield takeLatest(CREATE_ORGANISATION, createOrganisationFunc);
}
