import { HOME_BREADCRUMB_LISTING } from './common';

export const DOWNLOAD = 'DOWNLOAD';
export const SET_DOWNLOADS = 'SET_DOWNLOADS';
export const DOWNLOADED_FILE_DELETED = 'DOWNLOADED_FILE_DELETED';

export const title = 'Downloads';
export const singularTitle = 'Download';
export const moduleName = 'downloads';
export const baseUrl = '/downloads';
export const redirectUrl = '/downloads';
export const listingDataKey = 'downloads';
export const itemDataKey = 'download';

export const downloadConstants = {
  item: DOWNLOAD,
  setItems: SET_DOWNLOADS,
  deleteItem: DOWNLOADED_FILE_DELETED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  file_file_name_cont: '',
  created_at_gteq: '',
  created_at_lteq: '',
};

export const requiredPrefetchingModuleNames = [];

export const Title = 'Downloads';
export const TargetBaseUrl = 'downloads';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
