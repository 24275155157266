import axiosInstance from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';

export const GET = (args) => axiosInstance({
  url: args.url,
  method: 'get',
  params: args?.params || {},
})
  .catch((error) => {
    let { redirect_to: redirectTo, module_name: moduleName } = args?.error || {};
    const { shouldRedirect = true } = args?.error || {};

    redirectTo = redirectTo || '/';
    moduleName = moduleName || '';
    handleErrors(error, redirectTo, moduleName, shouldRedirect);
    throw error.response;
  });

export const POST = (args) => axiosInstance({
  url: args.url,
  method: 'post',
  data: args?.data || {},
  params: args?.params || {},
})
  .catch((error) => {
    let { redirect_to: redirectTo, module_name: moduleName } = args?.error || {};
    const { shouldRedirect = true } = args?.error || {};

    redirectTo = redirectTo || '/';
    moduleName = moduleName || '';
    handleErrors(error, redirectTo, moduleName, shouldRedirect);
    throw error.response;
  });

export const PUT = (args) => axiosInstance({
  url: args.url,
  method: 'put',
  data: args?.data || {},
})
  .catch((error) => {
    let { redirect_to: redirectTo, module_name: moduleName } = args?.error || {};
    redirectTo = redirectTo || '/';
    moduleName = moduleName || '';
    handleErrors(error, redirectTo, moduleName);
    throw error.response;
  });

export const DELETE = (args) => axiosInstance({
  url: args.url,
  method: 'delete',
})
  .catch((error) => {
    let { redirect_to: redirectTo, module_name: moduleName } = args?.error || {};
    redirectTo = redirectTo || '/';
    moduleName = moduleName || '';
    handleErrors(error, redirectTo, moduleName);
    throw error.response;
  });
