import { call, put, takeLatest } from 'redux-saga/effects';
import { serializeSearchParams } from '@src/utils/SerializeParams';
import {
  moduleName, FETCH_VOUCHER_BOOKS, FETCH_FORMAT_PREVIEW,
} from '../constants/voucher_books';
import axios from '../utils/axios';
import { handleErrors } from '../utils/handleErrors';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { fetchResources } from '../api/common';
import { fetchFormatPreview } from '../api/voucher_books';

export function saveVoucherBook(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      voucher_book: data,
    },
  })
    .then((response) => {
      const { voucher_book } = response.data;
      dispatch(addItem({ moduleName, item: voucher_book }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchVoucherBook(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { voucher_book } = response.data;
      dispatch(setItem({ moduleName, item: voucher_book }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateVoucherBook(voucherBook) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${voucherBook.id}`,
    method: 'put',
    data: {
      voucher_book: voucherBook,
    },
  })
    .then((response) => {
      const { voucher_book } = response.data;
      dispatch(updateItem({ moduleName, item: voucher_book }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function deleteVoucherBook(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteVoucherBooks(searchParams: any) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchVoucherBooksBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item', 'listingDataKey'] });
  const {
    baseUrl, redirectUrl, item, listingDataKey,
  } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => {
      const items = response.data[listingDataKey];

      dispatch(setItems({ moduleName, responseData: { items } }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchVoucherBooksSagaSideEffect(action: any) {
  const {
    search_params: searchParams, per_page: perPage, page, sort_by: sortBy, sort_order: sortOrder,
    selected_variant: selectedVariant, dont_merge_search_params: dontMergeSearchParams,
  } = action;
  try {
    const response = yield call(fetchResources({
      moduleName,
      search_params: searchParams,
      per_page: perPage,
      page,
      sort_by: sortBy,
      sort_order: sortOrder,
      selected_variant: selectedVariant,
      dont_merge_search_params: dontMergeSearchParams,
    }));
    const { voucher_books: voucherBooks, meta } = response.data;
    const { pagination } = meta;
    const { total_count: totalCount } = pagination;
    yield put(setItems({
      moduleName,
      responseData: {
        items: voucherBooks,
        current_page: page,
        total_count: totalCount,
        sort_by: sortBy,
        sort_order: sortOrder,
        search_params: searchParams,
        per_page: perPage,
        selected_variant: selectedVariant,
        dont_merge_search_params: dontMergeSearchParams,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchForamtPreviewSideEffect(action: any) {
  const { numberFormat } = action;
  try {
    const response = yield call(fetchFormatPreview(numberFormat));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* voucherBookWatcher() {
  yield takeLatest(FETCH_VOUCHER_BOOKS, fetchVoucherBooksSagaSideEffect);
  yield takeLatest(FETCH_FORMAT_PREVIEW, fetchForamtPreviewSideEffect);
}
