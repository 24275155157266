import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import { logEvent } from '../../utils/GAUtils';
import FormCancelConfirmationModal from './FormCancelConfirmationModal';
import history from '../history';
import { closeQuickAddModuleModal } from './quick_add_module/helper';

class FormCancelConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  }

  handleOnClick = () => {
    const { eventCategory, label } = this.props;
    history.goBack();
    logEvent(eventCategory, `${eventCategory}_CANCEL`, label);
  }

  render() {
    const {
      btnName, isFormChanged, additionalButtonClasses, open_form_in_modal,
    } = this.props;
    const { isOpen } = this.state;

    if (open_form_in_modal) {
      return <a className="btn btn-primary-outline" onClick={() => closeQuickAddModuleModal({})}>CANCEL</a>;
    }

    return (
      <>
        {
          (isFormChanged)
            ? (
              <>
                <a
                  data-cy="cancel-button"
                  className={`btn btn-primary-outline ${additionalButtonClasses}`}
                  onClick={this.openModal}
                >
                  <span>{btnName}</span>
                </a>
                <FormCancelConfirmationModal
                  isOpen={isOpen}
                  closeModal={this.closeModal}
                  handleOnClick={this.handleOnClick}
                />
              </>
            )
            : (
              <a
                data-cy="cancel-button"
                className={`btn btn-primary-outline ${additionalButtonClasses}`}
                onClick={this.handleOnClick}
              >
                <span>{btnName}</span>
              </a>
            )
        }
      </>
    );
  }
}

FormCancelConfirmation.propTypes = {
  btnName: string,
  additionalButtonClasses: string,
  isFormChanged: bool,
  eventCategory: string.isRequired,
  label: string.isRequired,
  open_form_in_modal: bool,
};

FormCancelConfirmation.defaultProps = {
  btnName: 'CANCEL',
  additionalButtonClasses: '',
  isFormChanged: false,
  open_form_in_modal: false,
};

export default FormCancelConfirmation;
