import Loadable from 'react-loadable';
import Loading from '@component/common/page_loading';

// We use loadable for invoices index page.
const Invoices = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInvoicesIndex" */ '@containers/invoices/Index'),
  loading: Loading,
});

// We use loadable for invoices new and edit page.
const InvoiceCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInvoicesCredate" */ '@containers/invoices/Credate'),
  loading: Loading,
});

// We use loadable for invoices show page.
const InvoiceShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInvoicesShow" */ '@containers/invoices/Show'),
  loading: Loading,
});

// We use loadable for purchase_orders index page.
const PurchaseOrders = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseOrdersIndex" */ '@containers/purchase_orders/Index'),
  loading: Loading,
});

// We use loadable for purchase_orders new and edit page.
const PurchaseOrderCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseOrdersCredate" */ '@containers/purchase_orders/Credate'),
  loading: Loading,
});

// We use loadable for purchase_orders show page.
const PurchaseOrderShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseOrdersShow" */ '@containers/purchase_orders/Show'),
  loading: Loading,
});

// We use loadable for purchase_invoices index page.
const PurchaseInvoices = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseInvoicesIndex" */ '@containers/purchase_invoices/Index'),
  loading: Loading,
});

// We use loadable for purchase_invoices new and edit page.
const PurchaseInvoiceCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseInvoicesCredate" */ '@containers/purchase_invoices/Credate'),
  loading: Loading,
});

// We use loadable for purchase_invoices show page.
const PurchaseInvoiceShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseInvoicesShow" */ '@containers/purchase_invoices/Show'),
  loading: Loading,
});

// We use loadable for payments index page.
const Payments = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentsIndex" */ '@containers/payments/Index'),
  loading: Loading,
});

// We use loadable for payments new and edit page.
const PaymentCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentsCredate" */ '@containers/payments/Credate'),
  loading: Loading,
});

// We use loadable for payments show page.
const PaymentShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentsShow" */ '@containers/payments/Show'),
  loading: Loading,
});

// We use loadable for payment outs index page.
const PaymentOuts = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentOutsIndex" */ '@containers/payment_outs/index'),
  loading: Loading,
});

// We use loadable for payment out new and edit page.
const PaymentOutCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentOutsCredate" */ '@containers/payment_outs/credate'),
  loading: Loading,
});

// We use loadable for payment outs show page.
const PaymentOutShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPaymentOutShow" */ '@containers/payment_outs/show'),
  loading: Loading,
});

// We use loadable for returns index page.
const Returns = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReturnsIndex" */ '@containers/returns/Index'),
  loading: Loading,
});

// We use loadable for returns new and edit page.
const ReturnCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReturnsCredate" */ '@containers/returns/Credate'),
  loading: Loading,
});

// We use loadable for returns show page.
const ReturnShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReturnsShow" */ '@containers/returns/Show'),
  loading: Loading,
});

// We use loadable for credit_notes index page.
const CreditNotes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCreditNotesIndex" */ '@containers/credit_notes/Index'),
  loading: Loading,
});

// We use loadable for credit_notes new and edit page.
const CreditNoteCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCreditNotesCredate" */ '@containers/credit_notes/Credate'),
  loading: Loading,
});

// We use loadable for credit_notes show page.
const CreditNoteShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersCreditNotesShow" */ '@containers/credit_notes/Show'),
  loading: Loading,
});

// We use loadable for debit_notes index page.
const DebitNotes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDebitNotesindex" */ '@containers/debit_notes/index'),
  loading: Loading,
});

// We use loadable for debit_notes new and edit page.
const DebitNoteCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDebitNotescredate" */ '@containers/debit_notes/credate'),
  loading: Loading,
});

// We use loadable for debit_notes show page.
const DebitNoteShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDebitNotesShow" */ '@containers/debit_notes/show'),
  loading: Loading,
});

// We use loadable for accounts index page.
// const Accounts = Loadable({
//   loader: () => import(/* webpackChunkName : "ContainersAccountsIndex" */ '@containers/accounts/Index'),
//   loading: Loading,
// });

// We use loadable for accounts new and edit page.
const AccountCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountsCredate" */ '@containers/accounts/Credate'),
  loading: Loading,
});

// We use loadable for accounts show page.
const AccountShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountsShow" */ '@containers/accounts/Show'),
  loading: Loading,
});

// We use loadable for invoice_terms index page.
const InvoiceTerms = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInvoiceTermsIndex" */ '@containers/invoice_terms/Index'),
  loading: Loading,
});

// We use loadable for invoice_terms new and edit page.
const InvoiceTermCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInvoiceTermsCredate" */ '@containers/invoice_terms/Credate'),
  loading: Loading,
});

// We use loadable for order_terms index page.
const OrderTerms = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOrderTermsIndex" */ '@containers/order_terms/Index'),
  loading: Loading,
});

// We use loadable for order_terms new and edit page.
const OrderTermCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOrderTermsCredate" */ '@containers/order_terms/Credate'),
  loading: Loading,
});

// // We use loadable for purchase_order_terms index page.
const PurchaseOrderTerms = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseOrderTermsIndex" */ '@containers/purchase_order_terms/Index'),
  loading: Loading,
});

// // We use loadable for order_terms new and edit page.
const PurchaseOrderTermCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseOrderTermsCredate" */ '@containers/purchase_order_terms/Credate'),
  loading: Loading,
});

// We use loadable for purchase_returns index page.
const PurchaseReturns = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsIndex" */ '@containers/purchase_returns/Index'),
  loading: Loading,
});

// We use loadable for purchase_returns new and edit page.
const PurchaseReturnCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsCredate" */ '@containers/purchase_returns/Credate'),
  loading: Loading,
});

// We use loadable for purchase_returns show page.
const PurchaseReturnShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsShow" */ '@containers/purchase_returns/Show'),
  loading: Loading,
});

const Explore = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsGettingStartedExplore" */ '@component/contents/explore'),
  loading: Loading,
});

const Onboard = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsGettingStartedOnboard" */ '@component/contents/onboard'),
  loading: Loading,
});

const Help = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsGettingStartedHelp" */ '@component/contents/help'),
  loading: Loading,
});

const HelpShow = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsContentsShow" */ '@component/contents/show'),
  loading: Loading,
});

const MasterRoutes = [
  {
    path: '/invoices/new', name: 'invoices/new', component: InvoiceCredate,
  },
  {
    path: '/invoices/:id/edit', name: 'invoices/edit', component: InvoiceCredate,
  },
  {
    path: '/invoices', name: 'Invoices', component: Invoices,
  },
  {
    path: '/invoices/:id', name: 'Invoice', component: InvoiceShow,
  },

  {
    path: '/purchase_orders/new', name: 'purchase_orders/new', component: PurchaseOrderCredate,
  },
  {
    path: '/purchase_orders/:id/edit', name: 'purchase_orders/edit', component: PurchaseOrderCredate,
  },
  {
    path: '/purchase_orders', name: 'Purchase Orders', component: PurchaseOrders,
  },
  {
    path: '/purchase_orders/:id', name: 'Purchase Order', component: PurchaseOrderShow,
  },

  {
    path: '/purchase_invoices/new', name: 'purchase_invoices/new', component: PurchaseInvoiceCredate,
  },
  {
    path: '/purchase_invoices/:id/edit', name: 'purchase_invoices/edit', component: PurchaseInvoiceCredate,
  },
  {
    path: '/purchase_invoices', name: 'Purchase Invoices', component: PurchaseInvoices,
  },
  {
    path: '/purchase_invoices/:id', name: 'Purchase Invoice', component: PurchaseInvoiceShow,
  },

  {
    path: '/purchase_returns/new', name: 'purchase_returns/new', component: PurchaseReturnCredate,
  },
  {
    path: '/purchase_returns/:id/edit', name: 'purchase_returns/edit', component: PurchaseReturnCredate,
  },
  {
    path: '/purchase_returns', name: 'Purchase Invoices', component: PurchaseReturns,
  },
  {
    path: '/purchase_returns/:id', name: 'Purchase Invoice', component: PurchaseReturnShow,
  },

  {
    path: '/payments/new', name: 'payments/new', component: PaymentCredate,
  },
  {
    path: '/payments/:id/edit', name: 'payments/edit', component: PaymentCredate,
  },
  {
    path: '/payments', name: 'Payments', component: Payments,
  },
  {
    path: '/payments/:id', name: 'Payments', component: PaymentShow,
  },

  {
    path: '/payment_outs/new', name: 'New payment outs', component: PaymentOutCredate,
  },
  {
    path: '/payment_outs/:id/edit', name: 'Edit payment outs', component: PaymentOutCredate,
  },
  {
    path: '/payment_outs', name: 'Payment outs', component: PaymentOuts,
  },
  {
    path: '/payment_outs/:id', name: 'Show Payment Out', component: PaymentOutShow,
  },

  {
    path: '/returns/new', name: 'returns/new', component: ReturnCredate,
  },
  {
    path: '/returns/:id/edit', name: 'returns/edit', component: ReturnCredate,
  },
  {
    path: '/returns', name: 'Returns', component: Returns,
  },
  {
    path: '/returns/:id', name: 'Returns', component: ReturnShow,
  },

  {
    path: '/credit_notes/new', name: 'credit_notes/new', component: CreditNoteCredate,
  },
  {
    path: '/credit_notes/:id/edit', name: 'credit_notes/edit', component: CreditNoteCredate,
  },
  {
    path: '/credit_notes', name: 'Credit Notes', component: CreditNotes,
  },
  {
    path: '/credit_notes/:id', name: 'Credit Notes', component: CreditNoteShow,
  },

  {
    path: '/debit_notes/new', name: 'New Debit Note', component: DebitNoteCredate,
  },
  {
    path: '/debit_notes/:id/edit', name: 'Edit Debit Note', component: DebitNoteCredate,
  },
  {
    path: '/debit_notes', name: 'Debit Notes', component: DebitNotes,
  },
  {
    path: '/debit_notes/:id', name: 'Show Debit Note', component: DebitNoteShow,
  },

  {
    path: '/customers/:customer_id/account/edit', name: 'accounts/edit', component: AccountCredate,
  },

  {
    path: '/customers/:customer_id/account', name: 'Account', component: AccountShow,
  },

  {
    path: '/invoice_terms/new', name: 'invoice_terms/new', component: InvoiceTermCredate,
  },
  {
    path: '/invoice_terms/:id/edit', name: 'invoice_terms/edit', component: InvoiceTermCredate,
  },
  {
    path: '/invoice_terms', name: 'Invoice Terms', component: InvoiceTerms,
  },
  {
    path: '/order_terms/new', name: 'New Order Term', component: OrderTermCredate,
  },
  {
    path: '/order_terms/:id/edit', name: 'Edit Order Term', component: OrderTermCredate,
  },
  {
    path: '/order_terms', name: 'Order Terms', component: OrderTerms,
  },
  {
    path: '/purchase_order_terms/new', name: 'New Purchase Order Term', component: PurchaseOrderTermCredate,
  },
  {
    path: '/purchase_order_terms/:id/edit', name: 'Edit Purchase Order Term', component: PurchaseOrderTermCredate,
  },
  {
    path: '/purchase_order_terms', name: 'PO Terms', component: PurchaseOrderTerms,
  },
  {
    path: '/explore', name: 'Explore', component: Explore, routeDependsOnSetting: true, settingKey: 'left_nav_show_getting_started',
  },
  {
    path: '/onboard', name: 'Onboard', component: Onboard, routeDependsOnSetting: true, settingKey: 'left_nav_show_getting_started',
  },
  {
    path: '/help', name: 'Help', component: Help,
  },
  {
    path: '/help/:id', name: 'Help Show', component: HelpShow,
  },
];

export default MasterRoutes;
