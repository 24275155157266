import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '@utils/axios';
import {
  moduleName, FETCH_USERS, SUSPEND_USER, REVOKE_USER_SUSPENSION, UPDATE_USER_SUSPENDED
} from '@constants/users';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteUnions,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { fetchUsers, revokeUserSuspensionAction, suspendUserAction } from '@api/users';

// createUser function take user_params and create new user.
// also dispatch(addUser(user)) update the redux users state.
export function createUser(user_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: '/users/add_access',
    method: 'POST',
    data: {
      user: user_params,
    },
  })
    .then((response) => {
      const { user } = response.data;
      dispatch(addItem({ moduleName, item: user }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get User
export function fetchUser(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { user } = response?.data || {};
      dispatch(setItem({ moduleName, item: (user || {}) }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update User.
export function updateUser(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data?.id}`,
    method: 'PUT',
    data: {
      user: data,
    },
  })
    .then((response) => {
      const { user } = response.data;
      dispatch(updateItem({ moduleName, item: user }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Remove User Access.
export function removeUserAccess(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/remove_access`,
    method: 'PUT',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteUsersName(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete_users_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchUsersBasicInfo(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_users_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { users } = response.data;
      dispatch(autocompleteUnions({ moduleName, items: users }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchUsersSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchUsers({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { users, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: users,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* suspendUserFunc(action: any) {
  const { id } = action;

  try {
    const response = yield call(suspendUserAction({ id }));
    yield put({ type: UPDATE_USER_SUSPENDED, payload: { id, suspended: true } });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* revokeUserSuspendFunc(action: any) {
  const { id } = action;

  try {
    const response = yield call(revokeUserSuspensionAction({ id }));

    yield put({ type: UPDATE_USER_SUSPENDED, payload: { id, suspended: false } });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* userWatcher() {
  yield takeLatest(FETCH_USERS, fetchUsersSagaSideEffect);
  yield takeLatest(SUSPEND_USER, suspendUserFunc);
  yield takeLatest(REVOKE_USER_SUSPENSION, revokeUserSuspendFunc);
}
