import {
  SETTINGS_REQUEST, SETTINGS_RESPONSE, SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_RESPONSE, SETTINGS_RESET_REQUEST, SETTINGS_ERROR_RESPONSE,
} from '@containers/settings/SettingsActions';

export function mergeMetaErrors(state, response) {
  return {
    ...state, isFetching: false, meta: response.meta, errors: response.errors,
  };
}

export function mergeDataLinks(state, response) {
  return {
    ...state, isFetching: false, links: response.links, data: response?.data || {},
  };
}

export function handleUpdateResponse(state, action) {
  const { response } = action;
  if (response.errors) {
    return mergeMetaErrors(state, response);
  }
  if (response) {
    return mergeDataLinks(state, response);
  }
  return state;
}

export const initialState = {};

export default function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return {
        ...state, isFetching: true, isError: false, errors: {},
      };
    case SETTINGS_RESPONSE: {
      const { links, data } = action?.response || {};
      return {
        ...state,
        isFetching: false,
        isError: false,
        links: links || {},
        data: data || {},
      };
    }
    case SETTINGS_UPDATE_REQUEST:
      return {
        ...state, isFetching: true, isError: false, errors: {},
      };
    case SETTINGS_UPDATE_RESPONSE: {
      const { links, data } = action?.response || {};
      return {
        ...state,
        isFetching: false,
        isError: false,
        links: links || state?.links || {},
        data: data || state?.data || {},
      };
    }
    case SETTINGS_ERROR_RESPONSE: {
      const { errors } = action?.response || {};
      return {
        ...state,
        isFetching: false,
        errors: errors || state?.errors || {},
      };
    }
    case SETTINGS_RESET_REQUEST:
      return {
        ...state, isFetching: false, isError: false, errors: {},
      };

    default:
      return state;
  }
}
