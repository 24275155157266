import { UNITS_UTILITIES, IS_FETCHING_UTILITIES } from '@constants/utilities';

export const initialState = {
  data: {
    units: [],
  },
  isFetching: false,
};

export default function utilities(state = initialState,
  action: { type: string; payload: Record<string, any>; }) {
  switch (action.type) {
    case IS_FETCHING_UTILITIES: {
      return {
        ...state,
        isFetching: action?.payload?.isFetching || false,
      };
    }
    case UNITS_UTILITIES: {
      const payload = action?.payload || {};
      const data = state?.data || {};
      return {
        ...state,
        data: { ...data, ...payload },
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
