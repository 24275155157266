import Loadable from 'react-loadable';
import Loading from '@component/common/page_loading';

// We use loadable for stocks index page.
const Stocks = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStocksIndex" */ '@containers/stocks/Index'),
  loading: Loading,
});

// We use loadable for stocks new and edit page.
const StockCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStocksCredate" */ '@containers/stocks/Credate'),
  loading: Loading,
});

// We use loadable for stocks show page.
const StockShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStocksShow" */ '@containers/stocks/Show'),
  loading: Loading,
});

// We use loadable for stock_entries index page.
const StockEntries = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStockEntriesIndex" */ '@containers/stock_entries/Index'),
  loading: Loading,
});

// We use loadable for stock_transfers index page.
const StockTransfers = Loadable({
  loader: () => import('@containers/stock_transfers/Index'),
  loading: Loading,
});

// We use loadable for stock_transfers new and edit page.
const StockTransferCredate = Loadable({
  loader: () => import('@containers/stock_transfers/Credate'),
  loading: Loading,
});

// We use loadable for stock transfer show page.
const StockTransferShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStockTransfersShow" */ '@containers/stock_transfers/Show'),
  loading: Loading,
});

// We use loadable for inventory_trackings index page.
const InventoryIdentifications = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInventoryIdentifications" */ '@containers/inventory_identifications/index'),
  loading: Loading,
});

// We use loadable for inventory_trackings new and edit page.
const InventoryIdentificationCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInventoryIdentificationCredate" */ '@containers/inventory_identifications/credate'),
  loading: Loading,
});

// We use loadable for inventory_tracking show page.
const InventoryIdentificationShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInventoryIdentificationShow" */ '@containers/inventory_identifications/show'),
  loading: Loading,
});

// // We use loadable for inventories index page.
const Inventories = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInventories" */ '@containers/inventory/index'),
  loading: Loading,
});

// // We use loadable for inventory credate new and edit page.
const InventoryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersInventoryCredate" */ '@containers/inventory/credate'),
  loading: Loading,
});

// // We use loadable for inventory show page.
// const InventoryShow = Loadable({
//   loader: () => import(/* webpackChunkName : "ContainersInventoryShow" */ '@containers/inventory/show'),
//   loading: Loading,
// });

// We use loadable for product index page.
const Products = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersProductsIndex" */ '@containers/products/Index'),
  loading: Loading,
});

// We use loadable for product new and edit index page.
const ProductCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersProductsCredate" */ '@containers/products/Credate'),
  loading: Loading,
});

// We use loadable for product show page.
const ProductShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersProductsShow" */ '@containers/products/Show'),
  loading: Loading,
});

const InventoryRoutes = [
  // stocks
  {
    path: '/stocks/new', name: 'stocks/new', component: StockCredate,
  },
  {
    path: '/stocks/:id/edit', name: 'stocks/edit', component: StockCredate,
  },
  {
    path: '/stocks', name: 'Stocks', component: Stocks,
  },
  {
    path: '/stocks/:id', name: 'Stock', component: StockShow,
  },

  // stock_entries
  {
    path: '/stock_entries', name: 'Stock Entries', component: StockEntries,
  },

  // stock_transfers
  {
    path: '/stock_transfers/new', name: 'stock_transfers/new', component: StockTransferCredate,
  },
  {
    path: '/stock_transfers/:id/edit', name: 'stock_transfers/edit', component: StockTransferCredate,
  },
  {
    path: '/stock_transfers', name: 'Stock Transfers', component: StockTransfers,
  },
  {
    path: '/stock_transfers/:id', name: 'Show Stock Transfer', component: StockTransferShow,
  },

  // inventory_identifications
  {
    path: '/inventory_identifications/new', name: 'New Identification Identification', component: InventoryIdentificationCredate,
  },
  {
    path: '/inventory_identifications/:id/edit', name: 'Edit Identification Identification', component: InventoryIdentificationCredate,
  },
  {
    path: '/inventory_identifications', name: 'Inventory Identifications', component: InventoryIdentifications,
  },
  {
    path: '/inventory_identifications/:id', name: 'Show Inventory Identification', component: InventoryIdentificationShow,
  },

  // inventory_batches
  {
    path: '/inventory_batches', name: 'Inventory Batches', component: Inventories,
  },
  {
    path: '/inventory_batches/new', name: 'New Inventory Batch', component: InventoryCredate,
  },
  {
    path: '/inventory_batches/:id/edit', name: 'Edit Inventory Batch', component: InventoryCredate,
  },
  // {
  //   path: '/inventory_batches/:id', name: 'Show Inventory Batch', component: InventoryShow,
  // },

  // inventory_pieces
  {
    path: '/inventory_pieces', name: 'Inventory Pieces', component: Inventories,
  },
  {
    path: '/inventory_pieces/new', name: 'New Inventory Piece', component: InventoryCredate,
  },
  {
    path: '/inventory_pieces/:id/edit', name: 'Edit Inventory Piece', component: InventoryCredate,
  },
  // {
  //   path: '/inventory_pieces/:id', name: 'Show Inventory Piece', component: InventoryShow,
  // },

  // products
  {
    path: '/products/new', name: 'products/new', component: ProductCredate,
  },
  {
    path: '/products/:id/edit', name: 'products/edit', component: ProductCredate,
  },
  {
    path: '/products', name: 'Products', component: Products,
  },
  {
    path: '/products/:id', name: 'Product Show', component: ProductShow,
  },
];

export default InventoryRoutes;
