import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { SET_ACCOUNT_ENTRIES, defaultSearchParams } from '../constants/account_entries';
import { setItems } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  search_params: { ...defaultSearchParams },
};

export default function accountEntries(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_ENTRIES: return setItems(state, action);
    default:
      return state;
  }
}
