import { SET_COLLECTION_PERFORMANCE, INITIAL_STATE } from '../constants/performances';

export const initialState = INITIAL_STATE;

export default function collectionPerformance(state = initialState, action) {
  switch (action.type) {
    case SET_COLLECTION_PERFORMANCE: {
      return {
        ftd: { ...state.ftd, ...action.ftd },
        mtd: { ...state.mtd, ...action.mtd },
        ytd: { ...state.ytd, ...action.ytd },
        description: action.description,
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
