import find from 'lodash/find';
import { AUTH_TOKEN_HEADER_FOR_BE, RECENT_USED_TENANT } from '@utils/common_constants';
import history from '@component/history';
import { fetchSettings, fetchUiPermissions } from '@saga/root';
import { DEFAULT_COOKIE_STORAGE_PERIOD, deleteCookie, getCookie, setCookie } from '@helpers/browserStorageManager';
import { getDomain, getSubDomain, getSubDomainTargetUrl } from '@helpers/common_helper';

interface Props {
  account?: any;
}

export const handleTenantAccess = ({ account = {} }: Props) => {
  const tenantAccesses = account?.tenant_accesses || [];
  // const organisations = account?.organisations || [];

  const domain = getDomain();
  const hasSubDomain = getSubDomain();

  const recentUsedTenant = getCookie(RECENT_USED_TENANT);
  const tenant = find(tenantAccesses, { tenant_name: recentUsedTenant });
  // const totalNumberOfOrganisations = [...tenantAccesses, ...organisations];

  if (!hasSubDomain) {
    // if ((totalNumberOfOrganisations?.length === 1)) {
    //   const tenant = tenantAccesses?.[0];
    //   const tenantName = tenant?.tenant_name || '';
    //   const targetUrl = getSubDomainTargetUrl(tenantName);
    //   setCookie(RECENT_USED_TENANT, tenantName, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
    //   if (tenant?.can_access_dashboard) {
    //     window.location.href = targetUrl;
    //   } else {
    //     deleteCookie(RECENT_USED_TENANT);
    //     history.push('/organisations');
    //   }
    // } else
    if (recentUsedTenant && tenant?.id && tenant?.can_access_dashboard) {
      const targetUrl = getSubDomainTargetUrl(tenant?.tenant_name);
      setCookie(RECENT_USED_TENANT, tenant?.tenant_name, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
      window.location.href = targetUrl;
    } else {
      deleteCookie(RECENT_USED_TENANT);
      history.push('/organisations');
    }
  } else {
    fetchSettings();
    fetchUiPermissions();
  }
};

interface TenantHasAccessProps {
  isAuthenticated: boolean;
}

export const hasDashboardPermission = ({ isAuthenticated }: TenantHasAccessProps) => {
  const cookieXAuthToken = getCookie(AUTH_TOKEN_HEADER_FOR_BE);
  const localStorageXAuthToken = localStorage.getItem(AUTH_TOKEN_HEADER_FOR_BE);

  return !!(isAuthenticated || cookieXAuthToken || localStorageXAuthToken);
};
