export const CUSTOM_FIELD = 'CUSTOM_FIELD';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const RESET_CUSTOM_FIELDS = 'RESET_CUSTOM_FIELDS';
export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export const CUSTOM_FIELD_FETCHED = 'CUSTOM_FIELD_FETCHED';
export const CUSTOM_FIELD_UPDATED = 'CUSTOM_FIELD_UPDATED';
export const CUSTOM_FIELD_DELETED = 'CUSTOM_FIELD_DELETED';
export const AUTOCOMPLETE_CUSTOM_FIELDS = 'AUTOCOMPLETE_CUSTOM_FIELDS';

export const title = 'Custom Fields';
export const singularTitle = 'Custom Field';
export const moduleName = 'custom_fields';
export const baseUrl = '/custom_fields';
export const redirectUrl = '/custom_fields';
export const listingDataKey = 'custom_fields';
export const itemDataKey = 'custom_field';
export const permissionKey = moduleName;

export const USER_TAG = 'UserTag';

export const customFieldsConstants = {
  item: CUSTOM_FIELD,
  setItems: SET_CUSTOM_FIELDS,
  addItem: ADD_CUSTOM_FIELD,
  setItem: CUSTOM_FIELD_FETCHED,
  updateItem: CUSTOM_FIELD_UPDATED,
  deleteItem: CUSTOM_FIELD_DELETED,
  autocompleteItems: AUTOCOMPLETE_CUSTOM_FIELDS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};

// CONSTANT CF_FORM_INITIAL_VALUES
export const CF_FORM_INITIAL_VALUES = {
  name: '',
  type: '',
  active: true,
  print: false,
  required: false,
  description: '',
  options: {
    greater_than: '',
    lesser_than: '',
    options_text: '',
    permissible_values: [],
  },
};

// List of resource types
export const ORDER_RESOURCE_TYPE = 'Order';
export const INVOICE_RESOURCE_TYPE = 'Invoice';
export const PAYMENT_RESOURCE_TYPE = 'Payment';
export const RETURN_RESOURCE_TYPE = 'Return';
export const CREDIT_NOTE_RESOURCE_TYPE = 'CreditNote';
export const DEBIT_NOTE_RESOURCE_TYPE = 'DebitNote';
export const CUSTOMER_RESOURCE_TYPE = 'Customer';
export const PURCHASE_ORDER_RESOURCE_TYPE = 'PurchaseOrder';
export const PURCHASE_INVOICE_RESOURCE_TYPE = 'PurchaseInvoice';
export const PAYMENT_OUT_RESOURCE_TYPE = 'PaymentOut';
export const PURCHASE_RETURN_RESOURCE_TYPE = 'PurchaseReturn';
export const PRODUCT_RESOURCE_TYPE = 'Product';
export const SUPPLIER_RESOURCE_TYPE = 'Supplier';

export const RESOURCE_TYPES = {
  orders: ORDER_RESOURCE_TYPE,
  invoices: INVOICE_RESOURCE_TYPE,
  payments: PAYMENT_RESOURCE_TYPE,
  returns: RETURN_RESOURCE_TYPE,
  credit_notes: CREDIT_NOTE_RESOURCE_TYPE,
  debit_notes: DEBIT_NOTE_RESOURCE_TYPE,
  customers: CUSTOMER_RESOURCE_TYPE,
  purchase_orders: PURCHASE_ORDER_RESOURCE_TYPE,
  purchase_invoices: PURCHASE_INVOICE_RESOURCE_TYPE,
  payment_outs: PAYMENT_OUT_RESOURCE_TYPE,
  purchase_returns: PURCHASE_RETURN_RESOURCE_TYPE,
  products: PRODUCT_RESOURCE_TYPE,
  suppliers: SUPPLIER_RESOURCE_TYPE,
};
