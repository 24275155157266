import { HOME_BREADCRUMB_LISTING } from './common';

export const ORDER_TERM = 'ORDER_TERM';
export const SET_ORDER_TERMS = 'SET_ORDER_TERMS';
export const ADD_ORDER_TERM = 'ADD_ORDER_TERM';
export const ORDER_TERM_FETCHED = 'ORDER_TERM_FETCHED';
export const ORDER_TERM_UPDATED = 'ORDER_TERM_UPDATED';
export const ORDER_TERM_DELETED = 'ORDER_TERM_DELETED';
export const RESET_ORDER_TERM_ERRORS = 'RESET_ORDER_TERM_ERRORS';
export const AUTOCOMPLETE_ORDER_TERMS = 'AUTOCOMPLETE_ORDER_TERMS';

export const FORM_INFORMATION_TITLE = 'ORDER TERM INFORMATION';
export const ADD_NEW_ORDER_TERM = 'Add New Order Term';

export const title = 'Order Terms';
export const singularTitle = 'Order Term';
export const moduleName = 'order_terms';
export const baseUrl = '/order_terms';
export const redirectUrl = '/order_terms';
export const listingDataKey = 'order_terms';
export const itemDataKey = 'order_term';

export const orderTermConstants = {
  item: ORDER_TERM,
  setItems: SET_ORDER_TERMS,
  addItem: ADD_ORDER_TERM,
  setItem: ORDER_TERM_FETCHED,
  updateItem: ORDER_TERM_UPDATED,
  deleteItem: ORDER_TERM_DELETED,
  resetErrors: RESET_ORDER_TERM_ERRORS,
  autocompleteItems: AUTOCOMPLETE_ORDER_TERMS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Order Terms';
export const TargetBaseUrl = 'order_terms';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'credit_days', 'interest_rate', 'grace_days', 'terms'],
};
