import { HOME_BREADCRUMB_LISTING } from './common';

export const DOCUMENT_CATEGORY = 'DOCUMENT_CATEGORY';
export const SET_DOCUMENT_CATEGORIES = 'SET_DOCUMENT_CATEGORIES';
export const ADD_DOCUMENT_CATEGORY = 'ADD_DOCUMENT_CATEGORY';
export const DOCUMENT_CATEGORY_FETCHED = 'DOCUMENT_CATEGORY_FETCHED';
export const DOCUMENT_CATEGORY_UPDATED = 'DOCUMENT_CATEGORY_UPDATED';
export const DOCUMENT_CATEGORY_DELETED = 'DOCUMENT_CATEGORY_DELETED';
export const RESET_DOCUMENT_CATEGORY_ERRORS = 'RESET_DOCUMENT_CATEGORY_ERRORS';
export const AUTOCOMPLETE_DOCUMENT_CATEGORIES = 'AUTOCOMPLETE_DOCUMENT_CATEGORIES';
export const SET_DOCUMENT_CATEGORY_TEMPLATE = 'SET_DOCUMENT_CATEGORY_TEMPLATE';

export const FORM_INFORMATION_TITLE = 'DOCUMENT CATEGORY INFORMATION';
export const ADD_NEW_DOCUMENT_CATEGORY = 'Add New Document Category';

export const title = 'Document Categories';
export const singularTitle = 'Document Category';
export const moduleName = 'document_categories';
export const baseUrl = '/document_categories';
export const redirectUrl = '/document_categories';
export const listingDataKey = 'document_categories';
export const itemDataKey = 'document_category';

export const documentCategoryConstants = {
  item: DOCUMENT_CATEGORY,
  setItems: SET_DOCUMENT_CATEGORIES,
  addItem: ADD_DOCUMENT_CATEGORY,
  setItem: DOCUMENT_CATEGORY_FETCHED,
  updateItem: DOCUMENT_CATEGORY_UPDATED,
  deleteItem: DOCUMENT_CATEGORY_DELETED,
  resetErrors: RESET_DOCUMENT_CATEGORY_ERRORS,
  autocompleteItems: AUTOCOMPLETE_DOCUMENT_CATEGORIES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Document Categories';
export const TargetBaseUrl = 'document_categories';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['model'],
};
