import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for credit_notes show page.
const TallyIntegraton = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersTallyIntegrationindex" */ '@containers/tally_integration/index'),
  loading: Loading,
});

// We use loadable for downloads report.
const Downloads = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsDownloads" */ '@containers/reports/Downloads'),
  loading: Loading,
});

const Feeds = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersFeedsFeeds" */ '@containers/feeds/Feeds'),
  loading: Loading,
});

const Statistics = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersStatistics" */ '@containers/statistics'),
  loading: Loading,
});

const SMSStatistics = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSMSStatistics" */ '@containers/sms_statistics'),
  loading: Loading,
});

const UtilsRoutes = [
  {
    path: '/downloads', name: 'Downloads', component: Downloads,
  },

  {
    path: '/feeds', name: 'Feeds', component: Feeds,
  },

  {
    path: '/tally_integration', name: 'Tally Integration', component: TallyIntegraton,
  },
  {
    path: '/statistics', name: 'Statistics', component: Statistics,
  },
  {
    path: '/sms_statistics', name: 'SMS Statistics', component: SMSStatistics,
  },
];

export default UtilsRoutes;
