import React, { Component, Fragment } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map, find, filter } from 'lodash';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UNAUTHENTICATED } from '@constants/auth';
import { logout } from '@saga/auth';
import distributoLogo from '@assets/img/distributo_product_logo_1.svg';
import ProfileImage from '@assets/img/user_logo.svg';
import paymentsLogo from '@assets/img/payments.svg';
import currentSubscription from '@assets/img/subscription.svg';
import { successNotification, errorNotification } from '@actions/root';
// import PROFILE_LOGO from '../../assets/img/DEFAULT_PROFILE_IMG.svg';
import { logEvent, CategoryEnum, ActionEnum } from '@utils/GAUtils';
import OverlayTriggerTooltip from '@component/common/OverlayTriggerTooltip';
import {
  getDomain, getMainAccountTargetUrl, getSubDomain, getSubDomainTargetUrl,
} from '@helpers/common_helper';
import { RECENT_USED_TENANT } from '@utils/common_constants';
import { DEFAULT_COOKIE_STORAGE_PERIOD, deleteCookie, setCookie } from '@helpers/browserStorageManager';
import LOGOUT from '@assets/img/logout.svg';
import CHANGE_PASSWORD from '@assets/img/change_password.svg';
import UPDATE_PROFILE from '@assets/img/update_profile.svg';
import history from '../history';

const changePassword = () => {
  logEvent(CategoryEnum.WINDOW, ActionEnum.CHANGE_PASSWORD, ActionEnum.CHANGE_PASSWORD);
  history.push('/account/password');
};

const updateProfile = () => {
  logEvent(CategoryEnum.WINDOW, ActionEnum.UPDATE_ACCOUNT_PROFILE, ActionEnum.UPDATE_ACCOUNT_PROFILE);
  history.push('/update_profile');
};

const tooltipDescription = (name, organisationName) => (
  <div>
    <b>{name}</b>
    <br />
    {organisationName}
  </div>
);

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: true,
    };
  }

  logoutUser = () => {
    const { logout } = this.props;
    logEvent(CategoryEnum.WINDOW, ActionEnum.SIGN_OUT, ActionEnum.SIGN_OUT);
    logout()
      .then((response) => {
        const { meta } = response?.data || {};
        successNotification({ message: (meta?.notice || ''), renderView: true });
      })
      .catch((error) => {
        const errors = (error?.data?.errors) || {};
        const alert = (errors?.alert) || (errors?.status) || UNAUTHENTICATED;
        errorNotification({ message: alert, renderView: true });
      });
  }

  myOrganisationsFunc = () => {
    deleteCookie(RECENT_USED_TENANT);
    const url = getMainAccountTargetUrl();
    window.location.href = url;
  }

  render() {
    const { auth } = this.props;
    const { isAuthenticated, current_user: user, tenant_accesses: tenantAccesses } = auth;
    const { name, organisation_name } = user || {};
    const { showTooltip } = this.state;
    const description = tooltipDescription(name, organisation_name);
    let nameInitials = name?.match(/\b(\w)/g)?.join('') || '';
    if (nameInitials?.length >= 2) {
      nameInitials = `${nameInitials?.[0]}${nameInitials?.[nameInitials?.length - 1]}`;
    }
    const subDomain = getSubDomain();
    const selectedTenant = find(tenantAccesses, { tenant_name: subDomain });
    const listOfTenantsExceptCurrentTenant = filter(tenantAccesses, (tenantData) => (tenantData?.tenant_name !== subDomain));
    const listOfTenantsExceptCurrentTenantCount = listOfTenantsExceptCurrentTenant?.length;

    if (!isAuthenticated) {
      return null;
    }

    return (
      <OverlayTriggerTooltip placement="bottom" description={description} otherClassNames={!showTooltip ? 'display-none' : ''}>
        <NavDropdown
          // title={<img src={PROFILE_LOGO} className="img-circle user-profile-img" alt="S" width="30" height="30" />}
          title={nameInitials || ''}
          noCaret
          data-cy="user-details-dropdown"
          id="basic-nav-dropdown-right"
          className="user-menu dropdown-with-icons"
          onToggle={(isOpen) => { this.setState({ showTooltip: !isOpen }); }}
        >
          <>
            <MenuItem className="user-menu-item account-info">
              <div className="text-black">
                <img src={ProfileImage} alt={user?.name} />
                &nbsp;
                {user?.name || ''}
              </div>
            </MenuItem>
            {selectedTenant?.id
              ? (
                <>
                  <MenuItem divider className="listing-action-menu-item-divider" />
                  <MenuItem className="listing-action-menu-item-header current-account">
                    Current Organisation
                  </MenuItem>
                  <MenuItem divider className="listing-action-menu-item-divider" />
                  <MenuItem
                    key={`menu-item-${selectedTenant?.tenant?.tenant_name}`}
                    id={`change-tenant-${selectedTenant?.tenant?.tenant_name}`}
                    className="current-tenant-user-menu-item"
                  >
                    <img
                      src={selectedTenant?.tenant?.logo?.signed_url || distributoLogo}
                      alt={selectedTenant?.tenant?.organisation_name}
                    />
                    &nbsp;
                    {selectedTenant?.tenant?.organisation_name || ''}
                  </MenuItem>
                  <MenuItem divider className="listing-action-menu-item-divider" />
                  <MenuItem
                    key={`menu-item-billing-${selectedTenant?.tenant?.tenant_name}`}
                    id={`change-tenant-billing-${selectedTenant?.tenant?.tenant_name}`}
                    className="current-tenant-billing-menu-item"
                  >
                    <img src={paymentsLogo} alt="billing" />
                    &nbsp;
                    <Link to="/billing/pricing" className="billing-link">Pricing</Link>
                  </MenuItem>
                  <MenuItem
                    key={`menu-item-subscription-${selectedTenant?.tenant?.tenant_name}`}
                    id={`change-tenant-subscription-${selectedTenant?.tenant?.tenant_name}`}
                    className="current-tenant-subscription-menu-item"
                  >
                    <img src={currentSubscription} alt="billing" />
                    &nbsp;
                    <Link to="/subscription" className="subscription-link">Subscription</Link>
                  </MenuItem>
                </>
              )
              : null}
            <MenuItem divider className="listing-action-menu-item-divider" />
            {
              (listOfTenantsExceptCurrentTenantCount > 0)
                ? (
                  <>
                    <MenuItem className="listing-action-menu-item-header switch-account">
                      Switch Organisation
                    </MenuItem>
                    <MenuItem divider className="listing-action-menu-item-divider" />
                    {
                      map(listOfTenantsExceptCurrentTenant, (tenantData) => {
                        const { tenant } = tenantData;
                        const { organisation_name: organisationName, logo } = tenant;
                        return (
                          <Fragment key={`menu-item-wrapper-${tenantData?.tenant_name}`}>
                            {
                              (!tenantData?.can_access_dashboard)
                                ? (
                                  <OverlayTriggerTooltip key={`menu-item-can-access-dashboard-${tenantData?.tenant_name}`} placement="left" description={!tenantData?.can_access_dashboard ? "You Don't have access of this organisation." : ''}>
                                    <MenuItem
                                      key={`menu-item-${tenantData?.tenant_name}`}
                                      id={`change-tenant-${tenantData?.tenant_name}`}
                                      className="user-menu-item-change-tenant"
                                      disabled
                                    >
                                      <div className="text-black flex-box">
                                        <img src={logo?.signed_url || distributoLogo} alt={organisationName} />
                                        &nbsp;
                                        <div>
                                          {organisationName || ''}
                                          {!tenantData?.can_access_dashboard ? (
                                            <>
                                              <br />
                                              <small className="text-right">(Mobile Only Access)</small>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                    </MenuItem>
                                  </OverlayTriggerTooltip>
                                )
                                : (
                                  <MenuItem
                                    key={`menu-item-${tenantData?.tenant_name}`}
                                    onClick={() => {
                                      const domain = getDomain();
                                      const targetUrl = getSubDomainTargetUrl(tenantData.tenant_name);
                                      setCookie(RECENT_USED_TENANT, tenantData.tenant_name, DEFAULT_COOKIE_STORAGE_PERIOD, domain);
                                      window.location.href = targetUrl;
                                    }}
                                    id={`change-tenant-${tenantData?.tenant_name}`}
                                    className="user-menu-item-change-tenant"
                                  >
                                    <div className="text-black">
                                      <img src={logo?.signed_url || distributoLogo} alt={organisationName} />
                                      &nbsp;
                                      {organisationName || ''}
                                    </div>
                                  </MenuItem>
                                )
                            }
                            <MenuItem key={`menu-item-divider-${tenantData?.tenant_name}`} divider className="listing-action-menu-item-divider" />
                          </Fragment>
                        );
                      })
                    }
                  </>
                ) : null
            }
            <MenuItem divider className="listing-action-menu-item-divider" />
            <MenuItem className="listing-action-menu-item-header my-organisations" onClick={() => this.myOrganisationsFunc()}>
              My Organisations
            </MenuItem>
            <MenuItem divider className="listing-action-menu-item-divider" />
          </>
          <MenuItem className="listing-action-menu-item-header my-account">
            My Account
          </MenuItem>
          <MenuItem divider className="listing-action-menu-item-divider" />
          <MenuItem onClick={updateProfile} id="update_profile" className="user-menu-item-update-profile">
            <div className="text-black">
              <img src={UPDATE_PROFILE} alt="Update Profile" />
              &nbsp;
              Update Profile
            </div>
          </MenuItem>
          <MenuItem divider className="listing-action-menu-item-divider" />
          <MenuItem onClick={changePassword} id="change_password" className="user-menu-item-change-password">
            <div className="text-black">
              <img src={CHANGE_PASSWORD} alt="Change Password" />
              &nbsp;
              Change Password
            </div>
          </MenuItem>
          <MenuItem
            onClick={this.logoutUser}
            id="logout"
            data-cy="logout"
            className="user-menu-item-sign-out"
          >
            <img src={LOGOUT} alt="logout" />
            &nbsp;
            Sign Out
          </MenuItem>
        </NavDropdown>
      </OverlayTriggerTooltip>
    );
  }
}

UserMenu.propTypes = {
  logout: func.isRequired,
  auth: object,
};

UserMenu.defaultProps = {
  auth: {},
};

function mapStateToProps(state) {
  const { auth } = state;
  return { auth };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
