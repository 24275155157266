import { HOME_BREADCRUMB_LISTING } from './common';


export const STOCK_ENTRY = 'STOCK_ENTRY';
export const SET_STOCK_ENTRIES = 'SET_STOCK_ENTRIES';
export const ADD_STOCK_ENTRY = 'ADD_STOCK_ENTRY';
export const STOCK_ENTRY_FETCHED = 'STOCK_ENTRY_FETCHED';
export const STOCK_ENTRY_UPDATED = 'STOCK_ENTRY_UPDATED';
export const STOCK_ENTRY_DELETED = 'STOCK_ENTRY_DELETED';
export const AUTOCOMPLETE_STOCK_ENTRIES = 'AUTOCOMPLETE_STOCK_ENTRIES';

export const OPEN_STOCK_ENTRY_FORM_MODAL = 'OPEN_STOCK_ENTRY_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Stock Entries';
export const singularTitle = 'Stock Entry';
export const moduleName = 'stock_entries';
export const baseUrl = '/stock_entries';
export const redirectUrl = '/stock_entries';
export const listingDataKey = 'stock_entries';
export const itemDataKey = 'stock_entry';
export const permissionKey = moduleName;

export const stockEntryConstants = {
  item: STOCK_ENTRY,
  setItems: SET_STOCK_ENTRIES,
  addItem: ADD_STOCK_ENTRY,
  setItem: STOCK_ENTRY_FETCHED,
  updateItem: STOCK_ENTRY_UPDATED,
  deleteItem: STOCK_ENTRY_DELETED,
  autocompleteItems: AUTOCOMPLETE_STOCK_ENTRIES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};

export const advanceSearchParams = {
  stock_logable_type_eq: '',
  ref_no_cont: '',
  stock_product_brand_id_in: [],
  stock_warehouse_id_in: [],
  stock_product_product_category_id_in: [],
  stock_product_id_in: [],
};

export const defaultSearchParams = {
  identifier_value_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['brands', 'warehouses', 'product_categories', 'products'];

export const Title = 'Stock Entries';
export const TargetBaseUrl = 'stock_entries';
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
