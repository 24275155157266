import { SET_SMS_STATISTICS, FETCHING_SMS_STATISTICS } from '@constants/sms_statistics';

export const initialState = {
  items: [],
  current_page: 1,
  per_page: 10,
  total_count: 0,
  isError: false,
  errors: {},
  isFetching: false,
  search_params: { mobile_cont: '' },
};

export default function SMSStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCHING_SMS_STATISTICS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SET_SMS_STATISTICS: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
}
