import {
  ADD_SUPPLIER_ADDRESS, SUPPLIER_ADDRESS_UPDATED, SUPPLIER_ADDRESS_DELETED, SET_SUPPLIER_ADDRESSES,
  SUPPLIER_ADDRESS_FETCHED,
} from '@constants/supplier_addresses';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  setItems, deleteItem, fetchItem,
} from '@reducers/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 8,
  sort_by: '',
  sort_order: '',
  search_params: {
    line_1_cont: '',
  },
};

export default function supplierAddresses(state = initialState, action) {
  switch (action.type) {
    case SET_SUPPLIER_ADDRESSES: return setItems(state, action);
    case SUPPLIER_ADDRESS_FETCHED: return fetchItem(state, action);
    case ADD_SUPPLIER_ADDRESS: {
      const isDefaultAddress = action?.item?.default || false;
      const items = state?.items?.map((address) => ({
        ...address, default: isDefaultAddress ? false : address.default,
      }));
      return { ...state, items: [...items, action?.item] };
    }
    case SUPPLIER_ADDRESS_UPDATED: {
      const items = state?.items?.map((address) => {
        if (address?.id !== action?.item?.id) return { ...address, default: false };
        return { ...address, ...action?.item };
      });
      return { ...state, items };
    }
    case SUPPLIER_ADDRESS_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
