import { HOME_BREADCRUMB_LISTING } from './common';

export const PRINT_FORMAT = 'PRINT_FORMAT';
export const SET_PRINT_FORMATS = 'SET_PRINT_FORMATS';
export const RESET_PRINT_FORMATS = 'RESET_PRINT_FORMATS';
export const ADD_PRINT_FORMAT = 'ADD_PRINT_FORMAT';
export const DUPLICATE_PRINT_FORMAT = 'DUPLICATE_PRINT_FORMAT';
export const PRINT_FORMAT_FETCHED = 'PRINT_FORMAT_FETCHED';
export const PRINT_FORMAT_CREATED = 'PRINT_FORMAT_CREATED';
export const PRINT_FORMAT_UPDATED = 'PRINT_FORMAT_UPDATED';
export const PRINT_FORMAT_DELETED = 'PRINT_FORMAT_DELETED';
export const PRINT_FORMAT_EDIT = 'PRINT_FORMAT_EDIT';
export const PRINT_FORMAT_PREVIEW = 'PRINT_FORMAT_PREVIEW';

export const SET_SETTABLE_CATEGORY = 'SET_SETTABLE_CATEGORY';
export const FETCH_SETTABLE_CATEGORY = 'FETCH_SETTABLE_CATEGORY';
export const FETCH_PRINT_FORMAT_LIST = 'FETCH_PRINT_FORMAT_LIST';
export const FETCH_PRINT_FORMAT = 'FETCH_PRINT_FORMAT';
export const FETCH_NEW_PRINT_FORMAT = 'FETCH_NEW_PRINT_FORMAT';
export const FETCH_DUPLICATE_PRINT_FORMAT = 'FETCH_DUPLICATE_PRINT_FORMAT';
export const CREATE_PRINT_FORMAT = 'CREATE_PRINT_FORMAT';
export const UPDATE_PRINT_FORMAT = 'UPDATE_PRINT_FORMAT';
export const DELETE_PRINT_FORMAT = 'DELETE_PRINT_FORMAT';
export const FETCH_PRINT_FORMAT_PREVIEW = 'FETCH_PRINT_FORMAT_PREVIEW';

export const title = 'Print Formats';
export const singularTitle = 'Print Format';
export const moduleName = 'print_formats';
export const baseUrl = '/print_formats';
export const redirectUrl = '/print_formats';
export const listingDataKey = 'print_formats';
export const itemDataKey = 'print_format';

export const printFormatConstants = {
  item: PRINT_FORMAT,
  setItems: SET_PRINT_FORMATS,
  addItem: ADD_PRINT_FORMAT,
  setItem: PRINT_FORMAT_FETCHED,
  updateItem: PRINT_FORMAT_UPDATED,
  deleteItem: PRINT_FORMAT_DELETED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// Breadcrumb
export const PRINT_FORMATS_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Settings', target: '', active: true }]);
