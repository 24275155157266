import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_VOUCHER_BOOKS, ADD_VOUCHER_BOOK, VOUCHER_BOOK_FETCHED,
  VOUCHER_BOOK_UPDATED, VOUCHER_BOOK_DELETED,
  defaultSearchParams, moduleName,
} from '@constants/voucher_books';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'resource_type',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  available_resource_types: [],
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function voucherBooksReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VOUCHER_BOOKS: return setItems(state, action);
    case ADD_VOUCHER_BOOK: return addItem(state, action);
    case VOUCHER_BOOK_FETCHED: return fetchItem(state, action);
    case VOUCHER_BOOK_UPDATED: return updateItem(state, action);
    case VOUCHER_BOOK_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
