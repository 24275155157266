export const SUCCESS = 'success';
export const FAILED = 'failed';
export const TIMEDOUT = 'timedout';
export const CANCELLED = 'cancelled';

export const TRIAL = 'trial';
export const MONTHLY = 'monthly';
export const HALF_YEARLY = 'half_yearly';
export const YEARLY = 'yearly';

export const FREE = 'Free';
export const BASIC = 'Basic';
export const ADVANCED = 'Advanced';
export const ENTERPRISE = 'Enterprise';

export const PLAN_1 = 'plan_1';
export const PLAN_2 = 'plan_2';
export const PLAN_3 = 'plan_3';
export const PLAN_4 = 'plan_4';

export const planCodeMappingWithName = {
  [PLAN_1]: FREE,
  [PLAN_2]: BASIC,
  [PLAN_3]: ADVANCED,
  [PLAN_4]: ENTERPRISE,
};

export const SUBSCRIPTION_ACTION = 'action';
export const RENEW_SUBSCRIPTION = 'renew';
export const UPGRADE_SUBSCRIPTION = 'upgrade';
export const NEW_SUBSCRIPTION = 'new';
export const INCREASE_USER_COUNT = 'add';

export const SMS_PACK = 'sms_pack';

export const OPEN_RENEW_SUBSCRIPTION_MODAL = 'OPEN_RENEW_SUBSCRIPTION_MODAL';
export const OPEN_UPGRADE_SUBSCRIPTION_MODAL = 'OPEN_UPGRADE_SUBSCRIPTION_MODAL';
export const OPEN_NEW_SUBSCRIPTION_MODAL = 'OPEN_NEW_SUBSCRIPTION_MODAL';
export const OPEN_INCREASE_USER_COUNT_MODAL = 'OPEN_INCREASE_USER_COUNT_MODAL';
// export const OPEN_RAZORPAY_MODAL = 'OPEN_RAZORPAY_MODAL';
// export const OPEN_PAYMENT_VERIFICATION_MODAL = 'OPEN_PAYMENT_VERIFICATION_MODAL';

export const CREATE_SUBSCRIPTION_ORDER = 'CREATE_SUBSCRIPTION_ORDER';

export const FETCH_ACTIVE_PLAN = 'FETCH_ACTIVE_PLAN';
export const CREATE_NEW_SUBSCRIPTION_ORDER = 'CREATE_NEW_SUBSCRIPTION_ORDER';
export const SAVE_SUCCESS_PAYMENT_VERIFICATION_RESPONSE = 'SAVE_SUCCESS_PAYMENT_VERIFICATION_RESPONSE';
export const SAVE_FAILED_PAYMENT_VERIFICATION = 'SAVE_FAILED_PAYMENT_VERIFICATION';

export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';

export const ALL_ACTIVE_SUBSCRIPTIONS = 'ALL_ACTIVE_SUBSCRIPTIONS';

export const FETCHING_FEATURES = 'FETCHING_FEATURES';
export const HANDLE_FAILURE_FEATURES = 'HANDLE_FAILURE_FEATURES';
export const FETCH_FEATURES = 'FETCH_FEATURES';
export const SET_FEATURES = 'SET_FEATURES';

export const FETCHING_PLANS = 'FETCHING_PLANS';
export const HANDLE_FAILURE_PLANS = 'HANDLE_FAILURE_PLANS';
export const FETCH_PLANS = 'FETCH_PLANS';
export const SET_PLANS = 'SET_PLANS';

export const FETCHING_SUBSCRIPTION_ORDERS_HISTORY = 'FETCHING_SUBSCRIPTION_ORDERS_HISTORY';
export const HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY = 'HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY';
export const SET_SUBSCRIPTION_ORDERS_HISTORY = 'SET_SUBSCRIPTION_ORDERS_HISTORY';
export const FETCH_SUBSCRIPTIONS_ORDERS_HISTORY = 'FETCH_SUBSCRIPTIONS_ORDERS_HISTORY';

export const FETCH_SUBSCRIPTIONS_ORDERS_REVIEW = 'FETCH_SUBSCRIPTIONS_ORDERS_REVIEW';
export const FETCH_SUBSCRIPTIONS_ORDERS_DATA = 'FETCH_SUBSCRIPTIONS_ORDERS_DATA';

export const USER_LOGIN = 'user_login';
export const SMS_NOTIFICATIONS = 'sms_notifications';

export const FETCHING_SUBSCRIPTION_ORDER_VERIFICATION = 'FETCHING_SUBSCRIPTION_ORDER_VERIFICATION';

export const BULK_PDF_DOWNLOAD_ALLOWED_AT_ONCE = {
  [PLAN_1]: 1,
  [PLAN_2]: 5,
  [PLAN_3]: 50,
  [PLAN_4]: 50,
};
export const BULK_ACTIONS_ALLOWED_AT_ONCE = {
  [PLAN_1]: 1,
  [PLAN_2]: 5,
  [PLAN_3]: 50,
  [PLAN_4]: 50,
};
