import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { brandFilterNotApplyNotice, BRAND_ID_IN } from './common_constants';
import { fallBackDatetimeFormat } from './common';

// Line chart colors
export const LIGHT_GREEN = '#77ddd8';
export const DARK_GREEN = '#29a39d';
export const LIGHT_BLUE = '#3CA2E0';
export const DARK_BLUE = '#1f86c7';
export const LIGHT_RED = '#EF9A9A';
export const DARK_RED = '#FF8A80';

// DMS Brand Color
// export const LIGHT_GREEN = '#c1f0cc';
// export const DARK_GREEN = '#28a745';
// export const LIGHT_BLUE = '#d2d2f9';
// export const DARK_BLUE = '#5c5de8';
// export const LIGHT_RED = '#fcd2cf';
// export const DARK_RED = '#f44336';

export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const FINANCIAL_YEAR = 'financial_year'; // Financial year
export const SALES = 'SALES';
export const PAYMENTS = 'PAYMENTS';
export const DUE_INVOICE = 'DUE_INVOICE';
export const SALES_CHART = 'sales';
export const PAYMENT_CHART = 'payment';
export const DUE_INVOICE_CHART = 'due_invoice';
export const ALL_CHART = 'all';

// Chart permission keys
export const SALES_CHART_PERMISSION_KEY = 'sales_plot';
export const PAYMENTS_CHART_PERMISSION_KEY = 'payments_plot';
export const DUE_INVOICES_CHART_PERMISSION_KEY = 'due_invoices_plot';

// Charts constants.
export const CHART = 'CHART';
export const SET_SALES_CHART = 'SET_SALES_CHART';
export const SET_PAYMENTS_CHART = 'SET_PAYMENTS_CHART';
export const SET_DUE_INVOICE_CHART = 'SET_DUE_INVOICE_CHART';

export const AMOUNT = 'amount';
export const ACCOUNTED_AMOUNT = 'accounted_amount';
export const TOTAL_SALES_EXCL_TAX = 'total_sales_excl_tax';
export const TOTAL_SALES_INCL_TAX = 'total_sales_incl_tax';
export const ACCOUNTED_TOTAL_SALES_EXCL_TAX = 'accounted_total_sales_excl_tax';
export const ACCOUNTED_TOTAL_SALES_INCL_TAX = 'accounted_total_sales_incl_tax';

export const SALES_CHART_BARS = [
  { dataKey: ACCOUNTED_TOTAL_SALES_EXCL_TAX, stroke: DARK_GREEN },
  { dataKey: ACCOUNTED_TOTAL_SALES_INCL_TAX, stroke: DARK_BLUE },
  { dataKey: TOTAL_SALES_EXCL_TAX, stroke: LIGHT_GREEN },
  { dataKey: TOTAL_SALES_INCL_TAX, stroke: LIGHT_BLUE },
];
export const PAYMENT_CHART_BARS = [
  { dataKey: ACCOUNTED_AMOUNT, stroke: DARK_GREEN }, { dataKey: AMOUNT, stroke: LIGHT_GREEN },
];
export const DUE_INVOICE_CHART_BARS = [{ dataKey: AMOUNT, stroke: DARK_RED }];

// Charts color palette referred from SAP Fiori Design Guidelines
// This is a 2D array, which has different colors in one direction
// and their variantions in other direction
export const CHARTS_COLOR_PALETTE = [
  ['#0C50A8', '#296CC2', '#4885D1', '#6B9EDF'],
  ['#922800', '#B94013', '#E05E2E', '#F38056'],
  ['#106B37', '#23894F', '#3E9C67', '#63B687'],
  ['#A60035', '#DC0147', '#E52F69', '#EA5283'],
  ['#0C697B', '#1E8395', '#3894A5', '#5BB0C0'],
  ['#4D0D96', '#6825B5', '#7F43C4', '#9965D5'],
  ['#274755', '#3E5E6D', '#597581', '#7B9099'],
  ['#0B0BC7', '#1D1DEC', '#4040F1', '#4040F1'],
  ['#A61317', '#D32A2E', '#E75054', '#FD7A7D'],
  ['#750058', '#930A71', '#AF218C', '#BC3F9D'],
  ['#062850', '#153C6A', '#245085', '#3D6492'],
];

// Returns an array of colors
export const getColors = ({ count = 1, colorIntensity = 2, startIndex = 0 }) => {
  const colors = [];
  let startingIndex = startIndex;
  let totalColors = count;
  // Check if requested number of colors can be provided from the startIndex.
  if (startIndex + count > CHARTS_COLOR_PALETTE.length) {
    // Reset index to 0 if not possible.
    startingIndex = 0;
    // Check if total number if colors requested are more than we have in the palette.
    if (count > CHARTS_COLOR_PALETTE.length) {
      // Provide max possible colors.
      totalColors = CHARTS_COLOR_PALETTE.length;
    }
  }
  for (let i = startingIndex; i < (totalColors + startingIndex); i += 1) {
    colors.push(CHARTS_COLOR_PALETTE[i][colorIntensity]);
  }
  return colors;
};

export const getColorsStartIndex = (chartKey) => {
  switch (chartKey) {
    case SALES:
      return 0;
    case PAYMENTS:
      return 4;
    case DUE_INVOICE:
      return 7;
    default:
      return 0;
  }
};

export const getChartNotice = (chartKey, searchParams) => {
  let notice = '';
  switch (chartKey) {
    case PAYMENTS:
      if (!isEmpty(searchParams[BRAND_ID_IN])) { notice = brandFilterNotApplyNotice; }
      break;

    default:
      break;
  }
  return notice;
};

// Charts initial common states use in chart reducer.
export const INITIAL_STATE = {
  period: 'week',
  xAxisDataKey: 'day',
  data: [],
  start_date: dayjs().startOf('week').format(fallBackDatetimeFormat),
  end_date: dayjs().endOf('week').format(fallBackDatetimeFormat),
};

export const getxAxisDataKey = (period) => {
  let xAxisDataKey = 'day';
  if (['year', 'financial_year'].includes(period)) {
    xAxisDataKey = 'month';
  } else if (period === 'month') {
    xAxisDataKey = 'date';
  }

  return xAxisDataKey;
};
