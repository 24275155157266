import { HOME_BREADCRUMB_LISTING } from './common';

export const STOCK = 'STOCK';
export const SET_STOCKS = 'SET_STOCKS';
export const ADD_STOCK = 'ADD_STOCK';
export const STOCK_FETCHED = 'STOCK_FETCHED';
export const STOCK_UPDATED = 'STOCK_UPDATED';
export const STOCK_DELETED = 'STOCK_DELETED';
export const AUTOCOMPLETE_STOCKS = 'AUTOCOMPLETE_STOCKS';

export const title = 'Stocks';
export const singularTitle = 'Stock';
export const moduleName = 'stocks';
export const baseUrl = '/stocks';
export const redirectUrl = '/stocks';
export const listingDataKey = 'stocks';
export const itemDataKey = 'stock';

export const stockConstants = {
  item: STOCK,
  setItems: SET_STOCKS,
  addItem: ADD_STOCK,
  setItem: STOCK_FETCHED,
  updateItem: STOCK_UPDATED,
  deleteItem: STOCK_DELETED,
  autocompleteItems: AUTOCOMPLETE_STOCKS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const STOCK_LOGABLE_TYPE = [
  { label: 'Invoice Item', value: 'InvoiceItem' },
  { label: 'Payment Item', value: 'PaymentItem' },
  { label: 'Return Item', value: 'ReturnItem' },
  { label: 'Return Item Credit Note Item', value: 'ReturnItemCreditNoteItem' },
  { label: 'Credit Note Item', value: 'CreditNoteItem' },
  { label: 'Debit Note Item', value: 'DebitNoteItem' },
  { label: 'Purchase Invoice Item', value: 'PurchaseInvoiceItem' },
  { label: 'Purchase Return Item', value: 'PurchaseReturnItem' },
  { label: 'Purchase Return Item Debit Note Item', value: 'PurchaseReturnItemDebitNoteItem' },
  { label: 'Stock Transfer Item', value: 'StockTransferItem' },
  { label: 'Payment Out Item', value: 'PaymentOutItem' },
];

export const advanceSearchParams = {
  warehouse_id_in: [],
  product_brand_id_in: [],
  product_product_category_id_in: [],
  product_id_in: [],
};

export const defaultSearchParams = {
  product_sku_or_product_name_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['brands', 'warehouses', 'product_categories', 'products'];

export const Title = 'Stocks';
export const TargetBaseUrl = 'stocks';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const BlockedInventoryExpl = 'Quantity in uninvoiced orders';

export const handledErrorKeys = {
  master: ['product', 'product_id', 'warehouse', 'warehouse_id'],
  opening_stock_entry: ['ref_no', 'date', 'quantity', 'price', 'inventory_stock_entry_batches', 'inventory_stock_entry_pieces'],
};
