import React, { Component } from 'react';
import { func } from 'prop-types';
import { logEvent, CategoryEnum, ActionEnum } from '@utils/GAUtils';

class ToggleSidebarButton extends Component {
  toggleSidebar = () => {
    const isSidebarMini = document.body.className.match('sidebar-mini');
    const { setSidebarMinimize } = this.props;
    logEvent(CategoryEnum.WINDOW, ActionEnum.SIDEBAR_TOGGLE, isSidebarMini ? 'expand' : 'collapse');
    if (isSidebarMini) {
      document.body.classList.remove('sidebar-mini');
      setSidebarMinimize(false);
      // this.handleFixedFooter(false);
    } else {
      document.body.classList.add('sidebar-mini');
      setSidebarMinimize(true);
      // this.handleFixedFooter(true);
    }
  }

  // handleFixedFooter = (isSidenavMinimize) => {
  //   const form_action_footer = document.getElementById('fixed-action-footer');
  //   if (form_action_footer) {
  //     if (isSidenavMinimize) {
  //       form_action_footer.classList.remove('fixed-action-footer');
  //       form_action_footer.classList.add('fixed-action-footer-for-min-sidebar');
  //     } else {
  //       form_action_footer.classList.remove('fixed-action-footer-for-min-sidebar');
  //       form_action_footer.classList.add('fixed-action-footer');
  //     }
  //   }
  // }

  render() {
    return (
      <div className="navbar-minimize">
        <button
          id="minimizeSidebar"
          onClick={this.toggleSidebar}
          className="btn btn-default btn-fill btn-round btn-icon"
          type="button"
        >
          <i className="fa fa-angle-left visible-on-sidebar-regular" />
          <i className="fa fa-bars visible-on-sidebar-regular" />
          <i className="fa fa-bars visible-on-sidebar-mini" />
        </button>
      </div>
    );
  }
}

ToggleSidebarButton.propTypes = {
  setSidebarMinimize: func.isRequired,
};

export default ToggleSidebarButton;
