import {
  ADD_NOTIFICATION, CLEAR_NOTIFICATION, SIDENAV_MINIMIZE, IS_ADS_BLOCK_DETECTED,
  CRASH_DIALOG_VISIBLITY, SET_ACTIVE_MODULE,
} from '@constants/root';

export const initialState = {
  notification: {
    title: '',
    message: '',
    level: '',
    renderView: true,
  },
  isSidenavMinimize: false,
  isAdsBlockDetected: false,
  crashDialogVisibility: false,
  activeModule: '',
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: action.title,
          message: action.message,
          level: action.level,
          renderView: action.renderView,
        },
      };
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: '',
          message: '',
          level: '',
          renderView: action.renderView,
        },
      };
    }
    case SIDENAV_MINIMIZE: {
      return { ...state, isSidenavMinimize: action.isSidenavMinimize };
    }
    case IS_ADS_BLOCK_DETECTED: {
      return { ...state, isAdsBlockDetected: action.isAdsBlockDetected };
    }
    case CRASH_DIALOG_VISIBLITY: {
      return { ...state, crashDialogVisibility: action.crashDialogVisibility };
    }
    case SET_ACTIVE_MODULE: {
      return { ...state, activeModule: action?.activeModule };
    }
    default:
      return state;
  }
}
