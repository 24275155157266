import React, { useEffect } from 'react';
import { map, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { MenuItem } from 'react-bootstrap';
import { downloadPdf as downloadFileAction } from '@saga/root';
import { FETCH_PRINT_FORMAT_LIST } from '@constants/print_formats';
import { sagaPreWatcherAction } from '@actions/common';
import PrintFormatDropdownItem from './print_format_dropdown_item';

interface DownloadFileActionProps {
  url: string;
  params: Record<string, unknown>;
  redirectTo: string;
  identifier: string;
  shouldOpen: boolean;
  shouldDownloadForGet: boolean;
}

interface PrintFormatDropdownListingProps {
  fetchPrintFormatConfigurationApi?: boolean;
  url: string;
  params: Record<string, any>;
  redirectUrl: string;
  identifier: string;
  moduleName: string;
  printFormats: Record<string, any>;
  sagaPreWatcherAction({ type: string, payload: any }, resolve: any, reject: any): any;
  downloadFile({
    url, params, redirectTo, identifier, shouldOpen, shouldDownloadForGet,
  }: DownloadFileActionProps): any;
}

const defaultProps = {
  fetchPrintFormatConfigurationApi: false,
};

const PrintFormatDropdownListing = (props: PrintFormatDropdownListingProps) => {
  const fetchPrintFormatList = () => {
    const { sagaPreWatcherAction, moduleName } = props;
    new Promise((resolve, reject) => {
      sagaPreWatcherAction({
        type: FETCH_PRINT_FORMAT_LIST,
        payload: {
          url: `/configuration/formats/${moduleName}`,
          settableCategoryName: `${moduleName}`,
        },
      }, resolve, reject);
    }).then((response) => response).catch((error) => error);
  };

  useEffect(() => {
    // Note: We can't fetch API If PrintFormatDropdownListing component render from listing components (ex. orders, invoices, payments, credit_notes, debit_notes, etc.).
    // For listing page we already fetch api from index container so there is no need to fire api from here.
    if (props?.fetchPrintFormatConfigurationApi) {
      fetchPrintFormatList();
    }
  }, []);

  const { printFormats, moduleName } = props;
  const listOfPrintFormatsForModule = (printFormats?.[moduleName]) || [];
  const numberOfPrintFormats = (listOfPrintFormatsForModule?.length) || 0;

  const {
    url, params, redirectUrl, identifier, downloadFile,
  } = props;

  if (!isEmpty(listOfPrintFormatsForModule)) {
    return (
      <>
        <MenuItem eventKey={numberOfPrintFormats + 1} className="listing-action-menu-item-header">
          Download
        </MenuItem>
        <MenuItem divider className="listing-action-menu-item-divider mt5" />
        {
          map(listOfPrintFormatsForModule, (printFormat: any, index) => (
            <PrintFormatDropdownItem
              key={printFormat?.id}
              idx={index}
              url={url}
              params={params}
              redirectUrl={redirectUrl}
              identifier={identifier}
              printFormat={printFormat}
              downloadFile={downloadFile}
            />
          ))
        }
      </>
    );
  }
  return <div />;
};

PrintFormatDropdownListing.defaultProps = defaultProps;

function mapStateToProps(state: { print_formats: Record<string, unknown>; }) {
  const { print_formats } = state;
  const { printFormats } = print_formats;
  return {
    printFormats,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({
    sagaPreWatcherAction,
    downloadFile: downloadFileAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintFormatDropdownListing);
