import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { SET_CREDIT_LEEWAYS, CREDIT_LEEWAY_UPDATED } from '../constants/credit_leeways';
import { setItems, updateItem } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'position',
  sort_order: 'asc',
  search_params: {},
};

export default function creditLeeways(state = initialState, action) {
  switch (action.type) {
    case SET_CREDIT_LEEWAYS: return setItems(state, action);
    case CREDIT_LEEWAY_UPDATED: return updateItem(state, action);
    default:
      return state;
  }
}
