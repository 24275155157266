import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  ADD_DEBIT_REASON, DEBIT_REASON_FETCHED, DEBIT_REASON_UPDATED, DEBIT_REASON_DELETED,
  SET_DEBIT_REASONS, AUTOCOMPLETE_DEBIT_REASONS, defaultSearchParams, moduleName,
} from '../constants/debit_reasons';
import {
  setItems, autocomplete, addItem, fetchItem, updateItem, deleteItem,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function debitReasons(state = initialState, action) {
  switch (action.type) {
    case SET_DEBIT_REASONS: return setItems(state, action);
    case ADD_DEBIT_REASON: return addItem(state, action);
    case DEBIT_REASON_FETCHED: return fetchItem(state, action);
    case DEBIT_REASON_UPDATED: return updateItem(state, action);
    case DEBIT_REASON_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_DEBIT_REASONS: return autocomplete(state, action);
    default:
      return state;
  }
}
