import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_DEFAULT_DAC_ROLES, ADD_DEFAULT_DAC_ROLE, DEFAULT_DAC_ROLE_FETCHED,
  DEFAULT_DAC_ROLE_UPDATED, DEFAULT_DAC_ROLE_DELETED, SET_DEFAULT_DAC_ROLE_TEMPLATE,
  defaultSearchParams, moduleName,
} from '../constants/default_dac_roles';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'resource_type',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  available_resource_types: [],
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function defaultDacRoles(state = initialState, action) {
  switch (action.type) {
    case SET_DEFAULT_DAC_ROLES: return setItems(state, action);
    case ADD_DEFAULT_DAC_ROLE: return addItem(state, action);
    case DEFAULT_DAC_ROLE_FETCHED: return fetchItem(state, action);
    case DEFAULT_DAC_ROLE_UPDATED: return updateItem(state, action);
    case DEFAULT_DAC_ROLE_DELETED: return deleteItem(state, action);
    case SET_DEFAULT_DAC_ROLE_TEMPLATE: {
      return { ...state, available_resource_types: action.available_resource_types };
    }
    default:
      return state;
  }
}
