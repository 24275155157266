import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for beat_route index page.
const BeatRoutes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBeatRoutesIndex" */ '../containers/beat_routes/Index'),
  loading: Loading,
});

// We use loadable for beat_route new and edit page.
const BeatRouteCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBeatRoutesCredate" */ '../containers/beat_routes/Credate'),
  loading: Loading,
});

// We use loadable for beat route show page.
const BeatRouteShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBeatRoutesShow" */ '../containers/beat_routes/Show'),
  loading: Loading,
});

const BeatRouteRoutes = [
  {
    path: '/beat_routes', name: 'BeatRoutes', component: BeatRoutes,
  },
  {
    path: '/beat_routes/new', name: 'beat_routes/new', component: BeatRouteCredate,
  },
  {
    path: '/beat_routes/:id/edit', name: 'beat_routes/edit', component: BeatRouteCredate,
  },
  {
    path: '/beat_routes/:id', name: 'BeatRoute Show', component: BeatRouteShow,
  },
];

export default BeatRouteRoutes;
