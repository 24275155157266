import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const explore = 'explore';
export const onboard = 'onboard';
export const help = 'help';
export const faq = 'faq';
export const CONTENT_TYPES = [explore, onboard, help];

export const Module = 'module';
export const Page = 'page';
export const Section = 'section';
export const Field = 'field';
export const CONTENT_TAG_TYPES = [Module, Page, Section, Field];

export const show = 'show';
export const form = 'form';
export const index = 'index';

export const HELP_PAGES = [show, form, index];

export const CONTENT = 'CONTENT';
export const FETCH_CONTENTS = 'FETCH_CONTENTS';
export const SET_CONTENTS = 'SET_CONTENTS';

export const FETCH_CONTENT = 'FETCH_CONTENT';
export const SET_CONTENT = 'SET_CONTENT';

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Help', target: '/help', active: false }]);
export const ShowBreadcrumbListing = ({
  BREADCRUMB_LISTING, id, TargetUrl,
}) => BREADCRUMB_LISTING.concat([{ title: id, target: TargetUrl, active: true }]);
