import React from 'react';
import { string, bool, func } from 'prop-types';
import { Modal } from 'react-bootstrap';

const FormCancelConfirmationModal = ({
  title, content, isOpen, closeModal, handleOnClick, cancelBtnName, submitBtnName,
}) => (
  <Modal
    show={isOpen}
    onHide={closeModal}
  >
    <Modal.Header>
      <Modal.Title className="text-muted">
        {title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="full_description">
          <div className="col-md-12">
            <h5>{content}</h5>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-sm btn-primary" onClick={handleOnClick} type="button">{submitBtnName}</button>
      <button className="btn btn-sm btn-primary-outline" onClick={closeModal} type="button">{cancelBtnName}</button>
    </Modal.Footer>
  </Modal>
);


FormCancelConfirmationModal.propTypes = {
  title: string,
  content: string,
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  handleOnClick: func.isRequired,
  cancelBtnName: string,
  submitBtnName: string,
};

FormCancelConfirmationModal.defaultProps = {
  title: 'Are you sure to proceed?',
  content: 'You have unsaved changes. Do you want to cancel and go back?',
  cancelBtnName: 'NO',
  submitBtnName: 'YES',
};

export default FormCancelConfirmationModal;
