import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchPurchaseOrders, updateOrderItemPurchaseOrderItems } from '@api/purchase_orders';
import {
  moduleName, FETCH_PURCHASE_ORDERS, UPDATE_ORDER_ITEM_PURCHASE_ORDER_ITEMS,
  SET_PURCHASE_ORDER_ITEMS,
} from '@constants/purchase_orders';
import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

// Fetch api to save/create new item.
export function savePurchaseOrder(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      purchase_order: data,
    },
  })
    .then((response) => {
      const { purchase_order } = response.data;
      dispatch(addItem({ moduleName, item: purchase_order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get item data.
export function fetchPurchaseOrder(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { purchase_order } = response.data;
      dispatch(setItem({ moduleName, item: purchase_order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Fetch api to update data.
export function updatePurchaseOrder(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      purchase_order: data,
    },
  })
    .then((response) => {
      const { purchase_order } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Fetch api to delete item.
export function deletePurchaseOrder(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: {
      state,
    },
  })
    .then((response) => {
      const { purchase_order } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_order }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompletePurchaseOrders(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchPurchaseOrdersSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchPurchaseOrders({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { purchase_orders, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: purchase_orders,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}
function* updateOrderItemPurchaseOrderItemsSagaSideEffect(action: any) {
  const {
    purchaseOrderID, purchaseOrderItemID, data,
  } = action;

  try {
    const response = yield call(updateOrderItemPurchaseOrderItems({
      purchaseOrderID, purchaseOrderItemID, data,
    }));
    yield put({
      type: SET_PURCHASE_ORDER_ITEMS,
      payload: {
        purchaseOrderID,
        purchaseOrderItemID,
        purchase_order_item: response?.data?.purchase_order_item || {},
      },
    });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function duplicatePurchaseOrder(purchaseOrderId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    // baseURL: `${BASE_URL}`,
    params: {
      purchase_order_id: purchaseOrderId,
    },
  })
    .then((response) => {
      const { purchase_order: purchaseOrder } = response?.data || {};
      dispatch(setItem({ moduleName, item: purchaseOrder }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function* purchaseOrderWatcher() {
  yield takeLatest(FETCH_PURCHASE_ORDERS, fetchPurchaseOrdersSagaSideEffect);
  yield takeLatest(UPDATE_ORDER_ITEM_PURCHASE_ORDER_ITEMS,
    updateOrderItemPurchaseOrderItemsSagaSideEffect);
}
