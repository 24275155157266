import React, { Component } from 'react';
import Loadable from 'react-loadable';
import {
  map, omit, merge, unionBy, find, isEqual,
} from 'lodash';
import {
  func, array, shape, bool,
} from 'prop-types';
import classnames from 'classnames';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import {
  BREADCRUMB_LISTING, NEW_BREADCRUMB_LISTING, TargetBaseUrl, FORM_INFORMATION_TITLE, baseUrl,
  handledErrorKeys, moduleName,
} from '@constants/orders';
import { AUTOCOMPLETE_CUSTOMER, DECIMAL_NUMBER_REGEX, SUBMIT_FORM_LOADER } from '@constants/common';
import { ORDER_RESOURCE_TYPE as RESOURCE_TYPE } from '@constants/custom_fields';
import { ADD_CUSTOMER, AUTOCOMPLETE_ACTIVE_CUSTOMERS } from '@constants/customers';
import { AUTOCOMPLETE_ORDERABLE_PRODUCTS } from '@constants/products';
import {
  editBreadcrumbListing, getItem, setIndexToNestedItems, getNextIndexFromItems, resetErrors,
  doesErrorExistOnField, getFieldErrors, selectedValueSanitizer, sanitizeCustomFieldsData,
  addPositionAttrToNestedFormItems, getI18nFields, getTenantLocalDateTime, validateNumberValue,
} from '@helpers/common_helper';
import DMSBasicCard from '@component/common/cards/DMSBasicCard';
import FormSubmitLoader from '@component/common/FormSubmitLoader';
import FormCancelConfirmation from '@component/common/FormCancelConfirmation';
import DMSSelect from '@component/common/DMSSelect';
import { getIdFromQuickAddModuleParams } from '@component/common/quick_add_module/helper';
import FormFixedFooter from '@component/common/FormFixedFooter';
import DMSDatePicker from '@component/common/dms_datepicker';
import EligibleOffers from '@component/common/offers/EligibleOffers';
import GenericFormComponent from '@component/common/form_components/form';
import FormFieldLabel from '@component/common/FormFieldLabel';
// SETUP-SECTION unhandled errors
import { processDeepNestedBackendErrors } from '@component/common/DMSFlashMessage/helper';
import { getNestedFormName } from '@component/common/DMSFlashMessage/handledErrorKeys';
import FormSectionTitle from '@component/common/form_components/form_section_title';
import InputFieldWithCheckbox from '@component/common/form_components/input_field_with_checkbox';

import {
  CategoryEnum, logEvent, logTiming, ActionEnum,
} from '@utils/GAUtils';
import OrderItemNestedForm from '@component/orders/OrderItemNestedForm';
import { ADD_ORDER_TERM } from '@constants/order_terms';
// !SETUP-SECTION unhandled errors

const gstinDetails = getI18nFields('customers', 'gstin') || {};

const CustomFieldsRenderer = Loadable({
  loader: () => import(/* webpackChunkName: "ComponentsCustomFieldsRenderer" */ '@component/common/custom_fields/custom_fields_renderer'),
  loading: () => null,
});

const CustomFieldsIndex = Loadable({
  loader: () => import(/* webpackChunkName: "ComponentsCustomFieldsIndex" */ '@component/common/custom_fields/custom_fields_index'),
  loading: () => null,
});

const getGSTIN = (customers = [], customerId = '') => {
  const customer = find(customers, { id: customerId });
  const gstin = customer?.gstin || '';
  return gstin;
};

const removeUnwantedAttributesFromOrder = ({ updated_order }) => {
  let order = updated_order;
  const remove_attribute_from_order = ['order_items', 'can_read', 'can_update', 'can_destroy', 'price_localized',
    'state', 'ip_address', 'item_total', 'discount_total', 'tax_total', 'grand_total',
    'round_total', 'item_total_localized', 'discount_total_localized', 'tax_total_localized', 'grand_total_localized',
    'round_total_localized', 'date_formatted', 'time_formatted', 'shipping_address_label', 'billing_address_label'];
  map(remove_attribute_from_order, (attr) => {
    order = omit(order, attr);
  });
  return order;
};

const description = () => (
  <div>
    Kindly Note: Assigning specific sequence to an order
    <br />
    will also impact the future orders sequence especially
    <br />
    if the sequence entered is higher than the latest order&apos;s sequence.
    <br />
    This MUST NOT be entered manually unless REQUIRED.
    <br />
    Use this functionality carefully.
  </div>
);

class Form extends Component {
  constructor(props) {
    super(props);
    this.defaultNestedFormItem = {
      id: '',
      index: '',
      position: '',
      order_id: '',
      variant_id: '',
      input_booked_quantity: '',
      input_price: 0,
      min_price: 0,
      input_cancelled_quantity: '',
      cancellation_reason: '',
      can_be_destroyed: true,
      disable_price_field: false,
      disable_quantity_field: false,
      cancellable_quantity: '',
      description: '',
      order_item_custom_discount_attributes: {
        id: '',
        value: '',
        discount_type: '',
        _destroy: false,
      },
      _destroy: false,
    };

    const { orders, customer_addresses, forEdit } = this.props;
    const order = getItem({ items: orders.items });
    const prefilledTenantDateTime = getTenantLocalDateTime();

    this.state = {
      order: {
        id: (order?.id) || '',
        sequence: (order?.sequence) || '',
        customer_id: (order?.customer_id) || '',
        auto_generate_sequence: false,
        user_id: (order?.user_id) || '',
        date: (order?.date) || prefilledTenantDateTime,
        channel: 'web',
        shipping_address_id: (order?.shipping_address_id) || '',
        billing_address_id: (order?.billing_address_id) || '',
        branch_id: (order?.branch_id) || '',
        notes: (order?.notes) || '',
        voucher_discount_type: (order?.voucher_discount_type) || '',
        voucher_discount_value: (order?.voucher_discount_value) || '',
        customer_po_number: (order?.customer_po_number) || '',
        customer_po_date: (order?.customer_po_date) || '',
        offer_ids: [],
        // For edit page we need not have an empty default nested item and for new page we need to
        // have a default nested item at index `0`
        order_items_attributes: forEdit ? [] : [{
          ...this.defaultNestedFormItem, position: 1, index: 0,
        }],
        custom_fields_data: {},
      },
      isFormChanged: false,
      errors: {},
      processedBackendErrors: {},
      isLoading: false,
      loadingIdentifier: '',
      customer_addresses,
      customers: [],
      products: {},
      startTime: new Date().getSeconds(),
      nestedFormAutoFocusIndex: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      orders, customer_addresses, current_user: currentUser, users: allUsers, branches,
      shouldGenerateDuplicateOrder,
    } = nextProps;
    const { errors, isError, isFetching } = orders;
    const order = getItem({ items: orders.items });
    const { isLoading, order: localOrderState } = this.state;

    const isOrdersPropsEqual = isEqual(this.props.orders, orders);
    const isCustomerAddressesPropsEqual = isEqual(this.props.customer_addresses, customer_addresses);
    if (!isFetching) {
      if (!isError) {
        if ((order?.id || shouldGenerateDuplicateOrder) && (!isOrdersPropsEqual || !isCustomerAddressesPropsEqual)) {
          const { order_items } = order;
          let orderItemsAttributes = merge(localOrderState?.order_items_attributes, order_items);
          orderItemsAttributes = setIndexToNestedItems({ items: orderItemsAttributes });
          const products = {};

          orderItemsAttributes = map(orderItemsAttributes, (orderItem) => {
            // Set index to each item(for eg: `index: 0`)
            const product = {
              id: orderItem.variant_id,
              name: orderItem.product_label,
              variantable: orderItem.product_variantable,
              active: orderItem.product_active,
              units: orderItem.units,
              quantity_units: orderItem.quantity_units,
              primary_unit: orderItem.product_primary_unit,
              tax_category_name: orderItem.tax_category_name,
            };
            products[orderItem?.index] = [product];

            let processedOPOItems = setIndexToNestedItems({
              items: orderItem?.order_item_purchase_order_items || [],
            });

            // add `input_quantity_with_order_item_unit` under `input_quantity` key for each item
            processedOPOItems = processedOPOItems.map((o) => ({
              ...o,
              selected: true,
              input_quantity: o?.quantity_sourced_with_order_item_unit,
              input_quantity_unit: o?.input_quantity_with_order_item_unit ? orderItem?.input_quantity_unit : '',
            }));

            const orderItemCustomDiscountAttributes = orderItem?.order_item_custom_discount;

            let { can_be_destroyed } = orderItem;
            if (shouldGenerateDuplicateOrder) {
              can_be_destroyed = true;
            }

            return {
              ...orderItem,
              order_item_purchase_order_items: processedOPOItems,
              order_item_custom_discount_attributes: orderItemCustomDiscountAttributes,
              can_be_destroyed,
            };
          });
          // const orderItemsWithPositionAttr = addPositionAttrToNestedFormItems({ items: orderItemsAttributes });
          let updatedOrder = {
            ...order, order_items_attributes: orderItemsAttributes, channel: 'web',
          };

          let customers = [];
          if (order.customer_id) {
            customers = [{ id: order.customer_id, name: order.customer_name }];
          }
          let date = updatedOrder?.date || '';
          if (!date && shouldGenerateDuplicateOrder) {
            date = getTenantLocalDateTime();
          }

          updatedOrder = removeUnwantedAttributesFromOrder({ updated_order: updatedOrder });

          this.setState({
            order: { ...updatedOrder, date }, customers, customer_addresses, products,
          });
        } else if (!order.id && !isLoading) {
          const user = find(allUsers, { mobile: currentUser?.mobile }) || {};
          const userId = (localOrderState?.user_id || user?.id || '');

          let branchId = (localOrderState?.branch_id) || '';
          if (branches?.length === 1) {
            branchId = branches?.[0].id || '';
          }
          const customerId = getIdFromQuickAddModuleParams({
            currentState: localOrderState,
            idKey: 'customer_id',
            nameKey: 'customer_name',
            callFunc: (id, name) => {
              this.getCustomers(name);
              this.setCustomerAddresses({ customer_id: id });
            },
          });

          const orderTermId = getIdFromQuickAddModuleParams({
            currentState: order,
            idKey: 'order_term_id',
            nameKey: 'order_term_name',
            callFunc: (id, name) => ({}),
          });

          const updatedOrderTermId = orderTermId || order?.order_term_id || '';

          this.setState({
            order: {
              ...localOrderState,
              user_id: userId,
              branch_id: branchId,
              customer_id: customerId,
              order_term_id: updatedOrderTermId,
            },
          });
        }
      }
    }

    if (isError) {
      const processedBackendErrorsWithDeepNested = processDeepNestedBackendErrors({
        errors, nestedFormNames: getNestedFormName({ moduleName }),
      });
      // SETUP-SECTION unhandled errors
      // const processedBackendErrors = processBackendErrors({ errors, nestedFormNames: getNestedFormName({ moduleName }) });
      this.setState({
        errors: errors || {},
        processedBackendErrors: processedBackendErrorsWithDeepNested,
      });
      // !SETUP-SECTION unhandled errors
    }
  }

  // We need this function for customer search or on customer input change
  // we hit sagaPreWatcherAction(search_params) redux action and pass search_params ie. { phone_or_name_cont: 'sac' }
  // on successfull response we update store with searched customers.
  getCustomers = (input) => {
    const { sagaPreWatcherAction } = this.props;
    if (input && (input.length > 1)) {
      const searchParams = { phone_or_name_cont: input };
      this.setState({ isLoading: true, loadingIdentifier: AUTOCOMPLETE_CUSTOMER }, () => {
        new Promise((resolve, reject) => {
          sagaPreWatcherAction({
            type: AUTOCOMPLETE_ACTIVE_CUSTOMERS, payload: { search_params: searchParams },
          }, resolve, reject);
        }).then((response) => {
          const customers = response?.data?.customers || [];
          this.setState({ customers, isLoading: false, loadingIdentifier: '' });
        }).catch((error) => {
          this.setState({ isLoading: false, loadingIdentifier: '' });
        });
      });
    }
  }

  getSimpleAndVariantProducts = (input, idx) => {
    const { sagaPreWatcherAction } = this.props;
    const { order } = this.state;
    const { customer_id: customerId } = order;

    if ((input?.length > 1) && customerId) {
      const searchParams = { sku_or_barcode_or_name_or_variants_name_cont: input };
      this.setState({ isLoading: true, loadingIdentifier: idx }, () => {
        new Promise((resolve, reject) => {
          sagaPreWatcherAction({
            type: AUTOCOMPLETE_ORDERABLE_PRODUCTS,
            payload: { search_params: searchParams, customer_id: customerId },
          }, resolve, reject);
        }).then((response) => {
          const { products: localProductsState } = this.state;
          const products = response?.data?.products || [];
          this.setState({ products: { ...localProductsState, [idx]: products }, isLoading: false, loadingIdentifier: '' });
        }).catch((error) => {
          this.setState({ isLoading: false, loadingIdentifier: '' });
        });
      });
    }
  }

  setCustomerAddresses = ({ customer_id }) => {
    const searchParams = { name_or_mobile_or_phone_or_line_1_or_city_or_state_or_postal_code_cont: '' };
    this.fetchCustomerAddresses({ customer_id, search_params: searchParams });
  }

  refetchCustomerAddresses = (input) => {
    const { order } = this.state;
    const { customer_id: customerId } = order;

    if ((input.length > 1) && customerId) {
      const searchParams = {
        name_or_mobile_or_phone_or_line_1_or_city_or_state_or_postal_code_cont: input,
      };
      this.fetchCustomerAddresses({ customer_id: customerId, search_params: searchParams });
    }
  }

  fetchCustomerAddresses = ({ customer_id, search_params }) => {
    const { getCustomerAddresses } = this.props;
    getCustomerAddresses({ search_params }, customer_id)
      .then((response) => {
        const { order, customer_addresses: customerAddresses } = this.state;
        const { addresses } = response?.data || {};
        const allCustomerAddresses = unionBy(addresses || [], customerAddresses, 'id');
        if (allCustomerAddresses?.length === 1) {
          const [address] = allCustomerAddresses;
          this.setState({
            order: { ...order, shipping_address_id: address.id, billing_address_id: address.id },
            customer_addresses: allCustomerAddresses,
          });
        } else {
          this.setState({ customer_addresses: allCustomerAddresses });
        }
      });
  }

  // We handle normal field change in this function
  handleChange = (value, name, errorName) => {
    this.updateComponentLocalState(value, name, errorName);
  }

  handleCustomFieldChange = (value, fieldName, errorName) => {
    this.setState((prevState) => {
      const errors = resetErrors({ errors: prevState.errors, error_name: errorName });
      const formValues = prevState.order;
      let { custom_fields_data: customFieldsData } = formValues;
      customFieldsData = { ...customFieldsData, [fieldName]: value };
      formValues.custom_fields_data = customFieldsData;
      return { order: formValues, isFormChanged: true, errors: errors || {} };
    });
  }

  handleCheckboxChange = (event, fieldName, errorName) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => {
      const errors = resetErrors({ errors: prevState.errors, error_name: errorName });
      const formValues = prevState.order;
      let { custom_fields_data: customFieldsData } = formValues;
      customFieldsData = { ...customFieldsData, [fieldName]: isChecked };
      formValues.custom_fields_data = customFieldsData;
      return { order: formValues, isFormChanged: true, errors };
    });
  }

  handleSequenceInputChange = (e, n) => {
    const { name, value } = e.target;
    const { order } = this.state;
    let { sequence } = order;
    if (!value) sequence = '';
    this.setState({ order: { ...order, sequence, [name]: value }, isFormChanged: true });
  }

  handleDatePickerChange = (date, name, errorName) => {
    this.updateComponentLocalState(date, name, errorName);
  }

  // We handle select field in this function
  // select is current selected value ie. { value: "true", label: "Active" }
  // if we want handle multiple select fields in same form then 'name' parameter is usefull ie. state, user_id.
  // here we pass select.value and name to updateComponentLocalState fuction.
  handleSelectChange = (value, name, errorName) => {
    const { order, errors: localErrorsState } = this.state;
    const { customer_id } = order;

    const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
    this.setState({ order: { ...order, [name]: value }, isFormChanged: true, errors });
  }

  handleCustomerSelectChange = (value, name, errorName) => {
    const { order, errors: localErrorsState, customers } = this.state;
    const { customer_id } = order;

    const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
    const customer = find(customers, { id: value });
    if (name === 'customer_id') {
      if (value) {
        this.setState({
          order: { ...order, [name]: value, customer_name: customer?.name || '' },
          customer_addresses: [],
          isFormChanged: true,
          errors,
        }, () => {
          const params = { customer_id: value };
          this.setCustomerAddresses(params);
          this.fetchEligibleOffers(params);
        });
      } else {
        this.setState({
          order: {
            ...order,
            [name]: value,
            customer_name: customer?.name || '',
            shipping_address_id: '',
            billing_address_id: '',
          },
          customer_addresses: [],
        }, () => {
          const params = { customer_id };
          this.fetchEligibleOffers(params);
        });
      }
    }
  }

  // In this fuction take two parameter ie. value and name and update state.
  updateComponentLocalState = (value, name, errorName) => {
    const { order, errors: stateErrors } = this.state;
    const errors = resetErrors({ errors: stateErrors, error_name: errorName });
    this.setState({ order: { ...order, [name]: value }, isFormChanged: true, errors }, () => {
      if ((name === 'date')) {
        const params = { date: (value || '') };
        this.fetchEligibleOffers(params);
      }
    });
  }

  fetchEligibleOffers = (params) => {
    const { fetchEligibleOffers } = this.props;
    const { order } = this.state;
    const { customer_id: customerId } = order;
    let redirectUrl = `${baseUrl}/new`;
    if (order.id) {
      redirectUrl = `${baseUrl}/${order.id}/edit`;
    }
    const url = '/sales/offers/eligible_for_order';
    if (customerId) {
      this.setState({ isLoading: true }, () => {
        fetchEligibleOffers({ url, redirectUrl, params: { ...params, customer_id: customerId } })
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      });
    }
  }

  // Change the state of nested form input.
  handleNestedFormInputChange = (event, errorName, idx) => {
    const { name, value } = event.target;
    const { order, errors: localErrorsState } = this.state;
    const { order_items_attributes: orderItemsAttributes } = order;
    const updatedOrderItems = orderItemsAttributes?.map((orderItem) => {
      if (idx !== orderItem.index) return orderItem;
      return { ...orderItem, [name]: value };
    });
    const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
    this.setState({
      order: { ...order, order_items_attributes: updatedOrderItems },
      isFormChanged: true,
      errors,
    });
  }

  // handleNestedFormOrderItemPurchaseOrderItem = (name, value, idx, errorName = '') => {
  //   const { order, errors: localErrorsState } = this.state;
  //   const { order_items_attributes: orderItemsAttributes } = order;
  //   const updatedOrderItems = orderItemsAttributes?.map((orderItem) => {
  //     if (idx !== orderItem.index) return orderItem;
  //     return { ...orderItem, [name]: value };
  //   });
  //   const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
  //   this.setState({
  //     order: { ...order, order_items_attributes: updatedOrderItems },
  //     isFormChanged: true,
  //     errors,
  //   });
  // }

  selectNestedFormChange = (value, name, errorName, idx) => {
    const { order, errors: localErrorsState } = this.state;
    const { order_items_attributes: orderItemsAttributes } = order;
    const updatedOrderItems = orderItemsAttributes?.map((orderItem) => {
      if (idx !== orderItem.index) return orderItem;

      const updatedItem = { ...orderItem, [name]: value };

      // When input quantity unit of the nested item is changed we have to update the input
      // quantity unit of order_item_purchase_order_items too
      if (name === 'input_quantity_unit') {
        const orderItemPurchaseOrderItems = updatedItem?.order_item_purchase_order_items || [];
        let updatedPOISOIItems = [...orderItemPurchaseOrderItems];
        updatedPOISOIItems = updatedPOISOIItems?.map((POISOIItem) => {
          if (POISOIItem.input_quantity_unit) { POISOIItem.input_quantity_unit = value; }
          return POISOIItem;
        });
        updatedItem.order_item_purchase_order_items = updatedPOISOIItems;
      }

      return updatedItem;
    });
    const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
    this.setState({
      order: { ...order, order_items_attributes: updatedOrderItems },
      isFormChanged: true,
      errors,
    });
  }

  selectVariantNestedFormChange = (selectedVariant, name, errorName, idx) => {
    const { order, errors: localErrorsState } = this.state;
    const { settings } = this.props;
    const { client_settings: clientSettings } = settings;
    const { order_items_attributes: orderItemsAttributes } = order;
    const id = (selectedVariant?.id) || '';
    const inputPrice = (selectedVariant?.current_price_for_customer?.price) || '';
    const minPrice = (selectedVariant?.current_price_for_customer?.min_price) || '';
    const primaryUnit = (selectedVariant?.primary_unit) || '';
    const inclusiveTax = (selectedVariant?.inclusive_tax) || false;
    const description = clientSettings?.auto_fill_order_item_description_from_product_master ? (selectedVariant?.description || '') : '';
    const product_label = selectedVariant?.name || '';

    const updatedOrderItems = orderItemsAttributes?.map((orderItem) => {
      if (idx !== orderItem.index) return orderItem;
      return {
        ...orderItem,
        [name]: id,
        input_price: inputPrice,
        min_price: minPrice,
        primary_unit: primaryUnit,
        inclusive_tax: inclusiveTax,
        input_price_per_unit: primaryUnit,
        input_quantity_unit: primaryUnit,
        description,
        product_label,
      };
    });
    const errors = resetErrors({ errors: localErrorsState, error_name: errorName });
    this.setState({
      order: { ...order, order_items_attributes: updatedOrderItems },
      isFormChanged: true,
      errors,
    });
  }

  // Add order item dynamically.
  addNestedFormItem = (event) => {
    event.preventDefault();
    const { order } = this.state;
    const { id, order_items_attributes: orderItemsAttributes } = order;
    const index = getNextIndexFromItems({ items: orderItemsAttributes });
    const newOrderItem = {
      ...this.defaultNestedFormItem, order_id: id, index,
    };
    const updatedOrderItems = orderItemsAttributes?.concat([newOrderItem]);
    logEvent(CategoryEnum.ORDER_FORM, ActionEnum.ORDER_ITEM_ACTION, ActionEnum.ORDER_ITEM_ADD);
    const orderItemsWithPositionAttr = addPositionAttrToNestedFormItems({
      items: updatedOrderItems,
    });
    this.setState({
      order: { ...order, order_items_attributes: orderItemsWithPositionAttr },
      isFormChanged: true,
      nestedFormAutoFocusIndex: index,
    });
  }

  // Remove order item.
  removeNestedFormItem = (idx) => {
    const { order } = this.state;
    const { order_items_attributes: orderItemsAttributes } = order;
    const updatedOrderItems = orderItemsAttributes?.map((orderItem) => {
      if (idx !== orderItem.index) return orderItem;
      return { ...orderItem, _destroy: true };
    });
    logEvent(CategoryEnum.ORDER_FORM, ActionEnum.ORDER_ITEM_ACTION, ActionEnum.ORDER_ITEM_REMOVE);
    const orderItemsWithPositionAttr = addPositionAttrToNestedFormItems({
      items: updatedOrderItems,
    });
    this.setState({
      order: { ...order, order_items_attributes: orderItemsWithPositionAttr },
      isFormChanged: true,
      nestedFormAutoFocusIndex: '',
    });
  }

  handleOrderItemCustomDiscountNestedFormInputChange = (name, value, errorKeys, idx) => {
    const { order, errors: localErrorsState } = this.state;
    const { order_items_attributes: orderItemsAttributes } = order;
    const updatetOrderItemsAttributes = orderItemsAttributes?.map((orderItem) => {
      if (orderItem?.index !== idx) return orderItem;
      let resetDiscountValue = {};
      if (name === 'discount_type') {
        resetDiscountValue = { value: '' };
        if (!value) resetDiscountValue = { ...resetDiscountValue, _destroy: true };
      }
      return {
        ...orderItem,
        order_item_custom_discount_attributes: {
          ...orderItem?.order_item_custom_discount_attributes,
          [name]: value,
          ...resetDiscountValue,
        },
      };
    });
    const errors = resetErrors({ errors: localErrorsState, error_name: errorKeys });
    this.setState({
      order: {
        ...order, order_items_attributes: updatetOrderItemsAttributes,
      },
      isFormChanged: true,
      errors,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { saveOrder } = this.props;
    const { startTime } = this.state;
    let { order } = this.state;

    // This block of code remove unwanted attributes from order items.
    const orderItemsAttributes = map(order?.order_items_attributes, (orderItem) => {
      let item = {
        ...orderItem,
        order_item_purchase_order_items_attributes: orderItem?.order_item_purchase_order_items,
      };

      const removeAttributeFromOrderItems = ['order_id', 'disable_price_field', 'tax', 'total',
        'disable_quantity_field', 'cancellable_quantity', 'quantity', 'quantity_invoiced', 'discount', 'price_localized',
        'discount_localized', 'tax_localized', 'total_localized', 'inclusive_tax', 'tax_category_id', 'product_label',
        'primary_unit', 'order_number', 'order_item_purchase_order_items'];
      map(removeAttributeFromOrderItems, (attr) => {
        item = omit(item, attr);
      });
      return item;
    });

    order = { ...order, order_items_attributes: orderItemsAttributes };
    logEvent(CategoryEnum.ORDER_FORM, `${CategoryEnum.ORDER_FORM}_SAVE`, order.id ? CategoryEnum.ORDER_UPDATE : CategoryEnum.ORDER_CREATE);

    const duration = new Date().getSeconds() - startTime;
    logTiming(CategoryEnum.ORDER_FORM, order.id
      ? CategoryEnum.ORDER_UPDATE : CategoryEnum.ORDER_CREATE, duration);

    this.setState({ isLoading: true, loadingIdentifier: SUBMIT_FORM_LOADER, isFormChanged: false },
      function submit() {
        const sanitizedFormData = sanitizeCustomFieldsData(order);
        saveOrder(sanitizedFormData)
          .catch(() => {
            this.setState({ isLoading: false, loadingIdentifier: '' });
          });
      });
  }

  render() {
    const {
      branches, users, settings, order_terms, shouldGenerateDuplicateOrder,
    } = this.props;
    const { client_settings: clientSettings } = settings;
    const {
      isLoading, loadingIdentifier, order, customer_addresses, customers, products,
      isFormChanged, errors = {}, processedBackendErrors, nestedFormAutoFocusIndex,
    } = this.state;
    const {
      id, number, customer_id, user_id, date, notes, shipping_address_id, billing_address_id,
      branch_id, voucher_discount_type, voucher_discount_value, offer_ids, sequence,
      customer_po_number, customer_po_date, auto_generate_sequence,
    } = order;

    let discountPlaceholder = 'Discount value';
    if (voucher_discount_type) {
      discountPlaceholder = (voucher_discount_type === 'flat') ? 'Flat discount' : '% discount';
    }

    return (
      <>
        <DMSBasicCard
          title={id ? `Edit ${number || 'Order'}` : 'New Order'}
          view="order_form"
          information_title={FORM_INFORMATION_TITLE}
          errors={errors}
          moduleName={moduleName}
          breadcrumb_listing={
            id
              ? editBreadcrumbListing({
                BREADCRUMB_LISTING, id: order.number, TargetUrl: `${TargetBaseUrl}/${id}`,
              })
              : NEW_BREADCRUMB_LISTING
          }
          gridClassName="full-height"
          // SETUP-SECTION unhandled errors
          processedBackendErrors={processedBackendErrors}
          handledErrorKeys={(handledErrorKeys?.master) || []}
        // SETUP-SECTION unhandled errors
        >
          <GenericFormComponent isFormChanged={isFormChanged} className="new form-horizontal" handleSubmit={this.handleSubmit}>
            <div className="row">

              <div className="col-md-4 pl0 flex-box">
                <div className={classnames('col-md-6', { error: doesErrorExistOnField({ errors, error_keys: ['date'] }) })}>
                  <FormGroup>
                    <FormFieldLabel id="order-date-label" displayName="Date" view="order_form" />
                    <DMSDatePicker
                      id="order-date"
                      selected={date || null}
                      placeholderText="Date"
                      dateFormat="dd/MM/yyyy"
                      // overrideDateFormat
                      // apiDatetimeFormat={apiDefaultDateTimeFormat}
                      onChange={(date) => this.handleDatePickerChange(date, 'date', ['date'])}
                      disabled={!!id && !(
                        (!(clientSettings?.orders_enforce_date_and_sequence_consistency)
                          && (clientSettings?.order_date_update_after_creation))
                      )}
                      tabIndex={id ? -1 : 0}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['date'] })}</span>
                  </FormGroup>
                </div>
                {
                  !(id)
                    ? (
                      <InputFieldWithCheckbox
                        name="sequence"
                        label="Sequence"
                        placeholder="Sequence"
                        value={sequence || ''}
                        handleChange={(event) => this.handleSequenceInputChange(event, 'sequence')}
                        fieldErrors={errors?.sequence?.join(', ') || []}
                        isRequired={false}
                        description={description() || ''}
                        fieldContainerClassNames={classnames('col-md-6', { error: !!errors.sequence })}
                        showCheckbox
                        checkboxKey="auto_generate_sequence"
                        checkboxValue={auto_generate_sequence}
                        checkBoxLabel="Enter Manually"
                        isDisabled={!auto_generate_sequence}
                        checkboxTabIndex={0}
                        tabIndex={!auto_generate_sequence ? -1 : 0}
                      />
                    )
                    : <div className="col-md-6" />
                }
                <div className={classnames('col-md-6', { error: doesErrorExistOnField({ errors, error_keys: ['branch', 'branch_id'] }) })}>
                  <FormGroup>
                    <FormFieldLabel
                      id="order-branch-label"
                      displayName="Branch"
                      view="order_form"
                      isRequired={!!(branches?.length > 1)}
                    />
                    <DMSSelect
                      id="order-branch"
                      placeholder="Select branch"
                      name="branch_id"
                      value={branch_id || ''}
                      onChange={!id ? (branchObj) => this.handleSelectChange(branchObj?.id || '', 'branch_id', ['branch', 'branch_id']) : undefined}
                      valueKey="id"
                      labelKey="name"
                      options={id ? [{ id: order.branch_id, name: order.branch_name }] : branches}
                      disabled={!!id}
                      tabIndex={id ? -1 : 0}
                      clearable={false}
                      autoFocus={!id}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['branch', 'branch_id'] })}</span>
                  </FormGroup>
                </div>

                <div className={classnames('col-md-6', { error: doesErrorExistOnField({ errors, error_keys: ['user', 'user_id'] }) })}>
                  <FormGroup>
                    <FormFieldLabel
                      id="order-salesman-label"
                      displayName="Salesman"
                      view="order_form"
                      isRequired
                    />
                    <DMSSelect
                      id="order-salesman"
                      placeholder="Search salesman"
                      name="user_id"
                      value={user_id || ''}
                      onChange={(userObj) => this.handleSelectChange(selectedValueSanitizer(userObj, 'id'), 'user_id', ['user', 'user_id'])}
                      valueKey="id"
                      labelKey="name"
                      options={users || []}
                      clearable
                      autoFocus={!!id}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['user', 'user_id'] })}</span>
                  </FormGroup>
                </div>

                <div className={classnames('col-md-12', { error: doesErrorExistOnField({ errors, error_keys: ['order_term', 'order_term_id'] }) })}>
                  <FormGroup>
                    <ControlLabel>Order Term</ControlLabel>
                    <DMSSelect
                      id="order-order-term"
                      placeholder="Select order term"
                      name="order_term_id"
                      value={order?.order_term_id || ''}
                      onChange={(selected_object) => this.handleSelectChange(selectedValueSanitizer(selected_object, 'id'), 'order_term_id', ['order_term', 'order_term_id'])}
                      valueKey="id"
                      labelKey="name"
                      options={order_terms || []}
                      clearable
                      moduleName="voucher_terms"
                      showCustomNoResultsText
                      identifier={ADD_ORDER_TERM}
                      className="wrappable-select"
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['order_term', 'order_term_id'] })}</span>
                  </FormGroup>
                </div>

                <div className={classnames('col-md-12', { error: doesErrorExistOnField({ errors, error_keys: ['notes'] }) })}>
                  <FormGroup>
                    <FormFieldLabel id="order-notes-label" displayName="Notes" view="order_form" />
                    <FormControl
                      data-cy="order-notes"
                      id="order-notes"
                      componentClass="textarea"
                      bsClass="form-control"
                      placeholder="Notes"
                      name="notes"
                      value={notes || ''}
                      onChange={(event) => this.handleChange(event.target.value, 'notes', ['notes'])}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['notes'] })}</span>
                  </FormGroup>
                </div>
              </div>

              <div className="col-md-2" />

              <div className="col-md-6 flex-box">
                <div className={classnames('col-md-8 customer-field', { error: doesErrorExistOnField({ errors, error_keys: ['customer', 'customer_id'] }) })}>
                  <FormGroup>
                    <FormFieldLabel
                      id="order-customer-label"
                      displayName="Customer"
                      view="order_form"
                      isRequired
                      dataCy="order-customer-required"
                    />
                    <DMSSelect
                      id="order-customer"
                      placeholder="Search customer"
                      name="customer_id"
                      value={customer_id || ''}
                      onChange={!id ? (customerObj) => this.handleCustomerSelectChange(customerObj?.id || '', 'customer_id', ['customer', 'customer_id']) : undefined}
                      onInputChange={!id ? this.getCustomers : undefined}
                      valueKey="id"
                      labelKey="name"
                      isLoading={isLoading && (loadingIdentifier === AUTOCOMPLETE_CUSTOMER)}
                      options={customers || []}
                      disabled={!!id}
                      tabIndex={id ? -1 : 0}
                      clearable
                      showCustomNoResultsText
                      identifier={ADD_CUSTOMER}
                      className="wrappable-select"
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['customer', 'customer_id'] })}</span>
                  </FormGroup>
                </div>

                <div className="col-md-4" />
                {
                  gstinDetails?.exists
                    ? (
                      <div className={classnames('col-md-4', { error: doesErrorExistOnField({ errors, error_keys: ['gstin'] }) })}>
                        <FormGroup>
                          <FormFieldLabel id="order-gstin-label" displayName={gstinDetails?.label || 'GSTIN'} view="order_form" />
                          <FormControl
                            data-cy="order-gstin"
                            id="order-gstin"
                            bsClass="form-control"
                            placeholder="GSTIN"
                            name="gstin"
                            value={(order?.id || shouldGenerateDuplicateOrder)
                              ? order?.customer_gstin : getGSTIN(customers, order?.customer_id)}
                            disabled
                          />
                          <span className="errors-message">{getFieldErrors({ errors, error_keys: ['gstin'] })}</span>
                        </FormGroup>
                      </div>
                    )
                    : null
                }

                <div className={classnames('col-md-4', { error: doesErrorExistOnField({ errors, error_keys: ['customer_po_number'] }) })}>
                  <FormGroup>
                    <FormFieldLabel id="order-customer-po-number-label" displayName="PO Number" view="order_form" />
                    <FormControl
                      data-cy="order-customer-po-number"
                      id="order-customer-po-number"
                      bsClass="form-control"
                      placeholder="Customer Purchase Order No."
                      name="customer_po_number"
                      value={customer_po_number || ''}
                      onChange={(event) => this.handleChange(event.target.value, 'customer_po_number', ['customer_po_number'])}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['customer_po_number'] })}</span>
                  </FormGroup>
                </div>

                <div className={classnames('col-md-3', { error: doesErrorExistOnField({ errors, error_keys: ['customer_po_date'] }) })}>
                  <FormGroup>
                    <FormFieldLabel id="order-customer-po-date-label" displayName="PO Date" view="order_form" />
                    <DMSDatePicker
                      id="order-customer-po-date"
                      selected={customer_po_date || null}
                      placeholderText="Customer Purchase Order Date"
                      dateFormat="dd/MM/yyyy"
                      // overrideDateFormat
                      // apiDatetimeFormat={apiDefaultDateTimeFormat}
                      onChange={(date) => this.handleDatePickerChange(date, 'customer_po_date', ['customer_po_date'])}
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['customer_po_date'] })}</span>
                  </FormGroup>
                </div>

                <div className={classnames('col-md-6 billing-address', { error: doesErrorExistOnField({ errors, error_keys: ['billing_address', 'billing_address_id'] }) })}>
                  <FormGroup>
                    <FormFieldLabel
                      id="order-billing-address-label"
                      displayName="Billing Address"
                      view="order_form"
                      isRequired
                      dataCy="order-billing-address-required"
                    />
                    <DMSSelect
                      id="order-billing-address"
                      placeholder="Select billing address"
                      name="billing_address_id"
                      value={billing_address_id || ''}
                      onChange={(selected_item) => this.handleSelectChange(selectedValueSanitizer(selected_item, 'id'), 'billing_address_id', ['billing_address', 'billing_address_id'])}
                      onInputChange={this.refetchCustomerAddresses}
                      valueKey="id"
                      labelKey="bill_to_label"
                      options={customer_addresses}
                      clearable={false}
                      tabIndex={(customer_addresses.length <= 1) ? -1 : 0}
                      className="wrappable-select"
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['billing_address', 'billing_address_id'] })}</span>
                  </FormGroup>
                </div>
                <div className={classnames('col-md-6 shipping-address', { error: doesErrorExistOnField({ errors, error_keys: ['shipping_address', 'shipping_address_id'] }) })}>
                  <FormGroup>
                    <FormFieldLabel
                      id="order-shipping-address-label"
                      displayName="Shipping Address"
                      view="order_form"
                      isRequired
                      dataCy="order-shipping-address-required"
                    />
                    <DMSSelect
                      id="order-shipping-address"
                      placeholder="Select shipping address"
                      name="shipping_address_id"
                      value={shipping_address_id || ''}
                      onChange={(selected_item) => this.handleSelectChange(selectedValueSanitizer(selected_item, 'id'), 'shipping_address_id', ['shipping_address', 'shipping_address_id'])}
                      onInputChange={this.refetchCustomerAddresses}
                      valueKey="id"
                      labelKey="ship_to_label"
                      options={customer_addresses}
                      clearable={false}
                      tabIndex={(customer_addresses.length <= 1) ? -1 : 0}
                      className="wrappable-select"
                    />
                    <span className="errors-message">{getFieldErrors({ errors, error_keys: ['shipping_address', 'shipping_address_id'] })}</span>
                  </FormGroup>
                </div>
              </div>

              <CustomFieldsRenderer
                resource_type={RESOURCE_TYPE}
                errors={errors}
                formState={order}
                handleChange={this.handleCustomFieldChange}
                handleCheckboxChange={this.handleCheckboxChange}
                isEdit={!!id}
                fieldClassNames="col-md-3"
                renderHrLineBeforeCustomFieldsRenderer
              />

              <div className="col-md-12">
                <OrderItemNestedForm
                  isLoading={isLoading}
                  loadingIdentifier={loadingIdentifier}
                  order={order}
                  products={products}
                  settings={settings}
                  addNestedFormItem={this.addNestedFormItem}
                  handleNestedFormInputChange={this.handleNestedFormInputChange}
                  selectNestedFormChange={this.selectNestedFormChange}
                  selectVariantNestedFormChange={this.selectVariantNestedFormChange}
                  getSimpleAndVariantProducts={this.getSimpleAndVariantProducts}
                  removeNestedFormItem={this.removeNestedFormItem}
                  errors={errors}
                  // SETUP-SECTION unhandled errors
                  processedBackendErrors={processedBackendErrors}
                  // handleNestedFormOrderItemPurchaseOrderItem={this.handleNestedFormOrderItemPurchaseOrderItem}
                  // !SETUP-SECTION unhandled errors
                  updateFormComponentState={this.updateComponentLocalState}
                  nestedFormAutoFocusIndex={nestedFormAutoFocusIndex}
                  handleOrderItemCustomDiscountNestedFormInputChange={
                    this.handleOrderItemCustomDiscountNestedFormInputChange
                  }
                />
              </div>

              <div className="col-md-12">
                <FormSectionTitle
                  displayTitle="OFFERS AND DISCOUNTS"
                  view="order_form"
                  className="p0"
                />
              </div>

              <div className="col-md-12" style={!(order && order.id) ? { marginBottom: '15%', display: 'flex' } : { marginBottom: '12%', display: 'flex' }}>
                <div className="col-md-7 pl0">
                  <EligibleOffers
                    customer_id={customer_id}
                    offerIds={offer_ids}
                    redirectUrl={`/orders/${order.id}/edit`}
                    handleEligibleOfferChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-md-1 feed-section-divider">
                  <div />
                </div>
                <div className="col-md-4 pr0">
                  <div className="col-md-6 voucher_discount-type">
                    <div className={classnames({ error: doesErrorExistOnField({ errors, error_keys: ['voucher_discount_type'] }) })}>
                      <FormGroup>
                        <FormFieldLabel
                          id="order-discount-type-label"
                          displayName="Discount Type"
                          view="order_form"
                        />
                        <DMSSelect
                          id="voucher-discount-type"
                          placeholder="Discount type"
                          name="voucher_discount_type"
                          value={voucher_discount_type || ''}
                          onChange={(selectedObject) => {
                            const selectedKey = selectedObject ? selectedObject.key : '';
                            this.handleChange(selectedKey, 'voucher_discount_type', 'voucher_discount_type');
                          }}
                          valueKey="key"
                          labelKey="label"
                          options={[{ key: 'flat', label: 'Flat' }, { key: 'percentage', label: 'Percentage' }]}
                        />
                        <span className="errors-message">{getFieldErrors({ errors, error_keys: ['voucher_discount_type'] })}</span>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-md-6 pr0">
                    <div className={classnames({ error: doesErrorExistOnField({ errors, error_keys: ['voucher_discount_value'] }) })}>
                      <FormGroup>
                        <FormFieldLabel
                          id="order-discount-label"
                          displayName="Discount"
                          view="order_form"
                        />
                        <FormControl
                          data-cy="voucher-discount"
                          id="voucher-discount"
                          bsClass="form-control text-right"
                          type="string"
                          placeholder={discountPlaceholder}
                          name="voucher_discount"
                          value={voucher_discount_value || ''}
                          onChange={(event) => {
                            const isValid = validateNumberValue(event?.target?.value, DECIMAL_NUMBER_REGEX);
                            if (isValid) {
                              this.handleChange(event.target.value, 'voucher_discount_value', ['voucher_discount_value']);
                            }
                          }}
                        />
                        <span className="errors-message">{getFieldErrors({ errors, error_keys: ['voucher_discount_value'] })}</span>
                      </FormGroup>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              <FormFixedFooter>
                <FormCancelConfirmation
                  eventCategory={CategoryEnum.ORDER_FORM}
                  label={id ? CategoryEnum.ORDER_UPDATE : CategoryEnum.ORDER_CREATE}
                />
                <FormSubmitLoader
                  loader={isLoading && (loadingIdentifier === SUBMIT_FORM_LOADER)}
                />
              </FormFixedFooter>
            </div>
          </GenericFormComponent>
        </DMSBasicCard>
      </>
    );
  }
}

Form.propTypes = {
  current_user: shape({}).isRequired,
  orders: shape({}),
  users: array,
  customer_addresses: array,
  branches: array.isRequired,
  saveOrder: func.isRequired,
  getCustomerAddresses: func.isRequired,
  sagaPreWatcherAction: func.isRequired,
  fetchEligibleOffers: func.isRequired,
  settings: shape({}).isRequired,
  order_terms: array,
  forEdit: bool,
  shouldGenerateDuplicateOrder: bool,
};

Form.defaultProps = {
  orders: {},
  users: [],
  customer_addresses: [],
  order_terms: [],
  forEdit: false,
  shouldGenerateDuplicateOrder: false,
};

export default Form;
