export const SET_INVOICE_E_INVOICE_DATA = 'SET_INVOICE_E_INVOICE_DATA';
export const SET_CREDIT_NOTE_E_INVOICE_DATA = 'SET_CREDIT_NOTE_E_INVOICE_DATA';
export const SET_DEBIT_NOTE_E_INVOICE_DATA = 'SET_DEBIT_NOTE_E_INVOICE_DATA';
export const GENERATE_E_INVOICE = 'GENERATE_E_INVOICE';
export const CANCEL_E_INVOICE = 'CANCEL_E_INVOICE';
export const AUTOCOMPLETE_E_INVOICE_CANCELLATION_REASONS = 'AUTOCOMPLETE_E_INVOICE_CANCELLATION_REASONS';

export const PROCESSING = 'processing';
export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const CANCEL = 'cancel';

export const E_INVOICES_STATES = {
  PROCESSING, SUCCESS, FAILURE, CANCEL,
};

export const E_INVOICE_STATUSES = [
  { label: 'Created', value: false },
  { label: 'Pending', value: true },
];
