import { call, put, takeLatest } from 'redux-saga/effects';
import { PER_PAGE } from '@constants/common';
import { fetchContent, fetchContents } from './action';
import { FETCH_CONTENT, FETCH_CONTENTS, SET_CONTENTS } from './constants';

export const setContents = ({ payload }) => ({
  type: SET_CONTENTS,
  payload,
});

function* fetchContentsFunc(action: any) {
  const { params, search_params } = action;

  try {
    const response = yield call(fetchContents({ params, search_params }));
    const { contents, meta } = response?.data || {};
    const { total_count: totalCount } = meta?.pagination || {};
    const items = contents || [];
    const currentPage = params?.page || 1;
    const perPage = params?.per_page || PER_PAGE;

    yield put(setContents({
      payload: {
        type_in: search_params?.type_in, items, current_page: currentPage, per_page: perPage, total_count: (totalCount || ''),
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchContentFunc(action: any) {
  const { params } = action;

  try {
    const response = yield call(fetchContent({ params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* contentsWatcher() {
  yield takeLatest(FETCH_CONTENTS, fetchContentsFunc);
  yield takeLatest(FETCH_CONTENT, fetchContentFunc);
}
