export const ACCOUNT_ENTRY = 'ACCOUNT_ENTRY';
export const SET_ACCOUNT_ENTRIES = 'SET_ACCOUNT_ENTRIES';

export const ACCOUNT_TYPES = [
  { name: 'Customer', id: 'Customer' }, { name: 'Supplier', id: 'Supplier' },
];

export const defaultSearchParams = {
  accountable_type_cont: '',
};
