import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const moduleName = 'e_way_bill_credentials';
export const E_WAY_BILL_CREDENTIAL = 'E_WAY_BILL_CREDENTIAL';
export const FETCH_E_WAY_BILL_CREDENTIAL = 'FETCH_E_WAY_BILL_CREDENTIAL';
export const SET_E_WAY_BILL_CREDENTIAL = 'SET_E_WAY_BILL_CREDENTIAL';
export const UPDATE_E_WAY_BILL_CREDENTIAL = 'UPDATE_E_WAY_BILL_CREDENTIAL';

export const permissionKey = 'in/e_way_bills';

export const Title = 'E-Way Bill Credential';
export const TargetBaseUrl = 'e_way_bill_credentials';
export const E_WAY_BILL_CREDENTIAL_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: true }],
);
