import React from 'react';
import classnames from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

interface TooltipTypes {
  description: string;
  isWhiteTooltip?: boolean;
  otherClassNames?: string;
}

const tooltip = ({ description, isWhiteTooltip, otherClassNames }: TooltipTypes) => (
  <Tooltip
    id="tooltip"
    className={classnames({ 'card-state-tooltip': isWhiteTooltip }, { [otherClassNames]: !!otherClassNames })}
  >
    <span>{description}</span>
  </Tooltip>
);

tooltip.defaultProps = {
  isWhiteTooltip: false,
  otherClassNames: '',
};

interface OverlayTriggerTooltipTypes {
  placement?: string;
  description?: any;
  children?: any;
  isWhiteTooltip?: boolean;
  otherClassNames?: string;
  show?: boolean;
}

const showChildren = (children) => {
  if (children?.length > 1) {
    return (
      <>
        {children}
      </>
    );
  }
  return children;
};

const OverlayTriggerTooltip = ({
  placement, description, children, isWhiteTooltip, otherClassNames, show,
}: OverlayTriggerTooltipTypes) => {
  if (!show) {
    return showChildren(children);
  }
  return (
    <OverlayTrigger
      placement={placement}
      overlay={tooltip({ description, isWhiteTooltip, otherClassNames })}
    >
      {showChildren(children)}
    </OverlayTrigger>
  );
};

OverlayTriggerTooltip.defaultProps = {
  placement: 'top',
  description: '',
  children: {},
  isWhiteTooltip: false,
  otherClassNames: '',
  show: true,
};

export default OverlayTriggerTooltip;
