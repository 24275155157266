import React, { Component } from 'react';
import { offlineTitle } from '@constants/common_constants';
import Thinking from '@assets/img/thinking.png';

class OfflineComponent extends Component<OfflineComponentProps> {
  render() {
    return (
      <div
        style={{ textAlign: 'center', marginTop: 50 }}
      >
        <img src={Thinking} alt="alt" />
        <div id="header-title" style={{ marginTop: 20 }}>{offlineTitle}</div>
        <div className="col-md-offset-4 col-md-4-half offline-component-help">
          <ol className="main-order-list">
            <li className="reload-page-text text-left">
              <b>Hard Refresh</b>
              <ol className="secondary-order-list" type="a">
                <li>
                  <p className="text-left">
                    For Windows :&nbsp;
                    <b>Press Ctrl + Shift + R</b>
                  </p>
                </li>
                <li>
                  <p>
                    For Mac :&nbsp;
                    <b>Press Cmd + Shift + R</b>
                  </p>
                </li>
              </ol>
            </li>
            <li className="check-internet-text text-left">
              <b>Check Internet</b>
              <p>Ensure your internet connection is working.</p>
            </li>
            <li className="contact-support-text text-left">
              <b>Contact Support</b>
              <p>If still unresolved, please reach out to customer support.</p>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

interface OfflineComponentProps {
  onClick: any;
}

export default OfflineComponent;
