import React from 'react';
import { func, shape, string } from 'prop-types';

const AddItemButton = ({
  handleClick, style, customClassName, dataCy,
}) => (
  <div className="form-group" style={style}>
    <div className="col-md-12 text-right">
      <button id="add-new-item" data-cy={dataCy} className={`btn btn-primary-outline text-bold ${customClassName}`} onClick={handleClick} tabIndex={0} type="button">
        <i className="fa fa-plus-circle" />
        &nbsp;
        ADD
      </button>
    </div>
  </div>
);

AddItemButton.propTypes = {
  handleClick: func.isRequired,
  style: shape({}),
  customClassName: string,
  dataCy: string,
};

AddItemButton.defaultProps = {
  style: {},
  customClassName: '',
  dataCy: 'add-new-item',
};

export default AddItemButton;
