import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchOrderTermsBasicInfo } from '@saga/order_terms';
import find from 'lodash/find';
import {
  saveOrder as saveOrderAction, fetchOrder as fetchOrderAction,
  updateOrder as updateOrderAction, duplicateOrder as duplicateOrderAction,
} from '@actions/orders';
import { getCustomerAddresses as getCustomerAddressesAction } from '@saga/customers';
import * as rootActions from '@actions/root';
import { baseUrl, itemDataKey } from '@constants/orders';
import { fetchEligibleOffers as fetchEligibleOffersAction } from '@saga/eligible_offers';
import { getIdFromSearchQuery, getSelectedItem, getTenantLocalDateTime } from '@helpers/common_helper';
import { fetchBasicInfoRequest } from '@helpers/fetch_basic_info_api';
import { sagaPreWatcherAction as sagaPreWatcher } from '@actions/common';
import OrderForm from '../../components/orders/Form';
import withHandleIsLoadingState from '../../hoc/withHandleIsLoadingState';

const basicInfoModuleNames = ['branches', 'users'];
const currentModuleName = 'orders';
const moduleNames = basicInfoModuleNames.concat([currentModuleName, 'products']);

// Types
type Props = {
  orders: Record<string, any>;
  products: Record<string, any>;
  branches: Record<string, any>;
  users: Record<string, any>;
  current_user: Record<string, any>;
  notification?: Record<string, any>;
  settings?: Record<string, any>;
  match: Record<string, any>;
  history: Record<string, any>;
  fetchOrder: any;
  saveOrder: any;
  updateOrder: any;
  getCustomerAddresses: any;
  sagaPreWatcherAction: any;
  fetchEligibleOffers: any;
  order_terms: any;
  fetchOrderTermsBasicInfo(args: any): any;
  duplicateOrder(orderId: string): any;
  shouldGenerateDuplicateOrder?: boolean;
  location: any;
}
type State = {
  customer_addresses: any[],
  helperTexts: any[],
}

class Credate extends Component<Props, State> {
  public static defaultProps = {
    notification: {},
    settings: {},
    shouldGenerateDuplicateOrder: false,
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      customer_addresses: [],
      helperTexts: [],
    };
  }

  componentDidMount() {
    const {
      fetchOrder, fetchEligibleOffers, match, fetchOrderTermsBasicInfo,
      duplicateOrder, location,
    } = this.props;

    const { params } = match;
    if (params.id) {
      fetchOrder(params.id)
        .then((response) => {
          const order = response?.data?.order || {};
          const { customer_id, id, date } = order || {};
          if (customer_id && id && date) {
            const url = '/sales/offers/eligible_for_order';
            const redirectUrl = `${baseUrl}/${id}/edit`;
            const args = { customer_id, order_id: id, date: (date || '') };
            fetchEligibleOffers({ url, redirectUrl, params: args });
          }

          if (customer_id) {
            this.setCustomerAddresses({ customer_id });
          }
        });
    }

    const orderId = getIdFromSearchQuery({ location, idKey: 'order_id' });
    if (orderId) {
      duplicateOrder(orderId)
        .then((response) => {
          const customerId = response?.data?.order?.customer_id || '';

          if (customerId && orderId) {
            const url = '/sales/offers/eligible_for_order';
            const redirectUrl = `${baseUrl}/${orderId}/edit`;
            const args = {
              customer_id: customerId,
              order_id: orderId,
              date: getTenantLocalDateTime(),
            };
            fetchEligibleOffers({ url, redirectUrl, params: args });
          }

          if (customerId) {
            this.setCustomerAddresses({ customer_id: customerId });
          }
        });
    }
    // } else {
    //   const url = '/sales/offers/eligible_for_order';
    //   const redirectUrl = `${baseUrl}/new`;
    //   const args = { };
    //   fetchEligibleOffers({ url, redirectUrl, params: args });
    // }
    fetchBasicInfoRequest(basicInfoModuleNames);

    fetchOrderTermsBasicInfo({});
  }

  setCustomerAddresses = ({ customer_id }) => {
    const { getCustomerAddresses } = this.props;
    const search_params = { name_or_mobile_or_phone_or_line_1_or_city_or_state_or_postal_code_cont: '' };
    getCustomerAddresses({ search_params }, customer_id)
      .then((response) => {
        const addresses = response?.data?.addresses || [];
        this.setState({ customer_addresses: addresses });
      });
  }

  saveOrderForm = (order) => {
    const { saveOrder, updateOrder, history } = this.props;
    if (order.id) {
      return updateOrder(order)
        .then((response) => {
          const { meta } = response?.data || {};
          const item = (response?.data?.[itemDataKey]) || {};
          history.push(`${baseUrl}/${item.id}`);
          rootActions.successNotification({ message: meta?.notice || '', renderView: true });
        });
    }
    return saveOrder(order)
      .then((response) => {
        const { meta } = response?.data || {};
        const item = (response?.data?.[itemDataKey]) || {};
        history.push(`${baseUrl}/${item.id}`);
        rootActions.successNotification({ message: meta?.notice || '', renderView: true });
      });
  }

  render() {
    const {
      orders, products, branches, users, order_terms, current_user, getCustomerAddresses,
      sagaPreWatcherAction, fetchEligibleOffers, settings, fetchOrderTermsBasicInfo, match,
      shouldGenerateDuplicateOrder,
    } = this.props;
    const { customer_addresses } = this.state;
    const { params } = match;

    return (
      <OrderForm
        current_user={current_user}
        orders={orders}
        products={products?.items || []}
        branches={branches?.items || []}
        users={users?.items || []}
        order_terms={order_terms?.items || []}
        customer_addresses={customer_addresses}
        settings={settings}
        getCustomerAddresses={getCustomerAddresses}
        saveOrder={this.saveOrderForm}
        sagaPreWatcherAction={sagaPreWatcherAction}
        fetchEligibleOffers={fetchEligibleOffers}
        fetchOrderTermsBasicInfo={fetchOrderTermsBasicInfo}
        forEdit={!!params.id}
        shouldGenerateDuplicateOrder={shouldGenerateDuplicateOrder}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { id } = props.match.params;
  const {
    root_reducer, auth, settings, order_terms,
  } = state;
  let data = {};

  let shouldGenerateDuplicateOrder = false;

  moduleNames.forEach((moduleName) => {
    if (currentModuleName === moduleName) {
      let items = getSelectedItem({ id, selected_state: state[moduleName] });
      const orderId = getIdFromSearchQuery({ location: props.location, idKey: 'order_id' });
      if (orderId) {
        const order = find(state[moduleName]?.items, (item) => !item?.id);
        items = [{ ...order, id: '', sequence: '' }];
        shouldGenerateDuplicateOrder = true;
      }
      data = { ...data, [moduleName]: { ...state[moduleName], items } };
    } else {
      data = { ...data, [moduleName]: state[moduleName] };
    }
  });

  return {
    ...data,
    order_terms,
    settings,
    current_user: auth?.current_user,
    notification: root_reducer?.notification,
    shouldGenerateDuplicateOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveOrder: saveOrderAction,
    fetchOrder: fetchOrderAction,
    updateOrder: updateOrderAction,
    getCustomerAddresses: getCustomerAddressesAction,
    fetchEligibleOffers: fetchEligibleOffersAction,
    sagaPreWatcherAction: sagaPreWatcher,
    fetchOrderTermsBasicInfo,
    duplicateOrder: duplicateOrderAction,
  }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withHandleIsLoadingState(Credate, moduleNames));
