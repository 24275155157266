import unionBy from 'lodash/unionBy';
import { SET_CREDIT_NOTE_E_INVOICE_DATA } from '@constants/e_invoice';
import {
  SET_CREDIT_NOTES, ADD_CREDIT_NOTE, CREDIT_NOTE_FETCHED, CREDIT_NOTE_UPDATED,
  CREDIT_NOTE_DELETED, defaultSearchParams, moduleName, GENERATE_CREDIT_NOTE_FROM_RETURN,
} from '@constants/credit_notes';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function creditNotes(state = initialState, action) {
  switch (action.type) {
    case SET_CREDIT_NOTES: return setItems(state, action);
    case ADD_CREDIT_NOTE: return addItem(state, action);
    case CREDIT_NOTE_FETCHED: return fetchItem(state, action);
    case CREDIT_NOTE_UPDATED: return updateItem(state, action);
    case CREDIT_NOTE_DELETED: return deleteItem(state, action);
    case SET_CREDIT_NOTE_E_INVOICE_DATA: {
      const items = state?.items?.map((item) => {
        if (item?.id !== action?.payload?.id) return item;
        return { ...item, e_invoice: action?.payload?.e_invoice };
      });
      return {
        ...state, items, isFetching: false, isError: false, errors: {},
      };
    }
    case GENERATE_CREDIT_NOTE_FROM_RETURN: {
      const item = action?.item || {};
      const oldCreditNotesState = state?.items || [];
      const unionCreditNotesState = unionBy([{ ...item }], oldCreditNotesState, 'id');
      return {
        ...state,
        items: unionCreditNotesState,
        isError: false,
        errors: {},
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
