import { HOME_BREADCRUMB_LISTING } from './common';

export const BRANCH = 'BRANCH';
export const SET_BRANCHES = 'SET_BRANCHES';
export const ADD_BRANCH = 'ADD_BRANCH';
export const BRANCH_FETCHED = 'BRANCH_FETCHED';
export const BRANCH_UPDATED = 'BRANCH_UPDATED';
export const BRANCH_DELETED = 'BRANCH_DELETED';
export const AUTOCOMPLETE_BRANCHES = 'AUTOCOMPLETE_BRANCHES';

export const FORM_INFORMATION_TITLE = 'BRANCH INFORMATION';
export const ADD_NEW_BRANCH = 'Add New Branch';
export const RESET_BRANCH_ERRORS = 'RESET_BRANCH_ERRORS';

export const title = 'Branches';
export const singularTitle = 'Branch';
export const moduleName = 'branches';
export const baseUrl = '/branches';
export const redirectUrl = '/branches';
export const listingDataKey = 'branches';
export const itemDataKey = 'branch';

export const branchConstants = {
  item: BRANCH,
  setItems: SET_BRANCHES,
  addItem: ADD_BRANCH,
  setItem: BRANCH_FETCHED,
  updateItem: BRANCH_UPDATED,
  deleteItem: BRANCH_DELETED,
  autocompleteItems: AUTOCOMPLETE_BRANCHES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Branches';
export const TargetBaseUrl = 'branches';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
export const APPLICABLE_TO = [{ label: 'Registered', value: 'Registered' }, { label: 'Unregistered', value: 'Unregistered' }];

export const handledErrorKeys = {
  master: ['name', 'code', 'email', 'gstin', 'pan', 'website'],
  address: ['name', 'phone', 'alt_phone', 'mobile', 'landmark', 'line_1', 'line_2', 'city', 'country_code', 'state', 'postal_code'],
  branch_zone_taxes: ['tax', 'tax_id', 'zone', 'zone_id', 'applicable_to'],
  branch_warehouses: ['warehouse', 'warehouse_id'],
};
