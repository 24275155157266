import { unionBy } from 'lodash';
import { SET_CURRENT_USER, SET_USER_ACCOUNT_PROFILE } from '@constants/auth';
import { SET_NEW_ORGANISATION } from '@component/organisations/constants';

export const initialState = {
  isAuthenticated: false,
  current_user: {},
  isFetching: false,
  isError: false,
  errors: {},
  tenant_accesses: [],
  organisations: [],
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        isAuthenticated: action?.isAuthenticated || false,
        current_user: action?.account || {},
        isFetching: false,
        isError: action?.isError || false,
        errors: action?.errors || {},
        tenant_accesses: action?.account?.tenant_accesses || [],
        organisations: action?.account?.organisations || [],
      };
    }
    case SET_USER_ACCOUNT_PROFILE: {
      return {
        ...state,
        current_user: action?.account || {},
        isFetching: false,
        isError: false,
        errors: {},
        tenant_accesses: action?.account?.tenant_accesses || [],
        organisations: action?.account?.organisations || [],
      };
    }
    case SET_NEW_ORGANISATION: {
      const oldOrganisations = state?.organisations || [];
      const organisation = action?.organisation || {};
      const updatedOrganisations = unionBy([...oldOrganisations], [{ ...organisation }], 'id');
      return {
        ...state,
        isFetching: false,
        isError: false,
        errors: {},
        organisations: updatedOrganisations || [],
      };
    }
    default:
      return state;
  }
}
