import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import { ControlLabel } from 'react-bootstrap';

interface Props {
  displayName: string;
  id: string;
  view: string;
  displayEl?(): ReactNode;
  isRequired?: boolean;
  style?: CSSProperties;
  className?: string;
  dataCy?: string;
}

class FormFieldLabel extends PureComponent<Props, {}> {
  render() {
    const {
      displayName, displayEl, isRequired,
      className, style, dataCy,
    } = this.props;


    const defaultStyle: CSSProperties = {
      ...style,
      width: '100%',
      textAlign: 'left',
      marginRight: 8,
    };

    return (
      <ControlLabel>
        <span
          className={className}
          style={defaultStyle}
          role="presentation"
        >
          {displayEl ? displayEl() : displayName}
          {isRequired && (
            <>
              &nbsp;
              <sup data-cy={dataCy || ''} className="text-danger">*</sup>
            </>
          )}
        </span>
      </ControlLabel>
    );
  }
}

export default FormFieldLabel;
