import Loadable from 'react-loadable';
import Loading from '@component/common/page_loading';

// We use loadable for credit_notes show page.
const Offers = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOffersOffers" */ '@containers/offers/Index'),
  loading: Loading,
});

const OfferCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOffersCredate" */ '@containers/offers/Credate'),
  loading: Loading,
});

// We use loadable for order show page.
const OfferShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOffersShow" */ '@containers/offers/Show'),
  loading: Loading,
});

const OffersRoutes = [
  {
    path: '/offers/new', name: 'Offers New', component: OfferCredate,
  },
  {
    path: '/offers/:id/edit', name: 'Offers Edit', component: OfferCredate,
  },
  {
    path: '/offers/:id', name: 'Offers', component: OfferShow,
  },
  {
    path: '/offers', name: 'Offers', component: Offers,
  },
];

export default OffersRoutes;
