import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Navbar, Nav } from 'react-bootstrap';
import { PRODUCT_NAME } from '@constants/auth';
import Loadable from 'react-loadable';
// import PRODUCT_LOGO_WITH_NAME from '@assets/img/distributo_product_logo_with_name_2.svg';
import PRODUCT_LOGO_WITH_NAME from '@assets/img/distributo_product_logo_with_name.svg';
import { setSidebarMinimize as setSidebarMinimizeAction } from '@actions/root';
import ToggleSidebarButton from '@component/common/ToggleSidebarButton';
import Breadcrumb from '@component/common/Breadcrumb';
import UserMenu from '@component/common/UserMenu';
import history from '@component/history';
import { MODULE_REPORTS_DATA } from '@component/common/module_reports/constants';
import {
  Module, help, form, faq,
} from '@component/contents/constants';
import Loading from '@component/common/Loading';
import FormHeader from './FormHeader';

const VideoModalLink = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsContentsVideoModalLink" */ '@component/contents/video_modal/link'),
  loading: Loading,
});

const ModuleConfigLink = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonFlashMessage" */ '@component/module_config'),
  loading: Loading,
});

const ModuleReportLink = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonModuleReportsModuleSettingLink" */ '@component/common/module_reports/module_reports_link'),
  loading: Loading,
});

interface BasicHeaderProps {
  title?: string;
  moduleName?: string;
  breadcrumb_listing?: any[];
  isSidenavMinimize: boolean;
  setSidebarMinimize: (args: boolean) => any;
  headerClassName?: string;
  showDropDownMenuItems?: boolean;
  updateFormComponent?: (selectedMenuItem: any) => void;
  showBreadcrumb?: boolean;
  showDistributoProductLogoInHeader?: boolean;
  isHelpLinkVisible?: boolean;
  isModuleConfigVisible?: boolean;
  isReportLinkVisible?: boolean;
}

const BasicHeader = ({
  title, breadcrumb_listing, isSidenavMinimize, setSidebarMinimize,
  headerClassName, showDropDownMenuItems, moduleName, updateFormComponent,
  showBreadcrumb, showDistributoProductLogoInHeader, isModuleConfigVisible,
  isHelpLinkVisible, isReportLinkVisible,
}: BasicHeaderProps) => {
  let newTitle = title;
  if (title) {
    newTitle = title.split('_').join(' ');
  }

  return (
    <div className={classnames('navbar-fixed-top', { [headerClassName]: !!headerClassName })}>
      <Navbar fluid>
        {
          isSidenavMinimize
            ? <ToggleSidebarButton setSidebarMinimize={setSidebarMinimize} />
            : null
        }
        {
          showBreadcrumb && !showDistributoProductLogoInHeader
            ? (
              <Navbar.Header className="col-md-4">
                <Navbar.Brand>
                  {
                    showDropDownMenuItems ? (
                      <FormHeader
                        title={newTitle}
                        breadcrumbListing={breadcrumb_listing}
                        moduleName={moduleName}
                        updateFormComponent={updateFormComponent}
                      />
                    ) : (
                      <Breadcrumb
                        title={newTitle}
                        breadcrumb_listing={breadcrumb_listing}
                      />
                    )
                  }
                </Navbar.Brand>
                <Navbar.Toggle />
              </Navbar.Header>
            )
            : null
        }
        <Navbar.Collapse className={showBreadcrumb && !showDistributoProductLogoInHeader ? 'col-md-8' : 'col-md-12'}>
          {
            !showBreadcrumb && showDistributoProductLogoInHeader
              ? (
                <Nav className="distributo-product-image-nav col-md-8">
                  <div
                    className="distributo-product-image-wrapper"
                    onClick={() => history.push('/')}
                  >
                    <img src={PRODUCT_LOGO_WITH_NAME} alt={PRODUCT_NAME} className="product-logo" />
                  </div>
                </Nav>
              )
              : null
          }
          <Nav pullRight className="logout-nav">
            <UserMenu />
          </Nav>
          {
            isReportLinkVisible && Object.keys(MODULE_REPORTS_DATA).includes(moduleName)
              ? (
                <Nav pullRight className="module-report-link-nav">
                  <ModuleReportLink
                    helpPage={form}
                  />
                </Nav>
              ) : null
          }
          {
            isModuleConfigVisible
              ? (
                <Nav pullRight className="module-config-link-nav">
                  <ModuleConfigLink
                    helpPage={form}
                  />
                </Nav>
              )
              : null
          }
          {
            isHelpLinkVisible
              ? (
                <Nav pullRight className="video-help-link-nav">
                  <VideoModalLink
                    searchParams={{
                      type_in: [help, faq],
                      tags_type_eq: Module,
                    }}
                    customModuleName={moduleName}
                    helpPage={form}
                  />
                </Nav>
              )
              : null
          }
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

BasicHeader.defaultProps = {
  title: '',
  moduleName: '',
  breadcrumb_listing: [],
  headerClassName: '',
  showDropDownMenuItems: false,
  updateFormComponent: () => ({}),
  showBreadcrumb: true,
  showDistributoProductLogoInHeader: false,
  isHelpLinkVisible: true,
  isModuleConfigVisible: true,
  isReportLinkVisible: true,
};

function mapStateToProps(state) {
  const { root_reducer: rootReducer } = state;

  return {
    isSidenavMinimize: rootReducer?.isSidenavMinimize,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSidebarMinimize: setSidebarMinimizeAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicHeader);
