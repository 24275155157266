import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BEAT_ROUTE_ASSIGNMENTS, ADD_BEAT_ROUTE_ASSIGNMENT, BEAT_ROUTE_ASSIGNMENT_FETCHED,
  BEAT_ROUTE_ASSIGNMENT_UPDATED, BEAT_ROUTE_ASSIGNMENT_DELETED, defaultSearchParams,
} from '../constants/beat_route_assignments';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'user_name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
};

export default function beatRouteAssignments(state = initialState, action) {
  switch (action.type) {
    case SET_BEAT_ROUTE_ASSIGNMENTS: return setItems(state, action);
    case ADD_BEAT_ROUTE_ASSIGNMENT: return addItem(state, action);
    case BEAT_ROUTE_ASSIGNMENT_FETCHED: return fetchItem(state, action);
    case BEAT_ROUTE_ASSIGNMENT_UPDATED: return updateItem(state, action);
    case BEAT_ROUTE_ASSIGNMENT_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
