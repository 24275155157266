import { HOME_BREADCRUMB_LISTING } from '@constants/common';
// PIECES
// PIECE
export const INVENTORY_PIECE = 'INVENTORY_PIECE';
export const SET_INVENTORY_PIECES = 'SET_INVENTORY_PIECES';
export const ADD_INVENTORY_PIECE = 'ADD_INVENTORY_PIECE';
export const INVENTORY_PIECE_FETCHED = 'INVENTORY_PIECE_FETCHED';
export const INVENTORY_PIECE_UPDATED = 'INVENTORY_PIECE_UPDATED';
export const INVENTORY_PIECE_DELETED = 'INVENTORY_PIECE_DELETED';
export const AUTOCOMPLETE_INVENTORY_PIECES = 'AUTOCOMPLETE_INVENTORY_PIECES';

export const FORM_INFORMATION_TITLE = 'INVENTORY PIECE INFORMATION';
export const ADD_NEW_INVENTORY_PIECE = 'Add New Inventory Piece';
export const RESET_INVENTORY_PIECE_ERRORS = 'RESET_INVENTORY_PIECE_ERRORS';
export const SHOW_INVENTORY_PIECE = 'SHOW_INVENTORY_PIECE';

export const FETCH_INVENTORY_PIECE_NUMBERS = 'FETCH_INVENTORY_PIECE_NUMBERS';

export const BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_INVOICE_ITEMS = 'BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_INVOICE_ITEMS';
export const BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_RETURN_ITEMS = 'BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_RETURN_ITEMS';
export const BULK_CREATE_INVENTORY_PIECES_FOR_RETURN_ITEMS = 'BULK_CREATE_INVENTORY_PIECES_FOR_RETURN_ITEMS';
export const BULK_CREATE_INVENTORY_PIECES_FOR_INVOICE_ITEMS = 'BULK_CREATE_INVENTORY_PIECES_FOR_INVOICE_ITEMS';
export const BULK_CREATE_INVENTORY_PIECES_FOR_STOCK_TRANSFER_ITEMS = 'BULK_CREATE_INVENTORY_PIECES_FOR_STOCK_TRANSFER_ITEMS';

export const title = 'Inventory Pieces';
export const singularTitle = 'Inventory Piece';
export const moduleName = 'inventory_pieces';
export const baseUrl = '/inventory_pieces';
export const redirectUrl = '/inventory_pieces';
export const listingDataKey = 'inventory_pieces';
export const itemDataKey = 'inventory_piece';

export const defaultSearchParams = {
  product_name_or_inventory_manufacturer_piece_number_cont: '',
  inventory_manufacturer_piece_mfd_date_gteq: '',
  inventory_manufacturer_piece_mfd_date_lteq: '',
  inventory_manufacturer_piece_expiry_date_gteq: '',
  inventory_manufacturer_piece_expiry_date_lteq: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Inventory Pieces';
export const TargetBaseUrl = 'inventory_pieces';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: [],
  inventory_manufacturer_batch_attributes: ['product_id', 'product', 'number', 'mfd_day', 'mfd_month', 'mfd_year', 'expiry_day', 'expiry_month', 'expiry_year'],
};

export const inventoryPieceConstants = {
  item: INVENTORY_PIECE,
  setItems: SET_INVENTORY_PIECES,
  addItem: ADD_INVENTORY_PIECE,
  setItem: INVENTORY_PIECE_FETCHED,
  updateItem: INVENTORY_PIECE_UPDATED,
  deleteItem: INVENTORY_PIECE_DELETED,
  resetErrors: RESET_INVENTORY_PIECE_ERRORS,
  autocompleteItems: AUTOCOMPLETE_INVENTORY_PIECES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  formInformationTitle: FORM_INFORMATION_TITLE,
  handledErrorKeys,
  breadcrumbListing: BREADCRUMB_LISTING,
  indexBreadcrumbListing: INDEX_BREADCRUMB_LISTING,
  newBreadcrumbListing: NEW_BREADCRUMB_LISTING,
  defaultSearchParamKey: 'product_name_or_inventory_manufacturer_piece_number_cont',
};
