import { HOME_BREADCRUMB_LISTING, SUPPLIER, CUSTOMER } from './common';
import { INVOICE_STATES } from './invoices';
import { PAYMENT_STATES } from './payments';
import { PURCHASE_INVOICE_STATES } from './purchase_invoices';
import { PAYMENT_OUT_STATES } from './payment_outs';
import {
  customerFilter, statusFilter, supplierFilter, partyTypeFilter,
  paymentModeFilter, refVoucherTypeFilter,
} from '../components/common/advance_search/common';

export const INTEGRATE_LEDGER = 'integrate_ledger';
export const INTEGRATE_INVENTORY = 'integrate_inventory';
export const INTEGRATE_DAYBOOK = 'integrate_daybook';
export const TALLY = 'TALLY';

export const ALL_DATA = 'All Data';
export const ADVANCED_OPTIONS = 'Advanced Options';
export const GUIDELINES = 'Guidelines';

export const TALLY_INTEGRATION = HOME_BREADCRUMB_LISTING.concat([{ title: 'Tally Integration', target: '', active: true }]);

// We use this properties name in other tally integration related component.
export const UPDATED_GTEQ = 'updated_at_gteq';
export const UPDATED_LTEQ = 'updated_at_lteq';
export const STATE_IN = 'state_in';
export const CUSTOMER_ID_IN = 'customer_id_in';
export const PAYMENT_MODE_ID_IN = 'payment_mode_id_in';
export const CREDITABLE_TYPE_CONT = 'creditable_type_cont';
export const SUPPLIER_ID_IN = 'supplier_id_in';

// We use this schema name in other tally integration related component.
export const DAYBOOK = 'daybook';
export const LEDGER = 'ledgers';
export const INVENTORY = 'Inventory';
export const INVOICE = 'invoice';
export const PAYMENT = 'payment';
export const CREDIT_NOTE = 'credit_note';
export const DEBIT_NOTE = 'debit_note';
export const PURCHASE_INVOICE = 'purchase_invoice';
export const PAYMENT_OUT = 'payment_out';
export const STOCK_TRANSFER = 'Stock_transfer';
export const STOCK_ENTRY = 'Stock_entry';

// We use this schema title in other tally integration related component.
export const DAYBOOKS = 'DayBook';
export const LEDGERS = 'Ledgers';
export const INVENTORIES = 'Inventory';
export const INVOICES = 'Invoices';
export const PAYMENTS = 'Payments';
export const CREDIT_NOTES = 'Credit Notes';
export const DEBIT_NOTES = 'Debit Notes';
export const PURCHASE_INVOICES = 'Purchase Invoices';
export const PAYMENT_OUTS = 'Payment Outs';
export const STOCK_TRANSFERS = 'Stock Transfers';
export const MANUAL_STOCK_ENTRIES = 'Manual Stock Entries';

export const DATE = 'date';
export const SELECT = 'select';
export const MULTI_SELECT = 'multi_select';

export const DAYBOOK_SCHEMA = [
  [{ ...customerFilter, className: 'col-md-6' }],
];

export const INVOICES_SCHEMA = [
  [{ ...customerFilter, className: 'col-md-6' }, { ...statusFilter, options: INVOICE_STATES, className: 'col-md-6' }],
];

export const PAYMENTS_SCHEMA = [
  [{ ...customerFilter, className: 'col-md-6' }, { ...statusFilter, options: PAYMENT_STATES, className: 'col-md-6' }],
  [{ ...paymentModeFilter, className: 'col-md-6' }],
];

export const CREDIT_NOTES_SCHEMA = [
  [
    { ...partyTypeFilter, className: 'col-md-4', clearDependantFieldsValue: { party_id_in: [] } },
    {
      ...supplierFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'party_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-8',
      partyTypeKey: 'party_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    },
  ],
];

export const DEBIT_NOTES_SCHEMA = [
  [
    { ...partyTypeFilter, className: 'col-md-4', clearDependantFieldsValue: { party_id_in: [] } },
    {
      ...supplierFilter,
      key: 'parties',
      placeholder: 'Select Parties',
      label: 'Parties',
      query_key: 'party_id_in',
      //   should_prefetch: true,
      //   call_api: false,
      className: 'col-md-8',
      partyTypeKey: 'party_type_eq',
      fieldDependantConditions: {
        default: { key: 'parties', should_prefetch: false, call_api: false },
        [SUPPLIER]: { key: 'suppliers', should_prefetch: true, call_api: false },
        [CUSTOMER]: { key: 'customers', should_prefetch: false, call_api: true },
      },
    },
  ],
  [
    {
      ...refVoucherTypeFilter, query_key: 'ref_voucher_type_eq', key: 'ref_voucher_type_eq', className: 'col-md-4',
    },
  ],
];

export const PURCHASE_INVOICES_SCHEMA = [
  [{ ...statusFilter, options: PURCHASE_INVOICE_STATES, className: 'col-md-6' }, { ...supplierFilter, className: 'col-md-6' }],
];

export const PAYMENT_OUTS_SCHEMA = [
  [{ ...statusFilter, options: PAYMENT_OUT_STATES, className: 'col-md-6' }, { ...supplierFilter, className: 'col-md-6' }],
];

export const STOCK_TRANSFERS_SCHEMA = [];

export const STOCK_ENTRIES_SCHEMA = [];
