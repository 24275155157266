import { filter, unionBy } from 'lodash';
import { SET_NOTICES, NOTICE_DELETED, ADD_NOTICE } from '../constants/notices';
import { addItem, updateObject } from './common';

export const noticesInitialState = {
  items: [],
};

const deleteNotice = (state: { items: any[]; total_count?: number; }, action: { id: number; }) => {
  const updatedActionState = {
    items: filter(state?.items, (item) => item?.id !== action?.id),
    total_count: state?.total_count - 1,
  };
  return updateObject(state, updatedActionState);
};

const notices = (state = noticesInitialState, action: any) => {
  switch (action?.type) {
    // case SET_NOTICES: return setItems(state, action);
    case SET_NOTICES: {
      const items = unionBy(action?.items || [], state?.items || [], 'id');
      return { ...state, ...{ items } };
    }
    case NOTICE_DELETED: return deleteNotice(state, action);
    case ADD_NOTICE: return addItem(state, action);
    default:
      return state;
  }
};

export default notices;
