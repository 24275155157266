import map from 'lodash/map';
import { HOME_BREADCRUMB_LISTING } from './common';

// Views for helper text
export const PAYMENT_OUT_FORM_VIEW = 'payment_out_form';

export const PAYMENT_OUT = 'PAYMENT_OUT';
export const SET_PAYMENT_OUTS = 'SET_PAYMENT_OUTS';
export const ADD_PAYMENT_OUT = 'ADD_PAYMENT_OUT';
export const PAYMENT_OUT_FETCHED = 'PAYMENT_OUT_FETCHED';
export const PAYMENT_OUT_UPDATED = 'PAYMENT_OUT_UPDATED';
export const PAYMENT_OUT_DELETED = 'PAYMENT_OUT_DELETED';
export const PAYMENT_OUT_EDIT = 'PAYMENT_OUT_EDIT';
// export const UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED = 'UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED';
export const UPDATE_SELECTED_BULK_PAYMENT_OUTS_STATUS_TO_ACCOUNTED = 'UPDATE_SELECTED_BULK_PAYMENT_OUTS_STATUS_TO_ACCOUNTED';

export const FETCH_PAYMENT_OUTS = 'FETCH_PAYMENT_OUTS';

export const title = 'Payment outs';
export const singularTitle = 'Payment out';
export const moduleName = 'payment_outs';
export const baseUrl = '/payment_outs';
export const redirectUrl = '/payment_outs';
export const listingDataKey = 'payment_outs';
export const itemDataKey = 'payment_out';

export const paymentOutConstants = {
  item: PAYMENT_OUT,
  setItems: SET_PAYMENT_OUTS,
  addItem: ADD_PAYMENT_OUT,
  setItem: PAYMENT_OUT_FETCHED,
  updateItem: PAYMENT_OUT_UPDATED,
  deleteItem: PAYMENT_OUT_DELETED,
  changeItemStatus: UPDATE_SELECTED_BULK_PAYMENT_OUTS_STATUS_TO_ACCOUNTED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// SETUP-SECTION quick add
export const FORM_INFORMATION_TITLE = 'PAYMENT OUT INFORMATION';
export const RESET_PAYMENT_OUT_ERRORS = 'RESET_PAYMENT_OUT_ERRORS';
export const ADD_NEW_PAYMENT_OUT = 'Add New Payment Out';
// !SETUP-SECTION quick add

export const SHOW_PAYMENT_OUT = 'SHOW_PAYMENT_OUT';

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  amount_gt: '',
  amount_eq: '',
  amount_lt: '',
  unallocated_amount_gt: '',
  instrument_no_cont: '',
  supplier_address_line_1_cont: '',
  supplier_type_eq: '',
  state_in: [],
  supplier_id_in: [],
  payment_mode_id_in: [],
  user_id_in: [],
  branch_id_in: [],
  purchase_invoice_payments_purchase_invoice_variants_brand_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_instrument_no_or_supplier_name_or_supplier_gstin_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['users', 'suppliers', 'branches', 'bank_accounts', 'payment_modes', 'banks', 'brands', 'customers', 'voucher_books'];

// Payment Out States
export const open = 'open';
export const accounted = 'accounted';
export const failed = 'failed';

export const PAYMENT_OUT_STATES = [{ label: 'Open', value: open }, { label: 'Accounted', value: accounted }, { label: 'Failed', value: failed }];

export const PAYMENT_OUT_STATES_FOR_REPORTS = map(PAYMENT_OUT_STATES, (state) => ({ id: state.value, name: state.label }));

// Payment Out Modes
export const cash = 'Cash';
export const cheque = 'Cheque';
export const dd = 'DD';
export const neft = 'NEFT/RTGS/Online Transfer';
export const paytm = 'Paytm';
export const other = 'Other';

export const PAYMENT_OUT_MODES = [
  { label: cash, value: cash }, { label: cheque, value: cheque },
  { label: dd, value: dd }, { label: neft, value: neft }, { label: paytm, value: paytm },
  { label: other, value: other },
];

// export const ledger = 'ledger';
// export const auto = 'auto';
// export const manual = 'manual';

// export const ALLOCATIONS = [{ label: 'Ledger', value: ledger }, { label: 'Auto', value: auto },
//   { label: 'Manual', value: manual }];

// export const ACTIVE_STATUS = [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }];

// Breadcrumb
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'supplier', 'supplier_id', 'branch', 'branch_id', 'amount', 'payment_mode', 'payment_mode_id', 'instrument_no', 'instrument_date', 'instrument_bank', 'instrument_bank_id', 'bank_account', 'bank_account_id', 'notes'],
  purchase_invoice_payments: ['purchase_invoice', 'purchase_invoice_id', 'amount'],
};
