import { HOME_BREADCRUMB_LISTING } from '../common';

export const CHART_OF_ACCOUNT = 'CHART_OF_ACCOUNT';
export const SET_CHART_OF_ACCOUNTS = 'SET_CHART_OF_ACCOUNTS';
export const ADD_CHART_OF_ACCOUNT = 'ADD_CHART_OF_ACCOUNT';
export const CHART_OF_ACCOUNT_FETCHED = 'CHART_OF_ACCOUNT_FETCHED';
export const CHART_OF_ACCOUNT_UPDATED = 'CHART_OF_ACCOUNT_UPDATED';
export const CHART_OF_ACCOUNT_DELETED = 'CHART_OF_ACCOUNT_DELETED';
export const AUTOCOMPLETE_CHART_OF_ACCOUNT = 'AUTOCOMPLETE_CHART_OF_ACCOUNT';
export const RESET_CHART_OF_ACCOUNT_ERRORS = 'RESET_CHART_OF_ACCOUNT_ERRORS';
export const AUTOCOMPLETE_ALL_CHART_OF_ACCOUNTS = 'AUTOCOMPLETE_ALL_CHART_OF_ACCOUNTS';
export const AUTOCOMPLETE_BASIC_INFO_CHART_OF_ACCOUNTS = 'AUTOCOMPLETE_BASIC_INFO_CHART_OF_ACCOUNTS';
export const FORM_INFORMATION_TITLE = 'ACCOUNT INFORMATION';
export const ADD_NEW_CHART_OF_ACCOUNT = 'Add New Account';

export const AMOUNTS = 'AMOUNTS';
export const SUB_ACCOUNTS = 'SUB_ACCOUNTS';

export const OPENING_BALANCE_TYPES = [
  { label: 'Credit', value: true },
  { label: 'Debit', value: false },
];

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

// export const types = [{ label: 'Asset', value: 'Plutus::Asset' }, { label: 'Liability', value: 'Plutus::Liability' },
//   { label: 'Revenue', value: 'Plutus::Revenue' }, { label: 'Expense', value: 'Plutus::Expense' },
//   { label: 'Equity', value: 'Plutus::Equity' }];
export const GROUP_LABEL = 'Group';
export const LEDGER_LABEL = 'Ledger';
export const GROUP_VALUE = true;
export const LEDGER_VALUE = false;

export const typesForQuickAddForm = [
  { label: 'Revenue', value: 'Plutus::Revenue' },
  { label: 'Expense', value: 'Plutus::Expense' },
];

export const title = 'Chart Of Accounts';
export const singularTitle = 'Chart Of Account';
export const moduleName = 'chart_of_accounts';
export const baseUrl = '/accounting/accounts';
export const redirectUrl = '/accounting/accounts';
export const listingDataKey = 'accounts';
export const itemDataKey = 'account';
export const permissionKey = 'plutus/accounts';

export const Title = 'Accounts';
export const TargetBaseUrl = 'accounting/accounts';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title, target: TargetBaseUrl, active: false }
]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title, target: TargetBaseUrl, active: true }
]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([
  { title: 'New', target: '', active: true }
]);

export const handledErrorKeys = {
  master: ['name', 'code', 'parent_id', 'opening_balance', 'opening_balance_date'],
};
