import { HOME_BREADCRUMB_LISTING } from './common';

export const SETTING = 'SETTING';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const SET_PAYMENT_MODES = 'SET_PAYMENT_MODES';
export const SET_ALL_STATES = 'SET_ALL_STATES';
export const SET_CLIENT_SETTINGS = 'SET_CLIENT_SETTINGS';
export const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS';
export const SET_CLIENT_UI_PERMISSIONS = 'SET_CLIENT_UI_PERMISSIONS';
export const SET_LETTER_OF_CREDIT_TYPES = 'SET_LETTER_OF_CREDIT_TYPES';
export const SET_COUNTRY_CODES = 'SET_COUNTRY_CODES';
export const SET_COUNTRY_DIAL_CODES = 'SET_COUNTRY_DIAL_CODES';
export const SET_TAX_ID_TYPES = 'SET_TAX_ID_TYPES';

export const FETCH_COUNTRY_DIAL_CODES = 'FETCH_COUNTRY_DIAL_CODES';

export const SETTINGS_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Settings', target: '', active: true }]);
