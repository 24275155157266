import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_REPORTS, REPORT_DELETED, defaultSearchParams, moduleName,
} from '@constants/reports';
import { setItems } from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  per_page: 50,
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case SET_REPORTS: return setItems(state, action);
    case REPORT_DELETED: {
      const deletedID = action.id;
      const parentID = action.parent_id;
      const newItems = state.items.map((parent_report) => {
        if (parent_report.id === parentID) {
          const custom_reports = parent_report.custom_reports.filter(
            (custom_report) => parseInt(custom_report?.id, 10) !== parseInt(deletedID, 10),
          );
          return { ...parent_report, custom_reports };
        }
        return parent_report;
      });
      const updated_action_state = {
        items: newItems,
        total_count: state.total_count - 1,
      };
      return {
        ...state, ...updated_action_state, isError: false, errors: {}, isFetching: false,
      };
    }
    default:
      return state;
  }
}
