import React from 'react';
import { bool, func } from 'prop-types';
import { Modal, Nav } from 'react-bootstrap';
import {
  offlineTitle, supportNumber1,
} from '@constants/common_constants';

const ErrorDialogModal = ({ show, closeModal }) => (
  <Modal
    show={show}
    onHide={closeModal}
  >
    <Modal.Header>
      <Modal.Title className="text-muted error-dialog-modal-header text-modal">
        {offlineTitle}
        <Nav pullRight className="close-listing-modal">
          <button type="button" className="close" onClick={closeModal}>
            <span className="btn btn-sm btn-default action-btn">
              <i className="fa fa-times make-cross-icon-thicker" aria-hidden="true" />
            </span>
          </button>
        </Nav>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="text-center offline-component-help">
        <ol className="main-order-list">
          <li className="reload-page-text text-left">
            <b>Hard Refresh</b>
            <ol className="secondary-order-list" type="a">
              <li>
                <p className="text-left">
                  For Windows :&nbsp;
                  <b>Press Ctrl + Shift + R</b>
                </p>
              </li>
              <li>
                <p>
                  For Mac :&nbsp;
                  <b>Press Cmd + Shift + R</b>
                </p>
              </li>
            </ol>
          </li>
          <li className="check-internet-text text-left">
            <b>Check Internet</b>
            <p>Ensure your internet connection is working.</p>
          </li>
          <li className="contact-support-text text-left">
            <b>Contact Support</b>
            <p>
              If still unresolved, please reach out to customer support at
              <br />
              {supportNumber1}
            </p>
          </li>
        </ol>
      </div>
    </Modal.Body>
  </Modal>
);

ErrorDialogModal.propTypes = {
  show: bool.isRequired,
  closeModal: func.isRequired,
};

export default ErrorDialogModal;
