import { ADD_ERRORS, CLEAR_ERRORS, FETCHING_REQUEST } from '../constants/errors';

export const initialState = {
  errors: {},
  notification_message: '',
  errorExist: false,
  fetchingRequest: false,
};

export default function errors(state = initialState, action) {
  switch (action.type) {
    case ADD_ERRORS: {
      return {
        ...state,
        errors: action.errors,
        notification_message: action.notification_message,
        errorExist: true,
        fetchingRequest: action.fetchingRequest,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: {},
        notification_message: '',
        errorExist: false,
        fetchingRequest: action.fetchingRequest,
      };
    }
    case FETCHING_REQUEST: {
      return { ...state, fetchingRequest: action.fetchingRequest };
    }
    default:
      return state;
  }
}
