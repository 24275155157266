import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_ADDRESS, DELETE_ADDRESS, FETCH_ADDRESS, FETCH_ADDRESSES, UPDATE_ADDRESS,
} from '@constants/addresses';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '@actions/common';
import {
  deleteAddress, fetchAddress, fetchAddresses, saveAddress, updateAddress,
} from '@api/addresses';
import { moduleName as customerAddresses, CUSTOMER_ADDRESS } from '@constants/customer_addresses';
import { moduleName as supplierAddresses, SUPPLIER_ADDRESS } from '@constants/supplier_addresses';

interface GetAssociatedRecordKeyTypes {
  key: string;
  type: string;
}

const getAssociatedRecordKey = (
  moduleName: string, actionName: string,
): GetAssociatedRecordKeyTypes => {
  switch (moduleName) {
    case customerAddresses:
      return { key: 'customer_id', type: `${actionName}_${CUSTOMER_ADDRESS}` };
    case supplierAddresses:
      return { key: 'supplier_id', type: `${actionName}_${SUPPLIER_ADDRESS}` };
    default:
      return { key: '', type: '' };
  }
};

// Saga generator function for fetching addresses.
function* fetchAddressesFunc(action: any) {
  const {
    moduleName, url, redirectUrl, search_params, per_page,
    page, sort_by, sort_order,
  } = action;

  try {
    const response = yield call(fetchAddresses({
      moduleName,
      url,
      redirectUrl,
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
    }));

    const data = response.data || {};
    const { pagination } = data?.meta || {};
    const { total_count: totalCount } = pagination || {};
    const items = data?.addresses || [];

    yield put(setItems({
      moduleName,
      responseData: {
        items,
        current_page: page,
        total_count: totalCount,
        sort_by,
        sort_order,
        search_params,
        per_page,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for fetching address.
function* fetchAddressFunc(action) {
  const { moduleName, url, redirectUrl } = action;

  try {
    const response = yield call(fetchAddress({ url, redirectUrl, moduleName }));
    const item = response?.data?.address || {};
    yield put(setItem({ moduleName, item }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for creating address.
function* createAddressFunc(action) {
  const {
    moduleName, url, redirectUrl, address,
  } = action || {};

  const { key, type } = getAssociatedRecordKey(moduleName, 'CREATE');

  try {
    const response = yield call(saveAddress({
      url, redirectUrl, moduleName, address,
    }));
    // addresses
    const addresses = response?.data?.addresses || {};
    // Here we create/insert new address into 'customer_addresses' and 'supplier_addresses' store data.

    yield put(addItem({ moduleName, item: addresses }));
    yield put({
      type, payload: { address: addresses, [key]: address?.[key] },
    });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for updating address.
function* updateAddressFunc(action) {
  const {
    moduleName, url, redirectUrl, address,
  } = action || {};

  const { key, type } = getAssociatedRecordKey(moduleName, 'UPDATE');

  try {
    const response = yield call(updateAddress({
      url, redirectUrl, moduleName, address,
    }));
    const addresses = response?.data?.addresses || {};
    // Here we update 'customer_addresses' and 'supplier' addresses store data.
    yield put(updateItem({ moduleName, item: addresses }));
    yield put({
      type, payload: { address: addresses, [key]: address?.[key] },
    });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for deleting address.
function* deleteAddressFunc(action) {
  const {
    moduleName, url, redirectUrl, id,
  } = action;
  try {
    const response = yield call(deleteAddress({
      url, redirectUrl, moduleName,
    }));
    yield put(deleteItem({ moduleName, id }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Addresses watcher continously watching our address related actions.
export function* addressesWatcher() {
  yield takeLatest(FETCH_ADDRESSES, fetchAddressesFunc);
  yield takeLatest(FETCH_ADDRESS, fetchAddressFunc);
  yield takeLatest(CREATE_ADDRESS, createAddressFunc);
  yield takeLatest(UPDATE_ADDRESS, updateAddressFunc);
  yield takeLatest(DELETE_ADDRESS, deleteAddressFunc);
}
