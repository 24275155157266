import { call, takeLatest } from 'redux-saga/effects';
import { fetchHistoricalPricingListing } from './action';
import { FETCH_HISTORICAL_PRICING_LISTING } from './constants';

// Saga generator function for fetching addresses.
function* fetchHistoricalPricingListingFunc(action: any) {
  const {
    variantId, partyId, pricePerUnit, redirectUrl, perPage,
  } = action;

  try {
    const response = yield call(fetchHistoricalPricingListing({
      variantId, partyId, pricePerUnit, redirectUrl, perPage,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* productHistoricalPricingWatcher() {
  yield takeLatest(FETCH_HISTORICAL_PRICING_LISTING, fetchHistoricalPricingListingFunc);
}
