import { HOME_BREADCRUMB_LISTING } from './common';

export const BANK_ACCOUNT_FORM_VIEW = 'bank_account_form';

export const BANK_ACCOUNT = 'BANK_ACCOUNT';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const BANK_ACCOUNT_FETCHED = 'BANK_ACCOUNT_FETCHED';
export const BANK_ACCOUNT_UPDATED = 'BANK_ACCOUNT_UPDATED';
export const BANK_ACCOUNT_DELETED = 'BANK_ACCOUNT_DELETED';
export const AUTOCOMPLETE_BANK_ACCOUNTS = 'AUTOCOMPLETE_BANK_ACCOUNTS';
export const SAVE_BANK_ACCOUNT = 'SAVE_BANK_ACCOUNT';

export const OPEN_BANK_ACCOUNT_FORM_MODAL = 'OPEN_BANK_ACCOUNT_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Bank Accounts';
export const singularTitle = 'Bank Account';
export const moduleName = 'bank_accounts';
export const baseUrl = '/bank_accounts';
export const redirectUrl = '/bank_accounts';
export const listingDataKey = 'bank_accounts';
export const itemDataKey = 'bank_account';

export const OPENING_BALANCE_TYPES = [
  { label: 'Credit', value: true },
  { label: 'Debit', value: false },
];

export const bankAccountConstants = {
  item: BANK_ACCOUNT,
  setItems: SET_BANK_ACCOUNTS,
  addItem: ADD_BANK_ACCOUNT,
  setItem: BANK_ACCOUNT_FETCHED,
  updateItem: BANK_ACCOUNT_UPDATED,
  deleteItem: BANK_ACCOUNT_DELETED,
  autocompleteItems: AUTOCOMPLETE_BANK_ACCOUNTS,
  saveBankAccount: SAVE_BANK_ACCOUNT,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  ifsc_code_cont: '',
  swift_code_cont: '',
  branch_cont: '',
  bank_id_in: [],
};

export const defaultSearchParams = {
  ...advanceSearchParams,
  number_or_holder_name_cont: '',
};

export const ACCOUNT_TYPES = [{ value: 'Current Account', label: 'Current Account' }, { value: 'Savings Account', label: 'Savings Account' }];

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['banks'];

export const Title = 'Bank Accounts';
export const TargetBaseUrl = 'bank_accounts';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['bank_id', 'bank', 'holder_name', 'account_type', 'number', 'ifsc_code', 'swift_code', 'upi_id', 'branch', 'opening_balance', 'opening_balance_date', 'opening_balance_type_credit'],
};
