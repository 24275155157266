import React, { CSSProperties, PureComponent, ReactElement } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GenObjectType } from '@type/dms_types';
import {
  permissionKey as offersPermissionKey, moduleName as offersModuleName,
} from '@constants/offers';
import {
  moduleName as defualtDacRoles, permissionKey as defaultDacRolesPermissionKey,
} from '@constants/default_dac_roles';
import {
  listingDataKey as accounts, permissionKey as accountsPermissionKey, moduleName as chartOfAccounts,
} from '@constants/accounting/accounts';
import {
  listingDataKey as accountEntries, permissionKey as accountsEntriesPermissionKey,
} from '@constants/accounting/entries';
import { getPermissionsHash } from '@helpers/common_helper';
import OverlayTriggerTooltip from './OverlayTriggerTooltip';

// INTERFACES
interface OwnProps extends RouteChildrenProps {
  children: any;
  location: any;
}
interface StateFromProps {
  clientPermissions: GenObjectType<GenObjectType<boolean>>;
}
type PVCProps = StateFromProps & OwnProps

interface PCProps extends PVCProps {
  permissionKey?: any;
  tooltipPlacement?: string;
}

interface SPCProps {
  item: any;
  permissionKey: any;
  children: ReactElement;
  tooltipPlacement?: string;
  className?: string;
}
// !INTERFACES

// STYLES
const pvcStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const noPermissionMsg = "You don't have permission to do this. Contact Admin.";

// WHAT   routes which have deep nested routes like `accounting/accounts/new`
const routeExceptions = ['accounting', 'authorisation'];

// WHAT   routes whose moduleNames have different permission keys
const modulePermissionKeyExceptions = {
  [offersModuleName]: offersPermissionKey,
  [defualtDacRoles]: defaultDacRolesPermissionKey,
  [accounts]: accountsPermissionKey,
  [chartOfAccounts]: accountsPermissionKey,
  [accountEntries]: accountsEntriesPermissionKey,
};

// WHAT   this functions tell whether to render content or not, based on the permission
const showContent = ({
  forPage = false, clientPermissions = {}, pathname = '', permissionKey = '',
}) => {
  let perm;
  const pathSplit = pathname.split('/');

  // WHAT   get module name
  if (!permissionKey && pathSplit[1]) {
    if (routeExceptions.includes(pathSplit[1])) {
      permissionKey = pathSplit[2];
    } else {
      permissionKey = pathSplit[1];
    }
  }

  if (Object.keys(modulePermissionKeyExceptions).includes(permissionKey)) {
    permissionKey = modulePermissionKeyExceptions[permissionKey];
  }

  // WHY  by default check if user can create a certain voucher record
  perm = clientPermissions[permissionKey] ? clientPermissions[permissionKey].can_create : false;

  // WHAT   if query for a page
  if (forPage) {
    // WHAT   for route exceptions new pages
    if (routeExceptions.includes(pathSplit[1]) && (pathSplit[3] === 'new')) {
      return perm;
    }

    // WHAT   for other new pages
    if (pathSplit[2] === 'new' && (permissionKey in clientPermissions)) {
      return perm;
    }

    // WHAT   for show pages and all other pages which are not new or edit
    return true;
  }

  // WHAT   for new btns in index pages
  return perm;
};

export class PVCWithoutRedux extends PureComponent<PVCProps, {}> {
  render() {
    const { children, clientPermissions, location } = this.props;

    const show = showContent({ forPage: true, clientPermissions, pathname: location.pathname });

    const permittedView = (
      <>
        {children}
      </>
    );

    return (
      <>
        {show ? permittedView : (
          <div style={pvcStyle}>
            <i
              style={{ fontSize: 80, width: 80 }}
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
            />

            <div id="header-title" style={{ marginTop: 20 }}>
              You don&apos;t have permissions to view this page!
            </div>
            <p style={{ marginTop: 10 }}>
              Please update your permissions or contact your Admin
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;
  const { client_ui_permissions } = settings || {};

  return {
    clientPermissions: client_ui_permissions || {},
  };
};

// export const PVC = withRouter(
//   connect<StateFromProps, void, OwnProps>(mapStateToProps)(PVCWithoutRedux),
// );

const reduxConnector = connect<StateFromProps, void, OwnProps>(mapStateToProps);
const connectedPVCWithRedux = reduxConnector(PVCWithoutRedux);

export const PVC: any = withRouter(connectedPVCWithRedux);

// WHAT   PC => Permissions Container

const PCEl = (props: PCProps) => {
  const {
    children, location, clientPermissions, permissionKey, tooltipPlacement,
  } = props;

  const show = showContent({ clientPermissions, pathname: location.pathname, permissionKey });

  return (
    <>
      {show ? children
        : (
          <OverlayTriggerTooltip
            description={noPermissionMsg}
            placement={tooltipPlacement}
          >
            <div className="not-permitted-container">
              <div className="not-permitted">{children}</div>
            </div>
          </OverlayTriggerTooltip>
        )}
    </>
  );
};

PCEl.defaultProps = {
  tooltipPlacement: 'bottom',
};

// export const PC = withRouter(
//   connect<StateFromProps, void, OwnProps>(mapStateToProps)(PCEl),
// );

const connectedPCElWithRedux = reduxConnector(PCEl);
export const PC: any = withRouter(connectedPCElWithRedux);

export const SPC = (props: SPCProps) => {
  const {
    item, permissionKey, children, tooltipPlacement, className,
  } = props;

  const askedPerms = getPermissionsHash({
    required_object: item, required_permissions: [permissionKey],
  });

  const isDisabled = !askedPerms[permissionKey];

  return (
    <>
      {isDisabled ? (
        <OverlayTriggerTooltip
          description={noPermissionMsg}
          placement={tooltipPlacement}
        >
          <div className={classnames('not-permitted-container', { [className]: !!className })}>
            <div className="not-permitted">{children}</div>
          </div>
        </OverlayTriggerTooltip>
      ) : children}
    </>
  );
};

SPC.defaultProps = {
  tooltipPlacement: 'bottom',
  className: '',
};
