import { call, put, takeLatest } from 'redux-saga/effects';
import { moduleName, FETCH_ORDERS } from '../constants/orders';
import { setItems } from '../actions/common';
import { fetchOrders } from '../api/orders';

function* fetchOrdersSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchOrders({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { orders, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: orders,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* orderWatcher() {
  yield takeLatest(FETCH_ORDERS, fetchOrdersSagaSideEffect);
}
