export const DOCUMENT = 'DOCUMENT';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const TOGGLE_POST_DOCUMENT_STATE = 'TOGGLE_POST_DOCUMENT_STATE';
export const LOAD_MORE_DOCUMENTS = 'LOAD_MORE_DOCUMENTS';
export const DOCUMENT_FETCHED = 'DOCUMENT_FETCHED';
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED';
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED';

export const title = 'Documents';
export const singularTitle = 'Document';
export const moduleName = 'documents';
export const listingDataKey = 'documents';
export const itemDataKey = 'document';
export const permissionKey = moduleName;

export const documentConstants = {
  item: DOCUMENT,
  setItems: SET_DOCUMENTS,
  addItem: ADD_DOCUMENT,
  setItem: DOCUMENT_FETCHED,
  updateItem: DOCUMENT_UPDATED,
  deleteItem: DOCUMENT_DELETED,
  title,
  singularTitle,
  moduleName,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
