export const CUSTOMER_CONTACT = 'CUSTOMER_CONTACT';
export const SET_CUSTOMER_CONTACTS = 'SET_CUSTOMER_CONTACTS';
export const ADD_CUSTOMER_CONTACT = 'ADD_CUSTOMER_CONTACT';
export const CUSTOMER_CONTACT_FETCHED = 'CUSTOMER_CONTACT_FETCHED';
export const CUSTOMER_CONTACT_UPDATED = 'CUSTOMER_CONTACT_UPDATED';
export const CUSTOMER_CONTACT_DELETED = 'CUSTOMER_CONTACT_DELETED';
export const CUSTOMER_CONTACT_EDIT = 'CUSTOMER_CONTACT_EDIT';
export const AUTOCOMPLETE_CUSTOMER_CONTACTS = 'AUTOCOMPLETE_CUSTOMER_CONTACTS';

export const OPEN_CUSTOMER_CONTACT_FORM_MODAL = 'OPEN_CUSTOMER_CONTACT_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Customer Contacts';
export const singularTitle = 'Customer Contact';
export const moduleName = 'customer_contacts';
export const baseUrl = '/customer_contacts';
export const redirectUrl = '/customer_contacts';
export const listingDataKey = 'customer_contacts';
export const itemDataKey = 'customer_contact';
export const permissionKey = 'customer/contacts';

export const customerContactConstants = {
  item: CUSTOMER_CONTACT,
  setItems: SET_CUSTOMER_CONTACTS,
  addItem: ADD_CUSTOMER_CONTACT,
  setItem: CUSTOMER_CONTACT_FETCHED,
  updateItem: CUSTOMER_CONTACT_UPDATED,
  deleteItem: CUSTOMER_CONTACT_DELETED,
  autocompleteItems: AUTOCOMPLETE_CUSTOMER_CONTACTS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
