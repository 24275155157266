import { HOME_BREADCRUMB_LISTING } from './common';

export const AUTHORISATION_CONDITION = 'AUTHORISATION_CONDITION';
export const SET_AUTHORISATION_CONDITIONS = 'SET_AUTHORISATION_CONDITIONS';

export const title = 'Authorisation Conditions';
export const singularTitle = 'Authorisation Condition';
export const moduleName = 'authorisation_conditions';
export const baseUrl = '/authorisation/conditions';
export const redirectUrl = '/authorisation/conditions';
export const apiBaseUrl = '/authorisation/conditions';
export const reduxStateKey = 'authorisation_conditions';
export const listingDataKey = 'conditions';
export const itemDataKey = 'condition';
export const permissionKey = 'authorisation/conditions';

export const conditionConstants = {
  item: AUTHORISATION_CONDITION,
  setItems: SET_AUTHORISATION_CONDITIONS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  apiBaseUrl,
  reduxStateKey,
  listingDataKey,
  itemDataKey,
  permissionKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Authorisation Conditions';
export const TargetBaseUrl = 'authorisation_conditions';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
