import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_TAXES, ADD_TAX, TAX_FETCHED, TAX_UPDATED, TAX_DELETED,
  AUTOCOMPLETE_TAXES, defaultSearchParams, moduleName,
} from '../constants/taxes';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function taxes(state = initialState, action) {
  switch (action.type) {
    case SET_TAXES: return setItems(state, action);
    case ADD_TAX: return addItem(state, action);
    case TAX_FETCHED: return fetchItem(state, action);
    case TAX_UPDATED: return updateItem(state, action);
    case TAX_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_TAXES: return autocomplete(state, action);
    default:
      return state;
  }
}
