import { HOME_BREADCRUMB_LISTING } from './common';

export const PURCHASE_INVOICE = 'PURCHASE_INVOICE';
export const NEW_PURCHASE_INVOICE = 'NEW_PURCHASE_INVOICE';
export const SET_PURCHASE_INVOICES = 'SET_PURCHASE_INVOICES';
export const ADD_PURCHASE_INVOICE = 'ADD_PURCHASE_INVOICE';
export const PURCHASE_INVOICE_FETCHED = 'PURCHASE_INVOICE_FETCHED';
export const PURCHASE_INVOICE_UPDATED = 'PURCHASE_INVOICE_UPDATED';
export const PURCHASE_INVOICE_DELETED = 'PURCHASE_INVOICE_DELETED';
export const PURCHASE_INVOICE_EDIT = 'PURCHASE_INVOICE_EDIT';
export const RESET_PURCHASE_INVOICE_ERRORS = 'RESET_PURCHASE_INVOICE_ERRORS';
export const AUTOCOMPLETE_PURCHASE_INVOICES = 'AUTOCOMPLETE_PURCHASE_INVOICES';

export const FETCH_PURCHASE_INVOICES = 'FETCH_PURCHASE_INVOICES';

export const FORM_INFORMATION_TITLE = 'PURCHASE INVOICE INFORMATION';

// Views for helper text
export const PURCHASE_INVOICE_FORM_VIEW = 'purchase_invoice_form';

export const title = 'Purchase Invoices';
export const singularTitle = 'Purchase Invoice';
export const moduleName = 'purchase_invoices';
export const baseUrl = `/${moduleName}`;
export const redirectUrl = `/${moduleName}`;
export const listingDataKey = moduleName;
export const itemDataKey = 'purchase_invoice';

export const ALLOCATION_STATUS = [
  { name: 'Allocated', id: 'Allocated' },
  { name: 'Unallocated', id: 'Unallocated' },
];

export const purchaseInvoiceConstants = {
  item: PURCHASE_INVOICE,
  setNewItem: NEW_PURCHASE_INVOICE,
  setItems: SET_PURCHASE_INVOICES,
  addItem: ADD_PURCHASE_INVOICE,
  setItem: PURCHASE_INVOICE_FETCHED,
  updateItem: PURCHASE_INVOICE_UPDATED,
  deleteItem: PURCHASE_INVOICE_DELETED,
  resetErrors: RESET_PURCHASE_INVOICE_ERRORS,
  autocompleteItems: AUTOCOMPLETE_PURCHASE_INVOICES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  ref_no_cont: '',
  supplier_type_eq: '',
  state_in: [],
  supplier_id_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  user_id_in: [],
  variants_product_category_id_in: [],
  variants_id_in: [],
  variants_brand_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_ref_no_or_supplier_name_or_supplier_gstin_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['suppliers', 'branches', 'warehouses', 'users', 'brands', 'product_categories', 'products', 'customers', 'voucher_books'];

// Purchase invoice states
export const draft = 'draft';
export const approved = 'approved';

export const PURCHASE_INVOICE_STATES = [{ value: draft, label: 'Draft' }, { value: approved, label: 'Approved' }];

// Breadcrumb
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['supplier', 'supplier_id', 'date', 'ref_no', 'branch', 'branch_id', 'warehouse', 'warehouse_id', 'notes', 'gross_amount', 'line_discount_amount', 'voucher_discount_amount', 'net_amount', 'tax_amount', 'other_charges', 'round_off', 'total_amount', ''],
  purchase_invoice_items: ['variant', 'variant_id', 'purchase_order_item_id', 'quantity', 'input_quantity', 'input_quantity_unit', 'input_price', 'input_price_per_unit', 'gross_amount', 'line_discount_percentage', 'line_discount_amount', 'amount', 'tax_amount'],
  purchase_invoice_taxes: ['tax_rate', 'tax_rate_id', 'amount'],
};
