import React from 'react';
import { Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { DOMPurifyConfig } from '@utils/dom_purify_config';
import {
  pickBy, startsWith, omit, map, isEmpty, forEach, isArray, keys, isString,
  isNumber, zipObjectDeep, isObject, startCase, cloneDeep,
} from 'lodash';
import { INTERNAL_SERVER_ERROR } from '@constants/common';

interface DisplayFormErrorsProps {
  unhandledErrors?: any;
  showInlineActionBtn?: boolean;
  inlineActionBtnName?: string;
  handleOnClick?: Function;
}

const displayFormErrorsDefaultProps = {
  unhandledErrors: {},
  showInlineActionBtn: false,
  inlineActionBtnName: '',
  handleOnClick: () => ({}),
};

interface BaseErrorProps {
  finalKey: string;
}

const BaseError = ({ finalKey }: BaseErrorProps) => {
  if (finalKey !== 'base') {
    return (
      <>
        <strong className="flash-message-key">{finalKey}</strong>
        :&nbsp;
      </>
    );
  }
  return null;
};

const getValue = (val) => (isArray(val) ? val.join(', ') : val);

interface DisplayErrorMessagesProps {
  value: any;
  unhandledErrorKey: string;
  finalKey: string;
  isNestedFormFlashMessage: boolean;
}

const DisplayErrorMessages = ({
  value, unhandledErrorKey, finalKey, isNestedFormFlashMessage,
}: DisplayErrorMessagesProps) => {
  let k = `${unhandledErrorKey}_form_flash_message`;
  if (isNestedFormFlashMessage) k = `${unhandledErrorKey}_nested_form_flash_message`;

  if (isArray(value)) {
    const errors = getValue(value);
    const cleanInnerHTML = DOMPurify.sanitize(errors, DOMPurifyConfig);
    return (
      <div key={k}>
        <BaseError finalKey={finalKey} />
        <span dangerouslySetInnerHTML={{ __html: cleanInnerHTML }} />
      </div>
    );
  }
  if (isObject(value)) {
    return (
      <div key={k}>
        <BaseError finalKey={finalKey} />
        {map(value, (val: any, ky: string) => {
          const fKey = startCase(ky) || '';
          const errors = getValue(value);
          const cleanInnerHTML = DOMPurify.sanitize(errors, DOMPurifyConfig);
          return (
            <span key={ky}>
              {fKey}
              &nbsp;
              :
              &nbsp;
              {getValue(val)}
              <span dangerouslySetInnerHTML={{ __html: cleanInnerHTML }} />
            </span>
          );
        })}
        {/* {finalKey} */}
      </div>
    );
  }
  const cleanInnerHTML = DOMPurify.sanitize(value, DOMPurifyConfig);
  return <div key={k} dangerouslySetInnerHTML={{ __html: cleanInnerHTML }} />;
};

export const displayFormErrors = ({
  unhandledErrors, showInlineActionBtn, inlineActionBtnName, handleOnClick,
}: DisplayFormErrorsProps) => (
  <>
    {
      (unhandledErrors && !isEmpty(unhandledErrors))

        ? (map(unhandledErrors, (value, key) => {
          const finalKey = startCase(key) || '';
          return (
            <DisplayErrorMessages
              key={key}
              unhandledErrorKey={key}
              finalKey={finalKey}
              value={value}
              isNestedFormFlashMessage={false}
            />
          );
        })
        ) : null
    }
    {
      showInlineActionBtn
        ? (
          <div className="text-right">
            <Button bsStyle="primary" bsSize="sm" onClick={handleOnClick}>{inlineActionBtnName}</Button>
          </div>
        )
        : null
    }
  </>
);

displayFormErrors.defaultProps = displayFormErrorsDefaultProps;

interface DisplayNestedFormErrorsProps {
  unhandledErrors: any;
}

export const displayNestedFormErrors = ({ unhandledErrors }: DisplayNestedFormErrorsProps) => {
  if (unhandledErrors && !isEmpty(unhandledErrors)) {
    return map(unhandledErrors, (value, key) => {
      const finalKey = startCase(key) || '';
      return (
        <DisplayErrorMessages
          key={key}
          value={value}
          finalKey={finalKey}
          unhandledErrorKey={key}
          isNestedFormFlashMessage
        />
      );
    });
  }
  return false;
};

// ex. order_items[0].input_price or order_items["0"].input_price
const handleNestedFormIndexedErrors = ({
  errName, processedErrors, nestedFormName, error,
}) => {
  const splitKeyByDotArr = errName.replace(/\./, '&').split('&'); // ['branch_zone_taxes[0]', 'tax']
  const secondKeyArr = (splitKeyByDotArr && splitKeyByDotArr[1]); // tax

  const processedNestedFormErrors = processedErrors?.[nestedFormName] || {};
  const nestedFormIdx = errName.replace(/(^.*\[|\].*$)/g, ''); // Get the nested form index.
  const updatedNestedFormIdx = !isNumber(nestedFormIdx) ? nestedFormIdx.replace(/"/g, '') : nestedFormIdx; // Some time index got order_items["0"].input_price in this format.
  const nestedFormIdxErrors = (processedNestedFormErrors?.[updatedNestedFormIdx]) || {};
  const nestedFormErrors = {
    ...processedErrors,
    [nestedFormName]: {
      ...processedNestedFormErrors,
      [updatedNestedFormIdx]: { ...nestedFormIdxErrors, [secondKeyArr]: error },
    },
  };
  return nestedFormErrors;
};

// ex. order_items, order_items.variant, order_items.base
const handleError = ({
  processedErrors, nestedFormName, errName, error,
}) => {
  let errors = {};
  const processedErrorsObj = processedErrors?.[nestedFormName] || {};
  // ex. address.mobile
  if (errName.includes('.')) {
    const keyArr = errName.split('.'); // ['address', 'mobile']
    const key = (keyArr && keyArr[1]); // 'mobile'
    errors = { [key]: error };
  } else {
    // ex. { order_items: 'Must Exist', order_items.base: '' }
    const processedBaseErrorsObj = (processedErrorsObj?.base) || [];
    errors = { base: [...processedBaseErrorsObj, ...error] };
  }

  const formErrors = { ...processedErrors, [nestedFormName]: { ...processedErrorsObj, ...errors } };
  return formErrors;
};

interface ProcessBackendErrorsProps {
  errors: any;
  nestedFormNames: any[];
}

// processedErrors = { master: { base: [''] }, order_items: { base: [''], 0: { booking_quantity: [], input_price: [] } } }
// ISSUE: get the lot of looping issue.
export const processBackendErrors = ({
  errors, nestedFormNames = [],
}: ProcessBackendErrorsProps) => {
  let processedErrors = { master: {} };

  // Setting default data for each nested form key
  forEach(nestedFormNames, (nestedFormName) => {
    processedErrors = { ...processedErrors, [nestedFormName]: {} };
  });

  if (errors?.status === 500) {
    return { ...processedErrors, master: { base: INTERNAL_SERVER_ERROR } };
  }

  let allNestedFormErrorsKeys = [];

  forEach(nestedFormNames, (nestedFormName) => {
    //  Get all nested form keys that are present in the errors object so that they can be omitted
    //  later when adding errors for the master form
    const nesFormerrors = pickBy(errors, (value, key) => startsWith(key, nestedFormName)); // [{'order_items[0].base': 'Not found'}, {'order_items.base': 'Not found'}] => ['order_items']
    const nesFormerrorsKeys = keys(nesFormerrors);
    allNestedFormErrorsKeys = [...allNestedFormErrorsKeys, ...nesFormerrorsKeys];

    forEach(nesFormerrors, (error, errName) => {
      const isNestedObjectKey = errName.includes('.') && !errName.includes('['); // Eg: "order_items.base"
      const isNestedFormKey = errName.includes('['); // Eg: "order_items[0].name"

      if (isNestedObjectKey) {
        processedErrors = handleError({
          processedErrors, nestedFormName, errName, error,
        });
      } else if (isNestedFormKey) {
        processedErrors = handleNestedFormIndexedErrors({
          errName, processedErrors, nestedFormName, error,
        });
      } else {
        processedErrors = handleError({
          processedErrors, nestedFormName, errName, error,
        });
      }
    });
  });

  // Move all master form errors under master key
  let masterErrors = omit(errors, allNestedFormErrorsKeys);
  if (isString(errors)) {
    masterErrors = { base: errors };
  }
  processedErrors = { ...processedErrors, master: masterErrors };
  return processedErrors;
};

// processedErrors = { master: { base: [''] }, order_items: { base: [''], 0: { booking_quantity: [], input_price: [] } } }
// ISSUE: get the lot of looping issue.
export const processDeepNestedBackendErrors = (args: ProcessBackendErrorsProps) => {
  const { nestedFormNames = [] } = args;
  // Had use to deep clone the error object since the errors passed in args are of reference type
  // and when args errors are modified here, the same changes might reflect in the original variable
  // passed to this helper func(for more info - https://masteringjs.io/tutorials/fundamentals/shallow-copy).
  const errors = cloneDeep(args.errors);
  let processedErrors = { master: {} };

  if (errors?.status && (errors?.status === 500)) {
    return { ...processedErrors, master: { base: INTERNAL_SERVER_ERROR } };
  }

  let allNestedFormErrorsKeys = [];

  forEach(nestedFormNames, (nestedFormName) => {
    processedErrors = { ...processedErrors, [nestedFormName]: {} };

    //  Get all nested form keys that are present in the errors object so that they can be omitted
    //  later when adding errors for the master form
    const nestedFormErrorOnBaseKey = (errors?.[nestedFormName]) || []; // Here we check the exact nestedFormName key error exist ie. { order_items: ['Is invalid items'] }
    const errorsExceptKeyWithNestedFormName = omit(errors, nestedFormName); // We temp omit error those key name is nestedFormName ex. { order_items: [] }
    const nestedFormerrors = pickBy(errorsExceptKeyWithNestedFormName, (value, key) => startsWith(key, nestedFormName)); // [{'order_items[0].base': 'Not found'}, {'order_items.base': 'Not found'}] => ['order_items']

    const nestedFormErrorsKeys = Object.keys(nestedFormerrors);
    const nestedFormErrorsValues = Object.values(nestedFormerrors);

    allNestedFormErrorsKeys = [...allNestedFormErrorsKeys, ...nestedFormErrorsKeys, nestedFormName];

    let errorsOnNestedForm = zipObjectDeep(nestedFormErrorsKeys, nestedFormErrorsValues) || {};
    const nestedFormBaseError = (errorsOnNestedForm?.[nestedFormName]?.base) || [];

    if (!isEmpty(nestedFormBaseError) || !isEmpty(nestedFormErrorOnBaseKey)) {
      const nestedFormBaseErrorArr = isArray(nestedFormBaseError) ? nestedFormBaseError : [nestedFormBaseError];
      const nestedFormErrorOnBaseKeyArr = isArray(nestedFormErrorOnBaseKey) ? nestedFormErrorOnBaseKey : [nestedFormErrorOnBaseKey];
      const base = { base: [...nestedFormBaseErrorArr, ...nestedFormErrorOnBaseKeyArr] };
      errorsOnNestedForm = { ...errorsOnNestedForm, [nestedFormName]: { ...errorsOnNestedForm[nestedFormName], ...base } };
    }
    processedErrors = { ...processedErrors, ...errorsOnNestedForm };
  });

  // Move all master form errors under master key
  let masterErrors = omit(errors, allNestedFormErrorsKeys);
  if (isString(errors)) {
    masterErrors = { base: errors };
  }
  processedErrors = { ...processedErrors, master: masterErrors };
  return processedErrors;
};

export const getUnhandledErrors = ({ errors, handledErrorKeys }) => {
  const unhandledErrors = omit(errors, handledErrorKeys);
  return unhandledErrors;
};

interface FormUnhandledErrorsProps {
  processedBackendErrors: any;
  handledErrorKeys: any[];
}

export const getFormUnhandledErrors = ({
  processedBackendErrors = { master: [] }, handledErrorKeys = [],
}: FormUnhandledErrorsProps) => {
  let unhandledErrors = {};
  const processedErrors = (processedBackendErrors?.master) || {};
  if (!isEmpty(processedErrors)) {
    unhandledErrors = getUnhandledErrors({ errors: processedErrors, handledErrorKeys });
  }
  return unhandledErrors;
};

interface NestedFormUnhandledBaseErrorsProps {
  processedBackendErrors: any;
  nestedFormName: string;
}

export const getNestedFormUnhandledBaseErrors = ({
  processedBackendErrors = {}, nestedFormName,
}: NestedFormUnhandledBaseErrorsProps) => {
  let unhandledErrors = {};

  const processedErrors = (processedBackendErrors?.[nestedFormName]?.base) || [];
  if (!isEmpty(processedErrors)) {
    unhandledErrors = { base: processedErrors };
  }

  const processedTypeErrors = (processedBackendErrors?.[nestedFormName]?.type) || [];
  if (!isEmpty(processedTypeErrors)) {
    unhandledErrors = { ...unhandledErrors, type: processedTypeErrors };
  }
  return unhandledErrors;
};

interface SingleNestedFormUnhandledBaseErrorsProps {
  processedBackendErrors: any;
  handledErrorKeys: any;
  nestedFormName: string;
}

export const getSingleNestedFormUnhandledBaseErrors = ({
  processedBackendErrors = {}, handledErrorKeys = {}, nestedFormName,
}: SingleNestedFormUnhandledBaseErrorsProps) => {
  let unhandledErrors = {};
  const processedErrors = (processedBackendErrors?.[nestedFormName]) || {};
  if (!isEmpty(processedErrors)) {
    unhandledErrors = getUnhandledErrors({
      errors: processedErrors, handledErrorKeys: (handledErrorKeys?.[nestedFormName]) || [],
    });
  }
  return unhandledErrors;
};

interface NestedFormIndexWiseUnhandledErrorsProps {
  processedBackendErrors: any;
  nestedFormName: string;
  handledErrorKeys: any;
  idx: string | number;
}

export const getNestedFormIndexWiseUnhandledErrors = ({
  processedBackendErrors = {}, nestedFormName, handledErrorKeys = {}, idx,
}: NestedFormIndexWiseUnhandledErrorsProps) => {
  let unhandledNestedFormErrors = {};
  const indexWiseProcessedErrors = (processedBackendErrors?.[nestedFormName]?.[idx]) || {};
  if (!isEmpty(indexWiseProcessedErrors)) {
    unhandledNestedFormErrors = getUnhandledErrors({
      errors: indexWiseProcessedErrors, handledErrorKeys: handledErrorKeys?.[nestedFormName],
    });
  }

  return unhandledNestedFormErrors;
};
