import map from 'lodash/map';
import { removeErrorForKeyFromState } from '@utils/utils_helpers';
import { SET_INVOICE_E_INVOICE_DATA } from '@constants/e_invoice';
import { SET_INVOICE_E_WAY_BILL_DATA } from '@component/common/e_way_bill/constants';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_INVOICES, ADD_INVOICE, INVOICE_FETCHED, INVOICE_UPDATED, INVOICE_DELETED,
  UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DELIVERED,
  UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DISPATCHED, moduleName,
  RESET_INVOICE_ERRORS, INVOICE_CLEAR_ERROR, defaultSearchParams, SET_ORDER_INVOICE,
} from '@constants/invoices';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateStatus, autocomplete,
} from '@reducers/common';
import { BULK_CREATE_INVENTORY_BATCHES_FOR_INVOICE_ITEMS } from '@constants/inventories';
import { BULK_CREATE_INVENTORY_PIECES_FOR_INVOICE_ITEMS } from '@constants/inventory_pieces';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function invoices(state = initialState, action) {
  switch (action?.type) {
    case SET_INVOICES: return setItems(state, action);
    case ADD_INVOICE: return addItem(state, action);
    case INVOICE_FETCHED: return fetchItem(state, action);
    case INVOICE_UPDATED: return updateItem(state, action);
    case INVOICE_DELETED: return deleteItem(state, action);
    case UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DELIVERED:
    case UPDATE_SELECTED_BULK_INVOICES_STATUS_TO_DISPATCHED:
      return updateStatus(state, action, action?.status);
    case SET_ORDER_INVOICE: return autocomplete(state, action);

    // For quick add form errors
    case RESET_INVOICE_ERRORS: {
      return {
        ...state, isError: false, errors: {}, isFetching: false,
      };
    }
    case INVOICE_CLEAR_ERROR: return removeErrorForKeyFromState(action, state);
    case SET_INVOICE_E_INVOICE_DATA: {
      const items = state?.items?.map((item) => {
        if (item?.id !== action?.payload?.id) return item;
        return { ...item, e_invoice: action?.payload?.e_invoice };
      });
      return {
        ...state, items, isFetching: false, isError: false, errors: {},
      };
    }
    case SET_INVOICE_E_WAY_BILL_DATA: {
      const items = state?.items?.map((item) => {
        if (item?.id !== action?.payload?.id) return item;
        return { ...item, e_way_bill: action?.payload?.e_way_bill };
      });
      return {
        ...state, items, isFetching: false, isError: false, errors: {},
      };
    }
    case BULK_CREATE_INVENTORY_PIECES_FOR_INVOICE_ITEMS:
    case BULK_CREATE_INVENTORY_BATCHES_FOR_INVOICE_ITEMS: {
      const {
        voucherId, voucherItemId, voucherItemData,
      } = action;
      const items = map(state.items, (item) => {
        if (item?.id !== voucherId) return item;
        const invoiceItems = map(item.invoice_items, (invoiceItem) => {
          if (invoiceItem?.id !== voucherItemId) return invoiceItem;
          return voucherItemData;
        });
        return { ...item, invoice_items: invoiceItems };
      });

      return { ...state, items };
    }
    default:
      return state;
  }
}
