import { HOME_BREADCRUMB_LISTING } from './common';

export const TAX_CATEGORY = 'TAX_CATEGORY';
export const SET_TAX_CATEGORIES = 'SET_TAX_CATEGORIES';
export const ADD_TAX_CATEGORY = 'ADD_TAX_CATEGORY';
export const TAX_CATEGORY_FETCHED = 'TAX_CATEGORY_FETCHED';
export const TAX_CATEGORY_UPDATED = 'TAX_CATEGORY_UPDATED';
export const TAX_CATEGORY_DELETED = 'TAX_CATEGORY_DELETED';
export const AUTOCOMPLETE_TAX_CATEGORIES = 'AUTOCOMPLETE_TAX_CATEGORIES';

export const GST_EXEMPTED = 'GST EXEMPTED';
export const GST_NIL_RATED = 'GST NIL-RATED';
export const NON_GST = 'Non-GST';

export const FORM_INFORMATION_TITLE = 'TAX CATEGORY INFORMATION';
export const RESET_TAX_CATEGORY_ERRORS = 'RESET_TAX_CATEGORY_ERRORS';
export const ADD_NEW_TAX_CATEGORY = 'Add New Tax Category';

export const title = 'Tax Categories';
export const singularTitle = 'Tax Category';
export const moduleName = 'tax_categories';
export const baseUrl = '/tax_categories';
export const redirectUrl = '/tax_categories';
export const listingDataKey = 'tax_categories';
export const itemDataKey = 'tax_category';

export const taxCategoryConstants = {
  item: TAX_CATEGORY,
  setItems: SET_TAX_CATEGORIES,
  addItem: ADD_TAX_CATEGORY,
  setItem: TAX_CATEGORY_FETCHED,
  updateItem: TAX_CATEGORY_UPDATED,
  deleteItem: TAX_CATEGORY_DELETED,
  autocompleteItems: AUTOCOMPLETE_TAX_CATEGORIES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Tax Categories';
export const TargetBaseUrl = 'tax_categories';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name'],
  tax_category_tax_rates: ['tax_rate', 'tax_rate_id', 'position'],
};
