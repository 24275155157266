import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: {},
};

export default function EWayBills(state = initialState, action) {
  switch (action.type) {
    // case SET_ELIGIBLE_OFFERS: return setItems(state, action);
    default:
      return state;
  }
}
