import Loadable from 'react-loadable';
import Loading from '@component/common/page_loading';

const Organization = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOrganizationsUpdate" */ '@containers/organizations/Update'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSettingsSettings" */ '@containers/settings/Settings'),
  loading: Loading,
});

const PrintFormats = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersSettingsSettings" */ '@containers/print_formats'),
  loading: Loading,
});

const EInvoiceCredentials = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentEInvoiceCredentials" */ '@component/credentials/e_invoice'),
  loading: Loading,
});

const EWayBillCredentials = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersEWayBillCredentials" */ '@component/credentials/e_way_bill'),
  loading: Loading,
});

const ConfigRoutes = [
  {
    path: '/organization', name: 'Organization', component: Organization,
  },
  {
    path: '/settings', name: 'Settings', component: Settings,
  },
  {
    path: '/print_formats', name: 'Print Formats', component: PrintFormats,
  },
  {
    path: '/e_invoice_credentials', name: 'E Invoice Credential', component: EInvoiceCredentials,
  },
  {
    path: '/e_way_bill_credentials', name: 'E Way Bill Credential', component: EWayBillCredentials,
  },
];

export default ConfigRoutes;
