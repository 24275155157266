import { SET_INCOME_STATEMENT } from '../constants/reports';

export const initialState = {
  data: {},
  from_date: '',
  to_date: '',
  isFetching: false,
  isError: false,
  errors: {},
};

export default function incomeStatement(state = initialState, action) {
  switch (action.type) {
    case SET_INCOME_STATEMENT: {
      return {
        ...state,
        data: action.data,
        from_date: action.from_date || '',
        to_date: action.to_date || '',
        isFetching: false,
        isError: false,
        errors: {},
      };
    }
    default:
      return state;
  }
}
