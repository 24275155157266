import { isArray, map } from 'lodash';
import { fetchingRequest } from '@utils/axios';
import { moduleName as accounts, accountConstants } from '@constants/accounts';
import { moduleName as bankAccounts, bankAccountConstants } from '@constants/bank_accounts';
import { moduleName as banks, bankConstants } from '@constants/banks';
import { moduleName as beatRouteAssignments, beatRouteAssignmentConstants } from '@constants/beat_route_assignments';
import { moduleName as beatRoutes, beatRouteConstants } from '@constants/beat_routes';
import { moduleName as branches, branchConstants } from '@constants/branches';
import { moduleName as comments, commentConstants } from '@constants/comments';
import { moduleName as brands, brandConstants } from '@constants/brands';
import { moduleName as conditions, conditionConstants } from '@constants/authorisation_conditions';
import { moduleName as creditLeeways, creditLeewayConstants } from '@constants/credit_leeways';
import { moduleName as creditNoteInvoices, creditNoteInvoiceConstants } from '@constants/credit_note_invoices';
import { moduleName as creditNotes, creditNoteConstants } from '@constants/credit_notes';
import { moduleName as creditReasons, creditReasonConstants } from '@constants/credit_reasons';
import { moduleName as customerAddresses, customerAddressConstants } from '@constants/customer_addresses';
import { moduleName as customerCategories, customerCategoryConstants } from '@constants/customer_categories';
import { moduleName as customerContacts, customerContactConstants } from '@constants/customer_contacts';
import { moduleName as customerRatings, customerRatingConstants } from '@constants/customer_ratings';
import { moduleName as customers, customerConstants } from '@constants/customers';
import { moduleName as customFields, customFieldsConstants } from '@constants/custom_fields';
import { moduleName as debitNotes, debitNoteConstants } from '@constants/debit_notes';
import { moduleName as debitReasons, debitReasonConstants } from '@constants/debit_reasons';
import { moduleName as defaultDacRoles, defaultDacRoleConstants } from '@constants/default_dac_roles';
import { moduleName as documentCategories, documentCategoryConstants } from '@constants/document_categories';
import { moduleName as documents, documentConstants } from '@constants/documents';
import { moduleName as downloads, downloadConstants } from '@constants/downloads';
import { moduleName as eWayBills, eWayBillConstants } from '@component/common/e_way_bill/constants';
import { moduleName as feeds, feedConstants } from '@constants/feeds';
import { moduleName as inventoryIdentifications, inventoryIdentificationConstants } from '@constants/inventory_identifications';
import { moduleName as invoicePayments, invoicePaymentConstants } from '@constants/invoice_payments';
import { moduleName as invoices, invoiceConstants } from '@constants/invoices';
import { moduleName as invoiceTerms, invoiceTermConstants } from '@constants/invoice_terms';
import { moduleName as orderTerms, orderTermConstants } from '@constants/order_terms';
import { moduleName as orders, orderConstants } from '@constants/orders';
import { moduleName as organizations, organizationConstants } from '@constants/organizations';
import { moduleName as paymentOuts, paymentOutConstants } from '@constants/payment_outs';
import { moduleName as payments, paymentConstants } from '@constants/payments';
import { moduleName as periodLocks, periodLockConstants } from '@constants/period_locks';
import { moduleName as productCategories, productCategoryConstants } from '@constants/product_categories';
import { moduleName as products, productConstants } from '@constants/products';
import { moduleName as purchaseInvoices, purchaseInvoiceConstants } from '@constants/purchase_invoices';
import { moduleName as purchaseOrders, purchaseOrderConstants } from '@constants/purchase_orders';
import { moduleName as purchaseOrderTerms, purchaseOrderTermConstants } from '@constants/purchase_order_terms';
import { moduleName as purchaseReturns, purchaseReturnConstants } from '@constants/purchase_returns';
import { moduleName as returns, returnConstants } from '@constants/returns';
import { moduleName as stockEntries, stockEntryConstants } from '@constants/stock_entries';
import { moduleName as stocks, stockConstants } from '@constants/stocks';
import { moduleName as stockTransfers, stockTransferConstants } from '@constants/stock_transfers';
import { moduleName as suppliers, supplierConstants } from '@constants/suppliers';
import { moduleName as supplierAddresses, supplierAddressConstants } from '@constants/supplier_addresses';
import { moduleName as supplierContacts, supplierContactConstants } from '@constants/supplier_contacts';
import { moduleName as taxCategories, taxCategoryConstants } from '@constants/tax_categories';
import { moduleName as taxes, taxConstants } from '@constants/taxes';
import { moduleName as taxRates, taxRateConstants } from '@constants/tax_rates';
import { moduleName as userRoles, userRoleConstants } from '@constants/user_roles';
import { moduleName as users, userConstants } from '@constants/users';
import { moduleName as usersGroups, userGroupConstants } from '@constants/users_groups';
import { moduleName as voucherBooks, voucherBookConstants } from '@constants/voucher_books';
import { moduleName as warehouses, warehouseConstants } from '@constants/warehouses';
import { moduleName as zones, zoneConstants } from '@constants/zones';
import { moduleName as printFormats, printFormatConstants } from '@constants/print_formats';
import { moduleName as inventoryBatches, inventoryBatchConstants } from '@constants/inventory_batches';
import { moduleName as inventoryPieces, inventoryPieceConstants } from '@constants/inventory_pieces';

const moduleActionTypes = {
  [accounts]: accountConstants,
  [bankAccounts]: bankAccountConstants,
  [banks]: bankConstants,
  [beatRouteAssignments]: beatRouteAssignmentConstants,
  [beatRoutes]: beatRouteConstants,
  [branches]: branchConstants,
  [comments]: commentConstants,
  [brands]: brandConstants,
  [conditions]: conditionConstants,
  [creditLeeways]: creditLeewayConstants,
  [creditNoteInvoices]: creditNoteInvoiceConstants,
  [creditNotes]: creditNoteConstants,
  [creditReasons]: creditReasonConstants,
  [customerAddresses]: customerAddressConstants,
  [customerCategories]: customerCategoryConstants,
  [customerContacts]: customerContactConstants,
  [customerRatings]: customerRatingConstants,
  [customers]: customerConstants,
  [customFields]: customFieldsConstants,
  [debitNotes]: debitNoteConstants,
  [debitReasons]: debitReasonConstants,
  [defaultDacRoles]: defaultDacRoleConstants,
  [documentCategories]: documentCategoryConstants,
  [documents]: documentConstants,
  [downloads]: downloadConstants,
  [eWayBills]: eWayBillConstants,
  [feeds]: feedConstants,
  [inventoryBatches]: inventoryBatchConstants,
  [inventoryIdentifications]: inventoryIdentificationConstants,
  [inventoryPieces]: inventoryPieceConstants,
  [invoicePayments]: invoicePaymentConstants,
  [invoices]: invoiceConstants,
  [invoiceTerms]: invoiceTermConstants,
  [orderTerms]: orderTermConstants,
  [orders]: orderConstants,
  [organizations]: organizationConstants,
  [paymentOuts]: paymentOutConstants,
  [payments]: paymentConstants,
  [periodLocks]: periodLockConstants,
  [productCategories]: productCategoryConstants,
  [products]: productConstants,
  [printFormats]: printFormatConstants,
  [purchaseInvoices]: purchaseInvoiceConstants,
  [purchaseOrders]: purchaseOrderConstants,
  [purchaseOrderTerms]: purchaseOrderTermConstants,
  [purchaseReturns]: purchaseReturnConstants,
  [returns]: returnConstants,
  [stockEntries]: stockEntryConstants,
  [stocks]: stockConstants,
  [stockTransfers]: stockTransferConstants,
  [suppliers]: supplierConstants,
  [supplierAddresses]: supplierAddressConstants,
  [supplierContacts]: supplierContactConstants,
  [taxCategories]: taxCategoryConstants,
  [taxes]: taxConstants,
  [taxRates]: taxRateConstants,
  [userRoles]: userRoleConstants,
  [users]: userConstants,
  [usersGroups]: userGroupConstants,
  [voucherBooks]: voucherBookConstants,
  [warehouses]: warehouseConstants,
  [zones]: zoneConstants,
};

type ActionTypesProps = {
  moduleName: string;
  actionTypes: string | string[];
}

export const getActionTypes = (args: ActionTypesProps) => {
  const { moduleName, actionTypes } = args;
  const moduleWiseActionTypes = moduleActionTypes[moduleName] || {};
  if (isArray(actionTypes)) {
    const actions = {};
    map(actionTypes, (type) => {
      actions[type] = moduleWiseActionTypes[type];
    });
    return actions;
  }
  return moduleWiseActionTypes[actionTypes as string];
};

export const startFetchingRequest = (moduleName: string) => {
  const item = getActionTypes({ moduleName, actionTypes: 'item' });
  fetchingRequest(item);
};
