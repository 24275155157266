import { SET_INTEGRATIONS } from '@constants/integrations';

// eslint-disable-next-line import/no-mutable-exports
export const initialState = {
  isFetching: false,
  integrations: [],
};

// NOTE: Currently this reduer is unused but it's usefull in future.
export default function integrations(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_INTEGRATIONS: {
      return { ...state, integrations: action?.payload.integrations || [] };
    }
    default:
      return state;
  }
}
