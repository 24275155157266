import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_PAYMENT_OUTS, ADD_PAYMENT_OUT, PAYMENT_OUT_FETCHED, PAYMENT_OUT_UPDATED, PAYMENT_OUT_DELETED,
  UPDATE_SELECTED_BULK_PAYMENT_OUTS_STATUS_TO_ACCOUNTED, defaultSearchParams, moduleName,
} from '@constants/payment_outs';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateStatus,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function paymentOuts(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_OUTS: return setItems(state, action);
    case ADD_PAYMENT_OUT: return addItem(state, action);
    case PAYMENT_OUT_FETCHED: return fetchItem(state, action);
    case PAYMENT_OUT_UPDATED: return updateItem(state, action);
    case PAYMENT_OUT_DELETED: return deleteItem(state, action);
    case UPDATE_SELECTED_BULK_PAYMENT_OUTS_STATUS_TO_ACCOUNTED:
      return updateStatus(state, action, action.status);
    default:
      return state;
  }
}
