import { HOME_BREADCRUMB_LISTING } from './common';

export const ORDER = 'ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const ORDER_FETCHED = 'ORDER_FETCHED';
export const ORDER_UPDATED = 'ORDER_UPDATED';
export const ORDER_DELETED = 'ORDER_DELETED';
export const RESET_ORDER_ERRORS = 'RESET_ORDER_ERRORS';
export const AUTOCOMPLETE_ORDERS = 'AUTOCOMPLETE_ORDERS';
export const UPDATE_SELECTED_BULK_ORDERS_STATUS_TO_CONFIRM = 'UPDATE_SELECTED_BULK_ORDERS_STATUS_TO_CONFIRM';
export const UPDATE_ORDERS_STATUS_TO_PROCESSING = 'UPDATE_ORDERS_STATUS_TO_PROCESSING';
export const BULK_ORDERS_INVOICE = 'BULK_ORDERS_INVOICE';
export const CANCEL_REMAINING_QUANTITY = 'CANCEL_REMAINING_QUANTITY';
export const BULK_CONFIRM = 'BULK_CONFIRM';
export const CREATE_BULK_INVOICES = 'CREATE_BULK_INVOICES';
export const UPDATE_PARAMS = 'order/update_params';

export const FETCH_ORDERS = 'FETCH_ORDERS';

export const FORM_INFORMATION_TITLE = 'ORDER INFORMATION';
export const PERCENTAGE = 'percentage';
export const FLAT = 'flat';
export const DISCOUNT_TYPES = [
  { label: '%', value: PERCENTAGE },
  { label: 'Flat', value: FLAT },
];

export const title = 'Orders';
export const singularTitle = 'Order';
export const moduleName = 'orders';
export const baseUrl = '/orders';
export const redirectUrl = '/orders';
export const listingDataKey = 'orders';
export const itemDataKey = 'order';

export const orderConstants = {
  item: ORDER,
  setItems: SET_ORDERS,
  addItem: ADD_ORDER,
  setItem: ORDER_FETCHED,
  updateItem: ORDER_UPDATED,
  deleteItem: ORDER_DELETED,
  resetErrors: RESET_ORDER_ERRORS,
  autocompleteItems: AUTOCOMPLETE_ORDERS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// This constant use in order items nested form.
export const CANCELLATION_REASONS = [{ label: 'Customer', value: 'customer' }, { label: 'Stock Unavailability', value: 'stock_unavailability' }];

// Order states
export const draft = 'draft';
export const review = 'review';
export const cancel = 'cancel';
export const confirm = 'confirm';
export const cancelled = 'cancelled';
export const processing = 'processing';
export const invoiced = 'invoiced';
// export const delivered = 'delivered';

export const ORDER_STATES = [
  { label: 'Draft', value: draft }, { label: 'Confirm', value: confirm },
  { label: 'Processing', value: processing }, { label: 'Invoiced', value: invoiced },
  { label: 'Cancelled', value: cancelled },
];

export const ORDER_STATES_FOR_REPORTS = [
  { name: 'Draft', id: draft }, { name: 'Confirm', id: confirm },
  { name: 'Processing', id: processing }, { name: 'Invoiced', id: invoiced },
  { name: 'Cancelled', id: cancelled },
];

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  billing_address_line_1_cont: '',
  customer_po_number_eq: '',
  variants_id_in: [],
  state_in: [],
  variants_product_category_id_in: [],
  branch_id_in: [],
  variants_brand_id_in: [],
  user_id_in: [],
  customer_id_in: [],
  customer_beat_routes_id_in: [],
  voucher_book_id_in: [],
  // partially_invoiced: '',
};

export const defaultSearchParams = {
  number_or_customer_name_or_customer_gstin_or_customer_beat_routes_name_cont: '',
  invoices_id_eq: '', // This search params use in invoice show page while showing orders listing on invoice show page.
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['brands', 'users', 'product_categories', 'branches', 'customers', 'products', 'beat_routes', 'voucher_books'];

// Order breadcrumb
export const Title = 'Orders';
export const TargetBaseUrl = 'orders';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'sequence', 'user', 'user_id', 'customer', 'customer_id', 'shipping_address', 'shipping_address_id', 'billing_address', 'billing_address_id', 'branch', 'branch_id', 'notes'],
  order_items: ['variant', 'variant_id', 'description', 'quantity', 'input_booked_quantity', 'booked_quantity', 'input_price', 'input_cancelled_quantity', 'cancellation_reason',
    'input_quantity_unit', 'input_price_per_unit', 'order_item_purchase_order_items', 'order_item_custom_discount', 'order_item_custom_discount.value', 'order_item_custom_discount.discount_type'],
};
