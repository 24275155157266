import React from 'react';
import {
  string, bool, oneOfType, element,
} from 'prop-types';
import { gray_lighter } from '@assets/css/_variables.module.scss';
import OverlayTriggerTooltip from '@component/common/OverlayTriggerTooltip';

const FieldLabel = ({
  fieldKey, label, isRequired, description,
}) => (
  <label htmlFor={fieldKey} className="control-label">
    {`${label} `}
    {isRequired && (<sup data-cy={`${fieldKey}-required`} className="text-danger input-field-with-checkbox-sup">*</sup>)}
    {description && (
      <OverlayTriggerTooltip placement="right" description={description}>
        <i className="fa fa-info-circle" style={{ color: gray_lighter }} aria-hidden="true" />
      </OverlayTriggerTooltip>
    )}
  </label>
);

// PROPS
FieldLabel.propTypes = {
  fieldKey: string.isRequired,
  label: string.isRequired,
  isRequired: bool,
  description: oneOfType([string, element]),
};

FieldLabel.defaultProps = {
  isRequired: false, description: '' || null,
};
// !PROPS

export default FieldLabel;
