import axios from '../utils/axios';
import { serializeSearchParams } from '../utils/SerializeParams';
import { handleErrors } from '../utils/handleErrors';
import { setItems } from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';

const moduleName = 'banks';

// This function are use in container ComponentDidMount() callback function.
export function fetchBanksBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item', 'listingDataKey'] });
  const {
    baseUrl, redirectUrl, item, listingDataKey,
  } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_bank_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const items = response.data[listingDataKey];

      dispatch(setItems({ moduleName, responseData: { items } }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
