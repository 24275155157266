import { SET_RECENT_PURCHASE_RETURNS, PURCHASE_RETURNS } from '../constants/recents';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { setRecents } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  search_params: {
    module_name: PURCHASE_RETURNS,
  },
};

export default function recentPurchaseReturnsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECENT_PURCHASE_RETURNS: return setRecents(state, action);
    default:
      return state;
  }
}
