import React, { PureComponent } from 'react';
import { moduleName as creditNotesModule } from '@constants/credit_notes';
import { moduleName as debitNotesModule } from '@constants/debit_notes';

// TYPES
type Props = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  onFocus?: any;
  onSelect?: any;
  option: any;
}
// !TYPES

const descriptionStyle: React.CSSProperties = { marginTop: 4, fontSize: 12 };

class CustomUnitOption extends PureComponent<Props> {
  handleMouseDown = (event) => {
    const { onSelect, option } = this.props;
    event.preventDefault();
    event.stopPropagation();

    if (onSelect) {
      onSelect(option, event);
    }
  }

  handleMouseEnter = (event) => {
    const { onFocus, option } = this.props;

    if (onFocus) {
      onFocus(option, event);
    }
  }

  handleMouseMove = (event) => {
    const { isFocused, onFocus, option } = this.props;
    if (isFocused) return;

    if (onFocus) {
      onFocus(option, event);
    }
  }

  render() {
    // Here option expected to be => unit: 'case', label: 'Case - Piece', primary_unit_multiplier:
    // 200, primary_unit: false, description: '200 piece`, validation_regex: ''}
    const { option, children, className } = this.props;

    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={option?.title || ''}
        role="presentation"
      >
        <div>{children}</div>
        {/* `${option?.customLabel}` used in credit note and debit note items form in credit reason options */}
        {([creditNotesModule, debitNotesModule].includes(option?.voucherModuleName))
          && <div className="text-muted" style={descriptionStyle}>{option?.customLabel}</div>}
        {/* Unit description */}
        {option?.description && (<div className="text-muted" style={descriptionStyle}>{option?.description}</div>)}
      </div>
    );
  }
}

export default CustomUnitOption;
