import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_STOCK_ENTRIES, ADD_STOCK_ENTRY, STOCK_ENTRY_FETCHED,
  STOCK_ENTRY_UPDATED, STOCK_ENTRY_DELETED, defaultSearchParams,
} from '../constants/stock_entries';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
};

export default function stockEntries(state = initialState, action) {
  switch (action.type) {
    case SET_STOCK_ENTRIES: return setItems(state, action);
    case ADD_STOCK_ENTRY: return addItem(state, action);
    case STOCK_ENTRY_FETCHED: return fetchItem(state, action);
    case STOCK_ENTRY_UPDATED: return updateItem(state, action);
    case STOCK_ENTRY_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
