import { handleErrors } from './handleErrors';
import { logException } from './GAUtils';
// Returns state with errors removed from key inside errors object
// Don't sent setIsError and setIsFetching if wants them to remain same
export function removeErrorForKeyFromState(
  action: Record<string, any>,
  state: Record<string, any>,
  setIsError: boolean,
  setIsFetching: boolean,
) {
  const { key } = action;
  const { errors } = state;
  if (errors && errors[key]) {
    delete errors[key];
  }
  const newErrorObject = { errors, isError: false, isFetching: false };
  if (setIsError) newErrorObject.isError = setIsError;
  if (setIsFetching) newErrorObject.isFetching = setIsFetching;
  return {
    ...state,
    ...newErrorObject,
  };
}

export function handleBlobErrors(error, redirectTo, moduleName, shouldRedirect = false) {
  try {
    const blob = new Blob(
      [error.response.data],
      { type: 'text' },
    );
    const reader : any = new FileReader();
    reader.onload = () => {
      // Convert blob error data type to JSON object
      const modError = error;
      modError.response.data = JSON.parse(reader.result);
      handleErrors(modError, redirectTo, moduleName, shouldRedirect);
    };
    reader.readAsText(blob);
  } catch (ex) {
    logException(ex.message, false);
  }
}
