import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_ACCOUNTS, ADD_ACCOUNT, ACCOUNT_FETCHED, ACCOUNT_UPDATED, ACCOUNT_DELETED, defaultSearchParams,
} from '../constants/accounts';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'customer_name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNTS: return setItems(state, action);
    case ADD_ACCOUNT: return addItem(state, action);
    case ACCOUNT_FETCHED: return fetchItem(state, action);
    case ACCOUNT_UPDATED: return updateItem(state, action);
    case ACCOUNT_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
