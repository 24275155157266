import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { SET_AUTHORISATION_CONDITIONS, defaultSearchParams, moduleName } from '../constants/authorisation_conditions';
import { setItems } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'resource_type',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function authorisationConditions(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHORISATION_CONDITIONS: return setItems(state, action);
    default:
      return state;
  }
}
