import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BEAT_ROUTES, ADD_BEAT_ROUTE, BEAT_ROUTE_FETCHED, BEAT_ROUTE_UPDATED,
  AUTOCOMPLETE_BEAT_ROUTE, BEAT_ROUTE_DELETED, defaultSearchParams, moduleName,
} from '../constants/beat_routes';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function beatRoutes(state = initialState, action) {
  switch (action.type) {
    case SET_BEAT_ROUTES: return setItems(state, action);
    case ADD_BEAT_ROUTE: return addItem(state, action);
    case BEAT_ROUTE_FETCHED: return fetchItem(state, action);
    case BEAT_ROUTE_UPDATED: return updateItem(state, action);
    case BEAT_ROUTE_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_BEAT_ROUTE: return autocomplete(state, action);
    default:
      return state;
  }
}
