import { HOME_BREADCRUMB_LISTING } from './common';

export const BEAT_ROUTE_ASSIGNMENT = 'BEAT_ROUTE_ASSIGNMENT';
export const SET_BEAT_ROUTE_ASSIGNMENTS = 'SET_BEAT_ROUTE_ASSIGNMENTS';
export const ADD_BEAT_ROUTE_ASSIGNMENT = 'ADD_BEAT_ROUTE_ASSIGNMENT';
export const BEAT_ROUTE_ASSIGNMENT_FETCHED = 'BEAT_ROUTE_ASSIGNMENT_FETCHED';
export const BEAT_ROUTE_ASSIGNMENT_UPDATED = 'BEAT_ROUTE_ASSIGNMENT_UPDATED';
export const BEAT_ROUTE_ASSIGNMENT_DELETED = 'BEAT_ROUTE_ASSIGNMENT_DELETED';

export const title = 'Beat Route Assignments';
export const singularTitle = 'Beat Route Assignment';
export const moduleName = 'beat_route_assignments';
export const baseUrl = '/beat_route_assignments';
export const redirectUrl = '/beat_route_assignments';
export const listingDataKey = 'beat_route_assignments';
export const itemDataKey = 'beat_route_assignment';
export const permissionKey = moduleName;

export const beatRouteAssignmentConstants = {
  item: BEAT_ROUTE_ASSIGNMENT,
  setItems: SET_BEAT_ROUTE_ASSIGNMENTS,
  addItem: ADD_BEAT_ROUTE_ASSIGNMENT,
  setItem: BEAT_ROUTE_ASSIGNMENT_FETCHED,
  updateItem: BEAT_ROUTE_ASSIGNMENT_UPDATED,
  deleteItem: BEAT_ROUTE_ASSIGNMENT_DELETED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};

export const defaultSearchParams = {
  beat_route_name_or_user_name_cont: '',
};

export const OPEN_BEAT_ROUTE_ASSIGNMENT_FORM_MODAL = 'OPEN_BEAT_ROUTE_ASSIGNMENT_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const FREQUENCY = [{ value: 1, label: 'Periodically' }, { value: 2, label: 'Daily' }, { value: 3, label: 'Weekly' },
  { value: 4, label: 'Monthly' }];

export const DAYS_LISTING = [{ value: '0', label: 'SUNDAY' }, { value: '1', label: 'MONDAY' },
  { value: '2', label: 'TUESDAY' }, { value: '3', label: 'WEDNESDAY' }, { value: '4', label: 'THURSDAY' },
  { value: '5', label: 'FRIDAY' }, { value: '6', label: 'SATURDAY' }];

export const Title = 'Beat Route Assignments';
export const TargetBaseUrl = 'beat_route_assignments';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
