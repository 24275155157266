import React from 'react';
import { Link } from 'react-router-dom';
import { ADD_INVENTORY_BATCH, moduleName as inventoryBatches } from '@constants/inventory_batches';
import { ADD_INVENTORY_PIECE, moduleName as inventoryPieces } from '@constants/inventory_pieces';
import { CanUpdate, CanDelete } from '@helpers/authorization_helper';
import { getPermissionsHash, isLocalStorageColumnsAndDefaultColumnsEqual } from '@helpers/common_helper';
import { CategoryEnum, ActionEnum } from '@utils/GAUtils';
import { getActionTypes } from '@actions/action_helpers';
import { moduleName as purchaseInvoicesModuleName } from '@constants/purchase_invoices';
import { moduleName as purchaseReturnsModuleName } from '@constants/purchase_returns';
import { moduleName as returnsModuleName } from '@constants/returns';
import { moduleName as invoicesModuleName } from '@constants/invoices';
import { moduleName as stockTransfersModuleName } from '@constants/stock_transfers';

export const getInventoryModuleKey = (moduleName: string) => {
  let dataKey = 'batch';
  if (moduleName === inventoryPieces) {
    dataKey = 'piece';
  }
  return dataKey;
};

export const getCategoryEnum = (moduleName: string) => {
  let listingCategoryEnum = CategoryEnum.INVENTORY_PIECES;
  let formCategoryEnum = CategoryEnum.INVENTORY_PIECE_FORM;
  let updateCategoryEnum = CategoryEnum.INVENTORY_PIECE_UPDATE;
  let createCategoryEnum = CategoryEnum.INVENTORY_PIECE_CREATE;
  let detailCategoryEnum = CategoryEnum.INVENTORY_PIECE_DETAIL;

  if (moduleName === inventoryBatches) {
    listingCategoryEnum = CategoryEnum.INVENTORY_BATCHES;
    formCategoryEnum = CategoryEnum.INVENTORY_BATCH_FORM;
    updateCategoryEnum = CategoryEnum.INVENTORY_BATCH_UPDATE;
    createCategoryEnum = CategoryEnum.INVENTORY_BATCH_CREATE;
    detailCategoryEnum = CategoryEnum.INVENTORY_BATCH_DETAIL;
  }
  return {
    listingCategoryEnum,
    formCategoryEnum,
    updateCategoryEnum,
    createCategoryEnum,
    detailCategoryEnum,
  };
};

export const getActionEnum = (moduleName: string) => {
  const sortingActionEnum = ActionEnum.SORTING;
  let inventoryItemAddActionEnum = ActionEnum.INVENTORY_PIECE_ITEM_ADD;
  let newActionEnum = ActionEnum.INVENTORY_PIECE_NEW;
  let inventoryActionEnum = ActionEnum.INVENTORY_PIECE_ITEM_ACTION;
  let inventoryItemRemoveActionEnum = ActionEnum.INVENTORY_PIECE_ITEM_REMOVE;
  let showActionEnum = ActionEnum.INVENTORY_PIECE_SHOW;

  if (moduleName === inventoryBatches) {
    newActionEnum = ActionEnum.INVENTORY_BATCH_NEW;
    inventoryActionEnum = ActionEnum.INVENTORY_BATCH_ITEM_ACTION;
    inventoryItemAddActionEnum = ActionEnum.INVENTORY_BATCH_ITEM_ADD;
    inventoryItemRemoveActionEnum = ActionEnum.INVENTORY_BATCH_ITEM_REMOVE;
    showActionEnum = ActionEnum.INVENTORY_BATCH_SHOW;
  }

  return {
    sortingActionEnum,
    newActionEnum,
    inventoryActionEnum,
    inventoryItemAddActionEnum,
    inventoryItemRemoveActionEnum,
    showActionEnum,
  };
};

export const defaultColumns = [
  {
    key: 'number', label: 'Number', callFunc: true, sort_by: 'inventory_manufacturer_batch_number', can_sort: false, default: true, show: true, disabled: false,
  },
  {
    key: 'product_id', label: 'Product', callFunc: true, sort_by: 'inventory_manufacturer_batch_product_name', can_sort: false, default: true, show: true, disabled: false,
  },
  {
    key: 'mfd_date', label: 'Mfd Date', callFunc: true, sort_by: 'mfd_date', can_sort: false, default: true, show: true, disabled: false,
  },
  {
    key: 'expiry_date', label: 'Expiry Date', callFunc: true, sort_by: 'expiry_date', can_sort: false, default: true, show: true, disabled: false,
  },
  {
    key: 'action', label: 'Action', callFunc: true, sort_by: '', can_sort: false, default: true, show: true, disabled: false,
  },
];

isLocalStorageColumnsAndDefaultColumnsEqual({ moduleName: inventoryBatches, key: '', defaultColumns });

isLocalStorageColumnsAndDefaultColumnsEqual({ moduleName: inventoryPieces, key: '', defaultColumns });

interface FetchTableDataProps {
  args: any;
  moduleName: string;
  openModal(args: any): any;
}

export const fetchTableData = ({
  args, moduleName, openModal = () => ({}),
}: FetchTableDataProps) => {
  const { key, item } = args;
  const { can_update, can_destroy } = getPermissionsHash({
    required_object: item, required_permissions: ['can_show', 'can_update', 'can_destroy'],
  });

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl'] });
  const { baseUrl } = moduleTypes || {};

  const { listingCategoryEnum } = getCategoryEnum(moduleName);

  const moduleKey = getInventoryModuleKey(moduleName);
  const nestedFormkey = `inventory_manufacturer_${moduleKey}`;

  switch (key) {
    case 'product_id':
      return (<Link to={`/products/${item?.product_id}/edit`}>{item?.product_name || ''}</Link>);
    case 'number':
      return args?.item?.[nestedFormkey]?.number || '';
    case 'mfd_date':
      return args?.item?.[nestedFormkey]?.formatted_mfd_date || '';
    case 'expiry_date':
      return args?.item?.[nestedFormkey]?.formatted_expiry_date || '';
    case 'action':
      return (
        <div className="listing-item-actions">
          <CanUpdate
            can_update={can_update}
            path={`${baseUrl}/${item.id}/edit`}
            editing_what={listingCategoryEnum}
          />
          <CanDelete
            can_destroy={can_destroy}
            openModal={(arg) => openModal(arg)}
            deleting_what={listingCategoryEnum}
            modal_identifier_args={{ isModalOpen: true, modalIdentifier: 'DELETE_ITEM', delete_item: item }}
          />
        </div>
      );
    default:
      return item?.[key] || '';
  }
};

export const getInventoriesModuleName = (history: any, openFormInModal, quickAddModalIdentifier) => {
  const { location } = history;
  const pathname = location?.pathname || '';

  if (openFormInModal && quickAddModalIdentifier) {
    if (quickAddModalIdentifier === ADD_INVENTORY_BATCH) return inventoryBatches;
    if (quickAddModalIdentifier === ADD_INVENTORY_PIECE) return inventoryPieces;
  }

  if (pathname?.includes(inventoryBatches)) {
    return inventoryBatches;
  }

  if (pathname?.includes(inventoryPieces)) {
    return inventoryPieces;
  }

  return '';
};

export const getInventoryApiEndpoint = (moduleName, id = '') => {
  const endUrl = moduleName?.split('_')?.[1] || '';
  if (id) {
    return `/inventory/${endUrl}/${id}`;
  }

  return `/inventory/${endUrl}`;
};

export const getAutoCompleteAPIEndUrl = (moduleName, productId) => {
  let url = '';
  if (moduleName === inventoryPieces) {
    url = `/products/${productId}/pieces/autocomplete`;
  }

  if (moduleName === inventoryBatches) {
    url = `/products/${productId}/batches/autocomplete`;
  }

  return url;
};

export const getInventoryAPIKey = (moduleName: string) => {
  let dataKey = 'inventory_batch';
  if (moduleName === inventoryPieces) {
    dataKey = 'inventory_piece';
  }
  return dataKey;
};

export const getInventoryManufacturerErrorKey = (moduleName: string) => {
  let errorKey = 'inventory_manufacturer_batch';
  if (moduleName === inventoryPieces) {
    errorKey = 'inventory_manufacturer_piece';
  }
  return errorKey;
};

export const getInventoryManufacturerAPIKey = (moduleName: string) => {
  let errorKey = 'inventory_manufacturer_batch';
  if (moduleName === inventoryPieces) {
    errorKey = 'inventory_manufacturer_piece';
  }
  return errorKey;
};

export const getBulkCreateVoucherItemInventoryBatchesApiKey = (voucherModuleName: string) => {
  switch (voucherModuleName) {
    case purchaseInvoicesModuleName:
      return 'purchase_invoice_item';
    case purchaseReturnsModuleName:
      return 'purchase_return_item';
    case returnsModuleName:
      return 'return_item';
    case invoicesModuleName:
      return 'invoice_item';
    case stockTransfersModuleName:
      return 'stock_transfer_item';
    default:
      return '';
  }
};
