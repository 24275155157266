import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for product category index page.
const ProductCategories = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersProductCategoriesIndex" */ '@containers/product_categories/Index'),
  loading: Loading,
});

// We use loadable for product category new and edit page.
const ProductCategoryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersProductCategoriesCredate" */ '@containers/product_categories/Credate'),
  loading: Loading,
});

// We use loadable for brand index page.
const Brands = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBrandsIndex" */ '@containers/brands/Index'),
  loading: Loading,
});

// We use loadable for brand new and edit page.
const BrandCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBrandsCredate" */ '@containers/brands/Credate'),
  loading: Loading,
});

// We use loadable for stocks show page.
const BrandShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersBrandsShow" */ '@containers/brands/Show'),
  loading: Loading,
});

const CommonActionsBaseScreen = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersPurchaseReturnsShow" */ '@containers/common_actions/common_actions_base_screen'),
  loading: Loading,
});

const CatalogueRoutes = [
  {
    path: '/product_categories/new', name: 'Product Categories/new', component: ProductCategoryCredate,
  },
  {
    path: '/product_categories/:id/edit', name: 'Product Categories/edit', component: ProductCategoryCredate,
  },
  {
    path: '/product_categories', name: 'Product Categories', component: ProductCategories,
  },
  {
    path: '/brands/new', name: 'New Brand', component: BrandCredate,
  },
  {
    path: '/brands/:id/edit', name: 'Brand edit', component: BrandCredate,
  },
  {
    path: '/brands', name: 'Brands', component: Brands,
  },
  {
    path: '/brands/:id', name: 'Brand', component: BrandShow,
  },
  {
    path: '/prices', name: 'Prices', component: CommonActionsBaseScreen,
  },
];

export default CatalogueRoutes;
