export const moduleMappingWithClass = {
  orders: 'order',
  invoices: 'invoice',
  payments: 'payment',
  returns: 'return',
  credit_notes: 'credit_note',
  debit_notes: 'debit_note',
  customers: 'customer',
  purchase_orders: 'purchase_order',
  purchase_invoices: 'purchase_invoice',
  payment_outs: 'payment_out',
  purchase_returns: 'purchase_return',
  suppliers: 'supplier',
  stocks: 'stock',
  stock_entries: 'stock_entry',
  stock_transfers: 'stock_transfer',
  products: 'product',
  companies: 'company',
  product_categories: 'product_category',
  branches: 'branch',
  warehouses: 'warehouse',
  beat_routes: 'beat_route',
  customer_categories: 'customer_category',
  customer_ratings: 'customer_rating',
  offers: 'sales/offer',
  order_terms: 'voucher_term/order_term',
  invoice_terms: 'voucher_term/invoice_term',
  purchase_order_terms: 'voucher_term/purchase_order_term',
  credit_reasons: 'credit_reason',
  debit_reasons: 'debit_reason',
  bank_accounts: 'bank_account',
  tax_categories: 'tax_category',
  taxes: 'tax',
  voucher_books: 'voucher_book',
  period_locks: 'period_lock',
  document_categories: 'document_category',
  chart_of_accounts: 'plutus/account',
  journal_entries: 'plutus/journal_entry',
  reconciliations: 'reconciliation',
  reports: 'report',
  products_feed: 'product_feed',
  product_categories_feed: 'product_category_feed',
  customers_feed: 'customer_feed',
  suppliers_feed: 'supplier_feed',
  purchase_invoice_feed: 'purchase_invoice_feed',
  price_list_feed: 'price_list_feed',
  orders_feed: 'order_feed',
  invoices_feed: 'invoice_feed',
  payments_feed: 'payment_feed',
  payment_outs_feed: 'payment_out_feed',
  credit_notes_feed: 'credit_note_feed',
  stocks_feed: 'stock_feed',
  account_entries_feed: 'account_entry_feed',
  feeds: 'feed',
  tally_integration: 'integration#distributo#tally#sync',
  location_logs: 'location_log',
  statistics: 'statistics',
  sms_statistics: 'sms',
  organization: 'organization',
  users: 'user',
  users_groups: 'users_group',
  default_dac_roles: 'default_dac_role',
  settings: 'setting',
  print_formats: 'print_format',
  e_invoice_credentials: 'in/gsp_credential/e_invoice',
  e_way_bill_credentials: 'in/gsp_credential/e_way_bill',
  downloads: 'download',
  help: 'help',
  explore: 'explore',
  onboard: 'onboard',
  dashboard: 'dashboard',
  404: '404',
};
