import { fromJS } from 'immutable';
import {
  AVAILABLE_REPORTS_RESPONSE, REPORT_RESPONSE,
  REPORT_REQUEST, REPORT_RESPONSE_SUCCESS, CUSTOM_REPORTS_RESPONSE, MAP_REPORT_KEY,
  RESET_REPORT_DATA,
} from './ReportActions';
import { REPORTS } from '../../constants/reports';

export const initialState = fromJS({
  available_reports: [],
  reports_data: {},
  isFetching: false,
  isError: false,
  errors: {},
});

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_REQUEST:
      return state.set('isFetching', true)
        .set('isError', false)
        .set('errors', {});
    case `${REPORTS}_ERROR_RESPONSE`:
      return state.set('isFetching', false)
        .set('isError', true)
        .set('errors', action.errors);
    case AVAILABLE_REPORTS_RESPONSE: {
      const newState = state.set('available_reports', action.data)
        .set('isFetching', false)
        .set('isError', false)
        .set('errors', {});
      return newState;
    }
    case CUSTOM_REPORTS_RESPONSE: {
      const newState = state.set('custom_reports', action.data)
        .set('isFetching', false)
        .set('isError', false)
        .set('errors', {});
      return newState;
    }
    case REPORT_RESPONSE: {
      const { report_name, data } = action;
      const newState = state.setIn(['reports_data', report_name], data)
        .set('isFetching', false)
        .set('isError', false)
        .set('errors', {});
      return newState;
    }
    case REPORT_RESPONSE_SUCCESS: {
      const newState = state.set('isFetching', false)
        .set('isError', false)
        .set('errors', {});
      return newState;
    }
    case MAP_REPORT_KEY: {
      const { current_key, new_key } = action;
      const data = state.getIn(['reports_data', current_key]);
      const newState = state.setIn(['reports_data', new_key], data);
      return newState;
    }
    case RESET_REPORT_DATA: {
      const newState = state.setIn(['reports_data'], {});
      return newState;
    }
    default:
      return state;
  }
}
