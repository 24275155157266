import { concat, omit, unionBy } from 'lodash';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_VISITS, ADD_VISIT, VISIT_FETCHED, VISIT_UPDATED, VISIT_DELETED, TOGGLE_POST_VISIT_STATE,
  LOAD_MORE_VISITS, TOGGLE_FETCH_VISITS_STATE, SET_VISITABLE, SET_VISITS_MODULE_NAME, RESET_VISITS,
} from '@constants/visits';
import {
  setItems, fetchItem, updateItem, deleteItem, updateObject,
} from '@reducers/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'updated_at',
  sort_order: 'desc',
  per_page: 30,
  search_params: {},
  isPosting: false,
  visitable: '',
  visitableId: '',
  module_name: '',
  allowed: '',
  visitables: {},
};

function resetVisitsData() {
  return { ...initialState };
}

function setVisitsModuleName(state, action) {
  return { ...state, module_name: action.moduleName };
}

function setVisitable(state, action) {
  return { ...state, ...action?.payload };
}

function togglePostVisitState(state) {
  const updatedActionState = {
    isPosting: !state.isPosting,
  };
  return updateObject(state, updatedActionState);
}

function toggleFetchVisitsState(state) {
  const imState = fromJS(state).toJS();
  const updatedActionState = {
    isFetching: !imState.isFetching,
  };

  return { ...imState, ...updatedActionState };
}

function addVisit(state, action) {
  const updatedActionState = {
    items: concat(action.item, ...state.items),
    total_count: state.total_count + 1,
  };
  return updateObject(state, updatedActionState);
}

export function addMoreVisits(state, action) {
  let updatedActionState = { ...action };
  updatedActionState = omit(updatedActionState, 'type');
  if (action?.search_params) {
    updatedActionState.search_params = { ...state?.search_params, ...action?.search_params };
  }
  if (!isEmpty(action.items)) {
    const { total_count: totalCount } = action;
    const visits = unionBy(state.items, action.items, 'id');
    const visitsLeft = totalCount - visits?.length;
    updatedActionState.items = visits;
    updatedActionState.visits_left = visitsLeft;
  }

  return updateObject(state, updatedActionState);
}

export default function Visits(state = initialState, action) {
  switch (action.type) {
    case SET_VISITS_MODULE_NAME: return setVisitsModuleName(state, action);
    case SET_VISITABLE: return setVisitable(state, action);
    case SET_VISITS: return setItems(state, action);
    case ADD_VISIT: return addVisit(state, action);
    case LOAD_MORE_VISITS: return addMoreVisits(state, action);
    case TOGGLE_POST_VISIT_STATE: return togglePostVisitState(state);
    case TOGGLE_FETCH_VISITS_STATE: return toggleFetchVisitsState(state);
    case VISIT_FETCHED: return fetchItem(state, action);
    case VISIT_UPDATED: return updateItem(state, action);
    case VISIT_DELETED: return deleteItem(state, action);
    case RESET_VISITS: return resetVisitsData();
    default:
      return state;
  }
}
