import { map } from 'lodash';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_PURCHASE_ORDERS, ADD_PURCHASE_ORDER, PURCHASE_ORDER_FETCHED, PURCHASE_ORDER_UPDATED,
  PURCHASE_ORDER_DELETED, defaultSearchParams, moduleName, SET_PURCHASE_ORDER_ITEMS,
} from '@constants/purchase_orders';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function purchaseOrders(state = initialState, action) {
  switch (action.type) {
    case SET_PURCHASE_ORDERS: return setItems(state, action);
    case ADD_PURCHASE_ORDER: return addItem(state, action);
    case PURCHASE_ORDER_FETCHED: return fetchItem(state, action);
    case PURCHASE_ORDER_UPDATED: return updateItem(state, action);
    case PURCHASE_ORDER_DELETED: return deleteItem(state, action);
    case SET_PURCHASE_ORDER_ITEMS: {
      const items = map(state?.items, (purchaseOrder) => {
        if (purchaseOrder?.id !== action?.payload?.purchaseOrderID) return purchaseOrder;
        const purchaseOrderItems = map(purchaseOrder?.purchase_order_items, (purchaseOrderItem) => {
          if (purchaseOrderItem?.id !== action?.payload?.purchaseOrderItemID) { return purchaseOrderItem; }
          return action?.payload?.purchase_order_item || {};
        });
        return { ...purchaseOrder, purchase_order_items: purchaseOrderItems };
      });
      return {
        ...state,
        isFetching: false,
        isError: false,
        errors: {},
        items,
      };
    }
    default:
      return state;
  }
}
