import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';

// Fetch "Current Plan"
export function fetchActivePlan() {
  return () => axios({
    url: '/subscription_management/subscriptions/plan',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Features"
export function fetchFeatures() {
  return () => axios({
    url: '/subscription_management/features',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Plans"
export function fetchPlans() {
  return () => axios({
    url: '/subscription_management/plans',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Subscription Order New"
export function fetchSubscriptionsOrdersData(args) {
  const { subscription_action, service_name } = args || {};
  return () => axios({
    url: '/subscription_management/orders',
    method: 'get',
    params: { subscription_action, service_name },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Subscription Order Review"
export function fetchSubscriptionsOrdersReview(args) {
  const { subscriptionsOrdersReviewParams } = args || {};
  return () => axios({
    url: '/subscription_management/orders/review',
    method: 'post',
    data: { ...subscriptionsOrdersReviewParams },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Subscription Order History"
export function fetchSubscriptionsOrdersHistory() {
  return () => axios({
    url: '/subscription_management/orders/history',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Create Subscription Order"
export function createNewSubscriptionOrder(args) {
  const { subscriptionOrderData } = args;
  return () => axios({
    url: '/subscription_management/orders',
    method: 'post',
    data: { ...subscriptionOrderData },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Saved Success payment"
export function saveSuccessPaymentVerificationResponse(args) {
  const { subscriptionOrderId, paymentGatewayResponseData } = args;
  return () => axios({
    url: `/subscription_management/orders/${subscriptionOrderId}/payments`,
    method: 'post',
    data: { ...paymentGatewayResponseData },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}

// Fetch "Saved Failed payment"
export function saveFailedPaymentVerificationResponse(args) {
  const { subscriptionOrderId, paymentGatewayResponseData } = args;
  return () => axios({
    url: `/subscription_management/orders/${subscriptionOrderId}/payments`,
    method: 'post',
    data: { ...paymentGatewayResponseData },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', '');
      throw error.response;
    });
}
