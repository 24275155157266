import { call, put, takeLatest } from 'redux-saga/effects';
import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { SIGN_UP_ACCOUNT } from './constants';

function signUpAccountAction(args) {
  const { account } = args;

  return () => axios({
    url: '/account',
    method: 'POST',
    data: { api_v1_account: account },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/sign_up', '');
      throw error.response;
    });
}

function* signUpAccountFunc(action) {
  const { account } = action || {};
  try {
    const response = yield call(signUpAccountAction({ account }));
    // yield put({ type: SET_SING_UP_ACCOUNT, account: response?.data?.account || {} });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* signUpAccount() {
  yield takeLatest(SIGN_UP_ACCOUNT, signUpAccountFunc);
}
