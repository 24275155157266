import React, { Component } from 'react';
import { shape, func } from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import Loadable from 'react-loadable';
import NotificationSystem from 'react-notification-system';
import { fetchNotices } from '@saga/root';
import { dashboardVersion } from '@utils/common_constants';
import DOMPurify from 'dompurify';
import { DOMPurifyConfig } from '@utils/dom_purify_config';
import { hasDashboardPermission } from '@helpers/tenant_access';
import AppRoutes from '@routes/AppRoutes';
import Loading from '@component/common/Loading';
import { style } from '@component/common/Variables';
import {
  clearNotification, adsBlockDetected, changeCrashDialogVisibility,
  setActiveModule as setActiveModuleAction,
} from '@actions/root';
import { logException, removeIDAndQueriesFromUrl } from '@utils/GAUtils';
import { AD_BLOCK_ENABLED_ON_MODULES } from '@constants/root';
import {
  fetchRequiredOnAppStart, isProdEnv, isStagingEnv, setCurrentUrlInSessionStorage,
  getCurrentUrlFromSessionStorage, getCurrentUrl, getCurrentAnchor, getSubDomain, getLocalStorage,
} from '@helpers/common_helper';
// import ExceptionMessage from '@component/common/exception_message';
import ErrorDialogModal from '@component/common/error_dialog_modal';
import { PVC } from '@component/common/permission_container';
import { sendExceptionEmail } from '@api/exception_email';
import NotFoundComponent from '@component/common/not_found_component';
import SuccessIcon from '@assets/img/success.svg';
import ErrorIcon from '@assets/img/error.svg';
import WarningIcon from '@assets/img/warning.svg';
import InfoIcon from '@assets/img/info.svg';
import { moduleNameFromBaseUrl } from '@component/module_config/helper';
import ErrorBoundary from '@component/common/error_boundary';
import Enforcement from './subscription/enforcement';

const localStorageSettings = getLocalStorage({ key: 'settings' });

const Login = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAuthLogin" */ '@containers/auth/login'),
  loading: Loading,
});

const Sidebar = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonSidebar" */ '@component/common/Sidebar'),
  loading: Loading,
});

const AdsBlockModal = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonAdsBlockModal" */ '@component/common/AdsBlockModal'),
  loading: Loading,
});

const BannerSection = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonBannersSection" */ '@component/common/banners_section'),
  loading: Loading,
});

const QuickAddWrapper = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonQuickAddWrapper" */ '@component/common/quick_add_module/quick_add_wrapper'),
  loading: Loading,
});

const QuickListingWrapper = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonQuickListingWrapper" */ '@component/common/quick_listing/quick_listing_wrapper'),
  loading: Loading,
});

const OrganisationPage = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsOrganisationsPage" */ '@component/organisations/organisations_page'),
  loading: Loading,
});

const OrganisationFormPage = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsOrganisationFormPage" */ '@component/organisations/form'),
  loading: Loading,
});

const UpdateAccountProfile = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentAuthUpdateAccountProfileIndex" */ '@component/auth/update_account_profile/index'),
  loading: Loading,
});

const ResetAccountPassword = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsAuthResetAccountPassword" */ '@component/auth/reset_account_password'),
  loading: Loading,
});

const SignUp = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsAuthSignUp" */ '@component/auth/sign_up'),
  loading: Loading,
});

const AccountConfirmation = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsAuthSignUpAccountConfirmation" */ '@component/auth/account_confirmation'),
  loading: Loading,
});

const BillingPricing = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentSubscriptionBillingPricing" */ '@component/subscription/index'),
  loading: Loading,
});

const prodAnalyticsID = 'UA-136117261-1';
const stagingAnalyticsID = 'UA-136117261-2';
const prodSentryDSN = 'https://fcd24d4f6e3744f09c1215d18972ff99@sentry.io/4357261';

function initializeReactGA() {
  let analyticsID;
  if (process.env.NODE_ENV === 'staging') {
    analyticsID = stagingAnalyticsID;
  } else if (process.env.NODE_ENV === 'production') {
    analyticsID = prodAnalyticsID;
    Sentry.init({
      dsn: prodSentryDSN,
      // We ignore this sentry error becuase of there is no additional useful information to debug.
      // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
      ignoreErrors: ['ResizeObserver loop limit exceeded', 'Request failed with status code 401'],
    });

    // REF: https://docs.sentry.io/platforms/javascript/enriching-events/tags
    Sentry.setTag('dashboard-version', dashboardVersion);
  }

  if (analyticsID) {
    ReactGA.initialize(analyticsID, {
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    initializeReactGA();
    this.state = {
      pageView: '',
    };
  }

  setActiveModule = () => {
    const { rootReducer, setActiveModule } = this.props;
    const { activeModule } = rootReducer;
    const moduleName = moduleNameFromBaseUrl();
    if (!activeModule || (moduleName !== activeModule)) {
      setActiveModule({ activeModule: moduleName });
    }
  };

  componentDidMount() {
    window.addEventListener('error', this.handleWindowErrors);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    const { auth, dispatch } = this.props;
    const { isAuthenticated } = auth;
    const hasSubDomain = !!getSubDomain();
    const hasDashboardPermissions = hasDashboardPermission({ isAuthenticated });

    if ((hasDashboardPermissions) && hasSubDomain) {
      fetchRequiredOnAppStart(dispatch);
    } else {
      // Fetch notices even though the user is not authenticated, because there are some public
      // notices which we have to show the even though the user is not authenticated, something like
      // software update, software maintainence ..etc.,
      fetchNotices();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rootReducer } = nextProps;
    const { notification } = rootReducer;
    const { level, message } = notification;
    const { auth: { isAuthenticated }, dispatch } = this.props;

    this.setActiveModule();

    const hasSubDomain = !!getSubDomain();

    if ((level?.length > 0) && (message?.length > 0)) {
      this.showNotification(nextProps);
    }

    const willBeAuthenticated = nextProps?.auth?.isAuthenticated && !isAuthenticated;
    if (willBeAuthenticated && hasSubDomain) {
      fetchRequiredOnAppStart(dispatch);
    }
  }

  componentDidUpdate(e) {
    if (window.innerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
    const { pageView } = this.state;
    if (pageView !== window.location.href) {
      const modPageView = removeIDAndQueriesFromUrl(window.location.href);
      ReactGA.pageview(modPageView);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ pageView: window.location.href });
    }
  }

  // componentWillUnmount() {
  //   // window.removeEventListener('error', this.handleWindowErrors);
  // }

  updateOnlineStatus = (event) => {
    if (navigator.onLine) {
      this.handleNotification(<span data-notify="icon" className="pe-7s-like2" />,
        'Connected.', 'success', true, 2, true);
    } else {
      this.handleNotification(<span data-notify="icon" className="pe-7s-attention" />,
        'You are currently offline.', 'error', true, 0, false);
    }
  }

  handleWindowErrors = (error, errorStackTrace = '') => {
    const { changeCrashDialogVisibility, sendExceptionEmail } = this.props;
    // Log to google analytics
    logException(`${error.filename}:  ${error.lineno}:  ${error.message}`, true);
    // Log to sentry
    Sentry.withScope((scope) => {
      scope.setExtras({ crashDialog: true });
    });

    // Sending exception email only when in production or staging env.
    if (isProdEnv() || isStagingEnv()) {
      const { auth } = this.props;
      const currentUser = auth?.current_user || {};
      const userName = currentUser?.name || '';
      const userId = currentUser.id;
      const hasSubDomain = getSubDomain();

      let errorStackTraceMessage = '';
      if (error?.message) {
        errorStackTraceMessage = `${error?.message}`;
      }
      if (errorStackTrace) {
        errorStackTraceMessage = `${errorStackTraceMessage}-${errorStackTrace}`;
      }
      sendExceptionEmail({
        error: errorStackTraceMessage, tenantName: hasSubDomain, userId, userName,
      });
    }

    // Open creash dialog.
    changeCrashDialogVisibility(true);
  }

  closeAdBlockModal = () => {
    const { adsBlockDetected } = this.props;
    adsBlockDetected(false);
  }

  closeCrashDialog = () => {
    const { changeCrashDialogVisibility } = this.props;
    changeCrashDialogVisibility(false);
  }

  handleNotification(title, message, level, renderView, dismissTime = 2, dismissible = true) {
    const { notificationSystem } = this;

    const cleanInnerHTML = DOMPurify.sanitize(message, DOMPurifyConfig);

    notificationSystem.clearNotifications();
    notificationSystem.addNotification({
      title,
      message: (<div dangerouslySetInnerHTML={{ __html: cleanInnerHTML }} />),
      level,
      position: 'tr',
      autoDismiss: dismissTime,
      dismissible,
    });
    clearNotification({ renderView });
  }

  showNotification(nextProps) {
    const { rootReducer } = nextProps;
    const { notification } = rootReducer;
    const { renderView, message, level } = notification;
    switch (level) {
      case 'success': {
        this.handleNotification(<img src={SuccessIcon} className="success" alt="success" />, message, 'success', renderView);
        break;
      }
      case 'error': {
        this.handleNotification(<img src={ErrorIcon} className="error" alt="error" />, message, 'error', renderView);
        break;
      }
      case 'warning': {
        this.handleNotification(<img src={WarningIcon} className="warning" alt="warning" />, message, 'warning', renderView);
        break;
      }
      case 'info': {
        this.handleNotification(<img src={InfoIcon} className="info" alt="info" />, message, 'info', renderView);
        break;
      }
      default:
        return '';
    }
    return null;
  }

  switchRoutes() {
    const { auth, location, settings } = this.props;
    const clientSettings = settings?.client_settings || {};
    const { isAuthenticated } = auth;
    const currentURLAnchor = getCurrentAnchor();
    const hasSubDomain = !!getSubDomain();
    let currentUrl;
    if (currentURLAnchor !== '#/404') {
      currentUrl = getCurrentUrl();
      setCurrentUrlInSessionStorage(currentUrl);
    } else {
      currentUrl = getCurrentUrlFromSessionStorage();
    }
    const { from } = location.state || { from: { pathname: '/' } };

    const hasDashboardPermissions = hasDashboardPermission({ isAuthenticated });

    return (
      <Switch>
        <Route path="/account/password" render={() => <ResetAccountPassword />} />
        <Route path="/account/confirmation" render={() => <AccountConfirmation />} />
        {
          ((hasDashboardPermissions) && (currentURLAnchor === '#/') && !hasSubDomain)
            ? <Redirect to="/organisations" /> : null
        }
        <Route
          path="/login"
          render={(routeProps) => (
            (hasDashboardPermissions) ? (
              <Redirect to={from.pathname} />
            )
              : (
                <Login
                  {...routeProps}
                />
              )
          )}
        />
        {
          // Below we use key={routeProps.match.params.id} it's helpfull to call
          // componentDidMount method.
          // Normally history.push to same url this time componentDidMount method not call again.
          // If you want to call same url with different id or value and hit the
          // componentDidMount method.
          // Then add key ie. <route.component key={routeProps.match.params.id} />
          // with unique identifier.
          // This key is helpfull to identify uniq component.
          AppRoutes.map((route) => {
            if (route?.routeDependsOnSetting
              && (!localStorageSettings?.[route?.settingKey] && !clientSettings?.[route?.settingKey])) {
              return '';
            }

            return (
              <Route
                exact
                path={route.path}
                key={route.path}
                render={(routeProps) => (
                  (hasDashboardPermissions)
                    ? (
                      <ErrorBoundary
                        onError={
                          (error, errorStackTrace) => {
                            this.handleWindowErrors(error, errorStackTrace)
                          }
                        }
                      >
                        <PVC>
                          <route.component
                            key={routeProps.match.params.id}
                            {...routeProps}
                          />
                        </PVC>
                      </ErrorBoundary>
                    )
                    : (
                      <Redirect to={{
                        pathname: '/login',
                        state: { from: routeProps.location },
                      }}
                      />
                    )
                )}
              />
            );
          })
        }
        <Route path="/account/password" render={() => <ResetAccountPassword />} />
        {
          (!isAuthenticated && !hasDashboardPermissions && (!currentURLAnchor?.includes('#/sign_up')))
            ? <Redirect to="/login" /> : null
        }
        {
          (!hasDashboardPermissions && (currentURLAnchor?.includes('#/sign_up')))
            ? <Route path="/sign_up" render={() => <SignUp />} /> : null
        }
        {
          ((hasDashboardPermissions) && (currentURLAnchor === '#/organisations/new'))
            ? <Route path="/organisations/new" render={() => <OrganisationFormPage />} /> : null
        }
        {
          ((hasDashboardPermissions)
            && (currentURLAnchor === '#/organisations') && (hasSubDomain))
            ? <Redirect to="/" /> : <Route path="/organisations" render={() => <OrganisationPage />} />
        }
        {
          (hasDashboardPermissions && (currentURLAnchor === '#/update_profile'))
            ? <Route path="/update_profile" render={() => <UpdateAccountProfile />} /> : null
        }
        {
          (hasDashboardPermissions && (currentURLAnchor === '#/billing/pricing'))
            ? <Route exact key="billing_pricing" path="/billing/pricing" render={(props) => <BillingPricing {...props} isPricingPage />} /> : null
        }
        {
          (hasDashboardPermissions && (currentURLAnchor === '#/subscription'))
            ? <Route exact key="subscription" path="/subscription" render={(props) => <BillingPricing {...props} isPricingPage={false} />} /> : null
        }
        <Route path="/404" render={() => (<NotFoundComponent isLoggedIn={isAuthenticated} requestedUrl={currentUrl || ''} />)} />
        <Redirect from="*" to="/404" />
      </Switch>
    );
  }

  render() {
    const {
      auth, rootReducer, location,
    } = this.props;
    const { isAdsBlockDetected, crashDialogVisibility } = rootReducer;
    const { isAuthenticated } = auth;
    const [, currentPath] = location.pathname.split('/');

    const canShowAdsBlockDetectedEle = AD_BLOCK_ENABLED_ON_MODULES.includes(currentPath);

    const hasSubDomain = !!getSubDomain();

    const hasDashboardPermissions = hasDashboardPermission({ isAuthenticated });

    return (
      <>
        <NotificationSystem ref={(c) => { this.notificationSystem = c; }} style={style} />
        <BannerSection />
        {
          canShowAdsBlockDetectedEle
            ? (
              <div id="ads-block-detected" style={{ position: 'absolute', zIndex: 0 }}>
                <div className="adBanner" />
              </div>
            )
            : null
        }
        {
          ((hasDashboardPermissions) && hasSubDomain
            && !['#/organisations', '#/organisations/new', '#/account/password',
              '#/update_profile', '#/account/confirmation', '#/billing/pricing', '#/subscription'].includes(window.location.hash))
            ? (
              <div className="wrapper">
                <Sidebar {...this.props} />
                <div id="main-panel" className="main-panel ps">
                  <Enforcement />

                  <div className="main-content">
                    {this.switchRoutes()}
                  </div>
                  {/* <Footer /> */}
                </div>
              </div>
            )
            : (
              <div className="login-main-container main-content">
                {this.switchRoutes()}
              </div>
            )
        }
        {
          isAdsBlockDetected
            ? (
              <AdsBlockModal
                show={isAdsBlockDetected}
                closeModal={this.closeAdBlockModal}
              />
            )
            : null
        }
        {
          crashDialogVisibility
            ? (
              <ErrorDialogModal
                show={crashDialogVisibility}
                closeModal={this.closeCrashDialog}
              />
            )
            : null
        }

        {/* Quick add Wrapper */}
        <QuickAddWrapper />

        {/* Quick Listing Wrapper */}
        <QuickListingWrapper />
      </>
    );
  }
}

App.propTypes = {
  auth: shape({}).isRequired,
  location: shape({}).isRequired,
  rootReducer: shape({}),
  adsBlockDetected: func.isRequired,
  changeCrashDialogVisibility: func.isRequired,
  dispatch: func.isRequired,
  setActiveModule: func.isRequired,
  settings: shape({}),
};

App.defaultProps = {
  rootReducer: {},
  settings: {},
};

function mapStateToProps(state) {
  const {
    auth, root_reducer: rootReducer, settings,
  } = state;
  return { auth, rootReducer, settings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    adsBlockDetected,
    changeCrashDialogVisibility,
    sendExceptionEmail,
    setActiveModule: setActiveModuleAction,
    dispatch,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
