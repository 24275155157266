import {
  ELIGIBLE_OFFER as baseType, SET_ELIGIBLE_OFFERS,
} from '../constants/eligible_offers';
import axios, { fetchingRequest } from '../utils/axios';
import { handleErrors } from '../utils/handleErrors';
import { setParams } from '../actions/common';

const defaultUrl = '/sales/offers/eligible_for_invoice';

export function fetchEligibleOffers(args) {
  const { url, redirectUrl, params } = args;
  fetchingRequest(baseType);
  return (dispatch) => axios({
    url: url || defaultUrl,
    method: 'get',
    params,
  })
    .then((response) => {
      const { offers } = response.data;
      dispatch(setParams({ type: SET_ELIGIBLE_OFFERS, params: { items: offers } }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl || '/', baseType);
      // throw error.response;
    });
}
