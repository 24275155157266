/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import debounce from 'lodash/debounce';
import { getClassNamesForField } from '@helpers/common_helper';
import FieldLabel from '@component/common/form_components/FieldLabel';
import { ENTER } from '@constants/event_keys';

const InputFieldWithCheckbox = ({
  name, label, handleChange, value,
  showCheckbox = true, checkboxKey = '', checkboxValue = false, checkBoxLabel = '',
  fieldContainerClassNames = '', fieldErrors = '', type = 'text',
  placeholder = '', isRequired = false, description = '',
  allErrors = {}, isDisabled = false, debounceInput = false,
  customClassNames = '', tabIndex = 0, checkBoxStyle = {}, checkboxTabIndex = -1,
}: InputFieldWithCheckboxProps) => {
  const key = `form-field-${name}`;

  // WHAT: debounce onChange handler
  const debounceOnChange = debounce((e) => { handleChange(e, name); }, 750);

  // WHAT: props when debounceInput is false - behaves like a controlled input
  const normalInputProps = { value, onChange: (e) => handleChange(e, name) };

  // WHAT: props when debounceInput is true - behaves like a uncontrolled input
  const debouncedInputProps = { onChange: (e) => { e.persist(); debounceOnChange(e); } };

  return (
    <div className={getClassNamesForField(name, (fieldContainerClassNames || 'col-md-6'), allErrors)}>
      <div className="form-group" key={key}>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FieldLabel
            fieldKey={key}
            label={label}
            description={description}
            isRequired={isRequired}
          />
          {showCheckbox ? (
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                marginLeft: '10',
                fontSize: 12,
                alignItems: 'center',
                height: 24,
                ...checkBoxStyle,
              }}
            >
              <label
                htmlFor={`input-checkbox-${name}`}
                className="check-hover"
                tabIndex={checkboxTabIndex}
                onKeyDown={
                  (e: any) => {
                    if (e?.key === ENTER) {
                      e.preventDefault();
                      handleChange({ target: { name: checkboxKey, value: !checkboxValue } });
                    }
                  }
                }
              >
                <input
                  type="checkbox"
                  name={checkboxKey}
                  id={`input-checkbox-${name}`}
                  onChange={(e: any) => {
                    handleChange({ target: { name: checkboxKey, value: e.target.checked } });
                  }}
                  tabIndex={-1}
                  checked={checkboxValue}
                />
              </label>
              {checkBoxLabel}
            </div>
          ) : null}
        </div>

        {/* WHAT: input */}
        <input
          id={key}
          data-cy={key}
          type={type}
          name={name}
          className={`form-control ${customClassNames}`}
          placeholder={placeholder || label}
          {...(debounceInput ? debouncedInputProps : normalInputProps)}
          autoComplete="off"
          disabled={isDisabled}
          tabIndex={tabIndex}
        />

        {/* WHAT: input errors */}
        <span className="errors-message">{fieldErrors}</span>
      </div>
    </div>
  );
};

interface InputFieldWithCheckboxProps {
  name: string;
  label: string;
  handleChange: Function;
  value: string | number;
  fieldContainerClassNames?: string;
  fieldErrors?: string;
  type?: string;
  placeholder?: string;
  isRequired?: boolean;
  description?: any;
  allErrors?: any;
  isDisabled?: boolean;
  debounceInput?: boolean;
  customClassNames?: string;
  showCheckbox?: boolean;
  checkboxKey?: string;
  checkboxValue?: boolean;
  checkBoxLabel?: string;
  tabIndex?: number;
  checkBoxStyle?: any;
  checkboxTabIndex?: number;
}

export default InputFieldWithCheckbox;
