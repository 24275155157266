import axios from '../utils/axios';
import { moduleName, PRINT_FORMAT } from '../constants/print_formats';
import { handleErrors } from '../utils/handleErrors';
import { startFetchingRequest } from '../actions/action_helpers';
import { toBase64 } from '../helpers/file_helper';

export async function fileReader(file) {
  const reader = new FileReader();
  return new Promise<any>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing file'));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsText(file);
  });
}

export async function parseErrorResponse(error, cb) {
  const { data } = error.response;
  try {
    // Read
    const file = await fileReader(data);
    const errRes = JSON.parse(file) || {};
    cb(errRes);
  } catch (e) {
    cb(e);
  }
  return '';
}

function handleErr(errorStatus, errors) {
  const err = { response: { data: errors, status: errorStatus } };
  handleErrors(err, '/print_formats', PRINT_FORMAT);
}
interface FetchSettableCategoriesProps {
  url: string;
}

export function fetchPrintFormatApi({ url }: FetchSettableCategoriesProps) {
  startFetchingRequest(moduleName);

  const axiosConfig: any = {
    url: `${url}`,
    method: 'get',
  };

  return () => axios(axiosConfig)
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/print_formats', PRINT_FORMAT);
      // throw error.response;
    });
}

interface FetchPrintFormatPreviewProps {
  url: string;
}

export function fetchPrintFormatPreviewApi({ url }: FetchPrintFormatPreviewProps) {
  startFetchingRequest(moduleName);

  const axiosConfig: any = {
    url: `${url}`,
    method: 'get',
  };

  const previewUrl = url.indexOf('preview') !== -1;
  if (previewUrl) {
    axiosConfig.responseType = 'blob';
  }

  return () => axios(axiosConfig)
    .then((response) => response)
    .catch((error) => {
      const { status } = error.response;
      parseErrorResponse(error, (errRes) => {
        if (error.response.status !== 404) {
          return handleErr(status, errRes);
        }
        return null;
      });
      throw error;
    });
}

interface CreateApiProps {
  url: string;
  data: any;
}

export function createPrintFormatApi({ url, data }: CreateApiProps) {
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${url}`,
    method: 'post',
    data: {
      configuration: data,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/print_formats', PRINT_FORMAT);
      // throw error.response;
    });
}
interface UpdateApiProps {
  url: string;
  data: any;
}

export function updatePrintFormatApi({ url, data }: UpdateApiProps) {
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${url}`,
    method: 'put',
    data: {
      configuration: data,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/print_formats', PRINT_FORMAT);
      // throw error.response;
    });
}

interface DeleteApiProps {
  url: string;
}

export function deletePrintFormatApi({ url }: DeleteApiProps) {
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${url}`,
    method: 'delete',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/print_formats', PRINT_FORMAT);
      // throw error.response;
    });
}

interface PreviewPrintFormatProps {
  response: any;
}

export function previewPrintFormat({ response }: PreviewPrintFormatProps) {
  return () => toBase64(response)
    .then((uri) => uri);
}
