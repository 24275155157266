export const CREDIT_LEEWAY = 'CREDIT_LEEWAY';
export const SET_CREDIT_LEEWAYS = 'SET_CREDIT_LEEWAYS';
export const CREDIT_LEEWAY_UPDATED = 'CREDIT_LEEWAY_UPDATED';
export const ADD_CREDIT_LEEWAY = 'ADD_CREDIT_LEEWAY';
export const CREDIT_LEEWAY_FETCHED = 'CREDIT_LEEWAY_FETCHED';
export const CREDIT_LEEWAY_DELETED = 'CREDIT_LEEWAY_DELETED';
export const AUTOCOMPLETE_CREDIT_LEEWAYS = 'AUTOCOMPLETE_CREDIT_LEEWAYS';

export const title = 'Credit Leeways';
export const singularTitle = 'Credit Leeway';
export const moduleName = 'credit_leeways';
export const baseUrl = '/credit_leeways';
export const redirectUrl = '/credit_leeways';
export const listingDataKey = 'credit_leeways';
export const itemDataKey = 'credit_leeway';

export const creditLeewayConstants = {
  item: CREDIT_LEEWAY,
  setItems: SET_CREDIT_LEEWAYS,
  addItem: ADD_CREDIT_LEEWAY,
  setItem: CREDIT_LEEWAY_FETCHED,
  updateItem: CREDIT_LEEWAY_UPDATED,
  deleteItem: CREDIT_LEEWAY_DELETED,
  autocompleteItems: AUTOCOMPLETE_CREDIT_LEEWAYS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};
