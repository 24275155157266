import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import OverlayTriggerTooltip from '@component/common/OverlayTriggerTooltip';
import { downloadPdf as downloadFileAction } from '@saga/root';
import { AxiosError, AxiosResponse } from 'axios';
import { logEvent } from '@src/utils/GAUtils';
import { downloadFileHelper } from '@component/common/download_files/helper';

interface DownloadFileProps {
  url: string;
  params: Record<string, unknown>;
  redirectTo: string;
  identifier: string;
  shouldOpen: boolean;
  shouldDownloadForGet: boolean;
}

// Interfaces
interface Props {
  description?: string;
  className?: string;
  placement?: string;
  url: string;
  params?: Record<string, unknown>;
  redirectUrl: string;
  identifier: string;
  canDownloadFile?: boolean;
  logEventForListingPage?: boolean;
  logEventForShowPage?: boolean;
  dowloadingWhat?: string;
  downloadFile({
    url, params, redirectTo, identifier, shouldOpen, shouldDownloadForGet,
  }: DownloadFileProps): any;
}

const DownloadFile = (props: Props) => {
  // Props
  const {
    className, description, placement, canDownloadFile, identifier,
    logEventForListingPage, logEventForShowPage, dowloadingWhat,
  } = props;

  // Hooks
  const [isLoading, setLoading] = useState(false);

  // Arrow functions
  const downloadFileFunc = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setLoading(true);

    const {
      url, params, redirectUrl, downloadFile,
    } = props;

    downloadFile({
      url,
      params,
      redirectTo: redirectUrl,
      identifier,
      shouldOpen: false,
      shouldDownloadForGet: true,
    })
      .then((response: AxiosResponse) => {
        downloadFileHelper({ response });
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
      });
  };

  const downloadingFileIdentifier = dowloadingWhat || `${identifier}_DOWNLOAD`;

  if (canDownloadFile) {
    return (
      <span>
        <OverlayTriggerTooltip placement={placement} description={description}>
          <button
            className={className}
            type="button"
            tabIndex={0}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              downloadFileFunc(e);
              if (logEventForListingPage) {
                logEvent(`${downloadingFileIdentifier}`, `${downloadingFileIdentifier}_Index_Item_Action`, `${downloadingFileIdentifier}_Download_Pdf`);
              }
              if (logEventForShowPage) {
                logEvent(`${downloadingFileIdentifier}`, `${downloadingFileIdentifier}_Show_Item_Action`, `${downloadingFileIdentifier}_Download_Pdf`);
              }
            }}
          >
            <i className={isLoading ? 'fa fa-spinner fa-spin' : 'fa fa-download'} aria-hidden="true" />
          </button>
        </OverlayTriggerTooltip>
      </span>
    );
  }
  return <div />;
};

DownloadFile.defaultProps = {
  params: {},
  description: 'Download PDF',
  placement: 'bottom',
  className: '',
  canDownloadFile: true,
  logEventForListingPage: false,
  logEventForShowPage: false,
  dowloadingWhat: '',
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ downloadFile: downloadFileAction }, dispatch);
}

export default connect(null, mapDispatchToProps)(DownloadFile);
