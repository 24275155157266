import {
  convertToTitleize, getLocalStorage, getNumberOfFiltersApplied, setLocalStorage,
} from '@src/helpers/common_helper';

export const DEFAULT = 'default';
export const FILTER = 'filter';
export const SEARCH = 'search';

// FUNCTION   returns index_params key for a moduleName
const getModuleIndexParamsKey = (module) => (`${module}_index_params`);

export const getHeaderSelection = ({ moduleName, selectionKey }) => {
  const titleizeModuleName = convertToTitleize(moduleName);
  switch (selectionKey) {
    case DEFAULT:
      return { key: `all_${moduleName}`, label: `All ${titleizeModuleName}`, clearFilters: true };
    case FILTER:
      return { key: `filtered_${moduleName}`, label: `Filtered ${titleizeModuleName}` };
    case SEARCH:
      return { key: `searched_${moduleName}`, label: `${titleizeModuleName} - Search Results` };
    default:
      return { key: `all_${moduleName}`, label: `All ${titleizeModuleName}`, clearFilters: true };
  }
};

export const filtersApplied = (search_params) => {
  let numberOfAdvancedSearchApplied = 0;
  if (search_params && Object.keys(search_params).length > 0) {
    numberOfAdvancedSearchApplied = getNumberOfFiltersApplied({ search_params });
  }
  return numberOfAdvancedSearchApplied > 0;
};

export const setAndGetIndexRelatedParams = ({ moduleName, defaultSearchParams }) => {
  let curModuleIndexParams = getLocalStorage({ key: getModuleIndexParamsKey(moduleName) });

  if (!curModuleIndexParams) {
    const defaultSelectedVariant = getHeaderSelection({ moduleName, selectionKey: DEFAULT });
    curModuleIndexParams = {
      filter_params: defaultSearchParams,
      selected_variant: defaultSelectedVariant,
    };

    setLocalStorage({ key: getModuleIndexParamsKey(moduleName), data: curModuleIndexParams });
    return { curSelectedVariant: defaultSelectedVariant, curSearchParams: defaultSearchParams };
  }

  const curSelectedVariant = curModuleIndexParams?.selected_variant || {};
  const curSearchParams = curModuleIndexParams?.filter_params || {};
  const curOtherAdvSearchConfig = curModuleIndexParams?.otherAdvSearchConfig || {};

  return { curSelectedVariant, curSearchParams, curOtherAdvSearchConfig };
};
