import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';
import OrderCredate from '../containers/orders/Credate';

// We use loadable for order index page.
const Orders = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOrdersIndex" */ '../containers/orders/Index'),
  loading: Loading,
});

// We use loadable for order new and edit page.
// const OrderCredate = Loadable({
//   loader: () => import(/* webpackChunkName : "ContainersOrdersCredate" */ '../containers/orders/Credate'),
//   loading: Loading,
// });

// We use loadable for order show page.
const OrderShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersOrdersShow" */ '../containers/orders/Show'),
  loading: Loading,
});

const OrderRoutes = [
  {
    path: '/orders/new', name: 'orders/new', component: OrderCredate,
  },
  {
    path: '/orders/:id/edit', name: 'orders/edit', component: OrderCredate,
  },
  {
    path: '/orders/:id', name: 'Orders', component: OrderShow,
  },
  {
    path: '/orders', name: 'Orders', component: Orders,
  },
];

export default OrderRoutes;
