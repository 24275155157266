import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { serializeSearchParams } from '@src/utils/SerializeParams';

interface Props {
  variantId: string;
  partyId: string;
  pricePerUnit: string;
  redirectUrl: string;
  perPage: number,
}

export function fetchHistoricalPricingListing(args: Props) {
  const {
    variantId, partyId, pricePerUnit, redirectUrl, perPage,
  } = args;
  const q = serializeSearchParams({ product_id_eq: variantId, voucher_party_id_eq: partyId });
  return () => axios({
    url: `/voucher_items?${q}&page[size]=${perPage}`,
    method: 'get',
    params: { price_per_unit: pricePerUnit },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, '');
      throw error.response;
    });
}
