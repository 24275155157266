import { requiredPrefetchingModuleNames as requiredPrefetchingApiModuleNamesForCustomers } from './customers';

export const FORM_INFORMATION_TITLE = 'OFFER INFORMATION';

export const OFFER = 'OFFER';
export const SET_OFFERS = 'SET_OFFERS';
export const ADD_OFFER = 'ADD_OFFER';
export const OFFER_FETCHED = 'OFFER_FETCHED';
export const OFFER_UPDATED = 'OFFER_UPDATED';
export const OFFER_DELETED = 'OFFER_DELETED';
export const UPDATE_ELIGIBLE_CUSTOMERS_COUNT = 'UPDATE_ELIGIBLE_CUSTOMERS_COUNT';
export const UPDATE_APPLICABLE_ON_PRODUCTS_COUNT = 'UPDATE_APPLICABLE_ON_PRODUCTS_COUNT';

export const ITEM_OFFERS = 'item_offers';
export const INVOICE_OFFERS = 'invoice_offers';

export const ITEM_OFFER_TYPE = 'Sales::ItemOffer';
export const ITEM_GROUP_OFFER_TYPE = 'Sales::ItemGroupOffer';
export const INVOICE_OFFER_TYPE = 'Sales::InvoiceOffer';

export const OFFER_TYPES = [
  { type: ITEM_OFFER_TYPE, label: 'a line item', labelForIndex: 'Item Offer' },
  { type: ITEM_GROUP_OFFER_TYPE, label: 'a group of line items combined', labelForIndex: 'Item Group Offer' },
  { type: INVOICE_OFFER_TYPE, label: 'invoice', labelForIndex: 'Invoice Offer' },
];

export const CUSTOMERS_FILTER = 'customers_filter';
export const PRODUCTS_FILTER = 'products_filter';
export const CONDITION_PRODUCTS_FILTER = 'condition_products_filter';
export const OFFER_PRODUCTS_FILTER = 'offer_products_filter';

export const advanceSearchParams = {
  valid_from_gteq: '',
  valid_till_lteq: '',
  disabled_eq: '',
  condition_on_eq: '',
  condition_operator_eq: '',
  type_eq: '',
  offer_benefits_impact_type_eq: '',
  offer_benefits_impact_on_eq: '',
  applicable_on_product_ids: [],
  eligible_for_customer_ids: [],
};

export const defaultSearchParams = {
  name_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['products', 'customers'];

export const requiredPrefetchingApiModuleNamesForOfferProducts = ['brands', 'product_categories', 'tax_categories', 'products', 'units'];
export const requiredPrefetchingModuleNamesForOffersCustomer = [...requiredPrefetchingApiModuleNamesForCustomers, 'customers'];

export const CONDITION_ON_OPTIONS = [
  { value: 'gross_amount', label: 'Amount' },
  { value: 'quantity', label: 'Quantity' },
  { value: 'net_product_quantity', label: 'Net Product Quantity' },
];

export const CONDITION_ON_OPTIONS_FOR_INVOICE_OFFER = [
  { value: 'gross_amount', label: 'Amount' },
];

export const greater_than_or_equals_to = 'greater_than_or_equals_to';
export const every = 'every';
export const gteq_lt = 'gteq_lt';
export const gt_lteq = 'gt_lteq';

export const CONDITION_OPERATOR_OPTIONS = [
  { value: greater_than_or_equals_to, label: 'is greater or equal to', operator: '>=' },
  { value: every, label: 'is, every time, equal to', operator: '/' },
  { value: gteq_lt, label: 'is between, meaning equal or greater than', operator: '' },
  { value: gt_lteq, label: 'is between, meaning greater than', operator: '' },
];

export const IMPACT_ON_OPTIONS_FOR_INVOICE_OFFER = [
  { value: 'gross_amount', label: 'Gross Amount' },
];

export const IMPACT_ON_OPTIONS = [
  { value: 'gross_price', label: 'Gross Price' },
  { value: 'gross_amount', label: 'Gross Amount' },
];

export const percentage = 'percentage';
export const flat = 'flat';
export const quantity = 'quantity';
export const percentage_markdown = 'percentage_markdown';
export const flat_markdown = 'flat_markdown';

export const percentageImpactType = { value: percentage, label: 'Percentage' };
export const flatImpactType = { value: flat, label: 'Flat' };
export const quantityImpactType = { value: quantity, label: 'Quantity' };
export const percentageMarkdownImpactType = { value: percentage_markdown, label: 'Percentage Markdown' };
export const flatMarkdownImpactType = { value: flat_markdown, label: 'Flat Markdown' };

export const IMPACT_TYPE_OPTIONS = [percentageImpactType, flatImpactType];

export const IMPACT_TYPE_OPTIONS_FOR_ITEM_OFFERS = [
  percentageImpactType, flatImpactType, quantityImpactType, percentageMarkdownImpactType,
  flatMarkdownImpactType,
];

export const DISABLED_OPTIONS = [
  { value: 'true', label: 'Yes' }, { value: 'false', label: 'No' },
];

export const title = 'Offers';
export const singularTitle = 'Offer';
export const moduleName = 'offers';
export const baseUrl = '/offers';
export const redirectUrl = '/offers';
export const listingDataKey = 'offers';
export const itemDataKey = 'offer';
export const permissionKey = 'sales/offers';

export const getHandledErrorKeysForOffersModule = (type) => {
  switch (type) {
    case INVOICE_OFFER_TYPE:
      return {
        master: ['name', 'valid_from', 'valid_till', 'condition_on', 'condition_operator', 'condition_value', 'overall_usage_limit', 'customer_usage_limit', 'customers_filter', 'description', 'chain_benefits'],
        offer_benefits: ['name', 'impact_type', 'impact_on', 'position', 'impact_value', 'impact_max_limit', 'extra_product_id', 'extra_product', 'extra_product_price'],
      };
    case ITEM_OFFER_TYPE:
    case ITEM_GROUP_OFFER_TYPE:
      return {
        master: ['name', 'valid_from', 'valid_till', 'condition_on', 'condition_operator', 'condition_value', 'overall_usage_limit', 'customer_usage_limit', 'customers_filter', 'products_filter', 'condition_products_filter', 'description', 'chain_benefits'],
        offer_benefits: ['name', 'impact_type', 'impact_on', 'position', 'impact_value', 'impact_max_limit', 'extra_product_id', 'extra_product', 'extra_product_price'],
      };
    default:
      return {
        master: [],
        offer_benefits: [],
      };
  }
};
