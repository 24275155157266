import { CHART_REQUEST, CHART_RESPONSE, CHART_DEFAULT_PARAMS } from './charts_actions';
import { COMMON } from '../../constants/common_constants';

interface ActionProps {
  type: string; chartKey: string; api: string; response: any; groupBy: [];
  searchParams: any; activeReportPeriod: string;
}

export const defaultChartsState = {
  [COMMON]: {
  },
  sales: {
    loading: false,
    api: 'reports/invoice_items/sales_plot',
    data: {},
    meta: {
      title: 'SALES',
    },
    totals: {},
  },
};

export default function chartsReducer(
  state = defaultChartsState, action: ActionProps,
) {
  switch (action.type) {
    case CHART_DEFAULT_PARAMS: {
      const { groupBy, searchParams, activeReportPeriod } = action;
      const oldData = state[COMMON];
      const newState = {
        ...state,
        [COMMON]: {
          ...oldData, groupBy, searchParams, activeReportPeriod,
        },
      };
      return newState;
    }
    case CHART_REQUEST: {
      const { chartKey } = action;
      const oldData = state[chartKey];
      const newState = {
        ...state,
        [chartKey]: {
          ...oldData,
          loading: true,
          isFetching: true,
          isError: false,
          errors: {},
          hasWarnings: false,
          warnings: {},
        },
      };
      return newState;
    }
    case CHART_RESPONSE: {
      const { chartKey, response } = action;
      const oldData = state[chartKey];
      const { data, meta, totals } = response;
      const newState = {
        ...state,
        [chartKey]: {
          ...oldData,
          loading: false,
          data,
          meta,
          totals,
          isFetching: false,
          isError: false,
          errors: {},
          hasWarnings: false,
          warnings: {},
        },
      };
      return newState;
    }
    default:
      return state;
  }
}
