import {
  call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { RESET_MODULE_ERRORS } from '@constants/common_constants';
import { fetchData } from '@containers/common_actions/common_actions_screen_helpers';

function* commonScreenWatcherSagaSideEffect(action: any) {
  const {
    api, filterValues, pageParams, redirectUrl,
  } = action;
  try {
    const response = yield call(fetchData({
      api, filterValues, pageParams, redirectUrl,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

interface ResetModuleErrorsInterface {
  resetModuleErrorsKey: string;
  resetSubModuleErrorsKey: string;
  type: string;
  resolve?: any;
  reject?: any;
}

function* resetModuleErrorsWatcher(action: ResetModuleErrorsInterface) {
  const { resetModuleErrorsKey: moduleName, resetSubModuleErrorsKey: subModuleName } = action;
  yield put({
    type: `RESET_${moduleName}_ERRORS`,
    moduleName,
    subModuleName: (subModuleName || ''),
  });
}

export function* commonScreenWatcher() {
  yield takeLatest('COMMON_BULK_OPTIONS', commonScreenWatcherSagaSideEffect);
  yield takeEvery(RESET_MODULE_ERRORS, resetModuleErrorsWatcher);
}
