import { HOME_BREADCRUMB_LISTING } from '../common';

export const JOURNAL_ENTRY = 'JOURNAL_ENTRY';
export const SET_JOURNAL_ENTRIES = 'SET_JOURNAL_ENTRIES';
export const ADD_JOURNAL_ENTRY = 'ADD_JOURNAL_ENTRY';
export const JOURNAL_ENTRY_FETCHED = 'JOURNAL_ENTRY_FETCHED';
export const JOURNAL_ENTRY_UPDATED = 'JOURNAL_ENTRY_UPDATED';
export const JOURNAL_ENTRY_DELETED = 'JOURNAL_ENTRY_DELETED';
export const RESET_JOURNAL_ENTRY_ERRORS = 'RESET_JOURNAL_ENTRY_ERRORS';
export const SET_CREDIT_ACCOUNTS_ID_OR_DEBIT_ACCOUNTS_ID_IN_SELECTED_OPTIONS = 'SET_CREDIT_ACCOUNTS_ID_OR_DEBIT_ACCOUNTS_ID_IN_SELECTED_OPTIONS';

export const FORM_INFORMATION_TITLE = 'ENTRY INFORMATION';

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  commercial_document_type_in: [],
  type_in: [],
  credit_accounts_id_or_debit_accounts_id_in: [],
};

// WHAT   this config is for advSearch components like dropdown ...
export const otherAdvSearchConfig = {
  credit_accounts_id_or_debit_accounts_id_in_selected_options: [],
};

export const defaultSearchParams = {
  ref_no_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['chart_of_accounts'];

export const VOUCHER_TYPE = [{ label: 'Invoice', value: 'Invoice' }, { label: 'Payment', value: 'Payment' },
  { label: 'CreditNote', value: 'CreditNote' }, { label: 'PurchaseInvoice', value: 'PurchaseInvoice' }];

export const ACCOUNT_ENTRY_TYPE = [{ label: 'Opening Balance Entry', value: 'Plutus::OpeningBalanceEntry' },
  { label: 'Journal Entry', value: 'Plutus::JournalEntry' }];

export const title = 'Journal Entries';
export const singularTitle = 'Journal Entry';
export const moduleName = 'journal_entries';
export const baseUrl = '/accounting/entries';
export const redirectUrl = '/accounting/entries';
export const listingDataKey = 'entries';
export const itemDataKey = 'entry';
export const permissionKey = 'plutus/entries';

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'ref_no', 'description'],
  debit_amounts: ['account_name', 'amount'],
  credit_amounts: ['account_name', 'amount'],
};
