import { isEmpty, omit } from 'lodash';
import { REDUCER_COMMON_INITIAL_STATES } from '../../constants/common';
import {
  SET_RECON_ENTRIES, ADD_RECON_ENTRY, RECON_ENTRY_FETCHED, LOAD_MORE_RECON_ENTRIES,
  RECON_ENTRY_UPDATED, RECON_ENTRY_DELETED, defaultSearchParams, RESET_RECON_ENTRIES,
  SET_RECON_ENTRIES_SEARCH_PARAMS, SET_INF_RECON_ENTRIES,
} from '../../constants/accounting/recon_entries';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateObject,
} from '../common';

export const reconEntriesInitialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'entry_date',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  per_page: 100,
  links: {},
  isActive: false,
};

export function addMoreReconEntries(state, action) {
  let updated_action_state = { ...action };
  updated_action_state = omit(updated_action_state, 'type');
  if (action.search_params) {
    updated_action_state.search_params = { ...state.search_params, ...action.search_params };
  }
  if (!isEmpty(action.items)) {
    const { total_count } = action;
    const recon_entries_items = [...state.items, ...action.items];
    const recon_entries_items_left = total_count - recon_entries_items.length;
    updated_action_state.items = recon_entries_items;
    updated_action_state.recon_entries_items_left = recon_entries_items_left;
  }

  return updateObject(state, updated_action_state);
}

export function setInfItems(state, action) {
  const {
    total_count, per_page, items, page,
  } = action;
  if (state.isActive) {
    const updated_action_state = { ...(omit(action, ['type', 'items'])) };

    if (action.items) {
      const isFirst = page === 1;
      const tableItems = isFirst ? [] : [...state.items];
      if (action.page === 1) {
        for (let i = 0; i < total_count; i += 1) {
          tableItems.push(undefined);
        }
      }
      tableItems.splice((page - 1) * per_page, items.length, ...items);
      updated_action_state.items = tableItems;
    }

    return updateObject(state, updated_action_state);
  }
  return state;
}

export default function reconEntries(state = reconEntriesInitialState, action) {
  switch (action.type) {
    case SET_RECON_ENTRIES: return setItems(state, action);
    case SET_INF_RECON_ENTRIES: return setInfItems(state, action);
    case RESET_RECON_ENTRIES: return reconEntriesInitialState;
    case SET_RECON_ENTRIES_SEARCH_PARAMS: return setItems(state, action);
    case LOAD_MORE_RECON_ENTRIES: return addMoreReconEntries(state, action);
    case ADD_RECON_ENTRY: return addItem(state, action);
    case RECON_ENTRY_FETCHED: return fetchItem(state, action);
    case RECON_ENTRY_UPDATED: return updateItem(state, action);
    case RECON_ENTRY_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
