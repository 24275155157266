import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { INTEGRATION } from '@constants/integrations';

export function fetchAllIntegrations({ url }) {
  return () => axios({
    url,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', INTEGRATION);
    });
}

export function fetchLastSuccessfulSyncsIntegration({ integrationId }) {
  return () => axios({
    url: `/integrations/${integrationId}/syncs/last_successful`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', INTEGRATION);
    });
}

export function fetchLastSyncsIntegration({ integrationId }) {
  return () => axios({
    url: `/integrations/${integrationId}/syncs/last`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', INTEGRATION);
    });
}

export function updateSyncsIntegration({ integrationId, integrationSyncId, state }) {
  return () => axios({
    url: `/integrations/${integrationId}/syncs/${integrationSyncId}`,
    method: 'PUT',
    data: {
      integration_sync: { state },
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', INTEGRATION);
    });
}
