import { getActionTypes } from './action_helpers';

export const SET_ITEMS = 'setItems';
export const ADD_ITEM = 'addItem';
export const SET_ITEM = 'setItem';
export const UPDATE_ITEM = 'updateItem';
export const DELETE_ITEM = 'deleteItem';
export const AUTOCOMPLETE_ITEMS = 'autocompleteItems';
export const RESET_ERRORS = 'resetErrors';
export const SET_NEW_ITEM = 'setNewItem';
export const CHANGE_ITEM_STATUS = 'changeItemStatus';
export const AUTOCOMPLETE_UNIONS = 'autocompleteUnions';

type SetItemsPropTypes = {
  moduleName: string;
  responseData: any;
}

// Action to set items.
export const setItems = (args: SetItemsPropTypes) => {
  const { moduleName, responseData } = args;
  const type = getActionTypes({ moduleName, actionTypes: SET_ITEMS });
  return { type, ...responseData };
};

type AddItemPropTypes = {
  moduleName: string;
  item: any;
}

// Action to add new item.
export const addItem = (args: AddItemPropTypes) => {
  const { moduleName, item } = args;
  const type = getActionTypes({ moduleName, actionTypes: ADD_ITEM });
  return { type, item };
};

type SetItemPropTypes = {
  moduleName: string;
  item: any;
}

// Action to set item.
export const setItem = (args: SetItemPropTypes) => {
  const { moduleName, item } = args;
  const type = getActionTypes({ moduleName, actionTypes: SET_ITEM });
  return { type, item };
};

type UpdateItemPropTypes = {
  moduleName: string;
  item: any;
}

// Action to update item.
export const updateItem = (args: UpdateItemPropTypes) => {
  const { moduleName, item } = args;
  const type = getActionTypes({ moduleName, actionTypes: UPDATE_ITEM });
  return { type, item };
};

type DeleteItemPropTypes = {
  moduleName: string;
  id: string | number;
}

// Action to delete item.
export const deleteItem = (args: DeleteItemPropTypes) => {
  const { moduleName, id } = args;
  const type = getActionTypes({ moduleName, actionTypes: DELETE_ITEM });
  return { type, id };
};

type AutocompletePropTypes = {
  moduleName: string;
  items: any[];
}

// Action to autocomplete items.
export const autocompleteItems = (args: AutocompletePropTypes) => {
  const { moduleName, items } = args;
  const type = getActionTypes({ moduleName, actionTypes: AUTOCOMPLETE_ITEMS });
  return { type, items };
};

type AutocompleteUnionsPropTypes = {
  moduleName: string;
  items: any[];
}

// Action to autocomplete items.
export const autocompleteUnions = (args: AutocompleteUnionsPropTypes) => {
  const { moduleName, items } = args;
  const type = getActionTypes({ moduleName, actionTypes: AUTOCOMPLETE_UNIONS });
  return { type, items };
};

type ResetErrorPropTypes = {
  moduleName: string;
  params: any;
}

// Action to resetErrors.
export const resetErrors = (args: ResetErrorPropTypes) => {
  const { moduleName, params } = args;
  const type = getActionTypes({ moduleName, actionTypes: RESET_ERRORS });
  return { type, ...params };
};

type SetParamsPropTypes = {
  type: string;
  params: any;
}

// Action to setParams.
export const setParams = (args: SetParamsPropTypes) => {
  const { type, params } = args;
  return { type, ...params };
};

type SetNewItemPropTypes = {
  moduleName: string;
  params: any;
}

// Action to setNewItem.
export const setNewItem = (args: SetNewItemPropTypes) => {
  const { moduleName, params } = args;
  const type = getActionTypes({ moduleName, actionTypes: SET_NEW_ITEM });
  return { type, ...params };
};

type changeStatusPropTypes = {
  moduleName: string;
  params: any;
}

// Action to changeItemStatus.
export const changeBulkStatus = (args: changeStatusPropTypes) => {
  const { moduleName, params } = args;
  const type = getActionTypes({ moduleName, actionTypes: CHANGE_ITEM_STATUS });
  return { type, ...params };
};

type autocompleteItemPropTypes = {
  type: string;
  payload: any;
}

// Action for autocomplete.
export const sagaPreWatcherAction = (
  args: autocompleteItemPropTypes,
  resolve?: any, reject?: any,
) => {
  const { type, payload } = args;
  return {
    type, ...payload, resolve, reject,
  };
};
