import {
  SET_TAX_RATES, ADD_TAX_RATE, TAX_RATE_FETCHED, TAX_RATE_UPDATED, TAX_RATE_DELETED,
  ADD_NEW_TAX_RATES,
} from '@constants/tax_rates';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  items: [],
  current_page: 1,
  tax_rates_count: 0,
  sort_by: 'id',
  sort_order: 'asc',
};

export default function taxRates(state = initialState, action) {
  switch (action.type) {
    case SET_TAX_RATES: return setItems(state, action);
    case ADD_TAX_RATE: return addItem(state, action);
    case TAX_RATE_FETCHED: return fetchItem(state, action);
    case TAX_RATE_UPDATED: return updateItem(state, action);
    case TAX_RATE_DELETED: return deleteItem(state, action);
    case ADD_NEW_TAX_RATES: {
      const newTaxRates = action?.tax_rates || [];
      const updatedItems = [...state.items, ...newTaxRates];
      return {
        ...state,
        items: updatedItems,
      };
    }
    default:
      return state;
  }
}
