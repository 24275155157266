import * as types from '../constants/customers';

// Create CUSTOMER_FETCHED action.
export function customerEdit(customer) {
  return { type: types.CUSTOMER_EDIT, item: customer };
}

// Create CUSTOMER_DELETED action
export function autocompleteCustomersWithUnionById(customers) {
  return { type: types.AUTOCOMPLETE_CUSTOMER_WITH_UNION_BY_ID, items: customers };
}

export function deleteCustomerById(customer_id) {
  return { type: types.DELETE_CUSTOMER_BY_ID, customer_id };
}

export function setWarningBeforeMergeingCustomer(id, warning) {
  return { type: types.SET_WARNING_BEFORE_MERGEING_CUSTOMER, id, warning };
}
