import { getResourceTypeForCustomFields as getResourceType } from '@component/common/advance_search/helpers';
import store from '../store/store';
// Constants
import { moduleName as users } from '../constants/users';
import { moduleName as brands } from '../constants/brands';
import { moduleName as branches } from '../constants/branches';
import { moduleName as beatRoutes } from '../constants/beat_routes';
import { moduleName as productCategories } from '../constants/product_categories';
import { moduleName as warehouses } from '../constants/warehouses';
import { moduleName as creditReasons } from '../constants/credit_reasons';
import { moduleName as debitReasons } from '../constants/debit_reasons';
import { moduleName as customerRatings } from '../constants/customer_ratings';
import { moduleName as customerCategories } from '../constants/customer_categories';
import { moduleName as taxCategories } from '../constants/tax_categories';
import { moduleName as suppliers } from '../constants/suppliers';
import { moduleName as banks } from '../constants/banks';
import { moduleName as customers } from '../constants/customers';
import { moduleName as products, AUTOCOMPLETE_BASIC_INFO_PRODUCTS, FETCH_SELLABLE_PRODUCTS } from '../constants/products';
import { moduleName as chartOfAccounts, AUTOCOMPLETE_BASIC_INFO_CHART_OF_ACCOUNTS } from '../constants/accounting/accounts';
import { moduleName as reconEntries } from '../constants/accounting/recon_entries';
import { moduleName as bankAccounts } from '../constants/bank_accounts';
import { moduleName as purchaseOrderTerms } from '../constants/purchase_order_terms';
import { moduleName as voucherBooks } from '../constants/voucher_books';

// Actions
import { fetchUsersBasicInfo } from '../saga/users';
import { fetchBranchesBasicInfo } from '../saga/branches';
import { fetchBrandsBasicInfo } from '../saga/brands';
import { fetchBeatRoutesBasicInfo } from '../saga/beat_routes';
import { fetchProductCategoriesBasicInfo } from '../saga/product_categories';
import { fetchWarehousesBasicInfo } from '../saga/warehouses';
import { autocompleteCustomerWithUnionById } from '../saga/customers';
import { getPaymentModes, fetchAllStates, getLetterOfCreditTypes } from '../saga/settings';
import { fetchCreditReasonsBasicInfo } from '../saga/credit_reasons';
import { fetchDebitReasonsBasicInfo } from '../saga/debit_reasons';
import { fetchCustomerRatingsBasicInfo } from '../saga/customer_ratings';
import { fetchCustomerCategoriesBasicInfo } from '../saga/customer_categories';
import { fetchTaxCategoriesBasicInfo } from '../saga/tax_categories';
import { fetchSuppliersBasicInfo } from '../saga/suppliers';
import { fetchBanksBasicInfo } from '../saga/banks';
import { fetchVoucherBooksBasicInfo } from '../saga/voucher_books';
import { fetchBankAccountsBasicInfo } from '../saga/bank_accounts';
import { sagaPreWatcherAction } from '../actions/common';
import { fetchPurchaseOrderTermsBasicInfo } from '../actions/purchase_order_terms';

// Helpers
import { getClientSetting } from './settings_helper';
import { PRODUCTS_FILTER, CONDITION_PRODUCTS_FILTER, OFFER_PRODUCTS_FILTER } from '../constants/offers';

// Extra Required Constants
const paymentModes = 'payment_modes';
const letterOfCreditTypes = 'letter_of_credit_types';
export const stateCodes = 'state_codes';

export const fetchBasicInfoRequest = (moduleNames) => {
  const storeData = store?.getState();
  const { root_reducer: rootReducer } = storeData || {};
  const { activeModule } = rootReducer || {};
  const resourceType = getResourceType(activeModule);
  moduleNames
    .forEach((moduleName) => {
      switch (moduleName) {
        case users:
          fetchUsersBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case brands:
          fetchBrandsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case branches:
          fetchBranchesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case beatRoutes:
          fetchBeatRoutesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case productCategories:
          fetchProductCategoriesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case warehouses:
          fetchWarehousesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case paymentModes:
          getPaymentModes()(store.dispatch);
          break;
        case letterOfCreditTypes:
          getLetterOfCreditTypes()(store.dispatch);
          break;
        case creditReasons:
          fetchCreditReasonsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case debitReasons:
          fetchDebitReasonsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case stateCodes:
          fetchAllStates(getClientSetting('base_country'))(store.dispatch);
          break;
        case customerRatings:
          fetchCustomerRatingsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case customerCategories:
          fetchCustomerCategoriesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case taxCategories:
          fetchTaxCategoriesBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case suppliers:
          fetchSuppliersBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case bankAccounts:
          fetchBankAccountsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case banks:
          fetchBanksBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case purchaseOrderTerms:
          fetchPurchaseOrderTermsBasicInfo({ name_cont: '' })(store.dispatch);
          break;
        case voucherBooks:
          fetchVoucherBooksBasicInfo({ name_cont: '', resource_type_eq: resourceType })(store.dispatch);
          break;
        default:
          break;
      }
    });
};

export const fetchAutoCompleteRequest = (moduleName, input, page = 1, searchParams = {}) => {
  switch (moduleName) {
    case customers:
      return autocompleteCustomerWithUnionById({ name_cont: input })(store.dispatch);
    case reconEntries:
      return autocompleteCustomerWithUnionById({ counterparty_id_in: input })(store.dispatch);

    // SETUP-SECTION advanced search (in case if you need autocomplete in adv search)
    case chartOfAccounts: {
      const search_params = { ...searchParams, name_cont: input };
      return new Promise((resolve, reject) => {
        store.dispatch(sagaPreWatcherAction({ type: AUTOCOMPLETE_BASIC_INFO_CHART_OF_ACCOUNTS, payload: { search_params, page } }, resolve, reject));
      }).then((response) => response).catch((error) => error);
    }
    // !SETUP-SECTION advanced search (in case if you need autocomplete in adv search)

    case products: {
      const search_params = { ...searchParams, sku_or_barcode_or_name_or_variants_name_cont: input };
      return new Promise((resolve, reject) => {
        store.dispatch(sagaPreWatcherAction({ type: AUTOCOMPLETE_BASIC_INFO_PRODUCTS, payload: { search_params } }, resolve, reject));
      }).then((response) => response).catch((error) => error);
    }
    case CONDITION_PRODUCTS_FILTER:
    case PRODUCTS_FILTER:
    case OFFER_PRODUCTS_FILTER: {
      const search_params = { ...searchParams, sku_or_barcode_or_name_or_variants_name_cont: input };
      return new Promise((resolve, reject) => {
        store.dispatch(sagaPreWatcherAction({ type: FETCH_SELLABLE_PRODUCTS, payload: { search_params } }, resolve, reject));
      }).then((response) => response).catch((error) => error);
    }
    default:
      break;
  }
};
