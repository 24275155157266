import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { SET_DOWNLOADS, DOWNLOADED_FILE_DELETED } from '../constants/downloads';
import { setItems, deleteItem } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'id',
  sort_order: 'asc',
  per_page: PER_PAGE,
  search_params: {},
};

export default function downloads(state = initialState, action) {
  switch (action.type) {
    case SET_DOWNLOADS: return setItems(state, action);
    case DOWNLOADED_FILE_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
