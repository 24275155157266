import axios from '@utils/axios';
import { moduleName } from '@constants/users_groups';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@utils/SerializeParams';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';

export function fetchAllUserGroupsWithRoles(args) {
  const { redirectUrl, resource_types: resourceTypes } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const { search_params: searchParams } = requestParams;

  let q = {};
  if (searchParams) {
    q = serializeSearchParams(searchParams);
  }
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}/user_groups_with_roles?${q}`,
    method: 'get',
    params: { resource_types: resourceTypes || [] },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
