import { serializePageParams, serializeSearchParams } from '@utils/SerializeParams';
import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';
import setIndexRequestParams from '@utils/HandleIndexActionParams';

export function fetchSmsStatistics(args: any) {
  const requestParams = setIndexRequestParams(args);
  const { search_params, per_page, page } = requestParams;
  const pageParams = serializePageParams(page, per_page);
  const q = serializeSearchParams(search_params);

  return () => axios({
    url: `/sms?${pageParams}&${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/sms_statistics', {});
      throw error.response;
    });
}
