import { HOME_BREADCRUMB_LISTING } from '../common';

export const RECONCILIATION = 'RECONCILIATION';
export const SET_RECONCILIATIONS = 'SET_RECONCILIATIONS';
export const RESET_RECONCILIATIONS = 'RESET_RECONCILIATIONS';
export const ADD_RECONCILIATION = 'ADD_RECONCILIATION';
export const RECONCILIATION_FETCHED = 'RECONCILIATION_FETCHED';
export const RECONCILIATION_UPDATED = 'RECONCILIATION_UPDATED';
export const RECONCILIATION_DELETED = 'RECONCILIATION_DELETED';

export const FORM_INFORMATION_TITLE = 'BASIC DETAILS';

export const advanceSearchParams = {
  account_name_cont: '',
  account_id_eq: '',
  start_date_gteq: '',
  end_date_lteq: '',
  state_in: [],
};

export const OPEN_SUB_ACCOUNT_FORM_MODAL = 'OPEN_SUB_ACCOUNT_FORM_MODAL';

// Reconciliation states
export const done = 'done';
export const in_progress = 'in_progress';
export const cancel = 'cancel';
export const confirm = 'confirm';
export const cancelled = 'cancelled';

export const stateBtnsConfig = {
  done: { title: 'Done', btnStyle: 'success' },
  in_progress: { title: 'In Progress', btnStyle: 'warning' },
};

export const defaultSearchParams = {
  ref_no_cont: '',
  ...advanceSearchParams,
};

// export const requiredPrefetchingModuleNames = ['chart_of_accounts'];
export const requiredPrefetchingModuleNames = [];

export const local_storage_listing_settings_key = 'reconciliations_listing_settings';

export const VOUCHER_TYPE = [{ label: 'Invoice', value: 'Invoice' }, { label: 'Payment', value: 'Payment' },
  { label: 'CreditNote', value: 'CreditNote' }, { label: 'PurchaseInvoice', value: 'PurchaseInvoice' }];

export const ACCOUNT_ENTRY_TYPE = [{ label: 'Opening Balance Entry', value: 'Plutus::OpeningBalanceEntry' },
  { label: 'Reconciliation', value: 'Plutus::JournalEntry' }];

export const RECONCILIATION_STATES = [{ label: 'In Progress', value: in_progress }, { label: 'Done', value: done }];

export const title = 'Reconciliations';
export const singularTitle = 'Reconciliation';
export const moduleName = 'reconciliations';
export const baseUrl = '/accounting/reconciliations';
export const redirectUrl = '/accounting/reconciliations';
export const listingDataKey = 'reconciliations';
export const itemDataKey = 'reconciliation';
export const permissionKey = moduleName;

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: [],
};
