import { all, fork } from 'redux-saga/effects';
import { productWatcher } from '@saga/products';
import { customerWatcher } from '@saga/customers';
import { chartOfAccountsWatcher } from '@saga/accounting/accounts';
import { debitNotesWatcher } from '@saga/debit_notes';
import { voucherBookWatcher } from '@saga/voucher_books';
import { orderWatcher } from '@saga/orders';
import { invoiceWatcher } from '@saga/invoices';
import { paymentWatcher } from '@saga/payments';
import { returnWatcher } from '@saga/returns';
import { purchaseOrderWatcher } from '@saga/purchase_orders';
import { purchaseReturnsWatcher } from '@saga/purchase_returns';
import { purchaseInvoicesWatcher } from '@saga/purchase_invoices';
import { userWatcher } from '@saga/users';
import { commonScreenWatcher } from '@saga/common';
import { credentialsWatcher } from '@saga/credentials';
import { getRecentsWatchers } from '@saga/recents';
import { printFormatsWatcher } from '@saga/print_formats';
import { statisticsWatcher } from '@saga/statistics';
import { smsStatisticsWatcher } from '@saga/sms_statistics';
import { utilitiesWatcher } from '@saga/utilities';
import { addressesWatcher } from '@saga/addresses';
import { purchaseOrderTermWatcher } from '@saga/purchase_order_terms';
import { eWayBillWatcher } from '@component/common/e_way_bill/saga';
import { inventoriesWatcher } from '@saga/inventories';
import { eInvoiceWatcher } from '@saga/e_invoice';
import { creditNotesWatcher } from '@saga/credit_notes';
import { inventoryPieceTrackingWatcher } from '@component/common/inventory_tracking/inventory_piece_tracking/saga';
import { settingsWatcher } from '@saga/settings';
import { productHistoricalPricingWatcher } from '@component/common/product_historical_pricing/saga';
import { integrationsWatcher } from '@saga/integrations';
import { bulkUpdateWatcher } from '@component/common/bulk_update/saga';
import { resetAccountPassword } from '@component/auth/reset_account_password/saga';
import { updateAccountProfile } from '@component/auth/update_account_profile/saga';
import { signUpAccount } from '@component/auth/sign_up/saga';
import { accountConfirmation } from '@component/auth/account_confirmation/saga';
import { newOrganisation } from '@component/organisations/saga';
import { contentsWatcher } from '@component/contents/saga';
import { usersGroupWatcher } from '@saga/users_groups';
import { subscriptionWatcher } from '@component/subscription/saga';

export const rootSaga = function* root() {
  yield all([
    fork(productWatcher),
    fork(customerWatcher),
    fork(chartOfAccountsWatcher),
    fork(voucherBookWatcher),
    fork(orderWatcher),
    fork(invoiceWatcher),
    fork(paymentWatcher),
    fork(returnWatcher),
    fork(purchaseOrderWatcher),
    fork(purchaseReturnsWatcher),
    fork(purchaseInvoicesWatcher),
    fork(userWatcher),
    fork(commonScreenWatcher),
    fork(credentialsWatcher),
    fork(printFormatsWatcher),
    fork(statisticsWatcher),
    fork(smsStatisticsWatcher),
    fork(utilitiesWatcher),
    fork(addressesWatcher),
    fork(purchaseOrderTermWatcher),
    fork(eWayBillWatcher),
    fork(inventoriesWatcher),
    fork(eInvoiceWatcher),
    fork(creditNotesWatcher),
    fork(inventoryPieceTrackingWatcher),
    fork(debitNotesWatcher),
    fork(settingsWatcher),
    fork(productHistoricalPricingWatcher),
    fork(integrationsWatcher),
    fork(bulkUpdateWatcher),
    fork(resetAccountPassword),
    fork(updateAccountProfile),
    fork(signUpAccount),
    fork(accountConfirmation),
    fork(newOrganisation),
    fork(contentsWatcher),
    fork(usersGroupWatcher),
    fork(subscriptionWatcher),
    ...(getRecentsWatchers().map((watcher) => (fork(watcher)))),
  ]);
};
