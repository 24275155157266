import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { INVALID_INPUT_ERROR_HEADER_TEXT } from '@constants/common_constants';
import DMSFlashMessage from '../DMSFlashMessage';

const idFromTitle = (title: string) => (title
  ? (title.split(' ').map((str) => str.toLowerCase())).join('-')
  : '');

interface FormSectionTitleProps {
  displayTitle: string;
  displayEl?(): ReactNode;
  descriptionEl?(): ReactNode;
  view: string;
  unhandledErrors?: any;
  isNestedFormFlashMessage?: boolean;
  style?: CSSProperties;
  className?: string;
  isInformationTitle?: boolean;
  informationEl?(): ReactNode;
  id?: string;
}

class FormSectionTitle extends PureComponent<FormSectionTitleProps> {
  render() {
    const {
      displayTitle, style = {}, className = '', id = '',
      isNestedFormFlashMessage, unhandledErrors,
      isInformationTitle, informationEl,
      descriptionEl, displayEl,
    } = this.props;

    const headerTitleDefaultStyle: CSSProperties = {
      width: isInformationTitle ? 'calc(100% - 130px)' : '100%',
      display: 'inline-block',
    };

    return (
      <div
        className={classnames('header col-md-12', { [className]: !!className })}
        style={style}
        id={id || idFromTitle(displayTitle)}
      >
        <div className="card-header">
          <div className="card-header-title">
            <h4 className="text-muted mx-6px">
              <div
                className="information-title"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
              >

                <span
                  role="presentation"
                  style={headerTitleDefaultStyle}
                >
                  <div>{displayEl ? displayEl() : displayTitle}</div>
                  <div>{descriptionEl && descriptionEl()}</div>
                </span>

                {isInformationTitle && (
                  <span style={{ display: 'inline-block' }}>
                    {informationEl()}
                  </span>
                )}

                <div />
              </div>
            </h4>

            {(unhandledErrors && (Object.keys(unhandledErrors).length > 0))
              ? (
                <DMSFlashMessage
                  unhandledErrors={unhandledErrors || {}}
                  isNestedFormFlashMessage={!!(isNestedFormFlashMessage)}
                  flashMessageTitle={INVALID_INPUT_ERROR_HEADER_TEXT}
                />
              ) : null}

          </div>
          <hr className="mx-6px" />
        </div>
      </div>
    );
  }
}

export default FormSectionTitle;
