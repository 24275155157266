import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for accounts index page.
const Accounts = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingAccountsIndex" */ '../containers/accounting/accounts/Index'),
  loading: Loading,
});

// We use loadable for account new and edit page.
const AccountCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingAccountsCredate" */ '../containers/accounting/accounts/Credate'),
  loading: Loading,
});

// We use loadable for account show page.
const AccountShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingAccountsShow" */ '../containers/accounting/accounts/Show'),
  loading: Loading,
});


// We use loadable for entries index page.
const Entries = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingEntriesIndex" */ '../containers/accounting/entries/Index'),
  loading: Loading,
});

// We use loadable for entry new and edit page.
const EntryCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingEntriesCredate" */ '../containers/accounting/entries/Credate'),
  loading: Loading,
});

// We use loadable for bank recon new and edit page.
const ReconCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingReconCredate" */ '../containers/accounting/reconciliations/Credate'),
  loading: Loading,
});

// We use loadable for bank recon index
const ReconIndex = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingReconCredate" */ '../containers/accounting/reconciliations/Index'),
  loading: Loading,
});

// We use loadable for entry show page.
const EntryShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingEntriesShow" */ '../containers/accounting/entries/Show'),
  loading: Loading,
});

// We use loadable for entry show page.
const ReconShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAccountingReconShow" */ '../containers/accounting/reconciliations/Show'),
  loading: Loading,
});

const Accounting = [
  {
    path: '/accounting/accounts/new', name: 'New Account', component: AccountCredate,
  },
  {
    path: '/accounting/accounts/:id/edit', name: 'Edit Account', component: AccountCredate,
  },
  {
    path: '/accounting/accounts', name: 'Accounts', component: Accounts,
  },

  {
    path: '/accounting/accounts/:id', name: 'Show Account', component: AccountShow,
  },

  {
    path: '/accounting/entries/new', name: 'New Entry', component: EntryCredate,
  },
  {
    path: '/accounting/entries/:id/edit', name: 'Edit Entry', component: EntryCredate,
  },
  {
    path: '/accounting/entries', name: 'Entries', component: Entries,
  },
  {
    path: '/accounting/entries/:id', name: 'Show Entry', component: EntryShow,
  },


  {
    path: '/accounting/reconciliations/new', name: 'New Reconciliation', component: ReconCredate,
  },
  {
    path: '/accounting/reconciliations/:id/edit', name: 'New Reconciliation', component: ReconCredate,
  },
  {
    path: '/accounting/reconciliations', name: 'Reconciliations', component: ReconIndex,
  },
  {
    path: '/accounting/reconciliations/:id', name: 'Show Reconciliation', component: ReconShow,
  },
];

export default Accounting;
