import React, { Component } from 'react';
import { map, filter } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOADER_BTN_NAME } from '@constants/common';
import { ENTER } from '@constants/event_keys';

interface Props {
  offers?: any;
  customer_id: string | number;
  offerIds?: any[];
  handleEligibleOfferChange(offer_ids: any[], key: string, errors: any): any;
  errors?: any;
}

class EligibleOffers extends Component<Props> {
  static defaultProps: {
    offers: {};
    offerIds: [];
    errors: {};
  };

  handleChange = (isChecked, offerId, offerErrors) => {
    const { handleEligibleOfferChange, offerIds } = this.props;
    let updatedOfferIds = offerIds;

    if (isChecked) {
      updatedOfferIds.push(offerId);
    } else {
      updatedOfferIds = filter(updatedOfferIds, (id) => (id !== offerId));
    }
    handleEligibleOfferChange(updatedOfferIds, 'offer_ids', offerErrors);
  }

  render() {
    const { offers, offerIds, errors } = this.props;

    if (offers?.isFetching) {
      return (
        <div className="col-md-12">
          <i className="fa fa-spinner fa-spin" />
          &nbsp;
          {LOADER_BTN_NAME}
        </div>
      );
    }

    return (
      <>
        {
          (offers?.items?.length > 0)
            ? (
              <table className="table offers eligible-offer-table">
                <tbody>
                  {
                    map(offers?.items, (offer) => {
                      const isChecked = offerIds?.includes(offer.id);
                      return (
                        <tr key={`offer_${offer?.id || ''}`}>
                          <td className="col-md-half eligible-offer-checkbox-td">
                            <div
                              className="check-hover"
                              onClick={(event: any) => {
                                if (event.key !== ENTER) {
                                  this.handleChange(!isChecked, offer?.id || '', [`offers[${offer?.id || ''}]`]);
                                }
                              }}
                              onKeyDown={(event) => {
                                if (event.key === ENTER) {
                                  event.preventDefault();
                                  this.handleChange(!isChecked, offer?.id || '', [`offers[${offer?.id || ''}]`]);
                                }
                              }}
                              role="presentation"
                              tabIndex={0}
                            >
                              <input
                                type="checkbox"
                                id={`master-checkbox-${offer?.id || ''}`}
                                checked={isChecked}
                                tabIndex={-1}
                              />
                            </div>
                          </td>
                          <td className="col-md-11-half eligible-offer-label-td">
                            <span className="text-muted discription">{offer?.name || ''}</span>
                            <br />
                            <span><small>{offer.description}</small></span>
                            <span className="errors-message">{(errors?.[`offers[${offer.id}]`]) || ''}</span>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            )
            : <div className="col-md-12">No Offers Found.</div>
        }
      </>
    );
  }
}

EligibleOffers.defaultProps = {
  offers: {},
  offerIds: [],
  errors: {},
};

function mapStateToProps(state) {
  const { eligible_offers: eligibleOffers } = state;

  return { offers: eligibleOffers };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibleOffers);
