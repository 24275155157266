import * as types from '../constants/invoices';

export function clearErrors(key) {
  return { type: types.INVOICE_CLEAR_ERROR, key };
}

export function resetInvoiceErrors() {
  return { type: types.RESET_INVOICE_ERRORS };
}

export function setOrderInvoice(invoices) {
  return {
    type: types.SET_ORDER_INVOICE,
    items: invoices,
  };
}
