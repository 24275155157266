import React from 'react';
import { FormGroup } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  string, bool, oneOfType, node, arrayOf,
} from 'prop-types';

const FormFixedFooter = ({ children, isSidenavMinimize, elsContainerClassname }) => {
  const formGroupClassName = (isSidenavMinimize) ? 'fixed-action-footer-for-min-sidebar' : 'fixed-action-footer';
  return (
    <FormGroup className={formGroupClassName} id="fixed-action-footer">
      <div className="col-md-12 text-right">
        <div className={classnames('form-footer-btn', { [elsContainerClassname]: !!elsContainerClassname })}>
          { children }
        </div>
      </div>
    </FormGroup>
  );
};

FormFixedFooter.propTypes = {
  isSidenavMinimize: bool,
  children: oneOfType([string, node, arrayOf(node)]),
  elsContainerClassname: string,
};

FormFixedFooter.defaultProps = {
  isSidenavMinimize: false,
  children: '',
  elsContainerClassname: '',
};

function mapStateToProps(state) {
  const { root_reducer } = state;
  return {
    isSidenavMinimize: root_reducer.isSidenavMinimize,
  };
}

export default connect(mapStateToProps, null)(FormFixedFooter);
