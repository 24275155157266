import axios from '../utils/axios';
import { ADD_CUSTOMER_RATING, moduleName } from '../constants/customer_ratings';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { updateAndCloseQuickAddModule } from '../components/common/quick_add_module/helper';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';

// Get CustomerRatings List (dispatch, getState)
export function fetchCustomerRatings(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { customer_ratings, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: customer_ratings, current_page: page, total_count, sort_by, sort_order, search_params, per_page, selected_variant, dont_merge_search_params, timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save CustomerRating.
export function saveCustomerRating(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      customer_rating: data,
    },
  })
    .then((response) => {
      const { customer_rating } = response.data;
      dispatch(addItem({ moduleName, item: customer_rating }));
      const params = { customer_rating_id: customer_rating.id, customer_rating_name: customer_rating.name };
      updateAndCloseQuickAddModule({ identifier: ADD_CUSTOMER_RATING, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get CustomerRating.
export function fetchCustomerRating(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { customer_rating } = response.data;
      dispatch(setItem({ moduleName, item: customer_rating }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update CustomerRating.
export function updateCustomerRating(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      customer_rating: data,
    },
  })
    .then((response) => {
      const { customer_rating } = response.data;
      dispatch(updateItem({ moduleName, item: customer_rating }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete CustomerRating.
export function deleteCustomerRating(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchCustomerRatingsBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_customer_ratings_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { customer_ratings } = response.data;
      dispatch(autocompleteItems({ moduleName, items: customer_ratings }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
