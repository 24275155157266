import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import FormCancelConfirmationModal from './FormCancelConfirmationModal';

interface Props {
  navigate: Function;
  shouldBlockNavigation: Function;
  when: boolean;
}

interface States {
  modalVisible: boolean;
  lastLocation: any;
  confirmedNavigation: boolean;
}

class RouteLeavingGuard extends Component<Props, States> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    };
  }

  showModal = (location) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  }

  closeModal = (callBack) => {
    this.setState({
      modalVisible: false,
    }, callBack);
  }

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  }

  handleConfirmNavigationClick = () => this.closeModal(() => {
    const { navigate } = this.props;
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true,
      }, () => {
        // Navigate to the previous blocked location with your navigate function
        navigate(lastLocation.pathname);
      });
    }
  })

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;

    return (
      <>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation}
        />
        <FormCancelConfirmationModal
          isOpen={modalVisible}
          closeModal={() => this.closeModal(() => ({}))}
          handleOnClick={this.handleConfirmNavigationClick}
          submitBtnName="Leave page"
          cancelBtnName="Stay on page"
          title="Do you want to leave without saving?"
          content="If you proceed all your unsaved changes will be lost."
        />
      </>
    );
  }
}
export default RouteLeavingGuard;
