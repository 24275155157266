import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const E_INVOICE_CREDENTIAL = 'E_INVOICE_CREDENTIAL';
export const moduleName = 'e_invoice_credentials';
export const FETCH_E_INVOICE_CREDENTIAL = 'FETCH_E_INVOICE_CREDENTIAL';
export const SET_E_INVOICE_CREDENTIAL = 'SET_E_INVOICE_CREDENTIAL';
export const UPDATE_E_INVOICE_CREDENTIAL = 'UPDATE_E_INVOICE_CREDENTIAL';

export const permissionKey = 'in/e_invoices';

export const Title = 'E-Invoice Credential';
export const TargetBaseUrl = 'e_invoice_credentials';
export const E_INVOICE_CREDENTIAL_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: true }],
);
