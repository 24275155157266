import { HOME_BREADCRUMB_LISTING } from './common';

export const APPLY_FILTER = 'APPLY_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FETCHNG_DATA = 'FETCHNG_DATA';

export const BEAT_ROUTE = 'BEAT_ROUTE';
export const SET_BEAT_ROUTES = 'SET_BEAT_ROUTES';
export const ADD_BEAT_ROUTE = 'ADD_BEAT_ROUTE';
export const BEAT_ROUTE_FETCHED = 'BEAT_ROUTE_FETCHED';
export const BEAT_ROUTE_UPDATED = 'BEAT_ROUTE_UPDATED';
export const BEAT_ROUTE_DELETED = 'BEAT_ROUTE_DELETED';
export const AUTOCOMPLETE_BEAT_ROUTE = 'AUTOCOMPLETE_BEAT_ROUTE';

export const RESET_BEAT_ROUTE_ERRORS = 'RESET_BEAT_ROUTE_ERRORS';
export const FORM_INFORMATION_TITLE = 'BEAT ROUTE INFORMATION';
export const ADD_NEW_BEAT_ROUTE = 'Add New Beat Route';


export const title = 'Beat Routes';
export const singularTitle = 'Beat Route';
export const moduleName = 'beat_routes';
export const baseUrl = '/beat_routes';
export const redirectUrl = '/beat_routes';
export const listingDataKey = 'beat_routes';
export const itemDataKey = 'beat_route';

export const beatRouteConstants = {
  item: BEAT_ROUTE,
  setItems: SET_BEAT_ROUTES,
  addItem: ADD_BEAT_ROUTE,
  setItem: BEAT_ROUTE_FETCHED,
  updateItem: BEAT_ROUTE_UPDATED,
  deleteItem: BEAT_ROUTE_DELETED,
  autocompleteItems: AUTOCOMPLETE_BEAT_ROUTE,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const BEAT_ASSINGED_FILTER_VALUES = [
  { name: 'Assigned', id: 'false' }, { name: 'Not Assigned', id: 'true' },
];

export const Title = 'Beat Routes';
export const TargetBaseUrl = 'beat_routes';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'customers_ids'],
  beat_route_assignments: ['date', 'user', 'user_id', 'frequency', 'interval', 'week_days', 'month_dates'],
};
