import { moduleName as voucherBooks } from '@constants/voucher_books';
import { moduleName as orders } from '@constants/orders';
import { moduleName as invoices } from '@constants/invoices';
import { moduleName as payments } from '@constants/payments';
import { moduleName as returns } from '@constants/returns';
import { moduleName as stockTransfers } from '@constants/stock_transfers';
import { moduleName as creditNotes } from '@constants/credit_notes';
import { moduleName as debitNotes } from '@constants/debit_notes';
import { moduleName as customers } from '@constants/customers';
import { moduleName as purchaseOrders } from '@constants/purchase_orders';
import { moduleName as purchaseInvoices } from '@constants/purchase_invoices';
import { moduleName as paymentOuts } from '@constants/payment_outs';
import { moduleName as purchaseReturns } from '@constants/purchase_returns';
import { moduleName as suppliers } from '@constants/suppliers';
import { moduleName as products } from '@constants/products';
import { moduleName as offers } from '@constants/offers';
import { moduleName as reconEntries } from '@constants/accounting/recon_entries';
import { moduleName as downloads } from '@constants/downloads';
import { UPDATED_GTEQ, UPDATED_LTEQ, TALLY } from '@constants/tally_integration';
import { getYearFromNow } from '@helpers/date_picker_helper';
import { apiDefaultDateTimeFormat, fallBackDatetimeFormat } from '@constants/common';
import { USER_TAG } from '@constants/custom_fields';

// eslint-disable-next-line import/prefer-default-export
export const getDateQueryKeys = (moduleName) => {
  switch (moduleName) {
    case orders:
      return ['date_gteq', 'date_lteq'];
    case offers:
      return ['valid_from_gteq', 'valid_till_lteq'];
    case reconEntries:
      return ['entry_date_gteq', 'entry_date_lteq'];

    // SETUP-SECTION advanced search (for time period filters)
    case 'reconciliations':
      return ['start_date_gteq', 'end_date_lteq'];
    // !SETUP-SECTION advanced search (for time period filters)
    case downloads:
      return ['created_at_gteq', 'created_at_lteq'];
    case TALLY:
      return [UPDATED_GTEQ, UPDATED_LTEQ];
    case voucherBooks:
      return ['valid_from_gteq', 'valid_from_lteq'];
    default:
      return ['date_gteq', 'date_lteq'];
  }
};

export const getResourceTypeForCustomFields = (moduleName) => {
  switch (moduleName) {
    case orders:
      return 'Order';
    case invoices:
      return 'Invoice';
    case payments:
      return 'Payment';
    case returns:
      return 'Return';
    case creditNotes:
      return 'CreditNote';
    case debitNotes:
      return 'DebitNote';
    case customers:
      return 'Customer';
    case purchaseOrders:
      return 'PurchaseOrder';
    case purchaseInvoices:
      return 'PurchaseInvoice';
    case paymentOuts:
      return 'PaymentOut';
    case purchaseReturns:
      return 'PurchaseReturn';
    case suppliers:
      return 'Supplier';
    case products:
      return 'Product';
    case stockTransfers:
      return 'StockTransfer';
    default:
      return '';
  }
};

export const modulesSupportingCustomFields = [orders, invoices, payments, returns,
  creditNotes, debitNotes, customers, purchaseOrders, purchaseInvoices, paymentOuts,
  purchaseReturns, suppliers, products];

export const generateSchemaForCustomFields = ({ customFields = [] }) => {
  const customFieldsSchema = [];
  customFields?.map((field) => {
    switch (field.type) {
      case 'Text':
        customFieldsSchema.push({
          key: `custom_fields_data_${field.id}_cont`,
          placeholder: field.name,
          label: field.name,
          query_key: `custom_fields_data_${field.id}_cont`,
          input_type: 'text',
          type: 'string',
          className: 'col-md-6',
        });
        break;
      case 'Number':
        customFieldsSchema.push([{
          key: `custom_fields_data_${field.id}_gteq`,
          placeholder: `${field.name} greater than`,
          label: `${field.name} greater than`,
          query_key: `custom_fields_data_${field.id}_gteq`,
          input_type: 'number',
          type: 'string',
          className: 'col-md-6',
        },
        {
          key: `custom_fields_data_${field.id}_lteq`,
          placeholder: `${field.name} less than`,
          label: `${field.name} less than`,
          query_key: `custom_fields_data_${field.id}_lteq`,
          input_type: 'number',
          type: 'string',
          className: 'col-md-6',
        }]);
        break;
      case 'Datetime':
        customFieldsSchema.push([
          {
            key: `custom_fields_data_${field.id}_gteq`,
            placeholder: `${field.name} greater than`,
            label: `${field.name} greater than`,
            query_key: `custom_fields_data_${field.id}_gteq`,
            input_type: 'date',
            min_date: new Date('2001-1-1'),
            max_date: getYearFromNow(10),
            type: 'date',
            show_time_select: true,
            overrideDateFormat: true,
            format: fallBackDatetimeFormat,
            apiDatetimeFormat: apiDefaultDateTimeFormat,
            className: 'col-md-6',
          },
          {
            key: `custom_fields_data_${field.id}_lteq`,
            placeholder: `${field.name} less than`,
            label: `${field.name} less than`,
            query_key: `custom_fields_data_${field.id}_lteq`,
            input_type: 'date',
            min_date: new Date('2001-1-1'),
            max_date: getYearFromNow(10),
            type: 'date',
            show_time_select: true,
            overrideDateFormat: true,
            format: fallBackDatetimeFormat,
            apiDatetimeFormat: apiDefaultDateTimeFormat,
            className: 'col-md-6',
          },
        ]);
        break;
      case 'Date':
        customFieldsSchema.push([
          {
            key: `custom_fields_data_${field.id}_gteq`,
            placeholder: `${field.name} greater than`,
            label: `${field.name} greater than`,
            query_key: `custom_fields_data_${field.id}_gteq`,
            input_type: 'date',
            min_date: new Date('2001-1-1'),
            max_date: getYearFromNow(10),
            type: 'date',
            show_time_select: false,
            className: 'col-md-6',
          },
          {
            key: `custom_fields_data_${field.id}_lteq`,
            placeholder: `${field.name} less than`,
            label: `${field.name} less than`,
            query_key: `custom_fields_data_${field.id}_lteq`,
            input_type: 'date',
            min_date: new Date('2001-1-1'),
            max_date: getYearFromNow(10),
            type: 'date',
            show_time_select: false,
            className: 'col-md-6',
          },
        ]);
        break;
      case 'Dropdown':
        customFieldsSchema.push({
          key: `custom_fields_data_${field.id}_in`,
          placeholder: field.name,
          label: field.name,
          query_key: `custom_fields_data_${field.id}_in`,
          input_type: 'dropdown',
          valueKey: undefined,
          labelKey: undefined,
          multi_select: true,
          should_prefetch: false,
          call_api: false,
          options: field?.options?.permissible_values?.map(
            (item) => ({ label: item, value: item }),
          ) || [],
          type: 'array',
          className: 'col-md-6',
        });
        break;
      case USER_TAG:
        customFieldsSchema.push({
          key: 'users',
          placeholder: field.name,
          label: field.name,
          query_key: `custom_fields_data_${field.id}_in`,
          input_type: 'dropdown',
          valueKey: 'id',
          labelKey: 'name',
          multi_select: true,
          should_prefetch: true,
          call_api: false,
          type: 'array',
          className: 'col-md-12',
        });
        break;
      default:
        break;
    }
    return '';
  });
  return customFieldsSchema;
};
