import {
  call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import {
  fetchActivePlan, createNewSubscriptionOrder, saveSuccessPaymentVerificationResponse,
  fetchFeatures, fetchPlans, fetchSubscriptionsOrdersHistory, fetchSubscriptionsOrdersReview,
  fetchSubscriptionsOrdersData,
} from './api';
import {
  CREATE_NEW_SUBSCRIPTION_ORDER, FETCH_ACTIVE_PLAN, FETCH_FEATURES, FETCH_PLANS,
  FETCH_SUBSCRIPTIONS_ORDERS_DATA, FETCH_SUBSCRIPTIONS_ORDERS_HISTORY,
  FETCH_SUBSCRIPTIONS_ORDERS_REVIEW, SAVE_FAILED_PAYMENT_VERIFICATION,
  SAVE_SUCCESS_PAYMENT_VERIFICATION_RESPONSE,
} from './constants';
import {
  setActivePlan, setFeatures, setPlans, fetchingFeaturesAPI, fetchingPlansAPI,
  setSubscriptionsOrders, fetchingSubscriptionsOrdersHistoryAPI,
  handleFailureSubscriptionsOrdersHistoryAPI, handleFailureFeaturesAPI, handleFailurePlansAPI,
} from './actions';

// Current Plan
function* fetchActivePlanFunc(action: any) {
  try {
    const response = yield call(fetchActivePlan());

    const activeSubscriptionPlan = response?.data?.subscription || {};
    yield put(setActivePlan({ activeSubscriptionPlan }));
    // We Fetch this API from fetchRequiredOnAppStart function in common helper.
    // action?.resolve(response);
  } catch (e) {
    // action?.reject(e);
  }
}

// Features
function* fetchFeaturesFunc(action: any) {
  try {
    yield put(fetchingFeaturesAPI());
    const response = yield call(fetchFeatures());
    const features = response?.data?.features || {};

    yield put(setFeatures({ features }));
    // action?.resolve(response);
  } catch (e) {
    yield put(handleFailureFeaturesAPI());
    // action?.reject(e);
  }
}

// Plans
function* fetchPlansFunc(action: any) {
  try {
    yield put(fetchingPlansAPI());

    const response = yield call(fetchPlans());
    const plans = response?.data?.plans || [];

    yield put(setPlans({ plans }));
    // action?.resolve(response);
  } catch (e) {
    yield put(handleFailurePlansAPI());
    // action?.reject(e);
  }
}

// "Subscription Order New"
function* fetchSubscriptionsOrdersDataFunc(action: any) {
  const { subscription_action, service_name } = action || {};
  try {
    const response = yield call(fetchSubscriptionsOrdersData({
      subscription_action, service_name,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// "Subscription Order Review"
function* fetchSubscriptionsOrdersReviewFunc(action: any) {
  const { subscriptionsOrdersReviewParams } = action || {};
  try {
    const response = yield call(fetchSubscriptionsOrdersReview({
      subscriptionsOrdersReviewParams,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// "Subscription Order History"
function* fetchSubscriptionsOrdersHistoryFunc(action: any) {
  try {
    yield put(fetchingSubscriptionsOrdersHistoryAPI());

    const response = yield call(fetchSubscriptionsOrdersHistory());
    const orders = response?.data || [];
    yield put(setSubscriptionsOrders({ orders_history: orders }));

    action.resolve(response);
  } catch (e) {
    yield put(handleFailureSubscriptionsOrdersHistoryAPI());
    action.reject(e);
  }
}

// "Create Subscription Order"
function* createNewSubscriptionOrderFunc(action: any) {
  const { subscriptionOrderData } = action;
  try {
    const response = yield call(createNewSubscriptionOrder({ subscriptionOrderData }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// "Saved Success payment"
function* saveSuccessPaymentVerificationResponseFunc(action: any) {
  const { subscriptionOrderId, paymentGatewayResponseData } = action;
  try {
    const response = yield call(saveSuccessPaymentVerificationResponse({
      subscriptionOrderId, paymentGatewayResponseData,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// "Saved Failed payment"
function* saveFailedPaymentVerificationResponseFunc(action: any) {
  const { subscriptionOrderId, paymentGatewayResponseData } = action;
  try {
    const response = yield call(saveSuccessPaymentVerificationResponse({
      subscriptionOrderId, paymentGatewayResponseData,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* subscriptionWatcher() {
  yield takeLatest(FETCH_ACTIVE_PLAN, fetchActivePlanFunc);
  yield takeLatest(FETCH_FEATURES, fetchFeaturesFunc);
  yield takeLatest(FETCH_PLANS, fetchPlansFunc);
  yield takeEvery(FETCH_SUBSCRIPTIONS_ORDERS_DATA, fetchSubscriptionsOrdersDataFunc);
  yield takeEvery(FETCH_SUBSCRIPTIONS_ORDERS_REVIEW, fetchSubscriptionsOrdersReviewFunc);
  yield takeLatest(FETCH_SUBSCRIPTIONS_ORDERS_HISTORY, fetchSubscriptionsOrdersHistoryFunc);
  yield takeLatest(CREATE_NEW_SUBSCRIPTION_ORDER, createNewSubscriptionOrderFunc);
  yield takeLatest(SAVE_SUCCESS_PAYMENT_VERIFICATION_RESPONSE, saveSuccessPaymentVerificationResponseFunc);
  yield takeLatest(SAVE_FAILED_PAYMENT_VERIFICATION, saveFailedPaymentVerificationResponseFunc);
}
