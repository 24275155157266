import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_BANKS, ADD_BANK, BANK_FETCHED, BANK_UPDATED, BANK_DELETED, AUTOCOMPLETE_BANKS,
  defaultSearchParams,
} from '../constants/banks';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
};

export default function banks(state = initialState, action) {
  switch (action.type) {
    case SET_BANKS: return setItems(state, action);
    case ADD_BANK: return addItem(state, action);
    case BANK_FETCHED: return fetchItem(state, action);
    case BANK_UPDATED: return updateItem(state, action);
    case BANK_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_BANKS: return autocomplete(state, action);
    default:
      return state;
  }
}
