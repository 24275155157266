import { HOME_BREADCRUMB_LISTING } from '../common';

export const RECON_ENTRY = 'RECON_ENTRY';
export const SET_RECON_ENTRIES = 'SET_RECON_ENTRIES';
export const SET_INF_RECON_ENTRIES = 'SET_INF_RECON_ENTRIES';
export const RESET_RECON_ENTRIES = 'RESET_RECON_ENTRIES';
export const SET_RECON_ENTRIES_SEARCH_PARAMS = 'SET_RECON_ENTRIES_SEARCH_PARAMS';
export const ADD_RECON_ENTRY = 'ADD_RECON_ENTRY';
export const RECON_ENTRY_FETCHED = 'RECON_ENTRY_FETCHED';
export const RECON_ENTRY_UPDATED = 'RECON_ENTRY_UPDATED';
export const RECON_ENTRY_DELETED = 'RECON_ENTRY_DELETED';
export const LOAD_MORE_RECON_ENTRIES = 'LOAD_MORE_RECON_ENTRIES';

export const FORM_INFORMATION_TITLE = 'ENTRY INFORMATION';

export const advanceSearchParams = {
  entry_date_gteq: '',
  entry_date_lteq: '',
  amount_lteq: '',
  amount_gteq: '',
  counterparty_id_in: [],
  entry_commercial_document_type_in: [],
  entry_ref_no_or_entry_description_or_counterparty_name_cont: '',
};

export const defaultSearchParams = {
  ref_no_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['customers'];

export const local_storage_listing_settings_key = 'accounting_recon_entries_listing_settings';

export const VOUCHER_TYPE = [{ label: 'Invoice', value: 'Invoice' }, { label: 'Payment', value: 'Payment' },
  { label: 'CreditNote', value: 'CreditNote' }, { label: 'PurchaseInvoice', value: 'PurchaseInvoice' }];

export const ACCOUNT_ENTRY_TYPE = [{ label: 'Opening Balance Entry', value: 'Plutus::OpeningBalanceEntry' },
  { label: 'Journal Entry', value: 'Plutus::JournalEntry' }];

export const title = 'Recon Entries';
export const singularTitle = 'Recon Entry';
export const moduleName = 'recon_entries';
export const baseUrl = '/accounting/entries';
export const redirectUrl = '/accounting/entries';
export const listingDataKey = 'recon_entries';
export const itemDataKey = 'recon_entry';

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);
