import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUnitsUtility, setUnits } from '@actions/utilities';
import { FETCH_UNITS_UTILITIES, IS_FETCHING_UTILITIES, UTILITIES } from '@constants/utilities';
import { getUnits } from '@helpers/units_helper';
import { handleErrors } from '@src/utils/handleErrors';

function* unitsUtilitiesSagaSideEffect() {
  try {
    yield put({ type: IS_FETCHING_UTILITIES, payload: { isFetching: true } });
    const response = yield call(fetchUnitsUtility());

    const data = response?.data || {};
    // Get 'units' in proper formated form.
    const units = getUnits(data);

    // Set 'units' in utilities reducer.
    yield put(setUnits({ units }));
  } catch (error) {
    // Handle errors.
    handleErrors(error, '/', UTILITIES);
  }
}

export function* utilitiesWatcher() {
  yield takeLatest(FETCH_UNITS_UTILITIES, unitsUtilitiesSagaSideEffect);
}
