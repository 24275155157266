import toUpper from 'lodash/toUpper';
import omit from 'lodash/omit';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios, { fetchingRequest } from '../utils/axios';
import * as types from '../constants/recents';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import {
  setRecentOrders, setRecentInvoices, setRecentPayments, setRecentReturns, setRecentCreditNotes, setRecentDebitNotes,
  setPurchaseOrders, setPurchaseInvoices, setPurchaseReturns, setPaymentOuts,
} from '../actions/recents';

import { fetchRecents as fetchRecentsRequest } from '../api/recents';

export function fetchRecents(args: Record<string, any> = {}) {
  const {
    search_params, page, per_page, recordName = 'customer', supplier_id, customer_id,
  } = args;
  const { module_name } = search_params;

  let searchParams = { ...search_params, [`${recordName}_id_eq`]: args[`${recordName}_id`] };
  if ((recordName === 'customer') && ((module_name === types.CREDIT_NOTES) || (module_name === types.DEBIT_NOTES))) {
    searchParams = { ...search_params, party_type_eq: 'Customer', party_id_eq: customer_id };
  } else if ((recordName === 'supplier') && ((module_name === types.DEBIT_NOTES))) {
    searchParams = { ...search_params, party_type_eq: 'Supplier', party_id_eq: supplier_id };
  } else if ((recordName === 'supplier') && ((module_name === types.CREDIT_NOTES))) {
    searchParams = { ...search_params, party_type_eq: 'Supplier', party_id_eq: supplier_id };
  } else if ((recordName === 'supplier') && ((module_name === types.PAYMENTS))) {
    searchParams = { ...search_params, customer_id_eq: supplier_id };
  }

  const q = serializeSearchParams(searchParams);
  const page_params = serializePageParams(page, per_page);
  fetchingRequest(`${types.RECENT}_${types.moduleNamesAndResourceType[module_name]}`);
  return (dispatch) => axios({
    url: `/${module_name}?${page_params}&${q}`,
    method: 'get',
    params: {
      trim: true,
    },
  })
    .then((response) => {
      const { meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      if (module_name === types.ORDERS) {
        dispatch(setRecentOrders({
          items: response.data.orders, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.INVOICES) {
        dispatch(setRecentInvoices({
          items: response.data.invoices, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.PAYMENTS) {
        dispatch(setRecentPayments({
          items: response.data.payments, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.RETURNS) {
        dispatch(setRecentReturns({
          items: response.data.returns, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.DEBIT_NOTES) {
        dispatch(setRecentDebitNotes({
          items: response.data.debit_notes, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.CREDIT_NOTES) {
        dispatch(setRecentCreditNotes({
          items: response.data.credit_notes, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.PURCHASE_ORDERS) {
        dispatch(setPurchaseOrders({
          items: response.data.purchase_orders, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.PURCHASE_INVOICES) {
        dispatch(setPurchaseInvoices({
          items: response.data.purchase_invoices, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.PURCHASE_RETURNS) {
        dispatch(setPurchaseReturns({
          items: response.data.purchase_returns, current_page: page, total_count, per_page,
        }));
      } else if (module_name === types.PAYMENT_OUTS) {
        dispatch(setPaymentOuts({
          items: response.data.payment_outs, current_page: page, total_count, per_page,
        }));
      }
      return response;
    })
    .catch((error) => {
      handleErrors(error, `/${recordName}s/${`${recordName}_id`}`, types.RECENT);
      throw error.response;
    });
}

const getRecentItems = (moduleName: string, response: any) => {
  switch (moduleName) {
    case types.ORDERS:
      return {
        items: response.data.orders,
      };
    case types.INVOICES:
      return {
        items: response.data.invoices,
      };
    case types.PAYMENTS:
      return {
        items: response.data.payments,
      };
    case types.RETURNS:
      return {
        items: response.data.returns,
      };
    case types.DEBIT_NOTES:
      return {
        items: response.data.debit_notes,
      };
    case types.CREDIT_NOTES:
      return {
        items: response.data.credit_notes,
      };
    case types.PURCHASE_ORDERS:
      return {
        items: response.data.purchase_orders,
      };
    case types.PURCHASE_INVOICES:
      return {
        items: response.data.purchase_invoices,
      };
    case types.PURCHASE_RETURNS:
      return {
        items: response.data.purchase_returns,
      };
    case types.PAYMENT_OUTS:
      return {
        items: response.data.payment_outs,
      };
    default:
      return {};
  }
};

function* fetchRecentsSideEffect(action: any) {
  const { page, search_params } = action;
  const { module_name } = search_params;

  try {
    // WHY  have to omit `resolve` and `reject` as it not necessary
    const response = yield call(fetchRecentsRequest(omit(action, ['reject', 'resolve'])));

    const { meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    // WHY  have to omit `resolve` and `reject` as it not necessary and `type` is omitted to
    //      infinite calls
    yield put({
      type: toUpper(`set_recent_${module_name}`),
      ...(getRecentItems(module_name, response)),
      isfetching: false,
      ...(omit(action, ['reject', 'resolve', 'type'])),
      current_page: page,
      total_count,
    });

    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export const getRecentsWatchers = () => types.recentsModuleNames.map((moduleName) => (function* () {
  yield takeLatest(types.getRecentsModuleFetchConst(moduleName), fetchRecentsSideEffect);
}));
