import {
  RECEIVE_CASH_FROM_SALESMAN, PAYMENT_BULK_OPTIONS,
  RECEIVE_CHEQUE_FROM_SALESMAN, LAST_OPENED_COMMON_ACTION_SCREEN, DEPOSIT_CHEQUE, REALIZE_CHEQUE,
} from '@containers/common_actions/common_actions_screen_helpers';
import { getTenantLocalDateTime, setLocalStorage } from '@helpers/common_helper';
import { open, accounted } from '@constants/payments';
import { TODAY, TILL_DATE } from '@constants/common';

export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'today_payments', label: 'Today\'s Payments', applyFilters: true, filterSelection: { date_gteq: getTenantLocalDateTime(), date_lteq: getTenantLocalDateTime() }, timePeriod: TODAY,
    },
    {
      key: 'open_payments', label: 'Open Payments', applyFilters: true, filterSelection: { state_in: [open] }, timePeriod: TILL_DATE,
    },
    {
      key: 'unallocated_payments', label: 'Unallocated Payments', applyFilters: true, filterSelection: { unallocated_amount_gt: '0' }, timePeriod: TILL_DATE,
    },
    {
      key: 'unallocated_accounted_payments', label: 'Unallocated Accounted Payments', applyFilters: true, filterSelection: { state_in: [accounted], unallocated_amount_gt: '0' }, timePeriod: TILL_DATE,
    },
  ],
  PROCESS: [
    {
      key: RECEIVE_CASH_FROM_SALESMAN,
      label: 'Receive Cash from Salesman',
      redirectUrl: PAYMENT_BULK_OPTIONS,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: 'Receive Cash from Salesman', key: RECEIVE_CASH_FROM_SALESMAN, printTitle: 'Cash Collection' },
        });
      },
    },
    {
      key: RECEIVE_CHEQUE_FROM_SALESMAN,
      label: 'Receive Cheques from Salesman',
      redirectUrl: PAYMENT_BULK_OPTIONS,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: 'Receive Cheques from Salesman', key: RECEIVE_CHEQUE_FROM_SALESMAN, printTitle: 'Cheque Collection' },
        });
      },
    },
    {
      key: DEPOSIT_CHEQUE,
      label: 'Deposit Cheques',
      redirectUrl: PAYMENT_BULK_OPTIONS,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: 'Deposit Cheques', key: DEPOSIT_CHEQUE, printTitle: 'Cheque Deposits' },
        });
      },
    },
    {
      key: REALIZE_CHEQUE,
      label: 'Realize Cheques',
      redirectUrl: PAYMENT_BULK_OPTIONS,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: 'Realize Cheques', key: REALIZE_CHEQUE, printTitle: 'Cheque Realization' },
        });
      },
    },
  ],
};
