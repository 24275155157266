import { UNITS_UTILITIES, UTILITIES } from '@constants/utilities';
import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';

export const setUnits = (payload: Record<string, any>) => ({
  type: UNITS_UTILITIES,
  payload,
});

export function fetchUnitsUtility() {
  return () => axios({
    url: '/utilities/units',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', UTILITIES);
      // throw error.response;
    });
}
