import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_STOCKS, ADD_STOCK, STOCK_FETCHED, STOCK_UPDATED, STOCK_DELETED, defaultSearchParams,
  moduleName,
} from '@constants/stocks';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'product_name',
  sort_order: 'asc',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function stocks(state = initialState, action) {
  switch (action.type) {
    case SET_STOCKS: return setItems(state, action);
    case ADD_STOCK: return addItem(state, action);
    case STOCK_FETCHED: return fetchItem(state, action);
    case STOCK_UPDATED: return updateItem(state, action);
    case STOCK_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
