import {
  SET_ACTIVE_PLAN, SET_FEATURES, SET_PLANS, FETCHING_FEATURES, FETCHING_PLANS,
  SET_SUBSCRIPTION_ORDERS_HISTORY, FETCHING_SUBSCRIPTION_ORDERS_HISTORY,
  HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY, HANDLE_FAILURE_FEATURES, HANDLE_FAILURE_PLANS,
} from './constants';

// Current Plan
export const setActivePlan = (args) => {
  const { activeSubscriptionPlan } = args;
  return { type: SET_ACTIVE_PLAN, activeSubscriptionPlan };
};

// Features
export const fetchingFeaturesAPI = () => ({ type: FETCHING_FEATURES });

export const setFeatures = (args) => {
  const { features } = args;
  return { type: SET_FEATURES, features };
};

export const handleFailureFeaturesAPI = () => ({ type: HANDLE_FAILURE_FEATURES });

// Plans
export const fetchingPlansAPI = () => ({ type: FETCHING_PLANS });

export const setPlans = (args) => {
  const { plans } = args;
  return { type: SET_PLANS, plans };
};
export const handleFailurePlansAPI = () => ({ type: HANDLE_FAILURE_PLANS });

// Subscription Order
export const fetchingSubscriptionsOrdersHistoryAPI = () => ({
  type: FETCHING_SUBSCRIPTION_ORDERS_HISTORY,
});

export const setSubscriptionsOrders = (args) => {
  const { orders_history: ordersHistory } = args;
  return { type: SET_SUBSCRIPTION_ORDERS_HISTORY, orders_history: ordersHistory };
};

export const handleFailureSubscriptionsOrdersHistoryAPI = () => ({
  type: HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY,
});
