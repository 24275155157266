import { HOME_BREADCRUMB_LISTING } from './common';

// Views for helper text
export const PURCHASE_ORDER_FORM_VIEW = 'purchase_order_form';

export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const PURCHASE_ORDER_FETCHED = 'PURCHASE_ORDER_FETCHED';
export const PURCHASE_ORDER_UPDATED = 'PURCHASE_ORDER_UPDATED';
export const PURCHASE_ORDER_DELETED = 'PURCHASE_ORDER_DELETED';
export const PURCHASE_ORDER_EDIT = 'PURCHASE_ORDER_EDIT';
export const AUTOCOMPLETE_PURCHASE_ORDERS = 'AUTOCOMPLETE_PURCHASE_ORDERS';

export const FETCH_PURCHASE_ORDERS = 'FETCH_PURCHASE_ORDERS';

export const FORM_INFORMATION_TITLE = 'PURCHASE ORDER INFORMATION';
export const UPDATE_ORDER_ITEM_PURCHASE_ORDER_ITEMS = 'UPDATE_ORDER_ITEM_PURCHASE_ORDER_ITEMS';
export const SET_PURCHASE_ORDER_ITEMS = 'SET_PURCHASE_ORDER_ITEMS';
export const RESET_PURCHASE_ORDER_ERRORS = 'RESET_PURCHASE_ORDER_ERRORS';

export const title = 'Purchase Orders';
export const singularTitle = 'Purchase Order';
export const moduleName = 'purchase_orders';
export const baseUrl = `/${moduleName}`;
export const redirectUrl = `/${moduleName}`;
export const listingDataKey = moduleName;
export const itemDataKey = 'purchase_order';

export const purchaseOrderConstants = {
  item: PURCHASE_ORDER,
  setItems: SET_PURCHASE_ORDERS,
  addItem: ADD_PURCHASE_ORDER,
  setItem: PURCHASE_ORDER_FETCHED,
  updateItem: PURCHASE_ORDER_UPDATED,
  deleteItem: PURCHASE_ORDER_DELETED,
  autocompleteItems: AUTOCOMPLETE_PURCHASE_ORDERS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  supplier_type_eq: '',
  state_in: [],
  supplier_id_in: [],
  branch_id_in: [],
  warehouse_id_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_supplier_name_or_supplier_gstin_cont: '',
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['suppliers', 'branches', 'warehouses', 'customers', 'voucher_books'];

// Purchase order states
export const draft = 'draft';
export const approved = 'approved';
export const processing = 'processing';
export const received = 'received';
export const closed = 'closed';
export const cancelled = 'cancelled';

export const PURCHASE_ORDER_STATES = [
  { value: draft, label: 'Draft' },
  { value: approved, label: 'Approved' },
  { value: processing, label: 'Processing' },
  { value: received, label: 'Received' },
  { value: closed, label: 'Closed' },
  { value: cancelled, label: 'Cancelled' },
];

// Breadcrumb
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'supplier', 'supplier_id', 'branch', 'branch_id', 'warehouse', 'warehouse_id'],
  purchase_order_items: ['variant', 'variant_id', 'variant_description', 'description', 'quantity', 'input_booked_quantity', 'unit', 'gross_price', 'order_item_purchase_order_items'],
};
