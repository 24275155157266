import { SET_ELIGIBLE_OFFERS } from '@constants/eligible_offers';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import { setItems } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: {},
};

export default function eligibleOffers(state = initialState, action) {
  switch (action.type) {
    case SET_ELIGIBLE_OFFERS: return setItems(state, action);
    default:
      return state;
  }
}
