import { concat, omit, unionBy } from 'lodash';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import {
  SET_COMMENTS, ADD_COMMENT, COMMENT_FETCHED, COMMENT_UPDATED,
  COMMENT_DELETED, TOGGLE_POST_COMMENT_STATE, LOAD_MORE_COMMENTS, TOGGLE_FETCH_COMMENTS_STATE,
  SET_COMMENTABLE, SET_COMMENTS_MODULE_NAME, CHECK_IF_COMMENTABLE_IS_ALLOWED, RESET_COMMENTS,
} from '../constants/comments';
import {
  setItems, fetchItem, updateItem, deleteItem, updateObject,
} from './common';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'updated_at',
  sort_order: 'desc',
  per_page: 30,
  search_params: {},
  isPosting: false,
  commentable: '',
  commentableId: '',
  module_name: '',
  allowed: '',
  commentables: {},
};

function resetCommentsData() {
  return { ...initialState };
}

function setCommentsModuleName(state, action) {
  return { ...state, module_name: action.moduleName };
}

function setCommentable(state, action) {
  return { ...state, ...action?.payload };
}

function setIfCommentableIsAllowed(state, action) {
  const newState = fromJS(state).toJS();
  const newCommentables = newState.commentables;
  newCommentables[action.commentable] = action.allowed;
  newState.isAllowed = action.allowed;

  return newState;
}

function togglePostCommentState(state) {
  const updated_action_state = {
    isPosting: !state.isPosting,
  };
  return updateObject(state, updated_action_state);
}

function toggleFetchCommentsState(state) {
  const imState = fromJS(state).toJS();
  const updated_action_state = {
    isFetching: !imState.isFetching,
  };

  return { ...imState, ...updated_action_state };
}

function addComment(state, action) {
  const updated_action_state = {
    items: concat(action.item, ...state.items),
    total_count: state.total_count + 1,
  };
  return updateObject(state, updated_action_state);
}

export function addMoreComments(state, action) {
  let updated_action_state = { ...action };
  updated_action_state = omit(updated_action_state, 'type');
  if (action.search_params) {
    updated_action_state.search_params = { ...state.search_params, ...action.search_params };
  }
  if (!isEmpty(action.items)) {
    const { total_count } = action;
    const comments = unionBy(state.items, action.items, 'id');
    const comments_left = total_count - comments.length;
    updated_action_state.items = comments;
    updated_action_state.comments_left = comments_left;
  }

  return updateObject(state, updated_action_state);
}

export default function comments(state = initialState, action) {
  switch (action.type) {
    case CHECK_IF_COMMENTABLE_IS_ALLOWED: return setIfCommentableIsAllowed(state, action);
    case SET_COMMENTS_MODULE_NAME: return setCommentsModuleName(state, action);
    case SET_COMMENTABLE: return setCommentable(state, action);
    case SET_COMMENTS: return setItems(state, action);
    case ADD_COMMENT: return addComment(state, action);
    case LOAD_MORE_COMMENTS: return addMoreComments(state, action);
    case TOGGLE_POST_COMMENT_STATE: return togglePostCommentState(state);
    case TOGGLE_FETCH_COMMENTS_STATE: return toggleFetchCommentsState(state);
    case COMMENT_FETCHED: return fetchItem(state, action);
    case COMMENT_UPDATED: return updateItem(state, action);
    case COMMENT_DELETED: return deleteItem(state, action);
    case RESET_COMMENTS: return resetCommentsData();
    default:
      return state;
  }
}
