import {
  concat, findIndex, map, filter, unionBy, includes, omit,
} from 'lodash';

// We use below common function to update redux state.
// Ex.
//    users: {
//      items: [],
//      current_page: 1,
//      per_page: 10,
//      total_count: 120,
//      sort_by: 'id',
//      sort_order: 'asc',
//      isError: false,
//      errors: {},
//      isFetching: false,
//      search_params: { name_cont: ''},
//    }

export function updateObject(oldState, newState) {
  return {
    ...oldState, ...newState, isError: false, errors: {}, isFetching: false,
  };
}

export function setItems(state, action) {
  let updated_action_state = { ...action };
  updated_action_state = omit(updated_action_state, 'type');
  if (!action.dont_merge_search_params && action.search_params) {
    updated_action_state.search_params = { ...state.search_params, ...action.search_params };
  }

  return updateObject(state, updated_action_state);
}

export function addItem(state, action) {
  const updated_action_state = {
    items: concat(...state.items, action.item),
    current_page: 1,
    total_count: state.total_count + 1,
  };
  return updateObject(state, updated_action_state);
}

export function fetchItem(state, action) {
  const index = findIndex(state.items, (item) => item.id === action.item.id);
  if (index > -1) {
    const updated_action_state = {
      items: map(state.items, (current_item) => {
        if (current_item.id === action.item.id) return action.item;
        return current_item;
      }),
    };
    return updateObject(state, updated_action_state);
  }

  const dataListing = {
    items: concat(...state.items, action.item),
    current_page: 1,
    total_count: 1,
  };

  return updateObject(state, dataListing);
}

export function updateItem(state, action) {
  const updated_action_state = {
    items: map(state.items, (item) => {
      if (item.id === action.item.id) return action.item;
      return item;
    }),
  };
  return updateObject(state, updated_action_state);
}

export function deleteItem(state, action) {
  const updated_action_state = {
    items: filter(state.items, (item) => parseInt(item.id, 10) !== parseInt(action.id, 10)),
    total_count: state.total_count - 1,
  };
  return updateObject(state, updated_action_state);
}

export function autocomplete(state, action) {
  const updated_action_state = {
    items: action.items,
    current_page: 1,
  };
  return updateObject(state, updated_action_state);
}

export function autocompleteUnions(state, action) {
  const updated_action_state = {
    items: unionBy(action.items, state.items, 'id'),
    current_page: 1,
  };
  return updateObject(state, updated_action_state);
}

export function updateStatus(state, action, status) {
  const updatedActionState = {
    items: map(state.items, (item) => {
      const isItem = includes(action.selected_items_ids, item.id);
      if (isItem) {
        return { ...item, state: status };
      }
      return item;
    }),
  };
  return updateObject(state, updatedActionState);
}

export function setRecents(state, action) {
  let updated_action_state = { ...action };
  updated_action_state = omit(updated_action_state, 'type');
  return updateObject(state, updated_action_state);
}
