import { HOME_BREADCRUMB_LISTING } from './common';

export const CREDIT_REASON = 'CREDIT_REASON';
export const SET_CREDIT_REASONS = 'SET_CREDIT_REASONS';
export const ADD_CREDIT_REASON = 'ADD_CREDIT_REASON';
export const CREDIT_REASON_FETCHED = 'CREDIT_REASON_FETCHED';
export const CREDIT_REASON_UPDATED = 'CREDIT_REASON_UPDATED';
export const CREDIT_REASON_DELETED = 'CREDIT_REASON_DELETED';
export const CREDIT_REASON_EDIT = 'CREDIT_REASON_EDIT';
export const AUTOCOMPLETE_CREDIT_REASONS = 'AUTOCOMPLETE_CREDIT_REASONS';

export const FORM_INFORMATION_TITLE = 'CREDIT REASON INFORMATION';
export const RESET_CREDIT_REASON_ERRORS = 'RESET_CREDIT_REASON_ERRORS';
export const ADD_NEW_CREDIT_REASON = 'Add New Credit Reason';

export const title = 'Credit Reasons';
export const singularTitle = 'Credit Reason';
export const moduleName = 'credit_reasons';
export const baseUrl = '/credit_reasons';
export const redirectUrl = '/credit_reasons';
export const listingDataKey = 'credit_reasons';
export const itemDataKey = 'credit_reason';

export const creditReasonConstants = {
  item: CREDIT_REASON,
  setItems: SET_CREDIT_REASONS,
  addItem: ADD_CREDIT_REASON,
  setItem: CREDIT_REASON_FETCHED,
  updateItem: CREDIT_REASON_UPDATED,
  deleteItem: CREDIT_REASON_DELETED,
  autocompleteItems: AUTOCOMPLETE_CREDIT_REASONS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Credit Reasons';
export const TargetBaseUrl = 'credit_reasons';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'debit_ledger_name', 'debit_ledger_id', 'debit_ledger'],
};
