import React from 'react';
import { object, oneOfType, bool, func } from 'prop-types';

const Loading = (props) => {
  if (props.error) {
    // When the loader has errored
    return <div>Please <button onClick={props.retry}>Retry</button> again!</div>;
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return <div>Taking a long time... <button onClick={props.retry}>Retry</button></div>;
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div className="main-loading"><div className="lds-roller"><div /><div /><div /><div /><div /><div /><div /><div /></div></div>;
  }
  // When the loader has just started
  return null;
};

Loading.propTypes = {
  error: oneOfType([object, bool]),
  retry: func.isRequired,
  pastDelay: bool.isRequired,
  timedOut: bool,
};

Loading.defaultProps = {
  error: null,
  timedOut: false,
};

export default Loading;
