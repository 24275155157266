import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import App from '../components/App';

const Routes = (
  <HashRouter>
    <Route path="/" name="Home" component={App} />
  </HashRouter>
);

export default Routes;
