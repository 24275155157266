import startCase from 'lodash/startCase';

export const title = 'EWayBills';
export const singularTitle = 'EWayBill';
export const moduleName = 'e_way_bills';
export const baseUrl = '/e_way_bills';
export const redirectUrl = '/e_way_bills';
export const listingDataKey = 'e_way_bills';
export const itemDataKey = 'e_way_bill';

export const E_WAY_BILL = 'E_WAY_BILL';
export const SET_E_WAY_BILLS = 'SET_E_WAY_BILLS';
export const ADD_E_WAY_BILL = 'ADD_E_WAY_BILL';
export const E_WAY_BILL_FETCHED = 'E_WAY_BILL_FETCHED';
export const E_WAY_BILL_UPDATED = 'E_WAY_BILL_UPDATED';
export const E_WAY_BILL_DELETED = 'E_WAY_BILL_DELETED';
export const RESET_E_WAY_BILL_ERRORS = 'RESET_E_WAY_BILL_ERRORS';
export const AUTOCOMPLETE_E_WAY_BILLS = 'AUTOCOMPLETE_E_WAY_BILLS';
export const FETCH_E_WAY_BILL_MASTER_DATA = 'FETCH_E_WAY_BILL_MASTER_DATA';
export const CREATE_E_WAY_BILL = 'CREATE_E_WAY_BILL';

export const SET_INVOICE_E_WAY_BILL_DATA = 'SET_INVOICE_E_WAY_BILL_DATA';
export const SET_CREDIT_NOTE_E_WAY_BILL_DATA = 'SET_CREDIT_NOTE_E_WAY_BILL_DATA';
export const SET_DEBIT_NOTE_E_WAY_BILL_DATA = 'SET_DEBIT_NOTE_E_WAY_BILL_DATA';

export const CANCEL_E_WAY_BILL = 'CANCEL_E_WAY_BILL';
export const AUTOCOMPLETE_E_WAY_BILL_CANCELLATION_REASONS = 'AUTOCOMPLETE_E_WAY_BILL_CANCELLATION_REASONS';

export const PROCESSING_BTN_LABEL = 'Processing E-Way Bill';
export const CREATE_E_WAY_BILL_BTN_LABEL = 'Create E-Way Bill';

export const eWayBillConstants = {
  item: E_WAY_BILL,
  setItems: SET_E_WAY_BILLS,
  addItem: ADD_E_WAY_BILL,
  setItem: E_WAY_BILL_FETCHED,
  updateItem: E_WAY_BILL_UPDATED,
  deleteItem: E_WAY_BILL_DELETED,
  resetErrors: RESET_E_WAY_BILL_ERRORS,
  autocompleteItems: AUTOCOMPLETE_E_WAY_BILLS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// E Way Bill states
export const PROCESSING = 'processing';
export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const CANCEL = 'cancel';

export const E_WAY_BILL_STATES = [
  { label: startCase(PROCESSING), value: PROCESSING },
  { label: startCase(SUCCESS), value: SUCCESS },
  { label: startCase(FAILURE), value: FAILURE },
  { label: startCase(CANCEL), value: CANCEL },
];

export const CUSTOMER = 'Customer';
export const SUPPLIER = 'Supplier';
export const OTHER = 'Other';
export const SELF = 'Self';

export const TRANSPORTER_TYPES = [
  { label: 'Supplier', value: SUPPLIER },
  { label: 'Self', value: SELF },
  { label: 'Customer', value: CUSTOMER },
  { label: 'Other', value: OTHER },
];

export const E_WAY_BILL_STATUSES = [
  { label: 'Created', value: false },
  { label: 'Pending', value: true },
];
