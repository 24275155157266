import map from 'lodash/map';
import { BULK_CREATE_INVENTORY_BATCHES_FOR_STOCK_TRANSFER_ITEMS } from '@constants/inventories';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_STOCK_TRANSFERS, ADD_STOCK_TRANSFER, STOCK_TRANSFER_FETCHED, STOCK_TRANSFER_UPDATED,
  STOCK_TRANSFER_DELETED, AUTOCOMPLETE_STOCK_TRANSFERS, defaultSearchParams,
  SET_WAREHOUSE_EMPTYING_DETAILS, WarehouseDetailsIntf, RESET_STOCK_TRANSFER_ITEMS,
} from '@constants/stock_transfers';
import { BULK_CREATE_INVENTORY_PIECES_FOR_STOCK_TRANSFER_ITEMS } from '@constants/inventory_pieces';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from './common';

interface ReducerAction {
  type: string;
}

interface Action extends ReducerAction {
  warehouseDetails: WarehouseDetailsIntf;
  voucherId: number;
  voucherItemId: number;
  voucherItemData: any;
}

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'number',
  sort_order: 'desc',
  search_params: { ...defaultSearchParams },
};

const setWarehouseEmptyingDetails = (state = initialState, action: Action) => {
  const currentState = { ...state };
  currentState.items = [action.warehouseDetails || {}];
  currentState.isFetching = false;

  return currentState;
};

const resetStockTransferItems = (state = initialState) => {
  const currentState = { ...state };
  currentState.items = [];

  return currentState;
};

export default function stockTransfers(state = initialState, action: Action) {
  switch (action.type) {
    case SET_STOCK_TRANSFERS: return setItems(state, action);
    case ADD_STOCK_TRANSFER: return addItem(state, action);
    case STOCK_TRANSFER_FETCHED: return fetchItem(state, action);
    case STOCK_TRANSFER_UPDATED: return updateItem(state, action);
    case STOCK_TRANSFER_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_STOCK_TRANSFERS: return autocompleteUnions(state, action);
    case SET_WAREHOUSE_EMPTYING_DETAILS: return setWarehouseEmptyingDetails(state, action);
    case RESET_STOCK_TRANSFER_ITEMS: return resetStockTransferItems(state);
    case BULK_CREATE_INVENTORY_PIECES_FOR_STOCK_TRANSFER_ITEMS:
    case BULK_CREATE_INVENTORY_BATCHES_FOR_STOCK_TRANSFER_ITEMS: {
      const {
        voucherId, voucherItemId, voucherItemData,
      } = action;
      const items = map(state.items, (item) => {
        if (item?.id !== voucherId) return item;
        const stockTransferItems = map(item.stock_transfer_items, (stockTransferItem) => {
          if (stockTransferItem?.id !== voucherItemId) return stockTransferItem;
          return voucherItemData;
        });
        return { ...item, stock_transfer_items: stockTransferItems };
      });

      return { ...state, items };
    }
    default:
      return state;
  }
}
