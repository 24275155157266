import { map } from 'lodash';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_RETURNS, ADD_RETURN, NEW_RETURN, RETURN_FETCHED, RETURN_UPDATED, RETURN_DELETED,
  UPDATE_SELECTED_BULK_RETURNS_STATUS_TO_APPROVED, approved, defaultSearchParams, moduleName,
} from '@constants/returns';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateStatus,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import { BULK_CREATE_INVENTORY_BATCHES_FOR_RETURN_ITEMS } from '@constants/inventories';
import { BULK_CREATE_INVENTORY_PIECES_FOR_RETURN_ITEMS } from '@constants/inventory_pieces';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function returns(state = initialState, action) {
  switch (action.type) {
    case SET_RETURNS: return setItems(state, action);
    case ADD_RETURN: return addItem(state, action);
    case RETURN_FETCHED: return fetchItem(state, action);
    case RETURN_UPDATED: return updateItem(state, action);
    case RETURN_DELETED: return deleteItem(state, action);
    case UPDATE_SELECTED_BULK_RETURNS_STATUS_TO_APPROVED: return updateStatus(state, action, approved);
    case NEW_RETURN: {
      const items = map(state.items, (item) => {
        if (item.id === null) return false;
        return item;
      });
      items.push(action.return_object);
      return {
        ...state,
        items,
        isError: false,
        errors: {},
        isFetching: false,
      };
    }
    case BULK_CREATE_INVENTORY_PIECES_FOR_RETURN_ITEMS:
    case BULK_CREATE_INVENTORY_BATCHES_FOR_RETURN_ITEMS: {
      const {
        voucherId, voucherItemId, voucherItemData,
      } = action;
      const items = map(state.items, (item) => {
        if (item?.id !== voucherId) return item;
        const returnItems = map(item.return_items, (returnItem) => {
          if (returnItem?.id !== voucherItemId) return returnItem;
          return voucherItemData;
        });
        return { ...item, return_items: returnItems };
      });

      return { ...state, items };
    }
    default:
      return state;
  }
}
