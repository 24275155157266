import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_CUSTOMER_RATINGS, ADD_CUSTOMER_RATING, CUSTOMER_RATING_FETCHED,
  CUSTOMER_RATING_UPDATED, CUSTOMER_RATING_DELETED, AUTOCOMPLETE_CUSTOMER_RATINGS,
  defaultSearchParams, moduleName,
} from '../constants/customer_ratings';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from './common';
import { getHeaderSelection, DEFAULT } from '../components/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function customerRatings(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_RATINGS: return setItems(state, action);
    case ADD_CUSTOMER_RATING: return addItem(state, action);
    case CUSTOMER_RATING_FETCHED: return fetchItem(state, action);
    case CUSTOMER_RATING_UPDATED: return updateItem(state, action);
    case CUSTOMER_RATING_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_CUSTOMER_RATINGS: return autocompleteUnions(state, action);
    default:
      return state;
  }
}
