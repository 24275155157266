import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { errStatusCodeToBeLogged } from '@constants/common_constants';
// import dayjs from 'dayjs';
import * as utilsConst from '@utils/common_constants';
import StackTrace from 'stacktrace-js';
import store from '@store/store';
import { BASE_URL } from '@constants/common';
import { NOTIFY_EXCEPTION_URL, sendExceptionEmail } from '@api/exception_email';
import { addUpdateNotice } from '@utils/axios_helper';
import {
  logException, logTiming, removeIDAndQueriesFromUrl, getCurrentTimestamp, CategoryEnum,
} from '@utils/GAUtils';
import { getCookie } from '@helpers/browserStorageManager';
import { getSubDomain } from '@helpers/common_helper';

// export const LOCAL_TIMEZONE = 'X-local-timezone';

// !SECTION headers config =====================================

export const shouldLogErrWithStatus = (code) => errStatusCodeToBeLogged.includes(code);

axios.defaults.headers.common[utilsConst.DMS_CHANNEL_HEADER_FOR_BE] = utilsConst.DMS_CHANNEL;

// We Create axios instance with baseUrl
const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    [utilsConst.dashboardVerHeaderForBE]: utilsConst.dashboardVersion,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const modConfig = config;
    modConfig.metadata = { startTime: getCurrentTimestamp() };

    // const localDateTimeWithZone = dayjs();
    // modConfig.headers[LOCAL_TIMEZONE] = localDateTimeWithZone.format();
    const xAuthToken = getCookie(utilsConst.AUTH_TOKEN_HEADER_FOR_BE) || '';
    const xAccountId = getCookie(utilsConst.USER_MOBILE_HEADER_FOR_BE) || '';
    const xCSRFToken = getCookie(utilsConst.CSRF_TOKEN_FOR_BE) || '';

    modConfig.headers[utilsConst.AUTH_TOKEN_HEADER_FOR_BE] = modConfig.headers[utilsConst.AUTH_TOKEN_HEADER_FOR_BE] || xAuthToken || localStorage.getItem(utilsConst.AUTH_TOKEN_HEADER_FOR_BE) || '';
    modConfig.headers[utilsConst.USER_MOBILE_HEADER_FOR_BE] = modConfig.headers[utilsConst.USER_MOBILE_HEADER_FOR_BE] || xAccountId || localStorage.getItem(utilsConst.USER_MOBILE_HEADER_FOR_BE) || '';
    modConfig.headers[utilsConst.CSRF_TOKEN_FOR_BE] = modConfig.headers[utilsConst.CSRF_TOKEN_FOR_BE] || xCSRFToken || localStorage.getItem(utilsConst.CSRF_TOKEN_FOR_BE) || '';

    return modConfig;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const resHeaders = response?.headers || {};

    const statusCodeToBeLogged = [
      // Redirection errros to be logged
      300, 301, 302, 303, 307, 308,
    ];

    if (statusCodeToBeLogged.includes(response.status)) {
      Sentry.withScope((scope) => {
        scope.setExtras({ responseType: 'redirect' });
        Sentry.captureException(response);
      });
    }

    // CONSTANT  what kind of update is availble => 'mandatory' | 'optional'
    if (Object.keys(resHeaders).length > 0) {
      addUpdateNotice(resHeaders);
    }

    response.config.metadata.endTime = getCurrentTimestamp();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
    const modUrl = removeIDAndQueriesFromUrl(response.config.url);
    logTiming(CategoryEnum.API, modUrl, response.duration);
    return response;
  },
  (error) => {
    const modError = error;

    const errorStatus = (modError?.response?.status) || '';
    if (shouldLogErrWithStatus(errorStatus) && (errorStatus !== 404)) {
      Sentry.withScope((scope) => {
        scope.setExtras({ responseType: 'error' });
      });

      const responseURL = error.response?.request?.responseURL || '';
      const isForNotifyException = responseURL.includes(NOTIFY_EXCEPTION_URL);

      // NOTE: this is to prevent infinite calls for `notify_exception` because when the
      // `notify_exception` request itself is giving an error, then why do we even need to call
      // notify_exception url again
      if (!isForNotifyException) {
        const auth = store.getState().auth || {};
        const currentUser = auth?.current_user || {};
        const userName = currentUser?.name || '';
        const userId = currentUser?.id || '';
        const hasSubDomain = getSubDomain();

        StackTrace.fromError(error).then((stackFrames) => {
          let errorStackTrace = '';
          const stack = stackFrames.map((row) => ({
            fileName: `${row.fileName}:${row.lineNumber || 0}:${row.columnNumber || 0}`,
            functionName: `${row.functionName}`,
          }));
          errorStackTrace = JSON.stringify(stack);

          let errorStackTraceMessage = '';
          if (error?.message) {
            errorStackTraceMessage = `${error?.message}`;
          }
          if (errorStackTrace) {
            errorStackTraceMessage = `${errorStackTraceMessage}-${errorStackTrace}`;
          }
          sendExceptionEmail({
            error: errorStackTraceMessage, tenantName: hasSubDomain, userName, userId,
          })(store);
        });
      }
    }
    const resHeaders = modError?.response?.headers || {};

    if (Object.keys(resHeaders).length > 0) {
      addUpdateNotice(resHeaders);
    }

    if (modError.response) {
      modError.config.metadata.endTime = new Date();
      modError.duration = modError.config.metadata.endTime - modError.config.metadata.startTime;
      const modUrl = removeIDAndQueriesFromUrl(modError.response.config.url);
      logTiming(CategoryEnum.API, modUrl, modError.response.duration);
      logException(`${modError.response.status}:  ${modError.response.config.method}: ${modUrl}`, false);
    }
    return Promise.reject(modError);
  },
);

// Interceptors
// You can intercept requests or responses before they are handled by then or catch.

export function fetchingRequest(moduleName, subModuleName = '', otherParams = {}) {
  store.dispatch({ type: `FETCHING_${moduleName}_REQUEST`, subModuleName, otherParams });
}

// This fuction automatically call before every api request send to server.
// export const globalRequestInterceptor = axiosInstance.interceptors.request.use((config) => {
// if (config.module_name) {
// fetchingRequest(config.module_name);
// }
// return config;
// }, error =>
// Do something with request error
// Promise.reject(error));

// We can use following GET, POST, PUT, DELETE method to reduce repeated code in actions/*.
// So use this method in everywhere.
// TODO: The following methods add only in actions/accounts.jsx and
// actions/account_entries.jsx file.

export default axiosInstance;
