import { HOME_BREADCRUMB_LISTING } from './common';

export const INVENTORY_IDENTIFICATION = 'INVENTORY_IDENTIFICATION';
export const SET_INVENTORY_IDENTIFICATIONS = 'SET_INVENTORY_IDENTIFICATIONS';
export const ADD_INVENTORY_IDENTIFICATION = 'ADD_INVENTORY_IDENTIFICATION';
export const INVENTORY_IDENTIFICATION_FETCHED = 'INVENTORY_IDENTIFICATION_FETCHED';
export const INVENTORY_IDENTIFICATION_UPDATED = 'INVENTORY_IDENTIFICATION_UPDATED';
export const INVENTORY_IDENTIFICATION_DELETED = 'INVENTORY_IDENTIFICATION_DELETED';
export const RESET_INVENTORY_IDENTIFICATION_ERRORS = 'RESET_INVENTORY_IDENTIFICATION_ERRORS';
export const AUTOCOMPLETE_INVENTORY_IDENTIFICATIONS = 'AUTOCOMPLETE_INVENTORY_IDENTIFICATIONS';

export const FORM_INFORMATION_TITLE = 'INVENTORY IDENTIFICATION INFORMATION';
export const ADD_NEW_INVENTORY_IDENTIFICATION = 'Add New Inventory Identification';

export const title = 'Inventory Identifications';
export const singularTitle = 'Inventory Identification';
export const moduleName = 'inventory_identifications';
export const baseUrl = '/inventory_identifications';
export const redirectUrl = '/inventory_identifications';
export const listingDataKey = 'inventory_identifications';
export const itemDataKey = 'inventory_identification';

export const inventoryIdentificationConstants = {
  item: INVENTORY_IDENTIFICATION,
  setItems: SET_INVENTORY_IDENTIFICATIONS,
  addItem: ADD_INVENTORY_IDENTIFICATION,
  setItem: INVENTORY_IDENTIFICATION_FETCHED,
  updateItem: INVENTORY_IDENTIFICATION_UPDATED,
  deleteItem: INVENTORY_IDENTIFICATION_DELETED,
  resetErrors: RESET_INVENTORY_IDENTIFICATION_ERRORS,
  autocompleteItems: AUTOCOMPLETE_INVENTORY_IDENTIFICATIONS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_or_level_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Inventory Identifications';
export const TargetBaseUrl = 'inventory_identifications';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['level', 'identifier'],
};
