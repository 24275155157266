export const PROFIT_MARGIN_KEY = 'profit_margin';
export const MRP_MARGIN_KEY = 'mrp_margin';
export const RETAILER_MARGIN_KEY = 'retailer_margin';
export const PROFIT_MARGIN_ERROR_KEY = 'profit_margin_error';
export const MRP_MARGIN_ERROR_KEY = 'mrp_margin_error';
export const RETAILER_MARGIN_ERROR_KEY = 'retailer_margin_error';
export const MRP_KEY = 'mrp';

export const costNullError = 'Cost doesn\'t exist';
export const mrpNullError = 'MRP doesn\'t exist';

export const sum = (a, b) => a + b;

export const marginOptions = [
  {
    key: PROFIT_MARGIN_KEY,
    label: 'Profit margin',
  },
  {
    key: MRP_MARGIN_KEY,
    label: 'MRP margin',
  },
  {
    key: RETAILER_MARGIN_KEY,
    label: 'Retailer margin',
  },
];

// Calculates all the margins
export const calculateMargins = (
  args: {
    cost: number; mrp: number; sellingPrice: number;
    setInputMarginValue?: number; skipMarginCalcFor?: string;
  },
) => {
  const {
    cost, mrp, sellingPrice, setInputMarginValue, skipMarginCalcFor,
  } = args;
  const margins = {};
  marginOptions.forEach((margin) => {
    switch (margin.key) {
      case PROFIT_MARGIN_KEY: {
        if (skipMarginCalcFor === PROFIT_MARGIN_KEY) {
          margins[PROFIT_MARGIN_KEY] = setInputMarginValue;
          break;
        }
        if (!sellingPrice) {
          margins[PROFIT_MARGIN_ERROR_KEY] = ' ';
        } else if (cost === null || cost === undefined) {
          // Cost can be null
          margins[PROFIT_MARGIN_ERROR_KEY] = costNullError;
        } else {
          const value = ((sellingPrice - cost) / sellingPrice) * 100;
          margins[PROFIT_MARGIN_ERROR_KEY] = '';
          margins[PROFIT_MARGIN_KEY] = Number(value.toFixed(2));
        }
        break;
      }
      case MRP_MARGIN_KEY: {
        if (skipMarginCalcFor === MRP_MARGIN_KEY) {
          margins[MRP_MARGIN_KEY] = setInputMarginValue;
          break;
        }
        if (!sellingPrice) {
          margins[MRP_MARGIN_ERROR_KEY] = ' ';
        } else if (cost === null || cost === undefined) {
          // Mrp or cost can be null
          margins[MRP_MARGIN_ERROR_KEY] = costNullError;
        } else if (mrp === null || mrp === undefined) {
          margins[MRP_MARGIN_ERROR_KEY] = mrpNullError;
        } else {
          const value = ((sellingPrice - cost) / mrp) * 100;
          margins[MRP_MARGIN_ERROR_KEY] = '';
          margins[MRP_MARGIN_KEY] = Number(value.toFixed(2));
        }
        break;
      }
      case RETAILER_MARGIN_KEY: {
        if (skipMarginCalcFor === RETAILER_MARGIN_KEY) {
          margins[RETAILER_MARGIN_KEY] = setInputMarginValue;
          break;
        }
        if (!sellingPrice) {
          margins[RETAILER_MARGIN_ERROR_KEY] = ' ';
        } else if (mrp === null || mrp === undefined) {
          // Mrp can be null
          margins[RETAILER_MARGIN_ERROR_KEY] = mrpNullError;
        } else {
          const value = ((mrp - sellingPrice) / mrp) * 100;
          margins[RETAILER_MARGIN_ERROR_KEY] = '';
          margins[RETAILER_MARGIN_KEY] = Number(value.toFixed(2));
        }
        break;
      }
      default:
        break;
    }
  });
  return margins;
};

// We can give these options for margin calculations -
// cost - 70, selling - 80, MRP - 100
// Profit margin = (80-70)/80 = 12.5%
// MRP margin = (80-70)/100 = 10%
// Retailer margin = (100-80)/100 = 20%

export const calculatePriceForMargin = (
  args: { margin: number; product: any; marginTypeKey: string;
    costOption: string; inclusiveTax: boolean; },
) => {
  const {
    margin, product, marginTypeKey, costOption, inclusiveTax,
  } = args;
  const mrp = Number(product[MRP_KEY]);
  let cost;
  if (inclusiveTax) {
    cost = Number(product.purchase_cost_history[costOption].incl_tax);
  } else {
    cost = Number(product.purchase_cost_history[costOption].excl_tax);
  }
  let price;
  switch (marginTypeKey) {
    case MRP_MARGIN_KEY:
      price = cost + (mrp * (margin / 100));
      break;
    case RETAILER_MARGIN_KEY:
      price = mrp * (1 - (margin / 100));
      break;
    case PROFIT_MARGIN_KEY:
    default:
      price = cost / (1 - (margin / 100));
  }
  return Number(price.toFixed(2));
};
