import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import { moduleName as customers, baseUrl as customersBaseUrl } from '@constants/customers';
import { moduleName as suppliers, baseUrl as suppliersBaseUrl } from '@constants/suppliers';
import { moduleName as users, baseUrl as usersBaseUrl } from '@constants/users';
import { moduleName as userGroups, baseUrl as userGroupsBaseUrl } from '@constants/users_groups';
import { moduleName as warehouses, baseUrl as warehousesBaseUrl } from '@constants/warehouses';
import { moduleName as branches, baseUrl as branchesBaseUrl } from '@constants/branches';
import { moduleName as productCategories, baseUrl as productCategoriesBaseUrl } from '@constants/product_categories';
import { moduleName as taxCategories, baseUrl as taxCategoriesBaseUrl } from '@constants/tax_categories';
import { moduleName as customerCategories, baseUrl as customerCategoriesBaseUrl } from '@constants/customer_categories';
import { moduleName as customerRatings, baseUrl as customerRatingsBaseUrl } from '@constants/customer_ratings';
import { moduleName as bankAccounts, baseUrl as bankAccountsBaseUrl } from '@constants/bank_accounts';
import { moduleName as invoiceTerms, baseUrl as invoiceTermsBaseUrl } from '@constants/invoice_terms';
import { moduleName as brands, baseUrl as brandsBaseUrl } from '@constants/brands';
import { moduleName as purchaseOrderTerms, baseUrl as purchaseOrderTermsBaseUrl } from '@constants/purchase_order_terms';

// INTERFACES
type supportedModuleNames = 'customers' | 'suppliers' | 'users' | 'branches' | 'warehouses' | 'users_groups' | 'product_categories' | 'tax_categories' | 'bank_accounts' | 'customer_categories' | 'customer_ratings' | 'invoice_terms' | 'brands' | 'purchase_order_terms'

interface GetColLinkProps {
  item: any; propName: string; moduleName: supportedModuleNames;
}
// !INTERFACES

const modulesWhichHaveShowPage = [
  users, bankAccounts, branches, brands, customers, suppliers, warehouses, userGroups,
];

export const GetColLink = (props: GetColLinkProps) => {
  const {
    item, propName, moduleName,
  } = props;

  const hasShow = modulesWhichHaveShowPage.includes(moduleName);

  let propId = propName;
  let propBaseUrl = '';
  const propValue = item[propName] || '';

  const strsToBeReplaced = ['_name', '_label'];
  let k = '';

  if (propId === 'name') {
    propId = 'id';
  } else if (strsToBeReplaced.some((str) => { k = str; return propId.includes(str); })) {
    const strToBeReplaced = strsToBeReplaced[strsToBeReplaced.indexOf(k)];
    propId = propId.replace(strToBeReplaced, '_id');
  } else {
    propId += '_id';
  }

  if (!item[propId]) {
    return (
      <>{propValue}</>
    );
  }

  switch (moduleName) {
    case customers:
      propBaseUrl = customersBaseUrl;
      break;
    case suppliers:
      propBaseUrl = suppliersBaseUrl;
      break;
    case users:
      propBaseUrl = usersBaseUrl;
      break;
    case warehouses:
      propBaseUrl = warehousesBaseUrl;
      break;
    case branches:
      propBaseUrl = branchesBaseUrl;
      break;
    case userGroups:
      propBaseUrl = userGroupsBaseUrl;
      break;
    case productCategories:
      propBaseUrl = productCategoriesBaseUrl;
      break;
    case taxCategories:
      propBaseUrl = taxCategoriesBaseUrl;
      break;
    case bankAccounts:
      propBaseUrl = bankAccountsBaseUrl;
      break;
    case customerCategories:
      propBaseUrl = customerCategoriesBaseUrl;
      break;
    case customerRatings:
      propBaseUrl = customerRatingsBaseUrl;
      break;
    case invoiceTerms:
      propBaseUrl = invoiceTermsBaseUrl;
      break;
    case brands:
      propBaseUrl = brandsBaseUrl;
      break;
    case purchaseOrderTerms:
      propBaseUrl = purchaseOrderTermsBaseUrl;
      break;
    default:
      break;
  }

  const editEndPt = hasShow ? '' : '/edit';
  propBaseUrl = `${propBaseUrl}/`;

  return (
    <Link to={`${propBaseUrl}${item[propId]}${editEndPt}`}>
      {propValue}
    </Link>
  );
};

// PROPS
GetColLink.defaultProps = {
  item: {}, propName: '', moduleName: '',
};
// !PROPS

export const getVoucherLink = (voucher: any) => {
  switch (voucher?.voucher_type) {
    case 'Order':
      return `/orders/${voucher?.voucher_id}`;
    case 'Invoice':
      return `/invoices/${voucher?.voucher_id}`;
    case 'Payment':
      return `/payments/${voucher?.voucher_id}`;
    case 'Return':
      return `/returns/${voucher?.voucher_id}`;
    case 'CreditNote':
      return `/credit_notes/${voucher?.voucher_id}`;
    case 'DebitNote':
      return `/debit_notes/${voucher?.voucher_id}`;
    case 'PurchaseOrder':
      return `/purchase_orders/${voucher?.voucher_id}`;
    case 'PurchaseInvoice':
      return `/purchase_invoices/${voucher?.voucher_id}`;
    case 'PurchaseReturn':
      return `/purchase_returns/${voucher?.voucher_id}`;
    case 'PaymentOut':
      return `/payment_outs/${voucher?.voucher_id}`;
    default:
      return '/';
  }
};

// PROPS
interface StatePillProps {
  states: { label: string; value: string; stateStyle: string }[];
  stateValue: string;
}
// !PROPS

// COMPONENT state tag
export const StatePill = ({ states, stateValue }: StatePillProps) => {
  if (stateValue && states && isArray(states)) {
    const curStateObj = states.find((state) => (state.value === stateValue));
    if (curStateObj) {
      const { label, stateStyle } = curStateObj;
      return (
        <span className={`label label-${stateStyle}`}>{label}</span>
      );
    }
    return null;
  }
  return null;
};

// !COMPONENT state tag

export const GenTableContainer = ({ className = '', style = {} }) => ({ children }) => (
  <div className={classnames('table-with-sticky-header table-with-side-filter', className)} style={style}>
    {children}
  </div>
);
