import { HOME_BREADCRUMB_LISTING } from './common';

export const ZONE = 'ZONE';
export const SET_ZONES = 'SET_ZONES';
export const ADD_ZONE = 'ADD_ZONE';
export const ZONE_FETCHED = 'ZONE_FETCHED';
export const ZONE_UPDATED = 'ZONE_UPDATED';
export const ZONE_DELETED = 'ZONE_DELETED';
export const AUTOCOMPLETE_ZONES = 'AUTOCOMPLETE_ZONES';

export const title = 'Zoness';
export const singularTitle = 'Zone';
export const moduleName = 'zones';
export const baseUrl = '/zones';
export const redirectUrl = '/zones';
export const listingDataKey = 'zones';
export const itemDataKey = 'zone';

export const zoneConstants = {
  item: ZONE,
  setItems: SET_ZONES,
  addItem: ADD_ZONE,
  setItem: ZONE_FETCHED,
  updateItem: ZONE_UPDATED,
  deleteItem: ZONE_DELETED,
  autocompleteItems: AUTOCOMPLETE_ZONES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Zones';
export const TargetBaseUrl = 'zones';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'description', 'zonable_type', 'zonable_codes', 'states_code_and_label_hash', 'countries_code_and_label_hash'],
};
