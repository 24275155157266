export const VISIT = 'VISIT';
export const SET_VISITS = 'SET_VISITS';
export const ADD_VISIT = 'ADD_VISIT';
export const VISIT_FETCHED = 'VISIT_FETCHED';
export const VISIT_UPDATED = 'VISIT_UPDATED';
export const VISIT_DELETED = 'VISIT_DELETED';
export const SET_VISITS_MODULE_NAME = 'SET_VISITS_MODULE_NAME';
export const SET_VISITABLE = 'SET_VISITABLE';
export const LOAD_MORE_VISITS = 'LOAD_MORE_VISITS';
export const TOGGLE_POST_VISIT_STATE = 'TOGGLE_POST_VISIT_STATE';
export const TOGGLE_FETCH_VISITS_STATE = 'TOGGLE_FETCH_VISITS_STATE';
export const RESET_VISITS = 'RESET_VISITS';

export const title = 'Visits';
export const singularTitle = 'Visit';
export const moduleName = 'visits';
export const listingDataKey = 'visits';
export const itemDataKey = 'visit';
export const permissionKey = moduleName;

export const visitConstants = {
  item: VISIT,
  setItems: SET_VISITS,
  addItem: ADD_VISIT,
  setItem: VISIT_FETCHED,
  updateItem: VISIT_UPDATED,
  deleteItem: VISIT_DELETED,
  title,
  singularTitle,
  moduleName,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
