import { getTenantLocalDateTime } from '@src/helpers/common_helper';
import { draft, confirm, processing } from '../../../../constants/orders';
import { TODAY, TILL_DATE } from '../../../../constants/common';

export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'today_orders', label: 'Today\'s Orders', applyFilters: true, filterSelection: { date_gteq: getTenantLocalDateTime('', false).startOf('day'), date_lteq: getTenantLocalDateTime('', false).endOf('day') }, timePeriod: TODAY,
    },
    {
      key: 'draft_orders', label: 'Draft Orders', applyFilters: true, filterSelection: { state_in: [draft] }, timePeriod: TILL_DATE,
    },
    {
      key: 'confirm_orders', label: 'Confirm Orders', applyFilters: true, filterSelection: { state_in: [confirm] }, timePeriod: TILL_DATE,
    },
    {
      key: 'open_orders', label: 'Open Orders', applyFilters: true, filterSelection: { state_in: [draft, confirm, processing] }, timePeriod: TILL_DATE,
    },
    // {
    //   key: 'partially_invoiced_orders', label: 'Partially Invoiced Orders', applyFilters: true, filterSelection: { partially_invoiced: true }, timePeriod: TILL_DATE,
    // },
  ],
};
