export const ACCOUNTING_SUB_ACCOUNT = 'ACCOUNTING_SUB_ACCOUNT';
export const SET_ACCOUNTING_SUB_ACCOUNTS = 'SET_ACCOUNTING_SUB_ACCOUNTS';
export const ADD_ACCOUNTING_SUB_ACCOUNT = 'ADD_ACCOUNTING_SUB_ACCOUNT';
export const ACCOUNTING_SUB_ACCOUNT_FETCHED = 'ACCOUNTING_SUB_ACCOUNT_FETCHED';
export const ACCOUNTING_SUB_ACCOUNT_UPDATED = 'ACCOUNTING_SUB_ACCOUNT_UPDATED';
export const ACCOUNTING_SUB_ACCOUNT_DELETED = 'ACCOUNTING_SUB_ACCOUNT_DELETED';
export const PUSH_ACCOUNTING_SUB_ACCOUNT_PATH = 'PUSH_ACCOUNTING_SUB_ACCOUNT_PATH';
export const POP_ACCOUNTING_SUB_ACCOUNT_PATH = 'POP_ACCOUNTING_SUB_ACCOUNT_PATH';
export const RESET_ACCOUNTING_SUB_ACCOUNTS = 'RESET_ACCOUNTING_SUB_ACCOUNTS';

export const OPEN_SUB_ACCOUNT_FORM_MODAL = 'OPEN_SUB_ACCOUNT_FORM_MODAL';

export const Title = 'Sub Accounts';
