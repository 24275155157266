import { concat } from 'lodash';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  ADD_CUSTOMER_CONTACT, CUSTOMER_CONTACT_UPDATED, CUSTOMER_CONTACT_DELETED, SET_CUSTOMER_CONTACTS,
  CUSTOMER_CONTACT_FETCHED,
} from '@constants/customer_contacts';
import {
  setItems, fetchItem, deleteItem, updateItem,
} from '@reducers/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 8,
  sort_by: '',
  sort_order: '',
  search_params: {
    name_cont: '',
  },
};

export default function customerContacts(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_CONTACTS: return setItems(state, action);
    case CUSTOMER_CONTACT_FETCHED: return fetchItem(state, action);
    case ADD_CUSTOMER_CONTACT: {
      let { items } = state;

      if (items?.length > 0) {
        items = concat(...items, action.item);
      } else {
        items = [action.item];
      }
      return {
        ...state,
        items,
        current_page: 1,
        total_count: state?.total_count + 1,
        isError: false,
        errors: {},
        isFetching: false,
      };
    }
    case CUSTOMER_CONTACT_UPDATED: return updateItem(state, action);
    case CUSTOMER_CONTACT_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
