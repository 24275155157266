import keys from 'lodash/keys';
import { QUICK_ADD_IS_USED_FOR } from '@component/common/quick_add_module/quick_add_common_helper';
import { closeQuickAddModuleModal, openShowAfterQuickAddSave } from '@component/common/quick_add_module/helper';
// WHAT:  opens quick show page of the payment recently added through quick add
// WHY:  have to open quick show so that user can update the status of the recently added voucher
const openShowAfterQuickAdd = (store) => (next) => (action) => {
  const { recon_entries } = store.getState();
  const dontCloseModal = recon_entries.isActive && (action.type === 'ADD_PAYMENT');

  if (dontCloseModal) {
    openShowAfterQuickAddSave({ identifier: 'SHOW_PAYMENT', id: action.item.id, title: action.item.number });
  }

  const result = next(action);
  return result;
};

// WHAT:  closes quick add modal after user save the voucher
// WHY:  helps us to avoid writing closemodal function in individual voucher actions
const closeQuickAddModal = (store) => (next) => (action) => {
  const { quick_add_module } = store.getState();
  const { identifiers } = quick_add_module;

  if (keys(QUICK_ADD_IS_USED_FOR).includes(action?.type) && identifiers.includes(action?.type)) {
    closeQuickAddModuleModal({ identifier: action.type });
  }

  const result = next(action);
  return result;
};

const quickAddModuleMiddleware = [openShowAfterQuickAdd, closeQuickAddModal];

export default quickAddModuleMiddleware;
