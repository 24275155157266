import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  ADD_CREDIT_NOTE_INVOICE, CREDIT_NOTE_INVOICE_UPDATED, defaultSearchParams,
} from '../constants/credit_note_invoices';
import { addItem, updateItem } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
};

const creditNoteInvoices = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CREDIT_NOTE_INVOICE: return addItem(state, action);
    case CREDIT_NOTE_INVOICE_UPDATED: return updateItem(state, action);
    default:
      return state;
  }
};

export default creditNoteInvoices;
