// ZONES HAVE BEEN MADE UNAMENABLE, WHICH MEANS EDIT, NEW
// AND DELETE FUNCTIONALITIES HAVE BEEN REMOVED.
// TODO: REMOVE UNUSED REDUCERS.

import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_ZONES, ADD_ZONE, ZONE_FETCHED, ZONE_UPDATED, ZONE_DELETED,
  AUTOCOMPLETE_ZONES, defaultSearchParams, moduleName,
} from '@constants/zones';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function zones(state = initialState, action) {
  switch (action.type) {
    case SET_ZONES: return setItems(state, action);
    case ADD_ZONE: return addItem(state, action);
    case ZONE_FETCHED: return fetchItem(state, action);
    case ZONE_UPDATED: return updateItem(state, action);
    case ZONE_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_ZONES: return autocomplete(state, action);
    default:
      return state;
  }
}
