import { HOME_BREADCRUMB_LISTING } from './common';

export const FETCH_STATISTICS = 'FETCH_STATISTICS';
export const SET_STATISTICS = 'SET_STATISTICS';
export const FETCHING_STATISTICS = 'FETCHING_STATISTICS';

export const title = 'Statistics';
export const singularTitle = 'Statistic';
export const moduleName = 'statistics';
export const baseUrl = '/statistics';
export const redirectUrl = '/statistics';
export const listingDataKey = 'statistics';
export const itemDataKey = 'statistic';

export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title, target: baseUrl, active: true }],
);
