import React, { Fragment } from 'react';

interface DragablePositionCellProps {
  idx?: number;
  position?: number | string;
  showCursorGrab?: boolean;
  parentElem?: any;
  parentElemExtraClasses?: string;
  parentElemCustomStyle?: any;
  parentElem2?: any;
  parentElem2ExtraClasses?: string;
  parentElem2CustomStyle?: any;
}

const DragablePositionCell = ({
  idx, position, showCursorGrab, parentElem, parentElemExtraClasses, parentElemCustomStyle,
  parentElem2, parentElem2ExtraClasses, parentElem2CustomStyle,
}: DragablePositionCellProps) => {
  let attr = {};
  if (parentElem2 !== Fragment) {
    attr = { className: parentElem2ExtraClasses, style: parentElem2CustomStyle };
  }
  // parentElem is the parent and parentElem2 is the child of parentElem.
  const ParentCustomElem = parentElem;
  const ParentCustomElem2 = parentElem2;

  return (
    <ParentCustomElem className={`sort-handle col-md-half text-center ${parentElemExtraClasses}`} style={parentElemCustomStyle}>
      <ParentCustomElem2 {...attr}>
        {
          showCursorGrab
            ? (
              <div className="cursor-grab">
                <i className="fa fa-ellipsis-v left" />
                <i className="fa fa-ellipsis-v right" />
              </div>
            )
            : null
        }
        <span className="item-number" data-cy={`serial-number-${idx}`}>{position}</span>
      </ParentCustomElem2>
    </ParentCustomElem>
  );
};

DragablePositionCell.defaultProps = {
  idx: '',
  position: '',
  showCursorGrab: true,
  parentElem: 'div',
  parentElemExtraClasses: '',
  parentElemCustomStyle: {},
  parentElem2: Fragment,
  parentElem2ExtraClasses: '',
  parentElem2CustomStyle: {},
};

export default DragablePositionCell;
