import React, { Component, CSSProperties } from 'react';
import { filter, isEmpty, debounce } from 'lodash';
import Select from 'react-select';
import { getNoResultText } from '@helpers/dms_select_helper';
import { openQuickAddModuleModal, getNoResultsText, getModuleNameForQuickAdd } from './quick_add_module/helper';
import { PC } from './permission_container';

interface DMSSelectProps {
  id: string;
  multi?: boolean;
  placeholder?: string;
  name?: string;
  value?: any;
  onChange?: Function;
  onInputChange?: Function;
  setIdentifierInStockTransferForm?: Function,
  valueKey?: string;
  labelKey?: string;
  isLoading?: boolean;
  filterOptions?: boolean;
  options?: any[];
  disabled?: boolean;
  clearable?: boolean;
  tabIndex?: string | number;
  showCustomNoResultsText?: boolean;
  noResultsText?: string;
  identifier?: string;
  formInitialValues?: any;
  menuPlacement?: string;
  isFixed?: boolean;
  style?: CSSProperties;
  handleOnBlur?: Function;
  promptTextCreator?: Function;
  autoComplete?: string;
  backspaceRemoves?: boolean;
  removeSelected?: boolean;
  closeOnSelect?: boolean;
  renderOptionWithCheckbox?: boolean;
  handleOnClose?: Function;
  handleOnFocus?: Function;
  selectRef?: any
  optionComponent?: any;
  dataTestID?: string
  valueComponent?: any;
  className?: string;
  moduleName?: string;
  autoFocus?: boolean;
}

interface DMSSelectStates {
  text: string;
}

function emptyFunction() {
  // do nothing.
}

const menuRenderer = (params) => {
  const {
    valueKey, labelKey, options, selectValue, focusOption, focusedOption,
    valueArray,
  } = params;
  if (options && options.length > 0) {
    return (
      <>
        {
          options.map((option) => {
            const isOption = filter(valueArray, { [valueKey]: option[valueKey] });
            const isChecked = !isEmpty(isOption);
            return (
              <div
                key={option[valueKey]}
                onClick={() => selectValue(option)}
                onMouseOver={() => focusOption(option)}
                className={`Select-option ${focusedOption && (focusedOption.value === option[valueKey]) ? 'is-focused' : ''}`}
                role="option"
              >
                <input type="checkbox" checked={isChecked} style={{ cursor: 'pointer' }} />
                &nbsp;
                {option[labelKey]}
              </div>
            );
          })
        }
      </>
    );
  }
  return false;
};

class DMSSelect extends Component<DMSSelectProps, DMSSelectStates> {
  public static defaultProps: Partial<DMSSelectProps> = {
    multi: false,
    placeholder: 'Select...',
    value: '',
    onInputChange: emptyFunction,
    onChange: emptyFunction,
    setIdentifierInStockTransferForm: emptyFunction,
    valueKey: undefined,
    labelKey: undefined,
    isLoading: false,
    filterOptions: true,
    options: [],
    disabled: false,
    clearable: true,
    tabIndex: '',
    showCustomNoResultsText: false,
    noResultsText: '',
    identifier: '',
    formInitialValues: {},
    menuPlacement: 'bottom',
    isFixed: true,
    style: {},
    handleOnClose: emptyFunction,
    handleOnBlur: emptyFunction,
    promptTextCreator: null,
    autoComplete: 'off',
    backspaceRemoves: true,
    removeSelected: true,
    closeOnSelect: true,
    renderOptionWithCheckbox: false,
    dataTestID: '',
    className: '',
    moduleName: '',
    autoFocus: false,
  };

  handleInputDebounce: any;

  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };

    this.handleInputDebounce = debounce(this.onInputChange, 300);
  }

  onInputChange = (input) => {
    const { onInputChange, setIdentifierInStockTransferForm, name } = this.props;
    setIdentifierInStockTransferForm(name); // Only applies for stock transfer form.
    onInputChange(input);
    this.setState({ text: input });
  }

  handleOnClick = () => {
    const { identifier, id, formInitialValues } = this.props;
    const { text } = this.state;
    openQuickAddModuleModal({ identifier, text, formInitialValues });
    document.getElementById(id).blur();
  }

  resetNoResultText = () => {
    const { text } = this.state;
    const { handleOnBlur } = this.props;
    handleOnBlur(text);
    this.setState({ text: '' });
  }

  onChangeHandler = (selected) => {
    const { onChange, clearable } = this.props;
    if (!clearable) {
      if (selected) {
        onChange(selected);
      }
    } else {
      onChange(selected);
    }
  }

  render() {
    const {
      id, multi, placeholder, name, value, valueKey, labelKey, isLoading,
      filterOptions, options, disabled, autoFocus,
      clearable, tabIndex, noResultsText, showCustomNoResultsText, identifier,
      menuPlacement, isFixed, style, className,
      promptTextCreator, autoComplete, backspaceRemoves, removeSelected,
      closeOnSelect, renderOptionWithCheckbox, valueComponent,
      optionComponent, selectRef, handleOnClose, handleOnFocus, dataTestID,
      moduleName: selectedFieldModuleName,
    } = this.props;

    const { text } = this.state;
    const noResultsLabel = getNoResultsText({ identifier, noResultsText });

    const moduleName = selectedFieldModuleName || getModuleNameForQuickAdd({ identifier });
    let noResultText = getNoResultText({
      moduleName,
      value: text,
      isLoading,
      labelKey,
      items: options,
      showCustomNoResultsText,
      noResultsText: noResultsLabel,
      handleNoResultsText: () => this.handleOnClick(),
    });

    if (moduleName) {
      noResultText = (
        <PC permissionKey={moduleName}>
          <div>
            {noResultText}
          </div>
        </PC>
      );
    }

    return (

      <div data-cy={id} className="select-box-container" data-testid={dataTestID}>
        <Select
          className={className}
          id={id}
          multi={multi}
          placeholder={placeholder}
          autoComplete={autoComplete}
          name={name}
          value={value}
          onChange={this.onChangeHandler}
          onInputChange={(input) => this.handleInputDebounce(input)}
          valueKey={valueKey}
          labelKey={labelKey}
          isLoading={isLoading}
          filterOptions={filterOptions}
          options={options}
          optionComponent={optionComponent}
          valueComponent={valueComponent}
          disabled={disabled}
          clearable={clearable}
          tabIndex={tabIndex}
          onBlur={this.resetNoResultText}
          noResultsText={noResultText}
          menuPlacement={menuPlacement}
          menuPosition={isFixed}
          style={style}
          backspaceRemoves={backspaceRemoves}
          removeSelected={removeSelected}
          closeOnSelect={closeOnSelect}
          menuRenderer={(multi && renderOptionWithCheckbox) ? menuRenderer : undefined}
          {...(promptTextCreator ? { promptTextCreator } : {})}
          ref={selectRef}
          onClose={handleOnClose}
          onFocus={handleOnFocus}
          autoFocus={autoFocus}
        />
      </div>
    );
  }
}

// REF https://stackoverflow.com/questions/51526461/how-to-use-react-forwardref-in-a-class-based-component/52076337#52076337
export const DMSSelectWithRef = React.forwardRef((props: DMSSelectProps, ref) => (
  <DMSSelect selectRef={ref} {...props} />
));

export default DMSSelect;
