import { call, put, takeLatest } from 'redux-saga/effects';
import {
  setItems, setItem, addItem, updateItem, deleteItem,
} from '@actions/common';
import {
  fetchInventories, fetchInventory, saveInventory, updateInventory, deleteInventory,
  autoCompleteInventory, autocompleteInventoryBatches, fetchInventoryBatchAvailableQuantity,
  bulkCreateVoucherItemInventoryBatches,
} from '@api/inventories';

import {
  FETCH_INVENTORIES, FETCH_INVENTORY, CREATE_INVENTORY, UPDATE_INVENTORY, DELETE_INVENTORY,
  AUTO_COMPLETE_INVENTORY, AUTOCOMPLETE_INVENTORY_BATCHES, BULK_CREATE_VOUCHER_ITEM_INVENTORY_BATCHES,
  FETCH_INVENTORY_BATCH_AVAILABLE_QUANTITY,
} from '@constants/inventories';
import { getActionTypes } from '@actions/action_helpers';
import { ADD_INVENTORY_BATCH, moduleName as inventoryBatchesModule } from '@constants/inventory_batches';
import { ADD_INVENTORY_PIECE, moduleName as inventoryPiecesModule } from '@constants/inventory_pieces';
import { updateAndCloseQuickAddModule } from '@component/common/quick_add_module/helper';

function* fetchInventoriesFunc(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, moduleName,
  } = action;
  const { selected_variant, dont_merge_search_params, timePeriod } = action;

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['listingDataKey'] });
  const { listingDataKey } = moduleTypes;

  try {
    const response = yield call(fetchInventories({
      moduleName,
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
    }));

    const { meta } = response?.data || {};
    const items = response?.data?.[listingDataKey] || [];
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for fetching inventory.
function* fetchInventoryFunc(action) {
  const { moduleName, id, redirectUrl } = action;

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['itemDataKey'] });
  const { itemDataKey } = moduleTypes || {};

  try {
    const response = yield call(fetchInventory({ id, redirectUrl, moduleName }));
    const item = response?.data?.[itemDataKey] || {};
    yield put(setItem({ moduleName, item }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for creating inventory.
function* createInventoryFunc(action) {
  const {
    moduleName, redirectUrl, data,
  } = action || {};

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['itemDataKey'] });
  const { itemDataKey } = moduleTypes;

  try {
    const response = yield call(saveInventory({
      redirectUrl, moduleName, data,
    }));
    // inventory response data.
    const inventoryItem = response?.data?.[itemDataKey] || {};

    yield put(addItem({ moduleName, item: inventoryItem }));

    if (moduleName === inventoryBatchesModule) {
      const inventoryBatchId = inventoryItem?.inventory_manufacturer_batch?.id || '';
      const inventoryBatchName = inventoryItem?.inventory_manufacturer_batch?.number || '';
      const params = { inventory_batch_id: inventoryBatchId || '', inventory_batch_number: inventoryBatchName || '' };
      updateAndCloseQuickAddModule({ identifier: ADD_INVENTORY_BATCH, params });
    }

    if (moduleName === inventoryPiecesModule) {
      const inventoryPieceId = inventoryItem?.inventory_manufacturer_piece?.id || '';
      const inventoryPieceNumber = inventoryItem?.inventory_manufacturer_piece?.number || '';
      const params = { inventory_piece_id: inventoryPieceId || '', inventory_piece_number: inventoryPieceNumber || '' };
      updateAndCloseQuickAddModule({ identifier: ADD_INVENTORY_PIECE, params });
    }
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for updating inventory.
function* updateInventoryFunc(action) {
  const {
    moduleName, id, redirectUrl, data,
  } = action || {};

  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['itemDataKey'] });
  const { itemDataKey } = moduleTypes;

  try {
    const response = yield call(updateInventory({
      id, redirectUrl, moduleName, data,
    }));
    const inventoryItem = response?.data?.[itemDataKey] || {};

    yield put(updateItem({ moduleName, item: inventoryItem }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

// Saga generator function for deleting inventory.
function* deleteInventoryFunc(action) {
  const {
    moduleName, id, redirectUrl,
  } = action;

  try {
    const response = yield call(deleteInventory({
      id, redirectUrl, moduleName,
    }));
    yield put(deleteItem({ moduleName, id }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autoCompleteInventoryFunc(action) {
  const {
    moduleName, searchParams, redirectUrl, productId,
  } = action;

  try {
    const response = yield call(autoCompleteInventory({
      moduleName, searchParams, redirectUrl, productId,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompleteInventoryBatchesFunc(action) {
  const {
    url, voucherModuleName, voucherId, search_params, per_page, page,
  } = action;

  try {
    const response = yield call(autocompleteInventoryBatches({
      url, voucherId, voucherModuleName, search_params, per_page, page,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

const updateBulkCreateVoucherItem = ({
  voucherId, voucherItemId, reponseItemData, itemKey,
}) => ({
  type: `BULK_CREATE_INVENTORY_BATCHES_FOR_${itemKey}_ITEMS`,
  voucherId,
  voucherItemId,
  voucherItemData: reponseItemData,
});

// Saga generator function for cerate bulk voucher item branches.
function* bulkCreateVoucherItemInventoryBatchesFunc(action) {
  const {
    url, voucherModuleName, voucherId, voucherItemId, data, voucherItemRetrieveApiDataKey,
  } = action || {};

  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['item'] });
  const { item: itemKey } = moduleTypes;

  try {
    const response = yield call(bulkCreateVoucherItemInventoryBatches({
      url, voucherModuleName, voucherId, data,
    }));

    const reponseItemData = response?.data?.[voucherItemRetrieveApiDataKey] || {};

    yield put(updateBulkCreateVoucherItem({
      voucherId, voucherItemId, reponseItemData, itemKey,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchInventoryBatchAvailableQuantityFunc(action) {
  const {
    url, voucherModuleName, voucherId, voucherItemId, productId, warehouseId,
  } = action || {};

  try {
    const response = yield call(fetchInventoryBatchAvailableQuantity({
      url, voucherModuleName, voucherId, voucherItemId, productId, warehouseId,
    }));

    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* inventoriesWatcher() {
  yield takeLatest(FETCH_INVENTORIES, fetchInventoriesFunc);
  yield takeLatest(FETCH_INVENTORY, fetchInventoryFunc);
  yield takeLatest(CREATE_INVENTORY, createInventoryFunc);
  yield takeLatest(UPDATE_INVENTORY, updateInventoryFunc);
  yield takeLatest(DELETE_INVENTORY, deleteInventoryFunc);
  yield takeLatest(AUTO_COMPLETE_INVENTORY, autoCompleteInventoryFunc);
  yield takeLatest(AUTOCOMPLETE_INVENTORY_BATCHES, autocompleteInventoryBatchesFunc);
  yield takeLatest(BULK_CREATE_VOUCHER_ITEM_INVENTORY_BATCHES, bulkCreateVoucherItemInventoryBatchesFunc);
  yield takeLatest(FETCH_INVENTORY_BATCH_AVAILABLE_QUANTITY, fetchInventoryBatchAvailableQuantityFunc);
}
