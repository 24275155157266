import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';

export function fetchApiToValidateMobileNUmber(args) {
  const { mobile } = args;

  return () => axios({
    url: `/account/password/generate_otp?account[mobile]=${mobile}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/account/password', '');
      throw error.response;
    });
}

export function submitOtpNumber(args) {
  const { mobile, reset_password_otp: resetPasswordOtp } = args;

  return () => axios({
    url: '/account/password/authenticate_otp',
    method: 'POST',
    data: { account: { mobile, reset_password_otp: resetPasswordOtp } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/account/password', '');
      throw error.response;
    });
}

export function updatePassword(args) {
  const { reset_password_token, password, password_confirmation } = args;

  return () => axios({
    url: '/account/password',
    method: 'PUT',
    data: { account: { reset_password_token, password, password_confirmation } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/account/password', '');
      throw error.response;
    });
}
