import axios from '@utils/axios';
import { getActionTypes } from '@actions/action_helpers';
import { handleErrors } from '@utils/handleErrors';

export const bulkUpdate = ({ moduleName, resourceIds, data }) => {
  const moduleTypes = getActionTypes({
    moduleName,
    actionTypes: ['baseUrl', 'redirectUrl', 'item'],
  });
  const { baseUrl, redirectUrl, item } = moduleTypes;

  return () => axios({
    url: `${baseUrl}/bulk_update`,
    method: 'PUT',
    data: { resource_ids: resourceIds, resource_data: data },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
};
