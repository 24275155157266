import { last, isEmpty } from 'lodash';
import store from '@store/store';
import * as types from '@constants/quick_add_module';
import {
  moduleName as customers, ADD_NEW_CUSTOMER, ADD_CUSTOMER, RESET_CUSTOMER_ERRORS,
  FORM_INFORMATION_TITLE as CUSTOMER_FORM_INFORMATION_TITLE,
} from '@constants/customers';
import {
  moduleName as brands, ADD_NEW_BRAND, ADD_BRAND, RESET_BRAND_ERRORS,
  FORM_INFORMATION_TITLE as BRAND_FORM_INFORMATION_TITLE,
} from '@constants/brands';
import {
  moduleName as productCategories, ADD_NEW_PRODUCT_CATEGORY, ADD_PRODUCT_CATEGORY,
  RESET_PRODUCT_CATEGORY_ERRORS, FORM_INFORMATION_TITLE as PRODUCT_CATEGORY_FORM_INFORMATION_TITLE,
} from '@constants/product_categories';
import {
  moduleName as invoiceTerms, ADD_NEW_INVOICE_TERM, ADD_INVOICE_TERM, RESET_INVOICE_TERM_ERRORS,
  FORM_INFORMATION_TITLE as INVOICE_TERM_FORM_INFORMATION_TITLE,
} from '@constants/invoice_terms';
import {
  moduleName as accounts, ADD_NEW_CHART_OF_ACCOUNT, ADD_CHART_OF_ACCOUNT,
  RESET_CHART_OF_ACCOUNT_ERRORS, FORM_INFORMATION_TITLE as CHART_OF_ACCOUNT_FORM_INFORMATION_TITLE,
} from '@constants/accounting/accounts';
import {
  moduleName as products, ADD_NEW_PRODUCT, ADD_PRODUCT, RESET_PRODUCT_ERRORS,
  FORM_INFORMATION_TITLE as PRODUCT_FORM_INFORMATION_TITLE,
} from '@constants/products';
import {
  moduleName as customerRatings, ADD_NEW_CUSTOMER_RATING, ADD_CUSTOMER_RATING,
  RESET_CUSTOMER_RATING_ERRORS, FORM_INFORMATION_TITLE as CUSTOMER_RATING_FORM_INFORMATION_TITLE,
} from '@constants/customer_ratings';
import {
  moduleName as customerCategories, ADD_NEW_CUSTOMER_CATEGORY, ADD_CUSTOMER_CATEGORY,
  RESET_CUSTOMER_CATEGORY_ERRORS,
  FORM_INFORMATION_TITLE as CUSTOMER_CATEGORY_FORM_INFORMATION_TITLE,
} from '@constants/customer_categories';
import {
  moduleName as documentCategories, ADD_NEW_DOCUMENT_CATEGORY, ADD_DOCUMENT_CATEGORY,
  RESET_DOCUMENT_CATEGORY_ERRORS,
  FORM_INFORMATION_TITLE as DOCUMENT_CATEGORY_FORM_INFORMATION_TITLE,
} from '@constants/document_categories';
import {
  ADD_JOURNAL_ENTRY,
  FORM_INFORMATION_TITLE as JOURNAL_ENTRY_FORM_INFORMATION_TITLE, RESET_JOURNAL_ENTRY_ERRORS,
} from '@constants/accounting/entries';

// SETUP-SECTION quick add
import {
  ADD_PAYMENT, SHOW_PAYMENT,
  FORM_INFORMATION_TITLE as PAYMENT_FORM_INFORMATION_TITLE, RESET_PAYMENT_ERRORS,
} from '@constants/payments';
// !SETUP-SECTION quick add

import {
  ADD_INVENTORY_PIECE, ADD_NEW_INVENTORY_PIECE, moduleName as inventoryPieces,
  FORM_INFORMATION_TITLE as INVENTORY_PIECE_FORM_INFORMATION_TITLE, RESET_INVENTORY_PIECE_ERRORS,
} from '@constants/inventory_pieces';

import {
  ADD_INVENTORY_BATCH, ADD_NEW_INVENTORY_BATCH, moduleName as inventoryBatches,
  FORM_INFORMATION_TITLE as INVENTORY_BATCH_FORM_INFORMATION_TITLE, RESET_INVENTORY_BATCH_ERRORS,
} from '@constants/inventory_batches';

import {
  ADD_CREDIT_NOTE,
  FORM_INFORMATION_TITLE as CREDIT_NOTE_FORM_INFORMATION_TITLE, RESET_CREDIT_NOTE_ERRORS,
} from '@constants/credit_notes';
import {
  ADD_INVOICE,
  FORM_INFORMATION_TITLE as INVOICE_FORM_INFORMATION_TITLE, RESET_INVOICE_ERRORS,
} from '@constants/invoices';
import {
  ADD_PURCHASE_INVOICE,
  FORM_INFORMATION_TITLE as PURCHASE_INVOICE_FORM_INFORMATION_TITLE, RESET_PURCHASE_INVOICE_ERRORS,
} from '@constants/purchase_invoices';
import {
  moduleName as branches, ADD_BRANCH, ADD_NEW_BRANCH,
  FORM_INFORMATION_TITLE as BRANCH_FORM_INFORMATION_TITLE, RESET_BRANCH_ERRORS,
} from '@constants/branches';
import {
  moduleName as warehouses, ADD_WAREHOUSE, ADD_NEW_WAREHOUSE,
  FORM_INFORMATION_TITLE as WAREHOUSE_FORM_INFORMATION_TITLE, RESET_WAREHOUSE_ERRORS,
} from '@constants/warehouses';

import {
  moduleName as beatRoutes, ADD_BEAT_ROUTE, ADD_NEW_BEAT_ROUTE,
  FORM_INFORMATION_TITLE as BEAT_ROUTE_FORM_INFORMATION_TITLE, RESET_BEAT_ROUTE_ERRORS,
} from '@constants/beat_routes';

import {
  moduleName as creditReasons, ADD_CREDIT_REASON, RESET_CREDIT_REASON_ERRORS,
  FORM_INFORMATION_TITLE as CREDIT_REASON_FORM_INFORMATION_TITLE, ADD_NEW_CREDIT_REASON,
} from '@constants/credit_reasons';

import {
  moduleName as suppliers, ADD_SUPPLIER, RESET_SUPPLIER_ERRORS,
  FORM_INFORMATION_TITLE as SUPPLIER_FORM_INFORMATION_TITLE, ADD_NEW_SUPPLIER,
} from '@constants/suppliers';

import {
  moduleName as orderTerms, ADD_ORDER_TERM, RESET_ORDER_TERM_ERRORS,
  FORM_INFORMATION_TITLE as ORDER_TERM_FORM_INFORMATION_TITLE, ADD_NEW_ORDER_TERM,
} from '@constants/order_terms';

import {
  moduleName as taxes, ADD_TAX, RESET_TAX_ERRORS,
  FORM_INFORMATION_TITLE as TAX_FORM_INFORMATION_TITLE, ADD_NEW_TAX,
} from '@constants/taxes';

import {
  moduleName as bank, ADD_BANK, RESET_BANK_ERRORS,
  FORM_INFORMATION_TITLE as BANK_FORM_INFORMATION_TITLE, ADD_NEW_BANK,
} from '@constants/banks';

import {
  moduleName as inventoryIdentification, ADD_INVENTORY_IDENTIFICATION,
  RESET_INVENTORY_IDENTIFICATION_ERRORS,
  FORM_INFORMATION_TITLE as INVENTORY_IDENTIFICATION_FORM_INFORMATION_TITLE,
  ADD_NEW_INVENTORY_IDENTIFICATION,
} from '@constants/inventory_identifications';

import {
  moduleName as taxCategory, ADD_TAX_CATEGORY, RESET_TAX_CATEGORY_ERRORS,
  FORM_INFORMATION_TITLE as TAX_CATEGORY_FORM_INFORMATION_TITLE, ADD_NEW_TAX_CATEGORY,
} from '@constants/tax_categories';

export const QUICK_SHOW_IS_USED_FOR = { SHOW_PAYMENT };

export const getQuickAddModuleParams = ({ identifier, text, id }) => {
  switch (identifier) {
    case ADD_CUSTOMER:
      return { customer_name: text, customer_id: id };
    case ADD_BRAND:
      return { brand_name: text, brand_id: id };
    case ADD_PRODUCT_CATEGORY:
      return { product_category_name: text, product_category_id: id };
    case ADD_INVOICE_TERM:
      return { invoice_term_name: text, invoice_term_id: id };
    case ADD_CHART_OF_ACCOUNT:
      return { account_name: text, account_id: id };
    case ADD_PRODUCT:
      return { product_name: text, product_id: id };
    case ADD_CUSTOMER_RATING:
      return { customer_rating_name: text, customer_rating_id: id };
    case ADD_CUSTOMER_CATEGORY:
      return { customer_category_name: text, customer_category_id: id };
    case ADD_DOCUMENT_CATEGORY:
      return { document_category_name: text, document_category_id: id };
    case ADD_WAREHOUSE:
      return { warehouse_name: text, warehouse_id: id };
    case ADD_BRANCH:
      return { branch_name: text, branch_id: id };
    case ADD_BEAT_ROUTE:
      return { beat_route_name: text, beat_route_id: id };
    case ADD_CREDIT_REASON:
      return { credit_reason_name: text, credit_reason_id: id };
    case ADD_SUPPLIER:
      return { supplier_name: text, supplier_id: id };
    case ADD_ORDER_TERM:
      return { order_term_name: text, order_term_id: id };
    case ADD_TAX:
      return { tax_name: text, tax_id: id };
    case ADD_BANK:
      return { bank_account_name: text, bank_account_id: id };
    case ADD_INVENTORY_IDENTIFICATION:
      return { inventory_identification_name: text, inventory_identification_id: id };
    case ADD_TAX_CATEGORY:
      return { tax_category_name: text, tax_category_id: id };
    case ADD_INVENTORY_PIECE:
      return { inventory_piece_id: id, inventory_piece_number: text };
    case ADD_INVENTORY_BATCH:
      return { inventory_batch_id: id, inventory_batch_number: text };
    default:
      return {};
  }
};

export const getNoResultsText = ({ identifier, noResultsText }) => {
  if (noResultsText) return noResultsText;
  switch (identifier) {
    case ADD_CUSTOMER:
      return ADD_NEW_CUSTOMER;
    case ADD_BRAND:
      return ADD_NEW_BRAND;
    case ADD_PRODUCT_CATEGORY:
      return ADD_NEW_PRODUCT_CATEGORY;
    case ADD_INVOICE_TERM:
      return ADD_NEW_INVOICE_TERM;
    case ADD_CHART_OF_ACCOUNT:
      return ADD_NEW_CHART_OF_ACCOUNT;
    case ADD_PRODUCT:
      return ADD_NEW_PRODUCT;
    case ADD_CUSTOMER_RATING:
      return ADD_NEW_CUSTOMER_RATING;
    case ADD_CUSTOMER_CATEGORY:
      return ADD_NEW_CUSTOMER_CATEGORY;
    case ADD_DOCUMENT_CATEGORY:
      return ADD_NEW_DOCUMENT_CATEGORY;
    case ADD_WAREHOUSE:
      return ADD_NEW_WAREHOUSE;
    case ADD_BRANCH:
      return ADD_NEW_BRANCH;
    case ADD_BEAT_ROUTE:
      return ADD_NEW_BEAT_ROUTE;
    case ADD_CREDIT_REASON:
      return ADD_NEW_CREDIT_REASON;
    case ADD_SUPPLIER:
      return ADD_NEW_SUPPLIER;
    case ADD_ORDER_TERM:
      return ADD_NEW_ORDER_TERM;
    case ADD_TAX:
      return ADD_NEW_TAX;
    case ADD_BANK:
      return ADD_NEW_BANK;
    case ADD_INVENTORY_IDENTIFICATION:
      return ADD_NEW_INVENTORY_IDENTIFICATION;
    case ADD_TAX_CATEGORY:
      return ADD_NEW_TAX_CATEGORY;
    case ADD_INVENTORY_PIECE:
      return ADD_NEW_INVENTORY_PIECE;
    case ADD_INVENTORY_BATCH:
      return ADD_NEW_INVENTORY_BATCH;
    default:
      return 'No result found.';
  }
};

export const getModuleNameForQuickAdd = ({ identifier }) => {
  switch (identifier) {
    case ADD_CUSTOMER:
      return customers;
    case ADD_BRAND:
      return brands;
    case ADD_PRODUCT_CATEGORY:
      return productCategories;
    case ADD_INVOICE_TERM:
      return invoiceTerms;
    case ADD_CHART_OF_ACCOUNT:
      return accounts;
    case ADD_PRODUCT:
      return products;
    case ADD_CUSTOMER_RATING:
      return customerRatings;
    case ADD_CUSTOMER_CATEGORY:
      return customerCategories;
    case ADD_DOCUMENT_CATEGORY:
      return documentCategories;
    case ADD_WAREHOUSE:
      return warehouses;
    case ADD_BRANCH:
      return branches;
    case ADD_BEAT_ROUTE:
      return beatRoutes;
    case ADD_CREDIT_REASON:
      return creditReasons;
    case ADD_SUPPLIER:
      return suppliers;
    case ADD_ORDER_TERM:
      return orderTerms;
    case ADD_TAX:
      return taxes;
    case ADD_BANK:
      return bank;
    case ADD_INVENTORY_IDENTIFICATION:
      return inventoryIdentification;
    case ADD_TAX_CATEGORY:
      return taxCategory;
    case ADD_INVENTORY_PIECE:
      return inventoryPieces;
    case ADD_INVENTORY_BATCH:
      return inventoryBatches;
    default:
      return '';
  }
};

export const resetFormErrors = ({ identifier }) => {
  switch (identifier) {
    case ADD_CUSTOMER:
      store.dispatch({ type: RESET_CUSTOMER_ERRORS });
      break;
    case ADD_BRAND:
      store.dispatch({ type: RESET_BRAND_ERRORS });
      break;
    case ADD_PRODUCT_CATEGORY:
      store.dispatch({ type: RESET_PRODUCT_CATEGORY_ERRORS });
      break;
    case ADD_INVOICE_TERM:
      store.dispatch({ type: RESET_INVOICE_TERM_ERRORS });
      break;
    case ADD_CHART_OF_ACCOUNT:
      store.dispatch({ type: RESET_CHART_OF_ACCOUNT_ERRORS });
      break;
    case ADD_PRODUCT:
      store.dispatch({ type: RESET_PRODUCT_ERRORS });
      break;
    case ADD_CUSTOMER_RATING:
      store.dispatch({ type: RESET_CUSTOMER_RATING_ERRORS });
      break;
    case ADD_CUSTOMER_CATEGORY:
      store.dispatch({ type: RESET_CUSTOMER_CATEGORY_ERRORS });
      break;
    case ADD_DOCUMENT_CATEGORY:
      store.dispatch({ type: RESET_DOCUMENT_CATEGORY_ERRORS });
      break;
    case ADD_JOURNAL_ENTRY:
      store.dispatch({ type: RESET_JOURNAL_ENTRY_ERRORS });
      break;

    // SETUP-SECTION quick add
    case ADD_PAYMENT:
      store.dispatch({ type: RESET_PAYMENT_ERRORS });
      break;
    // SETUP-SECTION quick add
    case ADD_INVENTORY_PIECE:
      store.dispatch({ type: RESET_INVENTORY_PIECE_ERRORS });
      break;

    case ADD_INVENTORY_BATCH:
      store.dispatch({ type: RESET_INVENTORY_BATCH_ERRORS });
      break;

    case ADD_CREDIT_NOTE:
      store.dispatch({ type: RESET_CREDIT_NOTE_ERRORS });
      break;
    case ADD_INVOICE:
      store.dispatch({ type: RESET_INVOICE_ERRORS });
      break;
    case ADD_PURCHASE_INVOICE:
      store.dispatch({ type: RESET_PURCHASE_INVOICE_ERRORS });
      break;
    case ADD_WAREHOUSE:
      store.dispatch({ type: RESET_WAREHOUSE_ERRORS });
      break;
    case ADD_BRANCH:
      store.dispatch({ type: RESET_BRANCH_ERRORS });
      break;
    case ADD_BEAT_ROUTE:
      store.dispatch({ type: RESET_BEAT_ROUTE_ERRORS });
      break;
    case ADD_CREDIT_REASON:
      store.dispatch({ type: RESET_CREDIT_REASON_ERRORS });
      break;
    case ADD_SUPPLIER:
      store.dispatch({ type: RESET_SUPPLIER_ERRORS });
      break;
    case ADD_ORDER_TERM:
      store.dispatch({ type: RESET_ORDER_TERM_ERRORS });
      break;
    case ADD_TAX:
      store.dispatch({ type: RESET_TAX_ERRORS });
      break;
    case ADD_BANK:
      store.dispatch({ type: RESET_BANK_ERRORS });
      break;
    case ADD_INVENTORY_IDENTIFICATION:
      store.dispatch({ type: RESET_INVENTORY_IDENTIFICATION_ERRORS });
      break;
    case ADD_TAX_CATEGORY:
      store.dispatch({ type: RESET_TAX_CATEGORY_ERRORS });
      break;
    default:
      break;
  }
};

export const resetQuickAddModuleParams = ({ identifier }) => {
  switch (identifier) {
    case ADD_CUSTOMER:
      return { customer_id: '', customer_name: '' };
    case ADD_BRAND:
      return { brand_id: '', brand_name: '' };
    case ADD_PRODUCT_CATEGORY:
      return { product_category_id: '', product_category_name: '' };
    case ADD_INVOICE_TERM:
      return { invoice_term_id: '', invoice_term_name: '' };
    case ADD_CHART_OF_ACCOUNT:
      return { account_id: '', account_name: '' };
    case ADD_PRODUCT:
      return { product_id: '', product_name: '' };
    case ADD_CUSTOMER_RATING:
      return { customer_rating_id: '', customer_rating_name: '' };
    case ADD_CUSTOMER_CATEGORY:
      return { customer_category_id: '', customer_category_name: '' };
    case ADD_DOCUMENT_CATEGORY:
      return { document_category_id: '', document_category_name: '' };
    case ADD_JOURNAL_ENTRY:
      return { journal_entry_id: '', journal_entry_name: '' };
    // SETUP-SECTION quick add
    case ADD_PAYMENT:
      return { payment_id: '', payment_name: '' };
    // !SETUP-SECTION quick add
    case ADD_INVENTORY_PIECE:
      return { inventory_piece_id: '', inventory_piece_number: '' };

    case ADD_INVENTORY_BATCH:
      return { inventory_batch_id: '', inventory_batch_number: '' };

    case ADD_CREDIT_NOTE:
      return { credit_note_id: '', credit_note_name: '' };
    case ADD_INVOICE:
      return { invoice_id: '', invoice_name: '' };
    case ADD_PURCHASE_INVOICE:
      return { purchase_invoice_id: '', purchase_invoice_name: '' };
    case ADD_WAREHOUSE:
      return { warehouse_id: '', warehouse_name: '' };
    case ADD_BRANCH:
      return { branch_id: '', branch_name: '' };
    case ADD_BEAT_ROUTE:
      return { beat_route_id: '', beat_route_name: '' };
    case ADD_CREDIT_REASON:
      return { credit_reason_id: '', credit_reason_name: '' };
    case ADD_SUPPLIER:
      return { supplier_id: '', supplier_name: '' };
    case ADD_ORDER_TERM:
      return { order_term_id: '', order_term_name: '' };
    case ADD_TAX:
      return { tax_id: '', tax_name: '' };
    case ADD_BANK:
      return { bank_account_id: '', bank_account_name: '' };
    case ADD_INVENTORY_IDENTIFICATION:
      return { inventory_identification_id: '', inventory_identification_name: '' };
    case ADD_TAX_CATEGORY:
      return { tax_category_id: '', tax_category_name: '' };
    default:
      return {};
  }
};

export const setTitle = ({ identifier }) => {
  switch (identifier) {
    case ADD_CUSTOMER:
      return CUSTOMER_FORM_INFORMATION_TITLE;
    case ADD_BRAND:
      return BRAND_FORM_INFORMATION_TITLE;
    case ADD_PRODUCT_CATEGORY:
      return PRODUCT_CATEGORY_FORM_INFORMATION_TITLE;
    case ADD_INVOICE_TERM:
      return INVOICE_TERM_FORM_INFORMATION_TITLE;
    case ADD_CHART_OF_ACCOUNT:
      return CHART_OF_ACCOUNT_FORM_INFORMATION_TITLE;
    case ADD_PRODUCT:
      return PRODUCT_FORM_INFORMATION_TITLE;
    case ADD_CUSTOMER_RATING:
      return CUSTOMER_RATING_FORM_INFORMATION_TITLE;
    case ADD_CUSTOMER_CATEGORY:
      return CUSTOMER_CATEGORY_FORM_INFORMATION_TITLE;
    case ADD_DOCUMENT_CATEGORY:
      return DOCUMENT_CATEGORY_FORM_INFORMATION_TITLE;
    case ADD_JOURNAL_ENTRY:
      return JOURNAL_ENTRY_FORM_INFORMATION_TITLE;

    // SETUP-SECTION quick add
    case SHOW_PAYMENT:
    case ADD_PAYMENT:
      return PAYMENT_FORM_INFORMATION_TITLE;
    // !SETUP-SECTION quick add
    case ADD_INVENTORY_PIECE:
      return INVENTORY_PIECE_FORM_INFORMATION_TITLE;

    case ADD_INVENTORY_BATCH:
      return INVENTORY_BATCH_FORM_INFORMATION_TITLE;

    case ADD_CREDIT_NOTE:
      return CREDIT_NOTE_FORM_INFORMATION_TITLE;
    case ADD_INVOICE:
      return INVOICE_FORM_INFORMATION_TITLE;
    case ADD_PURCHASE_INVOICE:
      return PURCHASE_INVOICE_FORM_INFORMATION_TITLE;
    case ADD_WAREHOUSE:
      return WAREHOUSE_FORM_INFORMATION_TITLE;
    case ADD_BRANCH:
      return BRANCH_FORM_INFORMATION_TITLE;
    case ADD_BEAT_ROUTE:
      return BEAT_ROUTE_FORM_INFORMATION_TITLE;
    case ADD_CREDIT_REASON:
      return CREDIT_REASON_FORM_INFORMATION_TITLE;
    case ADD_SUPPLIER:
      return SUPPLIER_FORM_INFORMATION_TITLE;
    case ADD_ORDER_TERM:
      return ORDER_TERM_FORM_INFORMATION_TITLE;
    case ADD_TAX:
      return TAX_FORM_INFORMATION_TITLE;
    case ADD_BANK:
      return BANK_FORM_INFORMATION_TITLE;
    case ADD_INVENTORY_IDENTIFICATION:
      return INVENTORY_IDENTIFICATION_FORM_INFORMATION_TITLE;
    case ADD_TAX_CATEGORY:
      return TAX_CATEGORY_FORM_INFORMATION_TITLE;
    default:
      return '';
  }
};

export const retrieveQuickAddModuleData = () => {
  const state = store.getState();
  const quickAddModule = (state?.quick_add_module) || {};
  return quickAddModule;
};

export const getLastOpenedQuickAddModalIdentifier = () => {
  const quickAddModule = retrieveQuickAddModuleData();
  const identifiers = (quickAddModule?.identifiers) || [];
  const identifier = last(identifiers);
  return identifier;
};

export const updateQuickAddModule = ({ params }) => {
  store.dispatch({
    type: types.UPDATE_QUICK_ADD_MODULE_PARAMS, params,
  });
};

export const closeQuickAddModuleModal = ({ identifier }) => {
  // const params = getQuickAddModuleParams({ identifier, text: '' });
  const recentIdentifier = getLastOpenedQuickAddModalIdentifier();
  const recentOpenModalIdentifier = identifier || recentIdentifier || '';
  if (recentOpenModalIdentifier) {
    const params = resetQuickAddModuleParams({ identifier: recentOpenModalIdentifier });
    updateQuickAddModule({ params });
    resetFormErrors({ identifier: recentOpenModalIdentifier });
  }

  store.dispatch({
    type: types.CLOSE_QUICK_ADD_MODULE_MODAL, identifier: recentOpenModalIdentifier,
  });
};

export const openQuickAddModuleModal = ({
  identifier, text = '', id = '', formInitialValues,
}) => {
  const params = getQuickAddModuleParams({ identifier, text, id });
  store.dispatch({
    type: types.OPEN_QUICK_ADD_MODULE_MODAL,
    identifier,
    params,
    formInitialValues,
  });
};

export const openShowAfterQuickAddSave = ({ identifier, id = '', title = '' }) => {
  store.dispatch({
    type: types.OPEN_SHOW_AFTER_QUICK_ADD_SAVE,
    identifier,
    match: { params: { id }, title },
  });
};

export const updateAndCloseQuickAddModule = ({ identifier, params }) => {
  const recentIdentifier = getLastOpenedQuickAddModalIdentifier();
  const recentOpenModalIdentifier = identifier || recentIdentifier || '';
  if (recentOpenModalIdentifier) {
    resetFormErrors({ identifier: recentOpenModalIdentifier });
    store.dispatch({
      type: types.UPDATE_PARAMS_AND_CLOSE_QUICK_ADD_MODULE, params, identifier,
    });
  }
};

export const resetQuickAddModule = () => {
  store.dispatch({
    type: types.RESET_QUICK_ADD_MODULE_PARAMS,
  });
};

export const getNameFromQuickAddModuleParams = ({ currentState, isFormChanged, nameLabel }) => {
  const quickAddModule = retrieveQuickAddModuleData();
  const { params } = quickAddModule;
  const value = (params?.[nameLabel]) || '';
  let { name } = currentState;
  if (value && isEmpty(name) && !isFormChanged) {
    name = value;
  }
  return name;
};

export const getIdFromQuickAddModuleParams = ({
  currentState, idKey, nameKey, callFunc, isPropsEqual = false,
}) => {
  // Get quick add module params from quick add module.
  const quickAddModule = retrieveQuickAddModuleData();
  const { params } = quickAddModule;

  // Get quick add module id from quick add module.
  const quickAddModuleId = (params?.[idKey]) || '';

  // Get quick add module name from quick add module.
  const quickAddModuleName = (params?.[nameKey]) || '';

  // Get id from current state.
  let id = (currentState?.[idKey]) || '';

  if (quickAddModuleId && (isEmpty(id) || (id !== quickAddModuleId))) {
    id = quickAddModuleId;
    updateQuickAddModule({ params: { [idKey]: '', [nameKey]: '' } });
  }

  if (quickAddModuleId && currentState?.id && isPropsEqual) {
    id = quickAddModuleId;
    updateQuickAddModule({ params: { [idKey]: '', [nameKey]: '' } });
  }

  if (quickAddModuleId && quickAddModuleName) {
    callFunc(quickAddModuleId, quickAddModuleName);
  }

  return id;
};
