import React from 'react';
import { object, func } from 'prop-types';
import { Link } from 'react-router-dom';
import lowerCase from 'lodash/lowerCase';
import PrintFormatDropdownListing from '@component/common/download_files/print_format_dropdown_listing';
import DownloadFile from '@component/common/download_files/download_file_circle_btn';
import {
  open, accounted, received, failed, moduleName, deposited, PAYMENT, redirectUrl,
} from '@constants/payments';
import {
  getPermissionsHash, getBusinessPermissions, isLocalStorageColumnsAndDefaultColumnsEqual,
} from '@helpers/common_helper';
import { CUSTOMER, SUPPLIER } from '@constants/common';
import {
  CanUpdate, CanDelete, CommentsSectionToggleBtn, CanDeleteItem,
} from '@helpers/authorization_helper';
import { CategoryEnum } from '@utils/GAUtils';
import { GetColLink } from '@helpers/index_screen_helper';
import ListingActionDropdown from '@component/common/listing_action_dropdown';

export const getInvoiceId = ({ location }) => {
  if (!location) return '';
  const searchArray = location.search.split('?');
  const [, searchString] = searchArray;
  if (searchArray && (searchString && searchString.indexOf('invoice_id') === 0)) {
    const invoiceArray = searchString.split('=');
    const [, invoiceID] = invoiceArray;
    return Number(invoiceID);
  }
  return '';
};

export const payment_state_tag = (state) => {
  if (state === open) {
    return (
      <span className="label label-danger">Open</span>
    );
  } if (state === accounted) {
    return (
      <span className="label label-success">Accounted</span>
    );
  } if (state === received) {
    return (
      <span className="label label-warning">Received</span>
    );
  } if (state === deposited) {
    return (
      <span className="label label-info">Deposited</span>
    );
  } if (state === failed) {
    return (
      <span className="label label-default">Failed</span>
    );
  }
  return null;
};

export const idColumn = {
  key: 'id', label: 'Checkbox', is_checkbox: true, callFunc: false, default: true, show: true, disabled: false,
};
export const numberColumn = {
  key: 'number', label: 'Number', callFunc: true, sort_by: 'number', can_sort: true, default: true, show: true, disabled: false,
};
export const voucherBookColumn = {
  key: 'voucher_book', label: 'Voucher Book', callFunc: true, sort_by: 'voucher_book_name', can_sort: false, default: false, show: false, disabled: false,
};
export const dateFormattedColumn = {
  key: 'date_formatted', label: 'Date', callFunc: false, sort_by: 'date', can_sort: true, default: false, show: true, disabled: false,
};
export const partyType = {
  key: 'party_type', label: 'Party Type', callFunc: false, sort_by: 'party_type', can_sort: false, default: false, show: false, disabled: false,
};
export const customerNameColumn = {
  key: 'customer_name', label: 'Party', callFunc: true, sort_by: 'customer_name', can_sort: false, default: false, show: true, thClassName: 'word-break', disabled: false,
};
export const userNameColumn = {
  key: 'user_name', label: 'User', callFunc: true, sort_by: 'user_name', can_sort: true, default: false, show: true, disabled: false,
};
export const modeColumn = {
  key: 'mode', label: 'Mode', callFunc: false, sort_by: 'payment_mode_name', can_sort: true, default: false, show: true, thClassName: 'word-break col-md-1', disabled: false,
};
export const instrumentColumn = {
  key: 'instrument', label: 'Instrument', callFunc: true, sort_by: 'instrument', can_sort: false, default: false, show: false, thClassName: 'word-break', disabled: false,
};
export const amountLocalizedColumn = {
  key: 'amount_localized', label: 'Amount', callFunc: false, sort_by: 'amount', can_sort: true, default: false, show: true, disabled: false,
};
export const unallocatedAmountLocalizedColumn = {
  key: 'unallocated_amount_localized', label: 'Unalloc. Amt.', callFunc: false, sort_by: 'unallocated_amount', can_sort: false, default: false, show: true, disabled: false,
};
export const discountLocalizedColumn = {
  key: 'discount_localized', label: 'Discount', callFunc: false, sort_by: 'discount', can_sort: true, default: false, show: true, disabled: false, thClassName: 'col-md-1',
};
export const stateColumn = {
  key: 'state', label: 'Status', callFunc: true, sort_by: 'state', can_sort: true, default: true, show: true, disabled: false,
};
export const actionColumn = {
  key: 'action', label: 'Action', callFunc: true, sort_by: '', can_sort: false, default: true, show: true, thClassName: 'helo', disabled: false,
};

export const defaultColumns = [
  {
    ...idColumn,
    thClassName: 'fix-col',
    tdClassName: 'fix-td',
    thStyle: {
      textAlign: 'left', width: 36, minWidth: 36, top: 0, left: 0, zIndex: 4,
    },
    tdStyle: {
      textAlign: 'left', width: 36, minWidth: 36, background: 'inherit', left: 0,
    },
  }, {
    ...numberColumn,
    thClassName: 'position-sticky',
    tdClassName: 'position-sticky',
    thStyle: {
      textAlign: 'left', width: 130, minWidth: 130, top: 0, left: 36, zIndex: 4,
    },
    tdStyle: {
      textAlign: 'left', width: 130, minWidth: 130, background: 'inherit', left: 36,
    },
  }, {
    ...voucherBookColumn,
    thStyle: {
      textAlign: 'left', width: 115, minWidth: 115, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 115, minWidth: 115, background: 'inherit',
    },
  }, {
    ...dateFormattedColumn,
    thStyle: {
      textAlign: 'left', width: 105, minWidth: 105, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 105, minWidth: 105, background: 'inherit',
    },
  },
  {
    ...partyType,
    thStyle: {
      textAlign: 'left', width: 100, minWidth: 100, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 100, minWidth: 100,
    },
  },
  {
    ...customerNameColumn,
    thStyle: {
      textAlign: 'left', width: 250, minWidth: 250, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 250, minWidth: 250,
    },
  }, {
    ...userNameColumn,
    thStyle: {
      textAlign: 'left', width: 130, minWidth: 130, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 130, minWidth: 130, background: 'inherit',
    },
  },
  {
    ...modeColumn,
    thStyle: {
      textAlign: 'left', width: 100, minWidth: 100, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 100, minWidth: 100, background: 'inherit',
    },
  }, {
    ...instrumentColumn,
    thStyle: {
      textAlign: 'left', width: 130, minWidth: 130, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 130, minWidth: 130, background: 'inherit',
    },
  }, {
    ...amountLocalizedColumn,
    thStyle: {
      textAlign: 'left', width: 100, minWidth: 100, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 100, minWidth: 100, background: 'inherit',
    },
  }, {
    ...discountLocalizedColumn,
    thStyle: {
      textAlign: 'left', width: 80, minWidth: 80, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 80, minWidth: 80, background: 'inherit',
    },
  }, {
    ...unallocatedAmountLocalizedColumn,
    thStyle: {
      textAlign: 'left', width: 120, minWidth: 120, top: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 120, minWidth: 120, background: 'inherit',
    },
  }, {
    ...stateColumn,
    thClassName: 'fix-col fix-right-col',
    tdClassName: 'fix-td fix-right-col',
    thStyle: {
      textAlign: 'left', width: 116, minWidth: 116, top: 0, position: 'sticky !important', right: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 116, minWidth: 116, position: 'sticky !important', right: 0, background: 'inherit',
    },
  }, {
    ...actionColumn,
    thStyle: {
      textAlign: 'left', width: 0, minWidth: 0, top: 0, position: 'sticky', right: 0,
    },
    tdStyle: {
      textAlign: 'left', width: 0, minWidth: 0, position: 'sticky', right: 0,
    },
  },
];

isLocalStorageColumnsAndDefaultColumnsEqual({ moduleName, defaultColumns });

export const payment_actions = (item, openModal) => {
  const { can_update, can_destroy } = getPermissionsHash({ required_object: item, required_permissions: ['can_update', 'can_destroy'] });
  const { updateable_with_detail, destroyable_with_detail } = getBusinessPermissions({ required_object: item, required_permissions: ['updateable_with_detail', 'destroyable_with_detail'] });
  return (
    <>
      <CanUpdate
        can_update={can_update}
        path={`/payments/${item.id}/edit`}
        editing_what={CategoryEnum.PAYMENT}
        updateable_with_detail={updateable_with_detail}
      />
      <CanDelete
        can_destroy={can_destroy}
        openModal={openModal}
        deleting_what={CategoryEnum.PAYMENT}
        destroyable_with_detail={destroyable_with_detail}
        modal_identifier_args={{ isModalOpen: true, modalIdentifier: 'DELETE_ITEM', delete_item: item }}
      />
    </>
  );
};

export const fetchTableData = ({
  args, openModal, openCommentsModal, setActiveCommentable,
}) => {
  const { key, item } = args;
  const {
    can_show, can_download_pdf: canDownloadPdf, can_update, can_destroy,
  } = getPermissionsHash({ required_object: item, required_permissions: ['can_show', 'can_download_pdf', 'can_update', 'can_destroy'] });
  const { updateable_with_detail, destroyable_with_detail } = getBusinessPermissions({ required_object: item, required_permissions: ['updateable_with_detail', 'destroyable_with_detail'] });

  if (key === 'number') {
    if (!can_show) return item.number;
    return (
      <Link to={`/payments/${item.id}`}>{item.number}</Link>
    );
  }
  if (key === 'voucher_book') {
    return (
      <Link
        to={`/voucher_books/${item?.voucher_book_id}`}
      >
        {item?.voucher_book_name || ''}
      </Link>
    );
  }
  if (key === 'customer_name') {
    if (item?.party_type === CUSTOMER) {
      return (
        <>
          <GetColLink item={item} propName={key} moduleName="customers" />
          <br />
          {item?.address_line_1 || ''}
        </>
      );
    }
    if (item?.party_type === SUPPLIER) {
      return (
        <>
          <GetColLink item={item} propName={key} moduleName="suppliers" />
          <br />
          {item?.address_line_1 || ''}
        </>
      );
    }
    return null;
  }
  if (key === 'user_name') {
    return (<GetColLink item={item} propName={key} moduleName="users" />);
  } if (key === 'instrument') {
    return (
      <>
        {item.instrument_no || ''}
        {(item.instrument_no) ? <br /> : null}
        {item.instrument_date_formatted}
      </>
    );
  } if (key === 'state') {
    return (
      payment_state_tag(item.state)
    );
  } if (key === 'action') {
    return (
      <div className="listing-item-actions">
        <DownloadFile
          url="/payments/download_pdf"
          params={{ id: item?.id, download: true }}
          className="btn btn-sm btn-success action-btn"
          redirectUrl={redirectUrl}
          identifier={PAYMENT}
          canDownloadFile={canDownloadPdf}
          logEventForListingPage
        />
        <CanUpdate
          can_update={can_update}
          path={`/payments/${item?.id}/edit`}
          editing_what={CategoryEnum.PAYMENT}
          updateable_with_detail={updateable_with_detail}
        />
        <CommentsSectionToggleBtn
          togglingWhat={CategoryEnum.PAYMENT}
          modal_identifier_args={{
            commentsUrl: `/payments/${item?.id}`,
            commentable: item.number,
            commentableId: item.id,
          }}
          toggle={openCommentsModal}
          commentsCount={item.number_of_comments}
          setActiveCommentable={setActiveCommentable}
        />
        <ListingActionDropdown>
          <CanDeleteItem
            can_destroy={can_destroy}
            openModal={openModal}
            deleting_what={CategoryEnum.PAYMENT}
            destroyable_with_detail={destroyable_with_detail}
            modal_identifier_args={{ isModalOpen: true, modalIdentifier: 'DELETE_ITEM', delete_item: item }}
            btn_classes="listing-action-menu-item"
          />
          <PrintFormatDropdownListing
            url="/payments/download_pdf"
            params={{ id: item?.id, download: true }}
            redirectUrl={redirectUrl}
            identifier={PAYMENT}
            moduleName={moduleName}
          />
        </ListingActionDropdown>
      </div>
    );
  }

  return item[key];
};

fetchTableData.propTypes = {
  args: object.isRequired,
  openModal: func.isRequired,
  downloadPdf: func,
  openCommentsModal: func.isRequired,
  setActiveCommentable: func.isRequired,
};

fetchTableData.defaultProps = {
  downloadPdf: () => { },
};

export const getInstrumentNoLabel = ({ paymentModeName, instrumentNoFieldDetails }) => {
  let labelSuffix = 'No.';
  if (lowerCase(paymentModeName) === 'upi') {
    labelSuffix = 'Id.';
  }

  if (instrumentNoFieldDetails) {
    return `${instrumentNoFieldDetails?.label || paymentModeName} ${labelSuffix}`;
  }

  return `Instrument ${labelSuffix}`;
};
