import { PIECE, BATCH } from '@constants/units';
import { HOME_BREADCRUMB_LISTING } from './common';

export const PRODUCT_CATEGORY = 'PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY';
export const PRODUCT_CATEGORY_FETCHED = 'PRODUCT_CATEGORY_FETCHED';
export const PRODUCT_CATEGORY_UPDATED = 'PRODUCT_CATEGORY_UPDATED';
export const PRODUCT_CATEGORY_DELETED = 'PRODUCT_CATEGORY_DELETED';
export const RESET_PRODUCT_CATEGORY_ERRORS = 'RESET_PRODUCT_CATEGORY_ERRORS';
export const AUTOCOMPLETE_PRODUCT_CATEGORIES = 'AUTOCOMPLETE_PRODUCT_CATEGORIES';

export const FORM_INFORMATION_TITLE = 'PRODUCT CATEGORY INFORMATION';
export const ADD_NEW_PRODUCT_CATEGORY = 'Add New Product category';

export const title = 'Product Categories';
export const singularTitle = 'Product Category';
export const moduleName = 'product_categories';
export const baseUrl = '/product_categories';
export const redirectUrl = '/product_categories';
export const listingDataKey = 'product_categories';
export const itemDataKey = 'product_category';

export const productCategoryConstants = {
  item: PRODUCT_CATEGORY,
  setItems: SET_PRODUCT_CATEGORIES,
  addItem: ADD_PRODUCT_CATEGORY,
  setItem: PRODUCT_CATEGORY_FETCHED,
  updateItem: PRODUCT_CATEGORY_UPDATED,
  deleteItem: PRODUCT_CATEGORY_DELETED,
  resetErrors: RESET_PRODUCT_CATEGORY_ERRORS,
  autocompleteItems: AUTOCOMPLETE_PRODUCT_CATEGORIES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const INVENTORY_IDENTIFICATION_OPTIONS = [
  { label: 'Piece', value: PIECE }, { label: 'Batch', value: BATCH },
];

export const Title = 'Product Categories';
export const TargetBaseUrl = 'product_categories';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'parent_id'],
};
