import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';
import {
  setNewItem, setItems, addItem, setItem, updateItem, deleteItem,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { fetchReturns, autocompleteReturnsAction } from '@api/returns';
import { moduleName, FETCH_RETURNS, AUTOCOMPLETE_RETURNS } from '@constants/returns';
import { GENERATE_CREDIT_NOTE_FROM_RETURN } from '@constants/credit_notes';

// Get Returns List (dispatch, getState)
export function fetchNewReturns(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { invoice_id } = args;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    params: {
      invoice_id,
    },
  })
    .then((response) => {
      const return_object = response.data.return;
      dispatch(setNewItem({ moduleName, params: { return_object } }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save Return.
export function saveReturn(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      return: data,
    },
  })
    .then((response) => {
      dispatch(addItem({ moduleName, item: response.data.return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get Return.
export function fetchReturn(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      dispatch(setItem({ moduleName, item: response.data.return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Generate credit note using return.
export function fetchReturnCreditNote(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/credit_note`,
    method: 'get',
  })
    .then((response) => {
      const creditNoteObjItem = response?.data?.credit_note || {};
      dispatch({ type: GENERATE_CREDIT_NOTE_FROM_RETURN, item: { ...creditNoteObjItem, id: '' } });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update Return.
export function updateReturn(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      return: data,
    },
  })
    .then((response) => {
      dispatch(updateItem({ moduleName, item: response.data.return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete Return.
export function deleteReturn(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateReturnState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: {
      state,
    },
  })
    .then((response) => {
      dispatch(updateItem({ moduleName, item: response.data.return }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

function* fetchReturnsSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchReturns({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { returns, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: returns,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* autocompleteReturns(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(autocompleteReturnsAction({ search_params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* returnWatcher() {
  yield takeLatest(FETCH_RETURNS, fetchReturnsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_RETURNS, autocompleteReturns);
}
