import React, { Component, ReactNode } from 'react';
import Sortable from 'sortablejs';
import {
  toInteger, find, filter, map,
} from 'lodash';

// Constants
const TBODY = 'tbody';
const DIV = 'div';
interface ItemsSortableProps {
  nestedItemsAttributes: any;
  nestedFormName: string;
  updateFormComponentState: (value: any[], name: string, errorName: any[]) => void;
  children?: ReactNode;
  parentElem?: string;
}

class ItemsSortable extends Component<ItemsSortableProps> {
  private sortableList: { destroy: () => void; };

  static defaultProps: { parentElem: string; };

  componentWillUnmount() {
    if (this.sortableList) {
      this.sortableList.destroy();
    }
  }

  updatePositions = (e: any) => {
    const { nestedItemsAttributes, nestedFormName, updateFormComponentState } = this.props;

    let deletedNestedItems = filter(
      nestedItemsAttributes, { _destroy: true },
    );
    deletedNestedItems = map(deletedNestedItems, (item: any) => ({ ...item, position: '' }));

    const nonDeletedNestedItems = filter(nestedItemsAttributes, { _destroy: false });

    const toList: HTMLCollection = e.to.children;
    // Elements in the list have positions.
    // Here we get the position;
    const newPositions = Array.from(toList).map((el) => toInteger(el?.id || '') || '');

    const newNestedItemsAttributes = [];
    newPositions.forEach((position, idx) => {
      const nestedItem = find(nonDeletedNestedItems, { position });
      newNestedItemsAttributes[idx] = { ...nestedItem, position: idx + 1 };
    });

    updateFormComponentState(
      [...newNestedItemsAttributes, ...deletedNestedItems], nestedFormName, [],
    );
  }

  sortableListRef = () => {
    const domEl = document.getElementById('item-options-sortable-list');
    if (!this.sortableList) {
      this.sortableList = Sortable.create(domEl, {
        handle: '.sort-handle',
        animation: 150,
        onEnd: this.updatePositions,
        dragClass: 'number-format-drag',
        chosenClass: 'number-format-drag',
        ghostClass: 'number-format-ghost',
      });
    }
  };

  component = {
    [TBODY]: 'tbody',
    [DIV]: 'div',
  };

  render() {
    const { children, parentElem } = this.props;
    const CustomElem = this.component?.[parentElem] || 'div';
    return (
      <CustomElem ref={this.sortableListRef} id="item-options-sortable-list">
        {children}
      </CustomElem>
    );
  }
}

ItemsSortable.defaultProps = {
  parentElem: 'div',
};

export default ItemsSortable;
