// We use dompurify to show tags(ie. <a></a>, <i></i> etc.) in dom. This data come from BE side.
// ex. GSTR1 report could not be downloaded for the time period as HSN codes for the below-mentioned products/services are invalid.
// You can visit the official website "here", to find a valid HSN code for your product/service.
// In above example 'here' is a anchor tag. Instead of showing url/<a href="..">here</a> we want to make this tag clickable.
export const DOMPurifyConfig = {
  ALLOWED_TAGS: ['b', 'a', 'p', 'br', 'ul', 'ol', 'li'],
  ALLOWED_ATTR: ['style', 'href', 'target', 'referrerpolicy'],
  // USE_PROFILES: { html: true },
  // NAMESPACE: 'http://www.w3.org/2000/svg',
  // FORBID_TAGS: ['style'],
  // FORBID_ATTR: ['style'],
  // ADD_TAGS: ['my-tag'],
  // ALLOW_ARIA_ATTR: false,
  // ALLOW_DATA_ATTR: false
};
