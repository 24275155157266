import { HOME_BREADCRUMB_LISTING } from './common';

export const REPORTS = 'REPORTS';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const YEARLY = 'yearly';

export const SET_REPORTS = 'SET_REPORTS';
export const REPORT_FETCHED = 'REPORT_FETCHED';
export const REPORT_DELETED = 'REPORT_DELETED';

export const STOCK = 'stock';

export const defaultSearchParams = {
  name_cont: '',
};

export const title = 'Reports';
export const singularTitle = 'Report';
export const moduleName = 'reports';
export const baseUrl = '/reports';
export const redirectUrl = '/reports';
export const listingDataKey = 'reports';
export const itemDataKey = 'report';

export const Title = 'Reports';
export const TargetBaseUrl = 'reports';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);

export const PERIOD = [
  { label: 'Daily', value: DAILY }, { label: 'Weekly', value: WEEKLY },
  { label: 'Monthly', value: MONTHLY }, { label: 'Quarterly', value: QUARTERLY },
  { label: 'Yearly', value: YEARLY },
];
export const DEFAULT_RANGE_PARTITIONS = { from: 0, to: '' };
export const DEFAULT_LIST_OF_RANGE_PARTITIONS = [
  { from: 0, to: 15 }, { from: 15, to: 30 }, { from: 30, to: 45 }, { from: 45, to: 60 },
  { from: 60, to: 90 }, { from: 90, to: 120 }];

export const GENERATE_REPORT = 'generate_report';
export const DOWNLOAD_REPORT = 'download_report';
export const AUTOCOMPLETE_USER = 'autocomplete_user';
export const AUTOCOMPLETE_CUSTOMER = 'autocomplete_customer';
export const AUTOCOMPLETE_CUSTOMER_CATEGORY = 'autocomplete_customer_category';
export const AUTOCOMPLETE_SALESMAN = 'autocomplete_salesman';
export const AUTOCOMPLETE_BRAND = 'autocomplete_brand';
export const AUTOCOMPLETE_BEAT_ROUTE = 'autocomplete_beat_route';
export const AUTOCOMPLETE_SUPPLIER = 'autocomplete_supplier';
export const AUTOCOMPLETE_PRODUCT_CATEGORY = 'autocomplete_product_category';

export const ACCOUNTING_REPORT = 'ACCOUNTING_REPORT';
export const ACCOUNTING_REPORT_BREADCRUMB_LISTING = 'ACCOUNTING_REPORT_BREADCRUMB_LISTING';
export const INCOME_STATEMENT = 'INCOME_STATEMENT';
export const BALANCE_SHEET = 'BALANCE_SHEET';
export const SET_INCOME_STATEMENT = 'SET_INCOME_STATEMENT';
export const SET_BALANCE_SHEET = 'SET_BALANCE_SHEET';
export const AUTOCOMPLETE_PRODUCT = 'autocomplete_product';
export const SAVE_CUSTOM_REPORT = 'save_custom_report';

export const INCOME_STATEMENT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Income Statement', target: '', active: true }]);
export const BALANCE_SHEET_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Balance Sheet', target: '', active: true }]);
export const REPORTS_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Reports', target: '', active: true }]);
export const ORDER_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Order Report', target: '', active: true }]);
export const INVOICE_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Invoice Report', target: '', active: true }]);
export const PURCHASE_INVOICE_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Purchase Invoice Report', target: '', active: true }]);
export const OUTSTANDING_BALANCE_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Outstanding Balance Report', target: '', active: true }]);
export const PAYMENT_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Payment Report', target: '', active: true }]);
export const GSTR1_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'GSTR1 Report', target: '', active: true }]);
export const GSTR3B_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'GST3B Report', target: '', active: true }]);
export const SALESMAN_PERFORMANCE_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Salesman Performance Report', target: '', active: true }]);
export const ACCOUNT_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Account Report', target: '', active: true }]);
export const SALESMAN_BRAND_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Salesman Brand Report', target: '', active: true }]);
export const CREDIT_NOTE_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Credit Note Report', target: '', active: true }]);
export const AGING_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Ageing Report', target: '', active: true }]);
export const CUSTOMER_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Customer Report', target: '', active: true }]);
export const RETURN_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Return Report', target: '', active: true }]);
export const CREDIT_LEEWAY_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Credit Leeway Report', target: '', active: true }]);
export const STOCK_REPORT_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: 'Stock Report', target: '', active: true }]);
