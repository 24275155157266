import { HOME_BREADCRUMB_LISTING } from './common';

export const DEBIT_REASON = 'DEBIT_REASON';
export const SET_DEBIT_REASONS = 'SET_DEBIT_REASONS';
export const ADD_DEBIT_REASON = 'ADD_DEBIT_REASON';
export const DEBIT_REASON_FETCHED = 'DEBIT_REASON_FETCHED';
export const DEBIT_REASON_UPDATED = 'DEBIT_REASON_UPDATED';
export const DEBIT_REASON_DELETED = 'DEBIT_REASON_DELETED';
export const DEBIT_REASON_EDIT = 'DEBIT_REASON_EDIT';
export const AUTOCOMPLETE_DEBIT_REASONS = 'AUTOCOMPLETE_DEBIT_REASONS';

export const title = 'Debit Reasons';
export const singularTitle = 'Debit Reason';
export const moduleName = 'debit_reasons';
export const baseUrl = '/debit_reasons';
export const redirectUrl = '/debit_reasons';
export const listingDataKey = 'debit_reasons';
export const itemDataKey = 'debit_reason';

export const debitReasonConstants = {
  item: DEBIT_REASON,
  setItems: SET_DEBIT_REASONS,
  addItem: ADD_DEBIT_REASON,
  setItem: DEBIT_REASON_FETCHED,
  updateItem: DEBIT_REASON_UPDATED,
  deleteItem: DEBIT_REASON_DELETED,
  autocompleteItems: AUTOCOMPLETE_DEBIT_REASONS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'credit_ledger_id', 'credit_ledger'],
};
