// Helpers
import { setLocalStorage } from '@helpers/common_helper';

// Constants
import { moduleName as orders } from '@constants/orders';
import { moduleName as invoices } from '@constants/invoices';
import { moduleName as payments } from '@constants/payments';
import { moduleName as returns } from '@constants/returns';
import { moduleName as creditNotes } from '@constants/credit_notes';
import { moduleName as customers } from '@constants/customers';
import { moduleName as purchaseInvoices } from '@constants/purchase_invoices';
import { moduleName as purchaseOrders } from '@constants/purchase_orders';
import { moduleName as purchaseReturns } from '@constants/purchase_returns';
import { moduleName as suppliers } from '@constants/suppliers';
import { moduleName as stocks } from '@constants/stocks';
import { moduleName as products } from '@constants/products';
import { moduleName as users } from '@constants/users';
import { stockFeedModuleName as stockFeed } from '@constants/feeds';
import { moduleName as productCategories } from '@constants/product_categories';

import { DEFAULT, getHeaderSelection } from '@component/common/headers/helpers/header_selection';
import { stockFeedIndexVariants } from './feed_header_helpers';
import { productIndexVariants, userIndexVariants } from './index_variants_helpers';
import { indexVariants as purOrderIndexVariants } from './purchase_orders_header_helpers';
import { indexVariants as purReturnIndexVariants } from './purchase_returns_header_helpers';
import { indexVariants as purInvoiceIndexVariants } from './purchase_invoices_header_helpers';
import { indexVariants as customerIndexVariants } from './customers_header_helpers';
import { indexVariants as paymentIndexVariants } from './payments_header_helpers';
import { indexVariants as returnIndexVariants } from './returns_header_helpers';
import { indexVariants as invoiceIndexVariants } from './invoices_header_helpers';
import { indexVariants as orderIndexVariants } from './orders_header_helpers';

// TODO set case name from appropriate module name form constant file.
export const getIndexVariants = (moduleName) => {
  const defaultSelection = getHeaderSelection({ moduleName, selectionKey: DEFAULT });
  switch (moduleName) {
    case orders: return { ...orderIndexVariants, LIST_VIEWS: [defaultSelection].concat(orderIndexVariants.LIST_VIEWS) };
    case invoices: return { ...invoiceIndexVariants, LIST_VIEWS: [defaultSelection].concat(invoiceIndexVariants.LIST_VIEWS) };
    case payments: return { ...paymentIndexVariants, LIST_VIEWS: [defaultSelection].concat(paymentIndexVariants.LIST_VIEWS) };
    case returns: return { ...returnIndexVariants, LIST_VIEWS: [defaultSelection].concat(returnIndexVariants.LIST_VIEWS) };
    case creditNotes: return { LIST_VIEWS: [defaultSelection] };
    case customers: return { ...customerIndexVariants, LIST_VIEWS: [defaultSelection].concat(customerIndexVariants.LIST_VIEWS) };
    case purchaseInvoices: return { ...purInvoiceIndexVariants, LIST_VIEWS: [defaultSelection].concat(purInvoiceIndexVariants.LIST_VIEWS) };
    case purchaseOrders: return { ...purOrderIndexVariants, LIST_VIEWS: [defaultSelection].concat(purOrderIndexVariants.LIST_VIEWS) };
    case purchaseReturns: return { ...purReturnIndexVariants, LIST_VIEWS: [defaultSelection].concat(purReturnIndexVariants.LIST_VIEWS) };
    case suppliers: return { LIST_VIEWS: [defaultSelection] };
    case stocks: return { LIST_VIEWS: [defaultSelection] };
    case stockFeed: return stockFeedIndexVariants;
    case products: return { ...productIndexVariants, LIST_VIEWS: [defaultSelection].concat(productIndexVariants.LIST_VIEWS) };
    case users: return { ...userIndexVariants, LIST_VIEWS: [defaultSelection].concat(userIndexVariants.LIST_VIEWS) };
    case productCategories: return { LIST_VIEWS: [defaultSelection] };
    default: return { key: '', label: '' };
  }
};

// FUNCTION   returns index_params key for a moduleName
const getModuleIndexParamsKey = (module) => (`${module}_index_params`);

// FUNCTION   sets index params and return curSelectedVariant and curSearchParams for a moduleName
//            in localStorage

// FUNCTION   set index_params in localStorage
export const setIndexRelatedParams = ({
  moduleName, search_params, selected_variant, otherAdvSearchConfig = null,
}) => {
  const data = {
    filter_params: search_params,
    selected_variant,
    otherAdvSearchConfig,
  };
  setLocalStorage({ key: getModuleIndexParamsKey(moduleName), data });
};
