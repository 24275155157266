import { CHANGE_PRICES_KEY, CHANGE_PRICES_URL, LAST_OPENED_COMMON_ACTION_SCREEN } from '../../../../containers/common_actions/common_actions_screen_helpers';
import { Prices } from '../../../../constants/common_constants';
import { setLocalStorage } from '../../../../helpers/common_helper';

// List of Index Variants for products.
export const productIndexVariants = {
  LIST_VIEWS: [
    {
      key: 'simple_and_parent_products', label: 'Simple and Parent Products', applyFilters: true, filterSelection: { parent_id_blank: true },
    },
    {
      key: 'active_products', label: 'Active Products', applyFilters: true, filterSelection: { active_eq: true },
    },
    {
      key: 'inactive_products', label: 'Inactive Products', applyFilters: true, filterSelection: { active_eq: 'false' },
    },
    {
      key: 'products_without_tax_category', label: 'Products without Taxes', applyFilters: true, filterSelection: { tax_category_id_null: true },
    },
    {
      key: 'products_without_hsn_code', label: 'Products without HSN/SAC', applyFilters: true, filterSelection: { hsn_code_blank: true },
    },
  ],
  PROCESS: [
    {
      key: CHANGE_PRICES_KEY,
      label: Prices,
      redirectUrl: CHANGE_PRICES_URL,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: Prices, key: CHANGE_PRICES_KEY, printTitle: Prices },
        });
      },
    },
  ],
};


// List of Index Variants for users.
export const userIndexVariants = {
  LIST_VIEWS: [
    {
      key: 'active_users', label: 'Active Users', applyFilters: true, filterSelection: { active_eq: true },
    },
  ],
};
