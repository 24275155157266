import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import axios, { fetchingRequest } from '../utils/axios';
import * as types from '../constants/recents';
import { handleErrors } from '../utils/handleErrors';

export function fetchRecents(args) {
  const {
    search_params, page, per_page, recordName = 'customer',
  } = args;
  const { module_name } = search_params;
  let searchParams = { ...search_params, [`${recordName}_id_eq`]: args[`${recordName}_id`] };
  if (module_name === 'credit_notes' || module_name === 'debit_notes') {
    searchParams = { ...search_params, party_type_eq: 'Customer', party_id_eq: args.customer_id };
  }

  const q = serializeSearchParams(searchParams);
  const page_params = serializePageParams(page, per_page);
  fetchingRequest(`${types.RECENT}_${types.moduleNamesAndResourceType[module_name]}`);

  return () => axios({
    url: `/${module_name}?${page_params}&${q}`,
    method: 'get',
    params: {
      trim: true,
    },
  })
    .then((response) => (response))
    .catch((error) => {
      handleErrors(error, `/${recordName}s/${`${recordName}_id`}`, types.RECENT);
      throw error.response;
    });
}
