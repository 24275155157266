import { moduleName as users } from './users';
import { moduleName as branches } from './branches';
import { moduleName as brands } from './brands';
import { moduleName as productCategories } from './product_categories';
import { stateCodes } from '../helpers/fetch_basic_info_api';

export const moduleName = 'dashboard';
export const DASHBOARD = 'DASHBOARD';
export const SET_DASHBOARD_RESPONSE = 'SET_DASHBOARD_RESPONSE';

export const requiredPrefetchingApiModuleNamesForDashboard = [
  users, stateCodes, branches, brands, productCategories,
];
