import axios from '@utils/axios';
import { E_INVOICE_CREDENTIAL } from '@constants/credentials/e_invoice';
import { E_WAY_BILL_CREDENTIAL } from '@constants/credentials/e_way_bill';
import { handleErrors } from '@utils/handleErrors';

export function fetchEInvoiceCredential() {
  return () => axios({
    url: '/in/e_invoice',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/e_invoice_credentials', E_INVOICE_CREDENTIAL);
      throw error.response;
    });
}

export function updateEInvoiceCredential({ in_gsp_credential_e_invoice }) {
  return () => axios({
    url: '/in/e_invoice',
    method: 'put',
    data: { in_gsp_credential_e_invoice },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/e_invoice_credentials', E_INVOICE_CREDENTIAL);
      throw error.response;
    });
}

export function fetchEWayBillCredential() {
  return () => axios({
    url: '/in/e_way_bill',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/e_way_bill_credentials', E_WAY_BILL_CREDENTIAL);
      throw error.response;
    });
}

export function updateEWayBillCredential({ in_gsp_credential_e_way_bill }) {
  return () => axios({
    url: '/in/e_way_bill',
    method: 'put',
    data: { in_gsp_credential_e_way_bill },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/e_way_bill_credentials', E_WAY_BILL_CREDENTIAL);
      throw error.response;
    });
}
