import { HOME_BREADCRUMB_LISTING } from './common';

export const ORGANIZATION = 'ORGANIZATION';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const ORGANIZATION_FETCHED = 'ORGANIZATION_FETCHED';
export const ORGANIZATION_UPDATED = 'ORGANIZATION_UPDATED';
export const ORGANIZATION_DELETED = 'ORGANIZATION_DELETED';
export const AUTOCOMPLETE_ORGANIZATIONS = 'AUTOCOMPLETE_ORGANIZATIONS';

export const title = 'Organizations';
export const singularTitle = 'Organization';
export const moduleName = 'organizations';
export const baseUrl = '/tenant';
export const redirectUrl = '/organizations';
export const listingDataKey = moduleName;
export const itemDataKey = 'organization';

export const organizationConstants = {
  item: ORGANIZATION,
  setItems: SET_ORGANIZATIONS,
  addItem: ADD_ORGANIZATION,
  setItem: ORGANIZATION_FETCHED,
  updateItem: ORGANIZATION_UPDATED,
  deleteItem: ORGANIZATION_DELETED,
  autocompleteItems: AUTOCOMPLETE_ORGANIZATIONS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};


export const defaultSearchParams = {
  name_cont: '',
};

export const Title = 'Organization';
export const TargetBaseUrl = 'organization';
export const CHANGE_ORGANIZATION_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
