import {
  SET_DOCUMENTS, ADD_DOCUMENT, DOCUMENT_FETCHED, DOCUMENT_UPDATED, DOCUMENT_DELETED,
  TOGGLE_POST_DOCUMENT_STATE,
} from '../constants/documents';
import {
  setItems, fetchItem, updateItem, deleteItem, addItem, updateObject,
} from './common';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'updated_at',
  sort_order: 'desc',
  per_page: 500000,
  search_params: {},
  isPosting: false,
};

function togglePostDocumentState(state) {
  const updated_action_state = {
    isPosting: !state.isPosting,
  };
  return updateObject(state, updated_action_state);
}

export default function documents(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENTS: return setItems(state, action);
    case ADD_DOCUMENT: return addItem(state, action);
    case TOGGLE_POST_DOCUMENT_STATE: return togglePostDocumentState(state);
    case DOCUMENT_FETCHED: return fetchItem(state, action);
    case DOCUMENT_UPDATED: return updateItem(state, action);
    case DOCUMENT_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
