import round from 'lodash/round';
import dayjs from 'dayjs';
import axios from '../../utils/axios';
import {
  BASE_URL, HOME_BREADCRUMB_LISTING,
} from '../../constants/common';
import {
  BREADCRUMB_LISTING as PaymentIndexBreadcrumb, open, cash, cheque, received,
} from '../../constants/payments';
import { BREADCRUMB_LISTING as ProductIndexBreadcrumb } from '../../constants/products';
import { BREADCRUMB_LISTING as CustomerIndexBreadcrumb } from '../../constants/customers';
import { serializeSearchParams, serializePageParams } from '../../utils/SerializeParams';
import { handleErrors } from '../../utils/handleErrors';
import { formatNumber, getTenantLocalDateTime, setLocalStorage } from '../../helpers/common_helper';
import {
  idColumn, numberColumn, dateFormattedColumn, customerNameColumn,
  amountLocalizedColumn, instrumentColumn, userNameColumn,
} from '../../helpers/payments_helper';
import { Prices, creditControl } from '../../constants/common_constants';
import { changePriceFiltersSchema } from './prices/prices_list_helper';
import { creditControlFilterSchema } from './credit_control/credit_control_helper';

export const ACTION_BUTTON = 'action_button';
export const FETCHING_DATA = 'fetching_data';

export const PAYMENT_BULK_OPTIONS = '/payment_bulk_options';
export const RECEIVE_CASH_FROM_SALESMAN = 'receive_cash_from_salesman';
export const RECEIVE_CHEQUE_FROM_SALESMAN = 'receive_cheque_from_salesman';
export const TOTAL_RECONCILED_AMOUNT = 'total_reconciled_amount';
export const DEPOSIT_CHEQUE = 'deposit_cheque';
export const REALIZE_CHEQUE = 'realize_cheque';

export const LOCATION_TRACKING = '/location_logs';
export const USER_LOCATION_LOG = 'user_location_log';

export const CHANGE_PRICES_URL = '/prices';
export const CHANGE_PRICES_KEY = 'prices';

export const CREDIT_CONTROL_URL = '/credit_control';
export const CREDIT_CONTROL_KEY = 'credit_control';

export const LAST_OPENED_COMMON_ACTION_SCREEN = 'last_opened_common_action_screen';
export const commonActionScrollId = 'common-action-table';

// If the screen has only one option available then it's title should be in all caps,
// otherwise we follow the same pattern as header title.
export const getBulkOptionVariants = (modelType) => {
  switch (modelType) {
    case PAYMENT_BULK_OPTIONS:
      return [
        { title: 'Receive Cash From Salesman', key: RECEIVE_CASH_FROM_SALESMAN, printTitle: 'Cash Collection' },
        { title: 'Receive Cheques From Salesman', key: RECEIVE_CHEQUE_FROM_SALESMAN, printTitle: 'Cheque Collection' },
        { title: 'Deposit Cheques', key: DEPOSIT_CHEQUE, printTitle: 'Cheque Deposits' },
        { title: 'Realize Cheques', key: REALIZE_CHEQUE, printTitle: 'Cheque Realization' },
      ];

    case LOCATION_TRACKING:
      return [
        { title: 'User Location Log', key: USER_LOCATION_LOG, printTitle: 'Location Log' },
      ];

    case CHANGE_PRICES_URL:
      return [
        { title: Prices, key: CHANGE_PRICES_KEY, printTitle: Prices },
      ];

    case CREDIT_CONTROL_URL:
      return [
        { title: creditControl, key: CREDIT_CONTROL_KEY, printTitle: creditControl },
      ];

    default:
      return [];
  }
};

export const updateLastOpenedScreenIfRequired = (modelType) => {
  if (modelType === LOCATION_TRACKING) {
    setLocalStorage({
      key: LAST_OPENED_COMMON_ACTION_SCREEN,
      data: { title: 'User Location Log ', key: USER_LOCATION_LOG },
    });
  }
};

// Breadcrumb listing array for the selected variant.
export const getBreadcrumbListing = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
      return PaymentIndexBreadcrumb.concat([{ title: 'Receive Cash', target: '', active: true }]);
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return PaymentIndexBreadcrumb.concat([{ title: 'Receive Cheques', target: '', active: true }]);
    case DEPOSIT_CHEQUE:
      return PaymentIndexBreadcrumb.concat([{ title: 'Deposit Cheques', target: '', active: true }]);
    case REALIZE_CHEQUE:
      return PaymentIndexBreadcrumb.concat([{ title: 'Realize Cheques', target: '', active: true }]);

    case USER_LOCATION_LOG:
      return HOME_BREADCRUMB_LISTING.concat([{ title: 'Location Tracking', target: '', active: true }]);

    case CHANGE_PRICES_KEY:
      return ProductIndexBreadcrumb.concat([{ title: Prices, target: '', active: true }]);

    case CREDIT_CONTROL_KEY:
      return CustomerIndexBreadcrumb.concat([{ title: creditControl, target: '', active: true }]);

    default:
      return '';
  }
};

// Gives module name which is used by the table to show
// "No reults found" text with module name as context.
export const getModuleName = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
      return 'Payments';

    case RECEIVE_CHEQUE_FROM_SALESMAN:
    case DEPOSIT_CHEQUE:
    case REALIZE_CHEQUE:
      return 'Cheques';

    case LOCATION_TRACKING:
      return 'Locations';

    case CHANGE_PRICES_KEY:
      return 'Products';

    case CREDIT_CONTROL_KEY:
      return 'Customers';

    default:
      return '';
  }
};

// Provides columns to be shown on any common action screen if it has a table layout.
export const getModuleColumns = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
      return [
        idColumn, { ...numberColumn, can_sort: false }, { ...dateFormattedColumn, can_sort: false },
        { ...customerNameColumn, can_sort: false },
        { ...userNameColumn, can_sort: false },
        {
          ...amountLocalizedColumn, can_sort: false, thClassName: 'text-right', tdClassName: 'text-right',
        },
      ];
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return [
        idColumn, { ...numberColumn, can_sort: false },
        {
          ...instrumentColumn, key: 'instrument_no', label: 'Cheque No', can_sort: false, show: true,
        },
        {
          key: 'instrument_date_formatted', label: 'Cheque Date', can_sort: false, show: true, thClassName: 'word-break', disabled: false,
        },
        {
          key: 'instrument_bank', label: 'Bank', can_sort: false, show: true, thClassName: 'word-break', disabled: false,
        },
        { ...customerNameColumn, can_sort: false },
        { ...userNameColumn, can_sort: false },
        {
          ...amountLocalizedColumn, can_sort: false, thClassName: 'text-right', tdClassName: 'text-right',
        },
      ];
    case DEPOSIT_CHEQUE:
      return [
        idColumn,
        {
          ...instrumentColumn, key: 'instrument_no', label: 'Cheque No', can_sort: false, show: true,
        },
        {
          key: 'instrument_date_formatted', label: 'Cheque Date', can_sort: false, show: true, thClassName: 'word-break', disabled: false,
        },
        {
          key: 'instrument_bank', label: 'Cheque Bank', can_sort: false, show: true, thClassName: 'word-break', disabled: false,
        },
        { ...numberColumn, label: 'Payment No.', can_sort: false },
        {
          ...amountLocalizedColumn, can_sort: false, thClassName: 'text-right', tdClassName: 'text-right',
        },
      ];
    case REALIZE_CHEQUE:
      return [
        {
          ...instrumentColumn, key: 'instrument_no', label: 'Cheque No', can_sort: false, show: true,
        },
        {
          key: 'instrument_date_formatted', label: 'Cheque Date', can_sort: false, show: true, thClassName: 'word-break', disabled: false,
        },
        {
          key: 'instrument_bank', label: 'Cheque Bank', can_sort: false, show: true, disabled: false,
        },
        {
          key: 'bank_account_name', label: 'Deposit Bank', can_sort: false, show: true, disabled: false,
        },
        {
          ...amountLocalizedColumn, can_sort: false, thClassName: 'text-right', tdClassName: 'text-right',
        },
        {
          key: 'realisation_date', label: 'Realization Date', can_sort: false, show: true, disabled: false, callFunc: true,
        },
        {
          key: 'realize_button', label: 'Action', can_sort: false, show: true, disabled: false, callFunc: true,
        },
      ];
    default:
      return [];
  }
};

// Provides columns to be shown in table footer on common action screens.
export const getModuleFooterColumns = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
      return [
        { ...idColumn, tdClassName: 'tfoot-first-child' }, numberColumn, dateFormattedColumn, customerNameColumn, userNameColumn,
        {
          ...amountLocalizedColumn, thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
        },
      ];
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return [
        { ...idColumn, tdClassName: 'tfoot-first-child' }, numberColumn, { ...instrumentColumn, show: true },
        { key: 'instrument_date_formatted', show: true },
        { key: 'instrument_bank', show: true }, customerNameColumn, userNameColumn,
        {
          ...amountLocalizedColumn, thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
        },
      ];
    case TOTAL_RECONCILED_AMOUNT:
      return {
        hasMultipleRows: true,
        columns: [
          [
            idColumn, { ...numberColumn, colAttrs: { colSpan: 6 } },
            {
              ...amountLocalizedColumn, key: 'debitAmountLocalized', thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
            },
            {
              ...amountLocalizedColumn, key: 'creditAmountLocalized', thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
            },
          ],
          [
            idColumn,
            {
              ...amountLocalizedColumn, key: 'total_reconciled_amount', callFunc: true, colAttrs: { colSpan: 6 },
            },
            {
              ...amountLocalizedColumn, key: 'totalDiff', thClassName: 'text-center', tdClassName: 'text-right', callFunc: true, colAttrs: { colSpan: 2 },
            },
          ],
        ],
      };
    case DEPOSIT_CHEQUE:
      return [
        { ...idColumn, tdClassName: 'tfoot-first-child' },
        { ...instrumentColumn, show: true }, { key: 'instrument_date_formatted', show: true },
        { key: 'instrument_bank', show: true },
        numberColumn,
        {
          ...amountLocalizedColumn, thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
        },
      ];
    case REALIZE_CHEQUE:
      return [
        {
          ...instrumentColumn, show: true,
        },
        { key: 'instrument_date_formatted', show: true },
        { key: 'instrument_bank', show: true }, { key: 'bank_account_name', show: true },
        {
          ...amountLocalizedColumn, thClassName: 'text-center', tdClassName: 'text-right', callFunc: true,
        },
        { key: 'realisation_date', show: true }, { key: 'realize_button', show: true },
      ];

    default:
      return [];
  }
};

const UsersInfo = { key: 'users', query_key: 'user_id_eq', data_key: 'items' };
const BankInfo = { key: 'banks', query_key: 'bank_account_id', data_key: 'items' };
const ProductCategoriesInfo = { key: 'product_categories', query_key: 'variants_product_category_id_in', data_key: 'items' };
const BrandsInfo = { key: 'brands', query_key: 'variants_brand_id_in', data_key: 'items' };
const CustomerCategoriesInfo = { key: 'customer_categories', query_key: 'customer_category_id', data_key: 'items' };
const ProductsInfo = { key: 'products', query_key: 'variants_id_in', data_key: 'items' };
const BranchesInfo = { key: 'branches', query_key: 'branch_id_in', data_key: 'items' };
const StateCodes = { key: 'settings', query_key: 'addresses_state_in', data_key: 'state_codes' };
const CustomerRatingsInfo = { key: 'customer_ratings', query_key: 'customer_rating_id', data_key: 'items' };
// realisation_date: "January 15, 2020"
// bank_account_id: 1
// id: 28471
// Provides model names which needs to be fetched for the filters.
export function getBasicInfoModels(selectedVariant) {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return [UsersInfo];
    case DEPOSIT_CHEQUE:
      return [BankInfo];
    case USER_LOCATION_LOG:
      return [UsersInfo];
    case CHANGE_PRICES_KEY:
      return [ProductCategoriesInfo, BrandsInfo, CustomerCategoriesInfo, ProductsInfo];
    case CREDIT_CONTROL_KEY:
      return [BranchesInfo, StateCodes, CustomerCategoriesInfo, CustomerRatingsInfo];

    default:
      return [];
  }
}

/**
 * Returns a string which defines page parameters, i.e. number and per_page.
 * Default return is empty string.
 * @param {object} selectedVariant
 */
export function getPageParams(selectedVariant, currentPage) {
  switch (selectedVariant.key) {
    case USER_LOCATION_LOG:
      return serializePageParams(currentPage, 100);
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
    case DEPOSIT_CHEQUE:
    case REALIZE_CHEQUE:
    case CREDIT_CONTROL_KEY:
      return serializePageParams(currentPage, 50);

    case CHANGE_PRICES_KEY:
      return serializePageParams(currentPage, 10);

    default:
      return '';
  }
}

/**
 * Returns an object of default filter values.
 * @param {object} selectedVariant
 */
export function getDefaultFilterSelections(selectedVariant) {
  switch (selectedVariant.key) {
    case USER_LOCATION_LOG:
      return { date: getTenantLocalDateTime() };
    case RECEIVE_CASH_FROM_SALESMAN:
      return { state_cont: open, payment_mode_name_eq: cash };
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return { state_cont: open, payment_mode_name_eq: cheque };
    case DEPOSIT_CHEQUE:
      return { state_cont: received, payment_mode_name_eq: cheque, bank_account_id_null: true };
    case REALIZE_CHEQUE:
      return {
        state_cont: received, payment_mode_name_eq: cheque, bank_account_id_null: false, realisation_date_null: true,
      };
    case CHANGE_PRICES_KEY:
      return {
        product_category_id_in: [], brand_id_in: [], id_in: [],
      };
    case CREDIT_CONTROL_KEY:
      return {
        branch_id_in: [],
        addresses_state_in: [],
        customer_category_id: [],
        customer_rating_id_in: [],
      };

    default:
      return {};
  }
}

// These are keys which are visible to the user and we want to retain their values
// when we switch between variants.
export const getFilterKeysToRetain = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
      return ['date_eq', 'user_id_eq'];
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return ['date_eq', 'user_id_eq'];
    case DEPOSIT_CHEQUE:
      return ['date_eq'];
    case REALIZE_CHEQUE:
      return ['date_eq'];

    default:
      return {};
  }
};

/**
 * Merge current filter selection like user id or date and replace others with the default
 * filters of that variant
 * @param {*} selectedVariant
 * @param {*} filterSelections
 */
export const mergeFilterSelections = (selectedVariant, filterSelections) => {
  const filtersToRetain = {};
  getFilterKeysToRetain(selectedVariant).forEach((key) => {
    if (filterSelections[key]) {
      filtersToRetain[key] = filterSelections[key];
    }
  });
  return { ...getDefaultFilterSelections(selectedVariant), ...filtersToRetain };
};

/**
 * Returns formatted summary data according to action screen.
 * @param {object} selectedVariant
 * @param {object} data
 */
export function generateSummaryData(selectedVariant, data, selectedIds = [], extraDetails = {}) {
  switch (selectedVariant.key) {
    case USER_LOCATION_LOG:
      return { locationCount: data.location_logs ? data.location_logs.length : 0 };
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      if (selectedIds && selectedIds.length > 0) {
        let total = 0;
        data.payments.forEach((payment) => {
          if (selectedIds.includes(payment.id)) {
            total += Number(payment.amount);
          }
        });
        return { number: `Selected Total : ${selectedIds.length}`, total_count: selectedIds.length, amount_localized: formatNumber({ number: total, currency: true }) };
      }
      if (data && data.payments) {
        return { number: `Total : ${data.payments.length}`, total_count: data.payments.length };
      } return {};

    case TOTAL_RECONCILED_AMOUNT:
      if (selectedIds && selectedIds.length > 0) {
        const {
          normal_credit_balance, ending_balance, starting_balance, reconciledIds,
          reconciledDebitAmount, reconciledCreditAmount,
        } = extraDetails;

        let debitTotal = reconciledDebitAmount || round(0, 2);
        let creditTotal = reconciledCreditAmount || round(0, 2);

        data.items.forEach((recon_entry) => {
          if (recon_entry) {
            const r_e_id = recon_entry.id;
            const reconAmount = round(parseFloat(recon_entry.amount), 2);
            if (selectedIds.includes(r_e_id) && !reconciledIds.includes(r_e_id)) {
              if (recon_entry.type === 'Plutus::CreditAmount') {
                creditTotal += reconAmount;
              } else if (recon_entry.type === 'Plutus::DebitAmount') {
                debitTotal += reconAmount;
              }
            } else if (!selectedIds.includes(r_e_id) && reconciledIds.includes(r_e_id)) {
              if (recon_entry.type === 'Plutus::CreditAmount') {
                creditTotal -= reconAmount;
              } else if (recon_entry.type === 'Plutus::DebitAmount') {
                debitTotal -= reconAmount;
              }
            }
          }
        });

        const isCreditPositive = (normal_credit_balance === true);
        creditTotal *= (isCreditPositive ? 1 : -1);
        debitTotal *= (isCreditPositive ? -1 : 1);
        const totalDiff = round((ending_balance - (starting_balance + creditTotal + debitTotal)), 2);

        const debitAmountLocalized = formatNumber({ number: Math.abs(debitTotal), currency: true });
        const creditAmountLocalized = formatNumber({ number: Math.abs(creditTotal), currency: true });
        const totalReconciledAmount = formatNumber({ number: creditTotal + debitTotal, currency: true });

        return {
          number: `Selected Entries : ${selectedIds.length}`,
          total_count: selectedIds.length,
          debitAmountLocalized,
          creditAmountLocalized,
          total_reconciled_amount: `Total Amount to be reconciled: ${totalReconciledAmount}`,
          totalDiff,
        };
      }
      if (data && data.items) {
        return { number: `Total Unreconciled Entries : ${data.total_count}`, total_count: data.total_count };
      } return {};

    case DEPOSIT_CHEQUE:
      if (selectedIds && selectedIds.length > 0) {
        let total = 0;
        data.payments.forEach((payment) => {
          if (selectedIds.includes(payment.id)) {
            total += Number(payment.amount);
          }
        });
        return { instrument: `Selected Total : ${selectedIds.length}`, total_count: selectedIds.length, amount_localized: formatNumber({ number: total, currency: true }) };
      }
      if (data && data.payments) {
        return { instrument: `Total : ${data.payments.length}`, total_count: data.payments.length };
      } return {};

    case REALIZE_CHEQUE:
      if (data && data.payments) {
        let total = 0;
        data.payments.forEach((payment) => {
          if (selectedIds.includes(payment.id)) {
            total += Number(payment.amount);
          }
        });
        return { instrument: `Total : ${selectedIds.length}`, total_count: selectedIds.length, amount_localized: formatNumber({ number: total, currency: true }) };
      } return {};

    default:
      return data;
  }
}

/**
 * Returns the message of empty screen, for example "Select User to see locations"
 * or "Location log is available after 8PM"
 * @param {object} selectedVariant
 * @param {object} data
 * @param {object} errors
 */
export function getEmptyScreenMessage(selectedVariant, data, errors) {
  if (errors.base) {
    return errors.base;
  }
  switch (selectedVariant.key) {
    case USER_LOCATION_LOG:
      if (!data.location_logs) {
        return 'Select a user to see location logs.';
      }
      if (data.location_logs.length === 0) {
        return 'Location data is not available for the selected date.';
      }
      return '';

    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      if (data.payments.length === 0) {
        return 'No open payments found.';
      }
      return '';

    case DEPOSIT_CHEQUE:
    case REALIZE_CHEQUE:
      if (data.payments.length === 0) {
        return 'There are no payments with uncleared cheques.';
      }
      return '';

    default:
      return '';
  }
}

/**
 * Returns whether data should be fetched for the selected filters.
 * In case we want to restrict user to select some filters before taking action on the data.
 * @param {*} selectedVariant
 * @param {*} filterSelections
 */
export function shouldFetchData(selectedVariant, filterSelections) {
  switch (selectedVariant.key) {
    case USER_LOCATION_LOG:
      return !!filterSelections.user_id_eq && !!filterSelections.date;

    default:
      return true;
  }
}

/**
 * Provides key to get data from the response.
 * @param {*} selectedVariant
 */
export const getItemKey = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
    case DEPOSIT_CHEQUE:
    case REALIZE_CHEQUE:
      return 'payments';
    case USER_LOCATION_LOG:
      return 'location_logs';
    case CHANGE_PRICES_KEY:
      return 'products';
    case CREDIT_CONTROL_KEY:
      return 'customers';

    default:
      return '';
  }
};

/**
 * Returns T/F if item has a value in any of the user input fields.
 * @param {object} selectedVariant
 * @param {object} item
 */
export const includeInSelectedIds = (selectedVariant, item) => {
  switch (selectedVariant.key) {
    case DEPOSIT_CHEQUE:
      return item.realisation_date || item.bank_account_id;

    case REALIZE_CHEQUE:
      return !!item.realisation_date;

    default:
      return false;
  }
};

/**
 * Returns api end point for common action screens to fetch data.
 * @param {*} selectedVariant
 */
export function getApiToFetchData(selectedVariant) {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
    case DEPOSIT_CHEQUE:
    case REALIZE_CHEQUE:
      return '/payments';

    case USER_LOCATION_LOG:
      return '/location_logs';

    case CHANGE_PRICES_KEY:
      return '/products/price_list';

    case CREDIT_CONTROL_KEY:
      return '/customers';

    default:
      return '';
  }
}

/**
 * Returns api end point for common action screen to make put/post requests.
 * @param {*} selectedVariant
 * @param {*} itemId optional parameter, if action to be taken is on single id
 */
export function getApiForActionRequest(selectedVariant, itemId) {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return '/payments/bulk_receive';
    case DEPOSIT_CHEQUE:
      return '/payments/bulk_update';
    case REALIZE_CHEQUE:
      return `payments/${itemId}/realize_and_account`;

    default:
      return '';
  }
}

/**
 * Returns whether entries received in the response should be pre-selected.
 * @param {*} selectedVariant
 */
export const shouldPreSelectAll = (selectedVariant) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
    case REALIZE_CHEQUE:
    case CHANGE_PRICES_KEY:
    case CREDIT_CONTROL_KEY:
      return true;

    default:
      return false;
  }
};

/**
 * Returns if we need to make any changes to the items received in the response.
 * This is different from performOperationOnEntries which only returns if we want to
 * loop through the entries and perform any calculations.
 * @param {*} selectedVariant
 * @param {*} response
 * @param {*} itemKey
 */
export const alterResponseData = (selectedVariant, response, itemKey) => {
  switch (selectedVariant.key) {
    case REALIZE_CHEQUE: {
      const alteredItems = response[itemKey].map((payment) => ({ ...payment, realisation_date: dayjs(), loading: false }));
      return ({ [itemKey]: alteredItems });
    }

    default:
      return response;
  }
};

export const shouldConcatNextPageData = (selectedVariant) => {
  switch (selectedVariant.key) {
    case CHANGE_PRICES_KEY:
    case CREDIT_CONTROL_KEY:
      return false;

    default:
      return true;
  }
};

/**
 * Checks whether the final action button should be enabled.
 * @param {*} selectedVariant
 * @param {*} bulkUpdateData
 */
export const checkBulkUpdateData = (selectedVariant, bulkUpdateData) => {
  switch (selectedVariant.key) {
    case DEPOSIT_CHEQUE:
      return !!bulkUpdateData.bank_account_id;
    default:
      return true;
  }
};

/**
 * Makes api call for fetching the data for common action screen.
 * @param {api, filterValues, page_params, redirectUrl} param0
 */
export function fetchData({
  api, filterValues, pageParams, redirectUrl,
}) {
  const q = serializeSearchParams(filterValues);
  let url = `${api}?${q}`;
  if (pageParams) {
    url += `${pageParams}`;
  }
  return () => axios({
    url,
    method: 'get',
    baseURL: `${BASE_URL}`,
  })
    .then((response) => (response?.data || {}))
    .catch((error) => {
      handleErrors(error, redirectUrl, 'BULK_ACTION');
      throw error.response;
    });
}

/**
 * Make network call when a user take some action on the common action screens.
 * @param {*} param0 {api, body, redirectUrl}
 */
export function takeActionOnData({ api, dataBody, redirectUrl }) {
  return axios({
    url: api,
    method: 'put',
    data: dataBody,
    baseURL: `${BASE_URL}`,
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, 'BULK_ACTION', false);
      throw error.response;
    });
}

/**
 * Returns request body for put/post request for any common action screen.
 * @param {*} param0
 */
export const createPostBody = ({
  selectedVariant, selected_ids, bulkUpdateData, singleUpdateData,
}) => {
  switch (selectedVariant.key) {
    case RECEIVE_CASH_FROM_SALESMAN:
    case RECEIVE_CHEQUE_FROM_SALESMAN:
      return { payment: { payment_ids: selected_ids } };

    case DEPOSIT_CHEQUE: {
      const selectedItems = {};
      selected_ids.forEach((selectedId) => {
        selectedItems[selectedId] = bulkUpdateData;
      });
      return { payments: selectedItems };
    }

    case REALIZE_CHEQUE: return { realisation_date: singleUpdateData.realisation_date };

    default:
      return {};
  }
};

/**
 * Returns true if common action options contains last opened variant.
 * This check is to ensure consistency between separate tabs.
 * @param {*} bulkOptionVariants
 * @param {*} lastOpenedVariant
 */
export const bulkOptionVariantsContains = (bulkOptionVariants, lastOpenedVariant) => {
  let contains = false;
  bulkOptionVariants.forEach((variant) => {
    if (lastOpenedVariant.key === variant.key) {
      contains = true;
    }
    return variant;
  });
  return contains;
};

/**
 * Returns classname for tfoot.
 * @param {object} selectedVariant
 */
export const getFooterClass = (selectedVariant) => {
  switch (selectedVariant.key) {
    case REALIZE_CHEQUE: return 'cheque-realization-tfoot';

    default:
      return '';
  }
};

export const getFiltersSchema = (modelType) => {
  switch (modelType) {
    case CHANGE_PRICES_URL:
      return changePriceFiltersSchema;

    case CREDIT_CONTROL_URL:
      return creditControlFilterSchema;

    default:
      return undefined;
  }
};
