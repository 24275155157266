import toUpper from 'lodash/toUpper';

import { moduleName as orders } from './orders';
import { moduleName as invoices } from './invoices';
import { moduleName as payments } from './payments';
import { moduleName as returns } from './returns';
import { moduleName as debitNotes } from './debit_notes';
import { moduleName as creditNotes } from './credit_notes';
import { moduleName as purchaseOrders } from './purchase_orders';
import { moduleName as purchaseInvoices } from './purchase_invoices';
import { moduleName as purchaseReturns } from './purchase_returns';
import { moduleName as paymentOuts } from './payment_outs';

export const RECENT = 'RECENT';
export const SET_RECENT_ORDERS = 'SET_RECENT_ORDERS';
export const SET_RECENT_INVOICES = 'SET_RECENT_INVOICES';
export const SET_RECENT_PAYMENTS = 'SET_RECENT_PAYMENTS';
export const SET_RECENT_RETURNS = 'SET_RECENT_RETURNS';
export const SET_RECENT_DEBIT_NOTES = 'SET_RECENT_DEBIT_NOTES';
export const SET_RECENT_CREDIT_NOTES = 'SET_RECENT_CREDIT_NOTES';
export const SET_RECENT_PURCHASE_ORDERS = 'SET_RECENT_PURCHASE_ORDERS';
export const SET_RECENT_PURCHASE_INVOICES = 'SET_RECENT_PURCHASE_INVOICES';
export const SET_RECENT_PURCHASE_RETURNS = 'SET_RECENT_PURCHASE_RETURNS';
export const SET_RECENT_PAYMENT_OUTS = 'SET_RECENT_PAYMENT_OUTS';

export const ORDERS = 'orders';
export const INVOICES = 'invoices';
export const PAYMENTS = 'payments';
export const RETURNS = 'returns';
export const DEBIT_NOTES = 'debit_notes';
export const CREDIT_NOTES = 'credit_notes';
export const ACCOUNT_ENTRIES = 'Account_entries';
export const CREDIT_LEEWAYS = 'credit_leeways';
export const PURCHASE_ORDERS = 'purchase_orders';
export const PURCHASE_INVOICES = 'purchase_invoices';
export const PURCHASE_RETURNS = 'purchase_returns';
export const PAYMENT_OUTS = 'payment_outs';

// SECTION state updates related constants
// For orders
export const UPDATE_SELECTED_BULK_RECENT_ORDERS_STATUS_TO_CONFIRM = 'UPDATE_SELECTED_BULK_RECENT_ORDERS_STATUS_TO_CONFIRM';

// For invoices
export const UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DELIVERED = 'UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DELIVERED';
export const UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DISPATCHED = 'UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DISPATCHED';

// For payments
export const UPDATE_SELECTED_BULK_RECENT_PAYMENTS_STATUS_TO_ACCOUNTED = 'UPDATE_SELECTED_BULK_RECENT_PAYMENTS_STATUS_TO_ACCOUNTED';

// For returns
export const UPDATE_SELECTED_BULK_RECENT_RETURNS_STATUS_TO_APPROVED = 'UPDATE_SELECTED_BULK_RECENT_RETURNS_STATUS_TO_APPROVED';

// For payment outs
export const UPDATE_SELECTED_BULK_RECENT_PAYMENT_OUTS_STATUS_TO_ACCOUNTED = 'UPDATE_SELECTED_BULK_RECENT_PAYMENT_OUTS_STATUS_TO_ACCOUNTED';
// !SECTION state updates related constants

export const recentsModuleNames = [
  orders, invoices, payments, returns, debitNotes, creditNotes, purchaseOrders, purchaseInvoices,
  purchaseReturns, paymentOuts,
];

export const moduleNamesAndResourceType = {
  [ORDERS]: 'ORDER',
  [INVOICES]: 'INVOICE',
  [PAYMENTS]: 'PAYMENT',
  [RETURNS]: 'RETURN',
  [DEBIT_NOTES]: 'DEBIT_NOTE',
  [CREDIT_NOTES]: 'CREDIT_NOTE',
  [PURCHASE_ORDERS]: 'PURCHASE_ORDER',
  [PURCHASE_INVOICES]: 'PURCHASE_INVOICE',
  [PURCHASE_RETURNS]: 'PURCHASE_RETURN',
  [PAYMENT_OUTS]: 'PAYMENT_OUT',
};

export const getRecentsModuleFetchConst = (moduleName: string) => `FETCH_${RECENT}_${toUpper(moduleName)}`;
