import { fromJS } from 'immutable';
import {
  SET_CUSTOM_FIELDS, ADD_CUSTOM_FIELD, CUSTOM_FIELD_UPDATED, CUSTOM_FIELD_DELETED,
  ORDER_RESOURCE_TYPE, INVOICE_RESOURCE_TYPE, PAYMENT_RESOURCE_TYPE, RETURN_RESOURCE_TYPE,
  CREDIT_NOTE_RESOURCE_TYPE, DEBIT_NOTE_RESOURCE_TYPE, CUSTOMER_RESOURCE_TYPE,
  PURCHASE_ORDER_RESOURCE_TYPE, PURCHASE_INVOICE_RESOURCE_TYPE, PAYMENT_OUT_RESOURCE_TYPE,
  PURCHASE_RETURN_RESOURCE_TYPE, PRODUCT_RESOURCE_TYPE, SUPPLIER_RESOURCE_TYPE,
} from '../constants/custom_fields';
import {
  setItems, addItem, updateItem, deleteItem,
} from './common';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';

const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  per_page: 100,
  search_params: {},
  prev_resource_type: '',
  cur_resource_type: '',
  can_create: false,
  sort_by: 'position',
  sort_order: 'asc',
};

export const customFieldsInitialState = {
  [ORDER_RESOURCE_TYPE]: { ...initialState },
  [INVOICE_RESOURCE_TYPE]: { ...initialState },
  [PAYMENT_RESOURCE_TYPE]: { ...initialState },
  [RETURN_RESOURCE_TYPE]: { ...initialState },
  [CREDIT_NOTE_RESOURCE_TYPE]: { ...initialState },
  [DEBIT_NOTE_RESOURCE_TYPE]: { ...initialState },
  [CUSTOMER_RESOURCE_TYPE]: { ...initialState },
  [PURCHASE_ORDER_RESOURCE_TYPE]: { ...initialState },
  [PURCHASE_INVOICE_RESOURCE_TYPE]: { ...initialState },
  [PAYMENT_OUT_RESOURCE_TYPE]: { ...initialState },
  [PURCHASE_RETURN_RESOURCE_TYPE]: { ...initialState },
  [PRODUCT_RESOURCE_TYPE]: { ...initialState },
  [SUPPLIER_RESOURCE_TYPE]: { ...initialState },
};

const setCustomFields = (state, action) => {
  const { resource_type: resourceType } = action;
  const { cur_resource_type } = state[resourceType];
  const actionCopy = fromJS(action).toJS();

  if (cur_resource_type === '') {
    actionCopy.cur_resource_type = resourceType;
    actionCopy.prev_resource_type = resourceType;
  }

  if (cur_resource_type && cur_resource_type !== resourceType) {
    actionCopy.prev_resource_type = cur_resource_type;
    actionCopy.cur_resource_type = resourceType;
  }

  return { ...state, [resourceType]: setItems(state[resourceType], actionCopy) };
};

const addCustomField = (state, action) => {
  const resourceType = action?.item?.resource_type || '';
  return { ...state, [resourceType]: addItem(state[resourceType], action) };
};

const updateCustomField = (state, action) => {
  const resourceType = action?.item?.resource_type || '';
  return { ...state, [resourceType]: updateItem(state[resourceType], action) };
};

const deleteCustomField = (state, action) => {
  const resourceType = action?.item?.resource_type || '';
  return { ...state, [resourceType]: deleteItem(state[resourceType], action) };
};

export default function customFields(state = customFieldsInitialState, action) {
  switch (action.type) {
    case SET_CUSTOM_FIELDS: return setCustomFields(state, action);
    case ADD_CUSTOM_FIELD: return addCustomField(state, action);
    case CUSTOM_FIELD_UPDATED: return updateCustomField(state, action);
    case CUSTOM_FIELD_DELETED: return deleteCustomField(state, action);
    default:
      return state;
  }
}
