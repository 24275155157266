import map from 'lodash/map';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_PURCHASE_RETURNS, ADD_PURCHASE_RETURN, PURCHASE_RETURN_FETCHED, PURCHASE_RETURN_UPDATED,
  PURCHASE_RETURN_DELETED, defaultSearchParams, moduleName,
} from '@constants/purchase_returns';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import { BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_RETURN_ITEMS } from '@constants/inventories';
import { BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_RETURN_ITEMS } from '@src/constants/inventory_pieces';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function purchaseReturns(state = initialState, action) {
  switch (action.type) {
    case SET_PURCHASE_RETURNS: return setItems(state, action);
    case ADD_PURCHASE_RETURN: return addItem(state, action);
    case PURCHASE_RETURN_FETCHED: return fetchItem(state, action);
    case PURCHASE_RETURN_UPDATED: return updateItem(state, action);
    case PURCHASE_RETURN_DELETED: return deleteItem(state, action);
    case BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_RETURN_ITEMS:
    case BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_RETURN_ITEMS: {
      const {
        voucherId, voucherItemId, voucherItemData,
      } = action;
      const items = map(state.items, (item) => {
        if (item?.id !== voucherId) return item;
        const purchaseReturnItems = map(item.purchase_return_items, (purchaseReturnItem) => {
          if (purchaseReturnItem?.id !== voucherItemId) return purchaseReturnItem;
          return voucherItemData;
        });
        return { ...item, purchase_return_items: purchaseReturnItems };
      });

      return { ...state, items };
    }
    default:
      return state;
  }
}
