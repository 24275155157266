import React from 'react';
import {
  map, isArray, isEmpty, isString, every, isObject, some,
} from 'lodash';

interface RenderErrorMessageProps {
  keyName: string;
  errorMessage: any;
}

const RenderErrorMessage = ({ keyName, errorMessage }: RenderErrorMessageProps) => (
  <span className="errors-message" key={`nested-form-flash-message-${keyName}`}>
    <strong className="flash-message-key">{(keyName?.replace(/_/g, ' ')) || ''}</strong>
    :
    &nbsp;
    {errorMessage || ''}
  </span>
);

interface ErrorMessageWrapperProps {
  keyName: string;
  value: any;
}

const ErrorMessageWrapper = ({ value, keyName: key }: ErrorMessageWrapperProps) => {
  // If value of type is string (ie. value: 'order_items', 'Must exist.')
  if (isString(value)) {
    return (
      <RenderErrorMessage key={key} keyName={key} errorMessage={value} />
    );
  }
  // If value of type is array (ie. value: 'order_item_discounts', ['Must exist','must be greated than 0'])
  if (isArray(value) && every(value, isString)) {
    return (
      <RenderErrorMessage key={key} keyName={key} errorMessage={value?.join(', ')} />
    );
  }
  // If value of type is array (ie. value: 'order_item_discounts', [{ amount: 'must be greated than 0'}, { discount: 'must be greated than 0'}])
  if (isArray(value) && every(value, isObject)) {
    return (
      <>
        {
          map(value, (val) => {
            const keysArray = Object.keys(val) || [];
            return map(keysArray, (v) => (<ErrorMessageWrapper key={`${key}.${v}`} keyName={`${key}.${v}`} value={val?.[v]} />));
          })
        }
      </>
    );
  }
  // If value of type is array (ie. value: 'order_item_discounts', [{ amount: 'must be greated than 0'}, 'Must Exist', ['test', 'test']])
  if (isArray(value) && some(value, isObject)) {
    return (
      <>
        {
          map(value, (val) => {
            if (isObject(val)) {
              const keysArray = Object.keys(val) || [];
              return map(keysArray, (v) => (<ErrorMessageWrapper key={`${key}.${v}`} keyName={`${key}.${v}`} value={val?.[v]} />));
            }
            return (<ErrorMessageWrapper key={key} keyName={key} value={val} />);
          })
        }
      </>
    );
  }

  // ex. { value1: 'value 1', value2: 'value 2', value3: 'value 3'}
  if (isObject(value)) {
    const keysArray = Object.keys(value) || [];
    return (
      <>
        {
          map(keysArray, (val) => (<ErrorMessageWrapper key={`${key}.${val}`} keyName={`${key}.${val}`} value={value?.[val]} />))
        }
      </>
    );
  }
  return <span />;
};

interface Props {
  unhandledErrors?: any;
  isFullWidth?: boolean;
  colSize?: string[];
}

const DisplayNestedFormErrors = ({
  unhandledErrors, isFullWidth, colSize,
}: Props) => {
  const firstColSize = (colSize && colSize[0]) || 'half';
  const secondColSize = (colSize && colSize[1]) || '11-half';

  if (isEmpty(unhandledErrors)) return <div />;

  if (isFullWidth) {
    return (
      <>
        <div className="col-md-12">
          {
            // eslint-disable-next-line max-len
            map(unhandledErrors, (value, key) => (<ErrorMessageWrapper key={key} keyName={key} value={value} />))
          }
        </div>
      </>
    );
  }

  return (
    <>
      <div className="col-md-12">
        <div className={`col-md-${firstColSize}`} />
        <div className={`col-md-${secondColSize}`}>
          {
            // eslint-disable-next-line max-len
            map(unhandledErrors, (value, key) => (<ErrorMessageWrapper key={key} keyName={key} value={value} />))
          }
        </div>
      </div>
    </>
  );
};

DisplayNestedFormErrors.defaultProps = {
  unhandledErrors: {},
  isFullWidth: false,
  colSize: ['half', '11-half'],
};

export default DisplayNestedFormErrors;
