import { POST, GET, PUT } from '@utils/axios_methods';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { FetchEWayBillMastersDataActionTypes, GenerateEWAYBillDataActionTypes } from '@component/common/e_way_bill/types';

export const generateEWAYBillAction = ({ id, item, response }) => ({
  type: `SET_${item}_E_WAY_BILL_DATA`,
  payload: { id, e_way_bill: (response?.data?.e_way_bill || {}) },
});

export const cancelEWayBillAction = ({ id, item, response }) => ({
  type: `CANCEL_${item}_E_WAY_BILL_DATA`,
  payload: { id, e_way_bill: (response?.data?.e_way_bill || {}) },
});

// Fetch master data.
export function fetchEWayBillMastersDataAction(args: FetchEWayBillMastersDataActionTypes) {
  const { moduleName, redirectUrl } = args;
  // moduleName: 'e_way_bills'
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;

  startFetchingRequest(moduleName);

  return () => GET({
    url: '/in/e_way_bills/masters_data',
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  })
    .then((response) => response);
}

// Generate e-way-bill.
export function createEWayBillAction(data: GenerateEWAYBillDataActionTypes) {
  const { moduleName, redirectUrl, e_way_bill } = data;
  // moduleName: 'e_way_bills'
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);

  return () => POST({
    url: '/in/e_way_bills',
    data: { in_e_way_bill: e_way_bill },
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  })
    .then((response) => response);
}

// Cancel e-way bill.
export function cancelEWayBill(data) {
  const {
    moduleName, id, cancellation_reason, cancellation_remarks,
  } = data;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  const url = `/in/e_way_bills/${id}/cancel`;
  return () => PUT({
    url,
    data: {
      in_e_way_bill: {
        id, cancellation_reason, cancellation_remarks,
      },
    },
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  }).then((response) => response);
}

// Autocomplete E-Way bill Cancellation Reasons
export function autocompleteEWayBillCancellationReasons(data) {
  const { moduleName } = data;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const url = '/in/e_way_bills/cancellation_reasons';
  return () => GET({
    url,
    error: {
      module_name: item, redirect_to: redirectUrl, shouldRedirect: false,
    },
  }).then((response) => response);
}
