import inRange from 'lodash/inRange';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getActionTypes } from '@actions/action_helpers';
import { BULK_FIELD_UPDATE } from './constants';
import { bulkUpdate as bulkUpdateAction } from './action';

function* bulkFieldUpdateFunc(action) {
  const { moduleName, resourceIds, data } = action || {};
  const moduleTypes = getActionTypes({
    moduleName,
    actionTypes: ['baseUrl', 'redirectUrl', 'item'],
  });
  const { item } = moduleTypes;
  try {
    const response = yield call(bulkUpdateAction({
      moduleName, resourceIds, data,
    }));

    if (inRange(response.status, 200, 210)) {
      yield put({ type: `BULK_UPDATE_${item}_DATA`, payload: { data: response.data?.[moduleName] || [] } });
    }
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* bulkUpdateWatcher() {
  yield takeLatest(BULK_FIELD_UPDATE, bulkFieldUpdateFunc);
}
