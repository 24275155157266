import { getTenantLocalDateTime } from '@helpers/common_helper';
import { ready, dispatched, delivered } from '@constants/invoices';
import { TODAY, TILL_DATE } from '@constants/common';

export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'today_invoices', label: 'Today\'s Invoices', applyFilters: true, filterSelection: { date_gteq: getTenantLocalDateTime(), date_lteq: getTenantLocalDateTime() }, timePeriod: TODAY,
    },
    {
      key: 'ready_invoices', label: 'Ready Invoices', applyFilters: true, filterSelection: { state_in: [ready] }, timePeriod: TILL_DATE,
    },
    {
      key: 'dispatched_invoices', label: 'Dispatched Invoices', applyFilters: true, filterSelection: { state_in: [dispatched] }, timePeriod: TILL_DATE,
    },
    {
      key: 'ready_dispatched_invoices', label: 'Ready or Dispatched Invoices', applyFilters: true, filterSelection: { state_in: [ready, dispatched] }, timePeriod: TILL_DATE,
    },
    {
      key: 'unpaid_delivered_invoices', label: 'Unpaid Delivered Invoices', applyFilters: true, filterSelection: { state_in: [delivered], unpaid_amount_gt: '0' }, timePeriod: TILL_DATE,
    },
  ],
};
