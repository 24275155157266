import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for users_group index page.
const UsersGroups = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersGroupsIndex" */ '../containers/users_groups/Index'),
  loading: Loading,
});

// We use loadable for users_group new and edit page.
const UsersGroupsCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersGroupsCredate" */ '../containers/users_groups/Credate'),
  loading: Loading,
});

// We use loadable for users_group show page.
const UsersGroupsShow = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersUsersGroupsShow" */ '../containers/users_groups/Show'),
  loading: Loading,
});


// We use loadable for default dac role index page.
const DefaultDacRolesRoutes = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDefaultDacRolesIndex" */ '../containers/default_dac_roles/Index'),
  loading: Loading,
});

// We use loadable for default dac role new and edit page.
const DefaultDacRoleCredate = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersDefaultDacRoleCredate" */ '../containers/default_dac_roles/Credate'),
  loading: Loading,
});

const AuthorisationConditions = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersAuthorisationConditionsIndex" */ '../containers/authorisation_conditions/Index'),
  loading: Loading,
});


const AccessManagement = [
  {
    path: '/users_groups', name: 'Users Groups', component: UsersGroups,
  },
  {
    path: '/users_groups/new', name: 'users_groups/new', component: UsersGroupsCredate,
  },
  {
    path: '/users_groups/:id/edit', name: 'users_groups/edit', component: UsersGroupsCredate,
  },
  {
    path: '/users_groups/:id', name: 'users_groups/show', component: UsersGroupsShow,
  },

  {
    path: '/default_dac_roles', name: 'Default Dac Roles', component: DefaultDacRolesRoutes,
  },
  {
    path: '/default_dac_roles/new', name: 'default_dac_roles/new', component: DefaultDacRoleCredate,
  },
  {
    path: '/default_dac_roles/:id/edit', name: 'default_dac_roles/edit', component: DefaultDacRoleCredate,
  },

  {
    path: '/authorisation/conditions', name: 'Authorisation conditions', component: AuthorisationConditions,
  },
];

export default AccessManagement;
