import { getClientSetting } from '@helpers/settings_helper';

interface ClientSettingsProps {
  base_country: string;
}

export const doesNotHaveIndiaAsBaseCountry = (clientSettings?: ClientSettingsProps) => {
  const baseCountry = clientSettings?.base_country || getClientSetting('base_country');
  return !['IN', ''].includes(baseCountry);
};
