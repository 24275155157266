import map from 'lodash/map';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_USERS, ADD_USER, USER_FETCHED, USER_UPDATED, USER_DELETED,
  AUTOCOMPLETE_SELECT_MULTI_USERS, defaultSearchParams, moduleName, UPDATE_USER_SUSPENDED,
} from '@constants/users';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case SET_USERS: return setItems(state, action);
    case ADD_USER: return addItem(state, action);
    case USER_FETCHED: return fetchItem(state, action);
    case USER_UPDATED: return updateItem(state, action);
    case USER_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_SELECT_MULTI_USERS: return autocompleteUnions(state, action);
    case UPDATE_USER_SUSPENDED: {
      const items = map(state?.items, (item) => {
        if (item?.id !== action.payload.id) return item;
        return { ...item, suspended: action?.payload?.suspended };
      });
      return { ...state, items };
    }
    default:
      return state;
  }
}
