export const returnBlobFileUrl = (response) => {
  const { data, headers } = response;
  const fileName = headers?.['file-name'] || 'Download Pdf';
  const contentType = headers?.['content-type'] || 'application/pdf';
  const file = new Blob([data], { type: contentType });
  const fileUrl = URL.createObjectURL(file);
  return { fileName, fileUrl };
};

export const downloadFileHelper = ({ response }) => {
  if (!response) return;
  const { fileName, fileUrl } = returnBlobFileUrl(response);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();
};
