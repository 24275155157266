import {
  SET_INVENTORY_IDENTIFICATIONS, ADD_INVENTORY_IDENTIFICATION,
  INVENTORY_IDENTIFICATION_FETCHED, INVENTORY_IDENTIFICATION_UPDATED,
  INVENTORY_IDENTIFICATION_DELETED, AUTOCOMPLETE_INVENTORY_IDENTIFICATIONS,
  defaultSearchParams, moduleName,
} from '@constants/inventory_identifications';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function inventoryIdentifications(state = initialState, action) {
  switch (action.type) {
    case SET_INVENTORY_IDENTIFICATIONS: return setItems(state, action);
    case ADD_INVENTORY_IDENTIFICATION: return addItem(state, action);
    case INVENTORY_IDENTIFICATION_FETCHED: return fetchItem(state, action);
    case INVENTORY_IDENTIFICATION_UPDATED: return updateItem(state, action);
    case INVENTORY_IDENTIFICATION_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_INVENTORY_IDENTIFICATIONS: return autocompleteUnions(state, action);
    default:
      return state;
  }
}
