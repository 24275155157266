export const SUPPLIER_ADDRESS = 'SUPPLIER_ADDRESS';
export const SET_SUPPLIER_ADDRESSES = 'SET_SUPPLIER_ADDRESSES';
export const ADD_SUPPLIER_ADDRESS = 'ADD_SUPPLIER_ADDRESS';
export const SUPPLIER_ADDRESS_FETCHED = 'SUPPLIER_ADDRESS_FETCHED';
export const SUPPLIER_ADDRESS_UPDATED = 'SUPPLIER_ADDRESS_UPDATED';
export const SUPPLIER_ADDRESS_DELETED = 'SUPPLIER_ADDRESS_DELETED';
export const SUPPLIER_ADDRESS_EDIT = 'SUPPLIER_ADDRESS_EDIT';
export const AUTOCOMPLETE_SUPPLIER_ADDRESSES = 'AUTOCOMPLETE_SUPPLIER_ADDRESSES';

export const OPEN_SUPPLIER_ADDRESS_FORM_MODAL = 'OPEN_SUPPLIER_ADDRESS_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Supplier Addresses';
export const singularTitle = 'Supplier address';
export const moduleName = 'supplier_addresses';
export const baseUrl = '/supplier_addresses';
export const redirectUrl = '/supplier_addresses';
export const listingDataKey = 'supplier_addresses';
export const itemDataKey = 'supplier_address';
export const permissionKey = 'customer/addresses'; // TODO: Change this one.

export const supplierAddressConstants = {
  item: SUPPLIER_ADDRESS,
  setItems: SET_SUPPLIER_ADDRESSES,
  addItem: ADD_SUPPLIER_ADDRESS,
  setItem: SUPPLIER_ADDRESS_FETCHED,
  updateItem: SUPPLIER_ADDRESS_UPDATED,
  deleteItem: SUPPLIER_ADDRESS_DELETED,
  autocompleteItems: AUTOCOMPLETE_SUPPLIER_ADDRESSES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
