import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUrl } from '../../helpers/common_helper';

const notFoundImage = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="ghost"
    className="svg-inline--fa fa-ghost fa-w-12"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    style={{ height: '260px' }}
  >
    <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />

  </svg>
);

interface NotFoundProps {
  requestedUrl: string;
  isLoggedIn: boolean;
}

class NotFoundComponent extends React.PureComponent<NotFoundProps> {
  showRequestedURL = () => {
    const { requestedUrl } = this.props;
    return requestedUrl || getCurrentUrl();
  }

  render() {
    const showURL = this.showRequestedURL();
    const { isLoggedIn } = this.props;
    return (
      <div>
        <div className="row p-0 parent-container-box-not-found-component">
          <div className="col-md-3 text-center">
            {notFoundImage}
          </div>
          <div className="col-md-7 p-0">
            <div className="row m-0 p-0">
              <h2 className="m-0 p-0 page-not-found-heading-text">Resource not found. It&lsquo;s either deleted or moved.</h2>
            </div>
            <div className="row m-0 p-0">
              <div className="page404-text-content-instructions">
                <br />
                You tried to access:
                &nbsp;
                <code>
                  {showURL}
                </code>
                <br />
                <br />
                {
                  isLoggedIn ? (
                    <div>
                      Try finding the page using the links in the menubar.
                      <br />
                      Go back
                      &nbsp;
                      <Link to="/">home</Link>
                    </div>
                  ) : (
                    <div>
                      Go back
                      &nbsp;
                      <Link to="/login">home</Link>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundComponent;
