import {
  CREDIT_CONTROL_URL, CREDIT_CONTROL_KEY,
  LAST_OPENED_COMMON_ACTION_SCREEN,
} from '@containers/common_actions/common_actions_screen_helpers';
import { creditControl } from '@constants/common_constants';
import { getI18nFields, setLocalStorage } from '@helpers/common_helper';

const customersI18nFields = getI18nFields('customers') || {};
const gstinDetails = customersI18nFields?.gstin || {};
export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'active_customers', label: 'Active Customers', applyFilters: true, filterSelection: { active_eq: true },
    },
    {
      key: 'customers_w_gstin', label: `Customers with ${gstinDetails?.label || 'GSTIN'}`, applyFilters: true, filterSelection: { gstin_present: true },
    },
    {
      key: 'customers_wo_gstin', label: `Customers without ${gstinDetails?.label || 'GSTIN'}`, applyFilters: true, filterSelection: { gstin_present: 'false' },
    },
  ],
  PROCESS: [
    {
      key: CREDIT_CONTROL_KEY,
      label: creditControl,
      redirectUrl: CREDIT_CONTROL_URL,
      callback: () => {
        setLocalStorage({
          key: LAST_OPENED_COMMON_ACTION_SCREEN,
          data: { title: creditControl, key: CREDIT_CONTROL_KEY },
        });
      },
    },
  ],
};
