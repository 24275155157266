import BLUESAPLING_LOGO from '@assets/img/bluesapling-icon.png';
import { HOME_BREADCRUMB_LISTING } from './common';

export const CURRENT_USER = 'CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const RESET_REDUCERS_STATE = 'RESET_REDUCERS_STATE';
export const SET_USER_ACCOUNT_PROFILE = 'SET_USER_ACCOUNT_PROFILE';

export const CAN_NOT_BLANK = "Can't be blank.";
export const NUMBER_SHOULD_BE_EQUAL_TO = 'Mobile number should be 10 digit.';

export const INFO_MESG = 'We are a technology company that understands complexity of businesses, and with our technical expertise, we help them transform and scale.';
export const PRODUCT_NAME = 'Distributo';
export const PRODUCT_LOGO = BLUESAPLING_LOGO;
export const showOldDesign = false;
export const LOADING_TENANTS_DETAILS = 'LOADING_TENANTS_DETAILS';

export const Title = 'Change Password';
export const TargetBaseUrl = 'account/password';
export const CHANGE_PASSWORD_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title: Title, target: TargetBaseUrl, active: false },
]);

export const UNAUTHENTICATED = 'Your session expired. Please sign in again to continue.';
