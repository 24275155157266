import { FetchParams } from '@src/type/dms_types';
import axios from '@utils/axios';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import {
  getAutoCompleteAPIEndUrl, getBulkCreateVoucherItemInventoryBatchesApiKey,
  getInventoryApiEndpoint, getInventoryAPIKey,
} from '@helpers/inventories_helpers';

// Types
type FetchInventoriesParams = FetchParams & {
  moduleName?: string;
}

export function fetchInventories(args: FetchInventoriesParams) {
  const { moduleName } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  const URL = getInventoryApiEndpoint(moduleName);
  return () => axios({
    url: `${URL}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
    });
}

// Get inventory.
export function fetchInventory(args) {
  const { id, redirectUrl, moduleName } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  const URL = getInventoryApiEndpoint(moduleName, id);
  startFetchingRequest(moduleName);
  return () => axios({ url: URL, method: 'get' })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// // Save Inventory.
export function saveInventory(args) {
  const { redirectUrl, moduleName, data } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  startFetchingRequest(moduleName);
  const dataKey = getInventoryAPIKey(moduleName);
  const URL = getInventoryApiEndpoint(moduleName);
  return () => axios({
    url: URL,
    method: 'post',
    data: { [dataKey]: { ...data } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// // Update inventory.
export function updateInventory(args) {
  const {
    id, redirectUrl, moduleName, data,
  } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  const URL = getInventoryApiEndpoint(moduleName, id);
  const dataKey = getInventoryAPIKey(moduleName);
  startFetchingRequest(moduleName);
  return () => axios({
    url: URL,
    method: 'put',
    data: { [dataKey]: { ...data } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// // Delete inventory.
export function deleteInventory(args) {
  const { id, redirectUrl, moduleName } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  const URL = getInventoryApiEndpoint(moduleName, id);
  startFetchingRequest(moduleName);
  return () => axios({ url: URL, method: 'delete' })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// // AutoComplete inventory.
export function autoCompleteInventory(args) {
  const {
    moduleName, searchParams, productId, redirectUrl,
  } = args;
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  const url = getAutoCompleteAPIEndUrl(moduleName, productId);
  return () => axios({ url: `${url}?${q}`, method: 'get' })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
    });
}

export function autocompleteInventoryBatches(args) {
  const { voucherId, url, voucherModuleName } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  return () => axios({
    url: `${url}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, item);
      throw error.response;
    });
}

export function bulkCreateVoucherItemInventoryBatches(args) {
  const {
    url, voucherModuleName, voucherId, data,
  } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl } = moduleTypes;

  const apiDataKey = getBulkCreateVoucherItemInventoryBatchesApiKey(voucherModuleName);

  return () => axios({
    url,
    method: 'post',
    data: { [apiDataKey]: { ...data } },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, '');
      throw error.response;
    });
}

export function fetchInventoryBatchAvailableQuantity(args) {
  const {
    url, voucherModuleName, voucherId, productId, warehouseId,
  } = args;
  const moduleTypes = getActionTypes({ moduleName: voucherModuleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;

  return () => axios({
    url,
    method: 'get',
    params: { product_id: productId, warehouse_id: warehouseId },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${voucherId}`, item);
      throw error.response;
    });
}
