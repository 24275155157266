import {
  SET_INVENTORY_PIECES, ADD_INVENTORY_PIECE, INVENTORY_PIECE_FETCHED, INVENTORY_PIECE_UPDATED,
  INVENTORY_PIECE_DELETED, AUTOCOMPLETE_INVENTORY_PIECES, defaultSearchParams, moduleName,
} from '@constants/inventory_pieces';
import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function inventoryPieces(state = initialState, action) {
  switch (action.type) {
    case SET_INVENTORY_PIECES: return setItems(state, action);
    case ADD_INVENTORY_PIECE: return addItem(state, action);
    case INVENTORY_PIECE_FETCHED: return fetchItem(state, action);
    case INVENTORY_PIECE_UPDATED: return updateItem(state, action);
    case INVENTORY_PIECE_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_INVENTORY_PIECES: return autocompleteUnions(state, action);
    default:
      return state;
  }
}
