export const ACCOUNTING_AMOUNT = 'ACCOUNTING_AMOUNT';
export const SET_ACCOUNTING_AMOUNTS = 'SET_ACCOUNTING_AMOUNTS';

export const Title = 'Account Amounts';
export const moduleName = 'amounts';

export const types = [
  { label: 'Invoice', value: 'Invoice' }, { label: 'Payment', value: 'Payment' },
  { label: 'CreditNote', value: 'CreditNote' }, { label: 'DebitNote', value: 'DebitNote' },
  { label: 'PurchaseInvoice', value: 'PurchaseInvoice' },
];

export const openingBalDateFilterOptions = [
  { label: 'Show all entries', value: '' },
  { label: 'Show entries before opening balance date', value: 'true' },
  { label: 'Show entries after opening balance date', value: 'false' },
];

export const defaultSearchParams = {
  entry_date_lteq: '',
  entry_date_gteq: '',
  entry_commercial_document_type_eq: '',
  before_opening_balance: '',
};
