import { HOME_BREADCRUMB_LISTING } from './common';

export const USERS_GROUP = 'USERS_GROUP';
export const SET_USERS_GROUPS = 'SET_USERS_GROUPS';
export const ADD_USERS_GROUP = 'ADD_USERS_GROUP';
export const USERS_GROUP_FETCHED = 'USERS_GROUP_FETCHED';
export const USERS_GROUP_UPDATED = 'USERS_GROUP_UPDATED';
export const USERS_GROUP_DELETED = 'USERS_GROUP_DELETED';
export const AUTOCOMPLETE_USERS_GROUPS = 'AUTOCOMPLETE_USERS_GROUPS';

export const FETCH_USERS_GROUPS_WITH_ROLE = 'FETCH_USERS_GROUPS_WITH_ROLE';

export const title = 'Users Groups';
export const singularTitle = 'Users Group';
export const moduleName = 'users_groups';
export const baseUrl = '/users_groups';
export const redirectUrl = '/users_groups';
export const listingDataKey = 'users_groups';
export const itemDataKey = 'users_group';

export const userGroupConstants = {
  item: USERS_GROUP,
  setItems: SET_USERS_GROUPS,
  addItem: ADD_USERS_GROUP,
  setItem: USERS_GROUP_FETCHED,
  updateItem: USERS_GROUP_UPDATED,
  deleteItem: USERS_GROUP_DELETED,
  autocompleteItems: AUTOCOMPLETE_USERS_GROUPS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};


export const defaultSearchParams = {
  name_cont: '',
};


// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Users Groups';
export const TargetBaseUrl = 'users_groups';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);


export const VIEW = 'view';
export const EDIT = 'edit';
export const CREATE = 'create';
export const MANAGE = 'manage';
export const BASIC = 'basic';
export const STATUS = 'status';
export const SPECIAL = 'special';

export const handledErrorKeys = {
  master: ['name'],
};
