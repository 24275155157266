import { HOME_BREADCRUMB_LISTING } from './common';

export const CUSTOMER = 'CUSTOMER';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const CUSTOMER_FETCHED = 'CUSTOMER_FETCHED';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED';
export const CUSTOMER_EDIT = 'CUSTOMER_EDIT';
export const DELETE_CUSTOMER_BY_ID = 'DELETE_CUSTOMER_BY_ID';
export const SET_WARNING_BEFORE_MERGEING_CUSTOMER = 'SET_WARNING_BEFORE_MERGEING_CUSTOMER';
export const AUTOCOMPLETE_CUSTOMER_WITH_UNION_BY_ID = 'AUTOCOMPLETE_CUSTOMER_WITH_UNION_BY_ID';
export const RESET_CUSTOMER_ERRORS = 'RESET_CUSTOMER_ERRORS';
export const AUTOCOMPLETE_ALL_CUSTOMERS = 'AUTOCOMPLETE_ALL_CUSTOMERS';
export const AUTOCOMPLETE_ACTIVE_CUSTOMERS = 'AUTOCOMPLETE_ACTIVE_CUSTOMERS';
export const UPDATE_CUSTOMER_ADDRESS = 'UPDATE_CUSTOMER_ADDRESS';
export const CREATE_CUSTOMER_ADDRESS = 'CREATE_CUSTOMER_ADDRESS';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';

export const FORM_INFORMATION_TITLE = 'CUSTOMER INFORMATION';
export const ADD_NEW_CUSTOMER = 'Add New Customer';

export const title = 'Customers';
export const singularTitle = 'Customer';
export const moduleName = 'customers';
export const baseUrl = '/customers';
export const redirectUrl = '/customers';
export const listingDataKey = 'customers';
export const itemDataKey = 'customer';

export const customerConstants = {
  item: CUSTOMER,
  setItems: SET_CUSTOMERS,
  addItem: ADD_CUSTOMER,
  setItem: CUSTOMER_FETCHED,
  updateItem: CUSTOMER_UPDATED,
  deleteItem: CUSTOMER_DELETED,
  resetErrors: RESET_CUSTOMER_ERRORS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const CUSTOMER_GSTIN_FILTER_VALUES = [
  {
    name: 'Registered', label: 'Registered', id: 'true', value: 'true',
  },
  {
    name: 'Not registered', label: 'Not registered', id: 'false', value: 'false',
  },
];

export const DEBIT_NOTE_PARTY_TYPE_FILTER_VALUES = [
  {
    name: 'Customer', label: 'Customer', id: 'Customer', value: 'Customer',
  },
  {
    name: 'Supplier', label: 'Supplier', id: 'Supplier', value: 'Supplier',
  },
];

export const OPENING_BALANCE_TYPES = [
  { label: 'Debit', value: false, description: 'Pay In' },
  { label: 'Credit', value: true, description: 'Pay Out' },
];

export const advanceSearchParams = {
  active_eq: '',
  gstin_cont: '',
  addresses_line_1_or_addresses_line_2_cont: '',
  addresses_city_cont: '',
  addresses_state_in: [],
  branches_id_in: [],
  customer_category_id_in: [],
  customer_rating_id_in: [],
  gstin_present: '',
  beat_routes_id_in: [],
};

export const defaultSearchParams = {
  phone_or_name_or_addresses_mobile_or_contacts_mobile_cont: '',
  ...advanceSearchParams,
};

export const CUSTOMERS_WITH_AND_WITHOUT_GSTIN = [{ label: 'With GSTIN', value: true }, { label: 'Without GSTIN', value: 'false' }];

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = ['customer_ratings', 'customer_categories', 'branches', 'state_codes', 'beat_routes'];

export const Title = 'Customers';
export const TargetBaseUrl = 'customers';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const MDCCM = 'merge_duplicate_customer_confirmation_modal';
export const MDCFM = 'merge_duplicate_customer_form_modal';

export const handledErrorKeys = {
  master: ['name', 'code', 'customer_category', 'customer_category_id', 'customer_rating', 'customer_rating_id', 'phone', 'email', 'active', 'gstin', 'pan', 'tin', 'credit_limit', 'outstanding_invoice_limit', 'outstanding_days_limit', 'opening_balance', 'opening_balance_date', 'beat_routes_ids', 'branch_ids', 'user_ids', 'phone_country_code', 'phone'],
  default_address: ['name', 'mobile', 'landmark', 'line_1', 'line_2', 'city', 'country_code', 'state', 'postal_code', 'latitude', 'longitude', 'gstin', 'fax_number'],
  primary_contact: ['name', 'mobile', 'email', 'designation'],
  customer_specific_taxes: ['tax_id', 'specified_from'],
};
