export const SUPPLIER_CONTACT = 'SUPPLIER_CONTACT';
export const SET_SUPPLIER_CONTACTS = 'SET_SUPPLIER_CONTACTS';
export const ADD_SUPPLIER_CONTACT = 'ADD_SUPPLIER_CONTACT';
export const SUPPLIER_CONTACT_FETCHED = 'SUPPLIER_CONTACT_FETCHED';
export const SUPPLIER_CONTACT_UPDATED = 'SUPPLIER_CONTACT_UPDATED';
export const SUPPLIER_CONTACT_DELETED = 'SUPPLIER_CONTACT_DELETED';
export const SUPPLIER_CONTACT_EDIT = 'SUPPLIER_CONTACT_EDIT';
export const AUTOCOMPLETE_SUPPLIER_CONTACTS = 'AUTOCOMPLETE_SUPPLIER_CONTACTS';

export const OPEN_SUPPLIER_CONTACT_FORM_MODAL = 'OPEN_SUPPLIER_CONTACT_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const title = 'Supplier Contacts';
export const singularTitle = 'Supplier Contact';
export const moduleName = 'supplier_contacts';
export const baseUrl = '/supplier_contacts';
export const redirectUrl = '/supplier_contacts';
export const listingDataKey = 'supplier_contacts';
export const itemDataKey = 'supplier_contact';
export const permissionKey = 'supplier/contacts';

export const supplierContactConstants = {
  item: SUPPLIER_CONTACT,
  setItems: SET_SUPPLIER_CONTACTS,
  addItem: ADD_SUPPLIER_CONTACT,
  setItem: SUPPLIER_CONTACT_FETCHED,
  updateItem: SUPPLIER_CONTACT_UPDATED,
  deleteItem: SUPPLIER_CONTACT_DELETED,
  autocompleteItems: AUTOCOMPLETE_SUPPLIER_CONTACTS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
