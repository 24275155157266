import React from 'react';
import { Link } from 'react-router-dom';
import { planCodeMappingWithName } from '../constants';

interface Props {
  meta: any;
}

const Content = (props: Props) => {
  const { meta } = props;
  const upgradePlan = meta?.upgrade_plan || '';
  const upgradePlanLabel = planCodeMappingWithName?.[upgradePlan] || '';

  if (upgradePlan) {
    return (
      <div className="enforcement-plan">
        <div className="flex-box enforcement-plan-content-section">
          <div className="col-md-12 enforcement-body-wrapper">
            <div className="col-md-7 enforcement-content-wrapper">
              <h2>Upgrade to {`${upgradePlanLabel}`} Plan</h2>
              <p>Scale and grow with strong digital foundation.</p>
            </div>
            <div className="col-md-5 enforcement-button-wrapper">
              <Link to="/billing/pricing" className="btn btn-md btn-primary-outline">CONTINUE</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Content;
