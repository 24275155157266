import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../utils/axios';
import {
  moduleName, AUTOCOMPLETE_ALL_CUSTOMERS, AUTOCOMPLETE_ACTIVE_CUSTOMERS,
  FETCH_CUSTOMERS, ADD_CUSTOMER,
} from '../constants/customers';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { updateAndCloseQuickAddModule } from '../components/common/quick_add_module/helper';
import {
  customerEdit, autocompleteCustomersWithUnionById, deleteCustomerById,
  setWarningBeforeMergeingCustomer,
} from '../actions/customers';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { autocompleteCustomersNameAndPhone, autocompleteActiveCustomersNameAndPhone, fetchCustomers } from '../api/customers';

// Save Customer.
export function saveCustomer(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      customer: data,
    },
  })
    .then((response) => {
      const { customer } = response.data;
      dispatch(addItem({ moduleName, item: customer }));
      const params = { customer_id: customer.id, customer_name: customer.name };
      updateAndCloseQuickAddModule({ identifier: ADD_CUSTOMER, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get Customer.
export function fetchCustomer(id, setReduxFetching = true) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;

  if (setReduxFetching) {
    startFetchingRequest(moduleName);
  }

  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { customer } = response.data;
      dispatch(setItem({ moduleName, item: customer }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Get Customer.
export function editCustomer(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/edit`,
    method: 'get',
  })
    .then((response) => {
      const { customer } = response.data;
      dispatch(customerEdit(customer));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Update Customer.
export function updateCustomer(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      customer: data,
    },
  })
    .then((response) => {
      const { customer } = response.data;
      dispatch(updateItem({ moduleName, item: customer }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete Customer.
export function deleteCustomer(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteCustomerWithUnionById(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_customers_name_and_phone?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { customers } = response.data;
      dispatch(autocompleteCustomersWithUnionById(customers));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function fetchCustomersBasicInfo(search_params) {
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/customers/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      // throw error.response;
    });
}

export function getCustomerAddresses(args, customerId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  return () => axios({
    url: `${baseUrl}/${customerId}/addresses?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function fetchCustomersForReport(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => {
      const { customers, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: customers, current_page: page, total_count, sort_by, sort_order,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Get Customers List (dispatch, getState)
export function filterCustomers(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;

  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  return () => axios({
    url: `${baseUrl}/filter_customers?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function mergeDuplicateCustomers(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const { id, warned, original_customer_id } = args;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/merge_customers`,
    method: 'get',
    params: {
      warned,
      original_customer_id,
    },
  })
    .then((response) => {
      const { customer } = response.data;
      if (warned === true) {
        dispatch(deleteCustomerById(id));
        if (customer) {
          dispatch(setItem({ moduleName, item: customer }));
        }
      }

      if (warned === false) {
        const { warning } = response.data;
        dispatch(setWarningBeforeMergeingCustomer(id, warning));
      }

      return response;
    })
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${id}`, item);
      throw error.response;
    });
}

export function uploadCustomerImage(image, customer_id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios.patch(`${baseUrl}/${customer_id}/upload_image`, image)
    .then((response) => {
      const { customer } = response.data;
      dispatch(setItem({ moduleName, item: customer }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${customer_id}`, item);
      throw error.response;
    });
}

// Download customers in xlsx
export function downloadCustomers(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['item'] });
  const { item } = moduleTypes;
  const q = serializeSearchParams(args);
  return () => axios({
    url: `/customer_feeds/download_customers?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, undefined, item);
      throw error.response;
    });
}

function* fetchCustomersSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchCustomers({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { customers, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: customers,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* allCustomersSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(autocompleteCustomersNameAndPhone(search_params));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* activeCustomersSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(autocompleteActiveCustomersNameAndPhone(search_params));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* customerWatcher() {
  yield takeLatest(FETCH_CUSTOMERS, fetchCustomersSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_ALL_CUSTOMERS, allCustomersSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_ACTIVE_CUSTOMERS, activeCustomersSagaSideEffect);
}
