export const START_TIMING = '@actions/GoogleAnalyticsActions/START_TIMING';
export const STOP_TIMING = '@actions/GoogleAnalyticsActions/STOP_TIMING';

export function StartTiming(category, variable) {
  return { type: START_TIMING, category, variable };
}

export function StopTiming() {
  return { type: STOP_TIMING };
}
