import { HOME_BREADCRUMB_LISTING } from './common';

export const WAREHOUSE = 'WAREHOUSE';
export const SET_WAREHOUSES = 'SET_WAREHOUSES';
export const ADD_WAREHOUSE = 'ADD_WAREHOUSE';
export const WAREHOUSE_FETCHED = 'WAREHOUSE_FETCHED';
export const WAREHOUSE_UPDATED = 'WAREHOUSE_UPDATED';
export const WAREHOUSE_DELETED = 'WAREHOUSE_DELETED';
export const RESET_WAREHOUSE_ERRORS = 'RESET_WAREHOUSE_ERRORS';
export const AUTOCOMPLETE_WAREHOUSES = 'AUTOCOMPLETE_WAREHOUSES';

export const FORM_INFORMATION_TITLE = 'WAREHOUSE INFORMATION';
export const ADD_NEW_WAREHOUSE = 'Add New Warehouse';

export const title = 'Warehouses';
export const singularTitle = 'Warehouse';
export const moduleName = 'warehouses';
export const baseUrl = '/warehouses';
export const redirectUrl = '/warehouses';
export const listingDataKey = 'warehouses';
export const itemDataKey = 'warehouse';

export const warehouseConstants = {
  item: WAREHOUSE,
  setItems: SET_WAREHOUSES,
  addItem: ADD_WAREHOUSE,
  setItem: WAREHOUSE_FETCHED,
  updateItem: WAREHOUSE_UPDATED,
  deleteItem: WAREHOUSE_DELETED,
  resetErrors: RESET_WAREHOUSE_ERRORS,
  autocompleteItems: AUTOCOMPLETE_WAREHOUSES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Warehouses';
export const TargetBaseUrl = 'warehouses';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name'],
  address: ['name', 'phone', 'alt_phone', 'mobile', 'landmark', 'line_1', 'line_2', 'city', 'state', 'country_code', 'postal_code'],
};
