import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CREATE_PURCHASE_ORDER_TERM, FETCH_DELETE_PURCHASE_ORDER_TERM, FETCH_PURCHASE_ORDER_TERM,
  FETCH_PURCHASE_ORDER_TERMS, FETCH_UPDATE_PURCHASE_ORDER_TERM, moduleName,
} from '@constants/purchase_order_terms';
import {
  setItems, setItem, addItem, updateItem, deleteItem,
} from '@actions/common';
import {
  deletePurchaseOrderTerm, fetchPurchaseOrderTerm as fetchPurchaseOrderTermAction,
  fetchPurchaseOrderTerms as fetchPurchaseOrderTermsAction, savePurchaseOrderTerm,
  updatePurchaseOrderTerm,
} from '@actions/purchase_order_terms';

function* fetchPurchaseOrderTerms(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchPurchaseOrderTermsAction({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { purchase_order_terms: purchaseOrderTerms, meta } = response?.data || {};
    const { pagination } = meta || {};
    const { total_count: totalCount } = pagination || {};

    yield put(setItems({
      moduleName,
      responseData: {
        items: purchaseOrderTerms || [],
        current_page: page,
        total_count: totalCount || 0,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action?.resolve(response);
  } catch (e) {
    action?.reject(e);
  }
}

function* fetchPurchaseOrderTerm(action) {
  const { id } = action;

  try {
    const response = yield call(fetchPurchaseOrderTermAction(id));
    const { purchase_order_term: purchaseOrderTerm } = response.data;
    yield put(setItem({ moduleName, item: purchaseOrderTerm }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchCreatePurchaseOrderTerm(action) {
  const { purchase_order_term: purchaseOrderTerm } = action || {};
  try {
    const response = yield call(savePurchaseOrderTerm(purchaseOrderTerm));
    const { purchase_order_term: responsePurchaseOrderTerm } = response?.data || {};
    yield put(addItem({ moduleName, item: responsePurchaseOrderTerm }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchUpdatePurchaseOrderTerm(action) {
  const { purchase_order_term: purchaseOrderTerm } = action || {};
  try {
    const response = yield call(updatePurchaseOrderTerm(purchaseOrderTerm || {}));
    const { purchase_order_term: responsePurchaseOrderTerm } = response?.data || {};
    yield put(updateItem({ moduleName, item: responsePurchaseOrderTerm }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchDeletePurchaseOrderTerm(action) {
  const { id } = action;
  try {
    const response = yield call(deletePurchaseOrderTerm(id));
    yield put(deleteItem({ moduleName, id }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* purchaseOrderTermWatcher() {
  yield takeLatest(FETCH_PURCHASE_ORDER_TERMS, fetchPurchaseOrderTerms);
  yield takeLatest(FETCH_PURCHASE_ORDER_TERM, fetchPurchaseOrderTerm);
  yield takeLatest(FETCH_CREATE_PURCHASE_ORDER_TERM, fetchCreatePurchaseOrderTerm);
  yield takeLatest(FETCH_UPDATE_PURCHASE_ORDER_TERM, fetchUpdatePurchaseOrderTerm);
  yield takeLatest(FETCH_DELETE_PURCHASE_ORDER_TERM, fetchDeletePurchaseOrderTerm);
}
