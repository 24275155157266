import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrors } from '@utils/handle_errors_helpers';
import {
  moduleName, ADD_VARIANT, AUTOCOMPLETE_ORDERABLE_PRODUCTS, AUTOCOMPLETE_ALL_PRODUCTS,
  AUTOCOMPLETE_RETURNABLE_BY_CUSTOMER_PRODUCTS, AUTOCOMPLETE_SELLABLE_PRODUCTS,
  AUTOCOMPLETE_BASIC_INFO_PRODUCTS, FETCH_PRODUCTS, FETCH_SELLABLE_PRODUCTS,
  FETCH_ALL_PRODUCTS,
} from '@constants/products';
import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams } from '@utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import {
  fetchOrderableProducts, autocompleteAllProducts, fetchProductsBasicInfo,
  fetchProductsReturnableByCustomer, fetchSellableProducts, fetchProducts,
} from '@api/products';
import { successNotification } from '@actions/root';

export function fetchNewProduct({ type }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  return () => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    params: {
      type,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function addVariant(product, parent_id) {
  return {
    type: ADD_VARIANT,
    item: product,
    parent_id,
  };
}

// Save Product.
export function saveProduct(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data,
  })
    .then((response) => {
      const { product } = response.data;
      const { parent_id, variantable } = product;

      if (parent_id && (variantable === false)) {
        dispatch(addVariant(product, parent_id));
      } else {
        dispatch(addItem({ moduleName, item: product }));
      }
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get Product.
export function fetchProduct(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { product } = response.data;
      dispatch(setItem({ moduleName, item: product }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update Product.
export function updateProduct(id, productData) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'put',
    data: productData,
  })
    .then((response) => {
      const { product, meta } = response.data;
      successNotification({ message: meta.notice, renderView: true });
      dispatch(updateItem({ moduleName, item: product }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete Product.
export function deleteProduct(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Download products in xlsx
export function downloadProducts(args) {
  const q = serializeSearchParams(args);
  return () => axios({
    url: `/product_feeds/download_products?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.data) {
        addErrors(error, error.response.data.errors, 'FEED');
      }
      throw error.response;
    });
}

export function uploadImages(data, id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios.patch(`${baseUrl}/${id}/upload_images`, data)
    .then((response) => {
      const { product, meta } = response.data;
      successNotification({ message: meta.notice, renderView: true });
      dispatch(setItem({ moduleName, item: product }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item, false);
      throw error.response;
    });
}

export function duplicateProduct(productId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    // baseURL: `${BASE_URL}`,
    params: {
      product_id: productId,
    },
  })
    .then((response) => {
      const { product } = response?.data || {};
      dispatch(setItem({ moduleName, item: product }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchProductsSagaSideEffect(action: any) {
  const {
    url, search_params, per_page, page, sort_by, sort_order, selected_variant, dont_merge_search_params,
  } = action;
  try {
    const response = yield call(fetchProducts({
      url, search_params, per_page, page, sort_by, sort_order, selected_variant, dont_merge_search_params,
    }));
    const { products, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;
    yield put(setItems({
      moduleName,
      responseData: {
        items: products,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* orderableProductsSagaSideEffect(action: any) {
  const { search_params, customer_id, warehouse_id } = action;
  try {
    const response = yield call(fetchOrderableProducts({ search_params, customer_id, warehouse_id }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* allProductsSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(autocompleteAllProducts({ search_params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* returnableByCustomerProductsSagaSideEffect(action: any) {
  const { search_params, customer_id } = action;
  try {
    const response = yield call(fetchProductsReturnableByCustomer({ search_params, customer_id, url: action?.url || '' }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* sellableProductsSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(fetchSellableProducts({ search_params }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* basicInfoProductsSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(fetchProductsBasicInfo({ search_params }));
    const { products } = response.data;
    yield put(autocompleteItems({ moduleName, items: products }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* fetchSellableProductsSagaSideEffect(action: any) {
  const { search_params } = action;
  try {
    const response = yield call(fetchSellableProducts({ search_params }));
    const { products } = response.data;
    yield put(autocompleteItems({ moduleName, items: products }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* productWatcher() {
  yield takeLatest(FETCH_PRODUCTS, fetchProductsSagaSideEffect);
  yield takeLatest(FETCH_ALL_PRODUCTS, fetchProductsSagaSideEffect);
  yield takeLatest(FETCH_SELLABLE_PRODUCTS, fetchSellableProductsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_ORDERABLE_PRODUCTS, orderableProductsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_ALL_PRODUCTS, allProductsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_RETURNABLE_BY_CUSTOMER_PRODUCTS, returnableByCustomerProductsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_SELLABLE_PRODUCTS, sellableProductsSagaSideEffect);
  yield takeLatest(AUTOCOMPLETE_BASIC_INFO_PRODUCTS, basicInfoProductsSagaSideEffect);
}
