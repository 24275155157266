import { call, put, takeLatest } from 'redux-saga/effects';
import { moduleName, FETCH_PURCHASE_INVOICES } from '../constants/purchase_invoices';
import axios from '../utils/axios';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams } from '../utils/SerializeParams';
import { BASE_URL } from '../constants/common';
import {
  setItems, addItem, setNewItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { fetchPurchaseInvoices } from '../api/purchase_invoices';

// Purchase Invoice New.
export function newPurchaseInvoice(purchaseOrderId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    params: {
      purchase_order_id: purchaseOrderId,
    },
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(setNewItem({ moduleName, params: { purchase_invoice } }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save PurchaseInvoice.
export function savePurchaseInvoice(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      purchase_invoice: data,
    },
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(addItem({ moduleName, item: purchase_invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get PurchaseInvoice.
export function fetchPurchaseInvoice(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(setItem({ moduleName, item: purchase_invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update PurchaseInvoice.
export function updatePurchaseInvoice(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      purchase_invoice: data,
    },
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete PurchaseInvoice.
export function deletePurchaseInvoice(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: {
      state,
    },
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(updateItem({ moduleName, item: purchase_invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompletePurchaseInvoiceNumber({ customer_id, search_params }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `/customers/${customer_id}${baseUrl}/autocomplete_purchase_invoices_number?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { purchase_invoices } = response.data;
      dispatch(autocompleteItems({ moduleName, items: purchase_invoices }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function duplicatePurchaseInvoice(purchase_invoice_id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  return (dispatch) => axios({
    url: `${baseUrl}/new`,
    method: 'get',
    baseURL: `${BASE_URL}`,
    params: {
      purchase_invoice_id,
    },
  })
    .then((response) => {
      const { purchase_invoice } = response.data;
      dispatch(setItem({ moduleName, item: purchase_invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function unpaidPurchaseInvoices({ supplier_id, search_params }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/purchase_invoices/autocomplete_unpaid?${q}`,
    method: 'get',
    params: { supplier_id },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompletePurchaseInvoices(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/purchase_invoices/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchPurchaseInvoicesSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchPurchaseInvoices({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { purchase_invoices, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: purchase_invoices,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* purchaseInvoicesWatcher() {
  yield takeLatest(FETCH_PURCHASE_INVOICES, fetchPurchaseInvoicesSagaSideEffect);
}
