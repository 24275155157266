import * as types from '../constants/print_formats';

interface SetSettableCategoryProps {
  settableCategoryName: string;
  payload: any;
}

export function setSettableCategory({ settableCategoryName, payload }: SetSettableCategoryProps) {
  return {
    type: types.SET_SETTABLE_CATEGORY,
    settableCategoryName,
    payload,
  };
}

interface SetPrintFormatsProps {
  settableCategoryName: string;
  payload: any;
}

export function setPrintFormats({ settableCategoryName, payload }: SetPrintFormatsProps) {
  return {
    type: types.SET_PRINT_FORMATS,
    settableCategoryName,
    payload,
  };
}

interface ResetPrintFormatsProps {
  settableCategoryName: string;
}

export function resetPrintFormats({ settableCategoryName }: ResetPrintFormatsProps) {
  return {
    type: types.RESET_PRINT_FORMATS,
    settableCategoryName,
  };
}

interface PrintFormatFetchedProps {
  settableCategoryName: string;
  payload: any;
}

export function printFormatFetched({ settableCategoryName, payload }: PrintFormatFetchedProps) {
  return {
    type: types.PRINT_FORMAT_FETCHED,
    settableCategoryName,
    payload,
  };
}

interface AddPrintFormatProps {
  settableCategoryName: string;
  payload: any;
}

export function addPrintFormat({ settableCategoryName, payload }: AddPrintFormatProps) {
  return {
    type: types.ADD_PRINT_FORMAT,
    settableCategoryName,
    payload,
  };
}

interface DuplicatePrintFormatProps {
  settableCategoryName: string;
  payload: any;
}

export function duplicatePrintFormat({ settableCategoryName, payload }: DuplicatePrintFormatProps) {
  return {
    type: types.DUPLICATE_PRINT_FORMAT,
    settableCategoryName,
    payload,
  };
}

interface PrintFormatCreatedProps {
  settableCategoryName: string;
  payload: any;
  idx: number;
}

export function printFormatCreated({ settableCategoryName, payload, idx }: PrintFormatCreatedProps) {
  return {
    type: types.PRINT_FORMAT_CREATED,
    settableCategoryName,
    payload,
    idx,
  };
}

interface PrintFormatUpdatedProps {
  settableCategoryName: string;
  payload: any;
}

export function printFormatUpdated({ settableCategoryName, payload }: PrintFormatUpdatedProps) {
  return {
    type: types.PRINT_FORMAT_UPDATED,
    settableCategoryName,
    payload,
  };
}

interface PrintFormatDeletedProps {
  settableCategoryName: string;
  idx: number;
}

export function printFormatDeleted({ settableCategoryName, idx }: PrintFormatDeletedProps) {
  return {
    type: types.PRINT_FORMAT_DELETED,
    settableCategoryName,
    idx,
  };
}

interface PrintFormatPreviewProps {
  settableCategoryName: string;
  id: number;
  payload: any;
}

export function printFormatPreview({ settableCategoryName, id, payload }: PrintFormatPreviewProps) {
  return {
    type: types.PRINT_FORMAT_PREVIEW,
    settableCategoryName,
    id,
    payload,
  };
}
