import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_PRODUCT_CATEGORIES, ADD_PRODUCT_CATEGORY, PRODUCT_CATEGORY_FETCHED,
  PRODUCT_CATEGORY_UPDATED, PRODUCT_CATEGORY_DELETED, AUTOCOMPLETE_PRODUCT_CATEGORIES,
  defaultSearchParams, moduleName,
} from '@constants/product_categories';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function productCategories(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES: return setItems(state, action);
    case ADD_PRODUCT_CATEGORY: return addItem(state, action);
    case PRODUCT_CATEGORY_FETCHED: return fetchItem(state, action);
    case PRODUCT_CATEGORY_UPDATED: return updateItem(state, action);
    case PRODUCT_CATEGORY_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_PRODUCT_CATEGORIES: return autocomplete(state, action);
    default:
      return state;
  }
}
