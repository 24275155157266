import React from 'react';
import {
  object, bool, func, string, number, shape, oneOfType, node,
} from 'prop-types';
import { Link } from 'react-router-dom';
import variables from '@assets/css/_variables.module.scss';
import OverlayTriggerTooltip from '@component/common/OverlayTriggerTooltip';
import { ActionEnum, CategoryEnum, logEvent } from '@utils/GAUtils';

// STYLES
const badgeStyle = {
  color: variables.white,
  position: 'absolute',
  top: -6,
  right: -6,
  zIndex: 10,
  fontSize: 10,
  padding: '2px 4px',
  background: variables.primary,
};

const commentsSectionToggleListItembadgeStyle = {
  color: variables.white,
  top: -6,
  right: -6,
  zIndex: 10,
  fontSize: 10,
  padding: '2px 4px',
  background: variables.primary,
};

export function CanUpdate({
  can_update, can_open_inline_modal, path, editing_what, updateable_with_detail,
  openModal, modal_identifier_args, btn_classes, cb, cbArgs,
}) {
  if (can_update) {
    if (updateable_with_detail?.updateable) {
      return (
        <OverlayTriggerTooltip placement="top" description={updateable_with_detail?.detail || 'Edit'}>
          {
            (can_open_inline_modal)
              ? (
                <a
                  className={`btn btn-sm btn-default action-btn ${btn_classes}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(modal_identifier_args);
                    logEvent(`${editing_what}`, `${editing_what}_Index_Item_Action`, `${editing_what}_Edit`);
                    cb(cbArgs);
                  }}
                  role="presentation"
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </a>
              )
              : (
                <Link
                  className={`btn btn-sm btn-default action-btn ${btn_classes}`}
                  to={path}
                  onClick={(e) => {
                    e.stopPropagation();
                    logEvent(`${editing_what}`, `${editing_what}_Index_Item_Action`, `${editing_what}_Edit`);
                    cb(cbArgs);
                  }}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </Link>
              )
          }
        </OverlayTriggerTooltip>
      );
    }
    return (
      <OverlayTriggerTooltip placement="top" description={updateable_with_detail?.detail || ''}>
        <a
          className={`btn btn-sm btn-default action-btn ${btn_classes}`}
          disabled
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </a>
      </OverlayTriggerTooltip>
    );
  }
  return null;
}

CanUpdate.propTypes = {
  can_update: bool,
  can_open_inline_modal: bool,
  path: string,
  editing_what: string,
  updateable_with_detail: object,
  openModal: func,
  modal_identifier_args: object,
  btn_classes: string,
  cb: func,
  cbArgs: object,
};

CanUpdate.defaultProps = {
  can_update: false,
  can_open_inline_modal: false,
  path: '',
  editing_what: '',
  updateable_with_detail: { updateable: true, detail: 'Edit' },
  openModal: () => { },
  modal_identifier_args: {},
  btn_classes: '',
  cb: () => { },
  cbArgs: {},
};

export function CanDelete({
  can_destroy, openModal, deleting_what, destroyable_with_detail, modal_identifier_args,
  btn_classes,
}) {
  if (can_destroy) {
    if (destroyable_with_detail?.destroyable) {
      return (
        <OverlayTriggerTooltip placement="top" description={destroyable_with_detail?.detail || 'Delete'}>
          <a
            className={`btn btn-sm btn-danger action-btn ${btn_classes}`}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              openModal(modal_identifier_args);
              logEvent(`${deleting_what}`, `${deleting_what}_Index_Item_Action`, `${deleting_what}_Delete`);
            }}
          >
            <i className="fa fa-trash-o" aria-hidden="true" />
          </a>
        </OverlayTriggerTooltip>
      );
    }

    return (
      <OverlayTriggerTooltip placement="top" description={(destroyable_with_detail?.detail) || 'Delete'}>
        <a
          className={`btn btn-sm btn-danger action-btn ${btn_classes}`}
          role="button"
          disabled
        >
          <i className="fa fa-trash-o" aria-hidden="true" />
        </a>
      </OverlayTriggerTooltip>
    );
  }
  return null;
}

CanDelete.propTypes = {
  can_destroy: bool,
  deleting_what: string,
  destroyable_with_detail: object,
  openModal: func,
  modal_identifier_args: object,
  btn_classes: string,
};

CanDelete.defaultProps = {
  can_destroy: false,
  deleting_what: '',
  destroyable_with_detail: { destroyable: true, detail: 'Delete' },
  openModal: () => { },
  modal_identifier_args: {},
  btn_classes: '',
};

const destroyableLineItem = ({
  menuItemName, btn_classes, modal_identifier_args, deleting_what, openModal,
}) => (
  <li
    className={`listing-item delete-item ${btn_classes}`}
    tabIndex={0}
    onClick={(e) => {
      e.stopPropagation();
      openModal(modal_identifier_args);
      logEvent(`${deleting_what}`, `${deleting_what}_Index_Item_Action`, `${deleting_what}_Delete`);
    }}
  >
    {menuItemName}
  </li>
);

export function CanDeleteItem({
  menuItemName, can_destroy, openModal, deleting_what, destroyable_with_detail,
  modal_identifier_args, btn_classes,
}) {
  if (can_destroy) {
    if (destroyable_with_detail?.destroyable) {
      if (destroyable_with_detail?.detail) {
        return (
          <OverlayTriggerTooltip placement="left" description={destroyable_with_detail.detail}>
            {destroyableLineItem({
              menuItemName, btn_classes, modal_identifier_args, deleting_what, openModal,
            })}
          </OverlayTriggerTooltip>
        );
      }
      return (
        destroyableLineItem({
          menuItemName, btn_classes, modal_identifier_args, deleting_what, openModal,
        })
      );
    }
    if (destroyable_with_detail?.detail) {
      return (
        <OverlayTriggerTooltip placement="left" description={(destroyable_with_detail.detail)}>
          <li
            className={`listing-item delete-item disabled ${btn_classes}`}
          >
            {menuItemName}
          </li>
        </OverlayTriggerTooltip>
      );
    }

    return (
      <li
        className={`listing-item delete-item disabled ${btn_classes}`}
      >
        {menuItemName}
      </li>
    );
  }
  return null;
}

CanDeleteItem.propTypes = {
  menuItemName: oneOfType([string, node]),
  can_destroy: bool,
  deleting_what: string,
  destroyable_with_detail: object,
  openModal: func,
  modal_identifier_args: object,
  btn_classes: string,
};

CanDeleteItem.defaultProps = {
  menuItemName: 'Delete',
  can_destroy: false,
  deleting_what: '',
  destroyable_with_detail: { destroyable: true, detail: 'Delete' },
  openModal: () => { },
  modal_identifier_args: {},
  btn_classes: '',
};

export const CommentsSectionToggleBtn = ({
  toggle, btn_classes, modal_identifier_args, commentsCount, setActiveCommentable, togglingWhat,
}) => (
  <OverlayTriggerTooltip placement="left" description="Show Comments">
    <span
      className={`btn btn-sm btn-primary action-btn ${btn_classes}`}
      role="presentation"
      style={{ paddingTop: 1, position: 'relative' }}
      onClick={(e) => {
        e.stopPropagation();
        setActiveCommentable(
          modal_identifier_args.commentable,
          () => toggle(modal_identifier_args.commentsUrl, modal_identifier_args.commentableId),
        );
        // LOG_EVENT   log event for opening comments modal for a module's(togglingWhat) index item
        logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Comments_Side_Modal`);
      }}
    >
      <i className="mdi mdi-message-text" aria-hidden="true" />
      {parseInt(commentsCount, 10) ? (
        <span
          className="badge badge-primary"
          style={badgeStyle}
        >
          {commentsCount}
        </span>
      ) : null}
    </span>
  </OverlayTriggerTooltip>
);

CommentsSectionToggleBtn.propTypes = {
  toggle: func.isRequired,
  btn_classes: string,
  togglingWhat: string.isRequired,
  modal_identifier_args: shape({}).isRequired,
  commentsCount: number,
  setActiveCommentable: func.isRequired,
};

export const VisitsSectionToggleBtn = ({
  toggle, btnClasses, modalIdentifierArgs, visitsCount, setActiveVisitable, togglingWhat,
}) => (
  <OverlayTriggerTooltip placement="left" description="Show Visits">
    <span
      className={`btn btn-sm btn-primary action-btn ${btnClasses}`}
      role="presentation"
      style={{ paddingTop: 1, position: 'relative' }}
      onClick={(e) => {
        e.stopPropagation();
        setActiveVisitable(
          modalIdentifierArgs?.visitable,
          () => toggle(modalIdentifierArgs?.visitsUrl, modalIdentifierArgs?.visitable),
        );
        // LOG_EVENT   log event for opening visits modal for a module's(togglingWhat) index item
        logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Visits_Side_Modal`);
      }}
    >
      <i className="mdi mdi-message-text" aria-hidden="true" />
      {parseInt(visitsCount, 10) ? (
        <span
          className="badge badge-primary"
          style={badgeStyle}
        >
          {visitsCount}
        </span>
      ) : null}
    </span>
  </OverlayTriggerTooltip>
);

VisitsSectionToggleBtn.propTypes = {
  toggle: func.isRequired,
  btnClasses: string,
  togglingWhat: string.isRequired,
  modalIdentifierArgs: shape({}).isRequired,
  visitsCount: number,
  setActiveVisitable: func.isRequired,
};

export const CommentsSectionToggleListItem = ({
  toggle, btn_classes, modal_identifier_args, commentsCount, setActiveCommentable, togglingWhat,
}) => (
  <OverlayTriggerTooltip placement="left" description="Show Comments">
    <li
      className={`listing-item ${btn_classes}`}
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();
        // LOG_EVENT   log event for opening comments modal for a module's(togglingWhat) index item
        logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Comments_Side_Modal`);

        setActiveCommentable(
          modal_identifier_args.commentable,
          () => toggle(modal_identifier_args.commentsUrl, modal_identifier_args.commentableId),
        );
      }}
    >
      Comments&nbsp;
      {parseInt(commentsCount, 10) ? (
        <span
          className="badge badge-primary"
          style={commentsSectionToggleListItembadgeStyle}
        >
          {commentsCount}
        </span>
      ) : null}
    </li>
  </OverlayTriggerTooltip>
);

CommentsSectionToggleListItem.propTypes = {
  toggle: func.isRequired,
  togglingWhat: string.isRequired,
  btn_classes: string,
  modal_identifier_args: shape({}).isRequired,
  commentsCount: number,
  setActiveCommentable: func.isRequired,
};

export const CommentsSectionToggleBtn2 = ({
  modal_identifier_args, openActModal, setActData, commentsCount, btn_classes, togglingWhat,
}) => {
  const identifier = 'comments';
  const { commentable, commentsUrl, commentableId } = modal_identifier_args;

  return (
    <OverlayTriggerTooltip placement="left" description="Show Comments">
      <span
        className={`btn btn-sm btn-primary action-btn ${btn_classes}`}
        role="presentation"
        style={{ paddingTop: 1, position: 'relative' }}
        onClick={(e) => {
          e.stopPropagation();
          // LOG_EVENT   log event for opening comments modal for a module's(togglingWhat) index item
          logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Comments_Side_Modal`);

          setActData({
            identifier,
            actData: { commentable, commentsUrl, commentableId },
            cb: () => openActModal({ identifier }),
          });
        }}
      >
        <i className="mdi mdi-message-text" aria-hidden="true" />
        {parseInt(commentsCount, 10) ? (
          <span
            className="badge badge-primary"
            style={badgeStyle}
          >
            {commentsCount}
          </span>
        ) : null}
      </span>
    </OverlayTriggerTooltip>
  );
};

CommentsSectionToggleBtn2.propTypes = {
  openActModal: func.isRequired,
  setActData: func.isRequired,
  togglingWhat: string.isRequired,
  btn_classes: string,
  modal_identifier_args: shape({}).isRequired,
  commentsCount: oneOfType([number, string]),
};

CommentsSectionToggleBtn2.defaultProps = {
  btn_classes: '',
  commentsCount: 0,
};

export const EmailShareToggleBtn = ({
  modal_identifier_args, openActModal, setActData, togglingWhat,
}) => {
  const identifier = 'email';

  return (
    <OverlayTriggerTooltip placement="left" description="Send Email">
      <span
        className="btn btn-sm btn-primary action-btn"
        role="presentation"
        style={{ paddingTop: 1, position: 'relative' }}
        onClick={(e) => {
          e.stopPropagation();

          // LOG_EVENT   log event for opening email modal for a module's(togglingWhat) index item
          logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Email_Modal`);

          setActData({
            identifier,
            actData: {
              recordId: modal_identifier_args.recordId,
            },
            cb: () => openActModal({ identifier }),
          });
        }}
      >
        <i className="fa fa-envelope" aria-hidden="true" style={{ lineHeight: '20px', fontSize: 14 }} />
      </span>
    </OverlayTriggerTooltip>
  );
};

EmailShareToggleBtn.propTypes = {
  openActModal: func.isRequired,
  setActData: func.isRequired,
  modal_identifier_args: shape({}).isRequired,
  togglingWhat: string.isRequired,
};

CommentsSectionToggleBtn.defaultProps = { btn_classes: '', commentsCount: 0 };

export const EmailShareOption = ({
  modal_identifier_args, openActModal, setActData, togglingWhat, menuItemClassName,
}) => {
  const identifier = 'email';

  return (
    <li
      className={`listing-item ${menuItemClassName}`}
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();

        // LOG_EVENT   log event for opening email modal for a module's(togglingWhat) index item
        logEvent(`${togglingWhat}`, `${togglingWhat}_Index_Item_Action`, `${togglingWhat}_Open_Email_Modal`);

        setActData({
          identifier,
          actData: {
            recordId: modal_identifier_args.recordId,
          },
          cb: () => openActModal({ identifier }),
        });
      }}
    >
      Send Email
    </li>
  );
};

EmailShareOption.propTypes = {
  openActModal: func.isRequired,
  setActData: func.isRequired,
  togglingWhat: string.isRequired,
  modal_identifier_args: shape({}).isRequired,
  menuItemClassName: string,
};

EmailShareOption.defaultProps = {
  menuItemClassName: '',
};

export function CanDownload({
  canDownload, args, handleDownload,
}) {
  const downloadDescription = canDownload ? 'Download' : 'Download is processing';

  if (canDownload) {
    return (
      <OverlayTriggerTooltip placement="top" description={downloadDescription}>
        <a className="btn btn-sm btn-primary action-btn " role="button" onClick={(e) => handleDownload(e, { ...args })}>
          <i className="fa fa-download" />
        </a>
      </OverlayTriggerTooltip>
    );
  }

  return (
    <OverlayTriggerTooltip placement="top" description={downloadDescription}>
      <a className="btn btn-sm btn-primary action-btn " role="button" disabled>
        <i className="fa fa-download" />
      </a>
    </OverlayTriggerTooltip>
  );
}

CanDownload.propTypes = {
  canDownload: bool,
  args: object,
  handleDownload: func,
};

CanDownload.defaultProps = {
  canDownload: true,
  args: {},
  handleDownload: () => { },
};

export function DuplicateProduct({ url, product }) {
  let show = false;
  let description = '';
  let disabled = false;

  if (product?.parent_id) {
    show = true;
    description = 'Duplicate Product.';
    disabled = false;
  }

  if (!product?.parent_id && product?.variantable) {
    show = true;
    description = "Can't Duplicate Parent Product.";
    disabled = true;
  }

  if (disabled) {
    return (
      <OverlayTriggerTooltip show={show} placement="top" description={description}>
        <a
          className="btn btn-sm btn-primary action-btn duplicate"
          disabled
        >
          <i className="fa fa-clone" aria-hidden="true" />
        </a>
      </OverlayTriggerTooltip>
    );
  }

  return (
    <OverlayTriggerTooltip show={show} placement="top" description={description}>
      <Link
        to={url}
        className="btn btn-sm btn-primary action-btn duplicate"
        onClick={() => logEvent(
          CategoryEnum.ORDER_DETAIL,
          ActionEnum.COPY, ActionEnum.COPY,
        )}
      >
        <i className="fa fa-clone" aria-hidden="true" />
      </Link>
    </OverlayTriggerTooltip>
  );
}

DuplicateProduct.propTypes = {
  url: string.isRequired,
  product: shape({}).isRequired,
};
