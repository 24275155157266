export const SETTINGS_REQUEST = '@actions/SettingsActions/SETTINGS_REQUEST';
export const SETTINGS_RESPONSE = '@actions/SettingsActions/SETTINGS_RESPONSE';
export const SETTINGS_UPDATE_REQUEST = '@actions/SettingsActions/SETTINGS_UPDATE_REQUEST';
export const SETTINGS_UPDATE_RESPONSE = '@actions/SettingsActions/SETTINGS_UPDATE_RESPONSE';
export const SETTINGS_RESET_REQUEST = '@actions/SettingsActions/SETTINGS_RESET_REQUEST';
export const SETTINGS_ERROR_RESPONSE = '@actions/SettingsActions/SETTINGS_ERROR_REQUEST';

export function settingsRequestAction() {
  return {
    type: SETTINGS_REQUEST,
  };
}

export function settingsResponseAction(response = {}) {
  return {
    type: SETTINGS_RESPONSE, response,
  };
}

export function settingsUpdateRequestAction() {
  return {
    type: SETTINGS_UPDATE_REQUEST,
  };
}

export function settingsResetRequestAction() {
  return {
    type: SETTINGS_RESET_REQUEST,
  };
}

export function settingsUpdateResponseAction(response = {}) {
  return {
    type: SETTINGS_UPDATE_RESPONSE, response,
  };
}

export function settingsErrorsResponseAction(response) {
  return {
    type: SETTINGS_ERROR_RESPONSE, response,
  };
}
