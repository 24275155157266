import { HOME_BREADCRUMB_LISTING } from './common';

export const PERIOD_LOCK = 'PERIOD_LOCK';
export const SET_PERIOD_LOCKS = 'SET_PERIOD_LOCKS';
export const ADD_PERIOD_LOCK = 'ADD_PERIOD_LOCK';
export const PERIOD_LOCK_FETCHED = 'PERIOD_LOCK_FETCHED';
export const PERIOD_LOCK_UPDATED = 'PERIOD_LOCK_UPDATED';
export const PERIOD_LOCK_DELETED = 'PERIOD_LOCK_DELETED';
export const PERIOD_LOCK_EDIT = 'PERIOD_LOCK_EDIT';

export const title = 'Period locks';
export const singularTitle = 'Period lock';
export const moduleName = 'period_locks';
export const baseUrl = '/period_locks';
export const redirectUrl = '/period_locks';
export const listingDataKey = 'period_locks';
export const itemDataKey = 'period_lock';

export const periodLockConstants = {
  item: PERIOD_LOCK,
  setItems: SET_PERIOD_LOCKS,
  addItem: ADD_PERIOD_LOCK,
  setItem: PERIOD_LOCK_FETCHED,
  updateItem: PERIOD_LOCK_UPDATED,
  deleteItem: PERIOD_LOCK_DELETED,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// SETUP-SECTION quick add
export const FORM_INFORMATION_TITLE = 'PERIOD LOCK INFORMATION';
export const RESET_PERIOD_LOCK_ERRORS = 'RESET_PERIOD_LOCK_ERRORS';
export const ADD_NEW_PERIOD_LOCK = 'Add New Period Lock';
// !SETUP-SECTION quick add

export const advanceSearchParams = {
  from_date_gteq: '',
  to_date_lteq: '',
};

export const defaultSearchParams = {
  ...advanceSearchParams,
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

// Breadcrumb
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: baseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['from_date', 'to_date'],
};
