import ReactGA from 'react-ga';
import { START_TIMING, STOP_TIMING } from '../actions/GAActions';
import { getCurrentTimestamp } from '../utils/GAUtils';

export const defaultGATimingState = {
  startTime: '',
  endTime: '',
  category: '',
  variable: '',
};

export default function GATimingReducer(state = defaultGATimingState, action) {
  switch (action.type) {
    case START_TIMING:
      return {
        ...state,
        startTime: getCurrentTimestamp(),
        category: action.category,
        variable: action.variable,
      };
    case STOP_TIMING: {
      const { category, variable, startTime } = state;
      ReactGA.timing({
        category,
        variable,
        value: (getCurrentTimestamp() - startTime), // in milliseconds
      });
      return defaultGATimingState;
    }
    default: return state;
  }
}
