import Loadable from 'react-loadable';
import Loading from '../components/common/page_loading';

// We use loadable for order report.
const OrdersReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsOrdersReport" */ '../containers/reports/OrdersReport'),
  loading: Loading,
});

// We use loadable for outstanding balance report.
const OutstandingBalanceReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsOutstandingBalanceReport" */ '../containers/reports/OutstandingBalanceReport'),
  loading: Loading,
});

// We use loadable for invoice report.
const InvoiceReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsInvoiceReport" */ '../containers/reports/InvoiceReport'),
  loading: Loading,
});

// We use loadable for purchase invoice report.
const PurchaseInvoiceReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsPurchaseInvoiceReport" */ '../containers/reports/PurchaseInvoiceReport'),
  loading: Loading,
});

// We use loadable for payment report.
const PaymentReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsPaymentReport" */ '../containers/reports/PaymentReport'),
  loading: Loading,
});

// We use loadable for gst report.
const GstReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsGstReport" */ '../containers/reports/gst_report'),
  loading: Loading,
});

// We use loadable for sales performance report.
const SalesmanPerformanceReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsSalesmanPerformanceReport" */ '../containers/reports/SalesmanPerformanceReport'),
  loading: Loading,
});

// We use loadable for sales brand report.
const SalesmanBrandReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsSalesmanBrandReport" */ '../containers/reports/SalesmanBrandReport'),
  loading: Loading,
});

// We use loadable for credit note report.
const CreditNoteReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsCreditNoteReport" */ '../containers/reports/CreditNoteReport'),
  loading: Loading,
});

// We use loadable for account report.
const AccountReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsAccountReport" */ '../containers/reports/AccountReport'),
  loading: Loading,
});

// We use loadable for aging report.
const AgingReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsAgingReport" */ '../containers/reports/AgingReport'),
  loading: Loading,
});

// We use loadable for customer report.
const CustomerReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsCustomerReport" */ '../containers/reports/CustomerReport'),
  loading: Loading,
});

// We use loadable for customer report.
const ReturnReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsReturnReport" */ '../containers/reports/ReturnReport'),
  loading: Loading,
});

// We use loadable for customer report.
const CreditLeewayReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsCreditLeewayReport" */ '../containers/reports/CreditLeewayReport'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsIndex" */ '../components/reports/index'),
  loading: Loading,
});

const ReportsNew = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReports" */ '../containers/reports'),
  loading: Loading,
});

// We use loadable for Balance Sheet report page.
const BalanceSheetReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsBalanceSheet" */ '../containers/reports/BalanceSheet'),
  loading: Loading,
});

// We use loadable for Income Statement report page.
const IncomeStatementReport = Loadable({
  loader: () => import(/* webpackChunkName : "ContainersReportsIncomeStatement" */ '../containers/reports/IncomeStatement'),
  loading: Loading,
});

const GeneralReport = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsCommonReportsReportBaseScreen" */ '../components/common/reports/ReportBaseScreen'),
  loading: Loading,
});

const ReportRoutes = [
  {
    path: '/reports_old', name: 'Reports', component: Reports,
  },
  {
    path: '/reports', name: 'Reports', component: ReportsNew,
  },
  {
    path: '/report/order', name: 'Orders Report', component: OrdersReport,
  },
  {
    path: '/report/outstanding_balance', name: 'Outstanding Balance Report', component: OutstandingBalanceReport,
  },
  {
    path: '/report/invoice', name: 'Invoice Report', component: InvoiceReport,
  },
  {
    path: '/report/purchase_invoice', name: 'Purchase Invoice Report', component: PurchaseInvoiceReport,
  },
  {
    path: '/report/payment', name: 'Payment Report', component: PaymentReport,
  },
  {
    path: '/report/gst', name: 'GSTR1 Report', component: GstReport,
  },
  {
    path: '/report/gstr3b', name: 'GSTR3B Report', component: GstReport,
  },
  {
    path: '/report/salesman_performance', name: 'Salesman Performance Report', component: SalesmanPerformanceReport,
  },
  {
    path: '/report/salesman_brand', name: 'Salesman Brand Report', component: SalesmanBrandReport,
  },
  {
    path: '/report/credit_note', name: 'Credit Note Report', component: CreditNoteReport,
  },
  {
    path: '/report/account', name: 'Account Report', component: AccountReport,
  },
  {
    path: '/report/aging', name: 'Ageing Report', component: AgingReport,
  },
  {
    path: '/report/customer', name: 'Customer Reports', component: CustomerReport,
  },
  {
    path: '/report/return', name: 'Return Reports', component: ReturnReport,
  },
  {
    path: '/report/credit_leeway', name: 'Credit Leeway Reports', component: CreditLeewayReport,
  },
  {
    path: '/report/balance_sheet', name: 'Balance Sheet', component: BalanceSheetReport,
  },
  {
    path: '/report/income_statement', name: 'Income Statement', component: IncomeStatementReport,
  },
  {
    path: '/reports/:report_type', name: 'Report', component: GeneralReport,
  },
];

export default ReportRoutes;
