export const TAX_RATE = 'TAX_RATE';
export const SET_TAX_RATES = 'SET_TAX_RATES';
export const ADD_TAX_RATE = 'ADD_TAX_RATE';
export const TAX_RATE_FETCHED = 'TAX_RATE_FETCHED';
export const TAX_RATE_UPDATED = 'TAX_RATE_UPDATED';
export const TAX_RATE_DELETED = 'TAX_RATE_DELETED';
export const AUTOCOMPLETE_TAX_RATES = 'AUTOCOMPLETE_TAX_RATES';
export const ADD_NEW_TAX_RATES = 'ADD_NEW_TAX_RATES';

export const title = 'Tax Rates';
export const singularTitle = 'Tax Rate';
export const moduleName = 'tax_rates';
export const baseUrl = '/tax_rates';
export const redirectUrl = '/tax_rates';
export const listingDataKey = 'tax_rates';
export const itemDataKey = 'tax_rate';

export const taxRateConstants = {
  item: TAX_RATE,
  setItems: SET_TAX_RATES,
  addItem: ADD_TAX_RATE,
  setItem: TAX_RATE_FETCHED,
  updateItem: TAX_RATE_UPDATED,
  deleteItem: TAX_RATE_DELETED,
  autocompleteItems: AUTOCOMPLETE_TAX_RATES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};
