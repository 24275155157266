import {
  FETCHING_FEATURES, FETCHING_PLANS, SET_ACTIVE_PLAN, SET_FEATURES, SET_PLANS,
  YEARLY, FETCHING_SUBSCRIPTION_ORDERS_HISTORY, SET_SUBSCRIPTION_ORDERS_HISTORY,
  HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY, HANDLE_FAILURE_FEATURES,
  HANDLE_FAILURE_PLANS,
} from './constants';

export const initialState = {
  activeSubscriptionPlan: {
    id: '',
    service_name: '',
    label: '',
    start_date_formatted: '',
    end_date_formatted: '',
    information: '',
    validity: YEARLY,
    enforcement: false,
  },
  features: {
    isFetching: false,
    items: [],
  },
  plans: {
    isFetching: false,
    items: [],
  },
  orders_history: {
    isFetching: false,
    items: [],
  },
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PLAN: {
      return {
        ...state,
        activeSubscriptionPlan: {
          ...state?.activeSubscriptionPlan,
          ...action.activeSubscriptionPlan,
        },
      };
    }
    case FETCHING_FEATURES: {
      return {
        ...state,
        features: {
          ...state?.features,
          isFetching: true,
        },
      };
    }
    case SET_FEATURES: {
      return {
        ...state,
        features: {
          isFetching: false,
          items: [
            ...action.features,
          ],
        },
      };
    }
    case HANDLE_FAILURE_FEATURES: {
      return {
        ...state,
        features: {
          ...state?.features,
          isFetching: false,
        },
      };
    }
    case FETCHING_PLANS: {
      return {
        ...state,
        plans: {
          ...state?.plans,
          isFetching: true,
        },
      };
    }
    case SET_PLANS: {
      return {
        ...state,
        plans: {
          isFetching: false,
          items: [
            ...action.plans,
          ],
        },
      };
    }
    case HANDLE_FAILURE_PLANS: {
      return {
        ...state,
        plans: {
          ...state?.plans,
          isFetching: false,
        },
      };
    }
    case FETCHING_SUBSCRIPTION_ORDERS_HISTORY: {
      return {
        ...state,
        orders_history: {
          ...state?.orders_history,
          isFetching: true,
        },
      };
    }
    case SET_SUBSCRIPTION_ORDERS_HISTORY: {
      return {
        ...state,
        orders_history: {
          isFetching: false,
          items: [
            ...action.orders_history,
          ],
        },
      };
    }
    case HANADLE_FAILURE_SUBSCRIPTION_ORDERS_HISTORY: {
      return {
        ...state,
        orders_history: {
          ...state?.orders_history,
          isFetching: false,
        },
      };
    }
    default:
      return state;
  }
}
