import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_PERIOD_LOCKS, ADD_PERIOD_LOCK, PERIOD_LOCK_FETCHED, PERIOD_LOCK_UPDATED, PERIOD_LOCK_DELETED,
  defaultSearchParams,
} from '../constants/period_locks';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...defaultSearchParams },
};

export default function periodLocks(state = initialState, action) {
  switch (action.type) {
    case SET_PERIOD_LOCKS: return setItems(state, action);
    case ADD_PERIOD_LOCK: return addItem(state, action);
    case PERIOD_LOCK_FETCHED: return fetchItem(state, action);
    case PERIOD_LOCK_UPDATED: return updateItem(state, action);
    case PERIOD_LOCK_DELETED: return deleteItem(state, action);
    default:
      return state;
  }
}
