import axios from '../utils/axios';
import { handleErrors } from '../utils/handleErrors';

export function fetchFormatPreview(data) {
  return () => axios({
    url: '/number_formats/preview',
    method: 'post',
    data: {
      number_format: data,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '', '');
      throw error.response;
    });
}
