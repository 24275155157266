import { PER_PAGE, DEFAULT_PAGE_NUMBER } from '../constants/common';

export default function setIndexActionParams(args) {
  const search_params = args.search_params || {};
  const per_page = args.per_page || PER_PAGE;
  const page = args.page || DEFAULT_PAGE_NUMBER;
  const sort_by = args.sort_by || '';
  const sort_order = args.sort_order || '';

  return {
    search_params, per_page, page, sort_by, sort_order,
  };
}
