import omit from 'lodash/omit';
import axios from '../utils/axios';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { updateAndCloseQuickAddModule } from '../components/common/quick_add_module/helper';
import { ADD_BEAT_ROUTE } from '../constants/beat_routes';

const moduleName = 'beat_routes';

// Get BeatRoutes List (dispatch, getState)
export function fetchBeatRoutes(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { beat_routes, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: beat_routes,
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save BeatRoute.
export function saveBeatRoute(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  let beat_route = data;
  beat_route = omit(data, 'customers');
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      beat_route,
    },
  })
    .then((response) => {
      const { beat_route } = response.data;
      const params = { beat_route_id: beat_route.id, beat_route_name: beat_route.name };
      updateAndCloseQuickAddModule({ identifier: ADD_BEAT_ROUTE, params });
      dispatch(addItem({ moduleName, item: beat_route }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get BeatRoute.
export function fetchBeatRoute(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { beat_route } = response.data;
      dispatch(setItem({ moduleName, item: beat_route }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update BeatRoute.
export function updateBeatRoute(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  let beat_route = data;
  beat_route = omit(data, 'customers');
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      beat_route,
    },
  })
    .then((response) => {
      const { beat_route } = response.data;
      dispatch(updateItem(({ moduleName, item: beat_route })));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete BeatRoute.
export function deleteBeatRoute(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteBeatRoutesName(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete_beat_routes_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function fetchBeatRoutesBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_beat_routes_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { beat_routes } = response.data;
      dispatch(autocompleteItems({ moduleName, items: beat_routes }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
