import { SET_E_INVOICE_CREDENTIAL } from '@constants/credentials/e_invoice';
import { SET_E_WAY_BILL_CREDENTIAL } from '@constants/credentials/e_way_bill';

export function setEInvoiceCredentialAction(payload) {
  return {
    type: SET_E_INVOICE_CREDENTIAL,
    payload,
  };
}

export function setEWayBillCredentialAction(payload) {
  return {
    type: SET_E_WAY_BILL_CREDENTIAL,
    payload,
  };
}
