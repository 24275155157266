// import { Settings } from '@type/settings_types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUniqCountryDialCodes } from '@api/settings';
import * as types from '@constants/settings';
import store from '@store/store';
import { handleErrors } from '@utils/handleErrors';
import axios, { fetchingRequest } from '@utils/axios';

// Create SET_PAYMENT_MODES action and dispatch to update settings reducer.
export function setPaymentModes(payment_modes) {
  return {
    type: types.SET_PAYMENT_MODES,
    payment_modes,
  };
}

// Call to /payment_modes and get all settings.
export function getPaymentModes() {
  fetchingRequest(types.SETTING);
  return (dispatch) => axios({
    url: '/payment_modes',
    method: 'get',
  })
    .then((response) => {
      const { payment_modes } = response.data;
      dispatch(setPaymentModes(payment_modes));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

export function setTaxIdTypes(taxTypes) {
  return {
    type: types.SET_TAX_ID_TYPES,
    taxTypes,
  };
}

// Call to /payment_modes and get all settings.
export function fetchTaxIdTypes() {
  fetchingRequest(types.SETTING);
  return (dispatch) => axios({
    url: '/tax_types',
    method: 'get',
  })
    .then((response) => {
      const taxIdTypes = response?.data?.tax_types || [];
      dispatch(setTaxIdTypes(taxIdTypes));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

// Create SET_LETTER_OF_CREDIT_TYPES action and dispatch to update settings reducer.
export function setLetterOfCreditTypes(letter_of_credit_types) {
  return {
    type: types.SET_LETTER_OF_CREDIT_TYPES,
    letter_of_credit_types,
  };
}

// Call to /payment_modes/letter_of_credit_types and get all settings.
export function getLetterOfCreditTypes() {
  fetchingRequest(types.SETTING);
  return (dispatch) => axios({
    url: '/payment_modes/letter_of_credit_types',
    method: 'get',
  })
    .then((response) => {
      const letter_of_credit_types = response?.data || [];
      dispatch(setLetterOfCreditTypes(letter_of_credit_types));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

// Create SET_ALL_ROLES action and dispatch to update settings reducer.
export function setAllRoles(roles) {
  return {
    type: types.SET_ALL_ROLES,
    roles,
  };
}

// Call to /permission_settings  and get permission_models and permission_actions.
export function getAllRoles() {
  fetchingRequest(types.SETTING);
  axios({
    url: '/authorisation/roles/all',
    method: 'get',
  })
    .then((response) => {
      const { roles } = response?.data || {};
      store.dispatch(setAllRoles(roles));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

export function getCountryCodes() {
  return () => axios({
    url: '/country_codes',
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

export function getStateCodes(country_code) {
  return () => axios({
    url: '/state_codes',
    method: 'get',
    params: {
      country_code,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

export function setAllStateCodes(state_codes) {
  return {
    type: types.SET_ALL_STATES,
    state_codes,
  };
}

export function fetchAllStates(country_code) {
  fetchingRequest(types.SETTING);
  if (!country_code) {
    return () => new Promise((res) => {
      res({ data: [] });
    }).then(() => {
      store.dispatch(setAllStateCodes([]));
    });
  }

  return (dispatch) => axios({
    url: '/state_codes',
    method: 'get',
    params: {
      country_code,
    },
  })
    .then((response) => {
      const stateCodes = response?.data?.state_codes || [];
      dispatch(setAllStateCodes(stateCodes));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

export function setCountryCodes(country_codes) {
  return {
    type: types.SET_COUNTRY_CODES,
    country_codes,
  };
}

export function fetchCountryCodes() {
  fetchingRequest(types.SETTING);
  return (dispatch) => axios({
    url: '/country_codes',
    method: 'get',
  })
    .then((response) => {
      const countryCodes = response?.data?.country_codes || [];
      dispatch(setCountryCodes(countryCodes));
      return response;
    })
    .catch((error) => {
      handleErrors(error, '/', types.SETTING);
      // throw error.response;
    });
}

function* fetchUniqCountryDialCodesFunc(action: any) {
  fetchingRequest(types.SETTING);
  try {
    const response = yield call(fetchUniqCountryDialCodes());
    const uniqCountryDialCodes = response?.data?.uniq_country_dial_codes || [];
    yield put({
      type: types.SET_COUNTRY_DIAL_CODES,
      uniqCountryDialCodes,
    });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* settingsWatcher() {
  yield takeLatest(types.FETCH_COUNTRY_DIAL_CODES, fetchUniqCountryDialCodesFunc);
}
