import axios from '../utils/axios';
import { moduleName, ADD_CREDIT_REASON } from '../constants/credit_reasons';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { handleErrors } from '../utils/handleErrors';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { updateAndCloseQuickAddModule } from '../components/common/quick_add_module/helper';

// Get CreditReasons List (dispatch, getState)
// args ex. { search_params, per_page, page, sort_by, sort_order }
export function fetchCreditReasons(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { credit_reasons, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData:
        {
          items: credit_reasons, current_page: page, total_count, sort_by, sort_order, search_params, per_page, selected_variant, dont_merge_search_params, timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save CreditReason.
export function saveCreditReason(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'POST',
    data: {
      credit_reason: data,
    },
  })
    .then((response) => {
      const { credit_reason } = response.data;
      dispatch(addItem({ moduleName, item: credit_reason }));
      const params = { credit_reason_id: credit_reason.id, credit_reason_name: credit_reason.name };
      updateAndCloseQuickAddModule({ identifier: ADD_CREDIT_REASON, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get CreditReason.
export function fetchCreditReason(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'GET',
  })
    .then((response) => {
      const { credit_reason } = response.data;
      dispatch(setItem({ moduleName, item: credit_reason }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update CreditReason.
export function updateCreditReason(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'PUT',
    data: {
      credit_reason: data,
    },
  })
    .then((response) => {
      const { credit_reason } = response.data;
      dispatch(updateItem({ moduleName, item: credit_reason }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete CreditReason.
export function deleteCreditReason(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'DELETE',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchCreditReasonsBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_credit_reason_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { credit_reasons } = response.data;
      dispatch(autocompleteItems({ moduleName, items: credit_reasons }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
