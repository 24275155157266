// import { last } from 'lodash';
import store from '@store/store';
import { OPEN_QUICK_LISTING_MODAL, CLOSE_QUICK_LISTING_MODAL } from '@constants/quick_listing_module';

// export const retrieveQuickListingModuleData = () => {
//   const state = store.getState();
//   const quickListingModule = (state && state.quick_add_module) || {};
//   return quickListingModule;
// };

// export const getLastOpenedQuickListingModalIdentifier = () => {
//   const quickListingModule = retrieveQuickListingModuleData();
//   const identifiers = (quickListingModule && quickListingModule.identifiers) || [];
//   const identifier = last(identifiers);
//   return identifier;
// };

export const closeQuickListingModal = () => {
  store.dispatch({ type: CLOSE_QUICK_LISTING_MODAL });
};

export const openQuickListingModal = ({ identifier }) => {
  store.dispatch({
    type: OPEN_QUICK_LISTING_MODAL,
    identifier,
  });
};
