import { HOME_BREADCRUMB_LISTING } from './common';

export const BRAND = 'BRAND';
export const SET_BRANDS = 'SET_BRANDS';
export const ADD_BRAND = 'ADD_BRAND';
export const BRAND_FETCHED = 'BRAND_FETCHED';
export const BRAND_UPDATED = 'BRAND_UPDATED';
export const BRAND_DELETED = 'BRAND_DELETED';
export const AUTOCOMPLETE_BRANDS = 'AUTOCOMPLETE_BRANDS';

export const OPEN_USER_ROLE_FORM_MODAL = 'OPEN_USER_ROLE_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';
export const RESET_BRAND_ERRORS = 'RESET_BRAND_ERRORS';
export const FORM_INFORMATION_TITLE = 'BRAND INFORMATION';
export const ADD_NEW_BRAND = 'Add New Brand';

export const title = 'Brands';
export const singularTitle = 'Brand';
export const moduleName = 'brands';
export const baseUrl = '/brands';
export const redirectUrl = '/brands';
export const listingDataKey = 'brands';
export const itemDataKey = 'brand';

export const brandConstants = {
  item: BRAND,
  setItems: SET_BRANDS,
  addItem: ADD_BRAND,
  setItem: BRAND_FETCHED,
  updateItem: BRAND_UPDATED,
  deleteItem: BRAND_DELETED,
  autocompleteItems: AUTOCOMPLETE_BRANDS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Brands';
export const TargetBaseUrl = 'brands';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{
  title: 'New', target: '', active: true,
}]);

export const handledErrorKeys = {
  master: ['position', 'code', 'user_ids', 'branch_ids'],
};
