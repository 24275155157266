import { getTenantLocalDateTime } from '@src/helpers/common_helper';
import { draft, approved } from '../../../../constants/purchase_invoices';
import { TILL_DATE, THIS_MONTH } from '../../../../constants/common';

export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'this_month_pur_inv', label: 'This month\'s Purchase Invoices', applyFilters: true, filterSelection: { date_gteq: getTenantLocalDateTime('', false).startOf('month'), date_lteq: getTenantLocalDateTime('', false).endOf('month') }, timePeriod: THIS_MONTH,
    },
    {
      key: 'draft_pur_inv', label: 'Draft Purchase Invoices', applyFilters: true, filterSelection: { state_in: [draft] }, timePeriod: TILL_DATE,
    },
    {
      key: 'approved_pur_inv', label: 'Approved Purchase Invoices', applyFilters: true, filterSelection: { state_in: [approved] }, timePeriod: TILL_DATE,
    },
  ],
};
