const getPageBaseUrl = () => {
  const baseUrlArray = window?.location?.href?.split('#/')?.[1]?.split('/');
  let baseUrl = (baseUrlArray?.[0] === 'accounting') ? `${baseUrlArray?.[0]}/${baseUrlArray?.[1]}` : baseUrlArray?.[0];
  if (['report', 'reports'].includes(baseUrlArray?.[0])) {
    baseUrl = 'reports';
  }
  return baseUrl;
};

export const moduleNameFromBaseUrl = () => {
  const baseUrl = getPageBaseUrl();
  switch (baseUrl) {
    case 'accounting/accounts':
      return 'chart_of_accounts';
    case 'accounting/entries':
      return 'journal_entries';
    case 'accounting/reconciliations':
      return 'reconciliations';
    case '':
      return 'dashboard';
    default:
      return baseUrl;
  }
};
