import {
  SET_RECENT_INVOICES,
  INVOICES,
  UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DELIVERED,
  UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DISPATCHED,
} from '../constants/recents';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { setRecents, updateStatus } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  search_params: {
    module_name: INVOICES,
  },
};

export default function recentInvoicesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECENT_INVOICES: return setRecents(state, action);
    case UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DELIVERED:
    case UPDATE_SELECTED_BULK_RECENT_INVOICES_STATUS_TO_DISPATCHED:
      return updateStatus(state, action, action.status);
    default:
      return state;
  }
}
