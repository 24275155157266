import axios from '@utils/axios';
import { moduleName } from '@constants/purchase_order_terms';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { handleErrors } from '@utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';
import { autocompleteItems } from './common';

export function fetchPurchaseOrderTerms(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save purchase_order_term.
export function savePurchaseOrderTerm(purchaseOrderTermParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({
    url: baseUrl,
    method: 'POST',
    data: {
      purchase_order_term: purchaseOrderTermParams,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get purchase_order_term
export function fetchPurchaseOrderTerm(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update PurchaseOrderTerm.
export function updatePurchaseOrderTerm(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'PATCH',
    data: {
      purchase_order_term: data,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete PurchaseOrderTerm.
export function deletePurchaseOrderTerm(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return () => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompletePurchaseOrderTermsName(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete_purchase_order_terms_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchPurchaseOrderTermsBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_purchase_order_terms_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const purchaseOrderTerms = response?.data?.purchase_order_terms || {};
      dispatch(autocompleteItems({ moduleName, items: purchaseOrderTerms }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
