import { call, takeLatest } from 'redux-saga/effects';
import { fetchApiToValidateMobileNUmber, submitOtpNumber, updatePassword } from './actions';
import { SUMBIT_OTP_NUMBER, UPDATE_PASSWORD, VALIDATE_MOBILE_NUMBER } from './constants';

function* validateMobileNumberFunc(action) {
  const { mobile } = action || {};
  try {
    const response = yield call(fetchApiToValidateMobileNUmber({ mobile }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* submitOtpNumberFunc(action) {
  const { mobile, reset_password_otp } = action || {};
  try {
    const response = yield call(submitOtpNumber({ mobile, reset_password_otp }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function* updatePasswordFunc(action) {
  const { reset_password_token, password, password_confirmation } = action || {};
  try {
    const response = yield call(updatePassword({
      reset_password_token, password, password_confirmation,
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* resetAccountPassword() {
  yield takeLatest(VALIDATE_MOBILE_NUMBER, validateMobileNumberFunc);
  yield takeLatest(SUMBIT_OTP_NUMBER, submitOtpNumberFunc);
  yield takeLatest(UPDATE_PASSWORD, updatePasswordFunc);
}
