import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { gray_lighter as grayLighter } from '@assets/css/_variables.module.scss';
import OverlayTriggerTooltip from '../OverlayTriggerTooltip';

const sNoDisplayTitle = 'S.No';
const actionDisplayTitle = 'Action';

interface NestedFormLabelProps {
  displayTitle: string;
  descriptionEl?(): ReactNode;
  isRequired?: boolean;
  view: string;
  unhandledErrors?: any;
  isNestedFormFlashMessage?: boolean;
  style?: CSSProperties;
  className?: string;
  isInformationTitle?: boolean;
  informationEl?(): ReactNode;
  id?: string;
  sNoLabel?: boolean;
  actionLabel?: boolean;
  dataCy?: string;
  description?: string;
  descriptionPlacement?: string;
}

class NestedFormLabel extends PureComponent<NestedFormLabelProps> {
  static defaultProps: {
    description: '',
    descriptionPlacement: 'right';
  };

  render() {
    const {
      displayTitle, isRequired, actionLabel, sNoLabel, dataCy,
      style, className, description = '', descriptionPlacement,
    } = this.props;

    const labelTitleDefaultStyle: CSSProperties = {
      display: 'inline-block',
    };

    let title = displayTitle;
    if (sNoLabel) title = displayTitle || sNoDisplayTitle;
    if (actionLabel) title = actionDisplayTitle;

    return (
      <>
        <label className={classnames('control-label text-uppercase pt-0', className)} style={style}>

          <span
            role="presentation"
            style={labelTitleDefaultStyle}
          >
            {title}
            {isRequired && (
              <sup data-cy={dataCy} className="text-danger nested-form-header-label">*</sup>
            )}
          </span>

          {description && (
            <OverlayTriggerTooltip placement={descriptionPlacement} description={description}>
              <i className="fa fa-info-circle" style={{ color: grayLighter }} aria-hidden="true" />
            </OverlayTriggerTooltip>
          )}

        </label>
      </>
    );
  }
}

NestedFormLabel.defaultProps = {
  description: '',
  descriptionPlacement: 'right',
};

export default NestedFormLabel;
