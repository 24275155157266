export const COMMENT = 'COMMENT';
export const CHECK_IF_COMMENTABLE_IS_ALLOWED = 'CHECK_IF_COMMENTABLE_IS_ALLOWED';
export const SET_COMMENTS = 'SET_COMMENTS';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const SET_COMMENTABLE = 'SET_COMMENTABLE';
export const SET_COMMENTS_MODULE_NAME = 'SET_COMMENTS_MODULE_NAME';
export const ADD_COMMENT = 'ADD_COMMENT';
export const TOGGLE_POST_COMMENT_STATE = 'TOGGLE_POST_COMMENT_STATE';
export const TOGGLE_FETCH_COMMENTS_STATE = 'TOGGLE_FETCH_COMMENTS_STATE';
export const DELETE_COMMENT_DOCUMENT = 'DELETE_COMMENT_DOCUMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const LOAD_MORE_COMMENTS = 'LOAD_MORE_COMMENTS';
export const COMMENT_FETCHED = 'COMMENT_FETCHED';
export const COMMENT_UPDATED = 'COMMENT_UPDATED';
export const COMMENT_DELETED = 'COMMENT_DELETED';

export const title = 'Comments';
export const singularTitle = 'Comment';
export const moduleName = 'comments';
export const listingDataKey = 'comments';
export const itemDataKey = 'comment';
export const permissionKey = moduleName;

export const commentConstants = {
  item: COMMENT,
  setItems: SET_COMMENTS,
  addItem: ADD_COMMENT,
  setItem: COMMENT_FETCHED,
  updateItem: COMMENT_UPDATED,
  deleteItem: COMMENT_DELETED,
  title,
  singularTitle,
  moduleName,
  listingDataKey,
  itemDataKey,
  permissionKey,
};
