import React, { Component } from 'react';
import RouteLeavingGuard from '../RouteLeavingGuard';
import history from '../../history';

interface FormProps {
  id?: string;
  noValidate?: boolean;
  autoComplete?: string;
  className?: string;
  handleSubmit: Function;
  children: any;
  style?: object;
  isFormChanged?: boolean;
}

class Form extends Component<FormProps, null> {
  public static defaultProps: Partial<FormProps> = {
    id: '',
    noValidate: true,
    autoComplete: 'off',
    className: '',
    style: {},
    isFormChanged: false,
  }

  render() {
    const {
      id, noValidate, autoComplete, className, handleSubmit, children,
      style, isFormChanged,
    } = this.props;
    return (
      <>
        <form
          id={id}
          autoComplete={autoComplete}
          noValidate={noValidate}
          className={className}
          onSubmit={(args) => handleSubmit(args)}
          style={style}
        >
          {children}
        </form>
        {isFormChanged && (
          <RouteLeavingGuard
            // Use this link for more details.
            // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
            // (same as "when" prop of Prompt of React-Router)
            when={isFormChanged}
            // Navigate function
            navigate={(path) => history.push(path)}
            // Use as "message" prop of Prompt of React-Router
            shouldBlockNavigation={
              (location) => true
              // This case it blocks the navigation when:
              // 1. the login form is dirty, and
              // 2. the user is going to 'sign-up' scene.
              //    (Just an example, in real case you might
              //     need to block all location regarding this case)
              // if (isFormChanged) {
              //   if (location.pathname === '/brands') {
              //     return true;
              //   }
              // }
              // return false;
            }
          />
        )}
      </>
    );
  }
}

export default Form;
