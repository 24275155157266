import { map, filter } from 'lodash';
import { REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_SETTABLE_CATEGORY, SET_PRINT_FORMATS, PRINT_FORMAT_FETCHED, ADD_PRINT_FORMAT,
  PRINT_FORMAT_UPDATED, PRINT_FORMAT_DELETED, PRINT_FORMAT_CREATED, DUPLICATE_PRINT_FORMAT,
  PRINT_FORMAT_PREVIEW, RESET_PRINT_FORMATS,
} from '../constants/print_formats';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  settableCategories: {},
  printFormats: {},
};

const resetState = {
  isFetching: false,
  isError: false,
  errors: {},
  hasWarnings: false,
  warnings: {},
};

export default function printFormats(state = initialState, action) {
  switch (action.type) {
    case SET_SETTABLE_CATEGORY: {
      return {
        ...state,
        settableCategories: {
          ...state.settableCategories, [action.settableCategoryName]: action.payload,
        },
        ...resetState,
      };
    }
    case SET_PRINT_FORMATS: {
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: action.payload,
        },
        ...resetState,
      };
    }
    case RESET_PRINT_FORMATS: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      const updatedPrintFormats = map(allSettablePrintFormats, (pf) => ({ ...pf, settings: [] }));
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    case PRINT_FORMAT_FETCHED: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      const updatedPrintFormats = map(allSettablePrintFormats, (pf) => {
        if (pf.id !== action.payload.id) return pf;
        return action.payload;
      });

      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    case ADD_PRINT_FORMAT: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      allSettablePrintFormats.push(action.payload);
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: allSettablePrintFormats,
        },
        ...resetState,
      };
    }
    case DUPLICATE_PRINT_FORMAT: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      allSettablePrintFormats.push(action.payload);
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: allSettablePrintFormats,
        },
        ...resetState,
      };
    }
    case PRINT_FORMAT_CREATED: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      const updatedPrintFormats = map(allSettablePrintFormats, (pf, idx) => {
        if (idx !== action.idx) return pf;
        return action.payload;
      });
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    case PRINT_FORMAT_UPDATED: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      const updatedPrintFormats = map(allSettablePrintFormats, (pf) => {
        if (pf.id !== action.payload.id) return pf;
        return action.payload;
      });

      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    case PRINT_FORMAT_DELETED: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      let updatedPrintFormats = allSettablePrintFormats;
      updatedPrintFormats = filter(allSettablePrintFormats, (pf, index) => (index !== action.idx));
      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    case PRINT_FORMAT_PREVIEW: {
      const allSettablePrintFormats = (state.printFormats && state.printFormats[action.settableCategoryName]) || [];
      const updatedPrintFormats = map(allSettablePrintFormats, (pf) => {
        if (pf.id !== action.id) return pf;
        return { ...pf, fileData: action.payload };
      });

      return {
        ...state,
        printFormats: {
          ...state.printFormats,
          [action.settableCategoryName]: updatedPrintFormats,
        },
        ...resetState,
      };
    }
    default:
      return { ...state };
  }
}
