import { SET_REPORTS, REPORT_DELETED } from '../../constants/reports';

export const AVAILABLE_REPORTS_REQUEST = '@actions/reports/AVAILABLE_REPORTS_REQUEST';
export const AVAILABLE_REPORTS_RESPONSE = '@actions/reports/AVAILABLE_REPORTS_RESPONSE';
export const CUSTOM_REPORTS_REQUEST = '@actions/reports/CUSTOM_REPORTS_REQUEST';
export const CUSTOM_REPORTS_RESPONSE = '@actions/reports/CUSTOM_REPORTS_RESPONSE';
export const REPORT_REQUEST = '@actions/reports/REPORT_REQUEST';
export const REPORT_ERROR_RESPONSE = '@actions/reports/REPORT_ERROR_RESPONSE';
export const REPORT_RESPONSE = '@actions/reports/REPORT_RESPONSE';
export const REPORT_RESPONSE_SUCCESS = '@actions/reports/REPORT_RESPONSE_SUCCESS';
export const MAP_REPORT_KEY = '@actions/reports/MAP_REPORT_KEY';
export const RESET_REPORT_DATA = '@actions/reports/RESET_REPORT_DATA';

export function setReports(reports) {
  return {
    type: SET_REPORTS,
    ...reports,
  };
}

export function reportDeleted(id, parent_id) {
  return {
    type: REPORT_DELETED,
    id,
    parent_id,
  };
}

export function setAvailableReports(available_reports) {
  return {
    type: AVAILABLE_REPORTS_RESPONSE,
    ...available_reports.data,
  };
}

export function setCustomReports(custom_reports) {
  return {
    type: CUSTOM_REPORTS_RESPONSE,
    ...custom_reports.data,
  };
}

export function setReportData(args) {
  return {
    type: REPORT_RESPONSE,
    data: args[args.report_name],
    report_name: args.report_name,
  };
}

export function mapReportDataToKey(current_key, new_key) {
  return {
    type: MAP_REPORT_KEY,
    current_key,
    new_key,
  };
}

export const resetReportData = () => ({ type: RESET_REPORT_DATA });
