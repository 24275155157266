import { SET_SALES_PERFORMANCE, INITIAL_STATE } from '../constants/performances';

export const initialState = INITIAL_STATE;

export default function salesPerformance(state = initialState, action) {
  switch (action.type) {
    case SET_SALES_PERFORMANCE: {
      return {
        ftd: { ...state.ftd, ...action.ftd },
        mtd: { ...state.mtd, ...action.mtd },
        ytd: { ...state.ytd, ...action.ytd },
        description: action.description,
      };
    }
    default:
      return state;
  }
}
