export const USER_ROLE = 'USER_ROLE';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const USER_ROLE_FETCHED = 'USER_ROLE_FETCHED';
export const USER_ROLE_UPDATED = 'USER_ROLE_UPDATED';
export const USER_ROLE_DELETED = 'USER_ROLE_DELETED';
export const AUTOCOMPLETE_USER_ROLES = 'AUTOCOMPLETE_USER_ROLES';

export const title = 'User Roles';
export const singularTitle = 'User Role';
export const moduleName = 'user_roles';
export const baseUrl = '/user_roles';
export const redirectUrl = '/user_roles';
export const listingDataKey = 'user_roles';
export const itemDataKey = 'user_roles';

export const userRoleConstants = {
  item: USER_ROLE,
  setItems: SET_USER_ROLES,
  addItem: ADD_USER_ROLE,
  setItem: USER_ROLE_FETCHED,
  updateItem: USER_ROLE_UPDATED,
  deleteItem: USER_ROLE_DELETED,
  autocompleteItems: AUTOCOMPLETE_USER_ROLES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};
