import keys from 'lodash/keys';
import { QUICK_ADD_IS_USED_FOR } from '@component/common/quick_add_module/quick_add_common_helper';
import { fetchReconEntries } from '@actions/accounting/recon_entries';
import { PAYMENT_UPDATED } from '@constants/payments';

// WHAT:  middleware func which checks if journal entry added or payment is updated
// WHY:  this is used because it check if user is in recon_entries and when user saves or updates
//       something the recon_entries have to be refetched
const fetchReconEntriesMiddleware = (store) => (next) => (action) => {
  const { recon_entries } = store.getState();
  const { isActive, account_id } = recon_entries;

  if ((keys(QUICK_ADD_IS_USED_FOR).includes(action?.type)
    || (action.type === PAYMENT_UPDATED)) && isActive) {
    if (isActive && account_id) {
      const {
        search_params, current_page, sort_by, sort_order, per_page,
      } = recon_entries;

      store.dispatch(fetchReconEntries({
        search_params,
        current_page,
        sort_by,
        sort_order,
        per_page,
        account_id,
      }));
    }
  }

  const result = next(action);
  return result;
};

export default fetchReconEntriesMiddleware;
