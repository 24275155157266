import { HOME_BREADCRUMB_LISTING } from './common';

export const USER = 'USER';
export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const USER_FETCHED = 'USER_FETCHED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_DELETED = 'USER_DELETED';
export const AUTOCOMPLETE_USERS = 'AUTOCOMPLETE_USERS';
export const AUTOCOMPLETE_SELECT_MULTI_USERS = 'AUTOCOMPLETE_SELECT_MULTI_USERS';
export const FORM_INFORMATION_TITLE = 'USER INFORMATION';

export const FETCH_USERS = 'FETCH_USERS';
export const SUSPEND_USER = 'SUSPEND_USER';
export const REVOKE_USER_SUSPENSION = 'REVOKE_USER_SUSPENSION';
export const UPDATE_USER_SUSPENDED = 'UPDATE_USER_SUSPENDED';

export const title = 'Users';
export const singularTitle = 'User';
export const moduleName = 'users';
export const baseUrl = '/users';
export const redirectUrl = '/users';
export const listingDataKey = 'users';
export const itemDataKey = 'user';

export const userConstants = {
  item: USER,
  setItems: SET_USERS,
  addItem: ADD_USER,
  setItem: USER_FETCHED,
  updateItem: USER_UPDATED,
  deleteItem: USER_DELETED,
  autocompleteItems: AUTOCOMPLETE_USERS,
  autocompleteUnions: AUTOCOMPLETE_SELECT_MULTI_USERS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_or_mobile_or_email_cont: '',
  active_eq: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Users';
export const TargetBaseUrl = 'users';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['email', 'name', 'mobile', 'active', 'password', 'password_confirmation', 'users_group', 'users_group_id', 'branch_ids'],
};
