import { HOME_BREADCRUMB_LISTING } from './common';

export const PURCHASE_ORDER_TERM = 'PURCHASE_ORDER_TERM';
export const SET_PURCHASE_ORDER_TERMS = 'SET_PURCHASE_ORDER_TERMS';
export const ADD_PURCHASE_ORDER_TERM = 'ADD_PURCHASE_ORDER_TERM';
export const PURCHASE_ORDER_TERM_FETCHED = 'PURCHASE_ORDER_TERM_FETCHED';
export const PURCHASE_ORDER_TERM_UPDATED = 'PURCHASE_ORDER_TERM_UPDATED';
export const PURCHASE_ORDER_TERM_DELETED = 'PURCHASE_ORDER_TERM_DELETED';
export const RESET_PURCHASE_ORDER_TERM_ERRORS = 'RESET_PURCHASE_ORDER_TERM_ERRORS';
export const AUTOCOMPLETE_PURCHASE_ORDER_TERMS = 'AUTOCOMPLETE_PURCHASE_ORDER_TERMS';

export const FETCH_PURCHASE_ORDER_TERMS = 'FETCH_PURCHASE_ORDER_TERMS';
export const FETCH_PURCHASE_ORDER_TERM = 'FETCH_PURCHASE_ORDER_TERM';
export const FETCH_CREATE_PURCHASE_ORDER_TERM = 'FETCH_CREATE_PURCHASE_ORDER_TERM';
export const FETCH_UPDATE_PURCHASE_ORDER_TERM = 'FETCH_UPDATE_PURCHASE_ORDER_TERM';
export const FETCH_DELETE_PURCHASE_ORDER_TERM = 'FETCH_DELETE_PURCHASE_ORDER_TERM';

export const FORM_INFORMATION_TITLE = 'PURCHASE ORDER TERM INFORMATION';
export const ADD_NEW_PURCHASE_ORDER_TERM = 'Add New Purchase Order Term';

export const title = 'Purchase Order Terms';
export const singularTitle = 'Purchase Order Term';
export const moduleName = 'purchase_order_terms';
export const baseUrl = '/purchase_order_terms';
export const redirectUrl = '/purchase_order_terms';
export const listingDataKey = 'purchase_order_terms';
export const itemDataKey = 'purchase_order_term';

export const purchaseOrderTermConstants = {
  item: PURCHASE_ORDER_TERM,
  setItems: SET_PURCHASE_ORDER_TERMS,
  addItem: ADD_PURCHASE_ORDER_TERM,
  setItem: PURCHASE_ORDER_TERM_FETCHED,
  updateItem: PURCHASE_ORDER_TERM_UPDATED,
  deleteItem: PURCHASE_ORDER_TERM_DELETED,
  resetErrors: RESET_PURCHASE_ORDER_TERM_ERRORS,
  autocompleteItems: AUTOCOMPLETE_PURCHASE_ORDER_TERMS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Purchase Order Terms';
export const TargetBaseUrl = 'purchase_order_terms';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title: Title, target: TargetBaseUrl, active: false },
]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title: Title, target: TargetBaseUrl, active: true },
]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([
  { title: 'New', target: '', active: true },
]);

export const handledErrorKeys = {
  master: ['name', 'credit_days', 'interest_rate', 'grace_days', 'terms'],
};
