import * as types from '@constants/auth';

// Create SET_CURRENT_USER action.
export function setCurrentUser(isAuthenticated, account, isError, errors) {
  return {
    type: types.SET_CURRENT_USER,
    isAuthenticated,
    account,
    isError,
    errors,
  };
}

export function resetReducerState() {
  return {
    type: types.RESET_REDUCERS_STATE,
  };
}
