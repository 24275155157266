import { SET_E_INVOICE_CREDENTIAL } from '@constants/credentials/e_invoice';

export const initialState = {
  data: {
    username: '',
    password: '',
  },
  isError: false,
  hasWarnings: false,
  errors: {},
  warnings: {},
  isFetching: false,
};

export default function EInvoice(state = initialState, action) {
  switch (action.type) {
    case SET_E_INVOICE_CREDENTIAL: {
      return {
        ...state,
        data: action?.payload?.in_gsp_credential_e_invoice,
        isError: false,
        hasWarnings: false,
        errors: {},
        warnings: {},
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
