import { isEmpty } from 'lodash';
import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const REPORT_BREADCRUMB = HOME_BREADCRUMB_LISTING.concat([{ title: 'Reports', target: '/reports', active: true }]);

export const MODULE_REPORTS_DATA = {
  orders: { report_category_name: 'Order', title: 'Order', breadcrumb: { title: 'Order', targetUrl: '/orders' } },
  invoices: { report_category_name: 'Invoice', title: 'Invoice', breadcrumb: { title: 'Invoice', targetUrl: '/invoices' } },
  returns: { report_category_name: 'Return', title: 'Return', breadcrumb: { title: 'Return', targetUrl: '/returns' } },
  offers: { report_category_name: 'Sales::Offer', title: 'Offer', breadcrumb: { title: 'Offer', targetUrl: '/offers' } },
  customers: { report_category_name: 'Customer', title: 'Customer', breadcrumb: { title: 'Customer', targetUrl: '/customers' } },
  payments: { report_category_name: 'Payment', title: 'Payment', breadcrumb: { title: 'Payment', targetUrl: '/payments' } },
  debit_notes: { report_category_name: 'DebitNote', title: 'Debit Note', breadcrumb: { title: 'Debit Note', targetUrl: '/debit_notes' } },
  stocks: { report_category_name: 'Stock', title: 'Stock', breadcrumb: { title: 'Stock', targetUrl: '/stocks' } },
  inventory_batches: { report_category_name: 'Inventory::StockEntryBatch', title: 'Stock Entry Batch', breadcrumb: { title: 'Stock Entry Batch', targetUrl: '/inventory_batches' } },
  stock_transfers: { report_category_name: 'StockTransfer', title: 'Stock Transfer', breadcrumb: { title: 'Stock Transfer', targetUrl: '/stock_transfers' } },
  chart_of_accounts: { report_category_name: 'Account', title: 'Account', breadcrumb: { title: 'Account', targetUrl: '/chart_of_accounts' } },
  products: { report_category_name: 'Product', title: 'Product', breadcrumb: { title: 'Product', targetUrl: '/products' } },
  credit_notes: { report_category_name: 'CreditNote', title: 'Credit Note', breadcrumb: { title: 'Credit Note', targetUrl: '/credit_notes' } },
  payment_outs: { report_category_name: 'PaymentOut', title: 'Payment Out', breadcrumb: { title: 'Payment Out', targetUrl: '/payment_outs' } },
  purchase_invoices: { report_category_name: 'PurchaseInvoice', title: 'Purchase Invoice', breadcrumb: { title: 'Purchase Invoice', targetUrl: '/purchase_invoices' } },
};

export const generateBreadcrumb = (moduleName) => {
  const moduleReportsData = MODULE_REPORTS_DATA?.[moduleName] || '';
  if (moduleName && !isEmpty(moduleReportsData?.breadcrumb)) {
    const breadcrumb = HOME_BREADCRUMB_LISTING.concat([{
      title: moduleReportsData?.breadcrumb?.title,
      target: moduleReportsData?.breadcrumb?.targetUrl,
      active: true,
    }]).concat([{ title: 'Reports', target: '/reports', active: true }]);
    return breadcrumb;
  }
  return REPORT_BREADCRUMB;
};
