import { BE_CONTROLLER_VERSION } from '@src/utils/common_constants';

export const BACKEND_DEV_PORT = 3000;
export const FRONTEND_DEV_PORT = 3006;
let URL = '';

// NOTE: For prod or dev urls are same, since we can use proxy in dev env
URL = `${window.location.protocol}//${window.location.host}/${BE_CONTROLLER_VERSION}`;
export enum VIEW_ELEMENT_TYPE {
  Field = 'Field',
  Section = 'Section',
  Page = 'Page',
}

export const BASE_URL = URL;
export const PER_PAGE = 10;
export const REPORT_DEFAULT_PER_PAGE = 100;
export const PAGINATION_MAX_BUTTONS = 3;
export const TOKEN_HEADER = 'X-Auth-Token';
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_SORT_BY = 'id';
export const DEFAULT_SORT_ORDER = 'asc';
export const PER_PAGE_OPTIONS = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
];
export const REPORT_PER_PAGE_OPTIONS = [
  { value: 50 }, { value: 75 }, { value: 100 }, { value: 150 }, { value: 300 },
];

export const ACTIVE_STATUS = [{ name: 'Active', id: 'true' }, { name: 'Inactive', id: 'false' }];

export const HOME_BREADCRUMB_LISTING = [{ title: 'Home', target: '/', active: false }];
export const REPORT_BREADCRUMB_LISTING = [{ title: 'Reports', target: '/reports', active: false }];
export const UNPROCESSABLE_ENTITY_ERROR_NOTIFICATION_MESSAGE = 'Please review the problems below.';
export const INR_CURRENCY_SYMBOL = 'INR'; // Indian National Rupee
export const USD_CURRENCY_SYMBOL = 'USD'; // US Dollar
export const EUR_CURRENCY_SYMBOL = 'EUR'; // Euro
export const CAD_CURRENCY_SYMBOL = 'CAD'; // Canadian Dollar
export const JPY_CURRENCY_SYMBOL = 'JPY'; // Japanese Yen
export const AUD_CURRENCY_SYMBOL = 'AUD'; // Australian Dollar

// export const ERRORS_NOTIFICATION_MESSAGE = {
//   UNPROCESSABLE_ENTITY: 'Please review the problems below.', // could not perform action
//   UNAUTHORIZED: 'Not authorized to do this action',
// };

export const LOADING = 'LOADING';
export const AUTOCOMPLETE_USER = 'autocomplete_user';
export const AUTOCOMPLETE_CUSTOMER = 'autocomplete_customer';
export const AUTOCOMPLETE_SALESMAN = 'autocomplete_salesman';
export const AUTOCOMPLETE_BRAND = 'autocomplete_brand';
export const AUTOCOMPLETE_BEAT_ROUTE = 'autocomplete_beat_route';
export const AUTOCOMPLETE_SUPPLIER = 'autocomplete_supplier';
export const AUTOCOMPLETE_PRODUCT_CATEGORY = 'autocomplete_product_category';
export const AUTOCOMPLETE_CUSTOMER_CATEGORY = 'autocomplete_customer_category';
export const AUTOCOMPLETE_CREDITABLE = 'creditable_listing';
export const AUTOCOMPLETE_DEBITABLE = 'debitable_listing';
export const AUTOCOMPLETE_INVOICE = 'autocomplete_invoice';
export const AUTOCOMPLETE_INVOICE_TERM = 'autocomplete_invoice_term';
export const AUTOCOMPLETE_RETURN = 'autocomplete_return';
export const AUTOCOMPLETE_ZONE = 'autocomplete_zone';
export const AUTOCOMPLETE_PAYMENT = 'autocomplete_payment';
export const AUTOCOMPLETE_ROLE = 'autocomplete_role';
export const AUTOCOMPLETE_PRODUCT = 'AUTOCOMPLETE_PRODUCT';
export const SUBMIT_FORM_LOADER = 'submit_form_loader';
export const AUTOCOMPLETE_CHART_OF_ACCOUNT = 'autocomplete_CHART_OF_ACCOUNT';
export const AUTOCOMPLETE_PARTY = 'autocomplete_party';
export const DOWNLOAD_FILE = 'download_file';
export const FILTER = 'filter';
export const RESET_FILTER = 'reset_filter';
export const LAST_OPENED_REPORT = 'last_opened_report';
export const OPENED_REPORTS = 'opened_report';
export const PARENT = 'parent';
export const VARIANT = 'variant';
export const CUSTOM = 'custom';
export const ADVANCE_SEARCH = 'ADVANCE_SEARCH';

// Pages constants.
export const NEW = 'NEW';
export const EDIT = 'EDIT';
export const SHOW = 'SHOW';
export const INDEX = 'INDEX';
export const TILL_DATE = 'Till Date';
export const TODAY = 'Today';
export const THIS_WEEK = 'This Week';
export const THIS_MONTH = 'This Month';

// Input types
export const DATE = 'date';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const STRING = 'string';
export const ARRAY = 'array';
export const OBJECT = 'object';

// Date formats
export const dateOnlyFormat = 'LL';
export const fallBackTimeFormat = 'h:mm a';
export const fallBackDateFormat = 'MMM dd, yyyy';
export const fallBackDatetimeFormat = `${fallBackDateFormat}, ${fallBackTimeFormat}`;

// Permission keys
export const CAN_ALLOCATE = 'can_allocate';
export const CAN_RECALCULATE = 'can_recalculate';

export const apiDefaultDateFormat = 'MMM D, YYYY';
export const apiDefaultDateTimeFormat = `${apiDefaultDateFormat} ${fallBackTimeFormat}`;

export const LOADER_BTN_NAME = 'Please wait..';
export const LOADER_BTN_SHORT_NAME = 'Wait..';

export const REDUCER_COMMON_INITIAL_STATES = {
  items: [],
  current_page: 1,
  per_page: PER_PAGE,
  total_count: 0,
  isError: false,
  hasWarnings: false,
  errors: {},
  warnings: {},
  isFetching: false,
  timePeriod: TILL_DATE,
};

// GLOBAL_CONSTANT STATUS COLORS
export const indexBgStatusColors = {
  danger: ['failure'],
  success: ['invoiced', 'closed', 'approved', 'accounted', 'done'],
  cancelled: ['cancelled', 'failed'],
  warning: ['processing', 'dispatched', 'received'],
  info: ['confirm', 'ready', 'deposited'],
  white: ['draft', 'delivered', 'open'],
};

// GLOBAL_CONSTANT refresh ui message id
export const refreshUiMessageId = 'refresh_ui_message';
export const INTERNAL_SERVER_ERROR = "Server couldn't fulfil this request. Something seems wrong.";

export const DECIMAL_NUMBER_REGEX = /^(\d+)([.])?(\d*)$/;
export const QUANTITY_VALUE_REGEX = /^(\d+)([-, .])?(\d*)$/;

// Accepts natural numbers (+ ve numbers) starting with 1,2,3,4,5 ..... to infinity.
// Does not accept standalone ZERO/0 as input.
export const NATURAL_NUMBER_REGEX = /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/;

// This constants used in purchase_orders, purchase_invoices, payment_outs form.
export const CUSTOMER = 'Customer';
export const SUPPLIER = 'Supplier';
export const PARTY_TYPES = [
  { label: CUSTOMER, value: CUSTOMER },
  { label: SUPPLIER, value: SUPPLIER },
];
