import { REDUCER_COMMON_INITIAL_STATES } from '../../constants/common';
import {
  SET_ACCOUNTING_SUB_ACCOUNTS, ADD_ACCOUNTING_SUB_ACCOUNT, ACCOUNTING_SUB_ACCOUNT_FETCHED,
  ACCOUNTING_SUB_ACCOUNT_UPDATED, ACCOUNTING_SUB_ACCOUNT_DELETED, PUSH_ACCOUNTING_SUB_ACCOUNT_PATH,
  RESET_ACCOUNTING_SUB_ACCOUNTS, POP_ACCOUNTING_SUB_ACCOUNT_PATH,
} from '../../constants/accounting/sub_accounts';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '../common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: {
    name_cont: '',
  },
  accHistory: [],
  curAcc: {},
  current_scrolling_page: 1,
  cur_id: '',
  account_id: '',
};

const pushAccToHistory = (state, action) => {
  const { curAcc, isInitial } = action;
  const updatedState = { ...state, curAcc };

  let newAccHistory = [];
  if (isInitial) {
    newAccHistory = [curAcc];
  } else {
    newAccHistory = [...updatedState.accHistory];
    newAccHistory.push(curAcc);
  }
  updatedState.accHistory = newAccHistory;

  return updatedState;
};

const jumpToAccInHistory = (state, action) => {
  const { curAcc, accIndex } = action;
  const updatedState = { ...state, curAcc };

  let newAccHistory = [...updatedState.accHistory];
  const spliceUpto = accIndex + 1;
  newAccHistory = newAccHistory.splice(0, spliceUpto);
  updatedState.accHistory = newAccHistory;

  return updatedState;
};

export default function accountingSubAccounts(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNTING_SUB_ACCOUNTS: return setItems(state, action);
    case RESET_ACCOUNTING_SUB_ACCOUNTS: return setItems(initialState, action);
    case ADD_ACCOUNTING_SUB_ACCOUNT: return addItem(state, action);
    case ACCOUNTING_SUB_ACCOUNT_FETCHED: return fetchItem(state, action);
    case ACCOUNTING_SUB_ACCOUNT_UPDATED: return updateItem(state, action);
    case ACCOUNTING_SUB_ACCOUNT_DELETED: return deleteItem(state, action);
    case PUSH_ACCOUNTING_SUB_ACCOUNT_PATH: return pushAccToHistory(state, action);
    case POP_ACCOUNTING_SUB_ACCOUNT_PATH: return jumpToAccInHistory(state, action);
    default:
      return state;
  }
}
