import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_ORDER_TERMS, ADD_ORDER_TERM, ORDER_TERM_FETCHED, ORDER_TERM_UPDATED,
  ORDER_TERM_DELETED, AUTOCOMPLETE_ORDER_TERMS, defaultSearchParams, moduleName,
} from '@constants/order_terms';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocompleteUnions,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function orderTerms(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_TERMS: return setItems(state, action);
    case ADD_ORDER_TERM: return addItem(state, action);
    case ORDER_TERM_FETCHED: return fetchItem(state, action);
    case ORDER_TERM_UPDATED: return updateItem(state, action);
    case ORDER_TERM_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_ORDER_TERMS: return autocompleteUnions(state, action);
    default:
      return state;
  }
}
