import { HOME_BREADCRUMB_LISTING } from './common';

// NOTE: Don't change any constant it affected on application.
// If you want to change/rename any constant then search constant in application and change in other place with updated constant name.
export const CUSTOMER_CATEGORY = 'CUSTOMER_CATEGORY';
export const SET_CUSTOMER_CATEGORIES = 'SET_CUSTOMER_CATEGORIES';
export const ADD_CUSTOMER_CATEGORY = 'ADD_CUSTOMER_CATEGORY';
export const CUSTOMER_CATEGORY_FETCHED = 'CUSTOMER_CATEGORY_FETCHED';
export const CUSTOMER_CATEGORY_UPDATED = 'CUSTOMER_CATEGORY_UPDATED';
export const CUSTOMER_CATEGORY_DELETED = 'CUSTOMER_CATEGORY_DELETED';
export const AUTOCOMPLETE_CUSTOMER_CATEGORIES = 'AUTOCOMPLETE_CUSTOMER_CATEGORIES';
export const RESET_CUSTOMER_CATEGORY_ERRORS = 'RESET_CUSTOMER_CATEGORY_ERRORS';

export const FORM_INFORMATION_TITLE = 'CUSTOMER CATEGORY INFORMATION';
export const ADD_NEW_CUSTOMER_CATEGORY = 'Add New Customer Category';

export const title = 'Customer Categories';
export const singularTitle = 'Customer Category';
export const moduleName = 'customer_categories';
export const baseUrl = '/customer_categories';
export const redirectUrl = '/customer_categories';
export const listingDataKey = 'customer_categories';
export const itemDataKey = 'customer_category';

export const customerCategoryConstants = {
  item: CUSTOMER_CATEGORY,
  setItems: SET_CUSTOMER_CATEGORIES,
  addItem: ADD_CUSTOMER_CATEGORY,
  setItem: CUSTOMER_CATEGORY_FETCHED,
  updateItem: CUSTOMER_CATEGORY_UPDATED,
  deleteItem: CUSTOMER_CATEGORY_DELETED,
  resetErrors: RESET_CUSTOMER_CATEGORY_ERRORS,
  autocompleteItems: AUTOCOMPLETE_CUSTOMER_CATEGORIES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  name_cont: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Customer Categories';
export const TargetBaseUrl = 'customer_categories';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name'],
};
