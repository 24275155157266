/* eslint-disable no-lonely-if */
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  func, object, string, bool,
} from 'prop-types';
import { fromJS } from 'immutable';
import { serialize } from 'object-to-formdata';
import {
  find, map, omit, filter, trim, values, isEqual, take, includes, isEmpty, isNumber,
  isArray, isString, startCase, round, keys, has, unionBy, ceil, compact,
} from 'lodash';
import dayjs from 'dayjs';
import purify from 'dompurify';
import parse from 'html-react-parser';
import { AUTH_TOKEN_HEADER_FOR_BE, CSRF_TOKEN_FOR_BE, USER_MOBILE_HEADER_FOR_BE } from '@utils/common_constants';
import { FETCH_UNITS_UTILITIES } from '@constants/utilities';
import {
  fetchSettings, fetchUiPermissions, fetchNotices, fetchI18nFields,
} from '@saga/root';
import { getAllRoles } from '@saga/settings';
import {
  PER_PAGE, indexBgStatusColors, STRING, BOOLEAN, ARRAY, OBJECT, DATE,
  apiDefaultDateFormat, OPENED_REPORTS, INR_CURRENCY_SYMBOL, USD_CURRENCY_SYMBOL,
  QUANTITY_VALUE_REGEX, apiDefaultDateTimeFormat, fallBackTimeFormat,
} from '@constants/common';
import {
  FETCH_ACTIVE_PLAN, FETCH_FEATURES, FETCH_PLANS,
} from '@component/subscription/constants';
import { ProductUnit } from '../types/product_types';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

// Ex. moduleName is 'orders' or 'invoices' so our key is 'orders_listing_settings' or 'invoices_listing_settings'
export const getLocalStorageKeyForListingColumn = ({ moduleName }) => `${moduleName}_listing_columns`;

// We can pass moduleName or key as a local storage key for storing data against key.
export const setLocalStorage = ({ moduleName = '', key, data }) => {
  let k = '';
  if (moduleName) {
    k = getLocalStorageKeyForListingColumn({ moduleName });
  }
  const newKey = key || k;
  localStorage.setItem(newKey, JSON.stringify(data));
};

// We can pass moduleName or key to get local storage data by using key name.
export const getLocalStorage = ({ moduleName = '', key = '' }) => {
  let k = '';
  if (moduleName) {
    k = getLocalStorageKeyForListingColumn({ moduleName });
  }
  const newKey = key || k;
  const data = localStorage.getItem(newKey);
  try {
    return JSON.parse(data);
  } catch (error) {
    return '';
  }
};

// We can pass moduleName or key to remove local storage data by using key name.
export const removeLocalStorageItem = ({ moduleName = '', key = '' }) => {
  let k = '';
  if (moduleName) {
    k = getLocalStorageKeyForListingColumn({ moduleName });
  }
  const newKey = key || k;
  localStorage.removeItem(newKey);
};

// We clear all local storage data.
export const clearAllLocalStorage = () => {
  localStorage.clear();
};

// This function is helpful to check local store key(ex. payments_listing_settings) columns
// and default columns are equal.
export const isLocalStorageColumnsAndDefaultColumnsEqual = ({
  moduleName, key, defaultColumns,
}) => {
  const lSListing = getLocalStorage({ moduleName, key });
  let is_equal = false;
  let columns = defaultColumns;
  if (lSListing && (lSListing.length > 0)) {
    is_equal = isEqual(lSListing, defaultColumns);
    columns = defaultColumns && defaultColumns.map((col) => {
      const { key } = col;
      const lSListingObject = find(lSListing, { key });
      return lSListingObject ? { ...col, show: (lSListingObject.show || false) } : col;
    });
  }
  if (!is_equal) {
    setLocalStorage({ moduleName, key, data: columns });
  }

  return { is_equal, columns };
};

const defaultTimeZone = 'Asia/Kolkata';

export const getTenantLocalDateTime = (date, returnDateTimeAsString = true, isDateTimeFormat = false) => {
  const currentDateTime = date || '';
  const BETimeZone = getLocalStorage({ key: 'timeZone' });
  const timeZone = BETimeZone || defaultTimeZone; // 'Canada/pacific'
  const dateWithCustomTimeZone = currentDateTime ? dayjs(currentDateTime).tz(timeZone) : dayjs().tz(timeZone);

  if (returnDateTimeAsString) {
    const dateTimeFormat = isDateTimeFormat ? apiDefaultDateTimeFormat : apiDefaultDateFormat;
    return dateWithCustomTimeZone.format(dateTimeFormat);
  }
  return dateWithCustomTimeZone;
};

export const getTenantLocalTime = () => {
  const BETimeZone = getLocalStorage({ key: 'timeZone' });

  const timeZone = BETimeZone || defaultTimeZone; // 'Canada/pacific'
  const dateWithCustomTimeZone = dayjs().tz(timeZone);

  return dateWithCustomTimeZone.format(fallBackTimeFormat);
};

export const isProdEnv = () => (process.env.NODE_ENV === 'production');
export const isStagingEnv = () => (process.env.NODE_ENV === 'staging');

export const getSubDomain = () => {
  const subdomains = window.location.hostname.split('.').slice(0, -2);
  if (!subdomains?.length) return undefined;

  // a host url can have many subdomains, so we consider only first one as the tenant name
  return subdomains?.[0];
};

export const getDomain = () => {
  const hasSubDomain = getSubDomain();
  const hostNameArray = window.location.hostname.split('.');
  if (hasSubDomain) {
    return hostNameArray.slice(1, 3).join('.');
  }
  return hostNameArray.slice(0, 2).join('.');
};

export const getDomainNameWithPortNumber = () => {
  const hasSubDomain = getSubDomain();
  const hostNameArray = window.location.host.split('.');
  if (hasSubDomain) {
    return hostNameArray.slice(1, 3).join('.');
  }
  return hostNameArray.slice(0, 2).join('.');
};

export const getSubDomainTargetUrl = (tenantName) => {
  const { protocol } = window?.location || {};
  const domainNameWithPortNumber = getDomainNameWithPortNumber();
  const targetUrl = `${protocol}//${tenantName}.${domainNameWithPortNumber}`;
  return targetUrl;
};

export const getMainAccountTargetUrl = () => {
  const domain = getDomain();
  const { protocol, port } = window?.location || {};
  let targetUrl = `${protocol}//${domain}`;
  if (port) {
    targetUrl = `${protocol}//${domain}:${port}`;
  }
  return targetUrl;
};

export const editBreadcrumbListing = ({ BREADCRUMB_LISTING, id, TargetUrl }) => BREADCRUMB_LISTING.concat([{ title: id, target: TargetUrl, active: false }, { title: 'Edit', target: '', active: true }]);
export const ShowBreadcrumbListing = ({ BREADCRUMB_LISTING, id, TargetUrl }) => BREADCRUMB_LISTING.concat([{ title: id, target: TargetUrl, active: true }]);

export const getSortingInfo = ({ name, module_data }) => {
  let order = 'asc';
  let { sort_by } = module_data;
  const { sort_order } = module_data;
  if (name === sort_by) {
    order = (sort_order === 'desc') ? 'asc' : 'desc';
  } else {
    order = 'asc';
    sort_by = name;
  }
  return { sort_by: (sort_by || name), sort_order: order };
};

export const handleApiParamsInfo = ({ args, module_data }) => {
  let { per_page, sort_by, sort_order } = module_data;
  const page = args.page || 1;
  per_page = args.per_page || per_page;
  sort_by = args.sort_by || sort_by;
  sort_order = args.sort_order || sort_order;

  return {
    page, per_page, sort_by, sort_order,
  };
};

export const getListOfSortableClasses = ({ name, sort_by, sort_order }) => {
  let sortable = ['sortable', 'sortable-asc'];

  if (name === sort_by) {
    sortable.push('sortable-active');
    if (sort_order === 'desc') {
      sortable = sortable.filter((e) => e !== 'sortable-asc');
      sortable.push('sortable-desc');
    }
  }
  return sortable?.join(' ');
};

// This method use to display page_start and page_end and total_count on index page.
export const displayPageStartAndEndRangeAndTotalItems = ({
  total_count, current_page, per_page,
}) => {
  let page_start;
  let page_end;
  const perPage = per_page || PER_PAGE;

  page_start = ((perPage * current_page) - perPage) + 1;
  if (total_count < 1) {
    page_start = 0;
  }
  page_end = perPage * current_page;
  if (page_end > total_count) {
    page_end = total_count;
  }

  return { page_start, page_end };
  // return `Displaying ${title} ${page_start}-${page_end} of ${total_count}`;
};

export const convertToTitleize = (str) => {
  if (!str) return '';
  const s = str.replace(/[_-]/g, ' ');
  const lower = String(s).toLowerCase();
  return lower.replace(/(^| )(\w)/g, (x) => x.toUpperCase());
};

export const getFinancialYears = () => {
  const minimum_start_year = 18;
  const date = new Date();
  const year = date.getYear();
  const current_year_string = year.toString().slice(-2);
  const current_year = parseInt(current_year_string, 10);
  let minimum_option_to_display = ((current_year - minimum_start_year) + 1);
  if (minimum_start_year >= current_year) {
    minimum_option_to_display = 1;
  }
  const financial_years = [];
  let current_start_year = minimum_start_year;
  for (let i = 1; i <= minimum_option_to_display; i += 1) {
    financial_years.push({ value: `${current_start_year}-${current_start_year + 1}`, label: `${current_start_year}-${current_start_year + 1}` });
    current_start_year += 1;
  }
  return financial_years;
};

export const getSelectedItem = ({ id, selected_state }) => {
  const selectedItems = selected_state?.items || [];
  const isItemsExist = !isEmpty(selectedItems);
  let items = [];
  if (id && isItemsExist) {
    const selectedItem = find(selectedItems,
      (item) => parseInt(item?.id, 10) === parseInt(id, 10));
    items = selectedItem ? [selectedItem] : [];
  }
  return items;
};

export const getItem = ({ items }) => ((items && (items.length > 0)) ? items[0] : {});

export const handleBaseKeyErrors = ({ errors }) => {
  let base_errors = [];

  if ((Object.keys(errors).length > 0) && errors.base && (errors.base.length > 0)) {
    base_errors = errors.base;
  }

  return base_errors;
};

export const handleSpecificTypeKeyErrors = ({ errors, keys }) => {
  const all_errors_with_specific_type = [];
  keys.map((type) => {
    const errors_with_specific_type = Object.keys(errors).filter((error) => (error === type));
    if (errors_with_specific_type.length > 0) {
      map(errors_with_specific_type, (key) => {
        all_errors_with_specific_type.push({ [key.split(`${type}.`)[1] || key.split(`${type}.`)[0]]: errors[key]?.join(', ') });
      });
    }
  });
  return all_errors_with_specific_type;
};

export const handleAssociatedObjectKeyErrors = ({ errors, keys }) => {
  const all_errors_with_specific_type = [];
  keys.map((type) => {
    const errors_with_specific_type = Object.keys(errors).filter((error) => error.includes(type));
    if (errors_with_specific_type.length > 0) {
      map(errors_with_specific_type, (key) => {
        all_errors_with_specific_type.push({ [key.split(`${type}.`)[1] || key.split(`${type}.`)[0]]: errors[key]?.join(', ') });
      });
    }
  });
  return all_errors_with_specific_type;
};

export const resetErrors = ({ errors = {}, error_name = '' }) => {
  let errorKeys = error_name;
  if (!isArray(error_name)) errorKeys = [errorKeys];
  return omit(errors, errorKeys);
};

// FUNCTION   this function helps to remove warnings and errs for a field
export const resetIssues = ({ errors = {}, warnings = {}, issueName = '' }) => {
  let iKeys = issueName;
  if (!isArray(issueName)) iKeys = [iKeys];
  return { errors: omit(errors, iKeys), warnings: omit(warnings, iKeys) };
};

export const getPermissionsHash = ({ required_object, required_permissions }) => {
  const permission_hash = {};
  required_permissions.map((permission) => {
    permission_hash[permission] = (required_object.meta && required_object.meta.permissions_hash[permission]) || false;
    return false;
  });

  return permission_hash;
};

export const getBusinessPermissions = ({ required_object, required_permissions }) => {
  const permission_hash = {};
  required_permissions.map((permission) => {
    permission_hash[permission] = (required_object.meta && required_object.meta[permission]) || {};
    return false;
  });

  return permission_hash;
};

export const stringToArray = ({ entered_string }) => {
  let expected_output_array = [];
  if ((entered_string.length > 0) && (typeof entered_string === 'string')) {
    expected_output_array = entered_string.split(',');
    expected_output_array = expected_output_array.map((i) => i.trim());
    expected_output_array = expected_output_array.filter((entry) => entry.trim() !== '');
    return expected_output_array;
  }
  return expected_output_array;
};

export const setIndexToNestedItems = ({ items }) => {
  let listOfItems = isArray(items) ? items : [];
  listOfItems = map(listOfItems, (oi, index) => ({ ...oi, index }));
  return listOfItems;
};

export const getNextIndexFromItems = ({ items }) => {
  if (items.length === 0) return 0;
  let maxIndex = 0;
  items.forEach((item) => {
    if (item.index > maxIndex) {
      maxIndex = item.index;
    }
  });
  return maxIndex + 1;
};

export const addPositionAttrToNestedFormItems = ({ items }) => {
  if (items.length === 0) return [];
  const deletedItems = filter(items, { _destroy: true });
  const filteredItems = filter(items, { _destroy: false });
  const updatedItems = map(filteredItems, (item, index) => ({ ...item, position: index + 1 }));
  return [...updatedItems, ...deletedItems];
};

export const getNextPositionFromItems = ({ items }) => {
  if (items.length === 0) return 0;
  let maxPosition = 0;
  items.forEach((item) => {
    if (item.position > maxPosition) {
      maxPosition = item.position;
    }
  });
  return maxPosition + 1;
};

export const generateFieldErrorKeys = ({ prefix, idx, errorKeys }) => {
  let errors = [];
  errors = errorKeys && errorKeys.map((key) => (`${prefix}[${idx}].${key}`));
  return errors;
};

export const doesErrorExistOnField = ({ errors = {}, error_keys }) => {
  let keys = error_keys;
  if (isEmpty(errors) || (errors === undefined) || (keys === undefined)) return false;
  if (!isArray(keys)) keys = [keys];
  const arr = map(keys, (ee) => !!errors[ee]);
  return includes(arr, true);
};

// FUNCTION   this function checks if any issues (errors and warnings) exists for a field
export const doesIssueExistOnField = ({ issues, issueKeys }) => {
  let iKeys = issueKeys;
  if (isEmpty(issues) || (issues === undefined) || (iKeys === undefined)) return false;
  if (!isArray(iKeys)) iKeys = [iKeys];
  const arr = map(iKeys, (ee) => !!issues[ee]);
  return includes(arr, true);
};

export const getFieldErrors = ({ errors = {}, error_keys }) => {
  let keys = error_keys;
  if (isEmpty(errors) || (errors === undefined) || (keys === undefined)) return '';
  if (!isArray(keys)) keys = [keys];

  const allErrors = [];
  map(keys, (en) => {
    let errorString = '';
    const errorVal = errors?.[en] || '';
    if (errorVal && isArray(errorVal)) {
      errorString = errorVal.join(', ') || '';
    } else if (isString(errorVal)) {
      errorString = errorVal;
    } else {
      errorString = '';
    }
    if (errorString) {
      allErrors.push(errorString);
    }
  });

  const uniqueErrors = allErrors?.filter((v, i, a) => a?.indexOf(v) === i);
  return uniqueErrors?.join(', ') || '';
};

// FUNCTION   this function helps to get any issues (errors and warnings) with field
export const getFieldIssues = (args) => {
  const { issues = {} } = args;
  let { issueKeys } = args;

  if (isEmpty(issues) || (issues === undefined) || (issueKeys === undefined)) return '';
  if (!isArray(issueKeys)) issueKeys = [issueKeys];

  const allIssues = [];
  issueKeys.forEach((en) => {
    if (issues[en]) {
      const errArrToStr = issues[en]?.join(', ');
      allIssues.push(errArrToStr);
    }
  });

  const uniqueIssues = allIssues.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueIssues?.join(', ') || '';
};

export const getNumberOfFiltersApplied = ({ search_params = {} }) => {
  const all_search_params_values = values(search_params);
  const all_advance_search_applied_array = filter(all_search_params_values, (el) => !['', [], undefined, null].includes(trim(el)));
  const number_of_advanse_search_applied = (all_advance_search_applied_array && all_advance_search_applied_array.length);
  return number_of_advanse_search_applied;
};

export const csvFileNameGenerator = ({
  prefix, selected_object, attrs,
}) => {
  let csv_name;

  map(attrs, (attr) => {
    if (attr.type === 'date') {
      const value = selected_object[attr.key] && new Date(selected_object[attr.key]);
      const date = dayjs(value).format('yyyy MMMM dd');
      const formated_date = date.split(' ')?.join('_')?.toUpperCase();
      csv_name = prefix ? `${prefix}_${formated_date}` : `${formated_date}`;
    }

    if ((attr.type === 'string') && selected_object[attr.key]) {
      const name_arr = selected_object[attr.key] && selected_object[attr.key]?.split(' ');
      const new_value_arr = take(name_arr, 2)?.join('_')?.toUpperCase();
      csv_name = new_value_arr ? `${csv_name}_${new_value_arr}` : `${csv_name}`;
    }
  });

  return `${csv_name}.csv`;
};

export function setCurrentUrlInSessionStorage(url) {
  sessionStorage.setItem('currentUrl', url);
}

export function getCurrentUrlFromSessionStorage() {
  return sessionStorage.getItem('currentUrl');
}

export function getCurrentUrl() {
  return typeof window !== 'undefined' ? window.location.href : '';
}

export function getCurrentAnchor() {
  return typeof window !== 'undefined' ? window.location.hash : '';
}

export const getItemIndexOnArrowUpAndDown = (e, activeItemIdx, totalItems) => {
  let idx = 0;
  if ((totalItems > 1)) {
    if (e.keyCode === 38) {
      let index = activeItemIdx - 1;
      if (index <= 0) index = 0;
      idx = index;
    } else if (e.keyCode === 40) {
      let index = activeItemIdx + 1;
      if (index > (totalItems - 1)) index = (totalItems - 1);
      idx = index;
    }
  }
  return idx;
};

export function fetchRequiredOnAppStart(dispatch) {
  fetchSettings();
  fetchUiPermissions();
  fetchNotices();
  fetchI18nFields();
  dispatch({ type: FETCH_UNITS_UTILITIES });
  dispatch({ type: FETCH_ACTIVE_PLAN });
  dispatch({ type: FETCH_FEATURES });
  // Note: Currently we don't require plans api. We use "planCodeMappingWithName" constant for plan label purpose.
  // dispatch({ type: FETCH_PLANS });
  getAllRoles();
}

export const roundToTwo = (num) => +(`${Math.round(`${num}e+2`)}e-2`);

export const allowTwoDecimal = (value) => {
  // const newValue = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  const regex = new RegExp(/^\d*(\.\d{0,2})?$/, 'g');
  let newValue = value;

  if (value && !regex.test(value)) {
    if (isNumber(value)) {
      const valueArr = value.toString().match(/^-?\d+(?:\.\d{0,2})?/); // .match function give array ie. ['123.12', index: 0, input: "1232.2e3", groups: undefined]
      if (isArray(valueArr) && !isEmpty(valueArr)) newValue = (valueArr && valueArr[0]) || '';
    } else {
      const valueArr = value.match(/^-?\d+(?:\.\d{0,2})?/); // .match function give array ie. ['123.12', index: 0, input: "1232.2e3", groups: undefined]
      if (isArray(valueArr) && !isEmpty(valueArr)) newValue = (valueArr && valueArr[0]) || '';
    }
  }
  return newValue;
};

/*
  This is a recusive function which calculates colspan for a column cell.
*/
export const calculateColSpan = (column) => {
  if (column.nested && column.nested.length > 0) {
    const [firstNestedColumn] = column.nested;
    return column.nested.length * calculateColSpan(firstNestedColumn);
  } return 1;
};

/*
  This function check whether a column should be rendered.
  If it's the first header row, we return true for all columns.
  For all subsequent rows we check if it has nested columns depending on current
  depth of rows.
  This is only important to not push any unwanted columns in the row stream.
*/
export const shouldRender = (column, depth, skipNestedCheck) => {
  if (depth === 0 || skipNestedCheck) return true;
  const immutableColumn = fromJS(column);
  const query = [];
  for (let i = 0; i < depth; i += 1) {
    query.push('nested');
  }
  return !!immutableColumn.getIn(query);
};

/*
  This recursively calculates the depth of a leaf in column tree.
*/
const calculateColumnDepth = (column) => {
  if (column.nested && column.nested.length > 0) {
    const [firstNestedColumn] = column.nested;
    return 1 + calculateColumnDepth(firstNestedColumn);
  } return 1;
};

// Returns maximum depth/height of the column tree, required for giving rowspan to some columns.
export const calculateColumnTreeHeight = (columns) => {
  let maxDepth = 1;
  for (let i = 0; i < columns.length; i += 1) {
    const columnDepth = calculateColumnDepth(columns[i]);
    if (maxDepth < columnDepth) {
      maxDepth = columnDepth;
    }
  }
  return maxDepth;
};

// This recursively find out columns at a specific depth of column tree.
export const getColumnsAtDepth = (parent_column, current_depth, columnsArray) => {
  if (current_depth === 0) return parent_column;
  parent_column.nested.forEach((nested_column) => {
    const columnToConcatenate = getColumnsAtDepth(nested_column, current_depth - 1, columnsArray);
    if (current_depth === 1) {
      columnsArray.push(columnToConcatenate);
    }
  });
  return columnsArray;
};

// returns array of nested columns
export const getColumnsToRender = (parent_column, current_depth) => {
  if (current_depth === 0) return [parent_column];
  const columnsArray = [];
  return getColumnsAtDepth(parent_column, current_depth, columnsArray);
};

// Filter columns where key is equal to passed key and return first item.
export const getColumnForKey = (columns, key) => columns.filter((column) => column.key === key)[0];

// This returns an array with the leaf node of column tree.
export const getLeafColumns = (
  parent_column, parent_key_array,
  columnArrayToUpdate, active_columns,
) => {
  // Need to keep track of parent key array, to look for data value accordingly.
  if (!parent_column.nested) {
    // Merge active_columns's data for this column key
    const active_column_data = getColumnForKey(active_columns, parent_column.key);
    return columnArrayToUpdate.push({
      ...parent_column,
      ...active_column_data,
      parent_key_array,
    });
  }
  // For each nested column, parent key array will have different values
  parent_column.nested.forEach((nested_column) => {
    const parent_key_arr = [...parent_key_array];
    parent_key_arr.push(nested_column.key);
    getLeafColumns(nested_column, parent_key_arr, columnArrayToUpdate, active_columns);
  });
  return columnArrayToUpdate;
};

// This forms new column array for showing the data in the table.
// header_columns will be received from the backend.
export const getDataColumns = (active_columns, header_columns = []) => {
  // Let's fetch all the end nodes of columns.
  const leafColumns = [];
  header_columns.forEach((header_column) => {
    getLeafColumns(header_column, [header_column.key], leafColumns, active_columns);
  });
  // Now we need to merge active columns with leafColumns.
  // Suppose someone hides quantity columns, so we need to hide that in all the nested columns
  // like Jan, Feb etc.
  // Let's do this while iterating, so that we don't iterate twice.
  return leafColumns;
};

export const addColumnProperties = (parent_column, active_columns) => {
  if (!parent_column.nested) {
    const active_column_data = getColumnForKey(active_columns, parent_column.key);
    return { ...parent_column, ...active_column_data };
  }
  const defaultColumnProps = getColumnForKey(active_columns, 'index');
  return {
    ...defaultColumnProps,
    ...parent_column,
    nested: parent_column.nested.map((nested_column) => addColumnProperties(nested_column, active_columns)),
  };
};

export const getHeaderColumns = (active_columns, header_columns = []) =>
  // We need to add column properties saved in local storage to header_columns based on their keys
  header_columns.map((header_column) => addColumnProperties(header_column, active_columns));

export const setLastOpenedReportInfo = (report_info, report_type) => {
  const openedReports = getLocalStorage({ key: OPENED_REPORTS }) || {};
  setLocalStorage({ key: OPENED_REPORTS, data: { ...openedReports, [report_info.path]: { ...report_info, report_type } } });
  // setLocalStorage({ key: LAST_OPENED_REPORT, data: { ...report_info, report_type } });
};

export const getOpenedReportInfo = ({ location }) => {
  const { pathname } = location;
  // Report name is required to fetch lot of meta data related to this report.
  const reports = getLocalStorage({ key: OPENED_REPORTS });
  const report = (reports && reports[pathname]) || {};
  return report;
};

// A common function to handle date changes.
// When date it cleared it provides null value which can result in crash.
export const handleDateChange = (args) => {
  const { callback, key, value } = args;
  const modValue = value || '';
  callback(modValue, key);
};

export const getContentForStatus = (moduleName, status, number) => (
  <>
    Update status to&nbsp;
    <b>{startCase(status)}</b>
    &nbsp;for&nbsp;
    {moduleName}
    &nbsp;
    <b>{number}</b>
  </>
);

export const formatNumber = ({
  number, currency = false, minimumFractionDigits = 2, currencySymbol = INR_CURRENCY_SYMBOL,
}) => {
  const style = currency ? { style: 'currency', currency: currencySymbol, minimumFractionDigits } : { minimumFractionDigits };
  let format;
  if (currencySymbol === INR_CURRENCY_SYMBOL) format = 'en-IN';
  if (currencySymbol === USD_CURRENCY_SYMBOL) format = 'en-US';

  if (format) {
    const formatter = new Intl.NumberFormat(format, style);
    return formatter.format(number);
  }

  const formatter = new Intl.NumberFormat(undefined, style);
  return formatter.format(number);
};

export const hocPropTypes = {
  newState: object.isRequired,
  handleChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  updateHocStates: func.isRequired,
  handleSearch: func.isRequired,
  advanceSearch: func.isRequired,
  handlePerPage: func.isRequired,
  clearSearchFilter: func.isRequired,
  reloadListing: func.isRequired,
};

export const snakeToPascalCase = (str) => {
  let newStr = str;
  newStr += '';
  newStr = newStr.split('_');

  function upper(newStr) {
    return newStr.slice(0, 1).toUpperCase() + newStr.slice(1, newStr.length);
  }

  for (let i = 0; i < newStr.length; i += 1) {
    const str2 = newStr[i].split('/');
    for (let j = 0; j < str2.length; j += 1) {
      str2[j] = upper(str2[j]);
    }
    newStr[i] = str2?.join('');
  }
  return newStr?.join('');
};

// FUNCTION: function to return path to a element in deep nested object
//           Eg: for 'par[0].or[1].k' => ['par', '0', 'or', '1', 'k']
export const getElPathInNestedObj = (str) => {
  const newStr = str.replace(/([\[\]\.])/g, ' ');
  let splitKeys = newStr.split(' ');
  splitKeys = splitKeys.filter((key) => key !== '');
  return splitKeys;
};

// FUNCTION: function to get classnames for a field container
export const getClassNamesForField = (name, fieldContainerClassNames, errors) => classnames(fieldContainerClassNames, { error: doesErrorExistOnField({ errors, error_keys: [name, `${name}_id`] }) });

export const generateDeleteItemContentText = ({ canHighlightText = true, highlightedText }) => {
  if (canHighlightText && highlightedText) {
    return (
      <div>
        Are you sure you want to delete&nbsp;
        <b>{highlightedText}</b>
        ?
      </div>
    );
  }

  if (!canHighlightText && highlightedText) {
    return (
      <div>
        Are you sure you want to delete&nbsp;
        {highlightedText}
        ?
      </div>
    );
  }

  return (<div>Are you sure you want to delete?</div>);
};

generateDeleteItemContentText.propTypes = {
  highlightedText: string,
  canHighlightText: bool,
};

generateDeleteItemContentText.defaultProps = {
  highlightedText: '',
  canHighlightText: true,
};

export const createSanitizedSearchParams = (searchParams, queryParams) => {
  let sanitizedSearchParams = searchParams;
  Object.entries(queryParams).forEach(([key, value]) => {
    sanitizedSearchParams = value ? { ...sanitizedSearchParams, [key]: value } : sanitizedSearchParams;
  });
  return sanitizedSearchParams;
};

export const clearObjectValues = ({ jsonObject }) => {
  const newJsonObject = {};
  const keys = Object.keys(jsonObject);
  keys.map((key) => {
    if (isString(jsonObject[key]) || isNumber(jsonObject[key])) {
      newJsonObject[key] = '';
    }

    if (isArray(jsonObject[key])) {
      newJsonObject[key] = [];
    }
    return null;
  });

  return newJsonObject;
};

export const getBreadcrumbUrl = ({ item }) => {
  let href = (item.target[0] === '/') ? `#${item.target}` : `#/${item.target}`;

  if (!item && item.target) {
    href = '#/';
  }

  if (!item && item.target && item.target.includes('/')) {
    href = `#/${item.target}`;
  }

  return href;
};

export const renderCommaSeperatedString = (str = '') => {
  if (!str) return '';
  const stringToArr = str.split(',');
  const compactStringToArr = compact(stringToArr);
  const compactStringToArrLength = compactStringToArr.length;
  return compactStringToArr?.map((subStr, idx) => {
    if (subStr.length > 0) {
      return (
        <>
          <span>{subStr}</span>
          {
            idx < (compactStringToArrLength - 1)
              ? <br /> : null
          }
        </>
      );
    }
    return null;
  });
};

export const renderCommaSeperatedElements = ({
  items = [], key, idKey = 'id', addLink = false, linkTo = 'show', baseUrl, noResultFoundMessage,
  callCBFunc = false, cb = () => ({}), renderFunc = false,
}) => {
  if ((items && items.length) > 0) {
    return (
      map(items, (item, index) => (
        <>
          {!renderFunc && (
            <>
              {
                addLink
                  ? (
                    <>
                      <Link to={(linkTo === 'edit') ? `${baseUrl}/${item[idKey]}/edit` : `${baseUrl}/${item.id}`}>{item[key]}</Link>
                      {callCBFunc ? cb(item) : null}
                    </>
                  )
                  : (
                    <>
                      {item[key]}
                      {callCBFunc ? cb(item) : null}
                    </>
                  )
              }
              {(index !== (items.length - 1)) ? (<>,&nbsp; </>) : ''}
            </>
          )}
          {renderFunc && (<>{renderFunc(item, index, items.length)}</>)}
        </>
      ))
    );
  }
  return noResultFoundMessage;
};

export const priceInclusiveOrExclusiveTax = ({ inclusive_tax }) => {
  if (inclusive_tax === true) {
    return 'inc.';
  }
  if (inclusive_tax === false) {
    return 'exc.';
  }
  return '';
};

export const doesTargetArrayContainAllElements = (sourceArray, targetArray) => {
  if (sourceArray && sourceArray.length < 1) return false;
  return sourceArray.every((i) => targetArray.includes(i));
};

// export const doesTargetArrayContainAnyElements = (sourceArray, targetArray) => (sourceArray.filter(e => targetArray.includes(e)).length > 0);
export const parseAndRoundToTwoDigits = (numberString) => round((parseFloat(numberString) || 0), 2);

export const getAcronym = ({ name, upperCase = false }) => {
  if (!name) return '';

  const str = name;
  const strSplit = str.split(' ');
  let acronym = '';
  if (strSplit.length === 1) {
    acronym = strSplit[0].slice(0, 2);
    if (upperCase) {
      acronym.toUpperCase();
    }
  } else if (strSplit.length > 1) {
    let firstLetter = strSplit[0][0];
    let secondLetter = strSplit[strSplit.length - 1][0];

    if (upperCase) {
      firstLetter = firstLetter.toUpperCase();
      secondLetter = secondLetter.toUpperCase();
    }
    acronym = firstLetter + secondLetter;
  }

  return acronym;
};

// WHAT this function return file details related to pdf download
export const getPdfFileDetails = (response) => {
  const { headers, request } = response;
  const fileName = headers['file-name'];
  return { fileName, fileURL: request.responseURL };
};

// WHAT   this function take a field's input value and some params like type of the input value,
//        default value for the field and return the field input value if there's any or returns the
//        default value based on the type
export const inputValueSanitizer = ({
  value, type, defaultValue, otherParams = {},
}) => {
  let finalValue = defaultValue;
  switch (type) {
    case STRING:
      finalValue = value || '';
      break;
    case BOOLEAN:
      finalValue = value || false;
      break;
    case ARRAY:
      finalValue = value || [];
      break;
    case OBJECT:
      finalValue = value || {};
      break;
    case DATE: {
      const { dtFormat } = otherParams;
      if (dtFormat) {
        finalValue = value ? dayjs(value).format(dtFormat) : '';
      } else {
        finalValue = value || '';
      }
      break;
    }
    default:
      break;
  }
  return finalValue;
};

// WHAT  this function return row state color
export const getStatusClass = (status) => {
  if (status) {
    const rowStatuses = keys(indexBgStatusColors);
    let rowStatus = rowStatuses.find((s) => indexBgStatusColors[s].includes(status));

    if (!rowStatus) {
      rowStatus = status;
    }

    return rowStatus;
  }
  return '';
};

// On custom condition basis we override the rowStatusClass in classes.
export const setAdditionalClassToTableRow = ({ moduleName, rowStatusClass, item }) => {
  switch (moduleName) {
    case 'purchase_returns': {
      if ((item?.debit_note_status === 'accounted') && (item?.state === 'approved')) {
        return rowStatusClass;
      }
      return '';
    }
    case 'users': {
      if (item?.suspended) {
        return `${rowStatusClass} row-warning`;
      }
      if (!item?.suspended) {
        return `${rowStatusClass} row-success`;
      }
      return rowStatusClass;
    }
    default:
      return rowStatusClass;
  }
};

// WHAT  this function sanitizes selectedValue for select element
export const selectedValueSanitizer = (selectedObj, key) => {
  const finalSelectedObj = selectedObj || {};

  if (key) {
    return finalSelectedObj[key] || '';
  }
  return '';
};

// WHAT   this function copies a given text to the clipboard
export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document?.body?.removeChild(el);
};

export const doesAnyKeyExistInObject = ({ keys = [], obj = {} }) => {
  let showExactKeySpecificError = false;
  map(keys, (k) => {
    showExactKeySpecificError = (showExactKeySpecificError || has(obj, k));
  });
  return showExactKeySpecificError;
};

// WHAT   this func sanitizes the custom fields data in formdata, which is it removes
//        `<custom_field_id>_formatted` data
export const sanitizeCustomFieldsData = (formData) => {
  if (formData.custom_fields_data) {
    const newFormData = formData;
    const customFieldsData = formData.custom_fields_data;
    const customFieldsDataKeys = keys(customFieldsData);
    const omitKeys = customFieldsDataKeys.filter((k) => k.includes('_formatted'));

    const newCustomFieldsData = omit(customFieldsData, omitKeys);
    newFormData.custom_fields_data = newCustomFieldsData;
    return newFormData;
  }
  return formData;
};

export const arrayBufferToString = (arrayBuffer) => String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));

export const altUnitLabels = (quantity, units, primaryUnitLabel) => {
  const allText = [];
  units.forEach((unit) => {
    // primaryUnitMultiplier * primaryUnit = packageUnitMultiplier * packageUnit
    // 12 piece = 1 dozen
    // 200 piece = 7 kg
    let text = '';
    // If primaryUnitMultiplier and packageUnitMultiplier are in
    // a fraction relationship(200 piece = 7 kg), remainder will come in fractions
    // In these cases it makes sense to show quotient in fraction rather than showing
    // floored quotient and fraction remainder.
    // E.g. 300 qty will show 10.5kg instead 10kg 14.2 pieces.
    // There are 4 cases:
    // Case 1: unit.primary_unit_multiplier > unit.package_unit_multiplier
    //         Case A: If multipliers have direct relationship. 12 piece = 1 box
    //                 Entered qty 18 will show 1 box 6 piece.
    //
    //         Case B: If multipliers have fraction relationship. 200 pice = 7kg
    //                 Entered qty 300 will show 10.5kg.
    //
    // Case 2: unit.primary_unit_multiplier < unit.package_unit_multiplier
    //         Case A: If multipliers have direct relationship. 1 piece = 5 box.
    //                 Entered qty 5 will show 25 box.
    //
    //         Case B: If multipliers have fraction relationship. 10 piece = 12 kg
    //                 Entered qty 12 will show 14.4kg.
    // We set default primary_unit_multiplier is 1.
    const primaryUnitMultiplier = unit?.primary_unit_multiplier || 1;
    if (primaryUnitMultiplier > 1) {
      const quotient = Math.floor((quantity) / primaryUnitMultiplier);
      const remainder = (quantity % primaryUnitMultiplier);
      if (remainder > 0) {
        text = `${quotient} ${unit.label} and ${round(remainder, 2)} ${primaryUnitLabel}`;
        allText.push(text);
      } else if (quotient > 0) {
        text = `${quotient} ${unit.label}`;
        allText.push(text);
      }
    } else {
      const quotient = (quantity);
      text = `${quotient} ${unit.label}`;
      allText.push(text);
    }
  });
  return allText;
};

export const showProductQtyByUnit = ({ quantity = 0, product = {} }) => {
  let returnTextArr = [];
  const units = (product?.units) || [];
  if (!quantity || units.length <= 0) return [];
  let primaryUnitLabel = product?.primary_unit || '';
  const newUnits = filter(units, (u) => {
    const primaryUnit = product?.primary_unit?.toLowerCase() || '';
    if ((u.unit !== primaryUnit) && (u.primary_unit_multiplier !== 1)) {
      return true;
    }
    primaryUnitLabel = u.label;
    return false;
  });
  const unitsExist = (newUnits?.length > 0);
  if (unitsExist) {
    returnTextArr = altUnitLabels(quantity, newUnits, primaryUnitLabel);
  }

  return returnTextArr;
};

export const filterOptionsCreatable = (options, filter, excludeOptions, props) => {
  let filteredOptions = options.slice();
  if (filter) {
    filteredOptions = filteredOptions && map(filteredOptions, (option) => {
      if (option && option.className) return { [props.labelKey]: filter, [props.valueKey]: filter, className: 'Select-create-option-placeholder' };
      return option;
    });
  }
  return filteredOptions;
};

const customDescriptionId = 'Custom Description';
const productServiceId = 'Product or Service';

const customDescriptionOption = { id: customDescriptionId, name: customDescriptionId, disabled: true };
const productServiceOption = { id: productServiceId, name: productServiceId, disabled: true };

export const generateItemVariantsOptions = (input, itemProduct, products) => {
  const itemProductsOrServices = (itemProduct?.productsOrServices) || [];
  let description = (itemProduct && !isEmpty(itemProduct.description)) ? itemProduct.description : '';
  if (input) {
    description = { id: input, name: input };
  }

  const productsOrServices = unionBy(itemProductsOrServices, products, 'id') || [];

  return ({ description, productsOrServices });
};

export const generateVariantOptions = (itemProduct) => {
  if (!isEmpty(itemProduct.description) && !isEmpty(itemProduct.productsOrServices)) {
    return [
      customDescriptionOption,
      itemProduct.description,
      productServiceOption,
      ...itemProduct.productsOrServices,
    ];
  }

  if (!isEmpty(itemProduct.description) && isEmpty(itemProduct.productsOrServices)) {
    return [
      customDescriptionOption,
      itemProduct.description,
      productServiceOption,
    ];
  }

  if (isEmpty(itemProduct.description) && !isEmpty(itemProduct.productsOrServices)) {
    return [
      customDescriptionOption,
      productServiceOption,
      ...itemProduct.productsOrServices,
    ];
  }

  return [
    customDescriptionOption,
    productServiceOption,
  ];
};

export const getSelectedIdsForCurrentPage = (items = [], selected_ids = []) => {
  if (isEmpty(selected_ids)) return [];
  const pageItemIds = map(items, 'id');
  const selectedIdsForCurrentPage = filter(selected_ids, (id) => pageItemIds.includes(id));
  return selectedIdsForCurrentPage;
};

export const getIsMasterCheckboxChecked = ({
  items, is_master_checkbox_checked, per_page, selectedIdsForCurrentPage,
}) => {
  let isMasterCheckboxChecked = is_master_checkbox_checked;
  const pageItems = (items && items.length) || 0;

  let perPage = parseInt(per_page, 10);
  if (pageItems < perPage) perPage = pageItems;

  if (perPage === 0) return false;

  if ((perPage === selectedIdsForCurrentPage.length)) {
    isMasterCheckboxChecked = true;
  }
  if (isMasterCheckboxChecked && (perPage !== selectedIdsForCurrentPage.length)) {
    isMasterCheckboxChecked = false;
  }
  return isMasterCheckboxChecked;
};

export const getUrlParams = (url) => {
  const result = {};
  const params = (url.split('?')[1] || '').split('&');
  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      const paramParts = params[param].split('=');
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || '');
    }
  }
  return result;
};

export const formatDateForUi = (date = dayjs(), format = apiDefaultDateFormat) => dayjs(date).format(format);

export const buildImagesFormData = (images) => {
  const formData = new FormData();

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];
    if (image.id) {
      if (image._destroy) {
        formData.append(`product[images_attributes][${i}][id]`, image.id);
        formData.append(`product[images_attributes][${i}][_destroy]`, true);
        formData.append(`product[images_attributes][${i}][index]`, i);
      }
    } else {
      formData.append(`product[images_attributes][${i}][attachment]`, image, image.name);
      formData.append(`product[images_attributes][${i}][index]`, i);
    }
  }
  return formData;
};

export const createFormData = (data) => serialize(data, {
  indices: true, allowEmptyArrays: true,
});

export const urlParamFor = (key) => {
  const urlString = window.location.href;
  const paramString = urlString.split('?')[1];

  const queryString = new URLSearchParams(paramString);

  return queryString.has(key) ? JSON.parse(queryString.get(key)) : null;
};

export const defaultLoaderForChunk = () => null;

export const clearAuthDetailsFromBrowserStorage = () => {
  localStorage.setItem(AUTH_TOKEN_HEADER_FOR_BE, '');
  localStorage.setItem(USER_MOBILE_HEADER_FOR_BE, '');
  localStorage.setItem(CSRF_TOKEN_FOR_BE, '');
};
export const isStagingOrLocalEnv = () => {
  let isDevelopmentEnv = false;
  let isStagingEnv = false;

  if (process.env.NODE_ENV !== 'production') {
    isDevelopmentEnv = window.location.hostname.indexOf('lvh.me') !== -1;
  } else {
    const STAGING_HOSTNAME_SUB = '.bsapbeat.com';
    const { hostname } = window.location;
    isStagingEnv = hostname.indexOf(STAGING_HOSTNAME_SUB) !== -1;
  }

  return isStagingEnv || isDevelopmentEnv;
};

// We validate number value.
// 123, 123. , 123.123 , 123-123 , 0.123

export const validateNumberValue = (number, regExpStr) => {
  if (!number) return true;
  const regex = new RegExp(regExpStr || QUANTITY_VALUE_REGEX);
  return regex.test(number);
};

export const validateEditedNumberValue = ({
  event = {}, item = {}, units = [], inputKey = '', prevText = '', curText = '', unit = undefined,
}) => {
  const value = curText || (event?.target || {})?.value || '';

  const currentValueLength = value.length;
  const previousValueLength = (prevText || item?.[inputKey])?.length;
  const isTextDeleted = (currentValueLength < previousValueLength);

  if (isTextDeleted) return true;

  const selectedUnit = unit || find(units, { unit: item.input_quantity_unit }) || {};

  const regExpStr = selectedUnit?.validation_regex || '';
  const isValid = validateNumberValue(value, regExpStr);

  return isValid;
};

export const getI18nFields = (moduleName, fieldName) => {
  const i18nFields = getLocalStorage({ key: 'i18nFields' }) || {};
  const i18nModuleData = (i18nFields && i18nFields[moduleName]) || {};
  if (fieldName) {
    const i18nFieldData = (i18nModuleData && i18nModuleData[fieldName]) || {};
    return i18nFieldData;
  }
  return i18nModuleData;
};

export const getFilteredDefaultColumns = (defaultColumns = [], moduleName = '', fieldKeys = []) => {
  const showOrHideColumns = {};
  fieldKeys.forEach((k) => {
    showOrHideColumns[k] = (getI18nFields(moduleName, k) || {}).exists;
  });

  // Filter column configs if Column key is not present in the `showOrHideColumns` or
  // `showOrHideColumns[key]` has value `true`
  return defaultColumns.filter((columnConfig) => !(columnConfig.key in showOrHideColumns) || showOrHideColumns[columnConfig.key]);
};

export const getSelectedProductUnit = (units: ProductUnit[] = [], value = ''): ProductUnit => {
  if (isEmpty(value) || isEmpty(units)) { return {}; }
  return (units.find((u) => u.unit === value) || {});
};

export const sumToUnit = (quantities: (string | number)[], unit: ProductUnit) => {
  let total = 0;
  const primaryUnitMultiplier = unit.primary_unit_multiplier || 1;
  const isPrimaryUnit = unit.primary_unit;

  quantities.forEach((val) => {
    if (val) {
      if (!isPrimaryUnit && typeof val === 'string') { // for eg: '110-23', '2/23' ...
        const splitValues = val.split(/-|\//);

        const compoundQuantity = splitValues[0] ? parseFloat(splitValues[0]) : 0;
        const primaryQuantity = splitValues[1] ? parseFloat(splitValues[1]) : 0;
        total += (compoundQuantity * primaryUnitMultiplier) + primaryQuantity;
      }

      if (isPrimaryUnit && !isNaN(val)) { // for eg: 1, 89.20, '2.00', '1000.2'
        total += ceil(val || '0', 2);
      }
    }
  });

  if (isPrimaryUnit) return total;
  const delimiter = (unit.validation_regex || '').match(/-|\/|\./)?.[0] || '';

  // Number based regexes have '.', it means the final result must be number or float
  if (delimiter === '.') {
    return ceil(total / primaryUnitMultiplier, 2);
  }

  const compoundQuantity = Math.floor(total / primaryUnitMultiplier);
  const primaryQuantity = total % primaryUnitMultiplier;

  // Remove empty or falsy primary value(for eg: 0 or "") before joining because there is no need
  // to render falsy primary values, for eg: 200 cases and 0 piece its better if we render
  // `200` instead of `200-0`
  return (primaryQuantity ? [compoundQuantity, primaryQuantity] : [compoundQuantity]).join(delimiter || '.');
};

export const getStockTransferItems = (stockTransfer) => {
  const key1 = 'stock_transfer_items_attributes';
  const key2 = 'stock_transfer_items';

  const stockItemsWithFirstKey = stockTransfer[key1]
    && stockTransfer[key1].length > 0
    && stockTransfer[key1];

  const stockItemsWithSecondKey = stockTransfer[key2]
    && stockTransfer[key2].length > 0
    && stockTransfer[key2];

  const stockItems = stockItemsWithFirstKey
    || stockItemsWithSecondKey || [];

  return stockItems;
};

export const getVoucherDefaultPrintFormat = (allPrintFormats, moduleName) => {
  const { printFormats } = allPrintFormats;
  const modulePrintFormats = printFormats?.[moduleName] || [];
  const moduleDefaultPrintFormat = find(modulePrintFormats, { default: true }) || {};
  return moduleDefaultPrintFormat?.file_type;
};

export const isDefaultPrintFormatExcel = (fileType = '') => {
  if (fileType?.includes('Excel') || fileType === '') {
    return true;
  }
  return false;
};

export const detectBrowser = () => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge';
  } if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.DOCUMENT_NODE === true)) {
    return 'IE';
  }
  return 'unknown';
};

// Here "n" is the number of digits you want to validate after the decimal place.

// For example: if n = 2
// Test Casese:
// 10.32  PASS
// 1213123 PASS
// 10.123132 FAIL
// 123.123 FAIL
// 11.88 PASS
// 1233.1 PASS
// 0.01 PASS

// We are setting "n" by default equal to 4 because in case
// someone deleted local storage global setting, we allow max
// number of decimal places allowed by the software to be entered.
export const generateDynamicDecimalRegex = (n = 4) => {
  const inputRegexVal = isString(n) ? parseInt(n, 10) : n;
  return `^(\\d+)([.])?(\\d{0,${inputRegexVal}})$`;
};

export const getIdFromSearchQuery = ({ location, idKey }) => {
  if (!location) return '';
  let id = '';
  const search = location?.search;
  const [, searchString] = search?.split('?');
  if ((search?.length > 0) && (searchString?.indexOf(idKey) === 0)) {
    [, id] = searchString?.split('=');
  }

  return id;
};

export const setPhoneCountryCodeWithNumber = ({ item, dialCodeKey, phoneNoKey }) => {
  let phoneNoWithCode = item?.[phoneNoKey] || '';
  if (phoneNoWithCode && item?.[dialCodeKey]) {
    phoneNoWithCode = `+${item?.[dialCodeKey]}-${phoneNoWithCode}`;
  }
  return phoneNoWithCode;
};

export const pricePerUnitDetailed = ({ item, key }) => (
  <>
    {item?.[`${key}_localized`]}
    {
      item[`${key}_tax_and_unit_label`] ? (
        <>
          <br />
          <small>{item[`${key}_tax_and_unit_label`]}</small>
        </>
      )
        : null
    }
  </>
);

export const quantityUnitDetailed = ({ item, key }) => (
  <>
    {item?.[key]}
    {
      item[`${key}_unit_label`] ? (
        <>
          <br />
          <small>{item[`${key}_unit_label`]}</small>
        </>
      )
        : null
    }
  </>
);

export const getCleanDom = (dirtyDom = '') => {
  if (!isEmpty(dirtyDom)) {
    const cleanDomText = purify.sanitize(dirtyDom);
    return parse(cleanDomText);
  }
  return '';
};
