import * as types from '../constants/recents';

export function setRecentOrders(recentOrderStates) {
  return {
    type: types.SET_RECENT_ORDERS,
    ...recentOrderStates,
  };
}

export function setRecentInvoices(recentInvoicesStates) {
  return {
    type: types.SET_RECENT_INVOICES,
    ...recentInvoicesStates,
  };
}


export function setRecentPayments(recentPaymentsStates) {
  return {
    type: types.SET_RECENT_PAYMENTS,
    ...recentPaymentsStates,
  };
}

export function setRecentReturns(recentReturnsStates) {
  return {
    type: types.SET_RECENT_RETURNS,
    ...recentReturnsStates,
  };
}

export function setRecentDebitNotes(recentDebitNotesStates) {
  return {
    type: types.SET_RECENT_DEBIT_NOTES,
    ...recentDebitNotesStates,
  };
}

export function setRecentCreditNotes(recentCreditNotesStates) {
  return {
    type: types.SET_RECENT_CREDIT_NOTES,
    ...recentCreditNotesStates,
  };
}

export function setPurchaseOrders(recentPurchaseOrdersStates) {
  return {
    type: types.SET_RECENT_PURCHASE_ORDERS,
    ...recentPurchaseOrdersStates,
  };
}

export function setPurchaseInvoices(recentPurchaseInvoicesStates) {
  return {
    type: types.SET_RECENT_PURCHASE_INVOICES,
    ...recentPurchaseInvoicesStates,
  };
}

export function setPurchaseReturns(recentPurchaseReturnsStates) {
  return {
    type: types.SET_RECENT_PURCHASE_RETURNS,
    ...recentPurchaseReturnsStates,
  };
}

export function setPaymentOuts(recent_payment_outs_states) {
  return {
    type: types.SET_RECENT_PAYMENT_OUTS,
    ...recent_payment_outs_states,
  };
}
