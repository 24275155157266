import { HOME_BREADCRUMB_LISTING } from './common';

export const SUPPLIER = 'SUPPLIER';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const SUPPLIER_FETCHED = 'SUPPLIER_FETCHED';
export const SUPPLIER_UPDATED = 'SUPPLIER_UPDATED';
export const SUPPLIER_DELETED = 'SUPPLIER_DELETED';
export const AUTOCOMPLETE_SUPPLIERS = 'AUTOCOMPLETE_SUPPLIERS';

// Start: This three constants used in 'addresses' saga and 'suppliers' reducer.
export const CREATE_SUPPLIER_ADDRESS = 'CREATE_SUPPLIER_ADDRESS';
export const UPDATE_SUPPLIER_ADDRESS = 'UPDATE_SUPPLIER_ADDRESS';
// END

export const title = 'Suppliers';
export const singularTitle = 'Supplier';
export const moduleName = 'suppliers';
export const baseUrl = '/suppliers';
export const redirectUrl = '/suppliers';
export const listingDataKey = 'suppliers';
export const itemDataKey = 'supplier';

export const OPENING_BALANCE_TYPES = [
  { label: 'Credit', value: true, description: 'Pay Out' },
  { label: 'Debit', value: false, description: 'Pay In' },
];

export const supplierConstants = {
  item: SUPPLIER,
  setItems: SET_SUPPLIERS,
  addItem: ADD_SUPPLIER,
  setItem: SUPPLIER_FETCHED,
  updateItem: SUPPLIER_UPDATED,
  deleteItem: SUPPLIER_DELETED,
  autocompleteItems: AUTOCOMPLETE_SUPPLIERS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

// Quick add constants
export const FORM_INFORMATION_TITLE = 'SUPPLIER INFORMATION';
export const RESET_SUPPLIER_ERRORS = 'RESET_SUPPLIER_ERRORS';
export const ADD_NEW_SUPPLIER = 'Add New Supplier';

export const defaultSearchParams = {
  name_cont: '',
};

// These constants are useful for advanced search in listing page.
export const requiredPrefetchingModuleNames = [];
export const Title = 'Suppliers';
export const TargetBaseUrl = 'suppliers';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['name', 'phone_no_1', 'phone_no_2', 'code', 'gstin', 'pan', 'website', 'remark', 'cin', 'opening_balance', 'opening_balance_date', 'phone_no_1_country_code', 'phone_country_code', 'phone_no_1', 'phone', 'phone_no_2_country_code', 'alt_phone_country_code', 'phone_no_2', 'alt_phone'],
  default_address: ['mobile', 'fax_number', 'landmark', 'line_1', 'line_2', 'city', 'country_code', 'state', 'postal_code'],
  primary_contact: ['name', 'mobile', 'email', 'designation'],
  supplier_specific_taxes: ['tax_id', 'specified_from'],
};
