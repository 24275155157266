import { HOME_BREADCRUMB_LISTING } from './common';

export const FEED = 'FEED';
export const SET_FEEDS = 'SET_FEEDS';
export const FEED_FILE_UPLOADED = 'FEED_FILE_UPLOADED';

export const FETCHING_FEED_CONFIGURATIONS = 'FETCHING_FEED_CONFIGURATIONS';
export const FETCHING_SETTABLE_FEED_TEMPLATES = 'FETCHING_SETTABLE_FEED_TEMPLATES';

export const title = 'Feeds';
export const singularTitle = 'Feeds';
export const moduleName = 'feeds';
export const baseUrl = '/feeds';
export const redirectUrl = '/feeds';
export const listingDataKey = 'feeds';
export const itemDataKey = 'feed';

export const feedConstants = {
  item: FEED,
  setItems: SET_FEEDS,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

const success = 'success';
const failure = 'failure';

export const FEED_STATES = [
  { label: 'Success', value: success, stateStyle: 'success' },
  { label: 'Failure', value: failure, stateStyle: 'danger' },
];

export const stockFeedModuleName = 'stocks_feed';

export const Title = 'Feeds';
export const TargetBaseUrl = 'feeds';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: false }],
);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: Title, target: TargetBaseUrl, active: true }],
);

export const ProductFeedTitle = 'Products Feed';
export const productFeedTargetBaseUrl = 'products/feeds';
export const PRODUCT_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: ProductFeedTitle, target: productFeedTargetBaseUrl, active: true }],
);

export const ProductCategoryFeedTitle = 'Product Categories Feed';
export const productCategoryFeedTargetBaseUrl = 'product_categories/feeds';
export const PRODUCT_CATEGORY_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: ProductCategoryFeedTitle, target: productCategoryFeedTargetBaseUrl, active: true }],
);

export const CustomerFeedTitle = 'Customers Feed';
export const customerFeedTargetBaseUrl = 'customers/feeds';
export const CUSTOMER_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: CustomerFeedTitle, target: customerFeedTargetBaseUrl, active: true }],
);

export const SupplierFeedTitle = 'Suppliers Feed';
export const supplierFeedTargetBaseUrl = 'suppliers/feeds';
export const SUPPLIER_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([
  { title: SupplierFeedTitle, target: supplierFeedTargetBaseUrl, active: true },
]);

export const OrderFeedTitle = 'Orders Feed';
export const orderFeedTargetBaseUrl = 'orders/feed';
export const ORDER_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: OrderFeedTitle, target: orderFeedTargetBaseUrl, active: true }],
);

export const PriceListFeedTitle = 'Price List Feed';
export const priceListFeedTargetBaseUrl = 'price_list_feed/new';
export const PRICE_LIST_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: PriceListFeedTitle, target: priceListFeedTargetBaseUrl, active: true }],
);

export const InvoiceFeedTitle = 'Invoices Feed';
export const invoiceFeedTargetBaseUrl = 'invoices/feed';
export const INVOICE_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: InvoiceFeedTitle, target: invoiceFeedTargetBaseUrl, active: true }],
);

export const PaymentFeedTitle = 'Payments Feed';
export const paymentFeedTargetBaseUrl = 'payments/feed';
export const PAYMENT_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: PaymentFeedTitle, target: paymentFeedTargetBaseUrl, active: true }],
);

export const PaymentOutFeedTitle = 'Payment Outs Feed';
export const paymentOutFeedTargetBaseUrl = 'payment_outs/feed';
export const PAYMENT_OUT_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: PaymentOutFeedTitle, target: paymentOutFeedTargetBaseUrl, active: true }],
);

export const PInvoiceFeedTitle = 'Purchase Invoice Feed';
export const pInvoiceFeedTargetBaseUrl = 'purchase_invoice_feed/new';
export const PI_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: PInvoiceFeedTitle, target: pInvoiceFeedTargetBaseUrl, active: true }],
);

export const CNFeedTitle = 'Credit Note Feed';
export const CNFeedTargetBaseUrl = 'credit_notes_feed/new';
export const CN_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: CNFeedTitle, target: CNFeedTargetBaseUrl, active: true }],
);

export const AEFeedTitle = 'Account Entry Feed';
export const AEFeedTargetBaseUrl = 'account_entries_feed/new';
export const AE_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: AEFeedTitle, target: AEFeedTargetBaseUrl, active: true }],
);

export const StockFeedTitle = 'Stocks Feed';
export const stockFeedTargetBaseUrl = 'stocks/feed';
export const STOCK_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: StockFeedTitle, target: stockFeedTargetBaseUrl, active: true }],
);

export const InventoryBatchFeedTitle = 'Inventory Batch Feed';
export const inventoryBatchFeedTargetBaseUrl = 'inventory_batch_feed';
export const INVENTORY_BATCH_FEED_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat(
  [{ title: InventoryBatchFeedTitle, target: inventoryBatchFeedTargetBaseUrl, active: true }],
);

export const ORDERS_FEED = 'ORDERS_FEED';
export const INVOICES_FEED = 'INVOICES_FEED';
export const CUSTOMERS_FEED = 'CUSTOMERS_FEED';
export const PAYMENTS_FEED = 'PAYMENTS_FEED';
export const PAYMENT_OUTS_FEED = 'PAYMENT_OUTS_FEED';
export const PRICE_LIST_FEED = 'PRICE_LIST_FEED';
export const PRODUCT_CATEGORIES_FEED = 'PRODUCT_CATEGORIES_FEED';
export const PRODUCTS_FEED = 'PRODUCTS_FEED';
export const STOCKS_FEED = 'STOCKS_FEED';
export const SUPPLIERS_FEED = 'SUPPLIERS_FEED';
