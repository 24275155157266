import { ADD_PRODUCT_CATEGORY, moduleName } from '../constants/product_categories';
import axios from '../utils/axios';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import { updateAndCloseQuickAddModule } from '../components/common/quick_add_module/helper';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';

// Get ProductCategories List (dispatch, getState)
export function fetchProductCategories(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { product_categories, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: product_categories, current_page: page, total_count, sort_by, sort_order, search_params, per_page, selected_variant, dont_merge_search_params, timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save ProductCategory.
export function saveProductCategory(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      product_category: data,
    },
  })
    .then((response) => {
      const { product_category } = response.data;
      dispatch(addItem({ moduleName, item: product_category }));
      const params = { product_category_id: product_category.id, product_category_name: product_category.name };
      updateAndCloseQuickAddModule({ identifier: ADD_PRODUCT_CATEGORY, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get ProductCategory.
export function fetchProductCategory(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { product_category } = response.data;
      dispatch(setItem({ moduleName, item: product_category }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update ProductCategory.
export function updateProductCategory(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      product_category: data,
    },
  })
    .then((response) => {
      const { product_category } = response.data;
      dispatch(updateItem({ moduleName, item: product_category }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete ProductCategory.
export function deleteProductCategory(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchProductCategoriesBasicInfo(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_product_categories_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { product_categories } = response.data;
      dispatch(autocompleteItems({ moduleName, items: product_categories }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompleteProductCategoriesName(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `${baseUrl}/autocomplete_product_categories_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function fetchProductsCategoriesWithoutUpdateStore() {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  return () => axios({
    url: `${baseUrl}/autocomplete_product_categories_name`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}
