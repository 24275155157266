import { HOME_BREADCRUMB_LISTING } from '@constants/common';

export const INVENTORY_BATCH = 'INVENTORY_BATCH';
export const SET_INVENTORY_BATCHES = 'SET_INVENTORY_BATCHES';
export const ADD_INVENTORY_BATCH = 'ADD_INVENTORY_BATCH';
export const INVENTORY_BATCH_FETCHED = 'INVENTORY_BATCH_FETCHED';
export const INVENTORY_BATCH_UPDATED = 'INVENTORY_BATCH_UPDATED';
export const INVENTORY_BATCH_DELETED = 'INVENTORY_BATCH_DELETED';
export const AUTOCOMPLETE_INVENTORY_BATCHES = 'AUTOCOMPLETE_INVENTORY_BATCHES';

export const FORM_INFORMATION_TITLE = 'INVENTORY BATCH INFORMATION';
export const RESET_INVENTORY_BATCH_ERRORS = 'RESET_INVENTORY_BATCH_ERRORS';
export const ADD_NEW_INVENTORY_BATCH = 'Add New Inventory Batch';

export const SHOW_INVENTORY_BATCH = 'SHOW_INVENTORY_BATCH';

export const title = 'Inventory Batches';
export const singularTitle = 'Inventory Batch';
export const moduleName = 'inventory_batches';
export const baseUrl = '/inventory_batches';
export const redirectUrl = '/inventory_batches';
export const listingDataKey = 'inventory_batches';
export const itemDataKey = 'inventory_batch';

export const defaultSearchParams = {
  product_name_or_inventory_manufacturer_batch_number_cont: '',
  inventory_manufacturer_batch_mfd_date_gteq: '',
  inventory_manufacturer_batch_mfd_date_lteq: '',
  inventory_manufacturer_batch_expiry_date_gteq: '',
  inventory_manufacturer_batch_expiry_date_lteq: '',
};

// This constance usefull for advance search in listing page.
export const requiredPrefetchingModuleNames = [];

export const Title = 'Inventory Batches';
export const TargetBaseUrl = 'inventory_batches';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: false,
}]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{
  title: Title, target: TargetBaseUrl, active: true,
}]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: [],
  inventory_manufacturer_batch_attributes: ['product_id', 'product', 'number', 'mfd_day', 'mfd_month', 'mfd_year', 'expiry_day', 'expiry_month', 'expiry_year'],
};

export const inventoryBatchConstants = {
  item: INVENTORY_BATCH,
  setItems: SET_INVENTORY_BATCHES,
  addItem: ADD_INVENTORY_BATCH,
  setItem: INVENTORY_BATCH_FETCHED,
  updateItem: INVENTORY_BATCH_UPDATED,
  deleteItem: INVENTORY_BATCH_DELETED,
  resetErrors: RESET_INVENTORY_BATCH_ERRORS,
  autocompleteItems: AUTOCOMPLETE_INVENTORY_BATCHES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
  formInformationTitle: FORM_INFORMATION_TITLE,
  handledErrorKeys,
  breadcrumbListing: BREADCRUMB_LISTING,
  indexBreadcrumbListing: INDEX_BREADCRUMB_LISTING,
  newBreadcrumbListing: NEW_BREADCRUMB_LISTING,
  defaultSearchParamKey: 'product_name_or_inventory_manufacturer_batch_number_cont',
};
