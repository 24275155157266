import {
  customerPhoneOrNameFilter, activeFilter, gstinFilter, gstinPresent,
  billingAddressLine1Filter, addressCityFilter, stateCodesFilter,
  branchFilter, customerCategoryFilter, customerRatingFilter,
} from '../../../components/common/advance_search/common';

export const MRP_KEY = 'mrp';
export const CREDIT_LIMIT_KEY = 'credit_limit';
export const CUSTOMER_INFO_KEY = 'customer_info';
export const CUSTOMER_RATING_KEY = 'customer_rating_id';
export const OUTSTANDING_DAY_LIMIT_KEY = 'outstanding_days_limit';
export const OUTSTANDING_INVOICE_LIMIT_KEY = 'outstanding_invoice_limit';

export const customerInfoColumn = {
  key: CUSTOMER_INFO_KEY,
  label: 'CUSTOMER',
  callFunc: true,
  inputType: undefined,
  thClassName: 'word-break',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 320,
  },
};
export const creditLimitColumn = {
  key: CREDIT_LIMIT_KEY,
  label: 'CREDIT LIMIT',
  callFunc: true,
  inputType: 'numberDecimal',
  queryKey: CREDIT_LIMIT_KEY,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 150,
  },
};
export const outstandingInvoiceLimitColumn = {
  key: OUTSTANDING_INVOICE_LIMIT_KEY,
  label: 'OUTSTANDING INVOICES LIMIT',
  callFunc: true,
  inputType: 'numberDecimal',
  queryKey: OUTSTANDING_INVOICE_LIMIT_KEY,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 220,
  },
};
export const outstandingDaysLimitColumn = {
  key: OUTSTANDING_DAY_LIMIT_KEY,
  label: 'OUTSTANDING DAYS LIMIT',
  callFunc: true,
  inputType: 'numberDecimal',
  queryKey: OUTSTANDING_DAY_LIMIT_KEY,
  thClassName: 'word-break text-right',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 220,
  },
};
export const ratingColumn = {
  key: CUSTOMER_RATING_KEY,
  label: 'CUSTOMER RATING',
  callFunc: true,
  inputType: 'dropdown',
  queryKey: CUSTOMER_RATING_KEY,
  thClassName: 'word-break',
  attributes: {
    rowspan: 1, colspan: 1, column_width: 200,
  },
};

// These are the headers, all of them with row and col spans
export const headers = [
  [
    customerInfoColumn, ratingColumn, creditLimitColumn,
    outstandingInvoiceLimitColumn, outstandingDaysLimitColumn,
  ],
];

// These are the leaf columns required to show the data
export const dataColumn = [
  customerInfoColumn, ratingColumn, creditLimitColumn,
  outstandingInvoiceLimitColumn, outstandingDaysLimitColumn,
];

export const creditControlFilterSchema = [
  { ...customerPhoneOrNameFilter, className: 'col-md-12' },
  { ...activeFilter, className: 'col-md-12' },
  { ...gstinFilter, className: 'col-md-12' },
  { ...gstinPresent, className: 'col-md-12' },
  {
    ...billingAddressLine1Filter,
    query_key: 'addresses_line_1_or_addresses_line_2_cont',
    placeholder: 'Line 1 or line 2 contains',
    key: 'addresses_line_1_or_addresses_line_2_cont',
    className: 'col-md-12',
  },
  { ...addressCityFilter, className: 'col-md-12' },
  { ...stateCodesFilter, className: 'col-md-12' },
  { ...branchFilter, query_key: 'branches_id_in', className: 'col-md-12' },
  { ...customerCategoryFilter, className: 'col-md-12' },
  { ...customerRatingFilter, className: 'col-md-12' },
];
