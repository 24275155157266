// import { saveAs } from 'file-saver';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../utils/axios';
import { moduleName, FETCH_INVOICES } from '../constants/invoices';
import setIndexRequestParams from '../utils/HandleIndexActionParams';
import { handleErrors } from '../utils/handleErrors';
import { serializeSearchParams, serializePageParams } from '../utils/SerializeParams';
import {
  setItems, addItem, setItem, updateItem, deleteItem,
} from '../actions/common';
import { startFetchingRequest, getActionTypes } from '../actions/action_helpers';
import { fetchInvoices } from '../api/invoices';
import { setOrderInvoice, resetInvoiceErrors } from '../actions/invoices';

// Save Invoice.
export function saveInvoice(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      invoice: data,
    },
  })
    .then((response) => {
      const { invoice } = response.data;
      dispatch(addItem({ moduleName, item: invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get Invoice.
export function fetchInvoice(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { invoice } = response.data;
      dispatch(setItem({ moduleName, item: invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Update Invoice.
export function updateInvoice(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      invoice: data,
    },
  })
    .then((response) => {
      const { invoice } = response.data;
      dispatch(updateItem({ moduleName, item: invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Delete Invoice.
export function deleteInvoice(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function updateState(id, state) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}/update_state`,
    method: 'put',
    data: {
      state,
    },
  })
    .then((response) => {
      const { invoice } = response.data;
      dispatch(updateItem({ moduleName, item: invoice }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function fetchInvoicesForReport(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);

  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `/invoices?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by,
      order: sort_order,
      report_id: args?.report_id,
    },
  })
    .then((response) => {
      const { invoices, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData: {
          items: invoices, current_page: page, total_count, sort_by, sort_order,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompleteInvoiceNumber({ customer_id, search_params }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/customers/${customer_id}/invoices/autocomplete_invoices_number?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function autocompleteUnpaidInvoices({ customer_id, search_params }) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/customers/${customer_id}/invoices/autocomplete_unpaid_invoices?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function autocompleteInvoices(search_params) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(search_params);
  return () => axios({
    url: `/invoices/autocomplete?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

export function bulkCreateInvoices(order_ids) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl'] });
  const { baseUrl } = moduleTypes;
  let ids = order_ids;
  if (typeof order_ids === 'string') {
    ids = JSON.parse(order_ids);
  }

  return () => axios({
    url: `${baseUrl}/bulk_create`,
    method: 'post',
    data: {
      order_ids: ids,
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/orders', 'ORDER');
      throw error.response;
    });
}

export function fetchOrderInvoice(order_id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { redirectUrl, item } = moduleTypes;

  startFetchingRequest(moduleName);
  return (dispatch) => axios.get(`/orders/${order_id}/order_invoice`)
    .then((response) => {
      const { invoice } = response.data;
      // We use resetInvoiceErrors dispatch method to reset previous invoice error message and set isError to false.
      dispatch(resetInvoiceErrors());
      dispatch(setOrderInvoice([invoice]));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

function* fetchInvoicesSagaSideEffect(action: any) {
  const {
    search_params, per_page, page, sort_by, sort_order, selected_variant,
    dont_merge_search_params, timePeriod,
  } = action;

  try {
    const response = yield call(fetchInvoices({
      search_params,
      per_page,
      page,
      sort_by,
      sort_order,
      selected_variant,
      dont_merge_search_params,
      timePeriod,
    }));

    const { invoices, meta } = response.data;
    const { pagination } = meta;
    const { total_count } = pagination;

    yield put(setItems({
      moduleName,
      responseData: {
        items: invoices,
        current_page: page,
        total_count,
        sort_by,
        sort_order,
        search_params,
        per_page,
        selected_variant,
        dont_merge_search_params,
        timePeriod,
      },
    }));
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* invoiceWatcher() {
  yield takeLatest(FETCH_INVOICES, fetchInvoicesSagaSideEffect);
}
