import React, { Component, ErrorInfo } from 'react';
import StackTrace from 'stacktrace-js';
import { logException } from '@utils/GAUtils';
import {
  errorBoundaryTitle, crashDesc1, crashDesc2, supportNumber1,
} from '@constants/common_constants';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStates> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;

    logException(error.message, true);

    StackTrace.fromError(error).then((stackFrames) => {
      let errorStackTrace = '';
      const stack = stackFrames.map((row) => ({
        fileName: `${row.fileName}:${row.lineNumber || 0}:${row.columnNumber || 0}`,
        functionName: `${row.functionName}`,
      }));
      errorStackTrace = JSON.stringify(stack);
      onError(error, errorStackTrace);
    });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div
          style={{ textAlign: 'center', marginTop: 50 }}
        >
          <div id="header-title">{errorBoundaryTitle}</div>
          <p style={{ marginTop: 10 }}>
            {crashDesc1}
            <br />
            {crashDesc2}
            {supportNumber1}
          </p>
        </div>
      );
    }

    return children;
  }
}

interface ErrorBoundaryStates {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError(error: any, errorStackTrace: any): any,
}

export default ErrorBoundary;
