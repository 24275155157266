// This constants used in invoice action and reducer.
export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';
export const SET_INVOICE_PAYMENTS = 'SET_INVOICE_PAYMENTS';
export const ADD_INVOICE_PAYMENT = 'ADD_INVOICE_PAYMENT';
export const INVOICE_PAYMENT_FETCHED = 'INVOICE_PAYMENT_FETCHED';
export const INVOICE_PAYMENT_UPDATED = 'INVOICE_PAYMENT_UPDATED';
export const INVOICE_PAYMENT_DELETED = 'INVOICE_PAYMENT_DELETED';
export const INVOICE_PAYMENT_EDIT = 'INVOICE_PAYMENT_EDIT';
export const AUTOCOMPLETE_INVOICE_PAYMENT = 'AUTOCOMPLETE_INVOICE_PAYMENT';

export const title = 'Invoice Payments';
export const singularTitle = 'Invoice Payment';
export const moduleName = 'invoice_payments';
export const baseUrl = '/invoice_payments';
export const redirectUrl = '/invoice_payments';
export const listingDataKey = 'invoice_payments';
export const itemDataKey = 'invoice_payment';

export const invoicePaymentConstants = {
  item: INVOICE_PAYMENT,
  setItems: SET_INVOICE_PAYMENTS,
  addItem: ADD_INVOICE_PAYMENT,
  setItem: INVOICE_PAYMENT_FETCHED,
  updateItem: INVOICE_PAYMENT_UPDATED,
  deleteItem: INVOICE_PAYMENT_DELETED,
  autocompleteItems: AUTOCOMPLETE_INVOICE_PAYMENT,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const defaultSearchParams = {
  payment_in_eq: '',
};
