import { getTenantLocalDateTime } from '@helpers/common_helper';
import { approved, draft } from '../../../../constants/returns';
import { THIS_WEEK, TILL_DATE } from '../../../../constants/common';

export const indexVariants = {
  LIST_VIEWS: [
    {
      key: 'this_week_returns', label: 'This week\'s Returns', applyFilters: true, filterSelection: { date_gteq: getTenantLocalDateTime('', false).startOf('week'), date_lteq: getTenantLocalDateTime('', false).endOf('week') }, timePeriod: THIS_WEEK,
    },
    {
      key: 'draft_returns', label: 'Draft Returns', applyFilters: true, filterSelection: { state_in: [draft] }, timePeriod: TILL_DATE,
    },
    {
      key: 'approved_wo_cn_returns', label: 'Approved Returns without Credit Notes', applyFilters: true, filterSelection: { state_in: [approved], credit_notes_id_null: true }, timePeriod: TILL_DATE,
    },
  ],
};
