import axios from '@utils/axios';
import { ADD_BRAND, moduleName } from '@constants/brands';
import setIndexRequestParams from '@utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '@utils/SerializeParams';
import { handleErrors } from '@utils/handleErrors';
import { updateAndCloseQuickAddModule } from '@component/common/quick_add_module/helper';
import {
  setItems, addItem, setItem, updateItem, deleteItem, autocompleteItems,
} from '@actions/common';
import { startFetchingRequest, getActionTypes } from '@actions/action_helpers';

// Get brands List (dispatch, getState)
// args ex. { search_params, per_page, page, sort_by, sort_order }
export function fetchBrands(args) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const requestParams = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = requestParams;
  const { selected_variant, dont_merge_search_params, timePeriod } = args;
  const q = serializeSearchParams(search_params);
  const pageParams = serializePageParams(page, per_page);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}?${pageParams}&${q}`,
    method: 'get',
    params: {
      sort_by, order: sort_order,
    },
  })
    .then((response) => {
      const { brands, meta } = response.data;
      const { pagination } = meta;
      const { total_count } = pagination;
      dispatch(setItems({
        moduleName,
        responseData:
        {
          items: brands,
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
        },
      }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Save Brand.
export function saveBrand(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: baseUrl,
    method: 'post',
    data: {
      brand: data,
    },
  })
    .then((response) => {
      const { brand } = response.data;
      dispatch(addItem({ moduleName, item: brand }));
      const params = { brand_id: brand.id, brand_name: brand.name };
      updateAndCloseQuickAddModule({ identifier: ADD_BRAND, params });
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// Get brand.
export function fetchBrand(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'get',
  })
    .then((response) => {
      const { brand } = response.data;
      dispatch(setItem({ moduleName, item: brand }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      // throw error.response;
    });
}

// Update brand.
export function updateBrand(data) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${data.id}`,
    method: 'put',
    data: {
      brand: data,
    },
  })
    .then((response) => {
      const { brand } = response.data;
      dispatch(updateItem({ moduleName, item: brand }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

export function uploadBrandLogo(logo, brandId) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios.patch(`${baseUrl}/${brandId}/upload_logo`, logo)
    .then((response) => {
      const { brand } = response.data;
      dispatch(setItem({ moduleName, item: brand }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, `${redirectUrl}/${brandId}`, item);
      throw error.response;
    });
}

// Delete brand.
export function deleteBrand(id) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/${id}`,
    method: 'delete',
  })
    .then((response) => {
      dispatch(deleteItem({ moduleName, id }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}

// This function are use in container ComponentDidMount() callback function.
export function fetchBrandsBasicInfo(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  startFetchingRequest(moduleName);
  return (dispatch) => axios({
    url: `${baseUrl}/autocomplete_brand_name?${q}`,
    method: 'get',
  })
    .then((response) => {
      const { brands } = response.data;
      dispatch(autocompleteItems({ moduleName, items: brands }));
      return response;
    })
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
    });
}

export function autocompleteBrandsName(searchParams) {
  const moduleTypes = getActionTypes({ moduleName, actionTypes: ['baseUrl', 'redirectUrl', 'item'] });
  const { baseUrl, redirectUrl, item } = moduleTypes;
  const q = serializeSearchParams(searchParams);
  return () => axios({
    url: `${baseUrl}/autocomplete_brand_name?${q}`,
    method: 'get',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, redirectUrl, item);
      throw error.response;
    });
}
