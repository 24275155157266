import { HOME_BREADCRUMB_LISTING } from '@constants/common';
import map from 'lodash/map';

export const CREDIT_NOTE_FORM_VIEW = 'credit_note_form';

// NOTE: Don't change this constants.
export const CREDIT_NOTE = 'CREDIT_NOTE';
export const SET_CREDIT_NOTES = 'SET_CREDIT_NOTES';
export const ADD_CREDIT_NOTE = 'ADD_CREDIT_NOTE';
export const CREDIT_NOTE_FETCHED = 'CREDIT_NOTE_FETCHED';
export const CREDIT_NOTE_UPDATED = 'CREDIT_NOTE_UPDATED';
export const CREDIT_NOTE_DELETED = 'CREDIT_NOTE_DELETED';
export const CREDIT_NOTE_EDIT = 'CREDIT_NOTE_EDIT';
export const AUTOCOMPLETE_CREDIT_NOTES = 'AUTOCOMPLETE_CREDIT_NOTES';
export const GENERATE_CREDIT_NOTE_FROM_RETURN = 'GENERATE_CREDIT_NOTE_FROM_RETURN';

export const FORM_INFORMATION_TITLE = 'CREDIT NOTE INFORMATION';
export const RESET_CREDIT_NOTE_ERRORS = 'RESET_CREDIT_NOTE_ERRORS';

export const OPEN_STATE_CHANGE_CONFIRMATION_MODAL = 'OPEN_STATE_CHANGE_CONFIRMATION_MODAL';
export const UPDATE_PARAMS = 'UPDATE_PARAMS';
export const AUTOCOMPLETE_RETURN_ITEM_CREDIT_NOTES = 'AUTOCOMPLETE_RETURN_ITEM_CREDIT_NOTES';

export const FETCH_CREDIT_NOTES = 'FETCH_CREDIT_NOTES';

export const title = 'Credit Notes';
export const singularTitle = 'Credit Note';
export const moduleName = 'credit_notes';
export const baseUrl = '/credit_notes';
export const redirectUrl = '/credit_notes';
export const listingDataKey = 'credit_notes';
export const itemDataKey = 'credit_note';

export const CUSTOMER = 'Customer';
export const SUPPLIER = 'Supplier';
export const PARTY_TYPES = [
  { label: CUSTOMER, value: CUSTOMER }, { label: SUPPLIER, value: SUPPLIER },
];

export const creditNoteConstants = {
  item: CREDIT_NOTE,
  setItems: SET_CREDIT_NOTES,
  addItem: ADD_CREDIT_NOTE,
  setItem: CREDIT_NOTE_FETCHED,
  updateItem: CREDIT_NOTE_UPDATED,
  deleteItem: CREDIT_NOTE_DELETED,
  autocompleteItems: AUTOCOMPLETE_CREDIT_NOTES,
  title,
  singularTitle,
  moduleName,
  baseUrl,
  redirectUrl,
  listingDataKey,
  itemDataKey,
};

export const GENERATE_BULK_PDF = 'GENERATE_BULK_PDF';

export const advanceSearchParams = {
  date_gteq: '',
  date_lteq: '',
  amount_gt: '',
  amount_lt: '',
  creditable_type_eq: '',
  branch_id_in: [],
  warehouse_id_in: [],
  user_id_in: [],
  state_in: [],
  voucher_book_id_in: [],
};

export const defaultSearchParams = {
  number_or_party_name_or_party_gstin_cont: '',
  ...advanceSearchParams,
};

export const requiredPrefetchingModuleNames = ['users', 'branches', 'credit_reasons', 'warehouses', 'suppliers', 'customers', 'voucher_books'];

export const PAYMENT = 'Payment';
export const RETURN = 'Return';
export const OPEN_CREDIT_NOTE_INVOICE_FORM_MODAL = 'OPEN_CREDIT_NOTE_INVOICE_FORM_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const DELETE_RECORD = 'DELETE_RECORD';

export const CREDITABLE_TYPES = [{ label: RETURN, value: RETURN }];

export const USER = 'user';

export const ISSUED = 'issued';
export const CANCELLED = 'cancelled';
export const ISSUED_BTN_NAME = 'Issued';
export const CANCELLED_BTN_NAME = 'Cancelled';

export const CREDIT_NOTE_STATES = [
  { label: ISSUED_BTN_NAME, value: ISSUED }, { label: CANCELLED_BTN_NAME, value: CANCELLED },
];

export const CREDIT_NOTE_STATES_FOR_REPORTS = map(CREDIT_NOTE_STATES,
  (state) => ({ name: state.label, id: state.value }));

export const Title = 'Credit Notes';
export const TargetBaseUrl = 'credit_notes';
export const BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: false }]);
export const INDEX_BREADCRUMB_LISTING = HOME_BREADCRUMB_LISTING.concat([{ title: Title, target: TargetBaseUrl, active: true }]);
export const NEW_BREADCRUMB_LISTING = BREADCRUMB_LISTING.concat([{ title: 'New', target: '', active: true }]);

export const handledErrorKeys = {
  master: ['date', 'customer', 'customer_id', 'branch', 'branch_id', 'creditable_type', 'creditable_id', 'sequence', 'notes', 'party_billing_address_id', 'party_billing_address', 'party_shipping_address_id', 'party_shipping_address'],
  credit_note_items: ['variant', 'variant_id', 'description', 'quantity', 'net_price', 'net_amount', 'tax_category', 'tax_category_id', 'tax_amount', 'credit_reason', 'credit_reason_id', 'return_item_credit_note_items'],
  credit_note_invoices: ['invoice', 'invoice_id', 'amount'],
  debit_note_settlements: ['debit_note', 'debit_note_id', 'amount', 'discount'],
};
