import * as types from '../constants/quick_add_module';

const initialStateParams = {
  customer_name: '',
  customer_id: '',
  brand_name: '',
  brand_id: '',
  product_category_name: '',
  product_category_id: '',
  invoice_term_name: '',
  invoice_term_id: '',
  account_name: '',
  account_id: '',
  product_name: '',
  product_id: '',
  customer_rating_name: '',
  customer_rating_id: '',
  customer_category_name: '',
  customer_category_id: '',
  document_category_name: '',
  document_category_id: '',
  warehouse_name: '',
  warehouse_id: '',
  branch_id: '',
  branch_name: '',
  beat_route_id: '',
  beat_route_name: '',
  credit_reason_id: '',
  credit_reason_name: '',
  inventory_batch_id: '',
  inventory_batch_number: '',
  inventory_piece_id: '',
  inventory_piece_number: '',
};

export const initialState = {
  identifiers: [],
  customMatch: {},
  params: { ...initialStateParams },
  formInitialValues: {},
};

export default function quickAddModule(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_QUICK_ADD_MODULE_MODAL: {
      const params = { ...state.params, ...action.params };
      const identifiers = state.identifiers || [];
      identifiers.push(action.identifier);
      return {
        ...state,
        identifiers,
        params,
        formInitialValues: action.formInitialValues,
      };
    }

    case types.OPEN_SHOW_AFTER_QUICK_ADD_SAVE: {
      const params = { ...state.params, ...action.params };
      const identifiers = state.identifiers || [];
      const customMatch = { ...state.customMatch, [action.identifier]: action.match };
      identifiers.pop();
      identifiers.push(action.identifier);
      return {
        ...state,
        identifiers,
        params,
        customMatch,
        formInitialValues: action.formInitialValues || {},
      };
    }

    case types.CLOSE_QUICK_ADD_MODULE_MODAL: {
      const { identifiers } = state;
      if (identifiers.includes(action.identifier)) {
        identifiers.pop();
      }
      return {
        ...state, identifiers,
      };
    }
    case types.UPDATE_QUICK_ADD_MODULE_PARAMS: {
      const params = { ...state.params, ...action.params };
      return { ...state, params };
    }
    case types.UPDATE_PARAMS_AND_CLOSE_QUICK_ADD_MODULE: {
      const params = { ...state.params, ...action.params };
      const { identifiers } = state;
      if (identifiers.includes(action.identifier)) {
        identifiers.pop();
      }
      return { ...state, identifiers, params };
    }
    case types.RESET_QUICK_ADD_MODULE_PARAMS: {
      return {
        ...state,
        params: { ...initialStateParams },
      };
    }
    default:
      return state;
  }
}
