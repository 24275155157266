import store from '@store/store';
import { setCurrentUser, resetReducerState } from '@actions/auth';
import { ADD_NOTIFICATION } from '@constants/root';

export function dispatchCurrentUser(
  isAuthenticated: boolean,
  user: Record<string, any>,
  isError: boolean,
  errors?: Record<string, any>,
) {
  store.dispatch(setCurrentUser(isAuthenticated, user, isError, errors));
}

export function addErrors(
  errors: Record<string, any>,
  message: string,
  moduleName: string,
  subModuleName?: string,
) {
  store.dispatch({
    type: ADD_NOTIFICATION, title: 'Error', message, level: 'error', renderView: true,
  });
  store.dispatch({ type: `${moduleName}_ERROR_RESPONSE`, errors, subModuleName });
}

export function addWarnings(
  warnings: Record<string, any>,
  moduleName: string,
  subModuleName?: string,
) {
  store.dispatch({ type: `${moduleName}_WARNING_RESPONSE`, warnings, subModuleName });
}

export function resetReducerAllState() {
  store.dispatch(resetReducerState());
}
