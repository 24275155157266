import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Loadable from 'react-loadable';
import { INVALID_ACTION_ERROR_HEADER_TEXT } from '@constants/common_constants';
import Loading from '../Loading';
import { displayFormErrors, displayNestedFormErrors } from './helper';

const DMSFlashMessageAlert = Loadable({
  loader: () => import(/* webpackChunkName : "ComponentsDMSFlashMessageDMSFlashMessageAlert" */ './dms_flash_message_alert'),
  loading: Loading,
});

interface Props {
  showFlashMessage?: boolean;
  flashMessageStyle?: string;
  flashMessageTitle?: string;
  unhandledErrors?: any;
  alertStyle?: any;
  showInlineActionBtn?: boolean;
  inlineActionBtnName?: string;
  isNestedFormFlashMessage?: boolean;
  handleOnClick?: Function;
  handleOnClose?: Function;
  showFlashMessageDismissBtn?: boolean;
}

interface States {
  showFlashMessage: boolean;
}

const defaultProps = {
  showFlashMessage: true,
  flashMessageStyle: 'danger',
  flashMessageTitle: INVALID_ACTION_ERROR_HEADER_TEXT,
  unhandledErrors: {},
  alertStyle: {},
  showInlineActionBtn: false,
  inlineActionBtnName: '',
  isNestedFormFlashMessage: false,
  handleOnClick: () => ({}),
  handleOnClose: () => ({}),
  showFlashMessageDismissBtn: true,
};

class DMSFlashMessage extends Component<Props, States> {
  static defaultProps = defaultProps

  constructor(props) {
    super(props);
    const { showFlashMessage } = this.props;
    this.state = {
      showFlashMessage,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { showFlashMessage } = nextProps;
    this.setState({ showFlashMessage });
  }

  handleFlashMessageDismiss = () => {
    const { handleOnClose } = this.props;
    handleOnClose();
    this.setState({ showFlashMessage: false });
  }

  handleButtonOnClick = () => {
    const { handleOnClick } = this.props;
    handleOnClick({ showFlashMessage: false });
  }

  render() {
    const {
      flashMessageStyle, flashMessageTitle, unhandledErrors, showInlineActionBtn,
      inlineActionBtnName, alertStyle, isNestedFormFlashMessage, handleOnClick,
      showFlashMessageDismissBtn,
    } = this.props;
    const { showFlashMessage } = this.state;

    if (!showFlashMessage || isEmpty(unhandledErrors)) return null;

    return (
      <>
        <DMSFlashMessageAlert
          flashMessageStyle={flashMessageStyle}
          alertStyle={alertStyle}
          flashMessageTitle={flashMessageTitle}
          handleFlashMessageDismiss={this.handleFlashMessageDismiss}
          showFlashMessageDismissBtn={showFlashMessageDismissBtn}
        >
          {
            isNestedFormFlashMessage
              ? displayNestedFormErrors({ unhandledErrors })
              : displayFormErrors({
                unhandledErrors, showInlineActionBtn, inlineActionBtnName, handleOnClick,
              })
          }
        </DMSFlashMessageAlert>
      </>
    );
  }
}

export default DMSFlashMessage;
