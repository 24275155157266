import axios from '@utils/axios';
import { handleErrors } from '@utils/handleErrors';

export const NOTIFY_EXCEPTION_URL = '/notify_exception';

export function sendExceptionEmail(args) {
  const {
    error, tenantName, userId, userName,
  } = args;

  let otherParams = {};
  if (tenantName) otherParams = { ...otherParams, tenant_name: tenantName };
  if (userId) otherParams = { ...otherParams, user_id: userId };
  if (userName) otherParams = { ...otherParams, user_name: userName };

  // const errorOwnPropertyDescriptors = Object.getOwnPropertyDescriptors(error);
  return () => axios({
    url: NOTIFY_EXCEPTION_URL,
    method: 'post',
    data: {
      error,
      ...otherParams,
    },
  })
    .then((response) => response)
    .catch((err) => {
      handleErrors(err, '/', {});
      // throw err.response;
    });
}
