import { REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_WAREHOUSES, ADD_WAREHOUSE, WAREHOUSE_FETCHED, WAREHOUSE_UPDATED,
  WAREHOUSE_DELETED, AUTOCOMPLETE_WAREHOUSES, defaultSearchParams, moduleName,
} from '@constants/warehouses';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, autocomplete,
} from '@reducers/common';
import { getHeaderSelection, DEFAULT } from '@component/common/headers/helpers/header_selection';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'name',
  sort_order: 'asc',
  search_params: { ...defaultSearchParams },
  selected_variant: getHeaderSelection({ moduleName, selectionKey: DEFAULT }),
};

export default function warehouses(state = initialState, action) {
  switch (action.type) {
    case SET_WAREHOUSES: return setItems(state, action);
    case ADD_WAREHOUSE: return addItem(state, action);
    case WAREHOUSE_FETCHED: return fetchItem(state, action);
    case WAREHOUSE_UPDATED: return updateItem(state, action);
    case WAREHOUSE_DELETED: return deleteItem(state, action);
    case AUTOCOMPLETE_WAREHOUSES: return autocomplete(state, action);
    default:
      return state;
  }
}
