import axios, { fetchingRequest } from '@utils/axios';
import { GET } from '@utils/axios_methods';
import * as types from '../../constants/accounting/recon_entries';
import setIndexRequestParams from '../../utils/HandleIndexActionParams';
import { serializeSearchParams, serializePageParams } from '../../utils/SerializeParams';
import { handleErrors } from '../../utils/handleErrors';
import { reconEntriesInitialState } from '../../reducers/accounting/recon_entries';

// Create SET_RECON_ENTRIES action.
export function setReconEntries(entries_states) {
  return {
    type: types.SET_RECON_ENTRIES,
    ...entries_states,
  };
}

export function setInfReconEntries(entries_states) {
  return {
    type: 'SET_INF_RECON_ENTRIES',
    ...entries_states,
  };
}

// Create RESET_RECON_ENTRIES action.
export function resetReconEntries() {
  return {
    type: types.RESET_RECON_ENTRIES,
    ...reconEntriesInitialState,
  };
}

// Create SET_RECON_ENTRIES action.
export function setReconEntriesSearchParams(entries_states) {
  return {
    type: types.SET_RECON_ENTRIES_SEARCH_PARAMS,
    ...entries_states,
  };
}

const loadMoreReconEntries = (reconEntriesData) => ({
  type: types.LOAD_MORE_RECON_ENTRIES,
  ...reconEntriesData,
});

// Get Recon Entries List (dispatch, getState)
// args ex. { search_params, per_page, page, sort_by, sort_order }
export function fetchReconEntries(args) {
  const request_params = setIndexRequestParams(args);
  const {
    search_params, per_page, page, sort_by, sort_order,
  } = request_params;
  const {
    selected_variant, dont_merge_search_params, timePeriod, account_id, recon_id,
  } = args;
  const q = serializeSearchParams(search_params);
  const page_params = serializePageParams(page, per_page);

  return (dispatch) => {
    fetchingRequest(types.RECON_ENTRY);
    return GET({
      url: `/accounting/amounts/reconciliation?${page_params}&${q}`,
      params: {
        sort_by, order: sort_order, account_id, reconciliation_id: recon_id,
      },
      error: { redirect_to: '/accounting/reconciliations', module_name: types.RECON_ENTRY },
    })
      .then((response) => {
        const { amounts, meta } = response.data;
        const {
          total_count, current_page, next_page, prev_page,
        } = meta.pagination;
        dispatch(setReconEntries({
          items: amounts || [],
          current_page: page,
          total_count,
          sort_by,
          sort_order,
          search_params,
          per_page,
          selected_variant,
          dont_merge_search_params,
          timePeriod,
          account_id,
          recon_id,
          links: { current_page, next_page, prev_page },
          isActive: false,
        }));
        return response;
      });
  };
}

// WHAT: Get next_page ReconEntries List (dispatch, getState)
export const fetchMoreReconEntries = (args) => {
  const request_params = setIndexRequestParams(args);
  const {
    search_params, per_page, sort_by, sort_order,
  } = request_params;
  const q = serializeSearchParams(search_params);

  return (dispatch) => {
    fetchingRequest(types.RECON_ENTRY);
    return axios({
      url: `${args.url}&${q}`,
      method: 'GET',
      params: {
        sort_by,
        order: sort_order,
        account_id: args.account_id,
        reconciliation_id: args.recon_id,
      },
    })
      .then((response) => {
        const { amounts, meta } = response.data;
        const {
          total_count, total_pages, current_page, next_page, prev_page,
        } = meta.pagination;

        dispatch(loadMoreReconEntries({
          items: amounts,
          current_page: args.current_page + 1,
          sort_by,
          sort_order,
          search_params,
          per_page,
          total_count,
          total_pages,
          links: { current_page, next_page, prev_page },
        }));
        return response;
      })
      .catch((error) => {
        handleErrors(error, '/recon_entries', types.RECON_ENTRY, false);
        throw error.response;
      });
  };
};
