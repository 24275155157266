import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import {
  SET_PAYMENTS, ADD_PAYMENT, PAYMENT_FETCHED, PAYMENT_UPDATED, PAYMENT_DELETED,
  UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED, defaultSearchParams,
  moduleName,
} from '../constants/payments';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem, updateStatus,
} from './common';
import { setAndGetIndexRelatedParams } from '../components/common/headers/helpers/header_selection';

const {
  curSelectedVariant, curSearchParams,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function payments(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENTS: return setItems(state, action);
    case ADD_PAYMENT: return addItem(state, action);
    case PAYMENT_FETCHED: return fetchItem(state, action);
    case PAYMENT_UPDATED: return updateItem(state, action);
    case PAYMENT_DELETED: return deleteItem(state, action);
    case UPDATE_SELECTED_BULK_PAYMENTS_STATUS_TO_ACCOUNTED:
      return updateStatus(state, action, action.status);
    default:
      return state;
  }
}
