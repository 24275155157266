import React, { Component } from 'react';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';

interface ListingActionDropdownProps {
  children: any;
}

class ListingActionDropdown extends Component<ListingActionDropdownProps, {}> {
  render() {
    const { children } = this.props;
    return (
      <ButtonToolbar className="listing-action-button-toolbar">
        <DropdownButton
          pullRight
          title={<i className="fa fa-ellipsis-v" aria-hidden="true" />}
          noCaret
          id="listing-item-action-dropdown"
          className="btn btn-sm btn-primary action-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MenuItem className="listing-action-menu-item-header">
            Actions
          </MenuItem>
          <MenuItem divider className="listing-action-menu-item-divider" />
          {children}
        </DropdownButton>
      </ButtonToolbar>
    );
  }
}
export default ListingActionDropdown;
