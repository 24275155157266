import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { bindActionCreators } from 'redux';
import { autocompleteVoucherBooks as autocompleteVoucherBooksAction } from '@saga/voucher_books';
import { NavDropdown } from 'react-bootstrap';
import { moduleName as invoices } from '@constants/invoices';
import { renderBreadcrumbs } from './IndexHeader';

const getSearchParams = (moduleName) => {
  switch (moduleName) {
    case invoices:
      return { resource_type_eq: 'Invoice' };
    default:
      return {};
  }
};

interface FormHeaderProps {
  title: string;
  moduleName?: string;
  breadcrumbListing?: any[];
  autocompleteVoucherBooks: (args: any) => any;
  updateFormComponent?: (selectedMenuItem: any) => void;
}

interface FormHeaderStates {
  isMenuOpened: boolean;
  menuListing: any[];
  selectedMenuItem: any;
}

const defaultProps = {
  moduleName: '',
  breadcrumbListing: [],
  updateFormComponent: () => ({}),
};

class FormHeader extends Component<FormHeaderProps, FormHeaderStates> {
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
      menuListing: [],
      selectedMenuItem: {},
    };
  }

  componentDidMount() {
    const { moduleName } = this.props;
    const searchParams = getSearchParams(moduleName);
    switch (moduleName) {
      case invoices:
        this.fetchVoucherBooks(searchParams);
        break;
      default:
        break;
    }
  }

  fetchVoucherBooks = (args) => {
    const { autocompleteVoucherBooks } = this.props;
    autocompleteVoucherBooks(args)
      .then((response) => {
        const voucherBooks = response?.data?.voucher_books || [];
        this.setState({ menuListing: voucherBooks });
      });
  }

  handleToggle = () => {
    const { isMenuOpened } = this.state;
    this.setState({ isMenuOpened: !isMenuOpened });
  }

  handleSelectChange = (selectedMenuItem: any) => {
    const { isMenuOpened } = this.state;
    const { updateFormComponent } = this.props;
    this.setState({ selectedMenuItem, isMenuOpened: !isMenuOpened });
    updateFormComponent(selectedMenuItem);
  }

  renderDropDownSelection = () => {
    const {
      isMenuOpened, menuListing, selectedMenuItem,
    } = this.state;
    return (
      <NavDropdown
        title={(
          <div id="header-title">
            {selectedMenuItem?.name || ''}
            &nbsp;
            <i className="fa fa-chevron-down" />
          </div>
        )}
        noCaret
        id="render-dropdown-selection"
        open={isMenuOpened}
        onToggle={this.handleToggle}
        onClick={() => { this.setState({ isMenuOpened: true }); }}
      >
        <div className="form-dropdown-section">
          {
            map(menuListing, (menuItem, idx) => (
              <a
                key={`index_header_selection_link_key_${menuItem.id || idx}`}
                onClick={() => this.handleSelectChange(menuItem)}
              >
                {menuItem?.name || ''}
              </a>
            ))
          }
        </div>
      </NavDropdown>
    );
  }

  render() {
    const { breadcrumbListing, title } = this.props;
    return (
      <div className="report-page-info form-header-breadcrumb">
        {renderBreadcrumbs(breadcrumbListing)}
        <div className="title-with-dropdown">
          <h4 className="header-breadcrumb title">{title}</h4>
          {this.renderDropDownSelection()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    autocompleteVoucherBooks: autocompleteVoucherBooksAction,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(FormHeader);
