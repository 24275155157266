import { call, takeLatest } from 'redux-saga/effects';
import { handleErrors } from '@utils/handleErrors';
import axios from '@utils/axios';
import { CONFIRM_OTP, GET_CONFIRMATION_OTP } from './constants';

function getAccountConfirmationOtp(args) {
  const { account } = args;

  return () => axios({
    url: '/account/confirmation',
    method: 'POST',
    data: {
      account: { mobile: account?.mobile },
    },
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/sign_up', '');
      throw error.response;
    });
}

function* getAccountConfirmationOtpFunc(action) {
  const { account } = action || {};
  try {
    const response = yield call(getAccountConfirmationOtp({ account }));
    // yield put({ type: SET_SING_UP_ACCOUNT, account: response?.data?.account || {} });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

function confirmAccount(args) {
  const { account } = args || {};
  const { mobile, confirmation_otp: confirmationOtp } = account;
  return () => axios({
    url: `/account/confirmation?account[mobile]=${mobile || ''}&account[confirmation_otp]=${confirmationOtp || ''}`,
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => {
      handleErrors(error, '/sign_up', '');
      throw error.response;
    });
}

function* confirmAccountFunc(action) {
  const { account } = action || {};
  try {
    const response = yield call(confirmAccount({ account }));
    // yield put({ type: SET_SING_UP_ACCOUNT, account: response?.data?.account || {} });
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* accountConfirmation() {
  yield takeLatest(GET_CONFIRMATION_OTP, getAccountConfirmationOtpFunc);
  yield takeLatest(CONFIRM_OTP, confirmAccountFunc);
}
