import * as utilsConst from '@utils/common_constants';
import store from '@store/store';
import { addNotice, deleteNotice } from '@actions/notices';
import { refreshUiMessageId } from '@constants/common';

export const addUpdateNotice = (resHeaders) => {
  const whatTypeOfUpdate = resHeaders && resHeaders[utilsConst.updateAvailableHeader];

  const dashboardVerFromBE = resHeaders && resHeaders[utilsConst.dashboardVerHeaderForFE];
  const shouldDashboardUpdate = dashboardVerFromBE
  && (dashboardVerFromBE > utilsConst.dashboardVersion);
  const isVersionTypeValid = !!((whatTypeOfUpdate === utilsConst?.mandatoryUpdate)
    || (whatTypeOfUpdate === utilsConst.optionalUpdate));

  if (whatTypeOfUpdate && isVersionTypeValid && shouldDashboardUpdate) {
    const { notices }: any = store.getState();
    const refreshUiBanners = notices.items.find((obj) => (obj.id === refreshUiMessageId));
    const isUpdateOptional = whatTypeOfUpdate === utilsConst.optionalUpdate;

    const updateLevel = isUpdateOptional ? 'warning' : 'danger';
    const isNoticeNotPresent = !refreshUiBanners;
    const isDifferentUpdateAvailable = !!(refreshUiBanners
      && (refreshUiBanners.type !== updateLevel));

    if (isDifferentUpdateAvailable) {
      store.dispatch(deleteNotice(refreshUiBanners.id));
    }

    if (isNoticeNotPresent || isDifferentUpdateAvailable) {
      store.dispatch(addNotice({
        id: refreshUiMessageId,
        message: 'Please refresh your page to get latest updates!',
        type: isUpdateOptional ? 'warning' : 'danger',
        closeable: isUpdateOptional,
      }));
    }
  }
};
