import * as types from '../constants/quick_listing_module';

export const initialState = {
  identifiers: [],
};

export default function quickListingModule(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_QUICK_LISTING_MODAL: {
      const identifiers = state.identifiers || [];
      identifiers.push(action.identifier);
      return {
        ...state,
        identifiers,
      };
    }
    case types.CLOSE_QUICK_LISTING_MODAL: {
      const { identifiers } = state;
      identifiers.pop();
      return {
        ...state, identifiers,
      };
    }
    default:
      return state;
  }
}
