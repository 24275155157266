import { REDUCER_COMMON_INITIAL_STATES } from '../../constants/common';
import { SET_ACCOUNTING_AMOUNTS, defaultSearchParams } from '../../constants/accounting/amounts';
import { setItems } from '../common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  search_params: { ...defaultSearchParams },
};

export default function accountingAmounts(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNTING_AMOUNTS: return setItems(state, action);
    default:
      return state;
  }
}
