import map from 'lodash/map';
import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '@constants/common';
import {
  SET_PURCHASE_INVOICES, ADD_PURCHASE_INVOICE, PURCHASE_INVOICE_FETCHED, PURCHASE_INVOICE_UPDATED,
  PURCHASE_INVOICE_DELETED, defaultSearchParams, moduleName, NEW_PURCHASE_INVOICE,
} from '@constants/purchase_invoices';
import { setAndGetIndexRelatedParams } from '@component/common/headers/helpers/header_selection';
import {
  setItems, addItem, fetchItem, updateItem, deleteItem,
} from '@reducers/common';
import { BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_INVOICE_ITEMS } from '@constants/inventories';
import { BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_INVOICE_ITEMS } from '@src/constants/inventory_pieces';

const {
  curSearchParams, curSelectedVariant,
} = setAndGetIndexRelatedParams({ moduleName, defaultSearchParams });

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: '',
  sort_order: '',
  per_page: PER_PAGE,
  search_params: { ...curSearchParams },
  selected_variant: curSelectedVariant,
};

export default function purchaseInvoices(state = initialState, action) {
  switch (action.type) {
    case SET_PURCHASE_INVOICES: return setItems(state, action);
    case ADD_PURCHASE_INVOICE: return addItem(state, action, true);
    case PURCHASE_INVOICE_FETCHED: return fetchItem(state, action);
    case PURCHASE_INVOICE_UPDATED: return updateItem(state, action);
    case PURCHASE_INVOICE_DELETED: return deleteItem(state, action);
    case NEW_PURCHASE_INVOICE: {
      const items = map(state.items, (item) => {
        if (item.id === null) return false;
        return item;
      });
      items.push(action.purchase_invoice);
      return {
        ...state,
        items,
        isError: false,
        errors: {},
        warnings: {},
        isFetching: false,
      };
    }
    case BULK_CREATE_INVENTORY_PIECES_FOR_PURCHASE_INVOICE_ITEMS:
    case BULK_CREATE_INVENTORY_BATCHES_FOR_PURCHASE_INVOICE_ITEMS: {
      const {
        voucherId, voucherItemId, voucherItemData,
      } = action;
      const items = map(state.items, (item) => {
        if (item?.id !== voucherId) return item;
        const purchaseInvoiceItems = map(item.purchase_invoice_items, (purchaseInvoiceItem) => {
          if (purchaseInvoiceItem?.id !== voucherItemId) return purchaseInvoiceItem;
          return voucherItemData;
        });
        return { ...item, purchase_invoice_items: purchaseInvoiceItems };
      });

      return { ...state, items };
    }
    default:
      return state;
  }
}
