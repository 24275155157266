import { PER_PAGE, REDUCER_COMMON_INITIAL_STATES } from '../constants/common';
import { SET_FEEDS } from '../constants/feeds';
import { setItems } from './common';

export const initialState = {
  ...REDUCER_COMMON_INITIAL_STATES,
  sort_by: 'id',
  sort_order: 'asc',
  per_page: PER_PAGE,
  search_params: {},
};

export default function feedsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEEDS: {
      return setItems(state, action);
    }
    default:
      return state;
  }
}
